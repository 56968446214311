<template>
  <left-modal :toolbar-name="toolbarName" @closeModal="$emit('closeModal')">
    <div v-if="!canceledTab" class="font-size-12 mb-2">
      تراکنش‌ها تا پیش از بالا رفتن موجودی کیف‌پول امکان لغو دارند. برای لغو
      برداشت، تراکنش‌های مورد نظر را انتخاب کنید.
    </div>
    <general-card custom-card-class="px-8 py-4">
      <div class="d-flex justify-space-between font_2 dashed-border pb-3">
        <span>جمع مبالغ</span>
        <span class="font_2"> {{ amountSum }} </span>
      </div>
      <div class="d-flex justify-space-between font_2 pt-3">
        <span>تعداد تراکنش</span>
        <span class="font_2"> {{ total }} برداشت</span>
      </div>
    </general-card>

    <settlement-queue-canceled
      v-if="canceledTab"
      :canceled-settlements="singleSettlements"
      :on-batch-component="true"
      :total="total"
      :cancel-skeleton="false"
    />
    <settlement-queue-pending-items
      v-else
      :pending-settlements="singleSettlements"
      :total="total"
      :on-batch-component="true"
      :all-pending-ids="getAllPendingIds"
      @resetData="$emit('resetData')"
      @checkCanceled="$emit('checkCanceled', $event)"
    />
  </left-modal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal.vue"
import GeneralCard from "@/components/elements/generalCard.vue"

export default {
  name: "QueuedBatchesModal",
  components: {
    LeftModal,
    GeneralCard,
    SettlementQueuePendingItems: () =>
      import(
        "@/components/businessHome/elements/settlementQueue/settlementQueuePendingItems.vue"
      ),
    SettlementQueueCanceled: () =>
      import(
        "@/components/businessHome/elements/settlementQueue/settlementQueueCanceled.vue"
      ),
  },
  props: {
    selectedSettlement: {
      default: () => {},
      type: Object,
    },
    amountSum: {
      default: "",
      type: String,
    },
    canceledTab: {
      default: false,
      type: Boolean,
    },
    toolbarName: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      singleSettlements: [],
    }
  },
  computed: {
    getAllPendingIds() {
      return this.selectedSettlement?.settlement_items?.map((e) => e.id) ?? []
    },
    total() {
      return this.singleSettlements.length
    },
  },
  watch: {
    selectedSettlement: function () {
      this.fillData()
    },
  },
  mounted() {
    this.fillData()
  },
  methods: {
    fillData() {
      let resultArray = []
      this.selectedSettlement.settlement_items.forEach((element) => {
        resultArray.push({
          id: element.id,
          settlement_items: [element],
        })
      })

      this.singleSettlements = resultArray
    },
  },
}
</script>

<style scoped lang="scss">
.dashed-border {
  border-bottom: 1px dashed $van_color16;
}
</style>
