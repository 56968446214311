<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 8L5.5 8L5.5 5L4.5 5L4.5 8ZM5 0.5C2.24 0.5 0 2.74 0 5.5C0 8.26 2.24 10.5 5 10.5C7.76 10.5 10 8.26 10 5.5C10 2.74 7.76 0.5 5 0.5ZM5 9.5C2.795 9.5 1 7.705 1 5.5C1 3.295 2.795 1.5 5 1.5C7.205 1.5 9 3.295 9 5.5C9 7.705 7.205 9.5 5 9.5ZM4.5 4L5.5 4V3L4.5 3V4Z"
      fill="#666666"
    />
  </svg>
</template>

<script>
export default {
  name: "AvatarSvg",
  props: {
    width: {
      type: String,
      default: '10',
    },
    height: {
      type: String,
      default: '11',
    },
  },
}
</script>

<style lang="scss" scoped></style>
