<template>
  <div class="w-100">
    <v-card
      v-if="isIpgInactive"
      class="d-flex align-center justify-space-between z-index-1 my-8 pl-3"
      :height="!reuseIsMobile ? '180' : '220'"
      :class="{ 'flex-column': reuseIsMobile }"
    >
      <div class="d-flex align-center justify-center">
        <v-img
          width="140"
          height="140"
          max-width="140"
          max-height="140"
          :src="require('@/assets/img/tools/ipg-introduce.svg')"
        />
        <div class="d-flex flex-column">
          <span class="font_9 mb-1">درگاه پرداخت</span>
          <span class="font_2"> ویژه دارندگان وب‌سایت و اپلیکیشن </span>
          <span class="font_26 ipg-detail" @click="openIpgDetailModal">
            جزییات درگاه پرداخت
            <v-icon size="20" color="#4C9F87">info_outline</v-icon></span
          >
        </div>
      </div>
      <v-btn
        :ripple="false"
        width="160"
        height="44"
        elevation="0"
        color="#4C9F87"
        :loading="isLoading"
        @click="shaparakTerminalModal = true"
      >
        <span class="font_36 white--text"> فعال‌سازی </span>
      </v-btn>
    </v-card>
    <v-card
      v-if="!isActiveToolsCountExceptIpgRefundA2aMoreThanOne"
      class="d-flex flex-column align-center justify-center z-index-1 mt-10 my-6"
      :height="!reuseIsMobile ? '257' : ''"
    >
      <div class="d-flex align-center justify-center flex-wrap w-100">
        <v-img
          width="40"
          height="40"
          max-width="40"
          max-height="40"
          :src="require('@/assets/img/tools/gray-customer.svg')"
        />
        <v-img
          width="60"
          height="60"
          max-height="60"
          max-width="60"
          :src="require('@/assets/img/tools/gray-requestMoney.svg')"
        />
        <v-img
          width="80"
          height="80"
          max-height="80"
          max-width="80"
          :src="require('@/assets/img/tools/gray-settlement_batch.svg')"
        />
        <v-img
          width="100"
          height="100"
          max-height="100"
          max-width="100"
          :src="require('@/assets/img/tools/gray-A2A.svg')"
        />
        <v-img
          width="80"
          height="80"
          max-height="80"
          max-width="80"
          :src="require('@/assets/img/tools/gray-subscription.svg')"
        />
        <v-img
          width="60"
          height="60"
          max-height="60"
          max-width="60"
          :src="require('@/assets/img/tools/gray-kerdar.svg')"
        />
        <v-img
          width="40"
          height="40"
          max-height="40"
          max-width="40"
          :src="require('@/assets/img/tools/gray-accountant.svg')"
        />
      </div>
      <div class="d-flex flex-column align-center">
        <span class="font_9 mb-1">ابزارهای بیشتر</span>
        <span class="font_2"> برای مشاهده سایر ابزارها، اینجا کلیک کنید. </span>
        <v-btn
          width="160"
          height="44"
          :ripple="false"
          outlined
          style="border: 1px solid #999"
          @click="goToTools"
        >
          <span class="font-14-no-666-24"> جعبه ابزار </span>
        </v-btn>
      </div>
    </v-card>
    <v-dialog
      v-if="modalStatus"
      v-model="modalStatus"
      :fullscreen="reuseIsMobile"
      :width="reuseIsMobile ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      transition="slide-x-transition"
    >
      <introduceModal
        v-if="introduceModel"
        :tool-status="ipgStatus"
        whichtool="ipg"
        @closeModal="closeModals"
      />
    </v-dialog>
    <v-dialog
      v-model="$store.state.ipg.isOpenIpgModal"
      content-class="vanmodal"
      :fullscreen="reuseIsMobile"
      :width="reuseIsMobile ? '100%' : '600'"
      no-click-animation
      transition="slide-x-transition"
      @click:outside="closeIpgModal"
    >
      <AddIpgModal @close-ipg-modal="closeIpgModal" />
    </v-dialog>

    <GeneralDialog
      :active="shaparakTerminalModal"
      btn1-text="بله دارم"
      btn2-class="btn_small_normal"
      btn2-text="انصراف"
      content-class="confirmationMessageDialog"
      description="به دستور بانک مرکزی، داشتن «اینماد» و «کد رهگیری مالیاتی» برای فعال‌سازی درگاه پرداخت الزامی‌ست."
      logo="newIcon/ipgDialogNew.svg"
      title="اینماد و کد رهگیری مالیاتی دارید؟"
      @btn1Clicked="activeIpgTool"
      @btn2Clicked="closeModals"
    />
  </div>
</template>

<script>
import introduceModal from "@/components/tools/modals/introduce"
import AddIpgModal from "@/components/ipg/modals/AddIpgModal"
import GeneralDialog from "@/components/modals/generalDialog"
import { mapMutations } from "vuex"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "NewToolsBox",
  components: {
    introduceModal,
    AddIpgModal,
    GeneralDialog,
  },
  mixins: [screenSize],
  data() {
    return {
      modalStatus: false,
      introduceModel: false,
      shaparakTerminalModal: false,
      isLoading: false,
      isActiveToolsCountExceptIpgRefundA2aMoreThanOne: false,
    }
  },
  computed: {
    ipgStatus() {
      return this.$store.state.business?.tools?.ipg.status
    },
    isIpgInactive() {
      return this.ipgStatus === "INACTIVE"
    },
  },
  mounted() {
    this.checkCountOfActiveToolsExceptIpgRefundA2a()
  },
  methods: {
    ...mapMutations(["changeIpgModalOpeningState"]),
    goToTools() {
      this.$router.push(`${this.$store.getters.urlParam} /tools`)
    },
    closeModals() {
      this.modalStatus = false
      this.introduceModel = false
      this.shaparakTerminalModal = false
      this.changeIpgModalOpeningState(false)
      this.isLoading = false
    },
    openIpgDetailModal() {
      this.modalStatus = true
      this.introduceModel = true
    },
    activeIpgTool() {
      this.shaparakTerminalModal = false
      this.isLoading = true
      if (!this.$store.state.business.has_shaparak_terminal) {
        this.changeIpgModalOpeningState(true)
        return
      }

      let data = {
        tools: "ipg",
        status: "PENDING",
      }

      this.$http
        .post(this.$store.getters.apiUrlParam + "/tools/update", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.commit("changeToolsStatus", {
            name: "ipg",
            status: response.data.data["ipg"],
          })

          this.introduceModel = false
          let message
          if (response.data.message === "اطلاعات درگاه خود را وارد کنید") {
            //ToDO: fix in tools deviation
            this.requireTaxCodeOrPostalCode = true
            this.changeIpgModalOpeningState(true)
            return
          }
          message = "درخواست فعال‌سازی «" + "ipg" + "» ثبت شد"
          this.$store.commit("callSuccessSnackbar", message)
          this.isLoading = false
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    closeIpgModal() {
      this.changeIpgModalOpeningState(false)
      this.isLoading = false
    },
    checkCountOfActiveToolsExceptIpgRefundA2a() {
      const toolsList = this.$store.state.business.tools
      if (toolsList) {
        let counter = 0
        this.isActiveToolsCountExceptIpgRefundA2aMoreThanOne = false

        for (let key of Object.keys(toolsList)) {
          if (
            key !== "ipg" &&
            key !== "refund" &&
            key !== "A2A" &&
            toolsList[key].is_created
          ) {
            counter++
            if (counter >= 1) {
              this.isActiveToolsCountExceptIpgRefundA2aMoreThanOne = true
              break
            }
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.z-index-1 {
  z-index: 1;
}

.ipg-detail {
  cursor: pointer;
}
</style>
