<template>
  <chart-card-one-filter
    class="mmt-5"
    :title="kerdarLang.general.comparisoningOfUnsuccessfulDeposit"
    :show-filter="false"
  >
    <one-bar-horizontal-bar-type2
      v-if="unsuccessfulDepositStatus"
      :x-value="[
        kerdarLang.general.lastDayErrors,
        generateTextOfAverageLastSomeDays(7),
        generateTextOfAverageLastSomeDays(30),
      ]"
      :y-value="yValueUnsuccessfulDeposit"
      :y-result="unsuccessfulDepositTooltip"
      :y-unit="kerdarLang.general.unsuccessfulDeposit"
      :round-value="true"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </chart-card-one-filter>
</template>

<script>
import OneBarHorizontalBarType2 from "@/components/kerdar/components/oneBarHorizontalBarType2"
import ChartCardOneFilter from "@/components/kerdar/components/chartCardOneFilter"
import {
  calculateGrowPercentage,
  generateTextOfAverageLastSomeDays,
  generateTextOfAverageUnsuccessfulDepositsLastSomeDays,
} from "@/components/kerdar/utils/generalMethods.js"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "PaymentFormUnsuccessfulDeposit",
  components: {
    OneBarHorizontalBarType2,
    ChartCardOneFilter,
  },
  props: {
    duration: {
      type: Object,
      default: () => {},
    },
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      unsuccessfulDepositData: [],
      unsuccessfulDepositStatus: false,
      generateTextOfAverageLastSomeDays,
    }
  },
  computed: {
    yValueUnsuccessfulDeposit() {
      return this.unsuccessfulDepositData
    },
    unsuccessfulDepositTooltip() {
      if (this.yValueUnsuccessfulDeposit) {
        let text0 = null
        let text1 = generateTextOfAverageUnsuccessfulDepositsLastSomeDays(7)
        if (
          calculateGrowPercentage(
            this.yValueUnsuccessfulDeposit[1],
            this.yValueUnsuccessfulDeposit[0]
          ) == 0
        ) {
          text1 += this.kerdarLang.general.unchangedWithTag
        } else if (
          calculateGrowPercentage(
            this.yValueUnsuccessfulDeposit[1],
            this.yValueUnsuccessfulDeposit[0]
          ) > 500
        ) {
          text1 += this.kerdarLang.general.moreThan500PercentWithTag
        } else {
          text1 += `<span style='color:#D44D42'> ${Math.abs(
            calculateGrowPercentage(
              this.yValueUnsuccessfulDeposit[1],
              this.yValueUnsuccessfulDeposit[0]
            )
          )}٪ `
          if (
            calculateGrowPercentage(
              this.yValueUnsuccessfulDeposit[1],
              this.yValueUnsuccessfulDeposit[0]
            ) >= 0
          ) {
            text1 += this.kerdarLang.general.itHasBeenMoreWithTag
          } else {
            text1 += this.kerdarLang.general.itHasBeenLessWithTag
          }
        }

        let text2 = generateTextOfAverageUnsuccessfulDepositsLastSomeDays(30)
        if (
          calculateGrowPercentage(
            this.yValueUnsuccessfulDeposit[2],
            this.yValueUnsuccessfulDeposit[0]
          ) == 0
        ) {
          text2 += this.kerdarLang.general.unchangedWithTag
        } else if (
          calculateGrowPercentage(
            this.yValueUnsuccessfulDeposit[2],
            this.yValueUnsuccessfulDeposit[0]
          ) > 500
        ) {
          text2 += this.kerdarLang.general.moreThan500PercentWithTag
        } else {
          text2 += `<span style='color:#D44D42'> ${Math.abs(
            calculateGrowPercentage(
              this.yValueUnsuccessfulDeposit[2],
              this.yValueUnsuccessfulDeposit[0]
            )
          )}٪ `
          if (
            calculateGrowPercentage(
              this.yValueUnsuccessfulDeposit[2],
              this.yValueUnsuccessfulDeposit[0]
            ) >= 0
          ) {
            text2 += this.kerdarLang.general.itHasBeenMoreWithTag
          } else {
            text2 += this.kerdarLang.general.itHasBeenLessWithTag
          }
        }
        return [text0, text1, text2]
      } else {
        return ["", "", ""]
      }
    },
  },
  mounted() {
    this.getUnsuccessfulDepositData()
  },
  methods: {
    async getUnsuccessfulDepositData() {
      const response = await kerdarServices(
        this.$http,
        this
      ).paymentForm.httpFormUnsuccessfulDepositsAverage()

      const data = response.data.data
      this.unsuccessfulDepositData = data[0].map(this.convertToNumber)
      this.unsuccessfulDepositStatus = true
    },
    convertToNumber(num) {
      return Number(num)
    },
  },
}
</script>

<style scoped></style>
