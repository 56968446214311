<template>
  <v-flex style="display: flex">
    <span style="margin-left: 5px" />
    {{ item.withdrawBankName }}
    <v-img size="20" width="20" height="20" :src="item.bankImg" />
  </v-flex>
</template>

<script>
export default {
  props: ["item"],
}
</script>

<style lang="scss" scoped>
.v-image {
  display: inline-block;
  margin-right: 10px;
  max-width: 20px;
}
</style>
