<template>
  <v-card v-if="load" class="multi-box">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">{{ form.title }}</v-toolbar-title>
      <v-spacer />
      <v-menu
        transition="slide-y-transition"
        offset-y
        close-on-content-click
        bottom
        content-class="up_menu"
      >
        <!-- this on slot sometime is undefined -->
        <template #activator="{ on }">
          <v-btn icon size="24" fab small v-on="on">
            <v-icon color="van_color03" size="21" style="line-height: 40px">
              more_vert
            </v-icon>
          </v-btn>
        </template>

        <v-card class="drop_down_menu">
          <v-list class="menu_v_list">
            <v-list-item
              :to="load ? '//' + form.form_link.substring(7) : '/'"
              target="_blank"
            >
              <v-list-item-content>
                <v-list-item-title>نمایش فرم</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-role-permission:not="['observer', 'developer']"
              @click="open_edit()"
            >
              <v-list-item-content>
                <v-list-item-title>ویرایش فرم درخواست</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="form.active"
              v-role-permission:not="['observer', 'developer']"
              @click="suspendStatus = true"
            >
              <v-list-item-content>
                <v-list-item-title>تعلیق فرم</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else-if="!form.active"
              v-role-permission:not="['observer', 'developer']"
              @click="suspendForm(true)"
            >
              <v-list-item-content>
                <v-list-item-title>فعال کردن فرم</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-tile @click="open_edit()">
                <v-list-tile-content>
                  <v-list-tile-title>پایان فرم پرداخت</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>-->
            <v-list-item
              v-role-permission:not="['observer', 'developer']"
              @click="deleteStatus = true"
            >
              <v-list-item-content>
                <v-list-item-title>حذف فرم</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar>

    <v-layout column>
      <v-layout class="modal-boxes">
        <v-flex
          :class="form.active ? '' : 'gray_back'"
          :xs4="
            form.goal.amount !== 0 && form.goal.amount !== null ? true : false
          "
          :xs6="
            form.goal.amount !== 0 && form.goal.amount !== null ? false : true
          "
        >
          <v-layout column height="100%" align-center justify-center>
            <v-flex style="max-height: 36px">
              {{ form.transactions_count | thousandSeprator }}
            </v-flex>
            <v-flex style="max-height: 30px"> تراکنش </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          :class="form.active ? '' : 'gray_back'"
          :xs4="
            form.goal.amount !== 0 && form.goal.amount !== null ? true : false
          "
          :xs6="
            form.goal.amount !== 0 && form.goal.amount !== null ? false : true
          "
        >
          <v-layout column height="100%" align-center justify-center>
            <v-flex style="max-height: 36px">
              {{ form.transactions_sum | currency }}
            </v-flex>
            <v-flex style="max-height: 30px">
              {{ currencyName }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="form.goal.amount !== 0 && form.goal.amount !== null"
          :class="form.active ? '' : 'gray_back'"
          xs4
        >
          <v-layout column height="100%" align-center justify-center>
            <v-flex style="max-height: 36px"> {{ form.goal.percent }}% </v-flex>
            <v-flex style="max-height: 30px"> تکمیل هدف </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout class="modal-hint" align-center>
        <v-flex>
          <v-card flat style="padding: 40px !important">
            <div
              :class="[
                'row_number d-flex mr-0 ml-3',
                form.active ? 'green_back' : 'gray_back',
              ]"
            >
              <v-img
                v-if="form.transactions_sum"
                max-width="14"
                max-height="14"
                class="ma-auto"
                :src="require('@/assets/img/Ascending.svg')"
              />
              <v-img
                v-else
                max-width="14"
                max-height="14"
                class="ma-auto"
                :src="require('@/assets/img/line.svg')"
              />
            </div>
            <div class="font_2">
              <span v-if="form.active">فرم پرداخت فعال است.</span>
              <span v-if="!form.active">فرم پرداخت غیرفعال است.</span>
              <span v-if="form.transactions_count && !fromReports">
                جهت مشاهده
                <div class="linkBorder">
                  <v-btn
                    v-if="$store.state.isUser"
                    class="small"
                    text
                    :to="{
                      name: 'report',
                      params: {
                        filtered: 'successfullTransactionRequestMoney',
                        formId: form.id,
                      },
                    }"
                    :ripple="false"
                    >لیست واریزها
                  </v-btn>
                  <v-btn
                    v-else
                    class="small"
                    text
                    :to="{
                      name: 'businessReport',
                      params: {
                        filtered: 'successfullTransactionRequestMoney',
                        formId: form.id,
                      },
                    }"
                    :ripple="false"
                    >لیست واریزها
                  </v-btn>
                </div>
                کلیک کنید
              </span>
            </div>
          </v-card>
        </v-flex>
      </v-layout>

      <v-flex>
        <v-layout column class="rows">
          <v-flex>
            <v-layout wrap align-center>
              <v-flex
                class="label"
                :style="
                  $vuetify.breakpoint.xsOnly ? 'width:100%;' : 'width:110px;'
                "
              >
                لینک فرم
              </v-flex>
              <v-flex style="text-decoration: underline">
                <div class="linkBorder">
                  <a @click="openLink(form.form_link)">{{
                    form.form_link.substring(7)
                  }}</a>
                </div>
              </v-flex>
              <v-flex style="max-width: 80px; position: relative">
                <CopyBtnGeneral
                  color-btn="van_color11"
                  label="کپی لینک"
                  :value="form.form_link"
                  :input-id="'copy_' + form.id"
                />
              </v-flex>
              <input
                id="hidden-input"
                v-model="form.form_link"
                class="hidden-input"
              />
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout :column="$vuetify.breakpoint.xsOnly" align-start>
              <v-flex class="label"> مبلغ ثابت </v-flex>
              <v-flex v-if="form.price == 0"> دلخواه </v-flex>
              <v-flex v-else>
                {{ form.price | currency }} {{ currencyName }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="form.expire_date.year !== null">
            <v-layout :column="$vuetify.breakpoint.xsOnly" align-start>
              <v-flex class="label"> انقضای فرم </v-flex>
              <v-flex>
                {{ form.expire_date.day }}
                {{ monthName[form.expire_date.month - 1] }}
                {{ form.expire_date.year }}
                ساعت
                {{ form.expire_date.minute }}
                :
                {{ form.expire_date.hour }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="form.goal.amount !== 0 && form.goal.amount !== null">
            <v-layout :column="$vuetify.breakpoint.xsOnly" align-start>
              <v-flex class="label"> هدف </v-flex>
              <v-flex>
                {{ form.goal.amount | currency }}
                {{ currencyName }}
                <span v-if="!form.goal.show">(نمایش خصوصی)</span>
                <span v-else>(نمایش عمومی)</span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="form.description !== null">
            <v-layout :column="$vuetify.breakpoint.xsOnly" align-start>
              <v-flex class="label"> توضیحات </v-flex>
              <v-flex style="max-width: 340px">{{ form.description }}</v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="
              form.mobile.active ||
              form.address.active ||
              form.phone.active ||
              form.email.active ||
              (form.additianl_fields != null &&
                form.additianl_fields.length != 0 &&
                form.additianl_fields.find((x) => x.active))
            "
          >
            <v-layout :column="$vuetify.breakpoint.xsOnly" align-start>
              <v-flex class="label"> اطلاعات بیشتر </v-flex>
              <div>
                <div v-if="form.mobile.active" class="mb-2">
                  تلفن همراه
                  <span v-if="form.mobile.mandatory">(الزامی)</span>
                  <span v-else>(اختیاری)</span>
                </div>
                <div v-if="form.address.active" class="mb-2">
                  آدرس
                  <span v-if="form.address.mandatory">(الزامی)</span>
                  <span v-else>(اختیاری)</span>
                </div>
                <div v-if="form.phone.active" class="mb-2">
                  تلفن ثابت
                  <span v-if="form.phone.mandatory">(الزامی)</span>
                  <span v-else>(اختیاری)</span>
                </div>
                <div v-if="form.email.active">
                  ایمیل
                  <span v-if="form.email.mandatory">(الزامی)</span>
                  <span v-else>(اختیاری)</span>
                </div>
                <div
                  v-for="(field, index) in form.additianl_fields"
                  :key="index"
                >
                  <div v-if="field.active" class="mb-2">
                    {{ field.name }}
                    <span v-if="field.mandatory">(الزامی)</span>
                    <span v-else>(اختیاری)</span>
                  </div>
                </div>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status1"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <add
        v-if="status1"
        :id="id"
        type="edit"
        :form="form"
        @closeModal="close_modal()"
        @updateForm="update_form"
      />
    </v-dialog>

    <general-dialog
      :active="suspendStatus"
      title="از تعلیق فرم اطمینان دارید؟"
      content-class="confirmationMessageDialog"
      description="فرم غیرفعال شده و تا فعال شدن مجدد، امکان پرداخت توسط آن وجود ندارد"
      btn1-text="خیر، تعلیق نشود"
      btn2-text="بلی، تعلیق شود"
      @btn1Clicked="suspendStatus = false"
      @btn2Clicked="suspendForm(false)"
    >
    </general-dialog>

    <v-dialog
      v-model="deleteStatus"
      width="360"
      content-class="confirmationMessage confirmSimple"
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex class="main-title font_1"> از حذف فرم اطمینان دارید؟ </v-flex>
          <v-flex class="main-des font_4">
            امکان بازگشت مجدد این فرم برای شما وجود ندارد
          </v-flex>
          <v-flex>
            <v-btn
              text
              dark
              class="btn_small_normal"
              @click="deleteStatus = false"
            >
              خیر، حذف نشود
            </v-btn>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="deleteForm()"
            >
              بلی، حذف شود
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import add from "./add"
import CopyBtnGeneral from "@/components/elements/copyBtnGeneral"
import GeneralDialog from "@/components/modals/generalDialog.vue"

export default {
  name: "Show",
  components: {
    GeneralDialog,
    CopyBtnGeneral,
    add,
  },
  props: {
    id: { default: 1 },
    fromReports: { default: false },
  },
  data: function () {
    return {
      load: false,
      status1: false,
      form: {},
      monthName: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      tooltip_show: false,
      suspendStatus: false,
      deleteStatus: false,
    }
  },
  computed: {
    dayItem() {
      var temp = []
      for (var i = 0; i < this.end; i++) {
        temp[i] = i + 1
      }
      return temp
    },
    yearItem() {
      var d = new Date()
      var n = d.getFullYear()
      var b = n - 621
      var temp = []
      for (var i = 0; i < 3; i++) {
        temp[i] = b + i
      }
      return temp
    },
  },
  watch: {
    status1: function (newValue) {
      if (!newValue) this.$store.commit("callEditFormModal", false)
    },
  },
  mounted() {
    this.$http
      .get(this.$store.getters.apiUrlParam + "/request-forms/" + this.id, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + this.$store.state.token,
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.form = response.data.data.form
        this.load = true
      })
      .catch((error) => {
        //callsnackbar
      })
  },
  methods: {
    openLink(link) {
      window.open(link)
    },
    open_edit() {
      this.$store.commit("callEditFormModal", true)
      this.status1 = true
    },
    close_modal() {
      this.$store.commit("callEditFormModal", false)
      this.status1 = false
    },
    update_form(form) {
      this.form = form
      this.$emit("updateFormList", form)
    },
    suspendForm(value) {
      var data = {
        active: value,
      }
      this.$http
        .post(
          this.$store.getters.apiUrlParam +
            "/request-forms/" +
            this.id +
            "/update/active",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$emit("updateFormList", response.data.data.form)
          this.$emit("closeModal")
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    deleteForm() {
      this.$http
        .delete(this.$store.getters.apiUrlParam + "/request-forms/" + this.id, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$emit("closeModal")
          this.$emit("deleteForm", this.id)
        })
        .catch((error) => {
          //callsnackbar)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-hint {
  margin: -2px 0 -20px;

  > .flex {
    max-width: 100%;
  }

  .v-card {
    display: flex;
    align-items: center;
    background-color: #ffffff !important;
    border-radius: 4px !important;
    padding: 20px !important;
    width: 100%;
  }

  .v-image {
    margin-left: 20px;
    max-width: 46px;
  }

  .linkBorder {
    margin: 0 4px;
  }
}
</style>
