<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="closeModal">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">
        بارگذاری کارت ملی
        {{
          $store.state.business.business_type == "personal"
            ? "صاحب کسب‌و‌کار"
            : "مدیر عامل"
        }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout class="modal-container">
      <div class="main-card-container w-100">
        <v-card outlined class="d-flex main-card pa-0 mb-4" flat>
          <div
            class="d-flex align-center justify-center flex-column"
            style="background: #eef6f3; width: 80px; height: 144px"
          >
            <v-img
              width="48px"
              height="49px"
              max-height="49px"
              max-width="48px"
              :src="require('@/assets/img/guide-icon-second.svg')"
            />
            <span class="font_28">راهنما</span>
          </div>
          <ul class="d-flex flex-column justify-center mr-4 font-12-no-000-24">
            <li>واضح و رنگی باشد</li>
            <li>عکس کارت ملی جدید را بارگذاری کنید. (اسکن نباشد)</li>
            <li>
              اگر عکس کارت ملی ندارید، رسید کارت ملی و صفحه اول شناسنامه را در
              یک فایل بارگذاری کنید.
            </li>
          </ul>
        </v-card>
        <v-card outlined class="main-card" flat>
          <v-radio-group v-model="radioBtn" class="simpleRadio">
            <v-radio
              class="mr-0 enamad_radio text-right"
              label="کارت ملی جدید را ارسال می‌کنم"
              :value="'newNationalCard'"
              color="van_green"
            />
            <v-radio
              class="mr-0 enamad_radio text-right"
              label="رسید کارت ملی و صفحه اول شناسنامه را ارسال می‌کنم"
              :value="'nationalCardReceipt'"
              color="van_green"
            />
          </v-radio-group>
        </v-card>
        <template v-if="radioBtn != ''">
          <UploadBox2
            :is-width100="true"
            :is-reset-uploaded-file="isResetUploadedFile"
            @reset-is-done="isResetUploadedFile = false"
            @uploaded-file="uploadedFile = $event"
          />
        </template>
      </div>
    </v-layout>
  </v-card>
</template>

<script>
import UploadBox2 from "@/components/elements/UploadBox2"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "AddNationalCardPhoto",
  components: {
    UploadBox2,
  },
  data() {
    return {
      loading: false,
      radioBtn: "newNationalCard",
      uploadedFile: "",
      isResetUploadedFile: false,
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.uploadedFile !== "") return true
      else return false
    },
  },
  watch: {
    radioBtn() {
      if (this.uploadedFile) {
        this.uploadedFile = ""
        this.isResetUploadedFile = true
      }
    },
  },
  methods: {
    sendForm() {
      if (this.uploadedFile !== "") {
        let data = {
          national_card_photo: this.uploadedFile,
        }

        servicesRepository(this)
          .onBoardingService.httpUpload(data)
          .then((response) => {
            this.$store.commit("setBusiness", response.data.data)
            this.loading = false
            this.closeModal()
            this.$emit("handle-success-state")
          })
          .catch((error) => {
            this.loading = false
          })
      }
    },
    closeModal() {
      this.$store.commit("callAddDocumentmodal", false)
      this.$emit("closeModal")
    },
  },
}
</script>
<style lang="scss" scoped>
.v-label {
  font-size: 12px !important;
}

ul {
  list-style-position: inside;
  list-style-image: url("@/assets/img/bullet.svg");
}
</style>
