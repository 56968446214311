<template>
  <div
    :column="$vuetify.breakpoint.xsOnly"
    class="mt-2 ml-2 py-3 px-3 balance-card d-flex align-center"
    :style="{ 'min-height': minHeight + 'px' }"
  >
    <v-layout column>
      <v-flex>
        <v-layout>
          <v-layout
            v-if="balanceAmount != null"
            class="d-flex font_2 ma-0"
            :class="{ 'flex-column align-start': reuseIsMobile }"
          >
            <v-flex class="d-flex justify-start">
              {{ title }}
              {{ balanceAmount | currency }}
              {{ currencyName }} {{ extraText }}
            </v-flex>
            <v-flex v-if="selectBtn" style="text-align-last: left !important">
              <div class="linkBorder d-inline-flex ml-2">
                <v-btn
                  v-if="balanceAmount > 0"
                  class="small"
                  text
                  :ripple="false"
                  @click="$emit('onSelectClick')"
                >
                  انتخاب
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
          <!-- skeleton -->
          <v-flex v-else class="label ma-0">
            <span class="default_gray ma-1" />
          </v-flex>
          <!-- skeleton  ends here-->
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import screenSize from "@/mixins/screenSize"

export default {
  mixins: [screenSize],
  props: ["balanceAmount", "minHeight", "selectBtn", "title", "extraText"],
}
</script>
<style lang="scss" scoped>
.default_gray {
  width: 190px !important ;
}

.balance-card {
  background: #f5f5f5;
  width: 352px;
  margin-right: 97px;
  border-radius: 4px;
}

@media screen and (max-width: 598px) {
  .balance-card {
    width: 100%;
    margin-right: 0;
  }
}
</style>
