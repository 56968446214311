<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close</v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> فرم پرداخت</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          v-if="type === 'edit'"
          text
          dark
          class="btn_small_normal"
          :disabled="!valid"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره تغییرات
        </v-btn>
        <v-btn
          v-else
          text
          dark
          class="btn_small_normal"
          :disabled="!valid"
          :loading="loading"
          @click="sendForm()"
        >
          ساخت فرم
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-card flat>
          <v-toolbar flat height="60">
            <v-toolbar-title class="pr-0"> اطلاعات پایه فرم</v-toolbar-title>
          </v-toolbar>

          <v-layout>
            <v-flex>
              <v-form ref="form" v-model="valid">
                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-space-between
                  align-start
                >
                  <v-flex class="label" style="width: 110px">
                    عنوان فرم
                    <span>*</span>
                  </v-flex>
                  <v-flex class="withlabel big withcounter">
                    <v-text-field
                      v-model="title"
                      :counter="30"
                      :rules="titleRules"
                      solo
                      flat
                      height="40"
                      maxlength="30"
                      outlined
                    >
                      <template #message="message">
                        <div v-html="message.message" />
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-space-between
                  align-start
                  class="char"
                >
                  <v-flex class="label"> توضیحات</v-flex>
                  <v-flex class="withlabel big">
                    <v-textarea
                      v-model="description"
                      :counter="80"
                      solo
                      flat
                      rows="3"
                      row-height="24"
                      outlined
                      no-resize
                      maxlength="80"
                    >
                      <template #message="message">
                        <div v-html="message.message" />
                      </template>
                    </v-textarea>
                  </v-flex>
                </v-layout>

                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-space-between
                  align-start
                >
                  <v-flex class="label"> مبلغ ثابت</v-flex>
                  <v-flex class="withlabel big">
                    <v-text-field
                      ref="code"
                      v-model="thousandSeprator"
                      :rules="mablaghRules"
                      flat
                      height="40"
                      outlined
                      :suffix="currencyName"
                      hint="اگر مبلغ را خالی بگذارید، پرداخت کننده می‌تواند مبلغ دلخواهش را واریز کند"
                      persistent-hint
                      @focusin="showRepetitiveAmount = true"
                      @keydown="numberKeyHandler"
                      @input="makeMablaghZero()"
                    >
                      <template #message="message">
                        <div v-html="message.message" />
                      </template>
                    </v-text-field>
                    <div v-if="showRepetitiveAmount">
                      <v-radio-group
                        class="priceGroup"
                        row
                        @change="setPrice($event)"
                      >
                        <v-radio
                          v-for="(item, index) in repetitiveAmount"
                          :key="'price' + index"
                          :label="repetitiveLabel(item.price)"
                          :value="item.price | currency"
                          class="d-flex flex mr-0 mb-2"
                        />
                        <!--                        <template #item="data">-->
                        <!--                          {{ data }} {{ currencyName }}</template-->
                        <!--                        >-->
                      </v-radio-group>
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-space-between
                  align-start
                >
                  <v-flex class="label"> دریافت کارمزد</v-flex>
                  <v-flex class="withlabel big">
                    <v-radio-group v-model="wage" row>
                      <v-radio label="از خودم" value="1" />
                      <v-radio label="از پرداخت‌کننده" value="2" />
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <div
                  class="d-flex van_color11 px-5 py-3 radius-4 mt-8 align-center justify-space-between"
                  :class="{
                    column: $vuetify.breakpoint.xsOnly,
                  }"
                >
                  <div class="font-size-12 black--text">
                    <span v-if="active"> فرم فعال است.</span>
                    <span v-else>فرم غیرفعال است.</span>
                  </div>
                  <div>
                    <v-switch
                      v-model="active"
                      style="width: fit-content"
                      class="van-switch pt-0"
                      color="van_green"
                      hide-details
                    />
                  </div>
                </div>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <!--  advanced setting    -->
      <v-flex class="info_panel mb-5">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0">
              <div class="font_2" style="margin-bottom: 0">تنظیمات پیشرفته</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5 pb-8">
              <v-layout>
                <v-flex>
                  <v-layout column class="multi-field-wrapper">
                    <v-flex class="d-flex align-center">
                      <v-checkbox
                        v-model="oneTimeUse.active"
                        color="van_green"
                        class="withTooltip big ma-0"
                        :disabled="!price"
                        label="پس از اولین پرداخت، فرم منقضی شود. (فرم یکبار مصرف)"
                      />
                      <v-tooltip v-if="!price" bottom>
                        <template #activator="{ on }">
                          <v-icon size="20" class="mr-2" v-on="on">
                            mdi_info_outline
                          </v-icon>
                        </template>
                        <span>
                          پس از وارد کردن مبلغ ثابت، این گزینه فعال می‌شود.
                        </span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                  <v-layout column class="multi-field-wrapper">
                    <v-flex>
                      <v-checkbox
                        v-model="goal.active"
                        color="van_green"
                        class="big"
                        :disabled="goal.disabled"
                        label="وقتی مقدار پول دریافت شده به مبلغ خاصی رسید، جمع آوری پول متوقف شود"
                      />
                    </v-flex>
                    <v-flex v-if="goal.active">
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        justify-space-between
                        align-start
                        class="multi-field"
                      >
                        <v-flex
                          class="label"
                          :style="
                            $vuetify.breakpoint.xsOnly
                              ? 'min-width:80px;width:80px;margin-bottom:10px;'
                              : 'width:140px;'
                          "
                        >
                          مقدار مبلغ هدف
                        </v-flex>
                        <v-flex
                          :style="
                            $vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''
                          "
                          class="rightAlignInput"
                        >
                          <!-- v-model="goal.amount" -->
                          <v-text-field
                            ref="code1"
                            v-model="thousandSeprator1"
                            flat
                            height="40"
                            outlined
                            :suffix="currencyName"
                            @keydown="numberKeyHandler"
                          >
                            <template #message="message">
                              <div v-html="message.message" />
                            </template>
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          :style="
                            $vuetify.breakpoint.xsOnly
                              ? 'width: 100%;margin:10px 0px;'
                              : 'min-width: 160px;width: 160px;'
                          "
                        >
                          <v-select
                            v-model="goal.show"
                            :items="selectItems2"
                            solo
                            flat
                            height="40"
                            outlined
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout column class="multi-field-wrapper">
                    <v-flex>
                      <v-checkbox
                        v-model="expire.active"
                        color="van_green"
                        class="big"
                        label="جمع‌آوری پول تا تاریخ مشخصی انجام شود (بعد از آن فرم بسته می‌شود)"
                      />
                    </v-flex>
                    <v-flex v-if="expire.active" class="mt-0 pr-8">
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        justify-start
                        align-start
                        class="multi-field"
                      >
                        <v-flex>
                          <v-radio-group
                            v-model="expire_date_type"
                            class="simpleRadio mr-0"
                            column
                          >
                            <v-radio
                              class="mr-0"
                              color="van_green"
                              label="در تاریخ مشخص"
                              value="beforeDate"
                            />
                            <v-layout
                              v-if="expire_date_type === 'beforeDate'"
                              class="withlabel date_group date_group_form mb-4 mt-1"
                              style="max-width: 226px"
                            >
                              <v-flex>
                                <v-select
                                  v-model="expire.date.day"
                                  :items="dayItem"
                                  solo
                                  flat
                                  height="40"
                                  outlined
                                  label="روز"
                                  :validate-on-blur="alreadyFullday"
                                />
                              </v-flex>
                              <v-flex>
                                <v-select
                                  v-model="expire.date.month"
                                  :items="monthItem"
                                  solo
                                  flat
                                  height="40"
                                  outlined
                                  label="ماه"
                                  :validate-on-blur="alreadyFullmonth"
                                  @input="changeEndDay()"
                                />
                              </v-flex>
                              <v-flex>
                                <v-select
                                  v-model="expire.date.year"
                                  :items="yearItem"
                                  solo
                                  flat
                                  height="40"
                                  outlined
                                  label="سال"
                                  :validate-on-blur="alreadyFullyear"
                                />
                              </v-flex>
                            </v-layout>
                            <v-radio
                              class="mr-0"
                              color="van_green"
                              label="در زمان مشخص بعد از ساخت فرم"
                              value="afterTime"
                            />
                            <v-layout
                              v-if="expire_date_type === 'afterTime'"
                              class="withlabel date_group date_group_form mb-4 mt-1"
                              style="max-width: 148px"
                            >
                              <v-flex>
                                <v-text-field
                                  v-model="expireAfter"
                                  validate-on-blur
                                  solo
                                  flat
                                  height="40"
                                  outlined
                                  @keydown="numberKeyHandler($event)"
                                >
                                  <template #message="message">
                                    <div v-html="message.message" />
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex>
                                <v-select
                                  v-model="expireAfterType"
                                  :items="afterTime"
                                  solo
                                  flat
                                  height="40"
                                  outlined
                                />
                              </v-flex>
                            </v-layout>
                          </v-radio-group>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>

      <v-flex class="info_panel">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0">
              <div class="font_2" style="margin-bottom: 0">
                دریافت اطلاعات بیشتر از پرداخت کننده
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5 pb-8">
              <v-layout column justify-start class="moreData">
                <v-flex>
                  <v-layout justify-space-between wrap class="formSetting">
                    <v-flex>
                      <v-checkbox
                        v-model="mobile.active"
                        color="van_green"
                        label="تلفن همراه"
                      />
                    </v-flex>
                    <v-flex>
                      <v-select
                        v-if="mobile.active"
                        v-model="mobile.mandatory"
                        :items="selectItems"
                        solo
                        flat
                        height="40"
                        outlined
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-divider class="grayDashedHr" />
                <v-flex>
                  <v-layout justify-space-between wrap class="formSetting">
                    <v-flex>
                      <v-checkbox
                        v-model="address.active"
                        color="van_green"
                        label="آدرس"
                      />
                    </v-flex>
                    <v-flex>
                      <v-select
                        v-if="address.active"
                        v-model="address.mandatory"
                        :items="selectItems"
                        solo
                        flat
                        height="40"
                        outlined
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-divider class="grayDashedHr" />
                <v-flex>
                  <v-layout justify-space-between wrap class="formSetting">
                    <v-flex>
                      <v-checkbox
                        v-model="phone.active"
                        color="van_green"
                        label="تلفن ثابت"
                      />
                    </v-flex>
                    <v-flex>
                      <v-select
                        v-if="phone.active"
                        v-model="phone.mandatory"
                        :items="selectItems"
                        solo
                        flat
                        height="40"
                        outlined
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-divider class="grayDashedHr" />
                <v-flex
                  :style="$store.state.isUser ? 'margin-bottom: 30px;' : ''"
                >
                  <v-layout justify-space-between wrap class="formSetting">
                    <v-flex>
                      <v-checkbox
                        v-model="email.active"
                        color="van_green"
                        label="ایمیل"
                      />
                    </v-flex>
                    <v-flex>
                      <v-select
                        v-if="email.active"
                        v-model="email.mandatory"
                        :items="selectItems"
                        solo
                        flat
                        height="40"
                        outlined
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-divider v-if="!$store.state.isUser" class="grayDashedHr" />
                <!-- Add optional field -->
                <v-flex v-for="(field, index) in optionalField" :key="index">
                  <v-layout justify-space-between wrap class="formSetting">
                    <v-flex>
                      <v-checkbox
                        v-model="field.active"
                        color="van_green"
                        :label="field.name"
                      />
                    </v-flex>
                    <v-flex>
                      <v-select
                        v-if="field.active"
                        v-model="field.mandatory"
                        :items="selectItems"
                        solo
                        flat
                        height="40"
                        outlined
                      />
                    </v-flex>
                  </v-layout>
                  <v-divider
                    class="grayDashedHr"
                    style="margin-top: 10px !important"
                  />
                </v-flex>
                <!-- End Of optional field -->
              </v-layout>

              <v-layout
                v-if="!addNewFiled && !$store.state.isUser"
                class="pt-0 pb-7"
              >
                <v-flex>
                  <v-btn
                    text
                    depressed
                    outlined
                    class="btn_small_normal"
                    @click="addNewFiled = true"
                  >
                    <v-icon style="margin: 0 -5px 0 5px" size="20">
                      add
                    </v-icon>
                    افزودن مورد دلخواه
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout
                v-else-if="addNewFiled && !$store.state.isUser"
                class="van_color11 pa-5 radius-4"
                align-center
                wrap
              >
                <v-flex class="label"> عنوان مورد نظر</v-flex>
                <v-flex class="withlabel withcounter">
                  <v-text-field
                    v-model="currentAddingField"
                    :counter="35"
                    solo
                    flat
                    height="40"
                    outlined
                    placeholder="مثل فکس، کدملی و ..."
                  >
                    <template #message="message">
                      <div v-html="message.message" />
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex style="max-width: 90px; min-width: 90px">
                  <v-btn
                    text
                    dark
                    class="btn_small_normal icon_btn"
                    :disabled="!disableAdditionalField"
                    @click="addITToAdditionalField()"
                  >
                    <v-icon>check</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    depressed
                    outlined
                    class="btn_small_normal icon_btn"
                    @click="addNewFiled = false"
                  >
                    <v-icon> close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
function formatAsCurrency(value, dec) {
  dec = dec || 0
  if (value === null) {
    return null
  }
  return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}

const jmoment = require("moment-jalaali")
export default {
  name: "AddRequestMoney",
  props: ["id", "type", "form"],
  data: function () {
    return {
      addNewFiled: false,
      currentAddingField: null,
      newField: null,
      optionalField: [],
      valid: false,
      loading: false,
      wage: "1",
      title: "",
      showRepetitiveAmount: true,
      description: "",
      price: null, //v-model of price
      mobile: {
        active: false,
        mandatory: "تکمیل این مورد اختیاری است",
      },
      address: {
        active: false,
        mandatory: "تکمیل این مورد اختیاری است",
      },
      phone: {
        active: false,
        mandatory: "تکمیل این مورد اختیاری است",
      },
      email: {
        active: false,
        mandatory: "تکمیل این مورد اختیاری است",
      },
      goal: {
        active: false,
        amount: null, //v-model of amount
        show: "در فرم نمایش داده شود",
        disabled: false,
      },
      oneTimeUse: {
        active: false,
      },
      expire: {
        active: false,
        date: {
          day: null,
          month: null,
          year: null,
          hour: "00",
          minute: "00",
        },
      },
      selectItems: ["تکمیل این مورد الزامی است", "تکمیل این مورد اختیاری است"],
      selectItems2: ["در فرم نمایش داده شود", "در فرم نمایش داده نشود"],
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      end: 31,
      repetitiveAmount: [],
      alreadyFullday: true,
      alreadyFullmonth: true,
      alreadyFullyear: true,
      convertMonth: null,
      titleRules: [(v) => !!v || "<span> نام فرم را وارد کنید </span>"],
      expire_date_type: "beforeDate",
      afterTime: ["ساعت", "دقیقه"],
      expireAfterType: "ساعت",
      expireAfter: null,
      changeTime: false,
      active: true,
    }
  },
  computed: {
    disableAdditionalField() {
      return (
        this.currentAddingField !== null &&
        this.currentAddingField.length >= 3 &&
        this.currentAddingField.length <= 35
      )
    },
    mablaghRules() {
      if (this.$store.state.user.currency === "RIAL")
        return [
          (v) =>
            (!!v &&
              !isNaN(v.replace(/,/g, "")) &&
              v.replace(/\D/g, "") <= 1000000000) ||
            v === null ||
            "<span> مبلغ نباید بیشتر از یک میلیارد ریال باشد </span>",
        ]
      else
        return [
          (v) =>
            (!!v &&
              !isNaN(v.replace(/,/g, "")) &&
              v.replace(/\D/g, "") <= 100000000) ||
            v === null ||
            "<span> مبلغ نباید بیشتر از ۱۰۰ میلیون تومان باشد </span>",
        ]
    },
    disable() {
      return !this.loading && this.valid
    },
    thousandSeprator: {
      get: function () {
        return formatAsCurrency(this.price, 0)
      },
      set: function (newValue) {
        if (newValue) {
          this.price = Number(
            this.convertNumbers2English(newValue).replace(/[^0-9.]/g, "")
          )
        } else {
          this.price = null
        }
      },
    },

    thousandSeprator1: {
      get: function () {
        return formatAsCurrency(this.goal.amount, 0)
      },
      set: function (newValue) {
        if (newValue) {
          this.goal.amount = Number(
            this.convertNumbers2English(newValue).replace(/[^0-9.]/g, "")
          )
        } else {
          this.goal.amount = null
        }
      },
    },
    dayItem() {
      let temp = []
      for (let i = 0; i < this.end; i++) {
        temp[i] = i + 1
      }
      return temp
    },
    yearItem() {
      let currentYear = jmoment(new Date()).format("jYYYY").toString()
      let temp = []
      for (let i = 0; i < 3; i++) {
        temp[i] = parseInt(currentYear) + i
      }
      return temp
    },
  },
  watch: {
    "oneTimeUse.active": function (newValue) {
      if (newValue === true) {
        this.goal.active = false
        this.goal.disabled = true
      } else this.goal.disabled = false
    },
    expireAfter: function (newValue) {
      if (newValue) {
        this.changeTime = true
        this.expireAfter = this.convertNumbers2English(newValue.toString())
      }
    },
    expire_date_type: function (newValue) {
      if (newValue === "beforeDate") {
        this.expire.date.hour = "00"
        this.expire.date.minute = "00"
        this.expireAfter = null
      }
    },
    price: function (newVal) {
      if (!newVal) {
        //if price is empty, uncheck one time payment option
        this.oneTimeUse.active = false
      }
    },
  },
  mounted() {
    this.getRepetitivePrices()

    if (!this.$store.state.isUser) {
      this.getAdditionalField()
    }
    if (this.form && this.type === "edit") {
      this.wage = this.form.wage_type ? "1" : "2"
      this.title = this.form.title
      this.description = this.form.description
      this.active = this.form.active
      if (this.form.price) {
        if (this.$store.state.user.currency === "RIAL")
          this.thousandSeprator = this.form.price.toString() + "0"
        else this.thousandSeprator = this.form.price.toString()
      } else {
        this.thousandSeprator = 0
      }
      this.mobile = {
        active: this.form.mobile.active,
        mandatory: this.form.mobile.mandatory
          ? "تکمیل این مورد الزامی است"
          : "تکمیل این مورد اختیاری است",
      }
      this.address = {
        active: this.form.address.active,
        mandatory: this.form.address.mandatory
          ? "تکمیل این مورد الزامی است"
          : "تکمیل این مورد اختیاری است",
      }
      this.phone = {
        active: this.form.phone.active,
        mandatory: this.form.phone.mandatory
          ? "تکمیل این مورد الزامی است"
          : "تکمیل این مورد اختیاری است",
      }
      this.email = {
        active: this.form.email.active,
        mandatory: this.form.email.mandatory
          ? "تکمیل این مورد الزامی است"
          : "تکمیل این مورد اختیاری است",
      }
      this.goal.show = this.form.goal.show
        ? "در فرم نمایش داده شود"
        : "در فرم نمایش داده نشود"
      this.goal.active = this.form.goal.expire
      if (this.form.goal.amount) {
        if (this.$store.state.user.currency === "RIAL")
          this.thousandSeprator1 = this.form.goal.amount.toString() + "0"
        else this.thousandSeprator1 = this.form.goal.amount.toString()
      } else {
        this.thousandSeprator1 = 0
      }
      this.expire.active = this.form.expire_date.expired
      this.expire.date = {
        day: this.form.expire_date.day,
        month: this.monthItem[this.form.expire_date.month - 1],
        year: this.form.expire_date.year,
        hour: this.form.expire_date.hour,
        minute: this.form.expire_date.minute,
      }
      if (
        this.form.expire_date.hour !== "00" &&
        this.form.expire_date.minute !== "00"
      ) {
        this.expire_date_type = "afterTime"
        this.expireAfterType = "دقیقه"
        const today = jmoment(new Date()).format("jYYYY-jMM-jDD HH:mm:ss")
        const expire = jmoment(
          this.form.expire_date.year +
            "-" +
            this.form.expire_date.month +
            "-" +
            this.form.expire_date.day +
            " " +
            this.form.expire_date.hour +
            ":" +
            this.form.expire_date.minute +
            ":00"
        )
        let diff = expire.diff(today, "minutes")
        this.expireAfter = diff > 0 ? diff : 0
      }

      //if form is one time use
      if (this.price && this.goal.amount === this.price) {
        this.oneTimeUse.active = true
      }
    }
  },
  methods: {
    repetitiveLabel(price) {
      return this.$options.filters.currency(price) + " " + this.currencyName
    },
    getRepetitivePrices() {
      this.$http
        .get(
          this.$store.getters.apiUrlParam + "/request-forms/most-used-prices",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          let data = response?.data?.data

          for (let element in data) {
            data[element].price = Math.floor(data[element].price / 10)
          }

          this.repetitiveAmount = data
        })
    },
    setPrice(event) {
      if (event) {
        this.thousandSeprator = event.toString()
        this.showRepetitiveAmount = false
      }
    },
    getAdditionalField() {
      this.$http
        .get(
          this.$store.getters.apiUrlParam + "/request-forms/aditional-fileds",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          response.data.data.forEach((element) => {
            let eachel = {
              name: element.name,
              active: false,
              mandatory: "تکمیل این مورد اختیاری است",
              uuid: element.uuid,
            }
            this.optionalField.push(eachel)

            //fill additional field data
            if (this.form && this.type === "edit") {
              this.form.additianl_fields.forEach((element) => {
                this.optionalField.forEach((el) => {
                  if (element.uuid === el.uuid) {
                    el.active = element.active
                    el.mandatory = element.mandatory
                      ? "تکمیل این مورد الزامی است"
                      : "تکمیل این مورد اختیاری است"
                  }
                })
              })
            }
          })
        })
        .catch(() => {
          //callsnackbar
        })
    },
    addITToAdditionalField() {
      this.newField = {
        name: this.currentAddingField,
        active: false,
        mandatory: "تکمیل این مورد اختیاری است",
        uuid: null,
      }

      let data = {
        name: this.currentAddingField,
      }
      this.$http
        .post(
          this.$store.getters.apiUrlParam + "/request-forms/aditional-fileds",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.newField.uuid = response.data.data.id
          this.optionalField.push(this.newField)
        })
        .catch(() => {
          //callsnackbar
        })
      this.currentAddingField = null
      this.addNewFiled = false
    },
    makeMablaghZero() {},
    changeEndDay() {
      switch (this.expire.date.month) {
        case "مهر":
        case "آبان":
        case "آذر":
        case "دی":
        case "بهمن":
        case "اسفند":
          this.end = 30
          break
        default:
          this.end = 31
          break
      }
      if (this.expire.date.day > this.end) {
        this.expire.date.day = null
      }
    },
    // convertToToman(value) {
    //   if (this.$store.state.user.currency === 'RIAL') {
    //     value = Math.floor(value / 10)
    //     return value
    //   } else return value
    // },
    sendForm() {
      this.monthItem.forEach((element, index) => {
        if (element === this.expire.date.month) {
          this.convertMonth = index + 1
        }
      })
      let additionalFieldData = this.optionalField

      additionalFieldData.forEach((element) => {
        element.mandatory = element.mandatory !== "تکمیل این مورد اختیاری است"

        delete element.name
      })

      if (this.oneTimeUse.active) {
        this.goal.amount = this.price
      }

      let data = {
        wage_type: this.wage == "1",
        title: this.title,
        description: this.description,
        price: this.price == "" || this.price == null ? 0 : this.price,
        active: this.active,
        mobile: {
          active: this.mobile.active,
          mandatory: this.mobile.mandatory !== "تکمیل این مورد اختیاری است",
        },
        address: {
          active: this.address.active,
          mandatory: this.address.mandatory !== "تکمیل این مورد اختیاری است",
        },
        phone: {
          active: this.phone.active,
          mandatory: this.phone.mandatory !== "تکمیل این مورد اختیاری است",
        },
        email: {
          active: this.email.active,
          mandatory: this.email.mandatory !== "تکمیل این مورد اختیاری است",
        },
        goal: {
          amount: this.goal.amount,
          expire: this.goal.active,
          show: this.goal.show === "در فرم نمایش داده شود",
        },
        expire_date: {
          expired: this.expire.active,
          year: this.expire.date.year,
          month: this.convertMonth,
          day: this.expire.date.day,
          hour: this.expire.date.hour,
          minute: this.expire.date.minute,
        },
        additianl_fields: additionalFieldData,
      }
      if (this.$store.state.user.currency === "RIAL") {
        data.price = Math.floor(data.price / 10)
        data.goal.amount = Math.floor(data.goal.amount / 10)
      }

      if (
        this.expire.active &&
        this.expire_date_type === "afterTime" &&
        this.changeTime
      ) {
        let today = new Date()
        let type = "hours"
        if (this.expireAfterType === "دقیقه") type = "minutes"
        let expire = jmoment(today)
          .add(this.expireAfter, type)
          .format("jYYYY-jMM-jDD HH:mm:ss")

        const splitExpire = expire.split(" ")
        const expireDate = splitExpire[0].split("-")
        const expireTime = splitExpire[1].split(":")
        data.expire_date.year = expireDate[0]
        data.expire_date.month = expireDate[1]
        data.expire_date.day = expireDate[2]
        data.expire_date.hour = expireTime[0]
        data.expire_date.minute = expireTime[1]
      }
      data = JSON.parse(this.convertNumbers2English(JSON.stringify(data)))
      if (this.type === "edit") {
        this.$http
          .post(
            this.$store.getters.apiUrlParam +
              "/request-forms/" +
              this.id +
              "/update",
            data
          )
          .then((response) => {
            this.$emit("updateForm", response.data.data.form)
            this.$emit("closeModal", { status: true })
            this.$store.commit("callSuccessSnackbar", "فرم با موفقیت ویرایش شد")
          })
          .catch(() => {
            //callsnackbar
          })
      } else {
        this.$http
          .post(this.$store.getters.apiUrlParam + "/request-forms/store", data)
          .then((response) => {
            //this.form = response.data.data.form
            this.$emit("addForm", response.data.data.form)
            this.$emit("closeModal", { status: true })
          })
          .catch(() => {
            //callsnackbar
          })
      }
    },
  },
}
</script>
<style lang="scss">
.v-input--radio-group.simpleRadio
  .date_group_form
  .v-input__control
  .v-input__slot {
  border: 1px solid #e5e5e5 !important;
}

.v-input {
  &--is-disabled {
    opacity: 0.6;
  }
}

.priceGroup {
  .v-input {
    &--radio-group {
      &__input {
        gap: 0 12px;

        .v-radio {
          width: 108px;
          max-width: 108px;
          background-color: $van_color11;
          border: 1px solid #d5d5d5 !important;

          label {
            justify-content: center;
            color: $van_color03 !important;
          }
        }
      }
    }
  }
}

.info_panel {
  margin-top: -20px;
  padding: 7px 20px !important;
}
</style>

<style lang="scss" scoped>
.withTooltip {
  width: fit-content;
  display: inline-block;
}
</style>
