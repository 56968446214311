<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">جزییات درگاه</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          depressed
          outlined
          text
          dark
          class="btn_small_normal"
          @click="open_edit()"
        >
          ویرایش
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-layout column class="rows">
          <v-flex>
            <v-layout align-center wrap>
              <v-flex class="label" style="width: 110px"> کلید API </v-flex>
              <v-flex style="text-decoration: underline">
                {{ $store.state.ipg.api_key }}
              </v-flex>
              <v-flex style="max-width: 80px; position: relative">
                <copy-btn-general
                  input-id="hidden-input"
                  :value="$store.state.ipg.api_key"
                  style-btn="height:40px !important"
                  label="کپی کلید"
                  color-btn="van_color06"
                  class-btn="van_color11 tblCpy-receipt_url px-2 ma-0"
                  :outlined="false"
                />
              </v-flex>
              <input
                id="hidden-input1"
                v-model="$store.state.ipg.api_key"
                class="hidden-input"
              />
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout>
              <v-flex class="label"> آدرس وب‌سایت </v-flex>
              <v-flex>
                <v-layout class="align-center">
                  <v-flex style="flex: none">
                    <div v-if="$store.state.ipg.url">
                      {{ $store.state.ipg.url }}
                    </div>
                    <div v-else>-</div>
                  </v-flex>
                  <v-flex
                    v-if="
                      $store.state.ipg.hasOwnProperty('modified_ipg_fields') &&
                      $store.state.ipg.modified_ipg_fields.url.status ===
                        'PENDING'
                    "
                    class="mr-4"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-icon
                          size="20"
                          color="van_color15"
                          style="direction: rtl; width: 20px"
                          v-on="on"
                        >
                          mdi_info_outline
                        </v-icon>
                      </template>
                      <span>
                        آدرس جدید وب‌سایت
                        <span
                          >"{{
                            $store.state.ipg.modified_ipg_fields.url.value
                          }}"</span
                        >
                        در حال بررسی است</span
                      >
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex>
            <v-layout>
              <v-flex class="label"> آی پی </v-flex>
              <v-flex style="max-width: 345px">
                <span
                  v-for="(ip, i) in $store.state.ipg.ips"
                  :key="i"
                  class="line-break"
                >
                  <span>{{ ip }}</span>
                  <span
                    v-if="i < $store.state.ipg.ips.length - 1"
                    style="padding: 0 5px"
                    >|</span
                  >
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout>
              <v-flex class="label"> کارمزد تراکنش </v-flex>
              <v-flex v-if="$store.state.ipg.wage"> از خودم کسر شود </v-flex>
              <v-flex v-else> از پرداخت کننده دریافت شود </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status1"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <EditIpgModal
        v-show="status1"
        :key="componentKey"
        type="edit"
        @closeModal="close_modal"
      />
    </v-dialog>

    <general-dialog
      v-if="webSiteNameOrUrlEdited"
      :active="webSiteNameOrUrlEdited"
      logo="newIcon/information.svg"
      content-class="confirmationMessageDialog"
      title="در انتظار تایید پشتیبانی"
      description="اطلاعات ویرایش شده پس از تایید پشتیبانی اعمال می‌شود و تا آن زمان، درگاه شما با اطلاعات قبلی فعال است"
      description-class="font_2 text-xs-center"
      :width="360"
      btn2-text="فهمیدم"
      @btn2Clicked="editSuccess = false"
    />
  </v-card>
</template>

<script>
import EditIpgModal from "./EditIpgModal"
import GeneralDialog from "../../modals/generalDialog"
import CopyBtnGeneral from "../../elements/copyBtnGeneral"

export default {
  name: "Show",
  components: {
    CopyBtnGeneral,
    GeneralDialog,
    EditIpgModal,
  },
  data: function () {
    return {
      componentKey: 0,
      status1: false,
      editSuccess: false,
    }
  },
  computed: {
    webSiteNameOrUrlEdited() {
      if (
        this.editSuccess &&
        this.$store.state.ipg.modified_ipg_fields.url.status === "PENDING"
      )
        return true
      return false
    },
  },
  watch: {
    status1: function (newValue) {
      if (!newValue) this.$store.commit("callEditFormModal", false)
    },
  },
  methods: {
    open_edit() {
      //replaced this two line instead of close_modal() to see if support
      // problem would resolve(edit btn did not work for a business)
      this.$store.commit("callEditFormModal", false)
      this.status1 = false

      this.$store.commit("callEditFormModal", true)
      this.status1 = true
    },
    close_modal(obj) {
      this.$store.commit("callEditFormModal", false)
      this.status1 = false

      if (obj && obj.status) {
        this.componentKey = !this.componentKey
        this.editSuccess = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
nav.v-toolbar > .v-toolbar__content {
  padding: 0 7px 0 7px !important;
}
.key_btn {
  .v-tooltip__content {
    min-width: auto !important;
    top: 39px !important;
    padding: 5px 8px !important;
  }
}
</style>
