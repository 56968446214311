<template>
  <v-card class="singleField">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">ثبت ایمیل</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ثبت
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout>
      <v-flex>
        <div class="font_2 des">
          در وارد کردن ایمیل دقت کنید چرا که ایمیل تاییدیه برای این آدرس ارسال
          می‌شود:
        </div>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="input"
            placeholder="مثل: your_name@gmail.com"
            :rules="emailRules"
            solo
            flat
            height="40"
            outlined
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-text-field>
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "AddEmail",
  props: [],
  data: function () {
    return {
      valid: false,
      loading: false,
      input: "",
      emailRules: [
        (v) => !!v || "<span>وارد کردن ایمیل الزامی است</span>",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(v) ||
          "<span> ایمیل نادرست است</span>",
      ],
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.valid) return true
      else return false
    },
  },
  mounted() {
    if (this.$store.state.user.email != null) {
      this.input = this.$store.state.user.email
    }
  },
  methods: {
    sendForm() {
      if (this.$refs.form.validate()) {
        this.loading = true
        var data = {
          email: this.input,
        }
        this.$http
          .post(
            this.$store.state.api_base_url +
              "/personal/verification/email/send",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.loading = false
            var obj = {
              title: "ایمیل تاییدیه ارسال شد",
              description:
                "لطفا ایمیل خود را بررسی و روی لینک فعال‌سازی کلیک کنید. اگر ایمیلی دریافت نکردید، بر روی «<b>ارسال مجدد</b>» در میز کار کلیک کنید",
            }
            this.$emit("closeModal")

            //update store with new email
            //this.$store.commit('setUser', response.data.data.user)
            this.$store.commit("setUserData", {
              key: "email",
              value: this.input,
            })
            this.$store.commit("updateStatusBox", {
              key: "email",
              value: "unverified",
            })
            this.$store.commit("callConfirmarionMessage", obj)
          })
          .catch((error) => {
            //callsnackbar
          })
      }
    },
  },
}
</script>
