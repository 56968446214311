<template>
  <div>
    <!-- skeleton -->
    <div v-if="skeleton">
      <v-layout
        v-for="item in 5"
        :key="item"
        :align-center="!$vuetify.breakpoint.xsOnly"
        fill-height
        :column="$vuetify.breakpoint.xsOnly"
        class="table_row van_color13"
      >
        <v-flex
          class="font_2"
          :style="
            $vuetify.breakpoint.xsOnly ? '' : 'max-width:105px;width:105px'
          "
        >
          <span style="width: 70%" class="default_gray" />
        </v-flex>
        <v-flex
          class="font_2 d-flex"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width:140px'"
        >
          <span style="width: 70%" class="default_gray" />
        </v-flex>
        <v-flex
          class="font_2"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'text-align:center'"
        >
          <span style="width: 50%" class="default_gray" />
        </v-flex>
        <v-flex
          class="font_26"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'text-align:left'"
        >
          <span style="width: 70%" class="default_gray" />
        </v-flex>
      </v-layout>
    </div>
    <v-data-table
      v-else
      :items="items"
      hide-default-header
      hide-default-footer
      no-data-text="خطایی برای نمایش وجود ندارد"
    >
      <template #item="{ item }">
        <v-layout
          :align-center="!$vuetify.breakpoint.xsOnly"
          fill-height
          :column="$vuetify.breakpoint.xsOnly"
          class="table_row"
        >
          <v-flex
            class="font_2"
            :style="
              $vuetify.breakpoint.xsOnly ? '' : 'max-width:105px;width:105px'
            "
          >
            {{ (item.amount / 10) | currency }} {{ currencyName }}
          </v-flex>
          <v-flex
            class="font_2 d-flex"
            :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width:140px'"
          >
            {{ item.iban }}
          </v-flex>
          <v-flex
            class="font_2 text-truncate mr-2"
            :style="
              $vuetify.breakpoint.xsOnly
                ? ''
                : 'max-width:135px;text-align:center'
            "
          >
            {{ item.iban_owner_name }}
          </v-flex>
          <v-flex
            class="font_25"
            :style="$vuetify.breakpoint.xsOnly ? '' : 'text-align:left'"
          >
            {{ item.error_message }}
          </v-flex>
        </v-layout>
      </template>
    </v-data-table>
    <v-layout
      v-if="totalPage > 1"
      align-center
      fill-height
      style="height: 60px"
      class="van_color13"
    >
      <v-flex v-if="skeleton" class="font_14 mr-7" style="text-align: right">
        <span style="width: 20%" class="default_gray" />
      </v-flex>
      <v-flex v-else class="font_14 mr-7" style="text-align: right">
        {{ totalItems }} برداشت
      </v-flex>
      <v-flex style="text-align: left; padding-top: 15px; paddint-bottom: 15px">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
          color="#4c9f8733"
          class="text--van_color02"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    batchId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      page: 1,
      totalPage: 1,
      items: [],
      totalItems: 0,
      skeleton: true,
    }
  },
  watch: {
    page: function () {
      this.getFailedSettlement()
    },
  },
  mounted() {
    this.getFailedSettlement()
  },
  methods: {
    getFailedSettlement() {
      this.$http
        .get(
          this.$store.getters.apiUrlBatch +
            "/batch-settlements/" +
            this.batchId +
            "?status=FAILED&page=" +
            this.page,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.items = response.data.data
          this.totalPage = response.data.meta.last_page
          this.totalItems = response.data.meta.total
          this.skeleton = false
        })
        .catch((error) => {
          //callsnackbar
          this.skeleton = false
        })
    },
  },
}
</script>

<style lang="scss" scopped>
.table_row {
  height: 48px;
  margin-top: 2px;
  padding: 14px 30px;
  border-bottom: 2px solid #fafafa;

  @media screen and (max-width: 599px) {
    height: 100% !important;
  }
}
.v-pagination__item {
  height: 30px !important;
  min-width: 30px !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  color: #666666 !important;
}
.v-pagination__item--active {
  color: #333333 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
</style>
