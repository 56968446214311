export const duration = {
  current_day: "روز جاری",
  current_week: "هفته جاری",
  current_month: "ماه جاری",
  current_quarter: "فصل جاری",
  current_year: "سال جاری",
  last_day: "دیروز",
  last_week: "هفته گذشته",
  last_month: "ماه گذشته",
  last_quarter: "فصل گذشته",
  last_year: "سال گذشته",
}
