<template>
  <div>
    <v-skeleton-loader
      v-if="showSkeleton && page == 1"
      class="mb-2"
      type="text"
      width="70%"
    ></v-skeleton-loader>

    <div v-else-if="pendingSettlements?.length" class="font-size-12 mb-2">
      تراکنش‌ها تا پیش از بالا رفتن موجودی کیف‌پول امکان لغو دارند.
    </div>

    <div v-if="showSkeleton">
      <v-skeleton-loader
        v-for="i in 6"
        :key="i"
        class="mb-2"
        max-height="60"
        type="card"
      ></v-skeleton-loader>
    </div>

    <SettlementQueuePendingItems
      v-else
      :pending-settlements="pendingSettlements"
      :total="total"
      :all-pending-ids="allPendingIds"
      @getNewPageData="getNewPageData()"
      @checkCanceled="checkCanceled($event)"
      @toggleBatchModal="toggleBatchModal($event)"
    />

    <v-dialog
      v-if="openBatchesModal"
      v-model="openBatchesModal"
      :content-class="
        $store.state.showThirdModal ? 'secondModal left-modal' : 'left-modal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <QueuedBatchesModal
        :selected-settlement="selectedSettlement"
        :amount-sum="handleAmount(sumAmount(selectedSettlement))"
        toolbar-name="لیست برداشت گروهی"
        @closeModal="closeDetails()"
        @resetData="resetData()"
        @checkCanceled="checkCanceled($event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import SettlementQueuePendingItems from "./settlementQueuePendingItems.vue"
import QueuedBatchesModal from "../../modals/queuedBatchesModal.vue"
import { queuedSettlementHelper } from "@/mixins/queuedSettlementHelper.js"

export default {
  name: "SettlementQueuePending",
  components: {
    SettlementQueuePendingItems,
    QueuedBatchesModal,
  },
  mixins: [queuedSettlementHelper],
  data() {
    return {
      page: 1,
      total: null,
      showSkeleton: true,
      pendingSettlements: [],
      selectedSettlement: null,
      openBatchesModal: false,
      allPendingIds: [],
    }
  },
  watch: {
    skeleton(newValue) {
      this.$emit("skeleton", newValue)
    },
    showPendingItem(newValue) {
      if (!newValue) {
        this.closeDetails()
      }
    },
    openBatchesModal(newValue) {
      if (!newValue) {
        this.closeDetails()
      }
    },
    pendingSettlements(newValue) {
      if (this.selectedSettlement) {
        const selected = newValue.filter(
          (element) => (element.id = this.selectedSettlement.id)
        )
        if (selected.length > 0) this.selectedSettlement = selected[0]
      }
    },
  },
  mounted() {
    this.getData()
    this.getAllPendingIds()
  },
  methods: {
    getData() {
      this.$http
        .get(
          this.$store.getters.apiUrlParamV3 +
            `/settlement/queued/list?status=PENDING&page=${this.page}`
        )
        .then((resp) => {
          if (this.page === 1) {
            this.pendingSettlements = []
          }

          this.pendingSettlements = this.pendingSettlements.concat(
            resp.data.data
          )

          this.total = resp.data.meta?.total
          this.showSkeleton = false
        })
        .catch(() => {
          this.showSkeleton = false
        })
    },
    getAllPendingIds() {
      const url = this.$store.getters.apiUrlParamV3 + "/settlement/queued/ids"
      this.$http
        .get(url)
        .then((response) => {
          if (response.data) this.allPendingIds = response.data.data
        })
        .catch(() => {
          //snackbar
        })
    },
    closeDetails() {
      this.openBatchesModal = false
      this.$store.commit("changeShowSecondModal", false)
    },
    getNewPageData() {
      this.page += 1

      this.getData()
    },
    resetData() {
      this.page = 1
      this.total = null
      this.showSkeleton = false
      this.pendingSettlements = []
      this.getData()
      this.getAllPendingIds()
    },
    toggleBatchModal(event) {
      this.openBatchesModal = true
      this.selectedSettlement = event
    },
    checkCanceled(event) {
      this.resetData()
      this.$emit("checkCanceled", event)
      if (event) {
        this.openBatchesModal = false
      }
    },
  },
}
</script>
