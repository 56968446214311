<template>
  <v-layout column class="rows paddingOnFirst">
    <v-flex v-for="(comment, index) in item.comments" :key="index">
      <v-layout
        :align-center="$vuetify.breakpoint.smAndUp"
        :column="$vuetify.breakpoint.xsOnly"
      >
        <v-flex v-if="comment.status == 'CREATION'" class="label">
          یادداشت محرمانه
        </v-flex>
        <v-flex v-else class="label"> یادداشت لغو </v-flex>
        <v-flex
          :style="
            $vuetify.breakpoint.smAndUp
              ? 'max-width: calc(100% - 55px);line-break: anywhere;'
              : 'max-width: 100%;line-break: anywhere;'
          "
        >
          {{ comment.comments }}
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["item"],
}
</script>

<style lang="scss" scoped>
.label {
  min-width: 110px;
}
</style>
