<template>
  <div class="d-flex flex-column align-start justify-center">
    <v-btn
      large
      class="ma-0 mb-2"
      outlined
      text
      :disabled="count >= 5"
      @click="add"
    >
      <CloudUploadSvg />
      <span class="mr-2">انتخاب فایل</span>
    </v-btn>
    <input
      ref="input"
      multiple
      type="file"
      :accept="accept"
      style="display: none"
      @change="capture"
    />
    <div class="d-flex align-center justify-start">
      <div v-for="file in files" :key="file.name">
        <FileUploaderUploadedFile
          :form-id="formId"
          :file="file"
          @delete-file="deleteFile"
        ></FileUploaderUploadedFile>
      </div>
      <div v-for="file in uploadingFiles" :key="file.id">
        <FileUploaderUploadingFile
          :file="file"
          @cancel="cancelUpload(file.id)"
        ></FileUploaderUploadingFile>
      </div>
    </div>
  </div>
</template>

<script>
import CloudUploadSvg from "@/components/tickets/components/CloudUploadSvg.vue"
import FileUploaderUploadedFile from "@/components/file-uploader/FileUploaderUploadedFile.vue"
import FileUploaderUploadingFile from "@/components/file-uploader/FileUploaderUploadingFile.vue"

export default {
  name: "FileUploader",
  components: {
    FileUploaderUploadedFile,
    FileUploaderUploadingFile,
    CloudUploadSvg,
  },
  props: {
    formId: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      files: [],
      uploadingFiles: [],
    }
  },

  computed: {
    count() {
      return this.files.length + this.uploadingFiles.length
    },
  },
  methods: {
    add() {
      this.$refs.input.click()
    },
    capture() {
      const files = this.$refs.input.files
      if (!files) return

      if (files.length + this.count > 5) {
        this.$store.commit(
          "callsnackbar",
          "نمیتوانید بیشتر از ۵ فایل آپلود کنید."
        )
        return
      }
      if (files?.length) {
        for (let index = 0; index < files.length; index++) {
          if (files[index].size / 1024 > 10000) {
            this.$store.commit(
              "callsnackbar",
              "حجم فایل نمیتواند بیشتر از 10MB باشد."
            )
            this.$refs.input.value = null
            return
          }
          let formData = new FormData()
          formData.append("form_id", this.formId)
          formData.append("files[]", files[index])
          const fileId = index + this.uploadingFiles.length
          this.uploadingFiles.push({
            id: fileId,
            file: files[index],
            progress: 0,
            extension: files[index].name.split(".").pop(),
            preview: URL.createObjectURL(files[index]),
            controller: new AbortController(),
          })

          this.$http
            .post(
              `${this.$store.state.file_uploader_api_base_url}/business/${this.$store.state.business.business_name}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                signal: this.uploadingFiles.find((item) => item.id === fileId)
                  .controller.signal,
                onUploadProgress: (progressEvent) =>
                  (this.uploadingFiles.find(
                    (item) => item.id === fileId
                  ).progress = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  )),
              }
            )
            .then((response) => {
              let i = this.uploadingFiles.findIndex((item) => item.id === index)
              this.uploadingFiles.splice(i, 1)
              const uploadedFiles = response.data.data.files
              this.files.push(...uploadedFiles)
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              let i = this.uploadingFiles.findIndex(
                (item) => item.id === fileId
              )
              this.uploadingFiles.splice(i, 1)

              throw Promise.reject(error)
            })
            .finally(() => (this.$refs.input.value = null))
        }
      }
    },
    deleteFile(fileName) {
      let index = this.files.findIndex((item) => item.name === fileName)
      this.files.splice(index, 1)
    },
    cancelUpload(id) {
      let index = this.uploadingFiles.findIndex((item) => item.id === id)
      this.uploadingFiles.splice(index, 1)
    },
  },
}
</script>

<style scoped></style>
