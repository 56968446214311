const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: context.hasOwnProperty("state")
    ? "Bearer " + context.state.token
    : "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpGetCustomers(url) {
    return context.$http.get(context.$store.getters.apiUrlParamV3 + url, {
      headers: apiHeaders(context),
    })
  },
  httpDeleteCustomers() {
    return context.$http.delete(
      `${context.$store.getters.apiUrlParamV3}/customers/${context.$store.state.customer.selectedCustomer.id}`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetCustomersIbans(axios, page, item_per_page) {
    return axios.get(
      `${context.getters.apiUrlParamV3}/customers/${context.state.customer.selectedCustomer.id}/ibans?page=${page}&per_page=${item_per_page}`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpAddCustomerIban(payload) {
    return context.$http.post(
      `${context.$store.getters.apiUrlParamV3}/customers/${context.$store.state.customer.selectedCustomer.id}/ibans`,
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpDeleteCustomerIban(queryParam) {
    return context.$http.delete(
      `${context.$store.getters.apiUrlParamV3}/customers/${context.$store.state.customer.selectedCustomer.id}/ibans/${queryParam}`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpAddCustomer(payload) {
    return context.$http.post(
      `${context.$store.getters.apiUrlParam}/customers`,
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpUpdateCustomer(payload) {
    return context.$http.put(
      `${context.$store.getters.apiUrlParamV3}/customers/${context.$store.state.customer.selectedCustomer.id}`,
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetCustomerInformation(axios, id) {
    return axios.get(`${context.getters.apiUrlParam}/customers/${id}`, {
      headers: apiHeaders(context),
    })
  },
})
