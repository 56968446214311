<template>
  <TicketsLeftModal toolbar-name="پیام جدید" @closeModal="$emit('closeModal')">
    <div slot="toolbarItems">
      <v-btn
        :disabled="!hasChange"
        class="btn_small_normal"
        style="margin-left: 0"
        dark
        text
        @click="sendMessage"
      >
        ارسال
      </v-btn>
    </div>
    <GeneralCard>
      <v-layout
        :column="$vuetify.breakpoint.smAndDown"
        justify-start
        align-start
        class="mt-4 mb-4"
      >
        <v-flex class="label">
          متن پیام
          <span class="error-text">*</span>
        </v-flex>
        <v-flex class="withlabel big">
          <v-textarea
            v-model="description"
            :class="{ 'mr-0': $vuetify.breakpoint.smAndDown }"
            label="شرح پیام را اینجا بنویسید"
            outlined
            dense
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout
        :column="$vuetify.breakpoint.smAndDown"
        justify-start
        align-start
      >
        <v-flex class="label"> فایل پیوست</v-flex>
        <v-flex>
          <FileUploader
            :class="{ 'mr-5': $vuetify.breakpoint.mdAndUp }"
            :form-id="formId"
            accept=".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv"
          ></FileUploader>
        </v-flex>
      </v-layout>
    </GeneralCard>
  </TicketsLeftModal>
</template>

<script>
import TicketsLeftModal from "@/components/tickets/components/TicketsLeftModal.vue"
import GeneralCard from "@/components/elements/generalCard"
import { v4 as uuidv4 } from "uuid"
import FileUploader from "@/components/file-uploader/FileUploader.vue"

export default {
  name: "CreateNewMessageModal",
  components: {
    FileUploader,
    TicketsLeftModal,
    GeneralCard,
    // CloudUploadSvg,
  },
  props: {
    newMessageCreationUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formId: uuidv4(),
      attachmentFile: null,
      description: "",
      hasChange: false,
    }
  },
  watch: {
    description(val) {
      if (val) {
        this.hasChange = true
      } else {
        this.hasChange = false
      }
    },
  },
  methods: {
    async sendMessage() {
      // if (this.attachmentFile?.length) {
      //   this.attachmentFile.forEach((item) =>
      //     formData.append("attachments[]", item)
      //   )
      // }
      await this.$http.post(this.newMessageCreationUrl, {
        form_id: this.formId,
        message: this.description,
      })
      this.$emit("send-message")
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.v-label--active) {
  display: none;
}
:deep(.theme--light.v-input textarea) {
  color: #666666 !important;
}

:deep(.v-label) {
  color: #666666 !important;
  font-size: 12px;
}

:deep(.v-input__prepend-outer) {
  display: none !important;
}

:deep(.v-input__prepend-inner) {
  padding-top: 2px;
  margin-left: 4px !important;
}
</style>
