<template>
  <v-layout class="box404" justify-center align-center fill-height column>
    <v-flex>
      <span class="back" />
      <!-- <svg style="fill:#f5f5f5;" xmlns="http://www.w3.org/2000/svg" width="500" height="500" viewBox="0 0 500 500"><path class="a" d="M752.392,352.633s22.614-23.973,45.229-23.973,45.229,23.973,45.229,23.973L1023.687,533.39s23.973,22.615,23.973,45.23-23.973,45.229-23.973,45.229h0L842.85,804.687s-22.615,23.973-45.229,23.973-45.229-23.973-45.229-23.973L571.633,623.85S547.66,601.235,547.66,578.62s23.973-45.23,23.973-45.23Z" transform="translate(-547.66 -328.66)"/></svg> -->
      <h1 class="mb-2">صفحه مورد نظر یافت نشد</h1>
      <div class="bold_text">Error 404</div>
      <div style="max-width: 257px; margin: 5px 10px 19px" class="font_2">
        لینک مورد نظر شما یا وجود ندارد یا تغییر کرده است در ادامه می‌توانید
        وارد صفحه همه کسب‌و‌کارها شده و از آنجا مسیر خود را پیدا کنید :
      </div>
      <v-btn ref="btn" text dark class="btn_small_normal" to="/">
        همه کسب‌و‌کارهای من
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "PageNotFound",
}
</script>

<style lang="scss" scoped>
.landingPage:before {
  display: none !important;
}
.box404 {
  min-height: calc(100vh - 100px);
  > .flex {
    max-width: 360px !important;
    max-height: 360px;
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > span.back {
      background-color: #f5f5f5;
      transform: rotate(45deg);
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 50px;
      z-index: -1;
      max-width: 360px;
      max-height: 360px;
      width: 100%;
      height: 100%;
    }
    .bold_text {
      font-size: 50px;
      color: $van_color06;
    }
  }
}
</style>
