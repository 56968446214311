export function initialState() {
  return {
    /**
     * templateStatus:
     * 1: default dashboard page with right drawer
     * 2: landing page with navbar on top of page
     * 3: landing pages without navbar
     */
    templateStatus: 3,
    isUser: true,
    token: null,
    //refresh token
    waitForRefreshToken: false,
    dash_base_url: process.env.VUE_APP_DASH_PATH,
    base_url: process.env.VUE_APP_BASE_URL,
    storage_base_url: process.env.VUE_APP_STORAGE_BASE_URL + "/",
    pay_base_url: process.env.VUE_APP_PAY_PATH,
    receipt_base_url: process.env.VUE_APP_RECEIPT_PATH,
    batch_base_url: process.env.VUE_APP_BATCH_PATH + "/api/v1",
    batch_base_url_v2: process.env.VUE_APP_BATCH_PATH + "/api/v2",
    kerdar_base_url: process.env.VUE_APP_KERDAR_PATH,
    kerdar_base_url_v2: process.env.VUE_APP_KERDAR_PATH_V2,
    mpg_base_url: process.env.VUE_APP_MPG_PATH + "/v1",
    miando_base_url: process.env.VUE_APP_API_PATH + "/miando/api/dashboard/v1",
    miando_url: process.env.VUE_APP_MIANDO_PATH,
    ticket_api_base_url: process.env.VUE_APP_API_PATH + "/ticket/v1",
    file_uploader_api_base_url:
      process.env.VUE_APP_API_PATH + "/file-uploader/v1",
    api_base_url: process.env.VUE_APP_API_PATH + "/v2",
    api_base_url_v3: process.env.VUE_APP_API_PATH + "/v3",
    api_base_url_withoutV: process.env.VUE_APP_API_PATH,
    api_pbv_url: process.env.VUE_APP_PBV_URL,
    api_pbv_banks: process.env.VUE_APP_PBV_BANKS,
    sso_url: process.env.VUE_APP_SSO_URL,
    is_sentry_active: process.env.VUE_APP_SENTRY == "active" ? true : false,
    main_domain: process.env.VUE_APP_MAIN_DOMAIN,
    dash_name: process.env.VUE_APP_DASH_NAME,
    refreshTokenTtl: process.env.VUE_APP_REFRESH_TOKEN_TTL,
    user: {},
    whatsNew: {},
    business: {},
    businessList: null,
    //check what is this
    tempMobile: null,
    confirmationMessage: {
      status: false,
      title: "",
      description: "",
    },
    errorMessage: {
      status: false,
      title: "",
      description: "",
    },
    snackbar: {
      status: false,
      message: "",
    },
    successSnackbar: {
      status: false,
      message: "",
    },
    vandarTypoCdn: "https://cdn.vandar.io/public/logos/typo.svg",
    vandarIconCdn: "https://cdn.vandar.io/public/logos/icon-logo.svg",
    sussessAlert: false,
    secondShebamodal: false,
    secondTelmodal: false,
    secondAddUsermodal: false,
    secondAddDocumentmodal: false,
    shouldDeleteUploadedFile: false,
    secondUserSetting: false,
    secondEditFormmodal: false,
    secondEditSubFormmodal: false,
    updateBusinessList: false,
    verificationOnboardingIndex: 1,
    tempEmail: "",
    startVerificationTab: false,
    startVerificationBusiness: false,
    enterModalPassword: false,
    lastMobile: "",
    secondEditCustomerModal: false,
    showDetailBatchesSettlementLoading: false,
    p2pConfirmLoading: false,
    logFilterLoading: false,
    secondWalletDetailModal: false,
    secondSettlementModal: false,
    secondAutoSettlementModal: false,
    signatureHasLoaded: false,
    showThirdModal: false,
    showSecondModal: false,
    showWhatsNewsDialog: false,
    delayTimeToTakeAction: 700,
    businessCeilingAmount: false,
    miandoFilterLoading: false,
    suspiciousCount: 0,
    addBusinessIbanIsActive: false,
    shouldGetSuspiciousRefund: false,
    suspiciousOnTheWayCount: 0,
    isRoutedFromSuspiciousItemsModal: false,
    shouldGetSuspiciousRefundOnTheWay: false,
    shouldFilterWithCashInByCode: false,
    isNationalIdFocused: false,
    accountsList: [],
    accountLastUpdatedTime: null,
    accountDetailsSecondModal: false,
    accountDetailsThirdModal: false,
    walletAmountDetails: {},
    totalBalanceAccounts: 0,
    isShowDatePicker: false,
  }
}

//export const state = initialState()
