<template>
  <div>
    <!-- skeleton for table -->
    <div v-if="loading">
      <div v-if="!$vuetify.breakpoint.smAndDown" class="loading">
        <v-layout v-for="index in 10" :key="index" align-center class="cardRow">
          <v-flex class="ml-4" style="max-width: 40px">
            <span class="default_gray row_number" />
          </v-flex>
          <v-flex style="width: 30%">
            <span class="default_gray" />
          </v-flex>
          <v-flex style="width: 25%">
            <span class="default_gray" />
          </v-flex>
          <v-flex style="width: 25%">
            <span class="default_gray" />
          </v-flex>
          <v-flex style="max-width: 40px">
            <span class="default_gray" />
          </v-flex>
        </v-layout>
      </div>
      <div v-else>
        <v-layout
          v-for="index in 10"
          :key="index"
          align-space-between
          class="cardRow"
          column
        >
          <v-flex>
            <v-layout>
              <v-flex>
                <v-layout align-center>
                  <v-flex style="max-width: 41px">
                    <span class="default_gray row_number" />
                  </v-flex>
                  <v-flex class="mr-5" style="max-width: 40%">
                    <span class="default_gray" />
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex style="max-width: 40px">
                <span class="default_gray" />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout class="mt-5">
              <v-flex
                style="
                  background-color: #f5f5f5;
                  display: flex;
                  align-item: center;
                  justify-content: center;
                  padding: 14px 27px;
                  margin-left: 8px;
                "
              />
              <v-flex
                style="
                  background-color: #f5f5f5;
                  display: flex;
                  align-item: center;
                  justify-content: center;
                  padding: 14px 27px;
                "
              />
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </div>

    <!-- filled table -->
    <div v-else-if="forms.length != 0">
      <div v-if="!$vuetify.breakpoint.smAndDown">
        <v-hover v-for="(item, index) in forms" :key="index" v-slot="{ hover }">
          <v-layout
            :class="[
              'cardRow',
              !item.active && !hover ? 'disabled_text' : '',
              hover ? 'card_shadow' : '',
            ]"
            align-center
          >
            <v-flex style="max-width: 40px; margin-left: 15px">
              <v-tooltip top>
                <template #activator="{ on }">
                  <div
                    :class="[
                      'row_number d-flex',
                      item.active ? 'green_back' : 'gray_back',
                    ]"
                    v-on="on"
                  >
                    <v-img
                      v-if="item.transactions_sum"
                      :src="require('@/assets/img/Ascending.svg')"
                      class="ma-auto"
                      max-height="14"
                      max-width="14"
                    />
                    <v-img
                      v-else
                      :src="require('@/assets/img/line.svg')"
                      class="ma-auto"
                      max-height="14"
                      max-width="14"
                    />
                  </div>
                </template>
                <span v-if="item.active && item.transactions_count">
                  فرم فعال با
                  {{ item.transactions_count | thousandSeprator }} واریزی
                </span>
                <span v-else-if="item.active && !item.transactions_count">
                  فرم فعال بدون واریزی
                </span>
                <span v-else-if="!item.active && item.transactions_count">
                  فرم غیرفعال با
                  {{ item.transactions_count | thousandSeprator }} واریزی
                </span>
                <span v-else-if="!item.active && !item.transactions_count">
                  فرم غیرفعال بدون واریزی
                </span>
              </v-tooltip>
            </v-flex>
            <v-flex
              :class="[
                'font_5 bold',
                !item.active && !hover ? 'disabled_text' : '',
              ]"
              style="width: 30%"
            >
              {{ item.title }}
            </v-flex>
            <v-flex style="width: 25%">
              <span v-if="item.price !== 0">
                <span class="pl-1" dir="ltr">{{ item.price | currency }}</span>
                <span>{{ currencyName }}</span>
              </span>
              <span v-else>مبلغ دلخواه</span>
            </v-flex>
            <v-flex style="width: 25%">
              <span v-if="item.transactions_sum === 0">بدون واریزی</span>
              <span v-else>
                <span dir="ltr">
                  {{ item.transactions_sum | currency }}
                </span>
                {{ currencyName }}
                در
                <span>{{ item.transactions_count | thousandSeprator }}</span>
                واریز
              </span>
            </v-flex>
            <v-flex style="max-width: 32px">
              <copy-btn-general
                :disabled="!item.active && !hover"
                :input-id="'form_' + item.id"
                :value="item.form_link"
                before-copy-hover-text="کپی لینک فرم پرداخت"
              />
            </v-flex>
            <v-flex style="max-width: 40px">
              <v-menu
                bottom
                close-on-content-click
                content-class="up_menu"
                offset-y
                transition="slide-y-transition"
              >
                <!-- this on slot sometime is undefined -->
                <template #activator="{ on }">
                  <v-btn
                    :disabled="!item.active && !hover"
                    fab
                    icon
                    size="24"
                    small
                    style="margin: 0px 8px"
                    v-on="on"
                  >
                    <v-icon
                      color="van_color03"
                      size="21"
                      style="line-height: 40px"
                    >
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>

                <v-card class="drop_down_menu" outlined>
                  <v-list class="menu_v_list">
                    <v-list-item @click="view_function(item.id)">
                      <v-list-item-content>
                        <v-list-item-title>جزییات</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-role-permission:not="['observer', 'developer']"
                      @click="edit_form(item.id)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>ویرایش فرم</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="item.active"
                      v-role-permission:not="['observer', 'developer']"
                      @click="suspendFormCall(item.id)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>تعلیق فرم</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="!item.active"
                      v-role-permission:not="['observer', 'developer']"
                      @click="unsuspendFormCall(item.id)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>فعال کردن فرم</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-role-permission:not="['observer', 'developer']"
                      @click="deleteFormCall(item.id)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>حذف فرم</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="item.transactions_sum"
                      :to="{
                        name: 'businessReport',
                        params: {
                          filtered: 'successfullTransactionRequestMoney',
                          formId: item.id,
                        },
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>مشاهده واریزها</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-hover>

        <!-- load more -->
        <observer
          v-if="!endOfLoadMoreRequest"
          @intersect="intersected('loadMore')"
        />

        <div v-if="loadMoreSkeleton && !endOfLoadMoreRequest">
          <v-layout
            v-for="index in 10"
            :key="index"
            align-center
            class="cardRow"
          >
            <v-flex style="max-width: 40px; margin-left: 15px">
              <v-avatar
                class="default_gray"
                color="#eee"
                size="40"
                style="border-radius: 50%; margin: 0px"
              />
            </v-flex>
            <v-flex style="width: 30%">
              <span class="default_gray" />
            </v-flex>
            <v-flex style="width: 25%">
              <span class="default_gray" />
            </v-flex>
            <v-flex style="width: 25%">
              <span class="default_gray" />
            </v-flex>
            <v-flex style="max-width: 40px">
              <span class="default_gray" />
            </v-flex>
          </v-layout>
        </div>
      </div>
      <div v-else>
        <v-hover v-for="(item, index) in forms" :key="index" v-slot="{ hover }">
          <v-layout
            :class="[
              'cardRow',
              !item.active && !hover ? 'disabled_text' : '',
              hover ? 'card_shadow' : '',
            ]"
            align-space-between
            column
          >
            <v-flex>
              <v-layout>
                <v-flex>
                  <v-layout align-center>
                    <v-flex style="max-width: 41px">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <div
                            :class="[
                              'row_number d-flex',
                              item.active ? 'green_back' : 'gray_back',
                            ]"
                            v-on="on"
                          >
                            <v-img
                              v-if="item.transactions_sum"
                              :src="require('@/assets/img/Ascending.svg')"
                              class="ma-auto"
                              max-height="14"
                              max-width="14"
                            />
                            <v-img
                              v-else
                              :src="require('@/assets/img/line.svg')"
                              class="ma-auto"
                              max-height="14"
                              max-width="14"
                            />
                          </div>
                        </template>
                        <span v-if="item.active && item.transactions_count">
                          فرم فعال با
                          {{ item.transactions_count | thousandSeprator }}
                          واریزی
                        </span>
                        <span
                          v-else-if="item.active && !item.transactions_count"
                        >
                          فرم فعال بدون واریزی
                        </span>
                        <span
                          v-else-if="!item.active && item.transactions_count"
                        >
                          فرم غیرفعال با
                          {{ item.transactions_count | thousandSeprator }}
                          واریزی
                        </span>
                        <span
                          v-else-if="!item.active && !item.transactions_count"
                        >
                          فرم غیرفعال بدون واریزی
                        </span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex
                      :class="[
                        'font_5 bold',
                        !item.active && !hover ? 'disabled_text' : '',
                      ]"
                      style="margin-right: 20px"
                    >
                      {{ item.title }}
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex style="max-width: 32px">
                  <copy-btn-general
                    :disabled="!item.active && !hover"
                    :input-id="'form_' + item.id"
                    :value="item.form_link"
                    before-copy-hover-text="کپی لینک فرم پرداخت"
                    class="mt-1"
                  />
                </v-flex>
                <v-flex style="max-width: 40px">
                  <v-menu
                    bottom
                    close-on-content-click
                    content-class="up_menu"
                    offset-y
                    transition="slide-y-transition"
                  >
                    <!-- this on slot sometime is undefined -->
                    <template #activator="{ on }">
                      <v-btn
                        :disabled="!item.active && !hover"
                        fab
                        icon
                        size="24"
                        small
                        style="margin: 0px 8px"
                        v-on="on"
                      >
                        <v-icon
                          color="van_color03"
                          size="21"
                          style="line-height: 40px"
                        >
                          more_vert
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-card class="drop_down_menu" outlined>
                      <v-list class="menu_v_list">
                        <v-list-item @click="view_function(item.id)">
                          <v-list-item-content>
                            <v-list-item-title>جزییات</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-role-permission:not="['observer', 'developer']"
                          @click="edit_form(item.id)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>ویرایش فرم</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-if="item.active"
                          v-role-permission:not="['observer', 'developer']"
                          @click="suspendFormCall(item.id)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>تعلیق فرم</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-if="!item.active"
                          v-role-permission:not="['observer', 'developer']"
                          @click="unsuspendFormCall(item.id)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>فعال کردن فرم</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-role-permission:not="['observer', 'developer']"
                          @click="deleteFormCall(item.id)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>حذف فرم</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-if="item.transactions_sum"
                          :to="{
                            name: 'businessReport',
                            params: {
                              filtered: 'successfullTransactionRequestMoney',
                              formId: item.id,
                            },
                          }"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              مشاهده واریزها
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout style="margin-top: 20px">
                <v-flex
                  style="
                    background-color: #f5f5f5;
                    display: flex;
                    align-item: center;
                    justify-content: center;
                    padding: 14px 27px;
                    margin-left: 8px;
                  "
                >
                  <span v-if="item.price !== 0">
                    <span class="pl-1" dir="ltr">{{
                      item.price | currency
                    }}</span>
                    <span>{{ currencyName }}</span>
                  </span>
                  <span v-else>مبلغ دلخواه</span>
                </v-flex>
                <v-flex
                  style="
                    background-color: #f5f5f5;
                    display: flex;
                    align-item: center;
                    justify-content: center;
                    padding: 14px 27px;
                  "
                >
                  <span v-if="item.transactions_sum === 0">بدون واریزی</span>
                  <span v-else>
                    <span dir="ltr">
                      {{ item.transactions_sum | currency }}
                    </span>
                    {{ currencyName }}
                    در
                    <span>{{
                      item.transactions_count | thousandSeprator
                    }}</span>
                    واریز
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-hover>

        <!-- load more -->
        <observer
          v-if="!endOfLoadMoreRequest"
          @intersect="intersected('loadMore')"
        />

        <div v-if="loadMoreSkeleton && !endOfLoadMoreRequest">
          <v-layout
            v-for="index in 10"
            :key="index"
            align-space-between
            class="cardRow"
            column
          >
            <v-flex>
              <v-layout>
                <v-flex>
                  <v-layout align-center>
                    <v-flex style="max-width: 41px">
                      <span class="default_gray row_number" />
                    </v-flex>
                    <v-flex class="mr-5" style="max-width: 40%">
                      <span class="default_gray" />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex style="max-width: 40px">
                  <span class="default_gray" />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout class="mt-5">
                <v-flex
                  style="
                    background-color: #f5f5f5;
                    display: flex;
                    align-item: center;
                    justify-content: center;
                    padding: 14px 27px;
                    margin-left: 8px;
                  "
                />
                <v-flex
                  style="
                    background-color: #f5f5f5;
                    display: flex;
                    align-item: center;
                    justify-content: center;
                    padding: 14px 27px;
                  "
                />
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </div>

    <v-card v-else-if="!showResultBar" class="py-5 px-6" outlined>
      <v-layout class="align-center">
        <v-flex style="max-width: 60px">
          <v-avatar color="#e5e5e54d" size="60" style="border-radius: 50%">
            <v-avatar
              color="#E5E5E5"
              size="43"
              style="border: 1px solid #ffffff; border-radius: 50%"
            >
              <v-img
                :src="require('@/assets/img/newIcon/payment_form_card.svg')"
                alt="card"
                contain
                max-width="16px"
              />
            </v-avatar>
          </v-avatar>
        </v-flex>
        <v-flex class="mx-3 font_2">
          <v-layout class="column">
            <v-flex class="bold"> هنوز فرم پرداختی ساخته نشده است</v-flex>
            <v-flex>فرم پرداخت‌های شما در این لیست نمایش داده می‌شوند.</v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>

    <!-- modals -->
    <v-dialog
      v-model="status"
      :content-class="
        $store.state.secondEditFormmodal ? 'secondModal vanmodal' : 'vanmodal'
      "
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      transition="slide-x-transition"
    >
      <add
        v-show="add"
        :key="componentKey"
        @addForm="add_form"
        @closeModal="close_modal"
      />
      <!--      edit form-->
      <add
        v-if="edit"
        :id="calledId"
        :form="selectedForm"
        type="edit"
        @closeModal="close_modal()"
        @updateForm="update_form_list"
      />
      <show
        v-if="view"
        :id="calledId"
        @closeModal="close_modal()"
        @deleteForm="delete_form"
        @updateFormList="update_form_list"
      />
    </v-dialog>

    <v-dialog
      v-if="updatedForm"
      v-model="addIsFinalize"
      content-class="confirmationMessage withlinks"
      width="360"
    >
      <v-card height="100%" outlined>
        <v-layout align-center column justify-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/checkbox-marked-circle-outline.svg')"
              height="40"
              width="40"
            />
          </v-flex>
          <v-flex class="main-title font_3"> فرم با موفقیت ساخته شد</v-flex>
          <v-flex class="main-des font_4">
            می‌توانید لینک فرم اختصاصیتان را کپی کرده و در اختیار دیگران قرار
            دهید
          </v-flex>
          <v-flex class="mt-5">
            <v-layout align-center justify-center>
              <v-flex
                v-if="updatedForm.form_link"
                class="font_4"
                style="
                  padding: 9px 10px;
                  background-color: #f5f5f5;
                  margin-left: 3px;
                "
              >
                {{ updatedForm.form_link.substring(7) }}
              </v-flex>
              <v-flex>
                <CopyBtnGeneral
                  :value="updatedForm.form_link"
                  input-id="hidden-input"
                  label="کپی لینک"
                  style-btn="height:40px !important"
                />
              </v-flex>
              <input
                v-if="updatedForm.form_link"
                id="hidden-input"
                v-model="updatedForm.form_link"
                class="hidden-input"
              />
            </v-layout>
          </v-flex>
          <v-flex>
            <v-btn
              class="btn_small_normal"
              depressed
              outlined
              text
              @click="closeAddMessage()"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!--    suspend form-->
    <general-dialog
      :active="suspendStatus"
      title="از تعلیق فرم اطمینان دارید؟"
      content-class="confirmationMessageDialog"
      description="فرم غیرفعال شده و تا فعال شدن مجدد، امکان پرداخت توسط آن وجود ندارد"
      btn1-text="خیر، تعلیق نشود"
      btn2-text="بلی، تعلیق شود"
      @btn1Clicked="close_modal()"
      @btn2Clicked="suspendForm(false)"
    >
    </general-dialog>

    <!--    delete form-->
    <v-dialog
      v-model="deleteStatus"
      content-class="confirmationMessage confirmSimple"
      width="360"
    >
      <v-card height="100%" outlined>
        <v-layout align-center column justify-center>
          <v-flex class="main-title font_1"> از حذف فرم اطمینان دارید؟</v-flex>
          <v-flex class="main-des font_4">
            امکان بازگشت مجدد این فرم برای شما وجود ندارد
          </v-flex>
          <v-flex>
            <v-btn class="btn_small_normal" dark text @click="close_modal()">
              خیر، حذف نشود
            </v-btn>
            <v-btn
              class="btn_small_normal"
              depressed
              outlined
              text
              @click="deleteForm()"
            >
              بلی، حذف شود
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import observer from "@/components/elements/observer"
import CopyBtnGeneral from "@/components/elements/copyBtnGeneral"
import add from "../modals/add"
import show from "../modals/show"
import GeneralDialog from "@/components/modals/generalDialog.vue"

export default {
  name: "RequestMoneyTable",
  components: {
    GeneralDialog,
    add,
    show,
    CopyBtnGeneral,
    observer,
  },
  props: {
    filters: {
      default() {
        return {}
      },
      type: Object,
    },
    showResultBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      loadMoreSkeleton: false,
      componentKey: 0,
      //this is modal status
      status: false,
      add: false,
      edit: false,
      view: false,
      suspendStatus: false,
      deleteStatus: false,
      calledId: null,
      forms: [],
      load: true,
      addIsFinalize: false,
      updatedForm: {},
      endOfLoadMoreRequest: false,
      currentPageRequest: 1,
      selectedForm: null,
    }
  },
  watch: {
    status: function (newValue) {
      if (!newValue) this.close_modal()
    },
  },
  mounted() {
    this.intersected()
  },
  methods: {
    close_modal(obj) {
      this.add = false
      this.edit = false
      this.view = false
      this.calledId = null
      this.status = false
      this.selectedForm = null
      this.suspendStatus = false
      this.deleteStatus = false
      if (obj && obj.status) {
        this.componentKey = !this.componentKey
      }
    },
    add_function() {
      this.close_modal()
      this.add = true
      this.status = true
    },
    view_function(id) {
      this.close_modal()
      this.calledId = id
      this.view = true
      setTimeout(() => {
        this.status = true
      }, 50)
    },
    add_form(form) {
      this.updatedForm = form
      this.addIsFinalize = true
      this.forms.unshift(form)
    },
    edit_form(formId) {
      this.$http
        .get(this.$store.getters.apiUrlParam + "/request-forms/" + formId, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.selectedForm = response.data.data.form
          this.calledId = formId
          this.edit = true
          this.status = true
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    suspendFormCall(id) {
      this.calledId = id
      this.suspendStatus = true
    },
    unsuspendFormCall(id) {
      this.calledId = id
      this.suspendForm(true)
    },
    suspendForm(value) {
      let data = {
        active: value,
      }
      this.$http
        .post(
          this.$store.getters.apiUrlParam +
            "/request-forms/" +
            this.calledId +
            "/update/active",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          const message = value ? "فرم پرداخت فعال شد" : "فرم پرداخت تعلیق شد"
          this.$store.commit("callSuccessSnackbar", message)
          this.update_form_list(response.data.data.form)
          this.close_modal()
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    deleteFormCall(id) {
      this.calledId = id
      this.deleteStatus = true
    },
    deleteForm() {
      this.$http
        .delete(
          this.$store.getters.apiUrlParam + "/request-forms/" + this.calledId,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.commit("callSuccessSnackbar", " فرم پرداخت حذف شد")
          this.delete_form(this.calledId)
          this.close_modal()
        })
        .catch((error) => {
          //callsnackbar)
        })
    },
    delete_form(formId) {
      this.forms.forEach((element, index) => {
        if (element.id == formId) {
          this.$delete(this.forms, index)
        }
      })
    },
    update_form_list(form) {
      var formId = form.id
      this.forms.forEach((element, index) => {
        if (element.id == formId) {
          this.$set(this.forms, index, form)
        }
      })
    },
    closeAddMessage() {
      this.addIsFinalize = false
      this.updatedForm = {}
    },
    intersected(kind) {
      let url = ""

      if (kind == "loadMore") {
        //load more call this func, keep search?
        this.currentPageRequest = this.currentPageRequest + 1
        this.loadMoreSkeleton = true
      } else {
        //searching
        this.currentPageRequest = 1
        this.loading = true
      }

      // add filters to the url if they are not null or default value
      for (let el in this.filters) {
        if (this.filters[el] && this.filters[el] !== "0") {
          url += "&" + el + "=" + this.filters[el]
        }
      }

      let cur = this.currentPageRequest
      this.$http
        .get(
          this.$store.getters.apiUrlParam + "/request-forms?page=" + cur + url,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (kind == "loadMore") {
            let temporaryList = this.forms
            response.data.data.forms.data.forEach((element) => {
              temporaryList.push(element)
            })
            this.forms = temporaryList
            this.currentPageRequest = response.data.data.forms.current_page
            if (
              response.data.data.forms.last_page ==
              response.data.data.forms.current_page
            ) {
              this.endOfLoadMoreRequest = true
            }
          } else {
            this.forms = response.data.data.forms.data
          }
          let total = response.data.data.forms.total
          this.$emit("getTotal", total)
          this.loading = false
          this.loadMoreSkeleton = false
        })
        .catch((error) => {
          //callsnackbar
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
