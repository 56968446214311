<template>
  <div id="header">
    <div class="logo">
      <a href="https://vandar.io">
        <v-img :src="$store.state.vandarIconCdn" width="38" alt="Vandar" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {}
  },
}
</script>
