<template>
  <div
    v-if="isBusinessActive && ipgStatus !== 'ACTIVE'"
    class="activationBlock py-6 px-2 mb-6"
  >
    <span
      :class="
        !legalNameRejected && pendingStatus
          ? 'topBack pendingTopBack'
          : 'topBack '
      "
    />
    <template v-if="!legalNameRejected">
      <div v-if="nationalCodeStatus != 'FAILED'">
        <v-layout
          :column="isMobileMode"
          :align-center="$vuetify.breakpoint.smAndUp"
        >
          <v-img
            width="100px"
            height="100px"
            max-width="100px"
            max-height="100px"
            class="ml-4"
            :class="{ 'd-none': isMobileMode }"
            :src="
              pendingStatus
                ? require('@/assets/img/ipg-soft-reject-pending.svg')
                : require('@/assets/img/ipg-soft-reject.svg')
            "
          />
          <v-flex
            class="shaparak-soft-reject-banner"
            :class="{ 'mb-2': isMobileMode }"
          >
            <template v-if="pendingStatus">
              <h1 class="shaparak-soft-reject-banner__title">
                در حال بررسی اطلاعات درگاه پرداخت
              </h1>
            </template>
            <template v-else>
              <h1 class="shaparak-soft-reject-banner__title">
                اصلاح اطلاعات درگاه پرداخت
              </h1>
            </template>

            <v-dialog
              v-model="isOpenFirstModal"
              :content-class="
                isOpenSecondModal ? 'secondModal vanmodal' : 'vanmodal'
              "
              transition="slide-x-transition"
              :width="isMobileMode ? '100%' : '600'"
              :fullscreen="isMobileMode"
              no-click-animation
              @click:outside="isOpenFirstModal = false"
            >
              <v-card>
                <v-toolbar flat height="60" class="fixed_toolbar">
                  <v-btn icon @click.native="isOpenFirstModal = false">
                    <v-icon color="van_color03" size="21"> close </v-icon>
                  </v-btn>
                  <v-toolbar-title class="pr-0">
                    اصلاح اطلاعات درگاه پراخت
                  </v-toolbar-title>
                </v-toolbar>
                <ShaparakActivationItems
                  @is-open-second-modal="isOpenSecondModal = true"
                  @close-shaparak-modal="isOpenSecondModal = false"
                />
              </v-card>
            </v-dialog>
          </v-flex>

          <v-btn
            v-if="!pendingStatus"
            color="white"
            width="126"
            height="48"
            elevation="0"
            class="pa-3"
            style="border: 1px solid #999999 !important"
            @click="isOpenFirstModal = true"
          >
            <span class="font-14-no-666-24"> اصلاح </span>
            <v-img
              width="18px"
              height="18px"
              max-height="18px"
              max-width="18px"
              class="mr-2"
              :src="require('@/assets/img/Arrow.svg')"
            />
          </v-btn>
        </v-layout>
      </div>

      <v-layout
        v-else-if="nationalCodeStatus == 'FAILED'"
        :column="isMobileMode"
        style="position: inherit"
      >
        <v-flex>
          <h1 class="font_23 mb-1">هویت اعلام شده، تایید نشد</h1>
          <span class="font_10">
            نوع هویت شما با اطلاعات مالیاتی ثبت شده در شاپرک مطابقت ندارد. جهت
            رفع لطفا با
            <a
              style="
                font-weight: bold;
                border-bottom: 1px dashed;
                color: #666666;
                text-decoration: none;
              "
              :href="
                isMobileMode ? 'tel:02142196000' : 'https://vandar.io/contact'
              "
              target="_blank"
            >
              ما تماس بگیرید
            </a>
          </span>
        </v-flex>
      </v-layout>
    </template>
    <v-layout
      v-else
      :column="isMobileMode"
      style="position: inherit"
      justify-space-between
    >
      <v-flex
        style="width: 100%; max-width: 642px"
        :class="isMobileMode ? 'mb-3' : null"
      >
        <h1 class="font_23 mb-3">نام حقوقی از سمت شاپرک خطا دریافت کرد.</h1>
        <span class="font_10">
          نام حقوقی ثبت شده در وندار، با نام واقعی شرکت همخوانی ندارد. به همین
          ترتیب برای اطمینان بیشتر

          <a
            style="
              font-weight: bold;
              border-bottom: 1px dashed;
              text-decoration: none;
            "
            :href="
              isMobileMode ? 'tel:02142196000' : 'https://vandar.io/contact'
            "
            target="_blank"
            >با پشتیبانی تماس
          </a>
          بگیرید</span
        >
      </v-flex>
    </v-layout>
    <generalDialog
      :active="statusHasChangedToPending"
      logo="Warning.svg"
      title="باید منتظر تایید شاپرک باشیم"
      description="اطلاعات شما را برای شاپرک ارسال کردیم تا صحت آن را بررسی کند. نتیجه آن را به شما اطلاع خواهیم داد"
      content-class="confirmationMessageDialog"
      btn2-text="منتظر می‌مانم"
      btn2-class="btn_small_normal"
      custom-style="height:290px"
      @btn2Clicked="statusHasChangedToPending = false"
    />
  </div>
</template>

<script>
import generalDialog from "../../modals/generalDialog.vue"
import ShaparakActivationItems from "./ShaparakActivationItems.vue"
import { mapGetters } from "vuex"

export default {
  name: "ShaparakActivationBlock",
  components: {
    generalDialog,
    ShaparakActivationItems,
  },
  data() {
    return {
      statusHasChangedToPending: false,
      time: 0,
      ipgTime: 0,
      changeBirthCertificateNumber: false,
      changeMccCode: false,
      isShowShaparakActivationItems: false,
      isOpenSecondModal: false,
      isOpenFirstModal: false,
    }
  },
  computed: {
    ...mapGetters(["isBusinessActive"]),
    pendingStatus() {
      return (
        this.$store.state.business.tax_code !== null &&
        this.$store.state.business.tax_code_detail.tax_code_status ===
          "PENDING" &&
        this.postalCodStatus === "PENDING" &&
        this.$store.state.business.default_iban &&
        (this.requestMoneyStatus === "PENDING" || this.ipgStatus === "PENDING")
      )
    },
    postalCodStatus() {
      return this.$store.state.business.postal_code_detail.postal_code_status
    },
    nationalCodeStatus() {
      if (!this.$store.state.isUser)
        return this.$store.state.business.national_code_detail
          .national_code_status
      return null
    },
    businessHasToolsProperty() {
      return Object.prototype.hasOwnProperty.call(
        this.$store.state.business,
        "tools"
      )
    },
    ipgStatus() {
      return this.$store.state.business.tools.ipg.status
    },
    requestMoneyStatus() {
      if (!this.businessHasToolsProperty) return false
      return this.$store.state.business.tools.requestMoney.status
    },
    legalNameRejected() {
      if (!this.$store.state.isUser)
        return (
          this.$store.state.business.rejects.legal_name &&
          this.$store.state.business.status === 1
        )
      return false
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  mounted() {
    if (!this.$store.state.isUser) {
      this.businessActiveCountDown(
        this.$store.state.business.tools.ipg.time,
        "ipg"
      )
      this.businessActiveCountDown(
        this.$store.state.business.tools.requestMoney.time,
        "requestMoney"
      )
    }
  },
  methods: {
    businessActiveCountDown(startTime, tools) {
      var secondTime = 0
      startTime = startTime ? startTime : "00:00:00"
      var sec = parseInt(startTime.split(":")[2])
      var min = parseInt(startTime.split(":")[1])
      var hour = parseInt(startTime.split(":")[0])

      secondTime = hour * 3600 + min * 60 + sec
      if (tools == "ipg") this.ipgCountdownfunc(secondTime)
      else if (tools == "requestMoney") this.countdownfunc(secondTime)
    },
    countdownfunc(startPoint) {
      var downloadTimer = setInterval(() => {
        startPoint -= 1
        if (startPoint <= 0) {
          clearInterval(downloadTimer)
        }
        this.time = startPoint
      }, 1000)
    },
    ipgCountdownfunc(startPoint) {
      var downloadTimer = setInterval(() => {
        startPoint -= 1
        if (startPoint <= 0) {
          clearInterval(downloadTimer)
        }
        this.ipgTime = startPoint
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.shaparak-soft-reject-banner {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    font-size: 20px;
    color: $van_color02;
    line-height: 30px;
    margin-bottom: 8px;
  }
}
</style>
