var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',[_c('v-flex',[_c('v-layout',{staticClass:"rows",attrs:{"column":""}},[(_vm.$store.state.customer.selectedCustomer.created_at)?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" تاریخ ثبت ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.created_at)+" ")])],1)],1):_vm._e(),(
          _vm.$store.state.customer.selectedCustomer.customer.type ==
            'INDIVIDUAL' &&
          _vm.$store.state.customer.selectedCustomer.customer.name
        )?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" نام و نام خانوادگی ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.name)+" ")])],1)],1):_vm._e(),(
          _vm.$store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
          _vm.$store.state.customer.selectedCustomer.customer.legal_name
        )?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" نام حقوقی ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.legal_name)+" ")])],1)],1):_vm._e(),(
          _vm.$store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
          _vm.$store.state.customer.selectedCustomer.customer.agent_name
        )?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" نام نماینده ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.agent_name)+" ")])],1)],1):_vm._e(),(
          _vm.$store.state.customer.selectedCustomer.customer.type ==
            'INDIVIDUAL' &&
          _vm.$store.state.customer.selectedCustomer.customer.mobile
        )?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" تلفن همراه ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.mobile)+" ")])],1)],1):_vm._e(),(
          _vm.$store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
          _vm.$store.state.customer.selectedCustomer.customer.agent_mobile
        )?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" تلفن همراه نماینده ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.agent_mobile)+" ")])],1)],1):_vm._e(),(_vm.$store.state.customer.selectedCustomer.customer.telephone)?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" تلفن ثابت ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.telephone)+" ")])],1)],1):_vm._e(),(
          _vm.$store.state.customer.selectedCustomer.customer.type ==
            'INDIVIDUAL' &&
          _vm.$store.state.customer.selectedCustomer.customer
            .individual_national_code
        )?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" کدملی ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer .individual_national_code)+" ")])],1)],1):_vm._e(),(
          _vm.$store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
          _vm.$store.state.customer.selectedCustomer.customer.legal_national_code
        )?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" شناسه ملی ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer .legal_national_code)+" ")])],1)],1):_vm._e(),(_vm.$store.state.customer.selectedCustomer.customer.city)?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" استان/شهر ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.province)+" - "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.city)+" ")])],1)],1):_vm._e(),(_vm.$store.state.customer.selectedCustomer.customer.address)?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" آدرس ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.address)+" ")])],1)],1):_vm._e(),(_vm.$store.state.customer.selectedCustomer.customer.postal_code)?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" کد پستی ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.postal_code)+" ")])],1)],1):_vm._e(),(
          _vm.$store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
          _vm.$store.state.customer.selectedCustomer.customer.mcc_code
        )?_c('v-flex',[_c('v-layout',[_c('v-flex',{staticClass:"label"},[_vm._v(" حوزه فعالیت ")]),_c('v-flex',[_vm._v(" "+_vm._s(_vm.$store.state.customer.selectedCustomer.customer.mcc_name)+" ")])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }