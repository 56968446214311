<template>
  <v-layout
    :column="$vuetify.breakpoint.xsOnly"
    justify-space-between
    align-start
  >
    <v-flex class="label"> {{ labelText }} </v-flex>
    <v-flex class="withlabel big">
      <v-textarea
        v-model="textArea"
        solo
        flat
        rows="3"
        :rules="rules"
        outlined
        :hint="hints"
      >
        <template #message="message">
          <div v-html="message.message" />
        </template>
      </v-textarea>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "VanTextareaWithLabel",
  props: {
    labelText: {
      type: String,
    },
    hints: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      rules: [
        (v) =>
          !v ||
          v.length < 255 ||
          `<span>${this.labelText}باید حداکثر ۲۵۵ کاراکتر باشد</span>`,
      ],
      textArea: null,
    }
  },
}
</script>
