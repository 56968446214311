<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">اطلاعات هویتی</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          v-if="$store.state.user.status != 1"
          ref="btn"
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout>
      <v-flex>
        <div class="font_2">
          اطلاعات خاکستری، قابل تغییر نیستند. جهت تغییر آنها،
          <v-btn
            class="small under"
            text
            :ripple="false"
            @click="pushToContactUs()"
          >
            با ما تماس بگیرید
          </v-btn>
        </div>
        <v-divider />
        <v-form ref="form" v-model="valid">
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              تلفن همراه
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="mobileNumber"
                solo
                flat
                height="40"
                outlined
                readonly
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              نام
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="fname"
                :rules="fnameRules"
                solo
                flat
                height="40"
                outlined
                :readonly="$store.state.user.status == 1"
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              نام خانوادگی
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="lname"
                :rules="lnameRules"
                solo
                flat
                height="40"
                outlined
                :readonly="$store.state.user.status == 1"
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              کد ملی
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="nationalCode"
                solo
                flat
                height="40"
                outlined
                readonly
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              تاریخ تولد
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel date_group">
              <v-layout>
                <v-flex>
                  <v-select
                    v-model="day"
                    :items="dayItem"
                    solo
                    flat
                    height="40"
                    outlined
                    label="روز"
                    :rules="dateRules"
                    :validate-on-blur="alreadyFullday"
                    :readonly="$store.state.user.status == 1"
                  />
                </v-flex>
                <v-flex>
                  <v-select
                    v-model="month"
                    :items="monthItem"
                    solo
                    flat
                    height="40"
                    outlined
                    label="ماه"
                    :rules="dateRules"
                    :validate-on-blur="alreadyFullmonth"
                    :readonly="$store.state.user.status == 1"
                    @input="changeEndDay()"
                  />
                </v-flex>
                <v-flex>
                  <v-select
                    v-model="year"
                    :items="yearItem"
                    solo
                    flat
                    height="40"
                    outlined
                    label="سال"
                    :rules="dateRules"
                    :validate-on-blur="alreadyFullyear"
                    :readonly="$store.state.user.status == 1"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <!--          <v-layout-->
          <!--            :column="$vuetify.breakpoint.xsOnly"-->
          <!--            justify-start-->
          <!--            align-start-->
          <!--          >-->
          <!--            <v-flex class="label">-->
          <!--              کد پستی-->
          <!--              <span>*</span>-->
          <!--            </v-flex>-->
          <!--            <v-flex class="withlabel">-->
          <!--              <postalCode-->
          <!--                :inputValue="postal_code"-->
          <!--                @setValue="postal_code = $event"-->
          <!--              />-->
          <!--            </v-flex>-->
          <!--          </v-layout>-->
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
// import postalCode from '@/components/inputs/postalCode.vue'
export default {
  name: "SettingInfo",
  props: [],
  data: function () {
    return {
      loading: false,
      valid: false,
      fname: "",
      lname: "",
      nationalCode: null,
      day: null,
      month: null,
      year: null,
      postal_code: null,
      mobileNumber: null,
      fnameRules: [
        (v) => !!v || " وارد کردن نام الزامی است ",
        (v) =>
          /^[\u0600-\u065F\u066E-\u06EF\s]+$/.test(v) ||
          " نام باید با حروف فارسی وارد شود",
        (v) =>
          (v && v.length >= 2) || " طول نام باید  2 یا بیشتر از 2 حرف باشد ",
        (v) => (v && v.length < 30) || " نام باید کمتر از 30 حرف باشد ",
      ],
      lnameRules: [
        (v) => !!v || " وارد کردن نام خانوادگی الزامی است",
        (v) =>
          /^[\u0600-\u065F\u066E-\u06EF\s]+$/.test(v) ||
          " نام خانوادگی باید با حروف فارسی وارد شود ",
        (v) =>
          (v && v.length >= 2) ||
          "طول نام خانوادگی باید 2 یا بیشتر از 2 حرف باشد ",
        (v) => (v && v.length < 40) || " نام خانوادگی باید کمتر از 40 حرف باشد",
      ],
      dateRules: [
        (v) => !!v || "<span> وارد کردن تاریخ تولد الزامی است </span>",
      ],
      messageNationalCode: "",
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      end: 31,
      alreadyFullday: true,
      alreadyFullmonth: true,
      alreadyFullyear: true,
      convertMonth: null,
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.valid) return true
      else return false
    },
    dayItem() {
      var temp = []
      for (var i = 0; i < this.end; i++) {
        temp[i] = i + 1
      }
      return temp
    },
    yearItem() {
      var d = new Date()
      var n = d.getFullYear()
      var b = n - 621 - 18
      var temp = []
      for (var i = 0; i < 81; i++) {
        temp[i] = b - i
      }
      return temp
    },
  },
  created() {
    if (this.$store.state.user.mobile != null) {
      this.mobileNumber = this.$store.state.user.mobile
    }
    if (this.$store.state.user.national_code != null) {
      this.nationalCode = this.$store.state.user.national_code
    }
    if (this.$store.state.user.fname != null) {
      this.fname = this.$store.state.user.fname
    }
    if (this.$store.state.user.lname != null) {
      this.lname = this.$store.state.user.lname
    }
    if (this.$store.state.user.postal_code != null) {
      this.postal_code = this.$store.state.user.postal_code
    }
    if (this.$store.state.user.birthdate_year != null) {
      this.year = this.$store.state.user.birthdate_year
      this.alreadyFullyear = false
    }
    if (this.$store.state.user.birthdate_month != null) {
      this.month = this.monthItem[this.$store.state.user.birthdate_month - 1]
      this.alreadyFullmonth = false
    }
    if (this.$store.state.user.birthdate_day != null) {
      this.day = this.$store.state.user.birthdate_day
      this.alreadyFullday = false
    }
    //TO DO:because of validate-on-blur attribute, after choosing last v-select you have to click any where else to validate it.
  },
  methods: {
    pushToContactUs() {
      var url = "https://vandar.io/contact"
      window.open(url)
    },
    changeEndDay() {
      switch (this.month) {
        case "مهر":
        case "آبان":
        case "آذر":
        case "دی":
        case "بهمن":
        case "اسفند":
          this.end = 30
          break
        default:
          this.end = 31
          break
      }
      if (this.day > this.end) {
        this.day = null
      }
    },
    sendForm() {
      this.monthItem.forEach((element, index) => {
        if (element == this.month) {
          this.convertMonth = index + 1
        }
      })
      if (this.$refs.form.validate()) {
        this.loading = true
        var data = {
          // postal_code: this.postal_code,
          national_code: this.nationalCode,
          year: this.year.toString(),
          month: this.convertMonth.toString(),
          day: this.day.toString(),
          fname: this.fname,
          lname: this.lname,
        }
        this.$http
          .post(
            this.$store.state.api_base_url + "/personal/update/profile",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.$store.commit("setUser", response.data.data.user)
            this.$store.commit("updateStatusBox", {
              key: "personal_information",
              value: "accepted",
            })
            this.$store.commit(
              "callSuccessSnackbar",
              "تغییر با موفقیت ذخیره شد"
            )
            this.loading = false
            this.$emit("closeModal", { status: true })
          })
          .catch((error) => {
            //callsnackbar
          })
      }
    },
  },
  // components: {
  //   postalCode,
  // },
}
</script>
