<template>
  <div>
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      outlined
      class="business"
      color="white"
      flat
    >
      <div class="titles">
        <div class="income" style="width: 200px">
          <h2 style="display: inline">
            <span class="default_gray" />
          </h2>
        </div>
        <div class="income">
          <span class="default_gray" />
        </div>
      </div>
      <div class="more-data">
        <div class="skeletonShadowLoading bus bus-right" />
        <div class="skeletonShadowLoading bus bus-right" />
        <div class="skeletonShadowLoading bus bus-left" />
      </div>
      <v-layout column class="botton">
        <v-flex>
          <span class="default_gray" />
        </v-flex>
        <v-flex>
          <span class="default_gray" />
        </v-flex>
      </v-layout>
    </v-card>
    <v-card
      v-if="!$vuetify.breakpoint.mdAndUp"
      outlined
      class="business-md-down"
      color="white"
      flat
    >
      <v-layout wrap justify-space-between>
        <v-flex>
          <div class="titles">
            <div class="income" style="width: 332px">
              <h2 style="display: inline">
                <span class="default_gray" />
              </h2>
            </div>
            <div class="income">
              <span class="default_gray" />
            </div>
          </div>
        </v-flex>
        <v-flex style="max-width: 40px; margin-left: -10px">
          <v-menu
            transition="slide-y-transition"
            offset-y
            close-on-content-click
            bottom
            content-class="up_menu"
          >
            <!-- this on slot sometime is undefined -->
            <template #activator="{ on }">
              <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                <v-icon color="van_color03" size="21" style="line-height: 40px">
                  more_vert
                </v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout class="more-data" align-center justify-center fill-height>
        <v-flex>
          <div class="skeletonShadowLoading bus bus-right" />
        </v-flex>
        <v-flex>
          <div class="skeletonShadowLoading bus bus-right" />
        </v-flex>
        <v-flex>
          <div class="skeletonShadowLoading bus bus-left" />
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CardSkeleton",
}
</script>

<style lang="scss" scoped>
.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}
.business {
  height: 120px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;
  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}
.business-md-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;
  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
  .more-data {
    display: flex;
    //width: 250px;
    margin-top: 16px;
  }
  .bus {
    background-color: $van_color11;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 11px 5px;
    height: 80px;
    width: 100%;
    margin: 5px;
    max-width: 95%;

    &.bus-right,
    &.bus-left {
      width: 95%;
    }

    &.flat {
      background: transparent;
      color: $van_pink;
      font-size: 12px;
      font-weight: bold;
      height: 28px;
    }
  }
}
.v-avatar {
  margin: 0 0 10px 0;
}
.titles {
  max-width: 125px;
}
h2 {
  margin: 0 0 9px 0;
  line-height: 22px;
}
.income {
  font-size: 10px;
  color: #999;
}
.bus-right {
  margin-left: 10px;
}
.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;
  &.bus-right,
  &.bus-left {
    width: 50%;
  }
  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}
.business .more-data {
  display: flex;
  width: 384px;
}
.mablagh {
  font-size: 14px;
  font-weight: bold;
}
.sub-text {
  font-size: 9px;
  color: #999;
}
.layout.botton {
  max-width: 88px;
  .v-btn {
    width: calc(100% - 16px);
    margin-top: 3px;
    margin-bottom: 3px;
  }
}
</style>
