<template>
  <div>
    <SearchInputVandar
      v-model="search"
      input-class="w-100 mb-6"
      input-style="width:100%"
      dont-show-btn
      place-holder="جستجو باشماره شبا و شناسه واریز"
      :should-delete-search="shouldDeleteSearch"
    />

    <template v-if="!loading">
      <p class="font_2">
        مبلغ این تراکنش‌ها به کیف‌پول شما اضافه نشده‌اند و به صورت سیستمی تعیین
        وضعیت می‌شوند.
      </p>
      <div class="font-size-12 van_color03--text mb-6">
        {{ $store.state.suspiciousOnTheWayCount }} تراکنش در انتظار واریز به
        کیف‌پول
      </div>
      <div v-for="(item, index) in items" :key="item.id">
        <SuspiciousOnTheWayItem
          :item="item"
          style="margin-bottom: 4px !important"
        ></SuspiciousOnTheWayItem>
        <Observer
          v-if="index === items.length - 2 && items.length < total"
          :key="'observe' + item.id"
          @intersect="nextPage()"
        />
      </div>
    </template>
    <template v-if="loading || moreItem">
      <OnTheWaySuspiciousItemSkeleton v-for="i in 5" :key="'skeleton' + i" />
    </template>
  </div>
</template>

<script>
import OnTheWaySuspiciousItemSkeleton from "@/components/businessHome/elements/suspicious/OnTheWaySuspiciousItemSkeleton.vue"
import Observer from "@/components/elements/observer.vue"
import SuspiciousOnTheWayItem from "@/components/businessHome/elements/suspicious/SuspiciousOnTheWayItem.vue"
import SearchInputVandar from "@/components/elements/searchInputVandar.vue"
import servicesRepository from "@/services/servicesRepository"
import { resetSuspiciousSearchModel } from "@/utils/generalMethods"
import { mapState } from "vuex"

export default {
  name: "OnTheWaySuspicious",
  components: {
    SearchInputVandar,
    OnTheWaySuspiciousItemSkeleton,
    Observer,
    SuspiciousOnTheWayItem,
  },
  props: {
    tab: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      search: "",
      total: 0,
      page: 1,
      showDetail: false,
      timeOut: null,
      moreItem: false,
      lastPage: null,
      shouldDeleteSearch: false,
    }
  },
  computed: {
    ...mapState(["shouldGetSuspiciousRefundOnTheWay"]),
  },
  watch: {
    search(newValue) {
      this.resetPage()
      this.loading = true
      this.resetItemsList()
      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }

      if (!newValue) {
        this.getSuspiciousPaymentOnTheWay(this.page)
      } else {
        this.timeOut = setTimeout(() => {
          this.getSearchedSuspiciousPaymentOnTheWay(this.page)
        }, 800)
      }
    },
    shouldGetSuspiciousRefundOnTheWay: {
      handler(val) {
        if (val) {
          this.resetItemsList()
          this.resetPage()
          this.getSuspiciousPaymentOnTheWay()
          this.$store.commit("callTheGetSuspiciousRefundOnTheWay", false)
        }
      },
      immediate: true,
    },
    tab() {
      resetSuspiciousSearchModel(this)
    },
  },
  mounted() {
    this.getSuspiciousPaymentOnTheWay(this.page)
  },
  methods: {
    nextPage() {
      this.page += 1
      if (this.page <= this.lastPage) {
        this.moreItem = true
        if (this.search) {
          this.getSearchedSuspiciousPaymentOnTheWay(this.page)
        } else {
          this.getSuspiciousPaymentOnTheWay(this.page)
        }
      }
    },
    getSuspiciousPaymentOnTheWay(page = 1) {
      servicesRepository(this)
        .suspiciousPayment.httpGetSuspiciousPaymentOnTheWay(page)
        .then((response) => this.handleDataAfterFetching(response))
    },
    getSearchedSuspiciousPaymentOnTheWay(page) {
      const queryParam = {
        page,
        searchQuery: this.search,
      }
      servicesRepository(this)
        .suspiciousPayment.httpGetSearchedSuspiciousPaymentOnTheWay(queryParam)
        .then((response) => this.handleDataAfterFetching(response))
    },
    handleDataAfterFetching(response) {
      this.loading = false
      this.lastPage = response.data.meta.last_page
      response.data.data.map((item) => this.items.push(item))
      this.total = response.data?.meta.total
      this.$store.commit(
        "changeSuspiciousOnTheWayCount",
        response.data?.meta?.total
      )
      this.moreItem = false
    },
    resetItemsList() {
      this.items = []
    },
    resetPage() {
      this.page = 1
    },
  },
}
</script>

<style scoped lang="scss"></style>
