import { render, staticRenderFns } from "./CreatingIpgStep.vue?vue&type=template&id=0bf3a99e&scoped=true&"
import script from "./CreatingIpgStep.vue?vue&type=script&lang=js&"
export * from "./CreatingIpgStep.vue?vue&type=script&lang=js&"
import style0 from "./CreatingIpgStep.vue?vue&type=style&index=0&id=0bf3a99e&prod&lang=scss&scoped=true&"
import style1 from "./CreatingIpgStep.vue?vue&type=style&index=1&id=0bf3a99e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf3a99e",
  null
  
)

export default component.exports