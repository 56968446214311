<template>
  <general-card>
    <div class="d-flex flex-column flex-sm-row justify-space-between">
      <!-- logo-->
      <div class="d-flex align-center mb-5 mb-sm-0">
        <div class="bankLogoSquare pa-2 ml-2 radius-4">
          <v-img :src="bankLogo" width="16" height="16" />
        </div>
        <div class="mr-2 font_2 bold bankName">
          بانک
          {{ bank.name }}
        </div>
      </div>
      <!-- details info-->
      <div
        class="d-flex flex-column details"
        :style="{ 'min-width': $vuetify.breakpoint.xsOnly ? '246px' : '276px' }"
      >
        <show-daily-amount
          v-for="(item, index) in amountList"
          :key="item + index"
          :amount="item.amount"
          :label="item.label"
        ></show-daily-amount>
      </div>
    </div>
  </general-card>
</template>

<script>
import GeneralCard from "../../elements/generalCard"
import ShowDailyAmount from "@/components/A2A/elements/showDailyAmount"
export default {
  name: "ShowDailyItem",
  components: { ShowDailyAmount, GeneralCard },
  props: {
    bank: {
      default: [],
    },
  },
  computed: {
    amountList() {
      return [
        {
          label: "سقف کلی",
          amount: this.totalInToman,
        },
        {
          label: "مصرف امروز",
          amount: this.usedInToman,
        },
        {
          label: "باقی مانده",
          amount: this.remainedInToman,
        },
      ]
    },
    amountLimits() {
      return this.bank?.a2a?.limit?.amount_limit
    },
    totalInToman() {
      return this.amountLimits?.total === -1
        ? -1
        : this.amountLimits?.total / 10
    },
    usedInToman() {
      return this.amountLimits?.used / 10
    },
    remainedInToman() {
      return this.amountLimits?.remained === -1
        ? -1
        : this.amountLimits?.remained / 10
    },
    bankLogo() {
      return require(`@/assets/img/banks/${parseInt(this.bank.code)}.svg`)
    },
  },
}
</script>

<style lang="scss" scoped>
.details {
  > div {
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
