<template>
  <v-card outlined class="business" color="white" max-width="280px" flat>
    <v-avatar
      class="skeletonShadowLoading van_color11"
      size="80"
      style="min-height: 80px; border-radius: 50%"
    />
    <h2 style="display: inline; width: 200px">
      <span class="default_gray mt-2 mx-auto mb-0" />
    </h2>
    <div class="income" style="width: 100px">
      <span style="margin: 0 auto" class="default_gray my-0 mx-auto" />
    </div>
    <div class="more-data">
      <div class="bus skeletonShadowLoading" />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "AccountCardSkeleton",
}
</script>

<style lang="scss" scoped>
.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}
.business {
  width: 280px;
  height: 251px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0 0px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  cursor: default;
  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
  border: 0px;
  &.v-card--hover {
    position: absolute;
    bottom: 0%;
    width: 100%;
  }
}
.newbusiness {
  min-height: 110px !important;
  width: 110px !important;
  // background-color: rgba(76, 159, 135, 0.1);
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
  > div {
    min-width: 70px;
    // background-color: $van_green;
    height: 70px;
    border-radius: 50%;
    img {
      width: 24px;
    }
  }
}
.v-avatar {
  margin: 0 0 10px 0;
}
h2 {
  margin: 0 0 7px 0;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 25px;
  max-width: 85%;
}
.income {
  margin: 0 0 15px 0;
  font-size: 10px;
  color: #999;
}
.bus-right {
  margin-left: 10px;
}
.bus {
  &.pending {
    background-color: rgba(249, 211, 88, 0.2);
    position: relative;
    .sub-text {
      color: $van_color02;
    }
    .istyle1 {
      position: absolute;
      right: -3px;
    }
  }
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 60px;
  width: 100%;
  &.bus-right,
  &.bus-left {
    width: 50%;
  }
  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}
.business .more-data {
  display: flex;
  width: calc(100% - 40px);
  //width: -moz-available;          /* WebKit-based browsers will ignore this. */
  //width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  //width: fill-available;
  margin: 0 20px 20px 20px;
}
.mablagh {
  font-size: 14px;
  font-weight: bold;
}
.sub-text {
  font-size: 9px;
  color: #999;
}
.v-card--hover:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  height: 288px;
  transition: all ease 0.5s;
  z-index: 5;
}
.layout.row.botton {
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  &:last-child .v-btn {
    margin: 0;
    border-left: 1px solid #afafaf;
  }
  .v-btn {
    width: 100%;
    font-size: 10px;
    color: #ffffffcc;
    background-color: #666 !important;
    border-radius: 0px;
    text-decoration: none;
    &:active,
    &:focus,
    &:hover {
      &:before {
        display: none;
      }
      background-color: $van_green !important;
      color: #fff;
    }
  }
}
</style>
