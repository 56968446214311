<template>
  <chart-card-one-filter
    class="mmt-5"
    :title="kerdarLang.directDebit.banksDepositFrequencyTitle"
    :show-filter="true"
    :duration="localDuration"
    :selected-duration-prop="{
      name: kerdarLang.general.currentMonth,
      value: 'current_month',
    }"
    :growth-text="fiterationResult"
    @changeFilter="changeTimeDurationFilter($event)"
  >
    <one-bar-horizontal-bar-type2
      v-if="banksDepositFrequencyHasData"
      :x-value="bankName"
      :y-value="depositFrequencyValue"
      :y-result="bankDepositFrequencyBarTooltip"
      :first-bar-color="maxFrequencyBarColor"
      :y-unit="kerdarLang.general.license"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </chart-card-one-filter>
</template>

<script>
import ChartCardOneFilter from "@/components/kerdar/components/chartCardOneFilter"
import OneBarHorizontalBarType2 from "@/components/kerdar/components/oneBarHorizontalBarType2"
import { sortData } from "@/components/kerdar/utils/generalMethods"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

export default {
  name: "DirectDebitBanksDepositsFrequency",
  components: {
    "chart-card-one-filter": ChartCardOneFilter,
    "one-bar-horizontal-bar-type2": OneBarHorizontalBarType2,
  },
  props: {
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      banksDepositFrequencyData: [],
      banksDepositFrequencyHasData: false,
      maxFrequencyBarColor: "#4C9F87",
      selectedTimeDuration: null,
      totalValue: 0,
      localDuration: [
        {
          name: this.kerdarLang.general.currentMonth,
          value: "current_month",
        },
        {
          name: this.kerdarLang.general.currentSeason,
          value: "current_quarter",
        },
        {
          name: this.kerdarLang.general.currentYear,
          value: "current_year",
        },
        {
          name: this.kerdarLang.general.lastMonth,
          value: "last_month",
        },
        {
          name: this.kerdarLang.general.lastSeason,
          value: "last_quarter",
        },
        {
          name: this.kerdarLang.general.lastYear,
          value: "last_year",
        },
      ],
    }
  },
  computed: {
    bankName() {
      return this.banksDepositFrequencyData.map((item) => item[0])
    },
    depositFrequencyValue() {
      return this.banksDepositFrequencyData.map((item) => Number(item[1]))
    },
    calculateTotalValue() {
      let total = 0
      if (
        this.selectedTimeDuration &&
        this.banksDepositFrequencyData.length > 0
      ) {
        for (let data of this.banksDepositFrequencyData) {
          total += data[1]
        }
      }
      return total
    },
    specifyBankWithMaxValue() {
      let valuesList = []
      for (let data of this.banksDepositFrequencyData) {
        valuesList.push(data[1])
      }
      const maxValue = Math.max(...valuesList)
      const maxValueIndex = valuesList.findIndex(
        (number) => number === maxValue
      )
      const maxValueData = this.banksDepositFrequencyData[maxValueIndex]
      return maxValueData
    },
    bankDepositFrequencyBarTooltip() {
      return this.banksDepositFrequencyData.map((item) => {
        const percentage =
          this.totalValue == 0
            ? 0
            : Math.round(
                ((100 * item[1]) / this.totalValue + Number.EPSILON) * 100
              ) / 100
        const text =
          '<span style="font-weight: bold;color: #4C9F87">' +
          percentage +
          `${this.kerdarLang.general.hasBeenDoneDepositsWithTag}` +
          ` ${this.kerdarLang.general.belongTo} ` +
          item[0] +
          ` ${this.kerdarLang.general.is}.`

        return text
      })
    },
    fiterationResult() {
      if (
        this.banksDepositFrequencyData &&
        this.banksDepositFrequencyData.length
      ) {
        const bankWithHighestDepositFrequency = this.specifyBankWithMaxValue

        const whiteSpace = " "
        const text =
          "<span class='bold van_green--text'>" +
          bankWithHighestDepositFrequency[0] +
          "</span>" +
          whiteSpace +
          this.kerdarLang.general.with +
          whiteSpace +
          bankWithHighestDepositFrequency[1].toLocaleString() +
          whiteSpace +
          this.kerdarLang.general.deposit +
          whiteSpace +
          this.kerdarLang.general.from +
          whiteSpace +
          this.totalValue.toLocaleString() +
          whiteSpace +
          this.kerdarLang.general.deposit +
          whiteSpace +
          this.kerdarLang.directDebit.firstRank

        return text
      } else {
        return
      }
    },
  },
  methods: {
    resetBanksDepositFrequencyData() {
      this.banksDepositFrequencyData = []
      this.banksDepositFrequencyHasData = false
    },
    changeTimeDurationFilter(timeDuration) {
      this.selectedTimeDuration = timeDuration
      this.resetBanksDepositFrequencyData()
      this.getBanksDepositFrequencyData(timeDuration)
    },
    async getBanksDepositFrequencyData(item) {
      const chartType = item.value
      if (!chartType) {
        return
      }

      const response = await kerdarServices(
        this.$http,
        this
      ).directDebit.httpDirectDebitIncomeFrequencyCount(chartType)

      this.banksDepositFrequencyData = sortData(response.data.data)
      this.banksDepositFrequencyHasData = true
      this.totalValue = this.calculateTotalValue
    },
  },
}
</script>

<style lang="scss" scoped></style>
