<template>
  <v-layout align-center fill-height justify-center>
    <v-layout align-start column justify-center wrap>
      <section class="chart-title">
        <h2
          v-if="
            $store.state.hasOwnProperty('business') &&
            $store.state.business.hasOwnProperty('tools') &&
            $store.state.business.tools.hasOwnProperty('subscription') &&
            $store.state.business.tools.subscription.is_created
          "
          class="mb-4 mr-0 ml-auto"
        >
          وضعیت پرداخت خودکار
        </h2>
        <KerdarNavigationLink />
      </section>
      <!-- chart -->
      <v-flex
        v-if="
          $store.state.hasOwnProperty('business') &&
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.hasOwnProperty('subscription') &&
          $store.state.business.tools.subscription.is_created &&
          $store.state.business.status == 1 &&
          $store.state.business.role != 'developer'
        "
        class="mb-5"
        style="width: 100%"
      >
        <DirectDebitDepositEChart :kerdar-lang="kerdarLang" />
      </v-flex>
      <!-- cards -->
      <v-flex style="width: 100%">
        <listOfCards />
      </v-flex>
      <!-- banks -->
      <v-flex style="width: 100%">
        <BankList />
      </v-flex>

      <!-- webhook signature -->
      <v-flex style="width: 100%">
        <webhook-signature />
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import listOfCards from "../elements/listOfCards.vue"
import BankList from "@/components/subscription/elements/BankList"
import WebhookSignature from "@/components/subscription/elements/webhookSignature/WebhookSignature"
import DirectDebitDepositEChart from "@/components/kerdar/tabs/direct-debit/elements/DirectDebitDepositEChart"
import kerdarLang from "@/components/kerdar/utils/langResource.json"
import KerdarNavigationLink from "@/components/kerdar/components/KerdarNavigationLink.vue"

export default {
  name: "Index",
  components: {
    WebhookSignature,
    BankList,
    listOfCards,
    DirectDebitDepositEChart,
    KerdarNavigationLink,
  },
  data() {
    return {
      status: false,
      kerdarLang,
    }
  },
  watch: {
    "$store.state.business": {
      handler: function (newValue) {
        if (
          this.$store.state.hasOwnProperty("business") &&
          this.$store.state.business.hasOwnProperty("role") &&
          this.$store.state.business.role != "developer"
        ) {
          this.fetchUbscriptionChart()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    //check if business is loaded like ipg
    if (
      this.$store.state.hasOwnProperty("business") &&
      this.$store.state.business.hasOwnProperty("role") &&
      this.$store.state.business.role != "developer"
    ) {
      this.fetchUbscriptionChart()
    }
  },
  beforeDestroy() {
    this.$store.commit("destroySubscription")
  },
  methods: {
    fetchUbscriptionChart() {
      this.$http
        .get(this.$store.getters.apiUrlParam + "/chart?channel=subscription", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then(() => {
          this.status = true
        })
        .catch((error) => {
          //callsnackbar
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.bank-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.image-box {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  width: 56px;
  height: 56px;
  border-radius: 4px;
}

.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}

.business {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  box-shadow: none;
  margin: auto;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.business-md-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }

  .more-data {
    display: flex;
    //width: 250px;
    margin-top: 16px;
  }

  .bus {
    background-color: $van_color11;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 11px 5px;
    height: 80px;
    width: 100%;
    margin: 5px;
    max-width: 95%;

    &.bus-right,
    &.bus-left {
      width: 95%;
    }

    &.flat {
      background: transparent;
      color: $van_pink;
      font-size: 12px;
      font-weight: bold;
      height: 28px;
    }
  }
}

.v-avatar {
  margin: 0 0 10px 0;
}

.titles {
  max-width: 295px;
}

h2 {
  margin: 0 0 9px 0;
  line-height: 20px;
}

.income {
  font-size: 12px;
  color: #666;
  font-weight: normal;

  h2 {
    font-size: 14px;
  }
}

.bus-right {
  margin-left: 10px;
}

.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;
  max-width: 120px;

  &.bus-right,
  &.bus-left {
    width: 50%;
  }

  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}

.business .more-data {
  display: flex;
  width: 384px;
}

.mablagh {
  font-size: 14px;
  font-weight: bold;
}

.sub-text {
  font-size: 9px;
  color: #999;
}

.layout.botton {
  max-width: 88px;

  .v-btn {
    width: calc(100% - 16px);
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.v-chip--small {
  height: 25px !important;
  margin-top: 5px !important;
}

.full-width {
  width: 100%;
}

.chart-title {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}
</style>
