import { render, staticRenderFns } from "./SkeletonDataTable.vue?vue&type=template&id=fbcf1686&scoped=true&"
import script from "./SkeletonDataTable.vue?vue&type=script&lang=js&"
export * from "./SkeletonDataTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbcf1686",
  null
  
)

export default component.exports