export default {
  namespaced: true,
  state: {
    shouldResetPriceInput: false,
    accountFilter: null,
    transactionType: null,
    priceRangeStatement: "",
    priceFrom: null,
    priceTo: null,
    duration: "",
    fromDate: null,
    toDate: null,
    areFiltersApplied: false,
    isSearchApplied: false,
    selectedSearchType: "",
    searchQuery: "",
    shouldGetData: false,
    bankInvoiceItems: [],
    isShowSkeleton: false,
    bankInvoiceUrl: "",
    transactionSource: "All",
  },
  mutations: {
    toggleResetPriceInput(state, resetStatus) {
      state.shouldResetPriceInput = resetStatus
    },
    setAccountFilter(state, accountFilter) {
      state.accountFilter = accountFilter
    },
    setTransactionType(state, transactionType) {
      state.transactionType = transactionType
    },
    setPriceRangeStatement(state, priceRangeStatement) {
      state.priceRangeStatement = priceRangeStatement
    },
    setPriceFrom(state, priceFrom) {
      state.priceFrom = priceFrom
    },
    setPriceTo(state, priceTo) {
      state.priceTo = priceTo
    },
    setDuration(state, duration) {
      state.duration = duration
    },
    setFromDate(state, fromDate) {
      state.fromDate = fromDate
    },
    setToDate(state, toDate) {
      state.toDate = toDate
    },
    setTableFilters(state, status) {
      state.areFiltersApplied = status
    },
    toggleSearchAppliedStatus(state, status) {
      state.isSearchApplied = status
    },
    setSearchQuery(state, searchQuery) {
      state.searchQuery = searchQuery
    },
    setSelectedSearchType(state, selectedSearchType) {
      state.selectedSearchType = selectedSearchType
    },
    toggleShouldGetData(state, status) {
      state.shouldGetData = status
    },
    setBankInvoiceItems(state, payload) {
      payload.bankInvoiceItems.forEach((element, index) => {
        element.rowNumber =
          index + 1 + (payload.page - 1) * payload.itemsPerPage
        state.bankInvoiceItems.push(element)
      })
    },
    resetBankInvoiceItems(state) {
      state.bankInvoiceItems = []
    },
    toggleSkeletonStatus(state, status) {
      state.isShowSkeleton = status
    },
    specifyBankInvoiceUrl(state, payload) {
      let url = ""
      url = `?page=${payload.page}`
      let priceFrom = state.priceFrom
      let priceTo = state.priceTo
      if (payload.currencyName === "تومان") {
        priceFrom *= 10
        priceTo *= 10
      }
      if (payload.per_page) url += `&per_page=${payload.per_page}`

      payload.transactionSource === "Vandar"
        ? (url += `&vandar=${1}`)
        : (url += `&vandar=${0}`)

      if (state.areFiltersApplied) {
        if (priceFrom) url += "&price_from=" + priceFrom
        if (priceTo) url += "&price_to=" + priceTo
        if (state.transactionType) url += "&type=" + state.transactionType
        if (state.accountFilter) url += "&account=" + state.accountFilter

        if (state.duration && state.duration !== "timeRange") {
          url += "&duration=" + state.duration
        } else {
          if (state.fromDate) url += "&date_from=" + state.fromDate
          if (state.toDate) url += "&date_to=" + state.toDate
        }
      }

      if (state.selectedSearchType && state.searchQuery) {
        url += "&search_field=" + state.selectedSearchType
        url += "&query=" + state.searchQuery
      }

      state.bankInvoiceUrl = url
    },
    specifyTransactionSource(state, transactionSource) {
      state.transactionSource = transactionSource
    },
  },
  actions: {},
}
