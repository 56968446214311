<template>
  <div>
    <v-layout
      :align-center="!$vuetify.breakpoint.xsOnly"
      :column="$vuetify.breakpoint.xsOnly"
      justify-space-between
    >
      <v-flex>
        <h2 class="mb-4 mr-0 ml-auto">فاکتورها</h2>
      </v-flex>
      <v-spacer />
      <div :class="$vuetify.breakpoint.xsOnly ? 'mb-3' : ''">
        <v-layout align-start justify-space-between>
          <v-flex>
            <SearchBar
              v-if="
                ($store.state.business.hasOwnProperty('tools') &&
                  $store.state.business.tools.subscription.is_created) ||
                !(invoices.length == 0 && loading == false)
              "
              :input-value="searchQuery"
              placeholder=" جستجو..."
              @searchClicked="searchInvoice($event)"
            />
          </v-flex>
        </v-layout>
      </div>
    </v-layout>

    <v-layout
      v-if="
        ($store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.subscription.is_created) ||
        !(invoices.length == 0 && loading == false)
      "
      align-center
      class="filter_row"
      justify-start
      wrap
    >
      <v-flex v-if="!$vuetify.breakpoint.xsOnly">
        <v-radio-group v-model="statusFilters" row @change="putFilter()">
          <v-radio label="همه" value="0" />
          <!-- <v-radio label="در انتظار پرداخت" value="PENDING"></v-radio> -->
          <!-- <v-radio label="در حال برداشت" value="INIT"></v-radio> -->
          <v-radio label="موفق" value="DONE" />
          <v-radio label="ناموفق" value="FAILED" />
          <v-radio label="بازگشت‌خورده" value="REFUND" />
          <!-- <v-radio label="لغو شده" value="CANCELED"></v-radio> -->
        </v-radio-group>
      </v-flex>
      <v-flex v-else class="selectFilter">
        <v-select
          v-model="statusFilters"
          :items="statusFilterItem"
          flat
          height="40"
          item-text="value"
          item-value="key"
          label="همه وضعیت‌ها"
          outlined
          solo
          @change="putFilter()"
        />
      </v-flex>
    </v-layout>

    <div style="position: relative">
      <!-- table skeleton -->
      <v-data-table
        v-if="invoices[0] === 'skeleton'"
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'elevation-1 vandarTable smAndDownTable'
            : 'elevation-1 vandarTable noHover'
        "
        :headers="headers"
        :hide-default-header="totalInvoices !== 0 ? false : true"
        :items="invoices"
        :items-per-page-items="rows"
        :options.sync="pagination"
        :server-items-length="totalInvoices"
        hide-default-footer
        items-per-page-text="نمایش در هر صفحه "
        no-data-text="تراکنشی برای نمایش وجود ندارد"
      >
        <template #item>
          <!-- empty responsive table -->
          <v-layout
            v-if="$vuetify.breakpoint.smAndDown"
            align-space-between
            class="big"
            fill-height
            justify-space-between
          >
            <v-flex class="right-number font_2">
              <span class="default_gray" style="width: 20%" />
            </v-flex>
            <v-flex>
              <v-layout column>
                <v-flex class="mb-5">
                  <v-layout>
                    <!-- right-top part Start-->
                    <v-flex>
                      <div style="display: flex; align-items: center">
                        <img
                          :src="require('@/assets/img/Ascending.svg')"
                          height="14"
                          style="
                            margin: 0 10px 0 25px;
                            filter: grayscale(100%);
                            opacity: 0.3;
                          "
                          width="16"
                        />
                        <span class="default_gray" style="max-width: 60%" />
                      </div>
                    </v-flex>
                    <!-- right-top part End-->
                    <!-- left-top part Start-->
                    <v-flex style="text-align: left">
                      <span
                        class="default_gray"
                        style="float: left; max-width: 30%"
                      />
                    </v-flex>
                    <!-- left-top part End-->
                  </v-layout>
                </v-flex>
                <v-flex>
                  <v-layout :column="$vuetify.breakpoint.xsOnly" class="font_2">
                    <!-- first description part Start-->
                    <v-flex style="margin-bottom: 15px; max-width: 250px">
                      <div>
                        <span class="default_gray" />
                      </div>
                      <div>
                        <span class="default_gray" style="max-width: 60%" />
                      </div>
                    </v-flex>
                    <!-- first description part End -->
                    <v-divider
                      :column="$vuetify.breakpoint.xsOnly"
                      class="my-0 mx-5"
                      vertical
                    />
                    <!-- second description part Start-->
                    <v-flex>
                      <!-- succeed 1 AND failed -1 -->
                      <div class="text-right">
                        <span class="default_gray" style="max-width: 50%" />
                        <div>
                          <span class="default_gray" style="max-width: 30%" />
                        </div>
                      </div>
                    </v-flex>
                    <!-- second description part End -->
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- empty table -->
          <tr v-else class="big">
            <!-- 1 -->
            <!--                        <td class="text-center">-->
            <!--                          <span style="width: 20%" class="default_gray" />-->
            <!--                        </td>-->

            <!-- 2 -->
            <td class="text-right padding-right-0 pl-0" style="width: 100px">
              <div class="d-flex pt-0 pr-2" style="width: unset">
                <span class="default_gray" style="width: 10%" />
                <div class="pt-0" style="display: flex; align-items: center">
                  <img
                    :src="require('@/assets/img/Ascending.svg')"
                    height="14"
                    style="filter: grayscale(100%); opacity: 0.3"
                    width="16"
                  />
                </div>
              </div>
            </td>

            <!-- 3 -->
            <td class="text-right padding-right-0">
              <span class="default_gray" />
              <div>
                <span class="default_gray" style="width: 40%" />
              </div>
            </td>

            <!-- 4 -->
            <td class="text-right padding-right-0">
              <span class="default_gray" />
            </td>

            <!-- 5 -->
            <td class="text-right padding-right-0 big">
              <span class="default_gray" />
              <div>
                <span class="default_gray" style="width: 50%" />
              </div>
            </td>

            <!-- 6 -->
            <td class="text-right padding-right-0 big">
              <span class="default_gray" />
            </td>
          </tr>
        </template>
        <template #no-data>
          <span class="vandar-table-empty">
            {{ lang.invoices.thereIsNoInvoice }}
          </span>
        </template>
      </v-data-table>

      <!-- filled table -->
      <v-data-table
        v-else
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'elevation-1 vandarTable smAndDownTable noHover'
            : 'elevation-1 vandarTable noHover'
        "
        :headers="headers"
        :hide-default-header="totalInvoices !== 0 ? false : true"
        :items="invoices"
        :items-per-page-items="rows"
        :options.sync="pagination"
        :server-items-length="totalInvoices"
        hide-default-footer
        items-per-page-text="نمایش در هر صفحه "
        no-data-text="فاکتوری برای نمایش وجود ندارد"
      >
        <template #item="{ item }">
          <!-- responsive table -->
          <v-layout
            v-if="$vuetify.breakpoint.smAndDown"
            :class="rowClass(item.status)"
            align-space-between
            fill-height
            justify-space-between
            @click="getDetails(item)"
          >
            <v-flex class="right-number font_2">
              {{ item.rowNumber }}
            </v-flex>
            <v-flex>
              <v-layout column>
                <v-flex class="mb-5">
                  <v-layout>
                    <!-- right-top part Start-->
                    <v-flex>
                      <div style="display: flex; align-items: center">
                        <img
                          :src="require('@/assets/img/Ascending.svg')"
                          class="my-0 mr-2 ml-6"
                          height="14"
                          width="16"
                        />
                        <span v-if="item.status == 'DONE'">
                          واریز موفق
                          <div
                            v-if="parseInt(item.wage_amount) != 0"
                            class="font_8"
                          >
                            (کارمزد
                            {{ parseInt(item.wage_amount) }}
                            {{ currencyName }})
                          </div>
                        </span>
                        <span v-else-if="item.status == 'INIT'"
                          >در حال واریز</span
                        >
                        <span v-else-if="item.status == 'PENDING'"
                          >در انتظار واریز</span
                        >
                        <span v-else-if="item.status == 'FAILED'"
                          >واریز ناموفق</span
                        >
                        <span v-else-if="item.status == 'REFUND'"
                          >واریز بازگشت‌خورده</span
                        >
                        <span v-else-if="item.status == 'CANCELED'"
                          >لغو شده</span
                        >
                      </div>
                    </v-flex>
                    <!-- right-top part End-->
                    <!-- left-top part Start-->
                    <v-flex style="text-align: left">
                      <span
                        v-if="item.amount || item.amount == 0"
                        class="text-right"
                        dir="ltr"
                        >{{ (item.amount / 10) | currency }}</span
                      >
                      <span v-else class="text-right">--</span>
                      {{ currencyName }}
                    </v-flex>
                    <!-- left-top part End-->
                  </v-layout>
                </v-flex>
                <v-flex>
                  <v-layout :column="$vuetify.breakpoint.xsOnly" class="font_2">
                    <!-- first description part Start-->
                    <v-flex style="margin-bottom: 15px; max-width: 250px">
                      <div>
                        زمان برداشت:
                        {{ item.withdrawal_date }}
                      </div>
                    </v-flex>
                    <!-- first description part End -->
                    <v-divider
                      :column="$vuetify.breakpoint.xsOnly"
                      class="my-0 mx-5"
                      vertical
                    />
                    <!-- second description part Start-->
                    <v-flex> پرداخت خودکار</v-flex>
                    <!-- second description part End -->
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <!-- table -->
          <tr v-else :class="rowClass(item.status)" @click="getDetails(item)">
            <!-- 1 -->
            <td class="text-center" style="max-width: 40px">
              <div>{{ item.rowNumber }}</div>
            </td>

            <!-- 2 -->
            <td class="text-right padding-right-0">
              <div style="display: flex; align-items: center">
                <img
                  :src="require('@/assets/img/Ascending.svg')"
                  class="my-0 mr-2 ml-6"
                  height="14"
                  width="16"
                />
                <span v-if="item.status == 'DONE'">
                  واریز موفق
                  <div v-if="parseInt(item.wage_amount) != 0" class="font_8">
                    (کارمزد
                    {{ parseInt(item.wage_amount) }}
                    {{ currencyName }})
                  </div>
                </span>
                <span v-else-if="item.status == 'INIT'">در حال واریز</span>
                <span v-else-if="item.status == 'PENDING'"
                  >در انتظار واریز</span
                >
                <span v-else-if="item.status == 'FAILED'">واریز ناموفق</span>
                <span v-else-if="item.status == 'REFUND'"
                  >واریز بازگشت‌خورده</span
                >
                <span v-else-if="item.status == 'CANCELED'">لغو شده</span>
              </div>
            </td>

            <!-- 4 -->
            <td class="text-right">
              {{ item.withdrawal_date }}
            </td>

            <!-- 5 -->
            <td class="text-right">پرداخت خودکار</td>

            <!-- 6 -->
            <td
              v-if="item.amount || item.amount == 0"
              class="text-right"
              dir="ltr"
            >
              {{ (item.amount / 10) | currency }}
            </td>
            <td v-else class="text-right">--</td>
          </tr>
        </template>
        <template #no-data>
          <span class="vandar-table-empty">
            {{ lang.invoices.thereIsNoInvoice }}
          </span>
        </template>
      </v-data-table>
      <table-paginate
        v-if="totalInvoices > 20"
        :items-length="totalInvoices"
        :total-pages="totalPages"
        @page="pagination.page = $event"
        @rowsPerPage="pagination.rowsPerPage = $event"
      />
    </div>

    <!-- modals -->
    <v-dialog
      v-model="status"
      :content-class="'vanmodal'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      transition="slide-x-transition"
    >
      <show v-if="status" :item="selectedForm" @closeModal="close_modal" />
    </v-dialog>
    <!-- modals
    <v-dialog
      :content-class="'vanmodal'"
      v-model="status"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
      persistent
    >
      <show v-if="status" @closeModal="close_modal" :item="selectedForm" />
    </v-dialog>
    -->

    <!-- <v-dialog
      :content-class="'vanmodal'"
      v-model="filterDateStatus"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
      persistent
    >
      <dateFilter
        v-if="filterDateStatus"
        @closeModal="close_dateFilter_modal"
        :toDateProp="toDate"
        :fromDateProp="fromDate"
      />
    </v-dialog>-->
  </div>
</template>
<script>
import show from "@/components/report/modals/show.vue"

//import show from "../modals/show.vue";
//import dateFilter from "../modals/dateFilter.vue";
import SearchBar from "@/components/inputs/SearchBar"
import TablePaginate from "../../elements/tablePaginate"
import lang from "@/lang/languageResource.json"

export default {
  components: {
    TablePaginate,
    SearchBar,
    show,
    //dateFilter
  },
  props: ["update"],
  data() {
    return {
      status: false,
      totalInvoices: 1,
      invoices: [],
      loading: true,
      pagination: {
        descending: false,
        page: 1,
        itemsPerPage: 20,
        // sortBy: null,
        itemsLength: 0,
      },
      totalPages: 0,
      rows: ["20", "50", "100"],
      selectedForm: {},
      downloadLoading: false,
      statusFilters: "0",
      kindFilter: "0",
      statusFilterItem: [
        {
          key: "0",
          value: "همه",
        },
        // {
        //   key: 'PENDING',
        //   value: 'در انتظار پرداخت',
        // },
        // {
        //   key: "INIT",
        //   value: "در حال برداشت"
        // },
        {
          key: "DONE",
          value: "واریز موفق",
        },
        {
          key: "FAILED",
          value: "واریز ناموفق",
        },
        {
          key: "REFUND",
          value: "واریز بازگشت‌خورده",
        },
        // {
        //   key: 'CANCELED',
        //   value: 'لغو شده',
        // },
      ],
      filterDateStatus: false,
      fromDate: null,
      toDate: null,
      fromDateText: null,
      toDateText: null,
      searchQuery: "",
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      lang,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "rowNumber",
        },
        {
          text: "وضعیت",
          align: "right",
          sortable: false,
          value: "status",
        },
        {
          text: "زمان",
          align: "right",
          sortable: false,
          value: "date",
        },
        {
          text: "اطلاعات تراکنش",
          align: "right",
          sortable: false,
          value: "discription",
        },
        {
          text: "مبلغ (" + this.currencyName + ")",
          align: "right",
          sortable: false,
          value: "amount",
        },
      ]
    },
  },
  watch: {
    invoices: {
      handler() {
        if (this.invoices.length == 0 && this.loading == true) {
          for (let index = 0; index < this.pagination.itemsPerPage; index++) {
            this.invoices[index] = "skeleton"
          }
        }
      },
      immediate: true,
    },
    pagination: {
      handler() {
        //add some more skeleton
        this.getDataFromApi().then((data) => {
          this.invoices = data.items
          this.totalInvoices = data.total
        })
      },
      deep: true,
    },
    update: function (newValue, oldValue) {
      if (newValue == true) {
        this.loading = true
        this.invoices = []
        this.getDataFromApi().then((data) => {
          this.invoices = data.items
          this.totalInvoices = data.total
          this.$emit("doneUpdateTable")
        })
      }
    },
    "$route.params.filtered": {
      handler: function (newValue) {
        if (newValue == "successfullTransaction") {
          this.statusFilters = "DONE"
          this.searchQuery = this.$route.params.uuid
          this.putFilter()
        }
        if (newValue == "allTransaction") {
          this.searchQuery = this.$route.params.uuid
          this.putFilter()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    searchInvoice(item) {
      this.searchQuery = item
      this.putFilter()
    },
    clearDate(which) {
      if (which == "from") {
        this.fromDate = null
        this.fromDateText = null
      } else if (which == "to") {
        this.toDate = null
        this.toDateText = null
      }
      this.getDataFromApi().then((data) => {
        this.invoices = data.items
        this.totalInvoices = data.total
      })
    },
    getDetails(item) {
      if (
        item.status == "DONE" ||
        item.status == "FAILED" ||
        item.status == "REFUND"
      ) {
        var id = item.gateway_transaction_id
        this.$http
          .get(
            this.$store.getters.apiWithVersionParam("3") +
              "/transaction?id=" +
              id,
            {
              timeout: 0,
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.selectedForm = response.data.data[0]
            this.status = true
          })
          .catch((error) => {
            //callsnackbar
          })
      }
    },
    close_dateFilter_modal(obj) {
      this.filterDateStatus = false
      if (obj.status) {
        this.fromDate = obj.fromDate
        this.toDate = obj.toDate
        this.fromDateText = obj.fromDateText
        this.toDateText = obj.toDateText
        this.getDataFromApi().then((data) => {
          this.invoices = data.items
          this.totalInvoices = data.total
        })
      }
    },
    close_modal(obj) {
      this.status = false
      if (obj.status) {
        // this.getDataFromApi()
        //   .then(data => {
        //     this.invoices = data.items
        //     this.totalInvoices = data.total
        //   })
        this.$emit("updateSettelment")
      }
    },
    putFilter() {
      this.getDataFromApi().then((data) => {
        this.invoices = data.items
        this.totalInvoices = data.total
      })
    },
    rowColor(status) {
      switch (status) {
        case "DONE":
          return "#4C9F87"
        case "FAILED":
          return "#D44D42"
        case "INIT":
          return "#F9D358"
        case "REFUND":
          return "#BBBBBB"
        case "CANCELED":
          return "#BBBBBB"
        case "PENDING":
          return "#F9D358"
        default:
          break
      }
    },
    rowClass(status) {
      switch (status) {
        case "DONE":
          return "hover succeed big"

        case "FAILED":
          return "hover failed big"

        case "REFUND":
          return "hover settlment_canceled big"

        case "INIT":
          return "pending big"

        case "CANCELED":
          return "settlment_canceled big"

        case "PENDING":
          return "pending big"

        default:
          break
      }
    },
    getDataFromApi() {
      this.loading = true
      this.invoices = []
      return new Promise((resolve, reject) => {
        const { sortBy, descending, page, itemsPerPage } = this.pagination
        var url

        url =
          "/subscription/withdrawal?page=" + page + "&per_page=" + itemsPerPage

        if (this.statusFilters != "0") {
          url = url + "&status=" + this.statusFilters
        }

        //add date
        /*if (this.fromDate != null) {
          url = url + "&fromDate=" + this.fromDate;
        }
        if (this.toDate != null) {
          url = url + "&toDate=" + this.toDate;
        }*/

        //add search query
        if (this.searchQuery) {
          url = url + "&q=" + this.searchQuery
        }

        this.$http
          .get(this.$store.getters.apiUrlParam + url, {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            this.loading = false
            if (response.data !== null) {
              var items = response.data.data

              //add rowNumber to the array
              items.forEach((element, index) => {
                element.rowNumber =
                  index +
                  (response.data.meta.current_page - 1) *
                    response.data.meta.per_page +
                  1
              })

              var total = response.data.meta.total
              this.totalPages = response.data.meta.last_page
            }
            resolve({
              items,
              total,
            })
          })
          .catch((error) => {
            this.loading = false
            this.invoices = []
            this.totalInvoices = 0
            //callsnackbar
          })
      })
    },
    setDateTomodal() {
      let today = new Date()
      let priorDateTimestamp = new Date().setDate(today.getDate() - 31)
      let priorDate = new Date(priorDateTimestamp)
      let jalalitoday = today
        .toLocaleDateString("fa-IR")
        .replace(/([۰-۹])/g, (token) =>
          String.fromCharCode(token.charCodeAt(0) - 1728)
        )
      let jalaliPriorDate = priorDate
        .toLocaleDateString("fa-IR")
        .replace(/([۰-۹])/g, (token) =>
          String.fromCharCode(token.charCodeAt(0) - 1728)
        )

      //Year
      var convertFromYear = jalaliPriorDate.split("/")[0].substring(2)
      //month
      var convertFromMonth = null
      var tempMonthFrom = null
      convertFromMonth =
        this.monthItem[parseInt(jalaliPriorDate.split("/")[1]) - 1]
      if (parseInt(jalaliPriorDate.split("/")[1]) < 10) {
        tempMonthFrom = "0" + parseInt(jalaliPriorDate.split("/")[1])
      }
      //day
      var convertFromDay = null
      if (parseInt(jalaliPriorDate.split("/")[2]) < 10) {
        convertFromDay = "0" + jalaliPriorDate.split("/")[2]
      } else {
        convertFromDay = jalaliPriorDate.split("/")[2]
      }
      this.fromDate = convertFromYear + "" + tempMonthFrom + "" + convertFromDay
      //make text
      this.fromDateText =
        " از " +
        jalaliPriorDate.split("/")[2] +
        " " +
        convertFromMonth +
        " " +
        convertFromYear

      //Year
      var convertToYear = jalalitoday.split("/")[0].substring(2)
      //month
      var convertToMonth = null
      var tempMonthTo = null
      convertToMonth = this.monthItem[parseInt(jalalitoday.split("/")[1]) - 1]
      if (parseInt(jalalitoday.split("/")[1]) < 10) {
        tempMonthTo = "0" + parseInt(jalalitoday.split("/")[1])
      }
      //day
      var convertToDay = null
      if (parseInt(jalalitoday.split("/")[2]) < 10) {
        convertToDay = "0" + jalalitoday.split("/")[2]
      } else {
        convertToDay = jalalitoday.split("/")[2]
      }
      this.toDate = convertToYear + "" + tempMonthTo + "" + convertToDay
      //make text
      this.toDateText =
        " تا " +
        jalalitoday.split("/")[2] +
        " " +
        convertToMonth +
        " " +
        convertToYear

      this.callExportApi()

      this.getDataFromApi().then((data) => {
        this.invoices = data.items
        this.totalInvoices = data.total
      })
    },
    checkIfIsLessThenOneMonth() {
      var moment = require("moment-jalaali")
      moment().format()
      var firstDate =
        "13" +
        this.fromDate.slice(0, 2) +
        "/" +
        this.fromDate.slice(2, 4) +
        "/" +
        this.fromDate.slice(4, 6)
      var lastDate =
        "13" +
        this.toDate.slice(0, 2) +
        "/" +
        this.toDate.slice(2, 4) +
        "/" +
        this.toDate.slice(4, 6)

      var date1 = moment(firstDate, "jYYYY/jM/jD")
      var date2 = moment(lastDate, "jYYYY/jM/jD")
      var diff = date2.diff(date1, "days")
      if (parseInt(diff) <= 31) {
        this.callExportApi()
      } else {
        this.$store.commit(
          "callsnackbar",
          "برای دریافت فایل اکسل، بازه زمانی حداکثر 31 روزه انتخاب کنید"
        )
        //this.$store.commit('callsnackbar','بازه انتخابی شما ' + diff + 'روز است')
      }
    },
    exportTable() {
      //if no date filter exist
      if (this.fromDate == null && this.toDate == null) {
        this.setDateTomodal()
      } else if (this.fromDate == null || this.toDate == null) {
        this.$store.commit(
          "callsnackbar",
          "برای دریافت فایل اکسل، بازه زمانی حداکثر 31 روزه انتخاب کنید"
        )
      } else if (this.fromDate != null && this.toDate != null) {
        this.checkIfIsLessThenOneMonth()
      }
    },
    callExportApi() {
      this.downloadLoading = true
      const { sortBy, descending, page, itemsPerPage } = this.pagination
      var url
      url = "/transaction/export?page=" + page + "&per_page=" + itemsPerPage
      if (this.kindFilter != "0") {
        url = url + "&statusKind=" + this.kindFilter
      }
      if (this.statusFilters != "0") {
        url = url + "&status=" + this.statusFilters
      }
      //add date
      if (this.fromDate != null) {
        url = url + "&fromDate=13" + this.fromDate
      }
      if (this.toDate != null) {
        url = url + "&toDate=13" + this.toDate
      }
      //add search query
      if (this.searchQuery != null) {
        url = url + "&q=" + this.searchQuery
      }
      this.$http
        .get(this.$store.getters.apiUrlParam + url, {
          timeout: 0,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.downloadLoading = false
          if (response.data.data.url !== null) {
            window.location = response.data.data.url
          }
        })
        .catch((error) => {
          this.downloadLoading = false
          //callsnackbar
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.selectFilter {
  min-width: 100%;

  .v-input {
    width: 100% !important;
  }
}
</style>
