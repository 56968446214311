<template>
  <LeftModal
    toolbar-name="مدیریت واریز‌های شناسه‌دار"
    @closeModal="$emit('closeModal')"
  >
    <template #toolbarItems>
      <div style="cursor: pointer" @click="goToReportPage">
        <span class="font_26"> واریز‌های منتقل شده به کیف‌پول </span>
        <v-icon size="20" color="van_green"> chevron_left </v-icon>
      </div>
    </template>
    <template #subToolbar>
      <v-tabs v-model="tab" :class="[reuseIsMobile ? 'px-5' : 'px-8']">
        <v-tab class="tab-title py-2 px-4 font-size-12"> وجوه در راه </v-tab>
        <v-tab class="tab-title py-2 px-4 font-size-12">
          در انتظار تعیین وضعیت
        </v-tab>
        <v-tab class="tab-title py-2 px-4 font-size-12">
          <span v-if="reuseIsMobile"> رد شده‌ </span>
          <span v-else> تراکنش‌های رد شده‌ </span>
        </v-tab>
      </v-tabs>
    </template>
    <template>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <OnTheWaySuspicious :tab="tab" />
        </v-tab-item>
        <v-tab-item>
          <PendingSuspicious :tab="tab" />
        </v-tab-item>
        <v-tab-item>
          <RejectedSuspicious :tab="tab" />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </LeftModal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal.vue"
import OnTheWaySuspicious from "@/components/businessHome/elements/suspicious/OnTheWaySuspicious.vue"
import PendingSuspicious from "@/components/businessHome/elements/suspicious/pendingSuspicious.vue"
import RejectedSuspicious from "@/components/businessHome/elements/suspicious/rejectedSuspicious.vue"
import screenSize from "@/mixins/screenSize"

export default {
  name: "SuspiciousItems",
  components: {
    RejectedSuspicious,
    PendingSuspicious,
    LeftModal,
    OnTheWaySuspicious,
  },
  mixins: [screenSize],
  data() {
    return {
      tab: 0,
    }
  },
  mounted() {
    this.$store.commit("checkIsRoutedFromSuspiciousItemsModal", false)
    if (this.$store.state.suspiciousCount > 0) this.tab = 1
  },
  methods: {
    goToReportPage() {
      this.$store.commit("checkIsRoutedFromSuspiciousItemsModal", true)
      this.$router.push({ name: "businessReport" })
    },
  },
}
</script>

<style scoped>
:deep(.v-slide-group__prev) {
  display: none !important;
}
</style>
