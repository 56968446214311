<template>
  <div>
    <v-flex v-if="blocked > 0" class="blockedBlock">
      <span class="back2" />
      <div class="blockText">
        <svg
          style="fill: #f9d358; opacity: 0.3"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
        >
          <path
            class="a"
            d="M568.133,331.057s2.261-2.4,4.523-2.4,4.523,2.4,4.523,2.4l18.084,18.076s2.4,2.262,2.4,4.523-2.4,4.523-2.4,4.523h0l-18.084,18.084s-2.261,2.4-4.523,2.4-4.523-2.4-4.523-2.4l-18.076-18.084s-2.4-2.261-2.4-4.523,2.4-4.523,2.4-4.523Z"
            transform="translate(-547.66 -328.66)"
          />
        </svg>
        <div>
          بنا به
          <b>احکام قضایی</b>
          مبلغ {{ blocked | currency }} {{ currencyName }} از موجوی کیف پول شما
          تا اطلاع ثانوی، مسدود شده است. از همکاری شما سپاسگزاریم
        </div>
      </div>
    </v-flex>
    <div class="settlement">
      <span class="back" :style="blocked > 0 ? 'height:330px;' : ''" />
      <v-layout
        justify-center
        align-center
        :column="$vuetify.breakpoint.xsOnly"
      >
        <v-flex :xs4="!$vuetify.breakpoint.xsOnly">
          <div class="font_14">موجودی کیف پول</div>
          <div v-if="load">
            <span dir="ltr" class="font_15 ml-1">{{ amount | currency }}</span>
            <span class="font_4 bold">{{ currencyName }}</span>
          </div>
          <div v-else>
            <div class="skeleton" />
          </div>
        </v-flex>
        <v-flex :xs4="!$vuetify.breakpoint.xsOnly" class="special">
          <div class="font_2">مبلغ قابل انتقال</div>
          <div v-if="load">
            <span dir="ltr" class="font_16 ml-1">{{ today | currency }}</span>
            <span class="font_7">{{ currencyName }}</span>
          </div>
          <div v-else>
            <div class="skeleton" />
          </div>
          <v-tooltip v-if="overdraft" bottom content-class="tableTooltip">
            <template #activator="{ on }">
              <div class="overdraft">
                <div
                  :class="overdraft.remaining == 0 ? 'van_color05' : ''"
                  v-on="on"
                >
                  <v-img
                    width="18"
                    height="14.4"
                    :src="require('@/assets/img/overdraft.svg')"
                  />
                </div>
              </div>
            </template>
            <v-layout justify-space-between>
              <v-flex> اعتبار مازاد: </v-flex>
              <v-flex class="mr-7" style="text-align: left">
                <div>
                  <span dir="ltr" class="ml-1">{{
                    overdraft.balance | currency
                  }}</span>
                  <span>{{ currencyName }}</span>
                </div>
              </v-flex>
            </v-layout>
            <v-layout justify-space-between>
              <v-flex> مصرف امروز: </v-flex>
              <v-flex class="mr-7" style="text-align: left">
                <div>
                  <span dir="ltr" class="ml-1">{{
                    overdraft.used | currency
                  }}</span>
                  <span>{{ currencyName }}</span>
                </div>
              </v-flex>
            </v-layout>
            <v-layout justify-space-between>
              <v-flex> قابل استفاده: </v-flex>
              <v-flex class="mr-7" style="text-align: left">
                <div>
                  <span dir="ltr" class="ml-1">{{
                    overdraft.remaining | currency
                  }}</span>
                  <span>{{ currencyName }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-tooltip>
        </v-flex>
        <!-- <v-flex :xs4="!$vuetify.breakpoint.xsOnly">
          <div class="font_14">برای انتقال مابقی کیف پول فردا اقدام کنید</div>
        </v-flex>
        <v-flex
          style="
            max-width: 1px;
            margin: 0px;
            height: 100%;
            width: 1px;
            border-right: 1px solid #eeeeee;
          "
        ></v-flex> -->
        <v-flex :xs4="!$vuetify.breakpoint.xsOnly">
          <div
            v-if="
              load &&
              ($store.state.isUser ||
                (!$store.state.isUser &&
                  !['observer', 'operator'].includes(
                    $store.state.business.role
                  )))
            "
          >
            <v-btn
              v-if="checkWallet"
              text
              dark
              class="btn_small_normal"
              :disabled="dontLet"
              @click="$emit('openModal')"
            >
              درخواست انتقال
            </v-btn>
            <div v-else class="yellowBox font_7">
              مبالغ بالای {{ 1000 | currency }} {{ currencyName }} قابل انتقال
              است
            </div>
          </div>
          <div v-else-if="load">
            <v-btn disabled text dark class="btn_small_normal">
              درخواست انتقال
            </v-btn>
          </div>
          <div v-else>
            <div class="skeleton mt-1" />
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  props: ["update", "dontLet"],
  data() {
    return {
      load: false,
      amount: 0,
      tomorrow: 0,
      today: 0,
      blocked: 0,
      overdraft: null,
      p2p_deductible: null,
    }
  },
  computed: {
    checkWallet() {
      if (this.amount >= 1000) return true
      else return false
    },
  },
  watch: {
    update: function (newValue, oldValue) {
      if (newValue == true) {
        this.initialState()
        this.getApi()
      }
    },
  },
  mounted() {
    this.getApi()
  },
  methods: {
    initialState() {
      this.load = false
      this.amount = 0
      this.tomorrow = 0
      this.today = 0
      this.blocked = 0
    },
    getApi() {
      this.$http
        .get(this.$store.getters.apiUrlParam + "/settlement/info", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          var res = response.data.data
          this.amount = res.wallet

          //check if amount want to update
          var param
          this.$store.state.isUser ? (param = "user") : (param = "business")
          if (this.$store.state[param].wallet != this.amount) {
            this.$store.commit("changeWallet", {
              name: param,
              wallet: this.amount,
            })
          }

          this.tomorrow = res.tomorrow_deductible_amount
          this.today = res.deductible_amount
          this.blocked = res.blocked_amount
          this.overdraft = res.overdraft

          if (this.overdraft != null)
            this.p2p_deductible = this.overdraft.p2p_deductible

          this.load = true
          this.$emit("doneUpdateSettlement")
        })
        .catch((error) => {
          if (
            error &&
            error.response != undefined &&
            error.response.status &&
            error.response.status === 429
          ) {
            setTimeout(() => {
              this.getApi()
            }, 2000)
          }
          //else{
          //callsnackbar
          //}
          this.$emit("doneUpdateSettlement")
        })
    },
    convertToRial(value) {
      if (this.$store.state.user.currency == "RIAL") {
        value = value * 10
        return value
      } else return value
    },
  },
}
</script>

<style lang="scss" scoped>
.overdraft {
  background-color: $van_color10;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  bottom: -23px;
  right: calc(50% - 25px);
  cursor: pointer;
  > div {
    background-color: $van_pink;
    border-radius: 50%;
    padding: 11.82px 10px;
    display: flex;
  }
}
.skeleton {
  width: 72px;
  height: 15px;
  background-color: $van_color11;
  border-radius: 3px;
  margin: 15px auto 5px auto;
}
.blockedBlock {
  position: relative;
  z-index: 2;
  margin: -10px auto 65px;
  height: 50px;
  .back2 {
    width: 100vw;
    height: 90px;
    background-color: #f8f3e0;
    position: absolute;
    top: -26px;
    right: calc((920px - 100vw) / 2);
    bottom: 0;
    left: 0;
    z-index: 0;
    @media screen and (max-width: 1275px) {
      right: -181px;
      left: -30px;
      width: 152vw;
    }
    @media screen and (max-width: 599px) {
      //display: none;
    }
  }
  .blockText {
    position: relative;
    display: flex;
    align-items: center;
    max-height: 50px;
    > div {
      color: $van_color03;
      font-size: 12px;
      z-index: 20;
      margin-right: -25px;
    }
  }
}
.settlement {
  margin: 37px 0 65px 0;
  > span.back {
    width: 120vw;
    height: 240px;
    background-color: $van_color10;
    position: absolute;
    top: -26px;
    right: calc((920px - 100vw) / 2);
    bottom: 0;
    left: 0;
    z-index: 0;
    @media screen and (max-width: 1275px) {
      right: -181px;
      left: -30px;
      width: 152vw;
    }
    @media screen and (max-width: 599px) {
      display: none;
    }
  }
  .layout {
    width: 100%;
    height: 110px;
    background: $van_color13 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000d;
    border: 1px solid $van_color11;
    border-radius: 4px 0px 0px 4px;
    opacity: 1;
    z-index: 5;
    position: relative;
    @media screen and (max-width: 599px) {
      height: 100%;
      > .flex {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        > div:nth-child(1) {
          margin-bottom: 0px !important;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
    > .flex {
      text-align: center;
      margin: 25px 5px;
      &:not(:nth-child(5)) {
        > div:nth-child(1) {
          margin-bottom: 8px;
        }
      }
      .yellowBox {
        height: 36px;
        background-color: rgba(249, 211, 88, 0.3);
        text-align: center;
        padding: 6px 10px 0 10px;
      }
      &.special {
        min-width: 235px;
        height: 160px;
        background: $van_color13 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 20px #0000001a;
        border: 1px solid $van_color11;
        border-radius: 4px;
        opacity: 1;
        margin: 0px;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        > div:nth-child(1) {
          margin-bottom: 6px;
        }
        > div:nth-child(2) {
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>
