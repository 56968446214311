<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">توکن جدید</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout>
      <v-flex>
        <div class="font_2">
          پس از ساخت توکن می‌توانید عبارت آن را از پنجره‌ای که نمایش داده می‌شود
          کپی کنید و با برنامه‌نویسان خود به اشتراک بگذارید.
        </div>
        <v-divider class="mb-5" style="border-style: dashed" />
        <v-form ref="form" v-model="valid">
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              نام توکن
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="name"
                :rules="[(v) => !!v || '<span> نام توکن را وارد کنید </span>']"
                solo
                flat
                height="40"
                outlined
                validate-on-blur
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label"> رمز ورود به داشبورد <span>*</span> </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="password"
                :rules="[
                  (v) =>
                    !!v || '<span> رمز ورود به داشبورد را وارد کنید </span>',
                ]"
                solo
                flat
                :hint="passwordHint"
                :append-icon="show ? 'visibility' : 'visibility_off'"
                :type="show ? 'text' : 'password'"
                height="40"
                outlined
                validate-on-blur
                autocomplete="new-password"
                @click:append="show = !show"
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label"> توضیحات </v-flex>
            <v-flex class="withlabel">
              <v-textarea v-model="description" solo flat outlined rows="2">
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-textarea>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <general-dialog
      v-if="showToken"
      :active="showToken"
      logo="newIcon/check.svg"
      content-class="confirmationMessageDialog"
      title="توکن ساخته شد"
      :width="360"
      btn2-text="بستن"
      @btn2Clicked="close()"
    >
      <template slot="extra">
        <div class="font_10 text-center">
          پیش از بستن این پنجره، توکن و رفرش توکن را کپی کنید. چرا که پس از آن
          دیگر به آنها دسترسی نخواهید داشت.
        </div>
        <v-layout align-center class="mt-6">
          <v-flex
            class="font_10 van_color11 ml-1 mr-0 pt-2"
            style="
              height: 36px;
              width: 218px;
              border-radius: 4px;
              text-align: center;
            "
            readonly
          >
            توکن و رفرش توکن
          </v-flex>
          <v-btn
            ref="btn"
            text
            depressed
            class="btn_small_normal van_color06 tblCpy-receipt_url"
            style="
              min-width: 80px !important;
              margin: 0px !important;
              padding: 0;
              max-width: 80px;
              height: 36px !important;
            "
            @click="shareToken()"
          >
            <v-icon color="van_color03" class="ml-1" size="14">
              content_copy
            </v-icon>
            <span class="font_4">کپی</span>
            <v-tooltip v-model="tooltip_show" top>
              <template #activator="{ on }">
                <v-btn
                  style="
                    position: absolute;
                    opacity: 0 !important;
                    width: 1px;
                    margin: 0px;
                    padding: 0px;
                    z-index: -1;
                    left: 40px;
                  "
                  v-on="on"
                />
              </template>
              <span>کپی شد</span>
            </v-tooltip>
          </v-btn>
          <input
            id="show-hidden-input-token"
            class="hidden-input"
            :value="JSON.stringify(token)"
          />
        </v-layout>
      </template>
    </general-dialog>
  </v-card>
</template>

<script>
import generalDialog from "./generalDialog.vue"
export default {
  name: "AddToken",
  components: { generalDialog },
  data: function () {
    return {
      loading: false,
      valid: false,
      showToken: false,
      tooltip_show: false,
      name: null,
      password: null,
      description: null,
      token: null,
      show: false,
      passwordHint:
        '<div class="d-flex align-center"><svg style="max-width:10px;margin-left:5px" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 8L5.5 8L5.5 5L4.5 5L4.5 8ZM5 0.5C2.24 0.5 0 2.74 0 5.5C0 8.26 2.24 10.5 5 10.5C7.76 10.5 10 8.26 10 5.5C10 2.74 7.76 0.5 5 0.5ZM5 9.5C2.795 9.5 1 7.705 1 5.5C1 3.295 2.795 1.5 5 1.5C7.205 1.5 9 3.295 9 5.5C9 7.705 7.205 9.5 5 9.5ZM4.5 4L5.5 4V3L4.5 3V4Z" fill="#666666"/></svg>رمز عبور حساب خود را وارد کنید</div>',
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.valid) return true
      else return false
    },
  },

  methods: {
    shareToken() {
      /* Get the text field */
      let copyText = document.querySelector("#show-hidden-input-token")

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        //Alert the copied text
        this.tooltip_show = true
        setTimeout(() => {
          this.tooltip_show = false
        }, 2000)
      }
    },
    close() {
      this.$emit("closeModal", { status: true })
    },
    sendForm() {
      if (this.$refs.form.validate()) {
        this.loading = true
        var data = {
          name: this.name,
          password: this.password,
          description: this.description,
        }
        this.$http
          .post(
            this.$store.getters.apiWithVersionParam("3") + "/token/generate",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.token = response.data
            this.showToken = true
            this.loading = false
          })
          .catch(() => {
            //callsnackbar
            this.loading = false
          })
      }
    },
  },
}
</script>
