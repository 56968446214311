<template>
  <v-card-actions
    color="white"
    class="d-flex align-center justify-space-between py-3 px-5"
    style="cursor: pointer"
    @click="$emit('itemClicked')"
  >
    <span class="font_2">
    <slot />
    </span>

    <v-img
      width="16px"
      height="16px"
      max-height="16px"
      max-width="16px"
      :src="require('@/assets/img/Arrow.svg')"
    />
  </v-card-actions>
</template>

<script>
export default {
  name: "CardElement",
}
</script>

<style lang="scss" scoped></style>
