const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export function returnDynamicContent(status) {
  if (status === "closed") {
    return {
      componentName: "DoneSvg",
      iconBackgroundColor: "#E5E5E5",
      cardBackgroundColor: "#F1F1F1",
      persianStatus: "بسته شده",
    }
  } else if (status === "pending") {
    return {
      componentName: "PendingSvg",
      iconBackgroundColor: "#F9D35880",
      cardBackgroundColor: "#F9D35833",
      persianStatus: "در انتظار بررسی",
    }
  } else if (status === "in_progress") {
    return {
      componentName: "InprogressSvg",
      iconBackgroundColor: "#A14E691A",
      cardBackgroundColor: "#A14E691A",
      persianStatus: "در حال بررسی",
    }
  } else if (status === "answered") {
    return {
      componentName: "MessageSvg",
      iconBackgroundColor: "#4C9F8733",
      cardBackgroundColor: "#4C9F871A",
      persianStatus: "پاسخ داده شده",
    }
  }
}

export function convertDate(date) {
  return `${jmoment(date).fromNow()} - ${jmoment(
    date,
    "YYYY-M-D HH:mm:ss"
  ).format("HH:mm")}`
}
