<template>
  <ul class="mobile-style-row" @click="goToTicket">
    <li>
      <div v-if="loading">
        <v-skeleton-loader type="chip"></v-skeleton-loader>
      </div>
      <div
        v-else
        class="item-status"
        :style="{
          backgroundColor: returnDynamicContent(rowData.item.status)
            ?.iconBackgroundColor,
        }"
      >
        <component
          :is="returnDynamicContent(rowData.item.status)?.componentName"
        />
        <span>{{
          returnDynamicContent(rowData.item.status)?.persianStatus
        }}</span>
      </div>
    </li>
    <li>
      <div v-if="loading">
        <v-skeleton-loader width="60" type="text"></v-skeleton-loader>
      </div>
      <div v-else class="item-id">
        <span>شناسه</span>:
        {{ rowData.item.id }}
      </div>
    </li>
    <li class="mb-4">
      <div v-if="loading">
        <v-skeleton-loader width="60%" type="text"></v-skeleton-loader>
      </div>
      <div v-else class="item-title">
        {{ rowData.item.title }}
      </div>
    </li>
    <li>
      <div v-if="loading">
        <v-skeleton-loader width="55%" type="text"></v-skeleton-loader>
      </div>
      <div v-else class="grid-row">
        <span class="item-label">دسته‌بندی</span>
        <div class="item-value">
          <span>
            {{ rowData.item.category?.parent?.title }}
          </span>
          <span
            v-if="
              rowData.item.category?.parent?.title &&
              rowData.item.category?.title
            "
          >
            /
          </span>
          <span> {{ rowData.item.category?.title }} </span>
        </div>
      </div>
    </li>
    <li>
      <div v-if="loading">
        <v-skeleton-loader width="50%" type="text"></v-skeleton-loader>
      </div>
      <div v-else class="grid-row">
        <span class="item-label"> تاریخ ایجاد </span>
        <span class="item-value">
          {{ jalaliDate(rowData.item.created_at, "jYYYY/jM/jD - HH:mm") }}
        </span>
      </div>
    </li>
    <li>
      <div v-if="loading">
        <v-skeleton-loader width="50%" type="text"></v-skeleton-loader>
      </div>
      <div v-else class="grid-row">
        <span class="item-label"> بروزرسانی </span>
        <span class="item-value">
          {{ jalaliDate(rowData.item.updated_at, "jYYYY/jM/jD - HH:mm") }}
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
import { returnDynamicContent } from "../utils/generalMethods"
import MessageSvg from "@/components/tickets/components/TextMessageSvg.vue"
import PendingSvg from "@/components/tickets/components/HourGlassSvg.vue"
import DoneSvg from "@/components/tickets/components/DoneSvg.vue"
import InprogressSvg from "@/components/tickets/components/InprogressSvg.vue"

export default {
  name: "TicketsListRowForMobile",
  components: {
    MessageSvg,
    PendingSvg,
    DoneSvg,
    InprogressSvg,
  },
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      returnDynamicContent,
    }
  },
  methods: {
    goToTicket() {
      this.$emit("send-out", this.rowData)
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-style-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 16px;
  list-style-type: none;
  border-bottom: 1px solid #D5D5D5;
}

.item-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  padding: 4px 8px 4px 12px;
  border-radius: 10px 10px 0px 10px;

  font-size: 11px;
  line-height: 17px;
  color: #333333;
}

.item-id {
  color: #666666;
  font-size: 12px;
  line-height: 24px;
}

.item-title {
  word-break: break-word;
  color: #333333;
  font-size: 14px;
  line-height: 24px;
  max-width: 100%;
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
}
.item-label {
  font-size: 11px;
  line-height: 17px;
  color: #999999;
  grid-column: 1;
}

.item-value {
  font-size: 12px;
  line-height: 24px;
  color: #666666;
  grid-column: 2/5;
}

li {
  margin-bottom: 8px;
}
</style>
