export default {
  //install
  install(Vue, options) {
    // an instance method
    Vue.prototype.$getMyBanksProperty = [
      {
        nickname: "central-bank",
        name: "Central Bank of Iran",
        persianName: "بانک مرکزی جمهوری اسلامی ایران",
        code: "010",
        cardNumber: ["636795"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "sanat-o-madan",
        name: "Sanat O Madan Bank",
        persianName: "بانک صنعت و معدن",
        code: "011",
        cardNumber: ["627961"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "mellat",
        name: "Mellat Bank",
        persianName: "بانک ملت",
        code: "012",
        cardNumber: ["610433", "991975"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "IR290120020000009744184211",
        vandarAccountId: "9744184211",
      },
      {
        nickname: "refah",
        name: "Refah Bank",
        persianName: "بانک رفاه کارگران",
        code: "013",
        cardNumber: ["589463"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "maskan",
        name: "Maskan Bank",
        persianName: "بانک مسکن",
        code: "014",
        cardNumber: ["628023"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "sepah",
        name: "Sepah Bank",
        persianName: "بانک سپه",
        code: "015",
        cardNumber: ["589210"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "keshavarzi",
        name: "Keshavarzi",
        persianName: "بانک کشاورزی",
        code: "016",
        cardNumber: ["603770", "639217"],
        accountNumberAvailable: false,
        maxLimit: 500000,
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "melli",
        name: "Melli",
        persianName: "بانک ملی ایران",
        code: "017",
        cardNumber: ["603799"],
        accountNumberAvailable: false,
        maxLimit: 500000,
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "tejarat",
        name: "Tejarat Bank",
        persianName: "بانک تجارت",
        code: "018",
        cardNumber: ["627353"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "saderat",
        name: "Saderat Bank",
        persianName: "بانک صادرات ایران",
        code: "019",
        cardNumber: ["603769"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "tosee-saderat",
        name: "Tose Saderat Bank",
        persianName: "بانک توسعه صادرات",
        code: "020",
        cardNumber: ["627648", "207177"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "post",
        name: "Post Bank",
        persianName: "پست بانک ایران",
        code: "021",
        cardNumber: ["627760"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "toose-taavon",
        name: "Tosee Taavon Bank",
        persianName: "بانک توسعه تعاون",
        code: "022",
        cardNumber: ["502908"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "tosee",
        name: "Tosee Bank",
        persianName: "موسسه اعتباری توسعه",
        code: "051",
        cardNumber: ["628157"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "ghavamin",
        name: "Ghavamin Bank",
        persianName: "بانک قوامین",
        code: "052",
        cardNumber: ["639599"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "karafarin",
        name: "Karafarin Bank",
        persianName: "بانک کارآفرین",
        code: "053",
        cardNumber: ["627488", "502910"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "parsian",
        name: "Parsian Bank",
        persianName: "بانک پارسیان",
        code: "054",
        cardNumber: ["622106", "639194"],
        accountNumberAvailable: true,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "eghtesad-novin",
        name: "Eghtesad Novin Bank",
        persianName: "بانک اقتصاد نوین",
        code: "055",
        cardNumber: ["627412"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "saman",
        name: "Saman Bank",
        persianName: "بانک سامان",
        code: "056",
        cardNumber: ["621986"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "IR280560080181003061335003",
        vandarAccountId: "801-810-3061335-3",
      },
      {
        nickname: "pasargad",
        name: "Pasargad Bank",
        persianName: "بانک پاسارگاد",
        code: "057",
        cardNumber: ["639347", "502229"],
        accountNumberAvailable: true,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "sarmayeh",
        name: "Sarmayeh Bank",
        persianName: "بانک سرمایه",
        code: "058",
        cardNumber: ["639607"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "sina",
        name: "Sina Bank",
        persianName: "بانک سینا",
        code: "059",
        cardNumber: ["639346"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "mehr-iran",
        name: "Mehr Iran Bank",
        persianName: "بانک مهر ایران",
        code: "060",
        cardNumber: ["606373"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "shahr",
        name: "City Bank",
        persianName: "بانک شهر",
        code: "061",
        cardNumber: ["502806"],
        accountNumberAvailable: true,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "ayandeh",
        name: "Ayandeh Bank",
        persianName: "بانک آینده",
        code: "062",
        cardNumber: ["636214"],
        accountNumberAvailable: false,
        maxLimit: 10000000,
        vandarIbanId: "IR260620000000203443585001",
        vandarAccountId: "0203443585001",
      },
      {
        nickname: "ansar",
        name: "Ansar Bank",
        persianName: "بانک انصار",
        code: "063",
        cardNumber: ["627381"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "gardeshgari",
        name: "Gardeshgari Bank",
        persianName: "بانک گردشگری",
        code: "064",
        cardNumber: ["505416"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "hekmat-iranian",
        name: "Hekmat Iranian Bank",
        persianName: "بانک حکمت ایرانیان",
        code: "065",
        cardNumber: ["636949"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "dey",
        name: "Dey Bank",
        persianName: "بانک دی",
        code: "066",
        cardNumber: ["502938"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "iran-zamin",
        name: "Iran Zamin Bank",
        persianName: "بانک ایران زمین",
        code: "069",
        cardNumber: ["505785"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "resalat",
        name: "Resalat Bank",
        persianName: "بانک قرض الحسنه رسالت",
        code: "070",
        cardNumber: ["504172"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "kosar",
        name: "Kosar Bank",
        persianName: "موسسه مالی اعتباری کوثر",
        code: "073",
        cardNumber: ["505801"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "melal",
        name: "melal Bank",
        persianName: "موسسه اعتباری ملل",
        code: "075",
        cardNumber: ["606256"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "khavarmiane",
        name: "khavarmiane Bank",
        persianName: "بانک خاورمیانه",
        code: "078",
        cardNumber: ["585947"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "mehr-eghtesad",
        name: "mehr eghtesad Bank",
        persianName: "بانک مهر اقتصاد",
        code: "079",
        cardNumber: ["639370"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "noor",
        name: "noor",
        persianName: "موسسه اعتباری نور",
        code: "080",
        cardNumber: ["507677"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "mehr-iran",
        name: "Mehr Iran Bank",
        persianName: "بانک مهر ایران",
        code: "090",
        cardNumber: ["606373"],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "iran-venezuela",
        name: "Iran and Venezuela Bank",
        persianName: "بانک ایران و ونزوئلا",
        code: "095",
        cardNumber: [],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "hamrahCard",
        name: "hamrahCard",
        persianName: "همراه کارت",
        code: "101",
        cardNumber: [],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "poduim",
        name: "poduim",
        persianName: "حساب دیجیتال پاسارگاد",
        code: "103",
        cardNumber: [],
        accountNumberAvailable: false,
        maxLimit: 3000000, //toman
        vandarIbanId: "",
        vandarAccountId: "",
      },
      {
        nickname: "blubank",
        name: "BluBank",
        persianName: "بلوبانک",
        code: "104",
        cardNumber: "621986",
        accountNumberAvailable: false,
        maxLimit: 3000000,
        vandarIbanId: "",
        vandarAccountId: "",
      },
    ]
  },
}
