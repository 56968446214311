<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">برداشت خودکار</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          :disabled="!valid"
          class="btn_small_normal"
          @click="openSaveModal()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex style="flex: 0 0 auto">
        <v-form ref="form" v-model="validForm">
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              زمان‌بندی واریز
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel big">
              <v-select
                v-model="schedule"
                :items="scheduleEnum"
                item-text="value"
                item-value="key"
                solo
                flat
                height="40"
                outlined
                label="انتخاب کنید"
                @change="setSchedule()"
              >
                <template #item="data">
                  <v-flex>
                    <v-layout>
                      <v-flex class="font_18">
                        {{ data.item.value }}
                      </v-flex>
                      <v-flex class="font_18" style="text-align: left">
                        {{ data.item.text }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
              </v-select>
            </v-flex>
          </v-layout>

          <iban-box
            v-if="step > 1"
            label="شماره شبا"
            :loading="loading"
            :default-value="iban"
            class="hide_arrow"
            :curve-all-corners="false"
            @setSelectedIban="ibanSelect = $event"
          >
            <template #bankIcon>
              <v-flex class="selectedBankLogo ml-3 pa-2 boxed">
                <!--bank logo-->
                <v-img
                  size="30"
                  :src="
                    require(`@/assets/img/banks/${parseInt(
                      ibanSelect[0].slice(5, 7)
                    )}.svg`)
                  "
                />
              </v-flex>
            </template>
          </iban-box>

          <!--          <iban-celling v-if="ibanSelect && schedule !== 'OFF'">-->
          <!--            <iban-celling-items-->
          <!--              v-for="(row, index) in ibanCellingArray"-->
          <!--              :key="row + index"-->
          <!--              v-bind="row"-->
          <!--              @showIbanDailyLimitModal="showWhyModal()"-->
          <!--            ></iban-celling-items>-->
          <!--          </iban-celling>-->
        </v-form>
      </v-flex>

      <v-flex class="info_panel" style="margin-top: -20px">
        <v-expansion-panels flat>
          <v-expansion-panel expand>
            <v-expansion-panel-header class="pa-0">
              <div class="font_2" style="margin-bottom: 0px">
                <v-icon class="ml-1" size="16" color="van_color02">
                  error_outline </v-icon
                >برداشت خودکار چگونه کار می کند؟
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout column justify-center align-start>
                <v-flex class="font_2 mt-2" style="text-align: justify">
                  با ثبت برداشت خودکار، موجودی قابل برداشت کیف پول شما روزانه،
                  هفتگی یا ماهیانه، به حساب بانکی‌تان واریز خواهد شد. زمان واریز
                  برای همه بانک‌ها به جز بانک آینده به ترتیب زیر است:
                </v-flex>
                <v-flex class="rowsWithDivider" style="width: 100%">
                  <v-layout justify-space-between>
                    <v-flex>واریز روزانه</v-flex>
                    <v-flex
                      class="font_2"
                      style="
                        text-align: left;
                        text-align-last: left !important;
                        margin-bottom: 0px;
                      "
                    >
                      شنبه تا پنج‌شنبه ساعت ۱۴
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>واریز هفتگی</v-flex>
                    <v-flex
                      class="font_2"
                      style="
                        text-align: left;
                        text-align-last: left !important;
                        margin-bottom: 0px;
                      "
                    >
                      شنبه هر هفته ساعت ۱۴
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>واریز ماهیانه</v-flex>
                    <v-flex
                      class="font_2"
                      style="
                        text-align: left;
                        text-align-last: left !important;
                        margin-bottom: 0px;
                      "
                    >
                      روز یکم هر ماه ساعت ۱۴
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-layout class="mb-2">
                  <v-flex style="width: 45px">
                    <v-img
                      :src="require('@/assets/img/gingool.svg')"
                      width="34"
                      height="23"
                    />
                  </v-flex>
                  <v-flex class="font_2" style="margin-bottom: 0px">
                    واریز به حساب‌های بانک آینده ساعت ۱۲ انجام می‌شود.
                  </v-flex>
                </v-layout>
                <v-layout class="mb-5">
                  <v-flex style="width: 45px">
                    <v-img
                      :src="require('@/assets/img/gingool.svg')"
                      width="34"
                      height="23"
                    />
                  </v-flex>
                  <v-flex class="font_2 mb-0">
                    فعال بودن برداشت خودکار از ثبت برداشت عادی جلوگیری نمی‌کند.
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>
    </v-layout>

    <v-dialog v-model="saveModal" width="360" content-class="autoheightMessage">
      <v-card outlined height="100%">
        <v-layout v-if="schedule != 'OFF'" column justify-center align-center>
          <v-flex class="main-title font_9">
            تنظیمات برداشت خودکار اعمال شود؟
          </v-flex>
          <v-flex
            style="
              font-size: 12px;
              line-height: 24px;
              font-weight: 300;
              background-color: #4c9f874d;
              padding: 8px 14px;
              text-align: center;
            "
          >
            <div v-if="isOwner">
              با این تنظیمات
              <span v-if="schedule == 'DAILY'">هر روز</span>
              <span v-if="schedule == 'WEEKLY'">شنبه هر هفته</span>
              <span v-if="schedule == 'MONTHLY'">یکم هر ماه</span>
              ساعت ۱۹، موجودی قابل برداشت کیف پول، به حساب بانکی‌تان واریز
              می‌شود.
            </div>
            <div v-if="!isOwner">
              با این تنظیمات
              <span v-if="schedule == 'DAILY'">هر روز</span>
              <span v-if="schedule == 'WEEKLY'">شنبه هر هفته</span>
              <span v-if="schedule == 'MONTHLY'">یکم هر ماه</span>
              ساعت ۱۹، تا مبلغ ۱۰۰٬۰۰۰٬۰۰۰ تومان از موجودی قابل برداشت کیف پول،
              به شبای زیر واریز می‌شود.
            </div>
          </v-flex>
          <v-flex
            class="van_color11"
            style="padding: 20px; width: 300px; margin-top: 5px"
          >
            <v-layout column class="rows little-rows">
              <v-flex>
                <v-layout justify-space-between>
                  <v-flex class="label"> صاحب حساب </v-flex>
                  <v-flex style="text-align-last: left">
                    {{ finalData.name }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout justify-space-between>
                  <v-flex class="label"> شماره شبا </v-flex>
                  <v-flex style="text-align-last: left">
                    {{ finalData.sheba }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout justify-space-between>
                  <v-flex class="label"> بانک </v-flex>
                  <v-flex style="text-align-last: left">
                    {{ finalData.bank }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-btn
              text
              dark
              class="btn_small_normal"
              :disabled="loading"
              :loading="loading"
              @click="sendForm"
            >
              بله، اعمال شود
            </v-btn>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="saveModal = false"
            >
              خیر، برو به تنظیمات
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout v-else column justify-center align-center>
          <v-flex class="main-title font_9">
            برداشت خودکار غیرفعال شود؟
          </v-flex>
          <v-flex
            class="van_color11"
            style="
              border-radius: 4px;
              font-size: 12px;
              line-height: 24px;
              font-weight: 300;
              padding: 8px 14px;
              text-align: center;
            "
          >
            با غیر فعال کردن برداشت خودکار، باید به صورت دستی اقدام به برداشت از
            کیف پول خود کنید
          </v-flex>
          <v-flex>
            <v-btn
              text
              dark
              class="btn_small_normal error"
              :disabled="loading"
              :loading="loading"
              @click="sendForm"
            >
              غیرفعال شود
            </v-btn>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="saveModal = false"
            >
              منصرف شدم
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDailySettlementForIban"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <detail-daily-settlement-for-iban
        v-if="showDailySettlementForIban"
        :is-owner="isOwner"
        @closeModal="close_modal"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import DetailDailySettlementForIban from "../report/modals/addSettlement/detailDailySettlementForIban"
import IbanBox from "@/components/elements/ibanBox"
// import IbanCelling from "@/components/elements/ibanCelling"
// import IbanCellingItems from "@/components/elements/ibanCellingItems"
import { numbersHelper } from "@/mixins/numbersHelper"

export default {
  name: "AutomaticSettlement",
  components: {
    // IbanCellingItems,
    // IbanCelling,
    IbanBox,
    DetailDailySettlementForIban,
  },
  mixins: [numbersHelper],
  data: function () {
    return {
      showDailySettlementForIban: false,
      isOwner: null,
      step: 1,
      schedule: "OFF",
      iban: null,
      scheduleEnum: [
        {
          key: "OFF",
          value: "غیر‌فعال",
        },
        {
          key: "DAILY",
          value: "روزانه",
          text: "شنبه تا پنج‌شنبه ساعت ۱۴",
        },
        {
          key: "WEEKLY",
          value: "هفتگی",
          text: "شنبه هر هفته  ساعت ۱۴",
        },
        {
          key: "MONTHLY",
          value: "ماهیانه",
          text: "یکم هر ماه  ساعت ۱۴",
        },
      ],
      loading: false,
      ibanSelect: "",
      saveModal: false,
      finalData: {
        name: null,
        sheba: null,
        bank: null,
      },
      validForm: false,
    }
  },
  computed: {
    valid() {
      return !(
        this.schedule !== "OFF" &&
        this.validForm &&
        (this.ibanSelect === [] ||
          this.ibanSelect === "" ||
          this.ibanSelect === null)
      )
    },
    ibanCellingArray() {
      return [
        {
          label: this.isOwner
            ? "سقف واریز روزانه به این شبا نامحدود است."
            : `سقف واریز روزانه به این شبا
           ${this.wordifyCeilingAmount()}`,
          cellingValue: " ",
          skeleton: false,
          isValueCurrency: false,
        },
      ]
    },
  },
  watch: {
    showDailySettlementForIban: function (newValue) {
      if (!newValue) {
        this.$store.commit("changeSecondAutoSettlementModal", false)
      }
    },
    ibanSelect: function (newValue) {
      if (newValue) {
        this.ibanCheck().then((response) => {
          this.isOwner = response.is_owner || response.in_whitelist_settlement
        })
      }
    },
    schedule: function (newValue, oldValue) {
      if (newValue != "OFF") {
        this.step = 2
      } else {
        this.step = 1
      }
    },
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    showWhyModal() {
      this.$store.commit("changeSecondAutoSettlementModal", true)
      this.showDailySettlementForIban = true
    },
    close_modal() {
      this.$store.commit("changeSecondAutoSettlementModal", false)
      this.showDailySettlementForIban = false
    },
    ibanCheck() {
      return new Promise((resolve) => {
        if (!this.$store.state.isUser) {
          this.$http
            .post(
              this.$store.getters.ibanApiUrlParam + "/check",
              {
                IBAN: this.ibanSelect[0],
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "Bearer " + this.$store.state.token,
                  Accept: "application/json",
                },
              }
            )
            .then((response) => {
              resolve(response.data.data)
            })
        } else {
          resolve({ is_owner: true })
        }
      })
    },
    goToTools() {
      this.$router.push(this.$store.getters.urlParam + "/tools")
      //TODO: open the tashim modal
    },
    getSetting() {
      this.$http
        .get(this.$store.getters.apiUrlParam + "/settlement/setting", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.schedule = response.data.data.schedule
          this.iban = [
            response.data.data.iban.IBAN,
            response.data.data.iban.account_owner[0].firstName +
              " " +
              response.data.data.iban.account_owner[0].lastName,
            response.data.data.iban.bank_name,
          ]
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
    openSaveModal() {
      this.finalData = {
        name: this.ibanSelect[1],
        sheba: this.ibanSelect[0],
        bank: this.ibanSelect[2],
      }
      this.saveModal = true
    },
    setSchedule() {
      if (this.schedule != "OFF") {
        this.step = 2
      } else {
        this.step = 1
      }
    },
    sendForm() {
      this.loading = true
      var data = {
        schedule: this.schedule,
        iban: this.ibanSelect[0],
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/settlement/setting", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$emit("closeModal", { status: true })
          this.loading = false
          this.saveModal = false

          //for updating automatic_settlement_tip
          if (this.$store.state.isUser) {
            this.$store
              .dispatch("retrieveUserObject")
              .then((response) => {
                //DO Nothing
              })
              .catch((error) => {
                //callsnackbar
              })
          } else {
            this.$store
              .dispatch(
                "retrieveBusinessObject",
                this.$store.state.business.business_name
              )
              .then((response) => {
                //DO Nothing
              })
              .catch((error) => {
                //callsnackbar
              })
          }
          //callSnackbar
          this.$store.commit(
            "callSuccessSnackbar",
            "تنظیمات برداشت خودکار ثبت و ذخیره شد"
          )
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
  },
}
</script>
