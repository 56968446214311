<template>
  <v-card class="singleField">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">شناسه وندار</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items v-if="disable" class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout class="adding_vandarId">
      <v-flex>
        <div class="font_2 des">
          شناسه ونداری برای هر حساب تعریف می‌شود و باید یکتا بوده و با حروف
          انگلیسی نوشته شود
        </div>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="input"
            placeholder="Vandar مانند "
            :rules="emailRules"
            solo
            flat
            height="40"
            outlined
            prefix="@"
            :readonly="lastID == null || lastID == '' ? false : true"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-text-field>
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "AddEmail",
  props: [],
  data: function () {
    return {
      valid: false,
      loading: false,
      input: "",
      emailRules: [
        (v) => !!v || "",
        (v) =>
          !/^[\u0600-\u06FF\s]+$/.test(v) ||
          "<span> این شناسه نباید شامل حروف فارسی باشد </span>",
      ],
      isFirstTime: false,
      lastID: null,
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.valid && this.oldValue != this.input)
        return true
      else return false
    },
  },
  mounted() {
    this.getVandarId()
  },
  methods: {
    getVandarId() {
      this.$http
        .get(this.$store.getters.apiUrlParam + "/profiles", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.input = response.data.result.profile.slug
          this.lastID = this.input
          this.oldValue = response.data.result.profile.slug
          if (response.data.result.profile.slug == null) {
            this.isFirstTime = true
          }
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    sendForm() {
      if (this.$refs.form.validate()) {
        this.loading = true
        var data = {
          slug: this.input,
        }
        if (this.isFirstTime) {
          this.$http
            .post(this.$store.getters.apiUrlParam + "/profiles", data, {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            })
            .then((response) => {
              this.loading = false
              this.$store.commit(
                "callSuccessSnackbar",
                "شناسه شما با موفقیت ثبت شد."
              )
              this.$emit("closeModal", { success: true })
            })
            .catch((error) => {
              this.loading = false
              //callsnackbar
            })
        } else {
          this.$http
            .put(this.$store.getters.apiUrlParam + "/profiles", data, {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            })
            .then((response) => {
              this.loading = false
              this.$store.commit(
                "callSuccessSnackbar",
                "شناسه شما با موفقیت تغییر کرد."
              )
              this.$emit("closeModal")
            })
            .catch((error) => {
              this.loading = false
              //callsnackbar
            })
        }
      }
    },
  },
}
</script>
