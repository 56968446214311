<template>
  <left-modal :toolbar-name="toolbarLabel" @closeModal="$emit('closeModal')">
    <div
      v-if="queueSettlement && !queueSettlement?.canceled_at"
      slot="toolbarItems"
    >
      <v-btn
        ref="btn"
        text
        dark
        outlined
        class="btn_small_normal"
        @click="$emit('cancel')"
      >
        لغو برداشت
      </v-btn>
    </div>
    <template v-if="queueSettlement">
      <div class="d-flex justify-space-between">
        <div
          class="ml-1 d-flex align-center justify-center px-5"
          style="position: relative; width: 50%; height: 92px"
          :style="`background-color:${backgroundColor}`"
        >
          <span
            class="font-size-18 bold ml-1"
            :class="{ 'font-size-26': $vuetify.breakpoint.smAndUp }"
          >
            {{ queueSettlement?.amount | currencyWithRialInput }}
          </span>
          <span
            class="font-size-11 bold mt-2"
            :class="{ 'ml-2': $vuetify.breakpoint.smAndUp }"
          >
            {{ currencyName }}
          </span>
        </div>
        <div
          class="mr-1 d-flex px-5 font-size-12"
          :class="{
            'flex-column align-center justify-center':
              $vuetify.breakpoint.xsOnly,
            'align-center justify-space-between': $vuetify.breakpoint.smAndUp,
          }"
          style="position: relative; width: 50%; height: 92px"
          :style="`background-color:${backgroundColor}`"
        >
          <div>تاریخ ایجاد</div>
          <div>
            {{
              jalaliDate(
                parseInt(queueSettlement?.created_at) * 1000,
                "HH:mm jYYYY/jMM/jDD"
              )
            }}
          </div>
        </div>
      </div>
      <general-card custom-card-class="px-8 py-5 mt-3" parent-class="mb-0">
        <div v-if="queueSettlement?.canceled_at" class="d-flex align-center">
          <v-img
            class="ml-2"
            max-width="30"
            :src="require('@/assets/img/newIcon/error_close.svg')"
          />
          <span class="font-size-12">
            تراکنش توسط شما در
            {{ jalaliDate(parseInt(queueSettlement?.canceled_at) * 1000) }}
            لغو شد.
          </span>
        </div>
        <div v-else class="d-flex align-center">
          <v-img
            max-width="30"
            class="ml-2"
            :src="require('@/assets/img/box-gray-past-time.svg')"
          />
          <span class="font-size-12">
            تا زمان بالا رفتن موجودی کیف‌پول امکان لغو دارید.
          </span>
        </div>
      </general-card>

      <general-card custom-card-class="py-10 px-11 mt-3">
        <div
          class="d-flex justify-start align-start align-sm-center"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <div class="label">دریافت کننده</div>
          <div class="withlabel big py-sm-3 py-1">
            <div class="w-100 font_2">
              {{ queueSettlement?.account_owner?.[0]?.firstName }}
              {{ queueSettlement?.account_owner?.[0]?.lastName }}
            </div>
          </div>
        </div>
        <v-divider style="border-style: dashed" />
        <div
          class="d-flex justify-start align-start align-sm-center"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <div class="label pt-2 pt-sm-0">شماره شبا</div>
          <div class="withlabel big py-sm-3 py-1">
            <div v-if="queueSettlement?.IBAN" class="w-100 font_2 d-flex">
              {{ queueSettlement?.IBAN }}
              <v-img
                max-width="16"
                max-height="16"
                class="mr-1"
                :src="
                  require(`@/assets/img/banks/${parseInt(
                    queueSettlement?.IBAN.slice(4, 7)
                  )}.svg`)
                "
              />
            </div>
          </div>
        </div>
        <v-divider
          v-if="queueSettlement?.track_id"
          style="border-style: dashed"
        />
        <div
          v-if="queueSettlement?.track_id"
          class="d-flex justify-start align-start align-sm-center"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <div class="label pt-2 pt-sm-0">
            شناسه تسویه
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon
                  size="20"
                  style="width: 20px"
                  class="van_color03--text"
                  v-on="on"
                >
                  mdi_info_outline
                </v-icon>
              </template>
              <span class="font_33">
                با شناسه تسویه (Track id) امکان پیگیری
                <br v-if="$vuetify.breakpoint.smAndUp" />
                تراکنش را تا پیش از ارسال به بانک دارید.
              </span>
            </v-tooltip>
          </div>
          <div class="withlabel flex-grow-1 big py-sm-3 py-1">
            <div class="d-flex align-center w-100 font_2">
              <span class="ml-auto"> {{ queueSettlement?.track_id }}</span>
              <CopyBtnGeneral
                v-if="!queueSettlement?.canceled_at"
                input-id="hidden-input"
                :value="queueSettlement?.track_id"
                label="کپی"
                color-btn="van_color06"
                class-btn="tblCpy-receipt_url px-2 ma-0"
                outlined
              />
            </div>
          </div>
        </div>
        <v-divider
          v-if="queueSettlement?.comment"
          style="border-style: dashed"
        />
        <div
          v-if="queueSettlement?.comment"
          class="d-flex justify-start align-start align-sm-center"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <div class="label pt-2 pt-sm-0">یادداشت محرمانه</div>
          <div class="withlabel big py-sm-3 py-1">
            <div class="w-100 font_2">
              {{ queueSettlement?.comment }}
            </div>
          </div>
        </div>
        <v-divider
          v-if="queueSettlement?.description"
          style="border-style: dashed"
        />
        <div
          v-if="queueSettlement?.description"
          class="d-flex justify-start align-start align-sm-center"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <div class="label pt-2 pt-sm-0">توضیحات</div>
          <div class="withlabel big py-sm-3 py-1">
            <div class="w-100 font_2">
              {{ queueSettlement?.description }}
            </div>
          </div>
        </div>
      </general-card>
    </template>
    <div v-else>
      <div class="d-flex justify-space-between">
        <div
          class="ml-1 d-flex align-center justify-center px-5"
          style="position: relative; width: 50%; height: 92px"
        >
          <v-skeleton-loader width="100%" height="100%" type="image" />
        </div>
        <div
          class="mr-1 d-flex align-center justify-space-between px-5 font-size-12"
          style="position: relative; width: 50%; height: 92px"
          :style="`background-color:${backgroundColor}`"
        >
          <v-skeleton-loader width="100%" height="100%" type="image" />
        </div>
      </div>
      <general-card
        custom-card-class="px-8 py-8 mt-3 align-center "
        parent-class="mb-0"
      >
        <v-skeleton-loader width="70%" class="my-auto" type="text" />
      </general-card>
      <general-card custom-card-class="py-10 px-11 mt-3">
        <div class="d-flex justify-start align-center">
          <v-skeleton-loader width="70%" type="text" />
        </div>
        <v-divider class="my-2" style="border-style: dashed" />
        <div class="d-flex justify-start align-center">
          <v-skeleton-loader width="70%" class="my-auto" type="text" />
        </div>
        <v-divider class="my-2" style="border-style: dashed" />
        <div class="d-flex justify-start align-center">
          <v-skeleton-loader width="70%" class="my-auto" type="text" />
        </div>
        <v-divider class="my-2" style="border-style: dashed" />
        <div class="d-flex justify-start align-center">
          <v-skeleton-loader width="70%" class="my-auto" type="text" />
        </div>
        <v-divider class="my-2" style="border-style: dashed" />
        <div class="d-flex justify-start align-center">
          <v-skeleton-loader width="70%" class="my-auto" type="text" />
        </div>
      </general-card>
    </div>
  </left-modal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal.vue"
import GeneralCard from "@/components/elements/generalCard.vue"
import CopyBtnGeneral from "@/components/elements/copyBtnGeneral"

export default {
  name: "QueueShow",
  components: { GeneralCard, LeftModal, CopyBtnGeneral },
  props: {
    id: {
      type: String,
      default: null,
    },
    queueSettlement: {
      type: Object,
      default: null,
    },
    toolbarLabel: {
      type: String,
      default: "",
    },
  },
  computed: {
    backgroundColor() {
      return this.queueSettlement?.canceled_at ? "#d44d4233;" : "#F5F5F5"
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  width: 110px;
  margin-top: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>
