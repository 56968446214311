<template>
  <div class="d-flex flex-column">
    <v-switch
      v-model="cancelableSwitch"
      class="van-switch font_31 ma-0"
      color="van_green"
      :label="label"
      hide-details="auto"
      :disabled="disabled"
      @change="$emit('onSwitchToggle', cancelableSwitch)"
    />
    <van-modal-announcement-bar
      v-if="!disabled"
      slot="infoBox"
      :description="description"
      :container-classes="`${
        cancelableSwitch ? 'green_back_1' : 'van_color11 '
      } withlabel big font_2 van_color03--text px-3 py-2 radius-4 mb-3`"
    />
  </div>
</template>

<script>
import vanModalAnnouncementBar from "@/components/VANComponents/vanModalAnnouncementBar.vue"

export default {
  name: "VanSwitchWithInfo",
  components: { vanModalAnnouncementBar },
  props: {
    label: {
      type: String,
      default: "امکان لغو تراکنش",
    },
    description: {
      type: String,
      default: " ",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    infoBoxClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cancelableSwitch: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.info-box {
  max-width: 350px;
}
</style>
