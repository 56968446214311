<template>
  <left-modal
    ref="leftModal"
    toolbar-name="بانک‌های متصل"
    @closeModal="$emit('closeModal', 'mandateList')"
  >
    <div slot="toolbarItems">
      <v-btn text dark class="btn_small_normal" @click="$emit('openPbvModal')"
        >بانک جدید</v-btn
      >
    </div>
    <general-card>
      <v-layout column class="authorizationList">
        <v-flex
          v-if="
            !(
              !authorizationLoad ||
              (authorization && authorization.length > 0) ||
              (notActiveauthorization && notActiveauthorization.length > 0)
            )
          "
        >
          <v-layout class="shebaList" column>
            <!-- add iban -->
            <v-flex>
              <v-layout align-center justify-start>
                <v-flex style="max-width: 56px; margin-left: 10px">
                  <v-avatar color="van_color11" size="56">
                    <img
                      style="max-width: 24px"
                      :src="require('@/assets/img/newIcon/Subscribe.svg')"
                    />
                  </v-avatar>
                </v-flex>
                <v-flex>
                  <div class="font_2" style="text-align-last: right">
                    حسابی برای این کاربر متصل نشده است
                  </div>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>

        <!-- authorization list -->
        <v-flex
          v-if="authorizationLoad && authorization && authorization.length > 0"
        >
          <v-layout class="shebaList" column>
            <v-flex
              v-for="(auth, index) in authorization"
              :key="index"
              style="width: 100%"
            >
              <v-layout align-center>
                <v-flex
                  style="
                    max-width: 60px;
                    padding: 15px;
                    margin-left: 10px;
                    border: 1px solid #eeeeee;
                  "
                >
                  <v-img
                    width="30"
                    :src="
                      require(`@/assets/img/banks/${parseInt(
                        auth.bank_code
                      )}.svg`)
                    "
                    v-on="on"
                  ></v-img>
                </v-flex>
                <v-flex style="text-align-last: right">
                  <div class="font_2 bold pb-2">
                    {{ auth.bank_name }}
                    <span v-if="auth.is_default" class="defaultBox"
                      >پیش‌فرض</span
                    >
                  </div>
                  <div class="font_30">
                    سقف
                    {{ auth.limit | currencyWithRialInput }}
                    {{ currencyName }}
                    تا
                    {{ auth.expiration_date }}
                  </div>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex style="max-width: 40px; margin-left: -10px">
                  <v-menu
                    transition="slide-y-transition"
                    offset-y
                    close-on-content-click
                    bottom
                    content-class="up_menu"
                  >
                    <!-- this on slot sometime is undefined -->
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        size="24"
                        fab
                        small
                        style="margin: 0px"
                        v-on="on"
                      >
                        <v-icon
                          color="van_color03"
                          size="21"
                          style="line-height: 40px"
                          >more_vert</v-icon
                        >
                      </v-btn>
                    </template>

                    <v-card class="drop_down_menu">
                      <v-list class="menu_v_list">
                        <v-list-item
                          :disabled="auth.is_default"
                          @click="selectDefault(auth.uuid, index)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              حساب پیش‌فرض
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="deleteAuth(auth.uuid, index)">
                          <v-list-item-content>
                            <v-list-item-title>لغو اتصال</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-divider
                v-if="index + 1 < authorization.length"
                class="my-5"
                style="border-color: #f5f5f5"
              ></v-divider>
            </v-flex>
          </v-layout>
        </v-flex>

        <!--  observer  -->
        <observer
          v-if="authorizationLoad && loadMoreSkeleton"
          @intersect="getAuthorization()"
        />

        <!-- skeleton -->
        <v-divider
          v-if="authorizationLoad && loadMoreSkeleton"
          class="my-5 mx-0"
          style="border-color: #f5f5f5"
        ></v-divider>
        <v-flex v-if="!authorizationLoad || loadMoreSkeleton">
          <mandate-list-skeleton></mandate-list-skeleton>
        </v-flex>
      </v-layout>
    </general-card>
    <v-dialog
      v-if="deleteStatusAuth"
      v-model="deleteStatusAuth"
      width="360"
      content-class="confirmationMessage withdata"
    >
      <v-card height="100%" class="pa-8">
        <v-layout column justify-center align-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/newIcon/error_close.svg')"
              width="63"
              height="63"
            ></v-img>
          </v-flex>
          <v-flex class="main-title font_1 my-6"
            >اتصال به این بانک لغو شود؟</v-flex
          >
          <v-layout
            align-center
            class="pa-5"
            style="background-color: #f5f5f5; width: 100%"
          >
            <v-flex
              style="
                max-width: 60px;
                padding: 15px;
                margin-left: 10px;
                border: 1px solid #eeeeee;
                background-color: white;
              "
            >
              <v-img
                width="30"
                :src="
                  require(`@/assets/img/banks/${parseInt(
                    authorization[deletedSelection.index].bank_code
                  )}.svg`)
                "
                v-on="on"
              ></v-img>
            </v-flex>
            <v-flex style="text-align-last: right">
              <div class="font_2 bold pb-2">
                {{ authorization[deletedSelection.index].bank_name }}
              </div>
              <div class="font_30">
                سقف
                {{
                  authorization[deletedSelection.index].limit
                    | currencyWithRialInput
                }}
                {{ currencyName }}
                تا
                {{ authorization[deletedSelection.index].expiration_date }}
              </div>
            </v-flex>
          </v-layout>
          <v-flex>
            <v-btn
              text
              depressed
              class="btn_small_normal error"
              :loading="deleteButtonLoading"
              @click="finalDelete()"
              >مطمئنم، لغو شود</v-btn
            >
            <v-btn
              text
              dark
              outlined
              class="btn_small_normal"
              @click="cancleDelete()"
              >بازگشت</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </left-modal>
</template>

<script>
import leftModal from "@/components/modals/leftModal"
import generalCard from "@/components/elements/generalCard.vue"
import MandateListSkeleton from "@/components/pbv/modals/mandateListSkeleton"
import observer from "@/components/elements/observer.vue"

export default {
  name: "MandateList",
  components: { MandateListSkeleton, leftModal, generalCard, observer },
  data() {
    return {
      authorizationLoad: false,
      authorization: [],
      currentPage: 1,
      loadMoreSkeleton: false,
      deletedSelection: {
        id: null,
        index: null,
      },
      deleteButtonLoading: false,
      deleteStatusAuth: false,
    }
  },
  mounted() {
    this.loadMoreSkeleton = true
    this.getAuthorization()
  },
  methods: {
    getAuthorization() {
      this.$http
        .get(
          this.$store.state.api_pbv_url +
            "/user/mandates?per_page=10&page=" +
            this.currentPage,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          response?.data?.data?.mandates?.data.forEach((element) => {
            if (element.bank_code === "102") element.bank_code = "062"
            this.authorization.push(element)
          })

          if (
            this.currentPage >= response?.data?.data?.mandates?.meta?.last_page
          ) {
            this.loadMoreSkeleton = false
          }
          this.authorizationLoad = true
          this.currentPage++
        })
        .catch((error) => {
          //call snackbar
        })
    },
    finalDelete() {
      this.deleteButtonLoading = true
      this.$http
        .delete(
          this.$store.state.api_pbv_url +
            "/user/mandates/" +
            this.deletedSelection.id,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$delete(this.authorization, this.deletedSelection.index)
          this.resetDeleteSelection()
          this.deleteStatusAuth = false
          this.deleteButtonLoading = false
          this.$store.commit(
            "callSuccessSnackbar",
            "اشتراک مورد نظر با موفقیت حذف شد."
          )
          this.$emit("updateMandateCard")
        })
        .catch((error) => {
          this.$store.commit("callsnackbar", error)
        })
    },
    deleteAuth(id, index) {
      this.deletedSelection = {
        id: id,
        index: index,
      }
      this.deleteStatusAuth = true
    },
    cancleDelete() {
      this.resetDeleteSelection()
      this.deleteStatusAuth = false
    },
    resetDeleteSelection() {
      this.deletedSelection = {
        id: null,
        index: null,
      }
    },
    selectDefault(id, index) {
      this.$http
        .post(
          this.$store.state.api_pbv_url + "/user/mandates/set-default",
          {
            mandate_uuid: id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          //erase the last default
          this.authorization.forEach((auth, index) => {
            if (auth.is_default) this.authorization[index].is_default = false
          })
          //set new default
          this.authorization.forEach((auth, index) => {
            if (auth.uuid == id) this.authorization[index].is_default = true
          })
          this.$store.commit(
            "callSuccessSnackbar",
            "حساب پیش‌فرض با موفقیت به روز شد"
          )
        })
        .catch((error) => {
          this.$store.commit("callsnackbar", error)
        })
    },
  },
}
</script>

<style lang="scss">
.flex-wrap {
  flex-wrap: wrap;
}
.border_eee {
  border: 1px solid;
  border-color: $van_color10 !important;
}
.border_d5 {
  border-color: #d5d5d5 !important;
}
.defaultBox {
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  color: #666666;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 10px;
}
</style>
