const apiHeader = (context) => ({
  "Content-Type": "application/json",
  authorization: `Bearer ${context.$store.state.token}`,
  Accept: "application/json",
})

export default (context) => ({
  httpGetBankInvoices(url) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/settlement/account/invoices${url}`,
      {
        headers: apiHeader(context),
      }
    )
  },
  httpGetExcel(url) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/settlement/account/invoices/export/excel${url}`,
      {
        "Content-Type": "application/json",
        authorization: `Bearer ${context.$store.state.token}`,
        Accept: "application/json",
        responseType: "blob",
      }
    )
  },
})
