<template>
  <v-card class="upload">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">کارت ملی صاحب سیم‌کارت</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex style="max-height: 220px">
        <div v-if="!startUpload">
          <input
            id="file"
            ref="file"
            type="file"
            accept="image/*"
            @change="handleFile()"
          />
          <v-layout
            justify-center
            align-content-space-around
            column
            class="uploadbox"
          >
            <v-flex>
              <v-icon color="van_color05" size="40"> backup </v-icon>
            </v-flex>
            <v-flex class="font_2"> فرمت‌های مجاز PNG و JPG است </v-flex>
            <v-flex class="font_8 mt-1"> جهت بارگذاری کلیک کنید </v-flex>
          </v-layout>
        </div>
        <v-layout
          v-else
          justify-center
          align-content-space-around
          column
          class="uploadloader"
        >
          <v-flex>
            <v-progress-circular
              :value="proggressBar"
              color="van_green"
              background-color="van_color06"
              size="45"
              width="4"
            >
              {{ proggressBar }}%
            </v-progress-circular>
          </v-flex>
          <v-flex class="font_6 mt-4">
            {{ file.name }}
          </v-flex>
          <v-flex v-if="proggressBar == 100" class="delete">
            <v-btn text icon @click="deleteFile()">
              <v-icon dark size="20" color="van_color03">
                delete_outline
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex
        style="
          margin-top: -20px;
          padding: 15px 20px !important;
          height: 60px;
          min-height: 60px;
          max-height: 60px;
          border: 1px solid #f5f5f5 !important;
        "
      >
        <div class="font_2 mt-0">
          <v-icon size="16" color="van_color02"> error_outline </v-icon>صاحب
          سیم‌کارت تنها می‌تواند یکی از اقوام درجه یک شما باشد.
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import Compressor from "compressorjs"
export default {
  name: "AddMobileOwnerCard",
  data: function () {
    return {
      file: "",
      loading: false,
      proggressBar: 0,
      startUpload: false,
      uploadedfile: "",
      size: 0,
      format: "",
      success: false,
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.uploadedfile !== "" && this.success) return true
      else return false
    },
  },
  methods: {
    handleFile() {
      this.file = this.$refs.file.files[0]
      this.size = this.file.size
      this.format = this.file.type

      if (
        this.file["type"].split("/")[0] === "image" &&
        this.file["type"].split("/")[1] !== "gif"
      ) {
        this.success = true
        this.startUpload = true
        this.compressAndUpload()
      } else if (
        this.file["type"].split("/")[0] !== "image" ||
        this.file["type"].split("/")[1] === "gif"
      ) {
        this.$store.commit(
          "callsnackbar",
          "فرمت فایل انتخاب شده قابل قبول نیست"
        )
      }
    },
    compressAndUpload() {
      new Compressor(this.file, {
        quality: 0.8,
        maxWidth: 1000,
        maxHeight: 1000,
        convertSize: 1000000,
        success: (result) => {
          if (result.size > 2000000) {
            this.$store.commit(
              "callsnackbar",
              "حجم فایل انتخاب شده بیشتر از حد مجاز است"
            )
          } else {
            const formData = new FormData()
            formData.append("image", result, "image.jpeg")
            this.$http
              .post(
                this.$store.state.api_base_url + "/modules/upload/image",
                formData,
                {
                  timeout: 50000,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    authorization: "Bearer " + this.$store.state.token,
                    Accept: "application/json",
                  },
                  onUploadProgress: (progressEvent) => {
                    this.proggressBar = parseInt(
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    )
                  },
                }
              )
              .then((response) => {
                this.uploadedfile = response.data.data
              })
              .catch((error) => {
                //callsnackbar
              })
          }
        },
        error(err) {
          this.deleteFile()
          store.commit("callsnackbar", "لطفا دوباره تلاش کنید")
          Sentry.captureException(err)
        },
      })
    },
    sendForm() {
      this.loading = true
      if (this.uploadedfile !== "") {
        var data = {
          mobile_owner_national_card: this.uploadedfile,
        }
        this.$http
          .post(
            this.$store.state.api_base_url +
              "/personal/update/mobileOwnerNationalCard",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.$store.commit("setUser", response.data.data.user)
            this.$store.commit("changeUpdateBusinessList", true)
            this.loading = false
            this.$emit("closeModal")
          })
          .catch((error) => {
            this.loading = false
            //callsnackbar
          })
      }
    },
    deleteFile() {
      this.file = null
      this.proggressBar = 0
      this.startUpload = false
    },
  },
}
</script>
