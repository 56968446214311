<template>
  <div class="activationBlock">
    <span class="topBack pendingTopBack" />
    <v-layout column class="mx-auto twoPending" style="margin: -20px">
      <v-flex class="personal_title alldone">
        <v-layout justify-space-between align-center>
          <v-flex>
            <v-layout align-center>
              <v-flex style="max-width: 60px">
                <v-img
                  :src="require('@/assets/img/newIcon/cashInPending.svg')"
                  alt="avatar"
                  width="40"
                />
              </v-flex>
              <v-flex>
                <div class="font_5">در انتظار ارسال قرارداد و گواهی امضا</div>
              </v-flex>
            </v-layout>
          </v-flex>
          <!--          <v-flex>-->
          <!--            <div style="text-align: left" class="font_7">-->
          <!--              <v-btn-->
          <!--                flat-->
          <!--                depressed-->
          <!--                outline-->
          <!--                class="btn_small_normal"-->
          <!--                @click="downloadContract()"-->
          <!--              >-->
          <!--                دریافت قرارداد-->
          <!--              </v-btn>-->
          <!--            </div>-->
          <!--          </v-flex>-->
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "CashInActivationBlock",
  data() {
    return {
      //this is modal status
      status: false,
    }
  },
  methods: {
    close_modal() {
      this.status = false
    },
  },
}
</script>

<style lang="scss" scoped>
.twoPending {
  > .personal_title:nth-child(2) {
    margin-top: 30px;
  }
}
</style>
