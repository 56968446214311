<template>
  <div>
    <SearchInputVandar
      v-model="search"
      input-class="w-100 mb-6"
      input-style="width:100%"
      dont-show-btn
      place-holder="جستجو باشماره شبا و شناسه واریز"
      :should-delete-search="shouldDeleteSearch"
    />

    <template v-if="!loading">
      <p class="font_2">
        در صورتیکه شبا مبدأ مربوط به مشتری شما نیست از تأیید آن خودداری فرمایید
        زیرا عواقب حقوقی و قضایی مربوط به این موضوع بر عهده شما خواهد بود.
      </p>
      <div class="font-size-12 van_color03--text mb-6">
        {{ $store.state.suspiciousCount }} تراکنش در انتظار تعیین وضعیت
      </div>
      <div v-for="(item, index) in items" :key="item.id">
        <SuspiciousItem
          :item="item"
          style="margin-bottom: 4px !important"
          @removeItem="removeItem(index)"
        ></SuspiciousItem>
        <Observer
          v-if="index === items.length - 2 && items.length < total"
          :key="'observe' + item.id"
          @intersect="nextPage()"
        />
      </div>
    </template>
    <template v-if="loading || moreItem">
      <SuspiciousItemSkeleton v-for="i in 5" :key="'skeleton' + i"
    /></template>
  </div>
</template>

<script>
import SuspiciousItemSkeleton from "@/components/businessHome/elements/suspicious/suspiciousItemSkeleton.vue"
import Observer from "@/components/elements/observer.vue"
import SuspiciousItem from "@/components/businessHome/elements/suspicious/suspiciousItem.vue"
import SearchInputVandar from "@/components/elements/searchInputVandar.vue"
import servicesRepository from "@/services/servicesRepository"
import { resetSuspiciousSearchModel } from "@/utils/generalMethods"

export default {
  name: "PendingSuspicious",
  components: {
    SearchInputVandar,
    SuspiciousItemSkeleton,
    Observer,
    SuspiciousItem,
  },
  props: {
    tab: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      search: "",
      total: 0,
      page: 1,
      showDetail: false,
      timeOut: null,
      moreItem: false,
      lastPage: null,
      shouldDeleteSearch: false,
    }
  },
  watch: {
    search(newValue) {
      this.page = 1
      this.loading = true
      this.resetItemsList()
      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }

      if (!newValue) {
        this.getSuspicious()
      } else {
        this.timeOut = setTimeout(() => {
          this.getSearchedSuspicious()
        }, 800)
      }
    },
    tab() {
      resetSuspiciousSearchModel(this)
    },
  },
  mounted() {
    this.getSuspicious()
  },
  methods: {
    nextPage() {
      this.page += 1
      if (this.page <= this.lastPage) {
        this.moreItem = true
        if (this.search) {
          this.getSearchedSuspicious()
        } else {
          this.getSuspicious()
        }
      }
    },
    getSuspicious() {
      servicesRepository(this)
        .suspiciousPayment.httpGetSuspicious(this.page)
        .then((response) => this.handleDataAfterFetching(response))
    },
    getSearchedSuspicious() {
      const queryParam = {
        page: this.page,
        searchQuery: this.search,
      }
      servicesRepository(this)
        .suspiciousPayment.httpGetSearchedSuspicious(queryParam)
        .then((response) => this.handleDataAfterFetching(response))
    },
    handleDataAfterFetching(response) {
      this.loading = false
      this.lastPage = response.data.meta.last_page
      response.data.data.map((item) => this.items.push(item))
      this.total = response.data?.meta.total
      this.$store.commit("changeSuspiciousCount", response.data?.meta?.total)
      this.moreItem = false
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.$store.commit(
        "changeSuspiciousCount",
        this.$store.state.suspiciousCount - 1
      )
      this.$store.commit("callTheGetSuspiciousRefund", true)
      this.$store.commit("callTheGetSuspiciousRefundOnTheWay", true)
    },
    resetItemsList() {
      this.items = []
    },
  },
}
</script>

<style scoped lang="scss"></style>
