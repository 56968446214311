import cashInServices from "./cashInServices"
import transactionServices from "./transactionServices"
import customersServices from "./customersServices"
import roleUserServices from "./roleUserServices"
import suspiciousPaymentServices from "./suspiciousPaymentServices"
import onBoardingService from "./onBoardingService"
import informationService from "./informationServices"
import businessServices from "./businessServices"
import ipgServices from "./ipgServices"
import checkServices from "./checkServices"
import settlementServices from "./settlementServices"
import bankInvoiceServices from "@/services/bankInvoiceServices"
import ibanServices from "@/services/ibanServices"

export default (context) => ({
  cashIn: cashInServices(context),
  transaction: transactionServices(context),
  customers: customersServices(context),
  roleUser: roleUserServices(context),
  suspiciousPayment: suspiciousPaymentServices(context),
  onBoardingService: onBoardingService(context),
  informationService: informationService(context),
  businessServices: businessServices(context),
  ipgServices: ipgServices(context),
  checkServices: checkServices(context),
  settlementServices: settlementServices(context),
  bankInvoiceServices: bankInvoiceServices(context),
  ibanServices: ibanServices(context),
})
