<template>
  <left-modal
    ref="leftModal"
    toolbar-name="جزییات صورتحساب"
    @closeModal="$emit('closeModal')"
  >
    <general-card v-if="loading">
      <span class="default_gray" style="width: 100%; height: 2em" />
    </general-card>
    <general-card v-else>
      <div
        v-if="checkout.status === 'PENDING_VERIFY'"
        class="d-flex align-center justify-start"
      >
        <svg
          class="ml-3 flex-no-fill"
          fill="none"
          height="40"
          viewBox="0 0 40 40"
          width="40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.05221 36.2766C5.11254 38.6777 7.91961 40 10.9563 40C11.4575 40 11.971 39.9641 12.4826 39.8934C19.7978 38.882 26.3247 37.496 26.5743 37.4428C27.4466 37.2126 28.3235 37.0056 29.1718 36.8053C32.2156 36.0867 35.0907 35.4079 36.9872 33.8401C39.0704 32.118 39.7653 29.474 39.1741 25.5192C37.9613 17.4068 37.0385 13.6133 36.7296 12.4581L36.4786 11.4422C35.5812 7.59352 34.2024 4.80851 32.2721 2.9405C30.2277 0.962063 27.5475 0.000323795 24.0783 0.000323795L24.0753 0.000317456L24.0725 0.00026665C24.0708 0.000234918 24.0563 0 24.0292 0C23.5762 0 19.4078 0.0684444 12.6265 2.43912C9.53026 3.52152 6.03406 4.90204 3.5789 6.93437C2.40198 7.9086 1.54211 8.95725 0.950163 10.1402C0.3108 11.418 0 12.8177 0 14.4193V27.9276C0 30.973 1.11249 34.0161 3.05221 36.2766Z"
            fill="#F9D358"
            opacity="0.2"
          />
          <path
            d="M16.875 13.333C16.1875 13.333 15.625 13.9044 15.625 14.6028V16.6155C15.625 16.9521 15.7563 17.2759 15.9938 17.5171L18.125 19.6822L15.9875 21.8536C15.7562 22.0949 15.625 22.4187 15.625 22.7552V24.7616C15.625 25.46 16.1875 26.0314 16.875 26.0314H21.875C22.5625 26.0314 23.125 25.46 23.125 24.7616V22.7552C23.125 22.4187 22.9938 22.0949 22.7625 21.86L20.625 19.6822L22.7563 17.5235C22.9938 17.2822 23.125 16.9584 23.125 16.6219V14.6028C23.125 13.9044 22.5625 13.333 21.875 13.333H16.875ZM21.875 22.5394V24.1267C21.875 24.4759 21.5938 24.7616 21.25 24.7616H17.5C17.1563 24.7616 16.875 24.4759 16.875 24.1267V22.5394L19.375 19.9997L21.875 22.5394ZM19.375 19.3648L16.875 16.8251V15.2378C16.875 14.8886 17.1563 14.6028 17.5 14.6028H21.25C21.5938 14.6028 21.875 14.8886 21.875 15.2378V16.8251L19.375 19.3648Z"
            fill="#F9D358"
          />
        </svg>
        <div class="d-flex align-center justify-start flex-no-fill">
          <span class="font_5">صورتحساب درحال پرداحت است.</span>
          <span class="linkBorder mr-2 flex-no-fill">
            <button
              class="small mb-0 v-btn v-btn--flat theme--light"
              data-v-2662be03=""
              style="font-size: 12px !important"
              type="button"
            >
              <a
                :href="checkout.receipt_url"
                style="text-decoration: none"
                target="_blank"
                ><span class="v-btn__content">مشاهده رسید</span></a
              >
            </button></span
          >
        </div>
      </div>
      <div
        v-if="checkout.status === 'SUCCEED'"
        class="d-flex align-center justify-start"
      >
        <svg
          class="ml-3 flex-no-fill"
          fill="none"
          height="40"
          viewBox="0 0 40 40"
          width="40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_680_7888)">
            <path
              d="M3.05221 36.2773C5.11254 38.6783 7.91961 40.0007 10.9563 40.0007C11.4575 40.0007 11.971 39.9648 12.4826 39.8941C19.7978 38.8827 26.3247 37.4967 26.5743 37.4434C27.4466 37.2133 28.3235 37.0062 29.1718 36.806C32.2156 36.0873 35.0907 35.4086 36.9872 33.8408C39.0704 32.1187 39.7653 29.4746 39.1741 25.5199C37.9613 17.4074 37.0385 13.614 36.7296 12.4588L36.4786 11.4429C35.5812 7.59418 34.2024 4.80917 32.2721 2.94116C30.2277 0.962722 27.5475 0.000982901 24.0783 0.000982901L24.0753 0.000976562C23.6223 0.000976562 19.4078 0.0691036 12.6265 2.43978C9.53026 3.52218 6.03406 4.9027 3.5789 6.93503C2.40198 7.90926 1.54211 8.95791 0.950163 10.1409C0.3108 11.4187 0 12.8183 0 14.4199V27.9283C0 30.9737 1.11249 34.0168 3.05221 36.2773Z"
              fill="#4C9F87"
              opacity="0.1"
            />
            <path
              d="M17.583 22.6253L14.916 19.8612L14.0078 20.7958L17.583 24.5012L25.2578 16.5469L24.356 15.6123L17.583 22.6253Z"
              fill="#4C9F87"
            />
          </g>
          <defs>
            <clipPath id="clip0_680_7888">
              <rect fill="white" height="40" width="40" />
            </clipPath>
          </defs>
        </svg>
        <div class="d-flex align-center justify-start">
          <span class="flex-no-fill font_5">صورتحساب پرداخت شد.</span>
          <span class="linkBorder mr-2 flex-no-fill">
            <button
              class="small mb-0 v-btn v-btn--flat theme--light"
              data-v-2662be03=""
              style="font-size: 12px !important"
              type="button"
            >
              <a
                :href="checkout.receipt_url"
                style="text-decoration: none"
                target="_blank"
                ><span class="v-btn__content">مشاهده رسید</span></a
              >
            </button></span
          >
        </div>
      </div>
      <div
        v-if="checkout.status === 'FAILED'"
        class="d-flex align-center justify-start"
      >
        <div class="ml-2">
          <svg
            fill="none"
            height="40"
            viewBox="0 0 40 40"
            width="40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_680_8105)">
              <path
                d="M3.05221 36.2766C5.11254 38.6777 7.91961 40 10.9563 40C11.4575 40 11.971 39.9641 12.4826 39.8934C19.7978 38.882 26.3247 37.496 26.5743 37.4428C27.4466 37.2126 28.3235 37.0056 29.1718 36.8053C32.2156 36.0867 35.0907 35.4079 36.9872 33.8401C39.0704 32.118 39.7653 29.474 39.1741 25.5192C37.9613 17.4068 37.0385 13.6133 36.7296 12.4581L36.4786 11.4422C35.5812 7.59352 34.2024 4.80851 32.2721 2.9405C30.2277 0.962063 27.5475 0.000323795 24.0783 0.000323795L24.0753 0.000317456L24.0725 0.00026665C24.0708 0.000234918 24.0563 0 24.0292 0C23.5762 0 19.4078 0.0684444 12.6265 2.43912C9.53026 3.52152 6.03406 4.90204 3.5789 6.93437C2.40198 7.9086 1.54211 8.95725 0.950163 10.1402C0.3108 11.418 0 12.8177 0 14.4193V27.9276C0 30.973 1.11249 34.0161 3.05221 36.2766Z"
                fill="#666666"
                opacity="0.1"
              />
              <path
                clip-rule="evenodd"
                d="M14.6875 22.8568H16.25V21.2695H14.6875V18.0949H24.0625V19.2857H25.625V14.9203C25.6244 14.4995 25.4597 14.0961 25.1667 13.7985C24.8738 13.501 24.4767 13.3336 24.0625 13.333H14.6875C14.2733 13.3336 13.8762 13.501 13.5833 13.7985C13.2903 14.0961 13.1256 14.4995 13.125 14.9203V21.2695C13.1256 21.6903 13.2903 22.0937 13.5833 22.3913C13.8762 22.6888 14.2733 22.8563 14.6875 22.8568ZM14.6875 16.5076V14.9203H24.0625V16.5076H14.6875Z"
                fill="#666666"
                fill-rule="evenodd"
              />
              <path
                d="M25.625 26.0314V22.0632H20.0625L21.9528 20.1323L21.125 19.2857L17.8125 22.6586L21.125 26.0314L21.9528 25.1827L20.0625 23.254H24.4535V26.0314H25.625Z"
                fill="#666666"
              />
            </g>
            <defs>
              <clipPath id="clip0_680_8105">
                <rect fill="white" height="40" width="40" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="d-flex align-center justify-start">
          <p class="mb-0">
            <span class="flex-no-fill font_5"
              >این صورتحساب پرداخت نشده اما پول از حساب بانکی شما کسر شده است.
              از این رو، پول را به حساب بانکی‌تان بازگشت دادیم.
            </span>
            <span class="linkBorder mr-2">
              <button
                class="small mb-0 v-btn v-btn--flat theme--light"
                data-v-2662be03=""
                style="font-size: 12px !important"
                type="button"
              >
                <a
                  :href="checkout.receipt_url"
                  style="text-decoration: none"
                  target="_blank"
                  ><span class="v-btn__content">مشاهده رسید</span></a
                >
              </button></span
            >
          </p>
        </div>
      </div>
    </general-card>

    <general-card>
      <div class="d-flex align-center justify-space-between">
        <div
          class="pa-2 flex-no-fill"
          style="border-radius: 5px; border: 1px solid #d5d5d5"
        >
          <span
            v-if="loading"
            class="default_gray"
            style="width: 25px; height: 25px"
          />
          <v-img
            v-else
            :src="checkout.business_logo"
            contain
            height="25"
            max-width="25"
            width="25"
          />
        </div>
        <span v-if="loading" class="default_gray" style="width: 25px" />
        <div v-else class="font_5 ppr-5">
          خرید از {{ checkout.business_company_name }}
        </div>
        <v-spacer />
        <span
          v-if="loading"
          class="default_gray"
          style="width: 2em; height: 0.8em"
        />
        <h1 v-else class="mb-0 flex-no-fill">
          {{ checkout.amount | currencyWithRialInput }} {{ currencyName }}
        </h1>
      </div>
    </general-card>
    <general-card>
      <div class="d-flex flex-column" style="width: 100%">
        <div class="d-flex align-center">
          <span class="flex-no-fill detail-title">زمان پرداخت</span>
          <span
            v-if="loading"
            class="default_gray"
            style="width: 2em; height: 0.8em"
          />
          <span v-else class="flex-no-fill detail-value">
            {{ checkout.time + " " + checkout.date }}</span
          >
        </div>
        <v-divider class="dashed-divider my-3" />

        <div class="d-flex align-center">
          <span class="flex-no-fill detail-title">بانک</span>
          <div class="d-flex align-center flex-no-fill">
            <span
              v-if="loading"
              class="default_gray"
              style="width: 2em; height: 0.8em"
            />
            <span v-else class="ml-2 detail-value">
              {{ checkout.account_number || checkout.bank_name }}</span
            >
            <span
              v-if="loading"
              class="default_gray"
              style="width: 20px; height: 20px"
            />
            <v-img
              v-else
              :src="
                require('@/assets/img/banks/' +
                  parseInt(checkout.bank_code) +
                  '.svg')
              "
              class="flex-no-fill"
              height="20"
              max-width="20"
              width="20"
            />
          </div>
        </div>

        <v-divider class="dashed-divider mt-3" />

        <div
          class="d-flex align-center justify-space-between py-3"
          @mouseleave="showCopyBtn = false"
          @mouseover="showCopyBtn = true"
        >
          <div class="d-flex align-center flex-no-fill">
            <span class="flex-no-fill detail-title">شناسه یکتا</span>
            <span
              v-if="loading"
              class="default_gray"
              style="width: 2em; height: 0.8em"
            />
            <span v-else class="flex-no-fill detail-value">
              {{ checkout.gateway_transaction_id }}</span
            >
          </div>
          <v-spacer />
          <v-flex
            v-if="showCopyBtn"
            style="position: relative; max-width: 36px; max-height: 19px"
          >
            <svg
              v-if="!loading"
              class="mr-2 flex-no-fill"
              fill="none"
              height="20"
              style="cursor: pointer"
              viewBox="0 0 17 20"
              width="17"
              xmlns="http://www.w3.org/2000/svg"
              @click="copyGatewayId"
            >
              <path
                d="M12.3346 0.833008H2.33464C1.41797 0.833008 0.667969 1.58301 0.667969 2.49967V14.1663H2.33464V2.49967H12.3346V0.833008ZM14.8346 4.16634H5.66797C4.7513 4.16634 4.0013 4.91634 4.0013 5.83301V17.4997C4.0013 18.4163 4.7513 19.1663 5.66797 19.1663H14.8346C15.7513 19.1663 16.5013 18.4163 16.5013 17.4997V5.83301C16.5013 4.91634 15.7513 4.16634 14.8346 4.16634ZM14.8346 17.4997H5.66797V5.83301H14.8346V17.4997Z"
                fill="#666666"
              />
            </svg>
            <v-tooltip
              v-model="tooltip_show"
              attach=".share"
              class="key_btn"
              min-width="100"
              top
            >
              <span>کپی شد</span>
            </v-tooltip>
          </v-flex>
        </div>
        <v-divider class="dashed-divider mb-3" />
        <div class="d-flex align-center">
          <span class="flex-no-fill detail-title">شماره فاکتور</span>
          <span
            v-if="loading"
            class="default_gray"
            style="width: 2em; height: 0.8em"
          />
          <span v-else class="flex-no-fill detail-value">
            {{ checkout.checkout_number }}</span
          >
        </div>
      </div>
    </general-card>
  </left-modal>
</template>

<script>
import leftModal from "@/components/modals/leftModal"
import generalCard from "@/components/elements/generalCard.vue"

export default {
  name: "ShowCheckout",
  components: { leftModal, generalCard },
  props: ["itemId"],
  data() {
    return {
      skeleton: true,
      tooltip_show: false,
      checkout: null,
      loading: true,
      showCopyBtn: false,
    }
  },

  mounted() {
    this.getCheckout()
  },
  methods: {
    getCheckout() {
      this.loading = true
      this.$http
        .get(`${this.$store.state.api_pbv_url}/user/checkouts/${this.itemId}`, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.checkout = response.data.data
          this.loading = false
        })
    },
    copyGatewayId() {
      const tmpTextField = document.createElement("textarea")
      tmpTextField.textContent = this.checkout.gateway_transaction_id
      tmpTextField.setAttribute("style", "position:absolute; right:200%;")
      document.body.appendChild(tmpTextField)
      tmpTextField.select()
      tmpTextField.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand("copy")
      tmpTextField.remove()
      this.tooltip_show = true
      setTimeout(() => {
        this.tooltip_show = false
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-fill {
  flex: 0 0 auto !important;
}

.border_eee {
  border: 1px solid;
  border-color: $van_color10 !important;
}

.border_f5 {
  border-color: $van_color11 !important;
}

.border_d5 {
  border-color: #d5d5d5 !important;
}

.key_btn {
  .v-tooltip__content {
    min-width: 50px !important;
    top: -31px !important;
    left: -7px !important;
    padding: 6px 8px !important;
  }
}

.detail-title {
  color: #999;
  font-size: 11px;
  width: 110px;
}

.detail-value {
  color: #333;
  font-size: 13px;
  font-weight: 400;
}

.dashed-divider {
  width: 100%;
  border-style: dashed;
  color: #f5f5f5;
}
</style>
