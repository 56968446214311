<template>
  <div>
    <v-flex class="boxes">
      <debitBox
        v-if="loading || debitMethods.length"
        :methods="debitMethods"
        :status="debitStatus"
        :loading="loading"
        :wage-type="debitWageType"
        @reload="getMethods"
        @show-management="showDebitManagment"
      />
      <creditBox
        v-if="loading || creditMethods.length"
        :methods="creditMethods"
        :status="creditStatus"
        :loading="loading"
        :wage-type="creditWageType"
        @reload="getMethods"
        @show-management="creditManagmet = true"
      />
    </v-flex>

    <v-dialog
      ref="banksList"
      v-model="debitManagmet"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      transition="slide-x-transition"
    >
      <bank-management
        :wage-type="debitWageType"
        type="debit"
        @update="updateWageType"
        @close-modal="closeDebitManagment"
      />
    </v-dialog>
    <v-dialog
      ref="banksList"
      v-model="creditManagmet"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      transition="slide-x-transition"
    >
      <bank-management
        :wage-type="creditWageType"
        type="credit"
        @close-modal="creditManagmet = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import BankManagement from "./BankManagement"
import debitBox from "./DebitMethods.vue"
import creditBox from "./CreditMethods.vue"

export default {
  name: "BankList",
  components: {
    debitBox,
    creditBox,
    BankManagement,
  },
  data() {
    return {
      debitManagmet: false,
      creditManagmet: false,
      debitMethods: [],
      debitStatus: "",
      debitWageType: "",
      creditMethods: [],
      creditStatus: "",
      creditWageType: "",
      loading: true,
    }
  },
  created() {
    this.getMethods()
  },
  methods: {
    updateWageType(wageType, type) {
      this[type + "WageType"] = wageType
    },
    showDebitManagment() {
      this.debitManagmet = true
    },
    closeDebitManagment() {
      this.debitManagmet = false
    },
    getMethods() {
      this.loading = true
      this.$http
        .get(this.$store.getters.urlMPG + "/payment-method-types")
        .then((res) => {
          const statuses = {
            ACTIVE: 1,
            DEACTIVATED: 2,
            PENDING: 3,
          }
          //debit
          if (res.data.data.types.debit) {
            this.debitStatus = res.data.data.types.debit.status
            this.debitWageType = res.data.data.types.debit.wage_type
            this.debitMethods = res.data.data.types.debit.payment_methods
            this.debitMethods = this.debitMethods.sort((a, b) => {
              return statuses[a.status] - statuses[b.status]
            })
            this.$store.commit("updateDebitMpgMethods", this.debitMethods)
          }
          //credit
          if (res.data.data.types.credit) {
            this.creditStatus = res.data.data.types.credit.status
            this.creditMethods = res.data.data.types.credit.payment_methods
            this.creditWageType = res.data.data.types.credit.wage_type
            this.$store.commit("updateCreditMpgMethods", this.creditMethods)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.full-height {
  height: 100% !important;
}
.boxes {
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.cards {
  margin-right: -5px;
  margin-left: -5px;

  > .flex {
    align-self: flex-end;
    height: 120px;
    width: 100%;
    margin: 5px 5px;
  }
}

@media (max-width: 1200px) {
  .layout {
    justify-content: center;
  }
}
</style>
