const mpg = {
  state() {
    return {
      mpgTypes: [],
      debitMethods: [],
      creditMethods: [],
    }
  },
  mutations: {
    updateDebitMpgMethods(state, payload) {
      state.debitMethods = payload
    },
    updateCreditMpgMethods(state, payload) {
      state.creditMethods = payload
    },
    updateMpgTypes(state, payload) {
      state.mpgTypes = payload
    },
  },
}

export default mpg
