<template>
  <div class="upload-docs-container" :class="{ 'mt-4': isMobileMode }">
    <v-btn
      outlined
      width="200"
      height="46"
      class="upload-docs-btn"
      :ripple="false"
      @click="openAddingNationalCardDialog"
    >
      <div class="font-14-no-666-24">
        <span v-if="isLegalBusiness"> کارت ملی مدیر عامل </span>
        <span v-else style="font-size: 13px"> کارت ملی صاحب کسب‌وکار </span>
      </div>
      <v-icon
        v-if="isShowSuccessStateOnNationalCard"
        size="18"
        style="color: #4c9f87 !important"
        >mdi-check</v-icon
      >
      <v-icon v-else size="18">mdi-arrow-left</v-icon>
    </v-btn>

    <v-btn
      v-if="isLegalBusiness"
      height="46"
      outlined
      width="200"
      :ripple="false"
      class="upload-docs-btn"
      @click="openAddingOfficialNewspaperDialog"
    >
      <div class="font-14-no-666-24">روزنامه رسمی</div>
      <v-icon
        v-if="isShowSuccessStateOnNewspaper"
        size="18"
        style="color: #4c9f87 !important"
        >mdi-check</v-icon
      >
      <v-icon v-else size="18">mdi-arrow-left</v-icon>
    </v-btn>

    <v-btn
      v-if="isLegalBusiness"
      outlined
      width="200"
      height="46"
      :ripple="false"
      class="upload-docs-btn"
      @click="openAddingIntroLetterDialog"
    >
      <div class="font-14-no-666-24">معرفی نامه</div>
      <v-icon
        v-if="isShowSuccessStateOnIntroLetter"
        size="18"
        style="color: #4c9f87 !important"
        >mdi-check</v-icon
      >
      <v-icon v-else size="18">mdi-arrow-left</v-icon>
    </v-btn>

    <v-dialog
      v-model="dialogStatus"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
      @click:outside="closeModals"
    >
      <AddNationalCardPhoto
        v-if="isShowNationalCard"
        @closeModal="closeModals"
        @handle-success-state="isShowSuccessStateOnNationalCard = true"
      />
      <AddOfficialNewspaper
        v-if="isShowNewspaper"
        @closeModal="closeModals"
        @handle-success-state="isShowSuccessStateOnNewspaper = true"
      />
      <AddIntroductionLetter
        v-if="isShowIntroLetter"
        @closeModal="closeModals"
        @handle-success-state="isShowSuccessStateOnIntroLetter = true"
      />
    </v-dialog>
  </div>
</template>

<script>
import AddNationalCardPhoto from "./AddNationalCardPhoto"
import AddOfficialNewspaper from "./AddOfficialNewspaper"
import AddIntroductionLetter from "./AddIntroductionLetter"
import { mapMutations } from "vuex"

export default {
  name: "UploadDocs",
  components: {
    AddNationalCardPhoto,
    AddOfficialNewspaper,
    AddIntroductionLetter,
  },
  data() {
    return {
      dialogStatus: false,
      isShowNationalCard: false,
      isShowNewspaper: false,
      isShowIntroLetter: false,
      isShowSuccessStateOnNationalCard: false,
      isShowSuccessStateOnNewspaper: false,
      isShowSuccessStateOnIntroLetter: false,
    }
  },
  computed: {
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
    shouldActiveSubmitBtn() {
      return this.isLegalBusiness
        ? this.isShowSuccessStateOnIntroLetter ||
            this.isShowSuccessStateOnNationalCard ||
            this.isShowSuccessStateOnNewspaper
        : this.isShowSuccessStateOnNationalCard
    },
    isAllDocsUploaded() {
      return (
        this.isShowSuccessStateOnIntroLetter &&
        this.isShowSuccessStateOnNationalCard &&
        this.isShowSuccessStateOnNewspaper
      )
    },
    isLegalBusiness() {
      return this.$store.state.business.business_type === "legal"
    },
  },
  watch: {
    shouldActiveSubmitBtn(val) {
      if (val) {
        this.changeOnboardingFirstBtnActiveState(true)
      } else {
        this.changeOnboardingFirstBtnActiveState(false)
      }
    },
    isAllDocsUploaded() {
      this.tellToParentAllDocsUploaded()
    },
  },
  methods: {
    ...mapMutations("newbusiness", ["changeOnboardingFirstBtnActiveState"]),
    openAddingNationalCardDialog() {
      if (!this.isShowSuccessStateOnNationalCard) {
        this.closeModals()
        this.dialogStatus = true
        this.isShowNationalCard = true
        this.$store.commit("callAddDocumentmodal", true)
      }
    },
    closeModals() {
      this.dialogStatus = false
      this.isShowNationalCard = false
      this.isShowNewspaper = false
      this.isShowIntroLetter = false
    },
    openAddingOfficialNewspaperDialog() {
      if (!this.isShowSuccessStateOnNewspaper) {
        this.closeModals()
        this.dialogStatus = true
        this.isShowNewspaper = true
        this.$store.commit("callAddDocumentmodal", true)
      }
    },
    openAddingIntroLetterDialog() {
      if (!this.isShowSuccessStateOnIntroLetter) {
        this.closeModals()
        this.dialogStatus = true
        this.isShowIntroLetter = true
        this.$store.commit("callAddDocumentmodal", true)
      }
    },
    tellToParentAllDocsUploaded() {
      if (this.isAllDocsUploaded) {
        this.$emit("all-docs-uploaded", true)
      } else {
        this.$emit("all-docs-uploaded", false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-docs-container {
  display: flex;
  flex-direction: column;
  width: 536px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 32px;

  @media screen and (min-width: 600px) and (max-width: 960px) {
    width: 500px;
    padding: 32px 20px;
  }

  @media screen and (max-width: 600px) {
    width: 316.66px;
  }
}

.upload-docs-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.upload-docs-btn.v-btn--outlined {
  border: thin solid #999999;
}

.upload-docs-btn .v-icon {
  color: #666666 !important;
  position: absolute;
  left: -6px;
}
</style>
