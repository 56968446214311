<script>
export default {
  name: "DescendingIcon",
  props: {
    width: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 12,
    },
    color: {
      type: String,
      default: "#666666",
    },
  },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 16 13.996"
  >
    <path
      :fill="color"
      d="M145.789,0l4.018,5,2.059-2.233A1.348,1.348,0,0,1,154,2.934l3.539,5.828,1.172-.913a.736.736,0,0,1,.446-.173h0c.29,0,.5.22.553.575l.729,5.023a.672.672,0,0,1-.126.552.608.608,0,0,1-.714.1l-4.692-1.936a.58.58,0,0,1-.145-1.066l1.118-.871-3.108-5.181L150.7,7.126a1.342,1.342,0,0,1-2.142-.187l-4.1-5.138Z"
      transform="translate(-144.453)"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
