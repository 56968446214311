<template>
  <v-card>
    <v-toolbar flat height="60">
      <v-btn icon @click.native="closeModal()">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">بارگذاری معرفی‌نامه</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!isNotDisabled"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card color="white" class="my-4 mx-8 py-4 px-8 rounded" flat>
      <v-radio-group v-model="isOwnSignature" class="simpleRadio">
        <v-radio
          label="به تنهایی صاحب امضای تعهدآور هستم"
          value="isOwner"
          color="van_green"
        />
        <v-radio
          label="به تنهایی صاحب امضای تعهدآور نیستم"
          value="isNotOwner"
          color="van_green"
        />
      </v-radio-group>
      <p
        v-if="noNeedToIntroLetter"
        class="my-5 py-6 px-5 text-center background-label font_2"
      >
        شما نیازی به بارگذاری معرفی‌نامه ندارید
      </p>
    </v-card>

    <!-- <v-card
      v-if="needToIntroLetter"
      color="white"
      class="my-4 mx-8 py-4 px-8 rounded"
    >
      <h2 class="font_13">راهنمای معرفی‌نامه</h2>
      <v-row no-gutters class="d-flex">
        <v-col cols="12" sm="8">
          <ul class="mr-5">
            <li>
              <span class="font-12-no-000-24">
                تمامی صاحبین امضای تعهدآور باید این معرفی‌نامه را امضا کنند.
              </span>
            </li>
            <li>
              <span class="font-12-no-000-24">
                معرفی‌نامه شما باید روی سربرگ شرکت به همراه مهر و امضا باشد.
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="d-flex justify-center"
          :class="{ 'mt-2': $vuetify.breakpoint.xsOnly }"
        >
          <div
            class="d-flex align-center justify-center background-label pa-4"
            style="max-width: 180px"
          >
            <v-btn
              tag="a"
              icon
              href="https://cdn.vandar.io/core/document/introduction.docx"
            >
              <v-img
                width="44px"
                height="44px"
                max-height="44px"
                max-width="44px"
                :src="require('@/assets/img/download-icon.svg')"
              />
            </v-btn>
            <span class="mr-2 text-decoration-underline font-12-no-333-20">
              فایل نمونه
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card> -->

    <v-card
      v-if="needToIntroLetter"
      color="white"
      class="my-4 mx-8 pa-8 rounded"
      flat
    >
      <span class="font_13">معرفی نامه چیست؟</span><br />
      <span class="font-12-no-000-24">
        معرفی نامه برای تایید نماینده شرکت برای دریافت خدمات از وندار است.
        <a
          href="https://cdn.vandar.io/core/document/introduction.docx"
          class="ma-0"
          target="_blank"
        >
          نمونه فایل
        </a>
        را دریافت کنید، سپس بارگذاری کنید.
      </span>
    </v-card>

    <v-card
      v-if="needToIntroLetter"
      outlined
      class="d-flex main-card pa-0 mx-8"
      flat
    >
      <div
        class="d-flex align-center justify-center flex-column"
        style="background: #eef6f3; width: 80px; height: 88px"
      >
        <v-img
          width="48px"
          height="49px"
          max-height="49px"
          max-width="48px"
          :src="require('@/assets/img/guide-icon-second.svg')"
        />
        <span class="font_28">راهنما</span>
      </div>
      <ul class="d-flex flex-column justify-center mr-4 font-12-no-000-24">
        <li>تمامی صاحبین امضای تعهدآور باید این معرفی‌نامه را امضا کنند.</li>
        <li>معرفی‌نامه شما باید روی سربرگ شرکت به همراه مهر و امضا باشد.</li>
      </ul>
    </v-card>

    <UploadBox2
      v-if="needToIntroLetter"
      @uploaded-file="uploadedFile = $event"
    />
  </v-card>
</template>

<script>
import servicesRepository from "@/services/servicesRepository"
import UploadBox2 from "@/components/elements/UploadBox2"

export default {
  name: "AddIntroductionLetter",
  components: {
    UploadBox2,
  },
  data() {
    return {
      loading: false,
      uploadedFile: "",
      isOwnSignature: null,
    }
  },
  computed: {
    isNotDisabled() {
      return (
        (!this.loading && this.uploadedFile !== "") || this.noNeedToIntroLetter
      )
    },
    needToIntroLetter() {
      return this.isOwnSignature === "isNotOwner"
    },
    noNeedToIntroLetter() {
      return this.isOwnSignature === "isOwner"
    },
  },
  methods: {
    sendForm() {
      if (this.noNeedToIntroLetter) {
        let payload = {
          business_owner_is_business_legal_owner: true,
        }
        servicesRepository(this)
          .onBoardingService.httpIsBusinessLegalOwner(payload)
          .then(() => {
            this.$store.commit("setIsBusinessLegalOwner")
            this.$emit("handle-success-state")
            this.loading = false
            this.closeModal()
          })
      } else {
        let data = {
          introduction_letter: this.uploadedFile,
        }
        servicesRepository(this)
          .onBoardingService.httpUpload(data)
          .then((response) => {
            this.$store.commit("setBusiness", response.data.data)
            this.loading = false
            this.closeModal()
            this.$emit("handle-success-state")
          })
          .catch((error) => {
            this.loading = false
            //callsnackbar
          })
      }
    },
    closeModal() {
      this.$store.commit("callAddDocumentmodal", false)
      this.$emit("closeModal")
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.v-label) {
  font-size: 12px !important;
  line-height: 24px !important;
}

.background-label {
  background: #edf5f3;
  border-radius: 4px;
  user-select: none;
}

li::marker {
  color: $van_color03;
}

.upload-box {
  border: 1px dashed #e5e5e5;
  position: relative;
}

.upload-guide-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 28%;
  right: 33%;
}

input {
  height: 180px;
  overflow: hidden;
  width: 100%;
  opacity: 0;
  z-index: 3;
  position: relative;
  cursor: pointer;
}

.upload-loader {
  border: 1px dashed #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;

  .delete-button {
    position: absolute;
    bottom: 40px;
  }
}

@media screen and (max-width: 600px) {
  .upload-guide-info {
    bottom: 28%;
    right: 16%;
  }
}
</style>
