<template>
  <div class="d-flex flex-column flex-sm-row mt-6 mt-sm-5">
    <div class="d-flex align-center">
      <v-checkbox
        v-model="isSelected"
        color="van_green"
        class="ma-0 checkbox"
        @change="$emit('hasChanged', isSelected)"
      />
      <div class="bankLogoSquare pa-2 mr-2 radius-4">
        <v-img :src="bankLogoSrc" width="16" height="16" />
      </div>
      <div class="mr-2 font_2 bold bankName">بانک {{ bank.name }}</div>
    </div>
    <div
      class="font_31 van_color02--text d-flex align-center mr-2 mr-sm-0 mt-3 mt-sm-0"
    >
      {{ limitationText }}
    </div>
  </div>
</template>

<script>
import { numbersHelper } from "@/mixins/numbersHelper.js"

export default {
  name: "BankManagementItem",
  mixins: [numbersHelper],
  props: { bank: { type: Object } },
  data() {
    return {
      isSelected: this.bank?.a2a?.is_active,
    }
  },
  computed: {
    limitationText() {
      if (this.totalAmount === -1)
        return `سقف  روزانه نامحدود | ${this.getBankTiming}`

      return `
      روزانه تا
      ${this.amountLimit}
      ${this.currencyName} |
      ${this.getBankTiming}`
    },
    totalAmount() {
      return this.bank?.a2a?.limit?.amount_limit?.total
    },
    bankLogoSrc() {
      return require(`@/assets/img/banks/${parseInt(this.bank.code)}.svg`)
    },
    amountLimit() {
      return this.priceInLetters(parseFloat(this.totalAmount / 10).toString())
    },
    getBankTiming() {
      let timeLimits = this.bank?.a2a?.limit?.time_limit

      timeLimits.sort(
        (a, b) => a.end_time?.split(":")[0] - b.end_time?.split(":")[0]
      )

      return this.calcTime(timeLimits)
    },
  },
  methods: {
    calcTime(timeLimits) {
      if (!timeLimits?.length) return "24 ساعته"

      let text = ""
      for (let index in timeLimits) {
        if (index != 0) {
          text += timeLimits[index].start_time?.slice(0, 5)
          text += " - "
        }
        text += timeLimits[index].end_time?.slice(0, 5)
        text += "   تا "

        if (index == timeLimits.length - 1) {
          text += timeLimits["0"].start_time?.slice(0, 5)
        }
      }

      return text
    },
  },
}
</script>

<style lang="scss" scoped>
.bankName {
  min-width: 120px;
}
.checkbox {
  width: unset !important;
}
</style>
