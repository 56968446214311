<template>
  <div>
    <v-layout class="my-3 font_5 bold">
      <v-flex> {{ kerdarLang.directDebit.withdrawalsDepositsMass }}</v-flex>
    </v-layout>
    <DirectDebitDepositEChart :kerdar-lang="kerdarLang" />

    <DirectDebitSuccessDepositEChart :kerdar-lang="kerdarLang" />

    <DirectDebitWithdrawalByLicenseEChart :kerdar-lang="kerdarLang" />

    <DirectDebitBanksDepositsFrequency :kerdar-lang="kerdarLang" />

    <v-layout class="my-3 font_5 bold">
      <v-flex> {{ kerdarLang.general.unsuccessfulDeposits }}</v-flex>
    </v-layout>

    <DirectDebitUnsuccessfulDeposit :kerdar-lang="kerdarLang" />

    <DirectDebitErrorFrequency :kerdar-lang="kerdarLang" :duration="duration" />

    <v-layout class="my-3 font_5 bold">
      <v-flex> {{ kerdarLang.general.licenses }}</v-flex>
    </v-layout>

    <!-- <DirectDebitLicensesGrowth :kerdar-lang="kerdarLang" /> -->

    <DirectDebitWithdrawalByCustomerEChart :kerdar-lang="kerdarLang" />

    <DirectDebitSuccessfulLicenseDemandRatioEChart :kerdar-lang="kerdarLang" />

    <DirectDebitUsersLicenseExpirationEChart :kerdar-lang="kerdarLang" />

    <!-- <DirectDebitAverageDensityDeposits
      :kerdar-lang="kerdarLang"
      :duration="duration"
    /> -->

    <!-- <DirectDebitAverageDensityOfAutoWithdrawalLicenseDemand
      :kerdar-lang="kerdarLang"
      :duration="duration"
    /> -->

    <DirectDebitBankActiveLicense :kerdar-lang="kerdarLang" />

    <v-layout class="my-3 font_5 bold">
      <v-flex> {{ kerdarLang.general.unsuccessfulLicensDemands }}</v-flex>
    </v-layout>

    <DirectDebitUnsuccessfulDepositsComparison :kerdar-lang="kerdarLang" />

    <DirectDebitMandateErrorFrequency
      :kerdar-lang="kerdarLang"
      :duration="duration"
    />
  </div>
</template>

<script>
import DirectDebitBankActiveLicense from "./elements/DirectDebitBankActiveLicense"
import DirectDebitBanksDepositsFrequency from "./elements/DirectDebitBanksDepositsFrequency"
import DirectDebitSuccessfulLicenseDemandRatioEChart from "./elements/DirectDebitSuccessfulLicenseDemandRatioEChart"
import DirectDebitUnsuccessfulDeposit from "./elements/DirectDebitUnsuccessfulDeposit"
import DirectDebitErrorFrequency from "./elements/DirectDebitErrorFrequency"
import DirectDebitUnsuccessfulDepositsComparison from "./elements/DirectDebitUnsuccessfulDepositsComparison"
import DirectDebitMandateErrorFrequency from "./elements/DirectDebitMandateErrorFrequency"
// import DirectDebitAverageDensityDeposits from "./elements/DirectDebitAverageDensityDeposits"
// import DirectDebitAverageDensityOfAutoWithdrawalLicenseDemand from "./elements/DirectDebitAverageDensityOfAutoWithdrawalLicenseDemand"
import kerdarLang from "@/components/kerdar/utils/langResource.json"
// import DirectDebitLicensesGrowth from "./elements/DirectDebitLicensesGrowth"
import { duration } from "@/components/kerdar/utils/duration"
import DirectDebitDepositEChart from "./elements/DirectDebitDepositEChart"
import DirectDebitSuccessDepositEChart from "./elements/DirectDebitSuccessDepositEChart"
import DirectDebitWithdrawalByCustomerEChart from "./elements/DirectDebitWithdrawalByCustomerEChart"
import DirectDebitWithdrawalByLicenseEChart from "./elements/DirectDebitWithdrawalByLicenseEChart"
import DirectDebitUsersLicenseExpirationEChart from "./elements/DirectDebitUsersLicenseExpirationEChart"

export default {
  name: "DirectDebit",
  components: {
    DirectDebitBankActiveLicense,
    DirectDebitUnsuccessfulDeposit,
    DirectDebitErrorFrequency,
    DirectDebitUnsuccessfulDepositsComparison,
    DirectDebitMandateErrorFrequency,
    DirectDebitBanksDepositsFrequency,
    // DirectDebitAverageDensityDeposits,
    // DirectDebitAverageDensityOfAutoWithdrawalLicenseDemand,
    // DirectDebitLicensesGrowth,
    DirectDebitSuccessfulLicenseDemandRatioEChart,
    DirectDebitDepositEChart,
    DirectDebitSuccessDepositEChart,
    DirectDebitWithdrawalByCustomerEChart,
    DirectDebitWithdrawalByLicenseEChart,
    DirectDebitUsersLicenseExpirationEChart,
  },
  data() {
    return {
      kerdarLang,
      duration,
    }
  },
}
</script>

<style scoped></style>
