<template>
  <v-layout
    class="mb-2"
    :column="reuseIsMobile"
    justify-space-between
    align-start
  >
    <v-flex class="label">
      حساب مبدا
      <span>*</span>
    </v-flex>

    <v-flex v-if="!originAccount" class="withlabel big w-100">
      <v-select
        v-model="originAccount"
        :items="itemsList"
        item-text="value"
        item-value="key"
        return-object
        solo
        flat
        outlined
        :menu-props="{ offsetY: true }"
        placeholder="انتخاب یا جستجو با نام یا شماره حساب‌های ثبت شده"
      >
        <template #item="{ item }">
          <div
            class="d-flex align-center w-100 py-2 px-4"
            :class="{
              'select-box-item-border':
                lastItem && item?.account !== lastItem?.account,
            }"
          >
            <span
              class="d-flex align-center justify-center pa-2 ml-2 icon-wrapper-border"
            >
              <v-img
                width="30"
                max-height="30"
                max-width="30"
                height="30"
                :src="
                  item.name === walletData.name
                    ? item.icon
                    : require(`@/assets/img/banks/${parseInt(
                        item?.iban?.slice(4, 7)
                      )}.svg`)
                "
              />
            </span>
            <div
              v-if="item.name === 'کیف‌ پول وندار'"
              class="d-flex flex-column justify-center font_24"
            >
              {{ item.name }}
            </div>
            <div v-else class="d-flex align-center justify-center font_24">
              <span v-if="item.name"> {{ item.name }} | </span>
              <span class="mr-1">
                {{ item.account }}
              </span>
            </div>
          </div>
        </template>
      </v-select>
    </v-flex>
    <v-flex
      v-else
      class="withlabel big pa-2 field-border"
      :class="{ 'bg-cash-wallet': isSelectedItemCashWallet }"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <span
            class="d-flex align-center justify-center pa-2 ml-2"
            :class="{ 'icon-wrapper-border': !isSelectedItemCashWallet }"
          >
            <v-img
              :width="!isSelectedItemCashWallet ? 30 : 42"
              :max-height="!isSelectedItemCashWallet ? 30 : 42"
              :max-width="!isSelectedItemCashWallet ? 30 : 42"
              :height="!isSelectedItemCashWallet ? 30 : 42"
              :src="
                originAccount.name === walletData.name
                  ? originAccount.icon
                  : require(`@/assets/img/banks/${parseInt(
                      originAccount?.iban?.slice(4, 7)
                    )}.svg`)
              "
            />
          </span>
          <div class="d-flex flex-column justify-center">
            <span class="font_24"> {{ originAccount.name }} </span>
            <span
              v-if="originAccount.name === walletData.name"
              class="font-11-no-333-17"
            >
              {{ originAccount.businessName }}
            </span>
          </div>
        </div>
        <v-btn
          v-if="bankAccountsListHasItem"
          icon
          small
          class="ma-0"
          @click="originAccount = null"
        >
          <v-icon class="d-flex justify-center" size="20"> close </v-icon>
        </v-btn>
      </div>
      <template v-if="!isSelectedItemCashWallet">
        <v-divider class="mt-3 mb-2"></v-divider>
        <div class="d-flex align-center justify-space-between">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span class="ml-2 font_31">موجودی قابل برداشت</span>
                <span class="font_2">
                  <strong>
                    {{
                      balanceAmountWithThousandSeparator(
                        originAccount.available_balance
                      )
                    }}
                  </strong>
                  {{ currencyName }}
                </span>
              </div>
            </template>
            <span class="font_31" style="color: #fff">
              آخرین به‌روز رسانی:
              {{
                jalaliDate(
                  originAccount.balance_updated_at * 1000,
                  "jYYYY/jM/jD - HH:mm"
                )
              }}
            </span>
          </v-tooltip>
          <v-btn
            height="24"
            width="24"
            icon
            :loading="originAccount.loading"
            @click="getLastBalance(originAccount)"
          >
            <v-img
              width="20"
              height="20"
              max-height="20"
              max-width="20"
              :src="require('@/assets/img/reloadIcon.svg')"
            />
          </v-btn>
        </div>
      </template>
    </v-flex>

    <GeneralDialog
      v-if="isShowUpdateAccountErrorModal"
      :active="isShowUpdateAccountErrorModal"
      logo="newIcon/warning.svg"
      content-class="confirmationMessageDialog"
      title="اختلال بانک"
      :width="360"
      btn2-text="متوجه شدم"
      @btn2Clicked="closeBankBalanceUpdatingErrorModal"
    >
      <template #extra>
        <span class="font_30 text-center">
          در حال حاضر به علت اختلال بانک امکان به‌روزرسانی موجودی حساب وجود
          ندارد.
        </span>
      </template>
    </GeneralDialog>
  </v-layout>
</template>

<script>
import screenSize from "@/mixins/screenSize.js"
import servicesRepository from "@/services/servicesRepository"
import currencyConvert from "@/mixins/currencyConvert.js"
import GeneralDialog from "@/components/modals/generalDialog"

export default {
  name: "BankAccountsSelectBox",
  components: {
    GeneralDialog,
  },
  mixins: [screenSize, currencyConvert],
  data() {
    return {
      selectedItem: "",
      itemsList: [],
      walletData: {
        name: "کیف‌ پول وندار",
        icon: require("@/assets/img/vandar-logo-sign.svg"),
        businessName: `کسب و کار ${this.$store.state?.business?.business_name_fa}`,
      },
      isShowUpdateAccountErrorModal: false,
    }
  },
  computed: {
    originAccount: {
      get() {
        return this.selectedItem
      },
      set(value) {
        this.selectedItem = value
        this.sendUpOriginAccount(value)
      },
    },
    isSelectedItemCashWallet() {
      return this.originAccount.name === this.walletData.name
    },
    bankAccountsList() {
      return this.$store.state.accountsList
    },
    bankAccountsListHasItem() {
      return this.bankAccountsList.length > 0
    },
    lastItem() {
      const listLength = this.bankAccountsList?.length
      return listLength > 0 ? this.bankAccountsList[listLength - 1] : ""
    },
  },
  mounted() {
    if (this.bankAccountsListHasItem) {
      this.setUpItemsList()
    } else {
      this.setUpSelectedItem()
    }
  },
  methods: {
    balanceAmountWithThousandSeparator(balanceAmount) {
      const convertedAmount =
        this.reuseConvertCurrencyForRialAmount(balanceAmount)
      return this.$options.filters.thousandSeprator(convertedAmount)
    },
    sendUpOriginAccount(originAccount) {
      this.$emit("origin-account-selected", originAccount)
    },
    async getLastBalance(selectedAccount) {
      try {
        selectedAccount.loading = true
        this.closeBankBalanceUpdatingErrorModal()
        const response = await servicesRepository(
          this
        ).settlementServices.httpGetLastBalance(selectedAccount.iban)

        const accountIndex = this.$store.state.accountsList.findIndex(
          (item) => item.account === selectedAccount.account
        )
        if (accountIndex !== -1) {
          const updatedAccount = response.data.data
          const list = [...this.$store.state.accountsList]
          list[accountIndex] = updatedAccount
          this.$store.commit(
            "setTheMostUpdatedTime",
            updatedAccount.balance_updated_at
          )

          this.$store.commit("callSuccessSnackbar", response.data.message)
          this.$store.commit("setAccountsList", list)
          this.selectedItem = updatedAccount
        }
      } catch (error) {
        this.openBankBalanceUpdatingErrorModal()
      } finally {
        selectedAccount.loading = false
      }
    },
    setUpItemsList() {
      this.itemsList = [this.walletData, ...this.bankAccountsList]
    },
    setUpSelectedItem() {
      this.selectedItem = this.walletData
      this.sendUpOriginAccount(this.selectedItem)
    },
    closeBankBalanceUpdatingErrorModal() {
      this.isShowUpdateAccountErrorModal = false
    },
    openBankBalanceUpdatingErrorModal() {
      this.isShowUpdateAccountErrorModal = true
    },
  },
}
</script>

<style lang="scss" scoped>
.field-border {
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}

.bg-cash-wallet {
  background: #f5f5f5;
  border: none;
}

.icon-wrapper-border {
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.select-box-item-border {
  border-bottom: 1px solid #d5d5d5;
}

:deep(.v-list-item) {
  padding: 0 !important;
}
</style>
