<template>
  <v-layout column wrap justify-center align-start>
    <v-flex class="mx-auto" style="width: 100%">
      <vandarTable
        :update="updateTable"
        @updateSettelment="allupdates()"
        @doneUpdateTable="done_update_table()"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import vandarTable from "../elements/vandarTable.vue"

export default {
  name: "Index",
  components: {
    vandarTable,
  },
  data() {
    return {
      //this is modal status
      status: false,
      addIsFinalize: false,
      name: "",
      sheba: "",
      time: "",
      blocked: true,
      updateSettlement: false,
      updateTable: false,
    }
  },
  methods: {
    done_update_settlement() {
      this.updateSettlement = false
    },
    done_update_table() {
      this.updateTable = false
    },
    update_user() {
      if (this.$store.state.isUser) {
        //erase wallet
        this.$store.commit("eraseWallet", "user")
        this.$store
          .dispatch("retrieveUserObject")
          .then((response) => {
            //DO Nothing
          })
          .catch((error) => {
            //callsnackbar
          })
      } else {
        //erase wallet
        this.$store.commit("eraseWallet", "business")
        this.$store
          .dispatch(
            "retrieveBusinessObject",
            this.$store.state.business.business_name
          )
          .then((response) => {
            //DO Nothing
          })
          .catch((error) => {
            //callsnackbar
          })
      }
    },
    updatejustSettlement() {
      //update settlement and wallet
      this.updateSettlement = true
      this.update_user()
    },
    allupdates() {
      //update settlement and table and wallet
      this.updateSettlement = true
      this.updateTable = true
      this.update_user()
    },
    close_modal(obj) {
      this.status = false
      this.addIsFinalize = obj.status
      if (obj.status) {
        this.name = obj.name
        this.sheba = obj.sheba
        this.time = obj.time
        this.allupdates()
      }
    },
    open_modal() {
      this.status = true
      this.addIsFinalize = false
    },
  },
}
</script>
