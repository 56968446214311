<template>
  <div>
    <template v-if="!isSharedCashIn">
      <div
        class="d-flex justify-space-between"
        :class="reuseIsMobile ? 'align-start' : 'align-center'"
      >
        <div :class="{ 'align-self-center': reuseIsMobile }">
          <p
            class="mb-1"
            :class="reuseIsMobile ? 'font-12-bo-333-18' : 'font_35'"
          >
            شناسه واریز
          </p>
          <span v-if="!reuseIsMobile" class="font_30">
            {{ cashInItem.bank_persian_name }}
          </span>
        </div>
        <div class="d-flex align-center">
          <span class="ml-2" :class="reuseIsMobile ? 'font_35' : 'font_9'">
            {{ cashInItem.code }}
          </span>
          <CopyBtnGeneral
            :value="cashInItem.code"
            :input-id="'hidden-input' + cashInItem?.code"
          />
        </div>
      </div>
      <span v-if="reuseIsMobile" class="font_30">
        {{ cashInItem.bank_persian_name }}
      </span>
      <v-divider class="my-4" style="border-style: dashed" />
    </template>

    <v-row
      class="d-flex flex-wrap align-center justify-center py-3 px-0 cash-in-details"
      no-gutters
      :style="reuseIsMobile ? 'position : relative' : ''"
    >
      <v-col
        class="d-flex align-center justify-center ml-2"
        :cols="reuseIsMobile ? 12 : 1"
        :class="{ 'icon-col': reuseIsMobile }"
      >
        <v-img
          width="32"
          height="32"
          max-height="32"
          max-width="32"
          :src="
            require(`@/assets/img/banks/${parseInt(cashInItem?.bank_code)}.svg`)
          "
        />
      </v-col>
      <div v-if="reuseIsMobile" class="w-100" style="height: 24px"></div>
      <v-col class="d-flex align-center justify-center" cols="10">
        <v-row no-gutters>
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between mb-2"
          >
            <div :class="{ 'd-flex flex-column': reuseIsMobile }">
              <span class="font_30"> شماره حساب وندار </span>
              <span v-if="reuseIsMobile" class="font_2 ml-2">
                {{ cashInItem?.account_number }}
              </span>
            </div>
            <span class="d-flex align-center justify-center">
              <span v-if="!reuseIsMobile" class="font_36 ml-2">
                {{ cashInItem?.account_number }}
              </span>
              <CopyBtnGeneral
                :value="cashInItem?.account_number"
                :input-id="'hidden-input' + cashInItem?.account_number"
              />
            </span>
          </v-col>
          <v-col cols="12" class="d-flex align-center justify-space-between">
            <div>
              <span class="font_30">شماره شبای وندار</span>
              <span v-if="reuseIsMobile" class="font_2 ml-2">
                {{ cashInItem?.sheba }}
              </span>
            </div>
            <span class="d-flex align-center justify-center font_36">
              <span v-if="!reuseIsMobile" class="font_36 ml-2">
                {{ cashInItem?.sheba }}
              </span>
              <CopyBtnGeneral
                :value="cashInItem?.sheba"
                :input-id="'hidden-input' + cashInItem?.sheba"
              />
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CopyBtnGeneral from "@/components/elements/copyBtnGeneral.vue"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "NewCashInIbanItem",
  components: { CopyBtnGeneral },
  mixins: [screenSize],
  props: {
    cashInItem: {
      type: Object,
      default: () => {},
    },
    isSharedCashIn: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style scoped lang="scss">
.cash-in-details {
  background-color: $van_color11;
  border-radius: 4px;
}

.icon-col {
  position: absolute;
  top: -12px;
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid #eeeeee;
}
</style>
