<template>
  <div class="kerdar-detail-link-wrapper">
    <img
      src="@/components/kerdar/assets/kerdar.svg"
      width="16px"
      height="16px"
    />
    <router-link
      :to="
        kerdarIsActive
          ? `${$store.getters.urlParam}/kerdar`
          : `${$store.getters.urlParam}/tools`
      "
      class="kerdar-detail-link"
    >
      <span v-if="kerdarIsActive">
        {{ kerdarLang.general.kerdarNavigationLinkTitle }}
      </span>
      <span v-else> {{ kerdarLang.general.activeKerdarTool }} </span>
    </router-link>
  </div>
</template>

<script>
import kerdarLang from "@/components/kerdar/utils/langResource.json"

export default {
  name: "KerdarNavigationLink",
  data() {
    return {
      kerdarLang,
    }
  },
  computed: {
    kerdarIsActive() {
      return this.$store.state.business.tools?.kerdar.status === "ACTIVE"
    },
  },
}
</script>

<style lang="scss" scoped>
.kerdar-detail-link-wrapper {
  display: flex;
  align-items: center;
}
.kerdar-detail-link {
  color: #666666;
  margin-right: 4px;
  font-size: 11px;
  line-height: 18.65px;
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-underline-offset: 6px;
  cursor: pointer;
}
</style>
