import Vue from "vue"
import { store } from "@/store"

Vue.filter("currency", function (value) {
  if (value === null || !value) {
    return 0
  }
  if (store.state.user.currency === "RIAL") value = value * 10
  return "" + value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
})

Vue.filter("currencyWithRialInput", function (value) {
  if (value === null || !value) {
    return 0
  }
  if (store.state.user.currency === "TOMAN") value = value / 10
  return "" + value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
})
