var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav_bottom"},[_c('v-divider'),_c('v-list',{staticClass:"menu_v_list bold_hover px-5"},[(!_vm.$store.state.isUser)?_c('v-list-item',{attrs:{"to":_vm.$store.getters.urlParam + '/tickets',"exact":""}},[_c('v-list-item-action',[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticClass:"svgs",attrs:{"d":"M13.3335 2.66666H2.66683C1.9335 2.66666 1.34016 3.26666 1.34016 4L1.3335 12C1.3335 12.7333 1.9335 13.3333 2.66683 13.3333H13.3335C14.0668 13.3333 14.6668 12.7333 14.6668 12V4C14.6668 3.26666 14.0668 2.66666 13.3335 2.66666ZM12.6668 12H3.3335C2.96683 12 2.66683 11.7 2.66683 11.3333V5.33333L7.2935 8.22666C7.72683 8.5 8.2735 8.5 8.70683 8.22666L13.3335 5.33333V11.3333C13.3335 11.7 13.0335 12 12.6668 12ZM8.00016 7.33333L2.66683 4H13.3335L8.00016 7.33333Z"}})])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.tickets.title))])],1)],1):_vm._e(),(
        !_vm.$store.state.isUser &&
        (_vm.$store.state.business.role == 'admin' ||
          _vm.$store.state.business.role == 'owner')
      )?_c('v-list-item',{class:{ 'v-list-item--active': _vm.isInBusinessToolsPage },attrs:{"to":_vm.$store.getters.urlParam + '/tools',"exact":""}},[_c('v-list-item-action',[_c('svg',{staticClass:"svgs",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"15.997","viewBox":"0 0 16 15.997"}},[_c('g',{attrs:{"transform":"translate(-0.001 -0.038)"}},[_c('g',{attrs:{"transform":"translate(0.001 0.038)"}},[_c('g',{attrs:{"transform":"translate(0 0)"}},[_c('path',{staticClass:"a",attrs:{"d":"M.441,264.282a1.5,1.5,0,0,0,2.127,2.126l4.937-4.938-2.127-2.125Z","transform":"translate(-0.001 -250.851)"}}),_c('path',{staticClass:"a",attrs:{"d":"M279.69,4.376l2.15-1.112,1.126-2.176-1.05-1.05-2.175,1.127-1.114,2.148-2.615,2.618,1.061,1.063Z","transform":"translate(-266.967 -0.038)"}}),_c('path',{staticClass:"a",attrs:{"d":"M13.719,10.047l-.2-.02a2.972,2.972,0,0,0-1.036.195L6.331,4.071a2.972,2.972,0,0,0,.195-1.036l-.02-.2A2.967,2.967,0,0,0,2.244.338L4.235,2.328a.977.977,0,0,1,.225.36A1,1,0,0,1,2.822,3.74L.832,1.75a2.967,2.967,0,0,0,2.5,4.261l.2.02a3,3,0,0,0,1.036-.194l6.149,6.15a3.018,3.018,0,0,0-.193,1.036l.021.2a2.985,2.985,0,0,0,2.976,2.795,2.934,2.934,0,0,0,1.285-.3L12.812,13.73a1.02,1.02,0,0,1-.227-.359,1,1,0,0,1,.933-1.347.982.982,0,0,1,.348.068.958.958,0,0,1,.359.225l1.991,1.991a2.955,2.955,0,0,0,.3-1.285A2.99,2.99,0,0,0,13.719,10.047Z","transform":"translate(-0.514 -0.038)"}})])])])])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.tools.title))])],1)],1):_vm._e(),(
        _vm.$store.state.isUser ||
        (!_vm.$store.state.isUser && _vm.$store.state.business.role !== 'developer')
      )?_c('v-list-item',{attrs:{"to":_vm.$store.getters.urlParam + '/setting',"exact":""}},[_c('v-list-item-action',[_c('svg',{staticClass:"svgs",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15.563","height":"16","viewBox":"0 0 15.563 16"}},[_c('defs'),_c('path',{staticClass:"a",attrs:{"d":"M10.054,12.8a2.8,2.8,0,1,1,2.8-2.8,2.8,2.8,0,0,1-2.8,2.8M16,10.776A6.216,6.216,0,0,0,16.054,10,6.841,6.841,0,0,0,16,9.2l1.688-1.3a.4.4,0,0,0,.1-.512l-1.6-2.768a.393.393,0,0,0-.488-.176l-1.992.8a5.8,5.8,0,0,0-1.352-.784l-.3-2.12a.4.4,0,0,0-.4-.336h-3.2a.4.4,0,0,0-.4.336l-.3,2.12a5.8,5.8,0,0,0-1.352.784l-1.992-.8a.393.393,0,0,0-.488.176l-1.6,2.768a.394.394,0,0,0,.1.512L4.11,9.2a6.841,6.841,0,0,0-.056.8,6.216,6.216,0,0,0,.056.776L2.422,12.1a.394.394,0,0,0-.1.512l1.6,2.768a.4.4,0,0,0,.488.176l1.992-.808a5.556,5.556,0,0,0,1.352.792l.3,2.12a.4.4,0,0,0,.4.336h3.2a.4.4,0,0,0,.4-.336l.3-2.12a5.825,5.825,0,0,0,1.352-.792l1.992.808a.4.4,0,0,0,.488-.176l1.6-2.768a.4.4,0,0,0-.1-.512Z","transform":"translate(-2.271 -2)"}})])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.setting.title))])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }