<template>
  <v-card
    :class="{
      'disabled-card': checkoutItem.status === 'PENDING_VERIFY',
      'align-center': $vuetify.breakpoint.mdAndUp,
    }"
    :height="$vuetify.breakpoint.smAndDown ? '' : '83'"
    :ripple="false"
    class="row-card-box d-flex px-5 py-3 mb-3 radius-4 clickable"
    flat
    @click="$emit('click-row')"
  >
    <div class="image-border" style="width: 40px; height: 40px">
      <v-img
        :src="checkoutItem.business_logo"
        alt="img"
        aspect-ratio="1"
        class="no-radius"
        contain
        width="100%"
      />
    </div>
    <div
      :class="{ 'flex-column mr-1': $vuetify.breakpoint.smAndDown }"
      class="d-flex flex mr-4"
    >
      <div
        :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
        class="font_24 flex"
      >
        {{ checkoutItem.business_company_name }}
      </div>
      <div
        :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
        class="font_2 flex"
      >
        {{ dateTime(checkoutItem.time + " - " + checkoutItem.date) }}
      </div>
      <div class="font_2 flex">از {{ checkoutItem.bank_name }}</div>
    </div>

    <div
      :style="
        checkoutItem.status === 'FAILED'
          ? 'text-decoration: line-through;text-decoration-color: #d44d42;'
          : null
      "
      class="font_24 text-left"
    >
      {{ checkoutItem.amount | currencyWithRialInput }} {{ currencyName }}
    </div>
  </v-card>
</template>

<script>
import { dateTimeHanddler } from "@/mixins/dateTimeHanddler"

export default {
  name: "TableRow",
  mixins: [dateTimeHanddler],
  props: ["checkoutItem"],
}
</script>

<style lang="scss" scoped>
.no-radius {
  border-radius: 0 !important;
}

.disabled-card {
  * {
    opacity: 0.6;
  }
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.15) !important;
}

.row-card-box {
  transition: all 200ms 20ms ease-in-out;
}
</style>
