<template>
  <v-layout column wrap justify-center align-start>
    <v-flex style="width: 100%">
      <vandarTable :update="update" />
      <!-- @updateSettelment="update_Settelment()" -->
    </v-flex>

    <!-- modals 
    <v-dialog
      content-class="vanmodal"
      v-model="status"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
      persistent
      >
      <addSettlement @closeModal="close_modal" />
    </v-dialog>
    -->

    <v-dialog
      v-model="addIsFinalize"
      width="360"
      content-class="confirmationMessage withdata"
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/checkbox-marked-circle-outline.svg')"
              width="40"
              height="40"
            />
          </v-flex>
          <v-flex class="main-title font_3"> درخواست با موفقیت ثبت شد </v-flex>
          <v-flex
            class="van_color11"
            style="padding: 20px; width: 300px; margin-top: 20px"
          >
            <v-layout column class="rows little-rows">
              <v-flex>
                <v-layout justify-space-between>
                  <v-flex class="label"> صاحب حساب </v-flex>
                  <v-flex style="text-align-last: left">
                    {{ name }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout justify-space-between>
                  <v-flex class="label"> شماره شبا </v-flex>
                  <v-flex style="text-align-last: left">
                    {{ sheba }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout justify-space-between>
                  <v-flex class="label"> زمان واریز </v-flex>
                  <v-flex style="text-align-last: left">
                    {{ time }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="addIsFinalize = false"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import vandarTable from "../elements/vandarTable.vue"

export default {
  name: "Index",
  components: {
    vandarTable,
  },
  data() {
    return {
      //this is modal status
      status: false,
      addIsFinalize: false,
      name: "",
      sheba: "",
      time: "",
      update: false,
      blocked: true,
      blockedNumber: 500000000,
    }
  },
  methods: {
    update_Settelment() {
      //update wallet
      if (this.$store.state.isUser) {
        this.$store
          .dispatch("retrieveUserObject")
          .then((response) => {
            //DO Nothing
          })
          .catch((error) => {
            store.commit("callsnackbar", error)
          })
      } else {
        this.$store
          .dispatch(
            "retrieveBusinessObject",
            this.$store.state.business.business_name
          )
          .then((response) => {
            //DO Nothing
          })
          .catch((error) => {
            store.commit("callsnackbar", error)
          })
      }

      //update settlement and table
      this.update = true
    },
    close_modal(obj) {
      this.status = false
      this.addIsFinalize = obj.status
      if (obj.status) {
        this.name = obj.name
        this.sheba = obj.sheba
        this.time = obj.time

        //update wallet
        if (this.$store.state.isUser) {
          this.$store
            .dispatch("retrieveUserObject")
            .then((response) => {
              //DO Nothing
            })
            .catch((error) => {
              store.commit("callsnackbar", error)
            })
        } else {
          this.$store
            .dispatch(
              "retrieveBusinessObject",
              this.$store.state.business.business_name
            )
            .then((response) => {
              //DO Nothing
            })
            .catch((error) => {
              store.commit("callsnackbar", error)
            })
        }

        //update settlement and table
        this.update = true
      }
    },
    open_modal() {
      this.update = false
      this.status = true
      this.addIsFinalize = false
    },
  },
}
</script>

<style lang="scss"></style>
