<template>
  <v-card class="singleBox detailSettlement">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal', { status: false })">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">جزییات کیف‌پول</v-toolbar-title>
    </v-toolbar>

    <v-layout column>
      <div class="font_2" style="margin: 32px 30px 16px">
        <span class="font_5 bold">کل موجودی کیف‌پول</span
        ><span v-if="overdraft"> (بدون اعتبار مازاد)</span>
      </div>
      <v-flex class="mb-0 mt-0">
        <v-layout column class="rows">
          <v-flex>
            <p class="text-justify">
              موجودی کیف‌پول شامل مبالغ قابل برداشت و غیر‌قابل برداشت است. امکان
              ثبت درخواست برداشت برای کسب‌و‌کار، تنها روی مبالغ قابل برداشت
              امکان‌پذیر است.
              <br />
              غیر قابل برداشت، وجوه دریافتی از ابزارهای درگاه و فرم پرداخت هستند
              که مستقیما نزد شاپرک تجمیع شده و روزانه با یک تراکنش تسویه به حساب
              بانکی کسب‌و‌کار واریز می‌شوند.
            </p>
            <v-layout class="bold" align-center wrap>
              <v-flex>
                کل موجودی کیف پول
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      size="20"
                      style="direction: rtl; width: 20px"
                      class="van_color05--text"
                      v-on="on"
                    >
                      mdi_info_outline
                    </v-icon>
                  </template>
                  <span class="font_33">
                    موجودی کیف‌پول شامل مبالغ قابل برداشت و غیر‌قابل برداشت است.
                  </span>
                </v-tooltip>
              </v-flex>
              <v-flex>
                <v-flex
                  style="text-align-last: left !important; margin-right: 15px"
                >
                  <span dir="ltr">{{ settlementData.wallet | currency }}</span>
                  {{ currencyName }}
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex v-if="settlementData">
            <v-layout class="bold" align-center wrap>
              <!-- <v-flex style="max-width: 170px">مجموع تراکنش‌ها تا </v-flex> -->
              <v-flex>
                قابل برداشت
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      size="20"
                      style="direction: rtl; width: 20px"
                      class="van_color05--text"
                      v-on="on"
                    >
                      mdi_info_outline
                    </v-icon>
                  </template>
                  <span class="font_33"
                    >برداشت‌ها تا سقف این مبلغ در کمتر از ۱ ساعت به بانک ارسال
                    می‌شود.</span
                  >
                </v-tooltip>
              </v-flex>
              <v-flex>
                <v-flex
                  style="text-align-last: left !important; margin-right: 15px"
                >
                  <span dir="ltr">{{
                    settlementData.deductible_amount | currency
                  }}</span>
                  {{ currencyName }}
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- payment_facilitator_wallet -->
          <v-flex v-if="settlementData?.payment_facilitator_wallet">
            <v-layout class="bold" align-center wrap>
              <v-flex>
                تجمیع شده نزد شاپرک (غیرقابل برداشت)
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      size="20"
                      style="direction: rtl; width: 20px"
                      class="van_color05--text"
                      v-on="on"
                    >
                      mdi_info_outline
                    </v-icon>
                  </template>
                  <span class="font_33">
                    واریزهای درگاه و فرم پرداخت که در حساب شاپرک تجمیع می‌شود.
                  </span>
                </v-tooltip>
              </v-flex>
              <v-flex>
                <v-flex
                  style="text-align-last: left !important; margin-right: 15px"
                >
                  <span dir="ltr">{{
                    settlementData?.payment_facilitator_wallet | currency
                  }}</span>

                  {{ currencyName }}
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <template v-if="overdraft">
      <div class="font_5 bold" style="margin: 20px 30px 16px">اعتبار مازاد</div>
      <v-layout>
        <v-flex class="mt-0 mb-3">
          <v-layout column class="rows">
            <v-flex v-if="settlementData">
              <v-layout class="bold" align-center wrap>
                <v-flex> اعتبار مازاد </v-flex>
                <v-flex>
                  <v-flex
                    style="text-align-last: left !important; margin-right: 15px"
                  >
                    <span dir="ltr">{{ overdraft.balance | currency }}</span>
                    {{ currencyName }}
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout class="bold" align-center wrap>
                <v-flex> مصرف امروز </v-flex>
                <v-flex>
                  <v-flex
                    style="text-align-last: left !important; margin-right: 15px"
                  >
                    <span dir="ltr">{{ overdraft.used | currency }}</span>

                    {{ currencyName }}
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout class="bold" align-center wrap>
                <v-flex> قابل استفاده </v-flex>
                <v-flex>
                  <v-flex
                    style="text-align-last: left !important; margin-right: 15px"
                  >
                    <span dir="ltr">{{ overdraft.remaining | currency }}</span>

                    {{ currencyName }}
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
    <v-dialog
      v-model="detailWalletDescription"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <detail-wallet-more-description
        v-if="detailWalletDescription"
        @closeModal="close_modal"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import DetailWalletMoreDescription from "./detailWalletMoreDescription"
export default {
  name: "DetailWallet",
  components: { DetailWalletMoreDescription },
  props: ["settlementData", "overdraft"],
  data: function () {
    return {
      detailWalletDescription: false,
    }
  },
  watch: {
    detailWalletDescription: function (newValue) {
      if (!newValue) this.close_modal()
    },
  },
  methods: {
    close_modal() {
      this.detailWalletDescription = false
      this.$store.commit("changeSecondWalletDetailModal", false)
    },
    open_moreDetails() {
      this.detailWalletDescription = true
      this.$store.commit("changeSecondWalletDetailModal", true)
    },
    openAddSettlement() {
      this.$emit("closeModal", { status: true })
    },
    checkAmount() {
      var param
      this.$store.state.isUser ? (param = "user") : (param = "business")
      var currentWallet = this.$store.state[param].wallet
      return currentWallet >= 5000
    },
  },
}
</script>
