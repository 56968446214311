var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showModalBoxesContainer"},[_c('v-flex',{staticClass:"showModalBoxes"},[_c('v-layout',{staticClass:"rows mainBody",attrs:{"column":""}},[_c('v-flex',[_c('v-layout',{attrs:{"column":_vm.$vuetify.breakpoint.xsOnly}},[_c('v-flex',{staticClass:"van_color04--text"},[_vm._v(_vm._s(_vm.shaparakSettlementDetails?.ipg_transaction_count)+" تراکنش واریز درگاه ")]),_c('v-flex',{class:[
              'd-flex',
              { 'justify-end': _vm.$vuetify.breakpoint.smAndUp },
            ]},[_vm._v(_vm._s(_vm._f("currency")(parseInt( _vm.shaparakSettlementDetails?.ipg_transaction_total_amount / 10 )))+" "+_vm._s(_vm.currencyName)+" ")]),_c('v-flex',{class:[
              'd-flex',
              { 'justify-end': _vm.$vuetify.breakpoint.smAndUp },
              { 'is-visible': !_vm.isAmountIpgTransactionZero },
              { 'is-hidden': _vm.isAmountIpgTransactionZero },
            ]},[_c('a',{staticClass:"linkBorder ma-0",on:{"click":function($event){return _vm.$emit('showDetails', {
                  id: _vm.shaparakSettlementDetails?.id,
                  type: 'ipg,mpg-ipg',
                })}}},[_vm._v(" مشاهده واریز‌ها ")])])],1)],1),_c('v-flex',[_c('v-layout',{attrs:{"column":_vm.$vuetify.breakpoint.xsOnly}},[_c('v-flex',{staticClass:"van_color04--text"},[_vm._v(_vm._s(_vm.shaparakSettlementDetails?.form_transaction_count)+" تراکنش واریز فرم پرداخت ")]),_c('v-flex',{class:[
              'd-flex',
              { 'justify-end': _vm.$vuetify.breakpoint.smAndUp },
            ]},[_vm._v(" "+_vm._s(_vm._f("currency")(parseInt( _vm.shaparakSettlementDetails?.form_transaction_total_amount / 10 )))+" "+_vm._s(_vm.currencyName)+" ")]),_c('v-flex',{class:[
              'd-flex',
              { 'justify-end': _vm.$vuetify.breakpoint.smAndUp },
              { 'is-visible': !_vm.isAmountOfFormTransactionZero },
              { 'is-hidden': _vm.isAmountOfFormTransactionZero },
            ]},[_c('a',{staticClass:"linkBorder ma-0",on:{"click":function($event){return _vm.$emit('showDetails', {
                  id: _vm.shaparakSettlementDetails?.id,
                  type: 'form',
                })}}},[_vm._v(" مشاهده واریز‌ها ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }