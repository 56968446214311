<template>
  <v-layout column>
    <v-flex>
      <v-toolbar flat height="68" color="van_color13">
        <v-btn icon class="ma-0" @click.native="$emit('closeModal')">
          <v-icon class="d-flex justify-center" color="van_color03" size="21">
            close
          </v-icon>
        </v-btn>
        <v-toolbar-title
          class="pr-0 bold subheading"
          :class="{ 'mr-3': $vuetify.breakpoint.smAndUp }"
        >
          {{ toolbarName }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items class="d-flex align-center">
          <slot name="toolbarItems" />
        </v-toolbar-items>
      </v-toolbar>
      <v-layout
        :class="['van_color13 mt-0', customSubToolbarClass]"
        style="border: 1px solid #f5f5f5"
      >
        <v-flex class="sub-tool-bar-style">
          <slot name="subToolbar" />
        </v-flex>
      </v-layout>

      <!--  modal body-->
      <v-layout
        column
        :class="[
          'modal-body van_color12',
          $vuetify.breakpoint.xsOnly ? bodyPaddingXs : bodyPadding,
          customBodyClass,
        ]"
      >
        <v-flex> <slot /></v-flex>
      </v-layout>

      <!-- snackbar-->
      <v-snackbar
        v-if="showSnackBar"
        v-model="showSnackBar"
        :color="snackbarStatus === 'succeed' ? 'van_green' : 'van_red'"
        bottom
        :timeout="snackbarTimeOut"
        class="vanSnackbar"
      >
        {{ snackbarMsg }}
        <v-progress-linear
          :buffer-value="1"
          height="4"
          color="rgba(255,255,255,0.6)"
          :value="Math.floor(100 * (snackbarCurrentTime / snackbarTimeOut))"
        />
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>

<script>
import screenSize from "@/mixins/screenSize"
export default {
  name: "LeftModal",
  mixins: [screenSize],
  props: {
    toolbarName: { default: "", type: String },
    bodyPadding: {
      default: "pa-8",
      type: String,
    },
    bodyPaddingXs: {
      default: "pa-6",
      type: String,
    },
    customBodyClass: { default: "", type: String },
    customSubToolbarClass: { default: "", type: String },
  },
  data() {
    return {
      showSnackBar: false,
      snackbarStatus: "succeed",
      snackbarCurrentTime: 0,
      snackbarTimeOut: 3000,
      snackbarMsg: "",
    }
  },
  watch: {
    snackbarStatus: function (newValue) {
      if (newValue) {
        this.progressBarStart()
      }
    },
    showSnackBar: function (newValue) {
      if (newValue) {
        this.progressBarStart()
      }
    },
  },
  mounted() {
    // this.callInternalSnackBar()
  },
  methods: {
    progressBarStart() {
      this.timeOut = setTimeout(() => {
        this.snackbarCurrentTime += 100
        if (this.snackbarTimeOut <= this.snackbarCurrentTime) {
          this.snackbarCurrentTime = 0
          clearTimeout(this.timeOut)
        } else {
          this.progressBarStart()
        }
      }, 100)
    },
    callInternalSnackBar(status, msg) {
      this.snackbarStatus = status
      this.snackbarMsg = msg
      this.showSnackBar = true
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-body {
  overflow: auto;
  height: calc(100vh - 68px);
}

.vanSnackbar {
  position: sticky !important;
  bottom: 40px;
  .v-progress-linear {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.sub-tool-bar-style {
  border-top: 1px solid #f5f5f5;
  margin-top: 0 !important;
}
</style>
