<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97501L20.1501 7.40001L9.5501 18Z"
      fill="#666666"
    />
  </svg>
</template>

<script>
export default {
  name: "DoneSvg",
}
</script>

<style lang="scss" scoped></style>
