const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpTransactionRtgsDetail(queryParam) {
    return context.$http.get(
      context.$store.getters.apiWithVersionParam("3") +
        "/transaction/" +
        "rtgs/detail/" +
        queryParam,
      {
        headers: apiHeaders(context),
      }
    )
  },
})
