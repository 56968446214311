var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[(!_vm.oneToolIsActive)?_c('div',[_c('NewToolsBox'),(_vm.$store.state.business.status === 1)?_c('WalletStatus',{staticStyle:{"padding-top":"1px"},on:{"refreshQueuedCard":_vm.refreshQueuedCard}}):_vm._e(),(_vm.isThereAnyAccounts)?_c('AccountsList'):_vm._e()],1):(_vm.businessHasToolsProperty)?_c('v-layout',{attrs:{"column":"","wrap":"","justify-center":"","align-start":""}},[_c('NewToolsBox'),_c('v-flex',{staticStyle:{"height":"100%","width":"100%"}},[_c('WalletStatus',{on:{"refreshQueuedCard":_vm.refreshQueuedCard}}),(_vm.isA2aActive && !_vm.noActiveBank && !_vm.isThereAnyAccounts)?_c('A2aDailyCard',{staticClass:"a2aBox",class:{
          'pb-6': _vm.reuseIsMobile,
          'pb-14': _vm.$vuetify.breakpoint.smAndUp,
        },on:{"noBankIsActive":function($event){_vm.noActiveBank = true}}}):_vm._e(),(_vm.isThereAnyAccounts)?_c('AccountsList'):_vm._e()],1),_c('div',{class:`d-flex w-100 ${
        _vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : 'gap-24 mt-8'
      }`},[_c('SettlementQueueCard',{ref:"queuedCard",staticClass:"col pa-0"}),(_vm.$store.state.business?.pic_suspicious_check)?_c('SuspiciousCard',{staticClass:"col pa-0"}):_vm._e()],1),_c('section',{staticClass:"chart-title"},[_c('h2',{staticClass:"mt-6 mr-0 mb-3 ml-auto"},[_vm._v("نگاه کلی تراکنش‌ها")]),_c('KerdarNavigationLink')],1),_c('v-flex',{staticClass:"mb-4",staticStyle:{"width":"100%"}},[_c('OverViewDepositEChart',{attrs:{"kerdar-lang":_vm.kerdarLang}})],1)],1):_vm._e(),(
      _vm.$store.state.business.hasOwnProperty('tools') &&
      (_vm.$store.state.business.tools.requestMoney?.status == 'ACTIVE' ||
        _vm.$store.state.business.tools.ipg?.status == 'ACTIVE' ||
        _vm.$store.state.business.tools.p2p?.status == 'ACTIVE' ||
        _vm.$store.state.business.tools.subscription?.status == 'ACTIVE')
    )?_c('v-flex',[_c('listOfCards',{attrs:{"skeleton":!_vm.status}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }