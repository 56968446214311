<template>
  <div>
    <div class="d-flex justify-space-between w-full align-center mb-5">
      <h2 class="mb-0">پرداخت اعتباری</h2>
      <div>
        <v-btn
          v-show="!loading && status === 'ACTIVE'"
          class="btn_small_normal"
          depressed
          outlined
          :disabled="!activeBanks.length"
          text
          @click="showManagement"
        >
          مدیریت
        </v-btn>
        <v-btn
          v-if="status !== 'PENDING' && status !== 'NOT_ASSIGNED'"
          :loading="loading"
          text
          depressed
          dark
          :outlined="status === 'ACTIVE'"
          class="btn_small_normal ma-0"
          @click="updateMethod(status == 'ACTIVE' ? 'DEACTIVATED' : 'ACTIVE')"
        >
          {{ statuses[status] }}
        </v-btn>
        <v-btn
          v-if="status == 'NOT_ASSIGNED'"
          :loading="loading"
          text
          depressed
          dark
          class="btn_small_normal ma-0"
          @click="addType()"
        >
          فعال‌سازی
        </v-btn>
        <div v-if="status == 'PENDING'" class="in-progress">
          {{ statuses[status] }}
        </div>
      </div>
    </div>
    <v-card class="business" color="white" flat>
      <div class="flex-column box-items">
        <div v-if="loading" class="titles">
          <div class="income" style="width: 332px">
            <div
              v-if="loading"
              class="default_gray"
              style="width: 5em; height: 2em"
            />
          </div>
          <div
            v-if="loading"
            class="default_gray"
            style="width: 6em; height: 1em"
          />
        </div>
        <div v-if="!loading" class="titles">
          <span v-if="status !== 'ACTIVE'" class="font_30"
            >در این روش مبلغ خدمات شما از اعتبار مشتریانتان در ارائه‌دهندگان
            پرداخت اعتباری که در آن عضو هستند کسر می‌گردد. لیست ارائه‌دهندگان و
            شرایط تسویه در زیر آمده است.
          </span>
          <div v-show="status === 'ACTIVE'">
            <h2 class="font_35 mb-1">کارمزد تراکنش :</h2>
            <span class="income font_14">{{
              wageType === "APPLICATION_SELF"
                ? "از خودم کسر شود"
                : "از پرداخت‌کننده دریافت شود"
            }}</span>
          </div>
        </div>
        <div class="divider" />
        <div class="more-data">
          <div v-if="loading" class="bank-list">
            <div v-for="i in 12" :key="i" class="bank-item">
              <div
                class="default_gray"
                style="width: 46px; height: 46px; margin: 0"
              />
            </div>
          </div>
          <div v-if="!loading" class="credit-list">
            <h3 :class="{ 'van_color04--text': status !== 'ACTIVE' }">
              سرویس‌های ارائه دهنده
            </h3>
            <div v-for="method in methods" :key="method.id" class="credit-item">
              <v-img
                :lazy-src="method.logo"
                :src="method.logo"
                contain
                height="32px"
                width="32px"
              />
              <span :class="{ 'van_color04--text': status !== 'ACTIVE' }">{{
                method.name
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CreditMethods",
  props: {
    status: {
      type: String,
      required: true,
    },
    methods: {
      type: Array,
      required: true,
    },
    wageType: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      statuses: {
        ACTIVE: "غیرفعال شود",
        PENDING: "در حال بررسی",
        DEACTIVATED: "‌فعال‌سازی",
      },
      isHover: false,
      hover: false,
      banks: [],
    }
  },
  computed: {
    activeBanks() {
      return this.methods.filter((item) => item.status !== "PENDING")
    },
  },
  methods: {
    showManagement() {
      this.$emit("show-management")
    },
    updateMethod(status) {
      this.loading = true
      this.$http
        .put(this.$store.getters.urlMPG + "/payment-method-types/credit", {
          status: status,
        })
        .then((res) => {
          this.status = status
        })
        .finally(() => {
          this.loading = false
        })
    },
    addType() {
      this.loading = true
      this.$http
        .post(this.$store.getters.urlMPG + "/payment-method-types/", {
          payment_method_type_slug: "credit",
        })
        .then((res) => {
          this.$emit("reload")
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.box-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.in-progress {
  color: $van_green;
  background-color: rgb($van_green, 0.1);
  width: 114px;
  height: 36px;
  padding: 9px 11px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  @media screen and (max-width: 960px) {
    margin-left: 0;
    margin-right: auto;
  }
}
.divider {
  background-color: #d5d5d5;
  height: 1px;
  width: 100%;
}
.bank-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
  gap: 8px;
  justify-content: flex-end;
}

.bank-item {
  height: max-content;
}
.credit-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  h3 {
    font-weight: 700;
    font-size: 11px;
    line-height: 17px;
  }
}
.credit-item {
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    font-size: 12px;
    color: #666666;
  }
}

.image-box {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  width: 46px;
  height: 46px;
  border-radius: 4px;
  &.disable {
    opacity: 0.3;
  }
}

.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}

.business {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  box-shadow: none;
  margin: auto;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.business-md-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }

  .more-data {
    display: flex;
    //width: 250px;
    margin-top: 16px;
  }

  .bus {
    background-color: $van_color11;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 11px 5px;
    height: 80px;
    width: 100%;
    margin: 5px;
    max-width: 95%;

    &.bus-right,
    &.bus-left {
      width: 95%;
    }

    &.flat {
      background: transparent;
      color: $van_pink;
      font-size: 12px;
      font-weight: bold;
      height: 28px;
    }
  }
}

.v-avatar {
  margin: 0 0 10px 0;
}

.titles {
  max-width: 563px;
}

h2 {
  margin: 0 0 9px 0;
  line-height: 20px;
}

.income {
  line-height: 24px;
  font-size: 12px;
  color: #666;
  font-weight: normal;

  h2 {
    font-size: 14px;
  }
}

.bus-right {
  margin-left: 10px;
}

.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;
  max-width: 120px;

  &.bus-right,
  &.bus-left {
    width: 50%;
  }

  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}

.business .more-data {
  display: flex;
  width: 384px;
}

.mablagh {
  font-size: 14px;
  font-weight: bold;
}

.sub-text {
  font-size: 9px;
  color: #999;
}

.layout.botton {
  max-width: 88px;

  .v-btn {
    width: calc(100% - 16px);
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.v-chip--small {
  height: 25px !important;
  margin-top: 5px !important;
}

.full-width {
  width: 100%;
}
</style>
