<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">
        {{ tool().title }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          v-if="
            (tool().enable == 'INACTIVE' || ipgHardReject) &&
            $store.state.business.status === 1
          "
          text
          dark
          :disabled="ipgHardReject"
          class="btn_small_normal"
          @click="$emit('activeTool', whichtool)"
        >
          فعال‌سازی
        </v-btn>
        <div
          v-else-if="
            (tool().enable == 'PENDING' && whichtool != 'cash-in') ||
            toolStatus == 'TERMINATED'
          "
          class="in-progress"
        >
          در حال بررسی
        </div>
        <div
          v-else-if="toolStatus == 'PENDING' && whichtool == 'cash-in'"
          class="waitForContract"
        >
          در انتظار مدارک
        </div>
        <v-btn
          v-else-if="tool().enable == 'ACTIVE'"
          text
          depressed
          outlined
          :disabled="isCustomerDiactivationDisabled"
          class="btn_small_normal"
          @click="$emit('suspendTool', whichtool)"
        >
          غیرفعال شود
        </v-btn>
        <div v-else-if="tool().enable == 'FAILED'" class="failed">
          در انتظار اصلاح
        </div>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout
      column
      :class="whichtool != 'log' && whichtool != 'invoice' ? 'twoFlex' : ''"
    >
      <v-flex
        class="tools-description"
        @click="handleClick"
        v-html="tool().subTitle"
      />
      <v-flex
        v-if="
          ![
            'log',
            'MPG',
            'invoice',
            'p2p',
            'refund',
            'cash-in',
            'PBV',
            'miando',
          ].includes(whichtool)
        "
      >
        <v-layout justify-center align-center>
          <v-flex class="ml-4" style="max-width: 160px">
            <v-img :src="bigImage" width="120" height="120" />
          </v-flex>
          <v-flex
            style="text-align-last: right; cursor: pointer"
            @click="pushTODoc(tool().address)"
          >
            <div v-if="tool().title3" class="font_27 bold mb-4">
              {{ tool().title3 }}
            </div>
            <div class="title2">
              {{ tool().title2 }}
            </div>

            <v-img
              width="24"
              height="24"
              style="user-select: none"
              :src="require('@/assets/img/Arrow.svg')"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { numbersHelper } from "@/mixins/numbersHelper"

export default {
  name: "Introduce",
  mixins: [numbersHelper],
  props: {
    whichtool: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            "MPG",
            "requestMoney",
            "ipg",
            "subscription",
            "api",
            "log",
            "customer",
            "invoice",
            "p2p",
            "refund",
            "settlement_batch",
            "cash-in",
            "cash-in-manual",
            "kerdar",
            "accountant",
            "PBV",
            "miando",
            "A2A",
          ].indexOf(value) !== -1
        )
      },
    },
    toolStatus: {
      type: String,
      required: true,
      default: null,
    },
  },
  data: function () {
    return {
      tools: {
        MPG: {
          enable: true,
          title: "پرداخت یکپارچه",
          subTitle: `چگونگی دریافت پول از مشتریان، از چالش‌های هر کسب‌و‌کاری است. شما نیاز دارید که بتوانید با تمام روش‌های پرداخت موجود و کمترین تلاش برای پیاده ‌سازی، چنین خدماتی را به مشتریان‌تان ارائه دهید.در این راستا وندار تلاش کرده‌است، سرویس یکپارچه‌ای از تمامی روش‌های پرداخت، را به صورت تجمیع‌شده به شما عرضه کند.
محصول «پرداخت یکپارچه» به منظور حل چالش‌های مطرح شده برای کسب‌وکارها ارائه شده است.
برای فعال‌سازی هر روش پرداخت، شرایط کاری گروه پرداخت را مطالعه می‌کنید و طبق مدل کسب‌و کاری‌تان، تصمیم ‌می‌گیرید که کدام‌یک از روش‌های پرداخت برای شما مناسب است. (امکان فعال‌سازی یک یا همه موارد باهم وجود دارد). در صورت تمایل برای یک یا همه روش‌های پرداخت، درخواست ثبت می‌کنید و پس از آن پشتیبانی برای دریافت مدارک لازم، با شما تماس می‌گیرد، پس از تایید توسط وندار و بانک انتخابی، روش پرداخت برای شما فعال می‌شود.پس از فعال‌ شدن، روش‌های پرداخت در سایت یا محصول‌ شما برای نمایش به مشتریان‌تان در دسترس خواهد بود.
</br>
</br>
در حال حاضر وندار فقط روش «پرداخت از حساب» را ارائه می‌دهد، در این روش، پرداخت‌کننده باید مجوز پرداخت از حساب بانکی خود (بانک هایی که کسب‌وکار فعال کرده است) را طی فرایندی و در چارچوب مشخص به کسب و‌کار تحویل دهد. این مجوز به ازای هر بانک و کسب‌و‌کار دریافت می‌شود.
</br>
به زودی در آینده روش‌های پرداخت دیگری به این قسمت اضافه خواهد شد.`,
        },
        miando: {
          enable: true,
          title: "معامله امن",
          subTitle: `میاندو سرویس معامله امن وندار است که با هدف کاهش ریسک تقلب و کلاهبرداری در معاملات، فرایند خرید و فروش بین فروشندگان و خریداران را تسهیل می‌کند. میاندو با احراز هویت طرفین، ریسک معامله با افراد ناشناس را، از بین می‌برد.
          </br>
          بعد از تایید هویت طرفین،‌ هر یک از آن‌ها می‌توانند لینک معامله را ایجاد کرده و برای طرف دوم ارسال کنند. با تایید فروشنده،‌ میاندو لینک پرداخت را برای خریدار ارسال می‌کند. وجه پرداخت شده، تا پایان موفقیت آمیز معامله نزد حساب امانی میاندو باقی می‌ماند.
          <br>
          در صورت بروز اختلاف، ‌کارشناسان میاندو،‌ با بررسی مدارک و شواهد،‌ مشکل را حل و فصل می کنند.
          <br>
          متناسب با نیازمندی‌های کسب‌وکارتان،‌می‌توانید از گزینه‌های پیاده‌سازی میاندو استفاده کنید. در صورت نیاز متخصصین فنی ما شما را در این مسیر همراهی می‌کنند.`,
          title3: "خرید و فروش امن",
          title2: "خرید و فروش بدون ریسک کلاهبرداری",
          address: "/miando-business",
        },
        requestMoney: {
          enable: true,
          title: "معرفی فرم پرداخت",
          subTitle: `<p>
            فرم پرداخت یا لینک پرداخت، یک راه ساده و سریع برای دریافت پول در کسب‌وکارها با ابعاد بزرگ، متوسط و حتی خانگی است. با ساخت فرم دلخواهتان در حساب کاربری وندار، یک لینک دریافت می‌کنید، آن را با مشتری‌های خود به اشتراک می‌گذارید، و آنها نیز به سادگی پرداخت خود را انجام می‌دهند.<br>
هرچند که فرم پرداخت، روش محبوب کسب‌وکارهای متوسط و خانگی است، اما از آنجا که هزینه‌های برنامه‌نویسی در آن کاهش می‌یابد، کسب‌وکارهای بزرگ نیز از آن استقبال می‌کنند.<br>
فرم پرداخت، که اولین محصول وندار است، با فراهم آوردن امکان پرداخت مبتنی بر کارت، عملیات پرداخت‌ را بسیار آسان می‌کند.<br>
فرم پرداخت از جمله روش‌های «بدون کد» است که استفاده از آن، نیازمند برنامه‌نویسی و اتصال به وب‌سرویس نیست.
            </p>
            <ul>
              <span style='color: #666666; font-size:12px; font-weight: 700'>
                سایر مزیت‌های فرم پرداخت:
              </span>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  کاربری بسیار آسان، بدون نیاز به دانش فنی؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  امکان ایجاد فرم با مبلغ ثابت یا قابل ویرایش؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  مشخص‌کردن سقف مبلغ کل یا بازه زمانی در فرم؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  هدفمند و نامحدود، با بی‌نهایت فرم برای کارهای مختلف با اهداف گوناگون؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  امکان تنظیم دریافت کارمزد از مشتری یا پذیرنده؛
                </span>
              </li>
            </ul>
          `,
          title3: "فرم پرداخت",
          title2: "درگاه پرداخت فوری بدون نیاز به برنامه نویسی",
          address: "/form",
        },
        A2A: {
          enable: true,
          title: "معرفی تسویه آنی",
          subTitle:
            "<p>سرویس تسویه آنی (A2A) در راستای به حداقل رساندن زمان واریز پول به حساب مشتریان و صاحبان کسب و کار، امکان تسویه آنی را برای مشتریان وندار، فراهم می‌کند." +
            " <br> با قابلیت تسویه تسویه آنی، تمامی کسب و کارها می‌توانند درخواست‌های تسویه خود را ثبت کنند تا در اولین زمان ممکن، انتقال وجه صورت گیرد.</p>" +
            "<p> همچنین این امکان به کسب و کارها داده شده‌است، تا از میان بانک‌های فعال برای سرویس تسویه آنی، ‌بانک‌های مورد نظرشان را انتخاب کنند." +
            " سقف برداشت روزانه و محدودیت زمانی هر بانک، شرایط ویژه خود را دارد. جزییات محدودیت‌های هر بانک، در منوی میز‌ کار قابل مشاهده است.</p>" +
            "<p> فعال سازی این ابزار برای همه کسب و کارها،" +
            " بدون نیاز به ثبت اطلاعات جدید و در لحظه، صورت می‌گیرد. برای استفاده لازم است از طریق داشبورد وندار و یا API ارائه شده، اقدام کنید.</p>" +
            `
            <ul>
              <span style='color: #666666; font-size:12px; font-weight: 700'>
                سایر مزیت‌های تسویه آنی:
              </span>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  ثبت تسویه در تعطیلات رسمی؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  صرفه‌جویی در  زمان، به‌واسطه امکان واریز پول به شرکا و همکاران در لحظه، و خارج از چرخه‌های پایای بانکی؛
                </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  عدم نیاز به تعامل و پیاده‌سازی فنی بانک‌های مختلف؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  کاهش بار حسابداری با تسویه در تعداد و حجم بالا، بدون دردسرهای حساب‌داری؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  مغایرت‌گیری دقیق و ارائه‌ گزارش‌ مغایرت‌گیری به مشتری؛
                </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  سهولت و روانی کل فرایند به‌واسطه بارگذاری فهرست شماره شبا و سپردن بقیه فرایند تسویه گروهی به وندار؛
                </span>
              </li>
            </ul>
            `,
          title3: "تسویه آنی",
          title2: "تسویه حساب‌ به حساب و در لحظه",
          address: "/payout-ani/",
        },
        ipg: {
          enable: true,
          title: "معرفی درگاه پرداخت",
          subTitle: `<p>
            درگاه پرداخت، رایج‌ترین و محبوب‌ترین روش پرداخت اینترنتی نزد مشتریان است زیرا آنها از طریق درگاه پرداخت، به آسانی می‌توانند با کارت بانکی خودشان به حساب کسب‌وکار شما پول واریز نمایند. این ابزار فینتکی، شریان حیاتی جریان مالی کســـب‌وکار شماست زیرا به‌عنوان رابط میان وب‌سایت و حساب تجاری شما عمل می‌کند.<br>
            از طریق وندار می‌توانید به‌طور همزمان برای یک کسب‌و‌کار از چند PSP چند درگاه پرداخت داشته باشید. همچنین امکان سوییچینگ هوشمند بین این چند PSP درگاه‌های پرداخت وجود دارد که شانس موفقیت عملیات پرداخت را به شدت افزایش می‌دهد و باعث افزایش نرخ تبدیل برای شما می‌شود.
            </p>
            <ul>
              <span style='color: #666666; font-size:12px; font-weight: 700'>
                سایر مزیت‌های درگاه پرداخت وندار:
              </span>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  استعلام و تطبیق هویت پرداخت‌کننده، و جلوگیری از تراکنش‌های پر ریسک؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  امکان واریز مستقیم به حساب بانکیِ شما در تمام بانک‌ها؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  گزارش لحظه‌ای از وضعیت هر تراکنش، از لحظه حضور پرداخت‌کننده در درگاه پرداخت تا موفق یا ناموفق بودن تراکنش؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  ارائه نمودارهای جامع خطاها و وضعیت‌ پرداخت‌های مشتریان از درگاه پرداخت؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  امکان بازگشت وجه (ریفاند) به صورت کامل یا جزئی، به درخواست پذیرنده و بدون نیاز به تنظیمات اضافی؛
                </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  امکان دریافت کارمزدِ تراکنش از مشتری در صورت تمایل پذیرنده؛
                </span>
              </li>
            </ul>
            `,
          title3: "درگاه پرداخت",
          title2: "خشت اول رشد کسب‌و‌کار",
          address: "/payment-gateway/",
        },
        PBV: {
          enable: true,
          title: "معرفی پرداخت با وندار",
          subTitle:
            "<p>سرویس «پرداخت با وندار» با هدف توانمندسازی کسب‌وکارها در زمینه پرداخت مشتریان‌شان ایجاد شده است.\n" +
            "این توانمندسازی دو بخش را شامل می‌شود: کسب‌وکارها و مشتریان کسب‌وکارها.</p>" +
            '<p class="font_9">بخش کسب‌وکارها\n</p>' +
            "<p>به حداقل رساندن تلاش کسب‌وکار برای ارائه روش‌های پرداخت مختلف از جمله:\n</p>" +
            '<ul class="pr-6">' +
            "<li>درگاه پرداخت</li>" +
            "<li>پرداخت با یک کلیک</li>" +
            "<li>پرداخت اعتباری BNPL (Buy Now, Pay Later)</li>" +
            "<li>پرداخت خودکار (Direct Debit)</li>" +
            "<li>پرداخت اعتباری اقساطی</li>" +
            "</ul>" +
            "<p></p>" +
            "<p>کافیست که  کسب‌وکار فقط یک بار سرویس «پرداخت با وندار» را پیاده‌سازی کرده تا مشتریان بتوانند از تمام روش‌های پرداختی (به انتخاب کسب‌وکار) بهره‌مند شوند. از مزایای این نوع پیاده‌سازی می‌توان به موارد زیر اشاره کرد:</p>" +
            "<p></p>" +
            '<ul class="pr-6">' +
            "<li>کاهش هزینه فنی و مالی پیاده‌سازی روش‌های پرداخت به‌صورت جداگانه</li>" +
            "<li>کاهش نیاز به نیروی انسانی</li>" +
            "<li>فعال‌سازی روش‌های پرداخت در لحظه</li>" +
            "<li>مواجهه کمتر با پیچیدگی‌های قانونی در روند ارائه روش‌های متنوع پرداخت</li>" +
            "<li>افزایش نرخ موفقیت تراکنش‌های پرداخت مشتریان</li>" +
            "<li>افزایش فروش کسب‌وکار به دلیل ارائه امکان پرداخت با روش‌های مختلف </li>" +
            "</ul>" +
            "<p></p>" +
            '<p class="font_9">بخش مشتریان کسب‌وکار\n</p>' +
            "<p>مشتریان می‌توانند دسترسی‌های لازم روی حساب‌های بانکی و اعتباری خود را یک بار به وندار اعطا کنند تا وندار وظیفه احراز هویت و مراوده مالی تراکنش‌های مشتری را با تأمین‌کننده مالی و اعتباری (بانک یا سرویس‌های اعتباری) انجام دهد. از مزیت‌های سرویس «پرداخت با وندار» برای مشتریان به موارد زیر می‌توان اشاره کرد:\n</p>" +
            '<ul class="pr-6">' +
            "<li>پرداخت فاکتورها فقط با یک کلیک</li>" +
            "<li>سهولت خرید آنلاین</li>" +
            "<li>بهره‌مندی از تنوع روش‌های پرداخت در کسب‌وکارهای مختلف</li>" +
            "<li>پرداخت امن‌تر</li>" +
            "<li>دریافت گزارش‌های پرداخت به‌صورت دقیق‌تر و کاربردی‌تر</li>" +
            "</ul>",
        },
        subscription: {
          enable: true,
          title: "معرفی پرداخت خودکار",
          subTitle: `<p>
            بسیاری از کسب‌و‌کارهای امروزی بر مبنای تنظیم تراکنشِ‌های دوره‌ای و تکراری کار می‌کنند. یعنی در زمان‌های مشخصی، مثلاً هر دو هفته یا هر ماه یکبار، پولی از حساب مشتری به حساب کسب‌وکار جابجا شود. کسب‌وکارهای اشتراکی، که اساس درآمدشان بر دریافت آبونمان و یا فروش حق اشتراک است، از این نوع هستند.<br>
این نیازِ قابل توجه کسب‌وکارها و مشتریان، مبنای خلق سرویس پرداخت خودکار وندار است که در عرف بین‌الملل به «دایرکت‌دبیت» نیز معروف است.<br>
با استفاده از سرویس پرداخت خودکار وندار، هزینه خدمات شما به صورت خودکار از حساب مشتریا‌نتان پرداخت می‌شود. کافی است فقط یک بار اجازه‌ اتصال به حساب بانکی به‌صورت آنلاین و قانونی از مشتری دریافت شود. پس از آن، مبلغ توافق‌شده، در زمان‌های مشخص و به‌طور خودکار از حساب بانکی مشتری به حساب کسب‌وکار شما منتقل می‌شود.<br>
یکی از مهم‌ترین مزایای سرویس پرداخت خودکار، بهبود جریان نقدی شرکت‌ شماست. زیرا تمام هزینه‌های زمانی و مالی ناشی از تأخیر در پرداخت‌های مشتریان تقریباً حذف می‌شوند و شما بهتر می‌توانید برای امور مالی و پیش‌بینی‌های آینده تصمیم بگیرید.
            </p>
            <ul>
              <span style='color: #666666; font-size:12px; font-weight: 700'>
                سایر مزیت‌های پرداخت خودکار:
              </span>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  افزایش درآمد با حل مشکل تأخیر در پرداخت مشتریان؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  پیش‌بینی جریان مالی با تخمین پرداخت هر مشترک؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  افزایش «ارزش طول عمر مشتری» (CLV) با تجربه کاربری جذاب و سهولت پرداخت برای مشتریان؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  کاهش هز‌ینه‌های یادآوری با صرفه‌جویی در زمان، هزینه مالی و نیروی انسانی؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  کاهش نرخ ریزش با حفظ اشتراک مشتریان؛
                </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  کمک به برنامه وفاداری مشتریان با خودکارسازی فرایند پرداخت؛
                </span>
              </li>
            </ul>
          `,
          title3: "پرداخت خودکار",
          title2: "دریافت حق‌اشتراک، به صورت خودکار",
          address: "/direct-debit/",
        },
        api: {
          enable: true,
          title: "معرفی وب سرویس",
          subTitle:
            "هر آنچه در داشـبورد وندار در اختیار شماست، از طـریق وب‌سرویس نیز در دسترس شماهای وندار امکان مدیریت یکپارچه امور مالی کسب‌و‌کار شما درAPI خواهد بود. استفاده سیستم‌های نرم‌افزاری شما را ایجاد می‌کند",
          title2: "ظرفیت توسعه‌ی بی‌حَد و مرز",
          address: "/web-service",
        },
        log: {
          enable: true,
          title: "معرفی گزارش فراخوانی",
          subTitle:
            "با گزارش فراخوانی هیچ‌ چیز از چشم شما پنهان نمی‌ماند. گزارشی که همه‌ی تراکنش‌ها، توکن‌های صادرشده، ورود و خروج‌های حساب کاربری، تغییرات تنظیمات حساب و در یک کلام هر آنچه در حساب شما اتفاق بیافتد را در گزارشی کامل و پرجزییات در اختیار شما می‌گذارد.",
        },
        customer: {
          enable: true,
          title: "معرفی مشتریان",
          subTitle: `<p>
            محصول مشتریان، ابزار توانمندسازی کسب‌و‌کارها برای شناخت، ارزیابی و مدیریت مشتریانشان است. این ابزار به شما کمک می‌کند که مشتریان خود را، بر اساس تشخیص چهره و تطابق با اطلاعات ثبت‌احوال، به طور کامل بشناسید، به راحتی استعلام‌های بانکی ضروری آنها را بگیرید و تعاملات خود را با آنها، به شکل مؤثرتری مدیریت کنید.
            </p>
            <ul>
              <span style='color: #666666; font-size:12px; font-weight: 700'>
                امکانات سرویس مشتریان              </span>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  احراز هویت بایومتریک
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  استعلام‌های بانکی
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  نویسه‌خوان مدارک (OCR)
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  واریز شناسه دار
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  کیف‌پول مشتری
                </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  واریزها و برداشت‌های مشتری
                </span>
              </li>
            </ul>
            `,
          title3: "مشتریان",
          title2: "مدیریت تعاملات با مشتریان و شرکا",
          address: "/customers/",
        },
        invoice: {
          enable: true,
          title: "فاکتورها",
          subTitle:
            "فاکتور، برگه‌ی فروش شماست که در آن خدماتی که به مشتری ارائه داده‌اید، مبلغ صورت حساب خدمات و وضعیت پرداخت (برداشت موفق یا ناموفق، در انتظار برداشت و برداشت لغو شده) مشخص شده است. ",
        },
        p2p: {
          enable: true,
          title: "انتقال وجه داخلی",
          subTitle:
            "مکـمل ابـزار تسویه وندار. با فعال‌سازی انتقال وجه داخلی دیگر نیازی نیست موجـودی کیـف پول کسـب‌وکارتان را یک بار به حسـاب بانکی و سپس با پرداخـت کارمـزدِ انتـقال به حسـاب مشـتری یا شرکای‌ خود واریز کنید. کافی است شناسه‌ی وندار مقصد را وارد کنید تا پول مسـتقیما از کیـف پول شما به کیف پول مشـتری یا شـرکا‌ی‌تان نزد وندار، منتقل شود. انتقالی آنی که بخـش زیادی از تسویه حساب‌های شما را از چـرخه‌های پایا و سـاعت‌های تسـویه واریز‌های بانکی بی‌نیاز می‌کند<p></p>با فعـال‌سازی این ابزار می‌توانید از موجودی کیف پول‌تان نزد وندار مسـتقیما به کیف پـول اشـخاص و کسـب‌وکارهای فعال در ونـدار واریز کنید. کافی است شـناسه‌ی وندار دریافت‌کننده‌ی وجه را وارد نمایید",
        },
        refund: {
          enable: true,
          title: "بازگشت وجه",
          subTitle:
            "ابزار بازگشت وجه اما این مشکل را به سادگی حل می‌کند. روی یک تراکنش انجام‌شده در درگاه یا فرم پرداخت کلیک‌ کنید و گزینه‌ی بازگشت وجه را انتخاب نمایید. تمام مبلغ تراکنش‌ (تا سقف ۵۰میلیون تومان) به کارت بانکی پرداخت‌کننده بازگردانده خواهد شد.<p></p> در خرید اینترنتی خطاهای مختلفی می‌تواند رخ دهد. گاهی این خطاها باعث می‌شوند پس از نهایی شدن پرداخت و انتقال پول به حساب بانکی پذیرنده، خریدار خواستار بازگشت پول به حسابش (Refund) شود. چه خریدار از خرید کالا پشیمان شده باشد، چه از حق قانونی پس دادن خریدهای اینترنتی تا ۷ روز استفاده کرده باشد و چه از اساس پول به اشتباه به حساب شما واریز شده باشد، از آنجایی که شماره‌ی شبا یا کارت پرداخت‌کننده را در اختیار ندارید، عودت وجه دریافتی معمولا کاری پرزحمت و دردسرساز است.",
        },
        settlement_batch: {
          enable: true,
          title: "برداشت گروهی",
          subTitle: `با استفاده از این روش می‌توانید تا سقف هزار درخواست تسویه را از کیف پول خود در وندار به حساب بانکی افراد مختلف ثبت کنید.
              <p></p> برداشت گروهی هم روی میزکار و هم در وب‌سرویس شما فعال خواهد شد. برای ثبت درخواست برداشت گروهی در میزکار،
               کافی است یک فایل اکسل از مجموعه‌ی درخواست‌ها با قالب مشخص شده بسازید و آن را روی میزکار بارگذاری کنید.<p></p> اگر از وب‌سرویس وندار استفاده می‌کنید
               هم کافی است به پیوست یک درخواست برداشت، فایل پردازش دسته‌ای آن را هم به وب‌سرویس وندار بفرستید.<p></p>`,
          title3: "برداشت گروهی",
          title2: "ثبت هزار برداشت با یک دستور",
          address: "/payout-paya/",
        },
        "cash-in": {
          enable: true,
          title: "واریز بانکی",
          subTitle: `<p>
          قابلیت «واریز بانکی» یا به اصطلاح ،cash in  یکی از بهترین روش‌های شارژ کیف پول کسب‌وکارهاست که به جعبه ابزار وندار اضافه شده است. کسب‌وکارهایی که مبالغ زیادی از حساب آن‌ها برداشته می‌شود، با استفاده از این ویژگی می‌توانند مبلغی را به کیف پول خود در وندار اضافه کرده و پس از آن تعیین کنند که چه مقدار وجه از کیف پول‌شان خارج شود.
          </p>
          <p>
توجه داشته باشید فعال‌سازی این ابزار، تنها در صورت اتصال به حساب شخصی مالک کسب‌وکار در کسب‌وکارهای حقیقی یا حساب حقوقی شرکت در کسب‌وکارهای حقوقی امکان‌پذیر است.
          </p>
          <p>
برای استفاده از قابلیت «واریز بانکی» کافی‌ست صاحب کسب‌وکار شماره حساب بانک آینده خود را در سایت وارد نماید و پس از اینکه مدارک شما از طرف پشتیبانی وندار تایید شد، ابزار «واریز بانکی» برای‌ شما فعال می‌شود. پس از فعال‌سازی این ابزار، شما می‌توانید کیف پول خود را به مبلغ دلخواه شارژ کنید
          </p>
          `,
        },
        kerdar: {
          enable: true,
          title: "کردار",
          subTitle: `<p>
            طول عمر مشتریان شما چقدر است؟ روند واریزها به کیف پول کسب‌وکار شما چگونه است؟ آیا مبالغ ورودی به کیف پول شما در روز گذشته نسبت به میانگین روزهای اخیر رشد داشته است؟ کردار به این سؤال‌‌ها و بسیاری سؤالات دیگر در رابطه با کسب‌وکارتان پاسخ می‌دهد.<br>
تحلیل داده یکی از مهمترین رویکردها برای ترسیم مسیر یک کسب‌وکار است. هوش تجاری به عنوان روشی برای فهم و تفسیر داده، به کسب‌وکار شما بینش ارائه می‌دهد.<br>
«کردار» همان هوشِ تجاری و چشمِ بینای کسب‌وکار شماست.
            </p>
            <ul>
              <span style='color: #666666; font-size:12px; font-weight: 700'>
                سایر مزیت‌های کردار:
              </span>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  افزایش درآمد، با کشف این که چه عواملی به رشد کسب‌وکار شما سرعت می‌بخشند؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  کاهش هزینه‌های تصمیم‌گیری، به دلیل بی‌نیازی از تشکیل تیم دیتا و تحلیل داده؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  تصویرسازی داده‌ها با ادغام داده‌ها در لحظه، و ارتقای بینش کسب‌وکار؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  فعال‌سازی آسان با یک کلیک در حساب کاربری کسب‌وکارتان در وندار؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  دسترسی رایگان؛
                </span>
              </li>
            </ul>
            `,
          title3: "کردار",
          title2: "تصویرساز داده ها برای بینش مالی",
          address: "/kerdar/",
        },
        accountant: {
          enable: true,
          title: "گزارش‌های مالی",
          subTitle: `<p>
            واحد مالی کسب‌‌وکار شما چقدر مشغله دارد؟ آیا می‌توان با قطعیت گفت که امکان و احتمال خطای فنی در بخش حسابداری، صفر است؟<br>
مدیران مالی و حسابدارها عموماً جزو تصمیم‌سازان کسب‌و‌کارها هستند. این افراد نیاز به دسترسی کامل به دیتای تمیز، قابل مقایسه‌ و قابل اتکا دارند. وندار این نیاز مهم را درک کرده و «گزارش‌های مالی» را برای پاسخ به آن توسعه داده است.<br>
با گزارش‌های مالی وندار می‌توانید کلیه واریز‌ها و برداشت‌های کسب‌وکار خود را در قالب استاندارد، و با جزئیاتِ مورد نیازِ صورت‌های حسابرسی، به تفکیک ماه و روز دریافت کنید.<br>
گزارش‌های مالی، به‌طور خودکار صورت‌های مالی و فاکتور رسمی برای پذیرنده می‌فرستد، کلیه تراکنش‌های موثر بر کیف‌پول وندار را، در قالبی مناسب برای استفاده مدیران و کارکنان مالی یک کسب‌وکار ارائه می‌کند، و کلیه واریزها و برداشت‌ها از کیف‌‌پول وندار را به صورت روزانه ثبت و در حساب کاربری در دسترس قرار می‌دهد. همچنین تمام این تراکنش‌ها به صورت ماهانه قابل دریافت هستند که از اسناد لازم برای ارائه گزارش‌های مالی کسب‌وکارها به‌شمار می‌روند.
            </p>
            <ul>
              <span style='color: #666666; font-size:12px; font-weight: 700'>
                سایر مزیت‌های گزارش‌های مالی:
              </span>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  صرفه‌جویی در زمان که یک فاکتور بسیار مهم و ارزشمند نزد نیروی انسانی است؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  ارائه جزئیات مهم اعم از اطلاعات مربوط به واریزها، برداشت‌ها و کارمزدهای هر تراکنش از کیف پول؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  مغایرت‌گیری به‌واسطه دسترسی به گزارش‌های دقیق و دسته‌بندی‌شده؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  دسته‌بندی اطلاعات از طریق مرتب‌سازی تراکنش‌ها به صورت روزانه و ماهانه؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  دسترسی آسان به اطلاعات مالی و امکان دریافت سریع آنها؛
                  </span>
              </li>
              <li style='color:#4C9F87; font-size: 1rem;margin-right:18px'>
                <span style='font-size:12px; color: #666666'>
                  دسترسی رایگان؛
                </span>
              </li>
            </ul>
            `,
          title3: "گزارش‌های مالی",
          title2: "دستیار هوشمند مالی شما",
          address: "/karbarg/",
        },
      },
    }
  },
  computed: {
    ipgHardReject() {
      return (
        ["ipg", "requestMoney"].includes(this.whichtool) &&
        this.$store.state.business.hasOwnProperty("tools") &&
        this.$store.state.business.tools.ipg.status == "HARD_REJECT"
      )
    },
    isCustomerDiactivationDisabled() {
      return (
        this.$store.state.business.hasOwnProperty("tools") &&
        this.whichtool == "customer" &&
        this.$store.state.business.tools.subscription.status != "INACTIVE"
      )
    },
    bigImage() {
      return require("@/assets/img/tools/" + this.whichtool + "-introduce.svg")
    },
  },
  methods: {
    handleClick(e) {
      if (e.target.id === "downloadContractSample") {
        const url =
          this.$store.state.business.business_type === "legal"
            ? "https://api.vandar.io/files/cash-in-legal.pdf"
            : "https://api.vandar.io/files/cash-in-personal.pdf"
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("target", "_blank") //or any other extension
        link.setAttribute("download", "downloadContractSample.pdf") //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    },
    pushTODoc(address) {
      const baseUrl = "https://vandar.io"
      const url = `${baseUrl}${address}`
      window.open(url)
    },
    tool() {
      this.tools[this.whichtool].enable = this.toolStatus
      return this.tools[this.whichtool]
    },
  },
}
</script>

<style lang="scss" scoped>
nav.v-toolbar > .v-toolbar__content {
  padding: 0 7px 0 7px !important;
}
.v-tooltip__content {
  min-width: auto !important;
  top: 39px !important;
  padding: 5px 8px !important;
}
.tools-description {
  font-size: 12px;
  color: $van_color02;
  line-height: 24px;
  text-align: justify;
  text-align-last: right !important;
}
.twoFlex > .flex:nth-child(2) {
  margin-top: -20px !important;
  padding: 20px 40px !important;
}
.title2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  color: $van_color02;
  margin-bottom: 8px;
}
.in-progress,
.waitForContract,
.failed {
  color: $van_green;
  background-color: rgb($van_green, 0.1);
  width: 114px;
  height: 36px;
  padding: 9px 11px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  //margin-top: 11px;
  @media screen and (max-width: 960px) {
    margin-left: 0;
    margin-right: auto;
  }
}
.waitForContract {
  color: $van_color03;
  background-color: $van_color06;
}
.failed {
  color: $van_color04;
  background-color: rgb($van_color10, 1);
}
.info_panel {
  .inside_list {
    li {
      list-style: inside;
    }
  }
}
</style>
