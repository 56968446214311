const miando = {
  state() {
    return {
      roleFilter: "0",
      stateFilter: "0",
      fromDate: null,
      toDate: null,
      duration: "0",
      updateFilters: false,
      loading: false,
      update: false,
    }
  },
  mutations: {
    updateToAndFormDateMiando(state, date) {
      state.toDate = date
      state.fromDate = date
    },
    updateFiltersMiando(state, obj) {
      state.roleFilter = obj.roleFilter
      state.stateFilter = obj.stateFilter
      state.fromDate = obj.fromDate
      state.toDate = obj.toDate
      state.duration = obj.duration
      state.loading = true
    },
    deleteAllFiltersMiando(state) {
      state.roleFilter = "0"
      state.stateFilter = "0"
      state.fromDate = null
      state.toDate = null
      state.duration = "0"
      state.updateFilters = true
      state.loading = false
    },
  },
  getters: {
    isFilterAppliedMiando(state, getters) {
      if (
        state.roleFilter == "0" &&
        state.stateFilter == "0" &&
        state.duration == "0" &&
        state.fromDate == null &&
        state.fromDate == null
      )
        return false
      else return true
    },
  },
}

export default miando
