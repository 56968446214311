<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> ورود 2 مرحله‌ای </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          v-if="!showModal"
          ref="btn"
          text
          dark
          :disabled="disableBtn"
          class="btn_small_normal"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex>
        <div class="font_2">
          ورود 2 مرحله‌ای یعنی ورود به حساب علاوه بر رمز عبور، نیازمند عنصر
          امنیتی دیگری هم باشد
        </div>
        <v-divider class="mb-7" style="border-style: dashed" />
        <v-form ref="form">
          <v-radio-group v-model="isActive" class="simpleRadio">
            <v-radio label="غیرفعال" :value="false" color="van_green" />
            <v-radio
              label="(Google Authenticator) فعال با رمزساز گوگل"
              value="google"
              color="van_green"
            />
            <v-radio label="فعال با پیامک" value="sms" color="van_green" />
          </v-radio-group>
        </v-form>
      </v-flex>

      <!--      show otp for sms-->
      <v-flex v-if="otpShown === 'sms' && !showModal" style="margin-top: -20px">
        <div
          class="font_2"
          style="text-align-last: center; margin-bottom: 20px"
        >
          کد تایید برای
          {{ $store.state.user.mobile }}
          پیامک شده است:
        </div>
        <otp @setValue="otpCode = $event" />
      </v-flex>

      <!--      show otp for GA-->
      <v-flex v-if="otpShown === 'google'" style="margin-top: -20px">
        <v-layout column>
          <v-flex class="font_2 bold mb-2"> مرحله اول </v-flex>
          <v-flex class="font_2 mb-6">
            اپلیکشن Google Authenticator را نصب کنید. دریافت از
            <span class="linkBorder mx-1"
              ><a
                href="https://cafebazaar.ir/app/com.google.android.apps.authenticator2?l=en"
                target="_blank"
                class="text-decoration-none"
                >کافه بازار</a
              ></span
            >
            <span class="linkBorder mx-1"
              ><a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                target="_blank"
                class="text-decoration-none"
                >پلی استور</a
              ></span
            >
            <span class="linkBorder mx-1"
              ><a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target="_blank"
                class="text-decoration-none"
                >اپ استور</a
              ></span
            >
          </v-flex>
        </v-layout>
        <v-divider class="grayDashedHr mt-5 mb-6" />
        <v-layout column>
          <v-flex class="font_2 bold mb-2"> مرحله دوم </v-flex>
          <v-flex class="font_2 mb-6">
            بارکد زیر را با استفاده از اپلیکیشن Google Authenticator اسکن کرده
            یا کد زیر را وارد کنید.
          </v-flex>
          <v-flex class="justify-center align-center">
            <div class="d-flex justify-center" v-html="qrCode" />
          </v-flex>
          <v-flex class="justify-center">
            <v-layout
              style="max-width: fit-content"
              class="van_color11 pa-2 align-center justify-center ma-auto"
            >
              <v-flex class="ml-2">
                {{ qrValue }}
              </v-flex>
              <v-flex>
                <copy-btn-general :value="qrValue" input-id="hidden-input" />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider class="grayDashedHr mt-5 mb-6" />
        <v-layout column>
          <v-flex class="font_2 bold mb-2"> مرحله سوم </v-flex>
          <v-flex class="font_2 mb-6">
            با استفاده از رمزساز وندار در Google Authenticator کد یک‌بار مصرف را
            وارد کنید. سپس دکمه ذخیره را در بالای صفحه، کلیک کنید.
          </v-flex>
        </v-layout>
        <otp type-of-otp="google" @setValue="otpCode = $event" />
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="showModal"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <left-modal
        toolbar-name="غیرفعال کردن ورود با رمزساز گوگل"
        @closeModal="showModal = !showModal"
      >
        <div slot="toolbarItems">
          <v-btn
            :disabled="!otpCode || otpCode.length !== 6"
            class="btn_small_normal"
            dark
            text
            :loading="loading"
            @click="sendForm"
          >
            ذخیره
          </v-btn>
        </div>
        <general-card v-if="target === 'sms'" class="font_2">
          <div class="text-center">
            برای فعال‌سازی ورود دو مرحله‌ای با پیامک، ابتدا لازم است ورود با
            رمزساز گوگل را غیرفعال کنید.
          </div>
        </general-card>
        <general-card v-else-if="target === 'google'" class="font_2">
          <div class="text-center">
            برای فعال‌سازی ورود دو مرحله‌ای با رمزساز گوگل، ابتدا لازم است ورود
            با پیامک را غیرفعال کنید.
          </div>
        </general-card>

        <general-card class="font_2">
          <div
            v-if="!target && $store.state.user.two_factor_type === 'google'"
            class="text-center"
          >
            برای غیرفعال‌سازی، رمز یک‌بار مصرف ایجاد شده در رمزساز گوگل را وارد
            کنید.
          </div>
          <div v-else-if="target === 'sms'" class="text-center">
            رمز یک‌بار مصرف ایجاد شده در رمزساز گوگل را وارد کنید.
          </div>
          <div
            v-else-if="
              target === 'google' ||
              (!target && $store.state.user.two_factor_type === 'sms')
            "
            class="text-center"
          >
            کد تایید ارسال شده به شماره {{ $store.state.user.mobile }} را وارد
            کنید.
          </div>
          <otp
            v-if="showModal"
            class="mt-7"
            :type-of-otp="
              (target === 'google' &&
                $store.state.user.two_factor_type === 'sms') ||
              (!target && $store.state.user.two_factor_type === 'sms')
                ? 'sms'
                : 'google'
            "
            @setValue="otpCode = $event"
          />
        </general-card>
      </left-modal>
    </v-dialog>
  </v-card>
</template>

<script>
import otp from "@/components/inputs/otp.vue"
import CopyBtnGeneral from "@/components/elements/copyBtnGeneral"
import LeftModal from "@/components/modals/leftModal"
import GeneralCard from "@/components/elements/generalCard"

export default {
  name: "TwoStepAuthentication",
  components: {
    GeneralCard,
    LeftModal,
    CopyBtnGeneral,
    otp,
  },
  data: function () {
    return {
      twoFactor: null,
      twoFactorType: null,
      otpShown: false,
      otpCode: null,
      loading: false,
      valid: false,
      qrCode: null,
      qrValue: null,
      showModal: false,
      target: null,
    }
  },
  computed: {
    disableBtn() {
      return (
        (this.isActive === "google" && this.otpCode?.length !== 6) ||
        (this.isActive === "sms" && this.otpCode?.length !== 5) ||
        this.loading
      )
    },
    isActive: {
      get: function () {
        if (!this.twoFactor) {
          return false
        } else {
          return this.twoFactorType
        }
      },
      set: function (newValue) {
        if (!newValue) {
          this.twoFactor = false
          this.twoFactorType = null
        } else {
          this.twoFactor = true
          this.twoFactorType = newValue
        }
      },
    },
  },
  watch: {
    showModal: function (newValue) {
      if (!newValue) {
        this.$store.commit("changeShowThirdModal", false)
        this.twoFactor = this.$store.state.user.is_two_factor
        this.twoFactorType = this.$store.state.user.two_factor_type
      }
    },
    otpCode: function (newValue) {
      if (
        (this.isActive === "sms" ||
          this.$store.state.user.two_factor_type === "sms") &&
        newValue?.length === 5
      ) {
        this.sendForm()
      }
      if (
        (this.isActive === "google" ||
          this.$store.state.user.two_factor_type === "google") &&
        newValue?.length === 6
      ) {
        this.sendForm()
      }
    },
    isActive: function (newValue) {
      if (newValue === "sms" && !this.$store.state.user.is_two_factor) {
        this.twoFactorSendCode("sms").then(() => {
          this.otpShown = "sms"
          this.$store.commit(
            "callSuccessSnackbar",
            "کد تایید برای شماره همراه شما ارسال شد."
          )
        })
      } else if (
        newValue === "google" &&
        !this.$store.state.user.is_two_factor
      ) {
        this.twoFactorSendCode("google").then((resp) => {
          this.qrCode = resp.data.qr_code
          this.qrValue = resp.data.secret_key
          this.otpShown = "google"
        })
      } else if (
        !newValue &&
        this.$store.state.user.two_factor_type === "sms"
      ) {
        this.twoFactorSendCode("sms").then(() => {
          this.otpShown = "sms"
          this.$store.commit(
            "callSuccessSnackbar",
            "کد تایید برای شماره همراه شما ارسال شد."
          )
        })
        this.$store.commit("changeShowThirdModal", true)
        this.showModal = true
        this.target = null
        this.otpCode = null
      } else if (
        !newValue &&
        this.$store.state.user.two_factor_type === "google"
      ) {
        this.$store.commit("changeShowThirdModal", true)
        this.showModal = true
        this.target = null
        this.otpCode = null
      } else if (
        (newValue === "sms" &&
          this.$store.state.user.two_factor_type === "google") ||
        (newValue === "google" &&
          this.$store.state.user.two_factor_type === "sms")
      ) {
        this.$store.commit("changeShowThirdModal", true)
        this.showModal = true
        this.target = newValue
        this.otpCode = null
        if (newValue === "google") {
          this.twoFactorSendCode("sms").then(() => {
            this.$store.commit(
              "callSuccessSnackbar",
              "کد تایید برای شماره همراه شما ارسال شد."
            )
          })
        }
      } else {
        this.otpShown = false
      }
    },
  },
  mounted() {
    this.twoFactor = this.$store.state.user.is_two_factor
    this.twoFactorType = this.$store.state.user.two_factor_type
  },
  methods: {
    sendForm() {
      if (this.otpCode != null) {
        this.loading = false
        let data = {
          is_two_factor: this.isActive && !this.target ? true : false,
          key: this.convertNumbers2English(this.otpCode),
        }

        if (
          (this.isActive === "google" && !this.target) ||
          this.$store.state.user.two_factor_type === "google"
        ) {
          data["two_factor_type"] = "google"
        } else if (
          this.isActive === "sms" ||
          this.$store.state.user.two_factor_type === "sms"
        ) {
          data["two_factor_type"] = "sms"
        }

        this.$http
          .post(
            this.$store.state.api_base_url +
              "/personal/update/two-factor-verify",
            data
          )
          .then((response) => {
            this.loading = false
            this.otpShown = false
            this.qrValue = null
            this.qrCode = null
            this.otpCode = null

            this.$store.commit("setUserData", {
              key: "is_two_factor",
              value: this.isActive ? true : false,
            })

            let two_factor_type = {
              key: "two_factor_type",
            }

            two_factor_type.value =
              this.isActive === "google"
                ? "google"
                : this.isActive === "sms"
                ? "sms"
                : null
            this.$store.commit("setUserData", two_factor_type)

            if (!this.target) {
              this.$emit("closeModal")
            } else if (this.target === "sms") {
              this.showModal = false
              this.twoFactor = true
              this.twoFactorType = "sms"
              this.target = null
              this.twoFactorSendCode("sms").then(() => {
                this.otpShown = "sms"
                this.$store.commit(
                  "callSuccessSnackbar",
                  "کد تایید برای شماره همراه شما ارسال شد."
                )
              })
            } else if (this.target === "google") {
              this.showModal = false
              this.twoFactor = true
              this.twoFactorType = "google"
              this.target = null
              this.twoFactorSendCode("google").then((resp) => {
                this.qrCode = resp.data.qr_code
                this.qrValue = resp.data.secret_key
                this.otpShown = "google"
              })
            }

            this.$store.commit("callSuccessSnackbar", response.data.message)
          })
          .catch((error) => {
            //callsnackbar
            // this.target = null
          })
      }
    },

    setAddress(response) {
      if (response != null) {
        this.addressDetailsRequired = false
        this.city = response.city
        this.province = response.provinces
        this.address = response.address
        this.cityCode = response.provinces_code
      } else {
        this.getCities()
        this.addressDetailsRequired = true
        this.city = ""
        this.province = ""
        this.address = ""
        this.cityCode = ""
        this.city_province_id = ""
        this.city_id = ""
        this.province_id = ""
      }
    },
    twoFactorSendCode(type) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(
            this.$store.state.api_base_url +
              "/personal/update/two-factor-send-code",
            {
              two_factor_type: type,
            }
          )
          .then((resp) => {
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setPostalCode(response) {
      this.postal_code = response
    },
  },
}
</script>
