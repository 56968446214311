<template>
  <div>
    <SearchInputVandar
      v-model="search"
      input-class="w-100 mb-6"
      input-style="width:100%"
      dont-show-btn
      place-holder="جستجو باشماره شبا و شناسه واریز"
      :should-delete-search="shouldDeleteSearch"
    />

    <template v-if="!loading">
      <p class="font_2">تراکنش‌های زیر توسط شما یا سیستم رد شده‌اند.</p>
      <div class="font-size-12 van_color03--text mb-6">
        {{ total }} تراکنش رد‌شده
      </div>
      <span v-for="(item, index) in items" :key="'rejected' + index">
        <RejectedItem
          :key="index"
          :rejected-transaction="item"
          :transaction-index="index"
          style="margin-bottom: 4px !important"
        ></RejectedItem>
        <Observer
          v-if="index === items.length - 2 && items.length < total"
          :key="'observe' + item.id"
          @intersect="nextPage()"
        />
      </span>
    </template>
    <template v-if="loading || moreItem">
      <RejectedSuspiciousItemSkeleton v-for="i in 5" :key="'skeleton' + i" />
    </template>
  </div>
</template>

<script>
import Observer from "@/components/elements/observer.vue"
import SearchInputVandar from "@/components/elements/searchInputVandar.vue"
import RejectedItem from "@/components/businessHome/elements/suspicious/rejectedItem.vue"
import RejectedSuspiciousItemSkeleton from "@/components/businessHome/elements/suspicious/rejectedSuspiciousItemSkeleton.vue"
import servicesRepository from "@/services/servicesRepository"
import { mapState } from "vuex"
import { resetSuspiciousSearchModel } from "@/utils/generalMethods"

export default {
  name: "RejectedSuspicious",
  components: {
    RejectedSuspiciousItemSkeleton,
    RejectedItem,
    SearchInputVandar,
    Observer,
  },
  props: {
    tab: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      search: "",
      total: 0,
      page: 1,
      timeOut: null,
      moreItem: false,
      isCalledGettingSuspiciousRefund: false,
      lastPage: null,
      shouldDeleteSearch: false,
    }
  },
  computed: {
    ...mapState(["shouldGetSuspiciousRefund"]),
  },
  watch: {
    search(newValue) {
      this.page = 1
      this.loading = true
      this.resetItemsList()
      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }

      if (!newValue) {
        this.getSuspiciousRefund()
      } else {
        this.timeOut = setTimeout(() => {
          this.getSearchedSuspiciousRefund()
        }, 800)
      }
    },
    shouldGetSuspiciousRefund: {
      handler(val) {
        if (val) {
          this.resetItemsList()
          this.getSuspiciousRefund()
          this.isCalledGettingSuspiciousRefund = true
          this.$store.commit("callTheGetSuspiciousRefund", false)
        }
      },
      immediate: true,
    },
    tab() {
      resetSuspiciousSearchModel(this)
    },
  },
  mounted() {
    if (!this.isCalledGettingSuspiciousRefund) {
      this.getSuspiciousRefund()
    } else {
      this.isCalledGettingSuspiciousRefund = false
    }
  },
  methods: {
    nextPage() {
      this.page += 1
      if (this.page <= this.lastPage) {
        this.moreItem = true
        if (this.search) {
          this.getSearchedSuspiciousRefund()
        } else {
          this.getSuspiciousRefund()
        }
      }
    },
    getSuspiciousRefund() {
      servicesRepository(this)
        .suspiciousPayment.httpGetSuspiciousRefund(this.page)
        .then((response) => this.handleDataAfterFetching(response))
    },
    getSearchedSuspiciousRefund() {
      const queryParam = {
        page: this.page,
        searchQuery: this.search,
      }
      servicesRepository(this)
        .suspiciousPayment.httpGetSearchedSuspiciousRefund(queryParam)
        .then((response) => this.handleDataAfterFetching(response))
    },
    handleDataAfterFetching(response) {
      this.loading = false
      this.lastPage = response.data.meta.last_page
      response.data.data.map((item) => this.items.push(item))
      this.total = response.data?.meta.total
      this.moreItem = false
    },
    resetItemsList() {
      this.items = []
    },
  },
}
</script>

<style scoped lang="scss"></style>
