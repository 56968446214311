<template>
  <div>
    <general-card
      v-for="(settlement, index) in canceledSettlements"
      :key="'canceled' + settlement.id"
      custom-card-class="px-8 py-4"
      parent-class="mb-2 with-default-hover"
      margin-last-child="8px"
      @clicked="showDetails(settlement)"
    >
      <div
        class="d-flex align-start align-sm-center justify-space-between w-100"
        :class="{
          'flex-column': $vuetify.breakpoint.xsOnly,
        }"
      >
        <div class="d-flex font_2 mr-sm-4 first-col">
          {{ handleAmount(sumAmount(settlement)) }}
        </div>
        <div class="d-none d-sm-flex font-size-11 mr-sm-4 van_color03--text">
          {{ typeOrOwnerName(settlement) }}
        </div>
        <div
          class="d-flex align-center mr-sm-auto font_2"
          :class="{ 'pb-1': $vuetify.breakpoint.xsOnly }"
        >
          <div v-if="isBatch(settlement)">
            {{ settlementsCount(settlement) }} برداشت
          </div>
          <template v-else-if="settlementsCount(settlement) === 1">
            {{ settlement.settlement_items[0].IBAN }}
            <v-img
              width="16"
              max-height="16"
              max-width="16"
              class="mr-1"
              :src="
                require(`@/assets/img/banks/${parseInt(
                  settlement.settlement_items[0]?.IBAN.slice(4, 7)
                )}.svg`)
              "
            />
          </template>
        </div>
        <div
          class="d-flex d-sm-none align-center border-top pt-2 font-size-11 w-100 van_color03--text"
        >
          {{ typeOrOwnerName(settlement) }}
        </div>
      </div>

      <!-- load more -->
      <observer
        v-if="
          index === canceledSettlements.length - 1 &&
          canceledSettlements.length < total
        "
        @intersect="nextPage()"
      />
    </general-card>

    <div v-if="cancelSkeleton">
      <v-skeleton-loader
        v-for="i in 6"
        :key="i"
        class="mb-2"
        max-height="60"
        type="card"
      ></v-skeleton-loader>
    </div>

    <v-dialog
      v-if="showCancelItem && selectedSettlement.settlement_items?.length > 0"
      v-model="showCancelItem"
      content-class="left-modal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <queue-show
        :id="selectedSettlement?.id"
        :queue-settlement="selectedSettlement.settlement_items[0]"
        toolbar-label="برداشت لغو شده"
        @closeModal="closeDetails()"
      />
    </v-dialog>

    <v-dialog
      v-if="openBatchesModal"
      v-model="openBatchesModal"
      :content-class="
        $store.state.showThirdModal ? 'secondModal left-modal' : 'left-modal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <queued-batches-modal
        :selected-settlement="selectedSettlement"
        :canceled-tab="true"
        :amount-sum="handleAmount(sumAmount(selectedSettlement))"
        toolbar-name="برداشت گروهی لغو شده"
        @closeModal="closeDetails()"
      />
    </v-dialog>
  </div>
</template>

<script>
import Observer from "@/components/elements/observer.vue"
import GeneralCard from "@/components/elements/generalCard.vue"
import QueueShow from "@/components/businessHome/elements/settlementQueue/show.vue"
import { queuedSettlementHelper } from "@/mixins/queuedSettlementHelper.js"
import queuedBatchesModal from "../../modals/queuedBatchesModal.vue"

export default {
  name: "SettlementQueueCanceled",
  components: { QueueShow, GeneralCard, Observer, queuedBatchesModal },
  mixins: [queuedSettlementHelper],
  props: {
    canceledSettlements: {
      type: Array,
      default: () => {
        return []
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    cancelSkeleton: {
      type: Boolean,
      default: false,
    },
    onBatchComponent: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showCancelItem: false,
      selectedSettlement: null,
      openBatchesModal: false,
    }
  },
  watch: {
    showCancelItem(newValue) {
      if (!newValue) {
        this.closeDetails()
      }
    },
    openBatchesModal(newValue) {
      if (!newValue) {
        this.closeDetails()
      }
    },
  },
  methods: {
    closeDetails() {
      this.showCancelItem = false
      this.openBatchesModal = false

      if (this.onBatchComponent) {
        this.$store.commit("changeShowThirdModal", false)
      } else this.$store.commit("changeShowSecondModal", false)
    },
    nextPage() {
      this.$emit("nextPage")
    },
    showDetails(settlement) {
      if (this.onBatchComponent) {
        this.$store.commit("changeShowThirdModal", true)
      } else this.$store.commit("changeShowSecondModal", true)

      this.selectedSettlement = settlement

      if (this.isBatch(settlement)) {
        this.openBatchesModal = true
        return
      }

      this.showCancelItem = true
      this.$store.commit("changeShowSecondModal", true)
    },
  },
}
</script>

<style lang="scss" scoped>
.first-col {
  min-width: 140px;
}

.border-top {
  border-top: 1px dashed $van_color16;
}
</style>
