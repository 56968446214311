<template>
  <v-card class="pa-6" :class="{ 'pa-8': $vuetify.breakpoint.smAndUp }">
    <v-layout
      class="mmb-12"
      :class="{
        'flex-column': $vuetify.breakpoint.xsOnly,
        'align-center': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-flex
        v-if="title"
        class="font_9"
        :class="{ 'mb-1': $vuetify.breakpoint.xsOnly }"
        >{{ title }}</v-flex
      >
      <v-spacer></v-spacer>
      <v-flex
        v-if="growthText"
        style="max-width: 260px; width: fit-content"
        :class="{
          'mx-4': $vuetify.breakpoint.smAndUp,
          'mb-2': $vuetify.breakpoint.xsOnly,
        }"
      >
        <div class="insight-text-box-wrapper">
          <div
            class="insight-text-box font_2 d-flex align-center"
            :style="'background:' + growthColor"
          >
            <div>
              <span v-html="growthText"></span>
            </div>
          </div>
          <div
            v-if="showFilter"
            class="triangle-badge"
            :style="{
              borderBottom: `12px solid ${growthColor}`,
            }"
          ></div>
        </div>
      </v-flex>
      <v-flex v-if="showFilter" style="max-width: fit-content">
        <v-layout style="width: fit-content" class="mx-0">
          <v-flex>
            <v-select
              v-model="selectedDuration"
              :items="duration"
              item-text="name"
              solo
              flat
              height="40"
              outlined
              return-object
              style="width: 132px"
              :menu-props="{ contentClass: 'withActiveStyle' }"
              @change="emitSelectedFilteration()"
            >
            </v-select>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <slot></slot>
  </v-card>
</template>
<script>
export default {
  name: "ChartCardOneFilter",
  props: {
    title: {
      type: String,
      default: "",
    },
    showFilter: {
      default: false,
      type: Boolean,
    },
    growthText: {
      type: String,
      default: "",
    },
    growthColor: {
      type: String,
      default: "#E7EFED",
    },
    selectedDurationProp: {
      type: Object,
      default: function () {
        return { name: "روز جاری", value: "current_day" }
      },
    },
    duration: {
      type: Array,
      default: () => [
        {
          name: "روز جاری",
          value: "current_day",
        },
        {
          name: "هفته جاری",
          value: "current_week",
        },
        {
          name: "ماه جاری",
          value: "current_month",
        },
        {
          name: "فصل جاری",
          value: "current_quarter",
        },
        {
          name: "سال جاری",
          value: "current_year",
        },
        {
          name: "دیروز",
          value: "last_day",
        },
        {
          name: "هفته گذشته",
          value: "last_week",
        },
        {
          name: "ماه گذشته",
          value: "last_month",
        },
        {
          name: "فصل گذشته",
          value: "last_quarter",
        },
        {
          name: "سال گذشته",
          value: "last_year",
        },
      ],
    },
  },
  data() {
    return {
      selectedDuration: null,
    }
  },
  mounted() {
    this.setSelectedDuration()
    this.emitSelectedFilteration()
  },
  methods: {
    emitSelectedFilteration() {
      this.$emit("changeFilter", this.selectedDuration)
    },
    setSelectedDuration() {
      this.selectedDuration = this.selectedDurationProp
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/components/kerdar/utils/sharedStyles/insight-box.scss";
</style>
