<template>
  <LeftModal toolbar-name="کیف‌ پول وندار" @closeModal="$emit('close-modal')">
    <div class="box-style">
      <v-row>
        <v-col class="d-flex align-center">
          <v-img
            v-if="walletData?.icon"
            width="30"
            max-height="30"
            max-width="30"
            height="30"
            class="ml-2"
            :src="walletData.icon"
          />
          <span class="font_22">وندار </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex align-center justify-space-between background-color radius-4"
        >
          <span class="font_30"> موجودی کل </span>
          <div>
            <span class="font_22">
              {{
                balanceAmountWithThousandSeparatorForRialAmount(
                  walletData.balance
                )
              }}
            </span>
            <span class="font-11-no-000-18">
              {{ currencyName }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex align-center justify-space-between background-color radius-4 mt-2"
        >
          <span class="font_30"> قابل برداشت </span>
          <div>
            <span class="font_22">
              {{
                balanceAmountWithThousandSeparatorForTomanAmount(
                  $store.state.walletAmountDetails.deductibleAmount
                )
              }}
            </span>
            <span class="font-11-no-000-18">
              {{ currencyName }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex align-center justify-space-between background-color radius-4 mt-2"
        >
          <span class="font_30"> مبالغ تجمیعی شاپرک </span>
          <div>
            <span class="font_22">
              {{
                balanceAmountWithThousandSeparatorForTomanAmount(
                  $store.state.walletAmountDetails.paymentFacilitatorWallet
                )
              }}
            </span>
            <span class="font-11-no-000-18">
              {{ currencyName }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
    <A2aDailyCard
      v-if="isA2aActive && !noActiveBank"
      :is-there-bank-accounts="true"
      class="mt-4"
      :class="{
        'pb-6': reuseIsMobile,
        'pb-14': $vuetify.breakpoint.smAndUp,
      }"
      @noBankIsActive="noActiveBank = true"
    />
  </LeftModal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal"
import A2aDailyCard from "@/components/A2A/elements/a2aDailyCard"
import screenSize from "@/mixins/screenSize.js"
import currencyConvert from "@/mixins/currencyConvert.js"

export default {
  name: "WalletDetails",
  components: {
    LeftModal,
    A2aDailyCard,
  },
  mixins: [screenSize, currencyConvert],
  props: {
    walletData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      noActiveBank: false,
    }
  },
  computed: {
    isA2aActive() {
      return this.$store.state.business?.tools?.A2A?.status === "ACTIVE"
    },
  },
  methods: {
    balanceAmountWithThousandSeparatorForRialAmount(balanceAmount) {
      const convertedAmount =
        this.reuseConvertCurrencyForRialAmount(balanceAmount)
      return this.$options.filters.thousandSeprator(convertedAmount)
    },
    balanceAmountWithThousandSeparatorForTomanAmount(balanceAmount) {
      const convertedAmount =
        this.reuseConvertCurrencyForTomanAmount(balanceAmount)
      return this.$options.filters.thousandSeprator(convertedAmount)
    },
  },
}
</script>

<style lang="scss" scoped>
.box-style {
  border: 1px solid #e5e5e5;
  padding: 24px;
  background: #fff;
  border-radius: 4px;
}

.radius-4 {
  border-radius: 4px;
}

.background-color {
  background: #f5f5f5;
}
</style>
