<template>
  <v-layout>
    <v-flex>
      <v-layout column class="rows">
        <v-flex v-if="$store.state.customer.selectedCustomer.created_at">
          <v-layout>
            <v-flex class="label"> تاریخ ثبت </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.created_at }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="
            $store.state.customer.selectedCustomer.customer.type ==
              'INDIVIDUAL' &&
            $store.state.customer.selectedCustomer.customer.name
          "
        >
          <v-layout>
            <v-flex class="label"> نام و نام خانوادگی </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="
            $store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
            $store.state.customer.selectedCustomer.customer.legal_name
          "
        >
          <v-layout>
            <v-flex class="label"> نام حقوقی </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.legal_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="
            $store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
            $store.state.customer.selectedCustomer.customer.agent_name
          "
        >
          <v-layout>
            <v-flex class="label"> نام نماینده </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.agent_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="
            $store.state.customer.selectedCustomer.customer.type ==
              'INDIVIDUAL' &&
            $store.state.customer.selectedCustomer.customer.mobile
          "
        >
          <v-layout>
            <v-flex class="label"> تلفن همراه </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.mobile }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="
            $store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
            $store.state.customer.selectedCustomer.customer.agent_mobile
          "
        >
          <v-layout>
            <v-flex class="label"> تلفن همراه نماینده </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.agent_mobile }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="$store.state.customer.selectedCustomer.customer.telephone"
        >
          <v-layout>
            <v-flex class="label"> تلفن ثابت </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.telephone }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="
            $store.state.customer.selectedCustomer.customer.type ==
              'INDIVIDUAL' &&
            $store.state.customer.selectedCustomer.customer
              .individual_national_code
          "
        >
          <v-layout>
            <v-flex class="label"> کدملی </v-flex>
            <v-flex>
              {{
                $store.state.customer.selectedCustomer.customer
                  .individual_national_code
              }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="
            $store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
            $store.state.customer.selectedCustomer.customer.legal_national_code
          "
        >
          <v-layout>
            <v-flex class="label"> شناسه ملی </v-flex>
            <v-flex>
              {{
                $store.state.customer.selectedCustomer.customer
                  .legal_national_code
              }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="$store.state.customer.selectedCustomer.customer.city">
          <v-layout>
            <v-flex class="label"> استان/شهر </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.province }} -
              {{ $store.state.customer.selectedCustomer.customer.city }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="$store.state.customer.selectedCustomer.customer.address">
          <v-layout>
            <v-flex class="label"> آدرس </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.address }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="$store.state.customer.selectedCustomer.customer.postal_code"
        >
          <v-layout>
            <v-flex class="label"> کد پستی </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.postal_code }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="
            $store.state.customer.selectedCustomer.customer.type == 'LEGAL' &&
            $store.state.customer.selectedCustomer.customer.mcc_code
          "
        >
          <v-layout>
            <v-flex class="label"> حوزه فعالیت </v-flex>
            <v-flex>
              {{ $store.state.customer.selectedCustomer.customer.mcc_name }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "CustomerInformation",
}
</script>

<style></style>
