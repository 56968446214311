<template>
  <!-- responsive table -->
  <v-layout
    v-if="$vuetify.breakpoint.smAndDown"
    align-space-between
    justify-space-between
    fill-height
    :class="rowClass(trans.status_code)"
    @click="showDetail(trans)"
  >
    <v-flex class="right-number font_2">{{ trans.rowNumber }}</v-flex>
    <v-flex>
      <v-layout column>
        <v-flex class="mmb-5">
          <v-layout>
            <!-- right-top part Start-->
            <v-flex>
              <div style="display: flex; align-items: center">
                <span class="font_2">
                  {{ trans._id }}
                </span>
              </div>
            </v-flex>
            <!-- right-top part End-->
            <!-- left-top part Start-->
            <v-flex style="text-align: left">
              <span class="text-right font_2">{{
                dateTime(trans.created_at)
              }}</span>
            </v-flex>
            <!-- left-top part End-->
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout :column="$vuetify.breakpoint.xsOnly" class="font_2">
            <!-- first description part Start-->
            <v-flex style="max-width: 250px">
              <div>{{ trans.channel_name_fa }}</div>
              <div>{{ trans.method }}</div>
            </v-flex>
            <!-- first description part End -->
            <v-divider
              vertical
              :column="$vuetify.breakpoint.xsOnly"
              class="mmy-0 mmx-5"
            ></v-divider>
            <!-- second description part Start-->
            <v-flex style="max-width: 220px">
              <!-- succeed 1 AND failed -1 -->
              <div>
                <div class="tdes">
                  <span>{{ status }}</span>
                </div>
              </div>
            </v-flex>
            <!-- second description part End -->
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>

  <!-- table -->
  <!--  -->
  <tr v-else :class="rowClass(trans.status_code)" @click="showDetail(trans)">
    <!-- 1 -->
    <td class="text-center">
      <div class="d-flex justify-center">{{ trans.rowNumber }}</div>
    </td>

    <!-- 2 -->
    <td class="text-right padding-right-0 inner">
      <div style="display: flex; align-items: center">
        <span>
          {{ trans._id }}
        </span>
      </div>
    </td>

    <!-- 3 -->
    <td class="text-right inner">{{ dateTime(trans.created_at) }}</td>

    <!-- 4 -->
    <!-- succeed 1 AND failed -1 -->
    <td class="text-right tdes inner">
      <span class="tdesrow">
        <span>{{ trans.channel_name_fa }}</span>
      </span>
    </td>

    <!-- 5 -->
    <td class="text-right inner">{{ trans.method }}</td>

    <!-- 6 -->
    <td class="text-right">{{ status }}</td>

    <!-- hidden part -->
    <div
      class="hiddentd"
      @mouseover="mouseIsOnBtn = true"
      @mouseleave="mouseIsOnBtn = false"
    >
      <v-btn
        v-if="trans.transaction_id != null"
        ref="btn"
        text
        dark
        :class="'btn_small_normal tblCpy' + trans.transaction_id"
        @click="shareLink()"
      >
        <v-icon color="#fff" size="15" class="ppl-2">content_copy</v-icon>
        کپی شناسه یکتا</v-btn
      >
      <v-tooltip v-model="tooltip_show" top>
        <template #activator="{ on }">
          <v-btn
            style="
              position: absolute;
              opacity: 0 !important;
              width: 1px;
              margin: 0px;
              padding: 0px;
              z-index: -1;
              left: 78px;
            "
            v-on="on"
          ></v-btn>
        </template>
        <span>کپی شد</span>
      </v-tooltip>
      <input
        :id="'hidden-input' + trans.transaction_id"
        v-model="trans.transaction_id"
        class="hidden-input"
      />
    </div>
  </tr>
</template>

<script>
export default {
  name: "TableRow",
  components: {
    // cancelSettlement,
  },
  props: ["transaction"],
  data() {
    return {
      tooltip_show: false,
      mouseIsOnBtn: false,
    }
  },
  computed: {
    trans() {
      return this.transaction
    },
    status() {
      switch (this.transaction.status_code) {
        case 200:
          return "۲۰۰ | موفق"
        case 301:
          return "۳۰۱ | موفق"
        case 302:
          return "۳۰۲ | موفق"
        case 422:
          return "۴۲۲ | ورودی نادرست"
        case 401:
          return "۴۰۱ | توکن نامعتبر"
        case 400:
          return "۴۰۰ | درخواست نادرست"
        case 403:
          return "۴۰۳ | دسترسی ندارید"
        case 404:
          return "۴۰۴ | آدرس نادرست"
        case 405:
          return "۴۰۵ | متد نامعتبر"
        case 429:
          return "۴۲۹ | ترافیک درخواست"
        case 500:
          return "۵۰۰ | خطای سرور"
        case 503:
          return "۵۰۳ | اختلال موقت"
        default:
          if (this.transaction.status_code < 400) {
            return `${this.transaction.status_code} | موفق`
          } else if (
            this.transaction.status_code >= 400 &&
            this.transaction.status_code < 500
          ) {
            return `${this.transaction.status_code} | درخواست نادرست`
          } else if (
            this.transaction.status_code >= 500 &&
            this.transaction.status_code < 600
          ) {
            return `${this.transaction.status_code} | خطای سرور`
          }
          return "-"
      }
    },
  },
  methods: {
    dateTime(date) {
      const moment = require("moment-jalaali")
      return this.jalaliDate(date, "jYYYY/jM/jD - HH:mm:ss")
    },
    shareLink() {
      /* Get the text field */
      let copyText = document.querySelector(
        "#hidden-input" + this.trans.transaction_id
      )

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)
        //Alert the copied text
        this.tooltip_show = true
        setTimeout(() => {
          this.tooltip_show = false
        }, 2000)
      }
    },
    showDetail(item) {
      if (!this.mouseIsOnBtn) this.$emit("showDetail", item)
    },
    rowClass(status_code) {
      if (status_code >= 200 && status_code < 400) {
        return "succeed big"
      } else {
        return "failed big"
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.inner {
  padding-left: 55px !important;
}
</style>
