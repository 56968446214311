<template>
  <v-dialog
    :value="true"
    no-click-animation
    width="360px"
    transition="slide-x-transition"
    :persistent="true"
  >
    <v-card height="100%" outlined class="py-8">
      <v-layout align-center column justify-center>
        <v-flex class="mb-6">
          <v-img :src="require('@/assets/img/newIcon/tools/success.svg')" />
        </v-flex>
        <h3 class="font_9">درخواست فعال‌سازی ثبت شد.</h3>
        <p class="font_30 mb-6 content">
          تیم فروش برای دریافت مدارک مورد نیاز روش «پرداخت از حساب» با شما تماس
          می‌گیرد.
        </p>
        <v-flex>
          <v-btn
            :loading="loading"
            class="btn_small_normal ml-0"
            dark
            text
            @click="$router.push({ name: 'mpg' })"
            >صفحه پرداخت یکپارچه
          </v-btn>
          <v-btn
            class="btn_small_normal"
            depressed
            outlined
            text
            @click="$emit('close')"
          >
            بستن
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CongfirmMPG",
}
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  padding: 0 32px;
}
</style>
