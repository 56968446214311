<template>
  <v-flex d-flex xs6 class="miniBox mr-0 mb-0 rightBold">
    <v-layout align-center justify-center column>
      <v-flex xs1>
        <div class="font_19">
          <span dir="ltr" class="pl-1">{{ computeAmount | currency }}</span>
          <span class="font_2">{{ currencyName }}</span>
        </div>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    wage: {
      type: Number,
      default: 0,
    },
    shaparakWage: {
      type: Number,
      default: 0,
    },
    status: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    computeAmount() {
      return this.status === 9
        ? this.amount
        : this.amount - this.wage - this.shaparakWage
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 599px) {
  .miniBox {
    margin-bottom: 5px !important;
  }
}
</style>
