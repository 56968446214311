export function resetSuspiciousSearchModel(context) {
  if (context.search) {
    context.search = null
    context.shouldDeleteSearch = true
  } else {
    context.shouldDeleteSearch = false
  }
}

export function removeWhiteSpaces(text) {
  return text?.replace(/^\s+|\s+$|\s+(?=\s)/g, "")
}
