<template>
  <!-- empty responsive table -->
  <tbody v-if="$vuetify.breakpoint.smAndDown">
    <v-layout
      v-for="i in 10"
      :key="i"
      align-space-between
      justify-space-between
      fill-height
      class="big"
    >
      <v-flex class="right-number font_2">
        <span style="width: 20%" class="default_gray"></span>
      </v-flex>
      <v-flex>
        <v-layout column>
          <v-flex class="mmb-5">
            <v-layout>
              <!-- right-top part Start-->
              <v-flex>
                <div style="display: flex; align-items: center">
                  <span style="max-width: 60%" class="default_gray"></span>
                </div>
              </v-flex>
              <!-- right-top part End-->
              <!-- left-top part Start-->
              <v-flex style="text-align: left">
                <span
                  style="float: left; max-width: 30%"
                  class="default_gray"
                ></span>
              </v-flex>
              <!-- left-top part End-->
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout :column="$vuetify.breakpoint.xsOnly" class="font_2">
              <!-- first description part Start-->
              <v-flex style="max-width: 250px">
                <div>
                  <span class="default_gray"></span>
                </div>
                <div>
                  <span style="max-width: 60%" class="default_gray"></span>
                </div>
              </v-flex>
              <!-- first description part End -->
              <v-divider
                vertical
                :column="$vuetify.breakpoint.xsOnly"
                class="mmy-0 mmx-5"
              ></v-divider>
              <!-- second description part Start-->
              <v-flex>
                <!-- succeed 1 AND failed -1 -->
                <div class="text-right">
                  <span style="max-width: 50%" class="default_gray"></span>
                </div>
              </v-flex>
              <!-- second description part End -->
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </tbody>

  <!-- empty table -->
  <tbody v-else>
    <tr v-for="(n, index) in 10" :key="index" class="big">
      <!-- 1 -->
      <td class="text-center" style="width: 70px">
        <span style="width: 36px; height: 36px" class="default_gray" />
      </td>

      <!-- 2 -->
      <td class="text-right padding-right-0">
        <div style="display: flex; align-items: center">
          <span style="width: 80%" class="default_gray" />
        </div>
      </td>

      <!-- 3 -->
      <td class="text-right padding-right-0">
        <span style="width: 50%" class="default_gray" />
      </td>

      <!-- 4 -->
      <td class="text-right padding-right-0">
        <span style="width: 50%" class="default_gray" />
      </td>

      <!-- 5 -->
      <td class="text-right padding-right-0 big">
        <span style="width: 50%" class="default_gray" />
      </td>

      <!-- 6 -->
      <td class="text-right padding-right-0 big">
        <span class="default_gray" />
      </td>

      <!-- 7 -->
      <td class="text-right padding-right-0 big">
        <span class="default_gray" />
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: "MiandoTableRowSkeleton",
}
</script>
