<template>
  <ChartCardWrapper
    :title="kerdarLang.general.depositAmountTitle"
    :show-filter="true"
    :selected-custom-duration="defaultSelectedDuration"
    @changeFilter="changeIncomeChartFilter($event)"
  >
    <!-- :growth="incomeGrowth" -->
    <LineEChart
      v-if="incomeStatus"
      :key="updateKeyCounter"
      chart-id="payment-form-deposit"
      :chart-name="kerdarLang.general.deposit"
      :x-axis-data="xValueIncome"
      :y-axis-data="calculateYaxisValueIncome()"
      :date-time-list="timeIncome"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </ChartCardWrapper>
</template>

<script>
import ChartCardWrapper from "@/components/kerdar/components/ChartCardWrapper"
import LineEChart from "@/components/kerdar/components/LineEChart"
import {
  generateWeekDaysV2,
  generateMonthsV2,
  generateHoursV2,
  changeHorizontalWeeklyFirstDayV2,
} from "@/components/kerdar/utils/generalMethods.js"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "PaymentFormDepositEChart",
  components: {
    ChartCardWrapper,
    LineEChart,
  },
  props: {
    duration: {
      type: Object,
      default: () => {},
    },
    kerdarLang: {
      type: Object,
      default: () => {},
    },
    defaultSelectedDuration: {
      type: Object,
      default: () => {
        return {
          name: "ماه جاری",
          value: "current_month",
        }
      },
    },
  },
  data() {
    return {
      incomeData: [],
      incomeStatus: false,
      incomeFilter: null,
      updateKeyCounter: 0,
    }
  },
  computed: {
    incomeGrowth() {
      if (
        this.calculateYaxisValueIncome() &&
        this.calculateYaxisValueIncome().length
      ) {
        if (
          this.calculateYaxisValueIncome()[0] ==
          this.calculateYaxisValueIncome()[
            this.calculateYaxisValueIncome().length - 1
          ]
        )
          return 0
        return (
          Math.round(
            ((this.calculateYaxisValueIncome()[
              this.calculateYaxisValueIncome().length - 1
            ] -
              this.calculateYaxisValueIncome()[0]) /
              this.calculateYaxisValueIncome()[0] || 1) *
              100 *
              100
          ) / 100
        )
      }
      return 0
    },
    xValueIncome() {
      if (
        this.incomeFilter &&
        ["monthly", "hourly"].includes(this.incomeFilter.timePeriod)
      ) {
        let xValue = []
        switch (this.incomeFilter.timePeriod) {
          case "monthly":
            generateMonthsV2(xValue, this.incomeData)
            break
          case "hourly":
            for (let element of this.incomeData) {
              xValue.push(jmoment(element.date_time).format("HH"))
            }
            break
        }
        return xValue
      }

      return this.incomeData.map((item) => item.dateWithoutYear)
    },
    timeIncome() {
      if (
        this.incomeFilter &&
        ["weekly", "monthly", "hourly"].includes(this.incomeFilter.timePeriod)
      ) {
        let times = []
        switch (this.incomeFilter.timePeriod) {
          case "weekly":
            generateWeekDaysV2(times, this.incomeData)
            break
          case "monthly":
            generateMonthsV2(times, this.incomeData)
            break
          case "hourly":
            // for (let element of this.incomeData) {
            //   times.push(
            //     `${this.kerdarLang.general.hour} ` +
            //       jmoment(element[0]).format("H")
            //   )
            // }
            generateHoursV2(times, this.incomeData)
            break
        }

        return times
      }
      return this.incomeData.map((item) => item.dateWithYear)
    },
  },
  watch: {
    currencyName() {
      this.updateKeyCounter++
    },
  },
  methods: {
    clearIncomeData() {
      this.incomeStatus = false
      this.incomeData = []
    },
    changeIncomeChartFilter(item) {
      this.incomeFilter = item
      this.clearIncomeData()
      this.getIncomeData(item)
    },
    async getIncomeData(item) {
      const response = await kerdarServices(
        this.$http,
        this
      ).paymentForm.httpPaymentFormDeposit(item)

      this.updateKeyCounter++

      const {
        data: { data },
      } = response

      changeHorizontalWeeklyFirstDayV2(data)
      this.incomeData = data
      this.incomeStatus = true
    },
    calculateYaxisValueIncome() {
      let amounts = this.incomeData.map((item) => {
        return this.$store.state.user.currency == "RIAL"
          ? item.value
          : item.value / 10
      })

      return amounts
    },
  },
}
</script>

<style scoped></style>
