<template>
  <v-flex class="copy" style="max-width: fit-content; position: relative">
    <v-btn
      ref="btn"
      text
      style="height: 32px !important; min-width: 32px"
      depressed
      outlined
      class="btn_small_normal tblCpy-receipt_url px-2 ma-0 copy-btn"
      @click="copyLink(inputId)"
    >
      <v-icon color="van_color03" :class="label ? 'ml-1' : null" size="14">
        content_copy
      </v-icon>
      <span v-if="label" class="font_4">{{ label }}</span>
      <span v-show="showTooltip" class="font-11-no-white-18 tooltip">
        کپی شد
      </span>
    </v-btn>
    <textarea :id="inputId" v-model="value" class="hidden-input" />
  </v-flex>
</template>

<script>
export default {
  props: {
    inputId: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    openOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  methods: {
    copyLink(inputId) {
      let copyText = document.querySelector("#" + inputId)
      if (copyText) {
        navigator.clipboard.writeText(copyText.value)
        this.showTooltip = true
        setTimeout(() => {
          this.showTooltip = false
        }, 2000)
      }
    },
  },
}
</script>
<style>
.tooltip {
  position: absolute;
  bottom: 24px;
  background: #333;
  padding: 6px 8px;
  border-radius: 4px;
}
</style>
