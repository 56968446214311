<template>
  <div style="height: 100%; width: 100%">
    <div class="img-container-crop">
      <img id="image" ref="image" width="400px" :src="srcImage" crossorigin />
    </div>
    <v-btn text dark class="btn_small_normal" @click="uploadCropPhoto()">
      ذخیره
    </v-btn>
    <v-btn text dark class="btn_small_normal error" @click="eraseCropPhoto()">
      حذف
    </v-btn>
  </div>
</template>

<script>
import Compressor from "compressorjs"
import Cropper from "cropperjs"
import debounce from "lodash/debounce"
import "cropperjs/dist/cropper.css"
export default {
  name: "CropperDialog",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Compressor,
    // eslint-disable-next-line vue/no-unused-components
    Cropper,
  },
  props: ["result", "aspectRatio"],
  data: function () {
    return {
      //cropDialog:false,
      srcImage: "",
      imageElement: null,
      cropper: {},
      canvasvar: null,
      debouncedUpdatePreview: debounce(this.updatePreview, 257),
    }
  },
  watch: {
    result: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          this.srcImage = window.URL.createObjectURL(newValue)
          //this.cropDialog = true
          this.imageElement = this.$refs.image
          this.$nextTick(this.setupCropperInstance)
        }
      },
    },
  },
  methods: {
    eraseCropPhoto() {
      //this.cropDialog = false
      this.imageElement = null
      this.srcImage = ""
      this.canvasvar = null
      this.cropper.destroy()
      this.$emit("closeCrop")
    },
    setupCropperInstance() {
      this.cropper = new Cropper(this.$refs.image, {
        ...(this.aspectRatio != "custom" && { aspectRatio: this.aspectRatio }),
        crop: this.debouncedUpdatePreview,
      })
    },
    updatePreview(event) {
      this.canvasvar = this.cropper.getCroppedCanvas()
    },
    uploadCropPhoto() {
      //close dialog
      //this.cropDialog = false

      //start uploading to server
      this.canvasvar.toBlob((blob) => {
        const formData = new FormData()
        formData.append("image", blob,'image.jpeg')
        this.$emit("uploadFormData", formData)
      },'image/jpeg')
    },
  },
}
</script>

<style lang="scss"></style>
