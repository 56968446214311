import { render, staticRenderFns } from "./BasicInfo.vue?vue&type=template&id=4f1bab88&scoped=true&"
import script from "./BasicInfo.vue?vue&type=script&lang=js&"
export * from "./BasicInfo.vue?vue&type=script&lang=js&"
import style0 from "./BasicInfo.vue?vue&type=style&index=0&id=4f1bab88&prod&lang=scss&scoped=true&"
import style1 from "./BasicInfo.vue?vue&type=style&index=1&id=4f1bab88&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f1bab88",
  null
  
)

export default component.exports