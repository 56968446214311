<template>
  <!-- empty responsive table -->
  <v-layout
    v-if="$vuetify.breakpoint.smAndDown"
    align-space-between
    justify-space-between
    fill-height
    class="big"
  >
    <v-flex class="right-number font_2">
      <span style="width: 20%" class="default_gray" />
    </v-flex>
    <v-flex>
      <v-layout column>
        <v-flex class="mb-5">
          <v-layout>
            <!-- right-top part Start-->
            <v-flex>
              <div style="display: flex; align-items: center">
                <img
                  width="16"
                  height="14"
                  style="
                    margin: 0 10px 0 25px;
                    filter: grayscale(100%);
                    opacity: 0.3;
                  "
                  :src="require('@/assets/img/Ascending.svg')"
                />
                <span style="max-width: 60%" class="default_gray" />
              </div>
            </v-flex>
            <!-- right-top part End-->
            <!-- left-top part Start-->
            <v-flex style="text-align: left">
              <span style="float: left; max-width: 30%" class="default_gray" />
            </v-flex>
            <!-- left-top part End-->
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout :column="$vuetify.breakpoint.xsOnly" class="font_2">
            <!-- first description part Start-->
            <v-flex style="margin-bottom: 15px; max-width: 250px">
              <div>
                <span class="default_gray" />
              </div>
              <div>
                <span style="max-width: 60%" class="default_gray" />
              </div>
            </v-flex>
            <!-- first description part End -->
            <v-divider
              vertical
              :column="$vuetify.breakpoint.xsOnly"
              class="my-0 mx-5"
            />
            <!-- second description part Start-->
            <v-flex>
              <!-- succeed 1 AND failed -1 -->
              <div class="text-right">
                <span style="max-width: 50%" class="default_gray" />
                <div>
                  <span style="max-width: 30%" class="default_gray" />
                </div>
              </div>
            </v-flex>
            <!-- second description part End -->
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
  <!-- empty table -->
  <tr v-else class="big">
    <!-- 1 -->
    <td class="text-center">
      <span style="width: 20%" class="default_gray" />
    </td>

    <!-- 2 -->
    <td class="text-right padding-right-0">
      <div style="display: flex; align-items: center">
        <img
          width="16"
          height="14"
          style="margin: 0 -10px 0 10px; filter: grayscale(100%); opacity: 0.3"
          :src="require('@/assets/img/Ascending.svg')"
        />
        <span class="default_gray" />
      </div>
    </td>

    <!-- 3 -->
    <td class="text-right padding-right-0">
      <span class="default_gray" />
      <div>
        <span style="width: 40%" class="default_gray" />
      </div>
    </td>

    <!-- 4 -->
    <td class="text-right padding-right-0">
      <span class="default_gray" />
    </td>

    <!-- 5 -->
    <td class="text-right padding-right-0 big">
      <span class="default_gray" />
      <div>
        <span style="width: 50%" class="default_gray" />
      </div>
    </td>

    <!-- 6 -->
    <td class="text-right padding-right-0 big">
      <span class="default_gray" />
    </td>
  </tr>
</template>

<script>
export default {
  name: "TableRowSkeleton",
}
</script>
