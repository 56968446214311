import { store } from "@/store"

const getCookie = function (cname) {
  const name = cname + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

const eraseCookie = function (cname) {
  let d = new Date()
  d.setTime(d.getTime() - 1 * 24 * 60 * 1000)
  let expires = "expires=" + d.toUTCString()
  const baseDomain = "." + store.state.main_domain
  document.cookie =
    cname + "=;domain=" + baseDomain + "; expires=" + expires + "; path=/"

  const dashDomain = store.state.dash_name + "." + store.state.main_domain
  document.cookie =
    cname + "=;domain=" + dashDomain + "; expires=" + expires + "; path=/"
}

const setCookie = function (obj) {
  const token = obj.token
  const dashDomain = store.state.dash_name + "." + store.state.main_domain
  // setting up cookie expire date after expires in
  let d = new Date()
  d.setTime(d.getTime() + obj.expireTime * 1000)
  let expireAfter = d.toUTCString()
  //now setup cookie
  document.cookie =
    obj.name +
    "=" +
    token +
    "; domain=" +
    dashDomain +
    "; expires=" +
    expireAfter +
    "; path=/"
}

export { setCookie, getCookie, eraseCookie }
