<template>
  <GeneralCard>
    <div class="card-wrapper">
      <section class="d-flex align-center">
        <v-img
          width="40"
          max-width="40"
          aspect-ratio="1"
          :src="require('@/assets/img/newIcon/cash-in-manual.svg')"
        />
        <span class="font-12-bo-333-18"> واریز بانکی به‌صورت دستی </span>
      </section>

      <v-btn
        :outlined="!isPrimary"
        :dark="isPrimary"
        :text="isPrimary"
        class="btn_small_normal"
        style="width: 95px"
        @click="registerRequest"
      >
        ثبت درخواست
      </v-btn>
    </div>
    <v-dialog
      v-if="isOpenManualCashInModal"
      v-model="isOpenManualCashInModal"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <AddCashIn
        v-if="isOpenManualCashInModal"
        @closeModal="closeManualCashInModal"
      />
    </v-dialog>
  </GeneralCard>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard"
import AddCashIn from "@/components/report/modals/addCashIn/addCashIn"

export default {
  name: "ManualCashInCard",
  components: {
    GeneralCard,
    AddCashIn,
  },
  props: {
    isPrimary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenManualCashInModal: false,
    }
  },
  watch: {
    isOpenManualCashInModal(val) {
      if (!val) {
        this.$store.commit("changeShowSecondModal", false)
      }
    },
  },
  methods: {
    registerRequest() {
      this.isOpenManualCashInModal = true
      this.$store.commit("changeShowSecondModal", true)
    },
    closeManualCashInModal(receiptObject) {
      this.isOpenManualCashInModal = false
      this.$store.commit("changeShowSecondModal", false)
      this.$emit("send-out-receipt", receiptObject)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
