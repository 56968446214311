<template>
  <div>
    <v-card>
      <v-toolbar flat height="60" class="fixed_toolbar">
        <v-btn icon @click.native="$emit('close')">
          <v-icon color="van_color03" size="21"> close </v-icon>
        </v-btn>
        <v-toolbar-title class="pr-0"> پرداخت یکپارچه </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items class="align-center">
          <v-btn
            text
            dark
            class="btn_small_normal"
            :disabled="!types.debit && !types.credit"
            @click="submit()"
          >
            ثبت و ادامه
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-layout column class="twoFlex">
        <v-flex class="group-box mb-3 mx-8">
          <div
            :class="`d-flex justify-space-between align-center ${
              debitMore ? 'activeMore' : ''
            }`"
          >
            <div class="d-flex flex-column">
              <v-checkbox
                v-model="types.debit"
                color="van_green"
                class="paymentCheckbox"
                label="پرداخت از حساب"
              />
              <p v-show="!debitMore" class="max-content mb-0 font_2">
                در این روش، مبلغ خدمات شما به صورت خودکار از حساب مشتریا‌ن‌تان،
                پرداخت می‌شود.
              </p>
            </div>
            <v-hover>
              <template #default="{ hover }">
                <div class="showMore" @click="debitMore = !debitMore">
                  <span>
                    {{ !debitMore ? "اطلاعات بیشتر" : "اطلاعات کمتر" }}
                  </span>
                  <v-icon size="32" :color="hover ? 'primary' : '#666'">
                    {{ !debitMore ? "chevron_left" : "expand_more" }}
                  </v-icon>
                </div>
              </template>
            </v-hover>
          </div>

          <div v-show="debitMore">
            <p class="mb-0 font_2">
              در این روش، مبلغ خدمات شما به صورت خودکار از حساب مشتریا‌ن‌تان،
              پرداخت می‌شود. کافی است اجازه‌ی اتصال به حساب بانکی، یک بار
              به‌صورت آنلاین از مشتری دریافت شود تا پس از آن، مبلغ توافق‌شده در
              زمان‌های مشخص از حساب بانکی او کسر و به کیف‌پول کسب‌وکار شما در
              وندار اضافه شود.
            </p>
            <p class="font_2 mb-6">
              وجوه واریز شده به کیف‌پولتان در لحظه قابل برداشت است.
            </p>
          </div>
          <div
            v-show="debitMore"
            class="percentageBox mb-10 flex-column flex-sm-row"
          >
            <span class="font_28">کارمزد پرداخت از حساب</span>
            <!-- this empty span should be here -->
            <span></span>
            <div class="percentageCirce">
              <span class="font_34">۲ ٪</span>
            </div>
            <span class="font_31">از کف ۱,۰۰۰ تا سقف ۴,۰۰۰ تومان</span>
          </div>
          <p v-show="debitMore" class="font_2 mb-10">
            از طریق این لینک می‌توانید شرایط بانک‌ها را مشاهده کنید.
          </p>

          <v-row v-show="debitMore">
            <v-col
              v-for="(method, ind) in methods.filter(
                (method) => method.type === 'debit'
              )"
              :key="ind"
              cols="3"
              sm="2"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <div class="banksBox" v-on="on">
                    <div class="bankImage mb-2">
                      <v-img
                        :src="method.logo"
                        width="100%"
                        height="100%"
                        :alt="method.slug"
                      />
                    </div>
                  </div>
                </template>
                <span>{{ method.name }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
      <v-layout column class="twoFlex">
        <v-flex class="group-box mb-3 mx-8">
          <div
            :class="`d-flex justify-space-between align-center ${
              creditMore ? 'activeMore' : ''
            }`"
          >
            <div class="d-flex flex-column">
              <v-checkbox
                v-model="types.credit"
                color="van_green"
                class="paymentCheckbox"
                label="پرداخت اعتباری"
              />
              <p v-show="!creditMore" class="max-content mb-0 font_2">
                در این روش پرداخت کننده با استفاده از اعتبار موجود در سرویس
                اعتباری موردنظرش،‌ عملیات پرداخت را انجام می‌دهد.
              </p>
            </div>
            <v-hover>
              <template #default="{ hover }">
                <div class="showMore" @click="creditMore = !creditMore">
                  <span>
                    {{ !creditMore ? "اطلاعات بیشتر" : "اطلاعات کمتر" }}
                  </span>
                  <v-icon size="32" :color="hover ? 'primary' : '#666'">
                    {{ !creditMore ? "chevron_left" : "expand_more" }}
                  </v-icon>
                </div>
              </template>
            </v-hover>
          </div>

          <div v-show="creditMore">
            <p class="mb-6 font_2">
              متنی که برای توضیح و تعریف پرداخت اعتباری می‌خواهیم اینجا بنویسیم.
              این متن می‌تواند درباره فرآیندی باشد که در مورد پرداخت اعتباری
              باید طی شود یا امکاناتی
            </p>
          </div>

          <v-row v-show="creditMore" class="px-3">
            <span class="font_37 mb-4">سرویس‌های ارائه دهنده</span>
            <div
              v-for="(method, ind) in methods.filter(
                (method) => method.type === 'credit'
              )"
              :key="ind"
              class="banksBox full-width"
            >
              <div class="creditRow mb-2">
                <div class="d-flex align-center">
                  <v-img
                    :src="method.logo"
                    width="32"
                    height="32"
                    :alt="method.slug"
                  />
                  <span class="methodName">{{ method.name }}</span>
                </div>
                <div
                  class="d-flex flex-grow-1 align-center justify-space-around"
                >
                  <span class="detail">میزان کارمزد : ۲۰ درصد</span>
                  <span class="detail">زمان تسویه : ۲۰ روز کاری</span>
                </div>
              </div>
            </div>
          </v-row>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SelectMpgMethod",
  data() {
    return {
      nextStep: false,
      methods: [],
      types: {
        debit: false,
        credit: false,
      },
      debitMore: false,
      creditMore: false,
    }
  },
  created() {
    this.getMethods()
  },
  methods: {
    getMethods() {
      this.$http
        .get(`${this.$store.state.mpg_base_url}/payment-methods`)
        .then((res) => {
          this.methods = res.data.data.payment_methods
        })
    },
    submit() {
      let types = []
      for (const [key, value] of Object.entries(this.types)) {
        if (value) {
          types.push(key)
        }
        this.$store.commit("updateMpgTypes", types)
      }
      this.$emit("submit")
    },
  },
}
</script>

<style lang="scss" scoped>
.paymentCheckbox {
  margin-right: 0 !important;
  text-align: right;
  width: max-content;
}
.paymentCheckbox::v-deep label.v-label.theme--light {
  font-weight: 700;
  font-size: 14px;
  margin-right: 5px;
  line-height: 22px;
}
.showMore {
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    visibility: hidden;
    color: #4c9f87;
    font-size: 11px;
  }
  v-icon {
    color: green !important;
  }
  &:hover {
    span {
      visibility: visible;
    }
  }
}
.group-box {
  padding: 16px 24px !important;
  text-align-last: right !important;
  border: 1px solid #f5f5f5;
  &:hover {
    filter: drop-shadow(0px -1px 10px rgba(0, 0, 0, 0.05));
  }
  .max-content {
    max-width: 342px;
  }
  .activeMore {
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    margin-bottom: 16px;
  }
}
.percentageBox {
  display: flex;
  justify-content: space-around;
  background: $van_color11;
  border-radius: 4px;
  position: relative;
  padding: 14px 0;
  .percentageCirce {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 60px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 100%;
    top: -5px;
  }
}
.banksBox {
  text-align-last: center !important;
  margin-bottom: 8px;
  .bankImage {
    margin: auto;
    width: 32px;
    height: 32px;
  }
  .creditRow {
    color: #666;
    display: flex;
    align-items: center;
    .methodName {
      font-size: 12px;
      margin-right: 8px;
    }
    .detail {
      font-size: 11px;
    }
  }
}
@media screen and (max-width: 600px) {
  .noShadow {
    box-shadow: unset !important;
    -webkit-box-shadow: unset !important;
  }
  .group-box {
    width: unset !important;
  }
  .percentageBox {
    padding: 8px 20px;
    .percentageCirce {
      top: unset;
      left: 23px;
    }
  }
}
</style>
