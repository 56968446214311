<template>
  <div class="search-input backWhite" :class="inputClass">
    <v-text-field
      v-model.trim="search"
      solo
      flat
      outlined
      hide-details
      :style="inputStyle"
      :placeholder="placeHolder"
      :prepend-inner-icon="search ? 'close' : 'search'"
      @click:prepend-inner="deleteSearch()"
    />
    <v-btn
      v-if="!dontShowBtn"
      text
      class="btn-search-inner"
      :disabled="disableBtn"
      @click="$emit('search')"
    >
      <v-icon size="20" class="white--text"> chevron_left </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "VanSearchInput",
  model: {
    prop: "input",
    event: "changeInput",
  },
  props: {
    placeHolder: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
    inputStyle: {
      type: String,
      default: "",
    },
    disableBtn: {
      type: Boolean,
      default: false,
    },
    dontShowBtn: {
      type: Boolean,
      default: false,
    },
    shouldDeleteSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: this.input,
    }
  },
  watch: {
    search(newValue) {
      this.$emit("changeInput", newValue)
    },
    shouldDeleteSearch(value) {
      if (value) {
        this.deleteSearch()
      }
    },
  },
  methods: {
    deleteSearch() {
      this.search = ""
    },
  },
}
</script>

<style scoped></style>
