<template>
  <!-- upload logo -->
  <div class="position-relative">
    <input
      ref="uploadedFile"
      type="file"
      :accept="accept"
      @change="onFileChange()"
      @click="handleClick($event)"
      @mouseenter="handleMouseEnter('enter')"
      @mouseleave="handleMouseEnter('leave')"
    />
    <div :class="`d-flex justify-center uploadBox ${iconConfigs.color}`">
      <div class="d-flex align-center">
        <component
          :is="iconConfigs.icon"
          @click="handleRemovingFile"
        ></component>
        <div v-if="label" class="font_18">{{ label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import removeIcon from "@/assets/img/file-uploader/removeIcon.vue"
import uploadIcon from "@/assets/img/file-uploader/uploadIcon.vue"
import failedIcon from "@/assets/img/file-uploader/failedIcon.vue"
import excelIcon from "@/assets/img/file-uploader/excelIcon.vue"
import loadingIcon from "@/assets/img/file-uploader/loadingIcon.vue"

export default {
  name: "VanUploadBox",
  components: { removeIcon, excelIcon, failedIcon, uploadIcon, loadingIcon },
  props: {
    convertSize: {
      type: Number,
      default: 1000000,
    },
    maxSize: {
      type: Number,
      default: 2000000,
    },
    accept: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      uploadedFile: null,
      prevIcon: null,
      uploadStatus: "empty",
      isUploaded: "uploaded",
      isFailed: "failed",
      isLoading: "loading",
      isEmpty: "empty",
      showRemoveIcon: "remove",
    }
  },
  computed: {
    iconConfigs() {
      switch (this.uploadStatus) {
        case this.isUploaded:
          return { icon: "excelIcon", color: "green_back_1" }
        case this.isFailed:
          return { icon: "failedIcon", color: "pink_back_1" }
        case this.showRemoveIcon:
          return { icon: "removeIcon", color: "van_color05" }
        case this.isEmpty:
          return { icon: "uploadIcon", color: "" }
        case this.isLoading:
          return { icon: "loadingIcon", color: "" }
      }
      return { icon: "uploadIcon", color: "" }
    },
  },
  methods: {
    fireClick() {
      this.$refs.uploadedFile.click()
    },
    setUploadedStatus() {
      this.uploadStatus = this.isUploaded
    },
    setFailedStatus() {
      this.uploadStatus = this.isFailed
    },
    onFileChange() {
      this.uploadedFile = this.$refs.uploadedFile.files?.[0] ?? null
      if (!this.uploadedFile) {
        this.uploadStatus = this.isEmpty
        this.$emit("onChange", this.uploadedFile)
        return
      }

      this.uploadStatus = this.isLoading
      this.$emit("onChange", this.uploadedFile)
    },
    handleRemovingFile() {
      this.uploadStatus = this.isEmpty
      this.uploadedFile = null
      this.$refs.uploadedFile.value = ""
      this.$emit("onChange", this.uploadedFile)
    },
    handleClick(event) {
      if (this.uploadStatus === this.showRemoveIcon) {
        this.handleRemovingFile()
        event.preventDefault()
      }
    },
    handleMouseEnter(status) {
      if (
        ![this.isUploaded, this.showRemoveIcon, this.isFailed].includes(
          this.uploadStatus
        )
      )
        return

      if (this.uploadStatus !== this.showRemoveIcon)
        this.prevIcon = this.uploadStatus
      this.uploadStatus =
        status === "enter" ? this.showRemoveIcon : this.prevIcon
    },
    removeFile() {
      this.$refs.uploadedFile.value = ""
      this.uploadStatus = this.isEmpty
    },
  },
}
</script>
<style lang="scss" scoped>
.progressbar {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
input {
  height: 100px;
  overflow: hidden;
  width: 100px;
  opacity: 0;
  z-index: 3;
  position: relative;
  cursor: pointer;
}
.uploadBox {
  border: 1px dashed $van_color07 !important;
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 4px;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.uploadLoader {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  position: sticky;
  overflow: hidden;
  max-width: 100px;

  > .flex {
    flex: 0 0 auto;
    height: 100%;

    .v-card {
      height: 100%;
    }
  }
}
.thumb {
  height: 100%;

  &:hover {
    opacity: 0.2;
    cursor: pointer;
    z-index: 3;
  }
}
</style>
