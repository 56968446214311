import { render, staticRenderFns } from "./AvatarSvg.vue?vue&type=template&id=dfac6084&scoped=true&functional=true&"
import script from "./AvatarSvg.vue?vue&type=script&lang=js&"
export * from "./AvatarSvg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "dfac6084",
  null
  
)

export default component.exports