<template>
  <v-card
    color="white"
    class="rounded pa-8 my-4 mx-8"
    :class="{ 'w-100': isWidth100 }"
    flat
  >
    <div v-if="!startUpload" class="w-100 upload-box">
      <input
        id="file"
        ref="file"
        type="file"
        accept="image/*, .pdf"
        @change="handleFile()"
      />
      <div class="upload-guide-info">
        <v-img
          width="24px"
          height="24px"
          max-height="24px"
          max-width="24px"
          :src="require('@/assets/img/Cloud_Upload.svg')"
        />
        <span class="font-11-no-333-17"> {{ firstGuidInfo }} </span>
        <span class="font-11-no-333-17">
          {{ secondGuidInfo }}
        </span>
      </div>
    </div>
    <div v-else class="upload-loader">
      <v-progress-circular
        :value="progressBar"
        color="van_green"
        background-color="van_color06"
        size="45"
        width="4"
      >
        {{ progressBar }}%
      </v-progress-circular>
      <span class="font_6 mt-4">
        {{ file.name }}
      </span>
      <v-btn
        v-if="progressBar == 100"
        class="delete-button"
        text
        icon
        @click="deleteFile()"
      >
        <v-icon dark size="20" color="van_color03"> delete_outline </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import Compressor from "compressorjs"

export default {
  name: "UploadBox2",
  props: {
    firstGuidInfo: {
      type: String,
      default: "حداکثر حجم فایل 10 مگابایت است.",
    },
    secondGuidInfo: {
      type: String,
      default: "فرمت‌‌های مجاز jpeg، jpg ،PDF و png است.",
    },
    quality: {
      type: Number,
      default: 0.8,
    },
    maxWidth: {
      type: Number,
      default: 1000,
    },
    maxHeight: {
      type: Number,
      default: 1000,
    },
    convertSize: {
      type: Number,
      default: 1000000,
    },
    maxSize: {
      type: Number,
      default: 10000000,
    },
    isWidth100: {
      type: Boolean,
      default: false,
    },
    isResetUploadedFile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: "",
      progressBar: 0,
      startUpload: false,
      uploadedFile: "",
      success: false,
    }
  },
  computed: {
    checkImageFormat() {
      const fileTypeSplitted = this.file["type"].split("/")
      return (
        fileTypeSplitted[0] === "image" &&
        (fileTypeSplitted[1] === "png" ||
          fileTypeSplitted[1] === "jpg" ||
          fileTypeSplitted[1] === "jpeg") &&
        fileTypeSplitted[1] !== "gif"
      )
    },
    shouldDeleteUploadedFileState() {
      return this.$store.state.shouldDeleteUploadedFile
    },
  },
  watch: {
    isResetUploadedFile(val) {
      if (val) {
        this.deleteFile()
        this.$emit("reset-is-done")
      }
    },
    shouldDeleteUploadedFileState(val) {
      if (!val) this.deleteFile()
    },
  },
  methods: {
    handleFile() {
      this.file = this.$refs.file.files[0]

      if (this.checkImageFormat) {
        this.success = true
        this.startUpload = true
        this.compressImageFileAndUpload()
      } else if (this.file["type"].split("/")[1] === "pdf") {
        this.success = true
        this.startUpload = true
        this.uploadFile(this.file)
      } else {
        this.$store.commit(
          "callsnackbar",
          "فرمت فایل انتخاب شده قابل قبول نیست"
        )
      }
    },
    compressImageFileAndUpload() {
      new Compressor(this.file, {
        quality: this.quality,
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        convertSize: this.convertSize,
        success: (result) => this.uploadFile(result),
        error(err) {
          this.deleteFile()
          this.$store.commit("callsnackbar", "لطفا دوباره تلاش کنید")
          Sentry.captureException(err)
        },
      })
    },
    uploadFile(file) {
      if (file.size > this.maxSize) {
        this.$store.commit(
          "callsnackbar",
          "حجم فایل انتخاب شده بیشتر از حد مجاز است"
        )
      } else {
        const formData = new FormData()
        const fileType = this.checkImageFormat ? "jpeg" : "pdf"
        formData.append("image", file, "file." + fileType)
        this.$http
          .post(
            this.$store.state.api_base_url + "/modules/upload/document",
            formData,
            {
              timeout: 50000,
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
              onUploadProgress: (progressEvent) => {
                this.progressBar = parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              },
            }
          )
          .then((response) => {
            this.uploadedFile = response.data.data
            this.$emit("uploaded-file", this.uploadedFile)
          })
          .catch((error) => {
            //callsnackbar
          })
      }
    },
    deleteFile() {
      this.file = null
      this.uploadedFile = ""
      this.progressBar = 0
      this.startUpload = false
      this.$emit("uploaded-file", "")
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-box {
  border: 1px dashed #e5e5e5;
  position: relative;
}

.upload-guide-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 28%;
  right: 33%;
}

input {
  height: 180px;
  overflow: hidden;
  width: 100%;
  opacity: 0;
  z-index: 3;
  position: relative;
  cursor: pointer;
}

.upload-loader {
  border: 1px dashed #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;

  .delete-button {
    position: absolute;
    bottom: 40px;
  }
}

@media screen and (max-width: 600px) {
  .upload-guide-info {
    bottom: 28%;
    right: 16%;
  }
}
</style>
