<template>
  <div class="activationBlock">
    <span class="topBack pendingTopBack" />
    <v-layout column class="mx-auto twoPending" style="margin: -20px">
      <v-flex class="personal_title alldone">
        <v-layout
          justify-space-between
          align-center
          :class="$vuetify.breakpoint.xsOnly ? 'column' : null"
        >
          <v-flex>
            <v-layout align-center>
              <v-flex style="max-width: 60px" class="pl-6">
                <v-img
                  v-if="icon"
                  :src="require('@/assets/img/newIcon/' + icon)"
                  alt="avatar"
                  width="40"
                />
              </v-flex>
              <v-flex>
                <div class="font-size-14">
                  <slot name="text"></slot>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex>
            <div class="text-left font_7">
              <slot name="action"> </slot>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "GeneralYellowBarMessage",
  props: {
    icon: {
      type: [String, null, undefined],
    },
  },
}
</script>
