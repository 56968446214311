<template>
  <v-card>
    <v-toolbar class="fixed_toolbar" flat height="60">
      <v-btn icon @click="$emit('close-modal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pa-0"> مدیریت روش‌های پرداخت </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :loading="loading"
          @click="saveType()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout column>
      <v-flex>
        <v-layout class="rows" column>
          <v-flex>
            <div class="wageType">
              <div class="justify-space-between d-flex align-center">
                <span>کارمزد تراکنش</span>
                <div class="select-box">
                  <v-select
                    v-model="wageType"
                    :items="wageTypeItems"
                    flat
                    height="36"
                    item-text="value"
                    item-value="key"
                    outlined
                    :disabled="type === 'credit'"
                    solo
                  />
                </div>
              </div>
              <div v-if="type === 'credit'" class="wageInfo">
                <v-icon> info </v-icon>
                <span>
                  کارمزد در روش پرداخت اعتباری تنها از کسب و کار دریافت می‌شود
                </span>
              </div>
            </div>
            <p class="mb-6">
              از میان روش‌های پرداخت فعال، نمایش یا عدم نمایش را، برای
              مشتریان‌تان، تعیین کنید.
            </p>
            <v-form :disabled="loading" class="full-width">
              <div v-for="(bank, index) in banks" :key="index">
                <v-divider
                  v-if="index !== 0"
                  class="my-3"
                  style="border-color: #eee"
                />
                <bank-management-item
                  :bank="bank"
                  @showDeactivateModal="showDeactivateModal"
                />
              </div>
            </v-form>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- modals -->
    <v-dialog
      v-if="selectedBank !== null"
      v-model="deactivateModal"
      no-click-animation
      transition="slide-x-transition"
      width="365"
    >
      <v-card>
        <v-form :disabled="loading">
          <v-card-text style="padding: 32px">
            <v-layout align-center column justify-center style="width: 100%">
              <v-flex class="mb-4">
                <svg
                  fill="none"
                  height="63"
                  viewBox="0 0 64 63"
                  width="64"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_359_4838)">
                    <path
                      d="M4.88353 57.1356C8.18007 60.9173 12.6714 63 17.5301 63C18.332 63 19.1537 62.9435 19.9722 62.8321C31.6765 61.2392 42.1195 59.0562 42.5188 58.9724C43.9145 58.6099 45.3177 58.2838 46.6748 57.9684C51.545 56.8365 56.1451 55.7674 59.1796 53.2982C62.5127 50.5859 63.6245 46.4215 62.6785 40.1928C60.7381 27.4157 59.2617 21.441 58.7673 19.6215L58.3657 18.0215C56.93 11.9598 54.7238 7.5734 51.6353 4.63129C48.3643 1.51525 44.0759 0.000509977 38.5253 0.000509977L38.5205 0.000499994L38.516 0.000419974C38.5133 0.000369996 38.4901 0 38.4467 0C37.7219 0 31.0524 0.1078 20.2023 3.84161C15.2484 5.54639 9.6545 7.72072 5.72624 10.9216C3.84316 12.456 2.46737 14.1077 1.52026 15.9709C0.49728 17.9833 0 20.1879 0 22.7104V43.986C0 48.7825 1.77998 53.5753 4.88353 57.1356Z"
                      fill="#D44D42"
                      opacity="0.1"
                    />
                    <path
                      d="M38 25.41L36.59 24L31 29.59L25.41 24L24 25.41L29.59 31L24 36.59L25.41 38L31 32.41L36.59 38L38 36.59L32.41 31L38 25.41Z"
                      fill="#D44D42"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_359_4838">
                      <rect fill="white" height="63" width="64" />
                    </clipPath>
                  </defs>
                </svg>
              </v-flex>
              <v-flex class="mb-1">
                <p class="modal-title">از عدم نمایش این بانک اطمینان داری؟</p>
              </v-flex>
              <v-flex class="mb-4">
                <p
                  class="mb-0 justify-center modal-description"
                  style="text-align: center"
                >
                  پس از تایید درخواست این بانک برای مشتریان شما نمایش داده
                  نمی‌شود.
                </p>
              </v-flex>
              <v-flex class="mb-3" style="width: 100%">
                <v-sheet class="pa-3" color="#f5f5f5">
                  <v-layout align-center justify-center style="width: 100%">
                    <v-flex style="flex-grow: 0">
                      <div class="modal-image-box ml-3">
                        <v-img
                          :lazy-src="selectedBank.logo"
                          :src="selectedBank.logo"
                          contain
                          height="18px"
                          min-width="18px"
                          width="18px"
                        />
                      </div>
                    </v-flex>
                    <v-flex>
                      <span class="modal-description">{{
                        selectedBank.name
                      }}</span>
                    </v-flex>
                  </v-layout>
                </v-sheet>
              </v-flex>
              <v-flex
                style="width: 100%; justify-content: center; display: flex"
              >
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  class="btn_small_normal px-4 ml-0"
                  color="#D44D42"
                  depressed
                  style="background-color: #d44d42"
                  text
                  @click="deactivate(selectedBank)"
                >
                  <span class="text--white" style="color: #fff !important"
                    >بله، مطمئنم</span
                  >
                </v-btn>

                <v-btn
                  :disabled="loading"
                  class="btn_small_normal px-3"
                  depressed
                  outlined
                  text
                  @click="deactivateModal = false"
                >
                  <span>خیر، منصرف شدم</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import BankManagementItem from "./BankManagementItem"

export default {
  name: "BankManagement",
  components: { BankManagementItem },
  props: {
    type: {
      type: String,
      required: true,
    },
    wageType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deactivateModal: false,
      selectedBank: null,
      loading: false,
      wageTypeItems: [
        {
          value: "از خودم کسر شود",
          key: "APPLICATION_SELF",
        },
        {
          value: "از پرداخت‌کننده دریافت شود",
          key: "APPLICATION_USER",
        },
      ],
    }
  },
  computed: {
    banks() {
      return this.$store.state.mpg[`${this.type}Methods`].filter(
        (bank) => bank.status !== "PENDING"
      )
    },
  },
  methods: {
    saveType() {
      this.loading = true
      this.$http
        .put(
          `${this.$store.getters.urlMPG}/payment-method-types/${this.type}`,
          {
            wage_type: this.wageType,
          }
        )
        .then((response) => {
          this.$emit("update", this.wageType, this.type)
        })
        .catch((error) => {})
        .finally(() => {
          this.$emit("close-modal")
          this.loading = false
        })
    },
    showDeactivateModal(bank) {
      this.selectedBank = bank
      this.deactivateModal = true
    },
    deactivate(bank) {
      this.loading = true
      this.$http
        .put(`${this.$store.getters.urlMPG}/payment-methods/${bank.slug}`, {
          status: "DEACTIVATED",
        })
        .then((response) => {
          bank.status = "DEACTIVATED"
          this.deactivateModal = false
        })
        .catch((error) => {
          //callsnackbar
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.image-box {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  width: 50px;
  height: 50px;
  border-radius: 4px;
}
.wageType {
  border-bottom: 1px solid #eee;
  margin-bottom: 16px;
  padding-bottom: 16px;
  span {
    font-size: 12px;
    color: #666;
  }
  .select-box {
    width: 189px;
  }
}
.wageInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 9.5px;
}

.full-width {
  width: 100% !important;
}

.modal-image-box {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 38px;
  height: 38px;
  border-radius: 4px;
}

.modal-title {
  font-size: 16px;
  font-weight: 700;
}

.modal-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
