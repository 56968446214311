<template>
  <div>
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      class="business"
      color="white"
      flat
      outlined
    >
      <!--first-->
      <div class="titles">
        <div
          v-if="
            $store.state.pbv.application && $store.state.pbv.application.domains
          "
          class="income mb-2"
          style="width: 332px"
        >
          <h2 style="display: inline">
            {{ $store.state.pbv.application.domains[0] }}
          </h2>
          <!--          <v-chip-->
          <!--            v-if="businessStatus === 0"-->
          <!--            class="ma-2"-->
          <!--            color="van_red"-->
          <!--            small-->
          <!--            text-color="white"-->
          <!--          >-->
          <!--            در انتظار تایید حساب-->
          <!--          </v-chip>-->
          <!--          <v-chip-->
          <!--            v-if="-->
          <!--              businessStatus === 1 && applicationStatus === 'INIT'-->
          <!--            "-->
          <!--            class="ma-2"-->
          <!--            color="#F9D358"-->
          <!--            small-->
          <!--            text-color="white"-->
          <!--          >-->
          <!--            در حال بررسی و تایید-->
          <!--          </v-chip>-->
          <!--          <v-chip-->
          <!--            v-if="-->
          <!--              businessStatus === 1 &&-->
          <!--                applicationStatus === 'ACTIVE'-->
          <!--            "-->
          <!--            class="ma-2"-->
          <!--            color="van_green"-->
          <!--            small-->
          <!--            text-color="white"-->
          <!--          >-->
          <!--            فعال-->
          <!--          </v-chip>-->
        </div>
        <div class="income">
          شناسه:
          {{ $store.state.business.business_name }}
        </div>
      </div>
      <!--second-->
      <!--      <div-->
      <!--        v-if="-->
      <!--          businessStatus === 1 && applicationStatus === 'ACTIVE'-->
      <!--        "-->
      <!--        class="more-data"-->
      <!--      >-->
      <!--        <div class="bus bus-right">-->
      <!--          <div-->
      <!--            v-if="$store.state.business.role !== 'developer'"-->
      <!--            class="mablagh"-->
      <!--          >-->
      <!--            {{ $store.state.pbv.successful_withdrawals_count | thousandSeprator }}-->
      <!--          </div>-->
      <!--          <div-->
      <!--            v-else-->
      <!--            class="mablagh"-->
      <!--          >-->
      <!--            - -->
      <!--          </div>-->
      <!--          <div class="sub-text">-->
      <!--            تراکنش-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="bus bus-right">-->
      <!--          <div-->
      <!--            v-if="$store.state.business.role !== 'developer'"-->
      <!--            class="mablagh"-->
      <!--            dir="ltr"-->
      <!--          >-->
      <!--            {{ $store.state.pbv.transactions_sum | currency }}-->
      <!--          </div>-->
      <!--          <div-->
      <!--            v-else-->
      <!--            class="mablagh"-->
      <!--          >-->
      <!--            - -->
      <!--          </div>-->
      <!--          <div class="sub-text">-->
      <!--            {{ currencyName }} تراکنش-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <v-layout class="botton" column>
        <v-flex>
          <v-btn
            class="btn_small_normal"
            depressed
            outlined
            text
            @click="$emit('view-setting')"
          >
            تنظیمات
          </v-btn>
        </v-flex>
        <v-flex v-if="applicationStatus === 'ACTIVE'">
          <v-btn
            :to="{
              name: 'businessReport',
              params: { filtered: 'successfulTransactionPbv' },
            }"
            class="btn_small_normal"
            depressed
            outlined
            text
          >
            تراکنش‌ها
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card
      v-if="!$vuetify.breakpoint.mdAndUp"
      class="business-md-down"
      color="white"
      flat
      outlined
    >
      <v-layout justify-space-between wrap>
        <v-flex>
          <div class="titles">
            <div
              v-if="
                $store.state.pbv.application &&
                $store.state.pbv.application.domains
              "
              class="income"
              style="width: 332px"
            >
              <h2 style="display: inline">
                {{ $store.state.pbv.application.domains[0] }}
              </h2>
              <v-chip
                v-if="businessStatus === 0"
                class="ma-2"
                color="van_red"
                small
                text-color="white"
              >
                در انتظار تایید حساب
              </v-chip>
              <v-chip
                v-if="businessStatus === 1 && applicationStatus === 'INIT'"
                class="ma-2"
                color="#F9D358"
                small
                text-color="white"
              >
                در حال بررسی و تایید
              </v-chip>
              <v-chip
                v-if="businessStatus === 1 && applicationStatus === 'ACTIVE'"
                class="ma-2"
                color="van_green"
                small
                text-color="white"
              >
                فعال
              </v-chip>
            </div>
            <div class="income">
              شناسه:
              {{ $store.state.business.business_name }}
            </div>
          </div>
        </v-flex>
        <v-flex style="max-width: 40px; margin-left: -10px">
          <v-menu
            bottom
            close-on-content-click
            content-class="up_menu"
            offset-y
            transition="slide-y-transition"
          >
            <!-- this on slot sometime is undefined -->
            <template #activator="{ on }">
              <v-btn fab icon size="24" small style="margin: 0" v-on="on">
                <v-icon color="van_color03" size="21" style="line-height: 40px">
                  more_vert
                </v-icon>
              </v-btn>
            </template>

            <v-card class="drop_down_menu" outlined>
              <v-list class="menu_v_list">
                <v-list-item @click="$emit('view-setting')">
                  <v-list-item-content>
                    <v-list-item-title>تنظیمات</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  :to="{
                    name: 'businessReport',
                    params: { filtered: 'successfulTransactionPbv' },
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title>واریزها</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="businessStatus === 1 && applicationStatus === 'ACTIVE'"
        align-center
        class="more-data"
        fill-height
        justify-center
      >
        <v-flex>
          <div class="bus bus-right">
            <div
              v-if="$store.state.business.role !== 'developer'"
              class="mablagh"
            >
              {{
                $store.state.pbv.successful_withdrawals_count | thousandSeprator
              }}
            </div>
            <div v-else class="mablagh">-</div>
            <div class="sub-text">تراکنش</div>
          </div>
        </v-flex>
        <v-flex>
          <div class="bus bus-right">
            <div
              v-if="$store.state.business.role !== 'developer'"
              class="mablagh"
              dir="ltr"
            >
              {{ $store.state.pbv.transactions_sum | currency }}
            </div>
            <div v-else class="mablagh">-</div>
            <div class="sub-text">{{ currencyName }} تراکنش</div>
          </div>
        </v-flex>
        <v-flex>
          <div class="bus bus-left">
            <div
              v-if="$store.state.business.role !== 'developer'"
              class="mablagh"
            >
              {{ $store.state.pbv.customers_count | thousandSeprator }}
            </div>
            <div v-else class="mablagh">-</div>
            <div class="sub-text">نفر مشتری</div>
          </div>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SettingCard",
  data() {
    return {
      isHover: false,
      hover: false,
    }
  },
  computed: {
    applicationStatus() {
      return this.$store.state.pbv?.application?.status ?? false
    },
    businessStatus() {
      return this.$store.state.business?.status ?? false
    },
  },
  methods: {
    pushToDoc() {
      var url = "https://vandarpay.github.io/docs/pbv/#directdebit"
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}

.business {
  height: 120px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.business-md-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }

  .more-data {
    display: flex;
    margin-top: 16px;
    justify-content: flex-end !important;
  }

  .bus {
    background-color: $van_color11;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 11px 5px;
    height: 80px;
    width: 100%;
    margin: 5px;
    max-width: 95%;

    &.bus-right,
    &.bus-left {
      width: 95%;
    }

    &.flat {
      background: transparent;
      color: $van_pink;
      font-size: 12px;
      font-weight: bold;
      height: 28px;
    }
  }
}

.v-avatar {
  margin: 0 0 10px 0;
}

.titles {
  max-width: 200px;
}

h2 {
  margin: 0 0 9px 0;
  line-height: 20px;
}

.income {
  font-size: 12px;
  color: #666;
  font-weight: normal;

  h2 {
    font-size: 14px;
  }
}

.bus-right {
  margin-left: 10px;
}

.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;
  max-width: 120px;

  &.bus-right,
  &.bus-left {
    width: 50%;
  }

  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}

.business .more-data {
  display: flex;
  width: 384px;
  justify-content: flex-end;
}

.mablagh {
  font-size: 14px;
  font-weight: bold;
}

.sub-text {
  font-size: 9px;
  color: #999;
}

.layout.botton {
  max-width: 88px;

  .v-btn {
    width: calc(100% - 16px);
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.v-chip--small {
  height: 25px !important;
  margin-top: 5px !important;
}
</style>
