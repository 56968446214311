<template>
  <v-list class="menu_v_list bold_hover pt-11">
    <v-list-item v-for="index in 4" :key="index" class="my-2">
      <v-list-item-action>
        <span
          style="width: 20px; height: 20px; margin: 9px 3px 3px 3px"
          class="default_gray"
        />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <span class="default_gray" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "NavigationDrawerSkeleton",
}
</script>

<style></style>
