<template>
  <div>
    <v-data-table
      :key="forceUpdateCounter"
      :headers="ticketItems.length > 0 ? headers : []"
      :items="ticketItems"
      :options.sync="options"
      :server-items-length="ticketsNumber"
      no-data-text="هیچ تیکتی برای نمایش وجود ندارد"
      no-results-text="هیچ تیکتی برای نمایش وجود ندارد"
      class="elevation-0"
      :footer-props="{
        'items-per-page-text': ` ${ticketDisplayNumber}`,
        'items-per-page-options': [20, 50, 100],
        'disable-items-per-page': ticketItems.length < 20 ? true : false,
      }"
      :hide-default-footer="ticketItems.length > 0 ? false : true"
      :hide-default-header="isMobile"
      :items-per-page="20"
      :page.sync="page"
      @page-count="pageCount = $event"
    >
      <template #item="props">
        <TicketsListRowForMobile
          v-if="isMobile"
          :row-data="props"
          :loading="loading"
          @send-out="goToTicket(props)"
        />
        <TicketsListRowForDesktop
          v-else
          :row-data="props"
          :loading="loading"
          @send-out="goToTicket(props)"
        />
      </template>
      <template #footer.prepend>
        <v-flex class="pagination-wrapper">
          <v-pagination
            v-model="page"
            :length="pageCount"
            class="pagination-style"
            :class="{ 'handle-no-items': ticketItems.length === 0 }"
            color="rgba(76, 159, 135, 0.3)"
          ></v-pagination>
        </v-flex>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showTicketModal"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :content-class="
        isShowNewMessageCreationModal ? 'secondModal left-modal' : 'left-modal'
      "
      no-click-animation
      transition="slide-x-transition"
      style="z-index: 1000003 !important"
    >
      <TicketModal
        :key="forceUpdateCounterOfTicketModal"
        :ticket="selectedTicket"
        :tickets-list-url="ticketsListUrl"
        :ticket-modal-is-open="showTicketModal"
        :should-get-messages="!isShowNewMessageCreationModal"
        @reopen-ticket="reopenTicket"
        @closeModal="showTicketModal = false"
        @showNewMessageModal="showNewMessageModal"
        @get-tickets-again="getTicketsAgain"
      />
    </v-dialog>
    <v-dialog
      v-if="isShowNewMessageCreationModal"
      v-model="isShowNewMessageCreationModal"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="left-modal"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
      transition="slide-x-transition"
    >
      <CreateNewMessageModal
        :new-message-creation-url="newMessageCreationUrl"
        @send-message="getUpdatedData"
        @closeModal="isShowNewMessageCreationModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import TicketModal from "../TicketModal.vue"
import CreateNewMessageModal from "../CreateNewMessageModal"
import { returnDynamicContent } from "../utils/generalMethods"
import TicketsListRowForMobile from "./TicketsListRowForMobile.vue"
import TicketsListRowForDesktop from "./TicketsListRowForDesktop.vue"

export default {
  name: "TicketsList",
  components: {
    TicketModal,
    CreateNewMessageModal,
    TicketsListRowForMobile,
    TicketsListRowForDesktop,
  },
  props: {
    ticketsListUrl: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    shouldGetTicketsList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ticketItems: [],
      loading: true,
      options: {},
      props: {
        response: {
          type: Object,
          default: () => {},
        },
      },
      headers: [
        {
          text: "وضعیت",
          align: "start",
          sortable: false,
          value: "status",
        },
        { text: "شناسه", value: "id", sortable: false },
        { text: "عنوان", value: "title", sortable: false, width: "30%" },
        { text: "دسته‌بندی", value: "category", sortable: false, width: "20%" },
        { text: "تاریخ ایجاد", value: "created_at", sortable: false },
        { text: "آخرین بروزرسانی", value: "updated_at", sortable: false },
      ],
      ticketsNumber: 0,
      page: 1,
      pageCount: 0,
      per_page: 0,
      showTicketModal: false,
      selectedTicket: {},
      isShowNewMessageCreationModal: false,
      returnDynamicContent,
      newMessageCreationUrl: "",
      forceUpdateCounter: 0,
      forceUpdateCounterOfTicketModal: 0,
      oldStatus: "",
      newStatus: "",
      statusHasChanged: false,
      hasItem: true,
    }
  },
  computed: {
    ticketDisplayNumber() {
      const pageRange =
        this.pageStart !== this.pageStop
          ? this.pageStart + " - " + this.pageStop
          : this.pageStart

      const from = "از"
      const showPerPageText = "نمایش در هر صفحه"
      const space = "\xa0\xa0"
      const result = `${pageRange} ${from} ${this.ticketsNumber} ${space} | ${space} ${showPerPageText} `
      return result
    },
    pageStart() {
      return (
        (parseInt(this.options.page) - 1) *
          parseInt(this.options.itemsPerPage) +
        1
      )
    },
    pageStop() {
      const end =
        parseInt(this.options.page) * parseInt(this.options.itemsPerPage)

      return end <= this.ticketsNumber ? end : this.ticketsNumber
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  watch: {
    options: {
      handler() {
        if (!this.statusHasChanged) {
          this.createFakeDataForSkeleton()
          this.getDataFromApi()
        }
      },
      deep: true,
    },
    status() {
      this.statusHasChanged = true
      this.resetPage()
      this.forceUpdateCounter++
      // if (this.ticketItems.length !== 0) {
      this.getDataFromApi()
      // }
    },
    shouldGetTicketsList(val) {
      if (val) {
        this.getDataFromApi()
      }
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      this.getTickets()
    },
    async getTickets() {
      try {
        const { page, itemsPerPage } = this.options
        const res = await this.$http.get(this.ticketsListUrl, {
          params: {
            page: page,
            per_page: itemsPerPage,
            status: this.status,
          },
        })
        const { total } = res.data.meta
        this.$nextTick(() => {
          this.ticketItems = [...res.data.data]
        })
        this.ticketsNumber = total

        if (itemsPerPage > 0) {
          this.ticketItems = this.ticketItems.slice(
            (page - 1) * itemsPerPage,
            page * itemsPerPage
          )
        }
      } finally {
        this.loading = false
        if (this.statusHasChanged) {
          this.statusHasChanged = false
        }
      }
    },
    goToTicket(selectedRow) {
      this.selectedTicket = selectedRow.item
      this.showTicketModal = true
      this.forceUpdateCounterOfTicketModal++
    },
    showNewMessageModal(url) {
      this.newMessageCreationUrl = url
      this.isShowNewMessageCreationModal = true
    },
    closeNewMessageModal() {
      this.isShowNewMessageCreationModal = false
    },
    getUpdatedData() {
      this.getDataFromApi()
      this.selectedTicket.status = "pending"
      this.isShowNewMessageCreationModal = false
      this.$store.commit("callSuccessSnackbar", "پیام با موفقیت ارسال شد")
    },
    createFakeDataForSkeleton() {
      this.ticketsNumber = 20
      for (let i = 0; i < 20; i++) {
        this.ticketItems.push({
          id: null,
          category: {
            parent: { id: null, title: null },
            id: null,
            title: null,
          },
          created_at: null,
          status: null,
          title: null,
          updated_at: null,
        })
      }
    },
    resetPage() {
      this.options.page = 1
      this.page = 1
    },
    reopenTicket() {
      this.selectedTicket.status = "answered"
      this.getDataFromApi()
    },
    getTicketsAgain() {
      this.getDataFromApi()
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.v-data-footer) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

:deep(.v-data-footer__select) {
  margin-right: 24px !important;
  justify-content: flex-start;
  color: #333333;
}

:deep(.v-select__selections) {
  color: #333333 !important;
}

:deep(.v-input) {
  margin: 0 20px !important;
}

:deep(.v-data-footer__pagination) {
  display: none;
}

:deep(.v-data-footer__icons-before) {
  display: none;
}

:deep(.v-data-footer__icons-after) {
  display: none;
}

:deep(.v-text-field > .v-input__control > .v-input__slot:before) {
  display: none;
}

:deep(.v-data-footer) {
  padding: 12px 8px;
}

:deep(tbody tr td:first-child div, tbody td:first-child div) {
  margin: 0 !important;
  padding-top: 0 !important;
}

.footer {
  display: flex;
  justify-content: space-around;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  max-width: 380px;
}

.pagination-style {
  margin-left: 32px;
}

:deep(.v-pagination__item) {
  font-size: 12px !important;
  color: #333333 !important;
}

:deep(.v-pagination__item--active) {
  font-size: 14px !important;
}

:deep(.v-data-table) {
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}

:deep(table thead th span) {
  font-size: 11px !important;
}

.handle-no-items {
  visibility: hidden;
}

:deep(.v-data-table__empty-wrapper) {
  pointer-events: none;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  user-select: none;
}

@media screen and (max-width: 960px) {
  :deep(tr:hover td:first-child) {
    padding: 4px 15px !important;
  }
}

@media screen and (max-width: 780px) {
  :deep(.v-data-footer) {
    flex-direction: column-reverse;
  }

  :deep(.v-data-footer__select) {
    margin-right: 1px !important;
    margin-left: 8px !important;
    padding-bottom: 12px;
    padding-right: 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
  }

  .pagination-style {
    margin-left: 10px;
  }
}

@media screen and (max-width: 600px) {
  :deep(.v-data-table__empty-wrapper) {
    margin-top: 50px;
  }
}
</style>
