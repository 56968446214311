<template>
  <v-item>
    <v-card class="d-flex flex-column align-center" flat>
      <v-card
        :style="
          selectedbank && selectedbank.code === bank.code
            ? 'background-color: #FCFCFC;border-color: #BBBBBB !important'
            : 'background-color: #FAFAFA;border-color: #eeeeee'
        "
        class="bank_card mb-2 radius-4 w-100 border_eee"
        flat
      >
        <div v-if="bank.mandate" class="mandate_notif">
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
              fill="#666666"
            />
          </svg>
        </div>

        <v-img
          :lazy-src="bank.logo"
          :src="bank.logo"
          alt="لوگو بانک"
          class="ma-0"
          contain
          eager
          height="44"
          width="44"
        />
      </v-card>
      <div class="font_2 bank-name d-flex justify-center">
        {{ bank.name }}
      </div>
    </v-card>
  </v-item>
</template>

<script>
export default {
  name: "BankCard",
  props: ["bank", "selectedbank"],
}
</script>

<style lang="scss">
.bank_card {
  border-color: #eeeeee;
  padding: 31px;

  &:hover {
    background: #fcfcfc !important;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.4) !important;
  }
}

.border_bbb {
  border-color: #bbbbbb !important;
}

.mandate_notif {
  position: absolute;
  top: -7px;
  left: -6px;
}

.bank-name {
  font-size: 11px !important;
}
</style>
