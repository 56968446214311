<template>
  <v-layout column wrap justify-center align-start>
    <v-flex style="height: 100%; width: 100%">
      <topBoard
        :update="updateSettlement"
        :dont-let="dontLetP2p"
        @openModal="open_modal"
        @doneUpdateSettlement="done_update_settlement()"
      />
    </v-flex>
    <v-flex style="width: 100%; margin: 30px auto">
      <vandarTable
        :update="updateTable"
        :dont-let="dontLetP2p"
        @updateSettelment="allupdates()"
        @doneUpdateTable="done_update_table()"
        @doSameTransfer="do_same_transfer"
        @doSameId="do_same_id"
      />
    </v-flex>

    <!-- modals -->
    <v-dialog
      v-model="status"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <add
        v-show="addModal"
        :key="componentKey"
        :is-same-transfer="isSameTransfer"
        :add-props="addProps"
        @closeModal="close_modal"
        @closeAfterSubmit="close_after_submit"
      />
      <VandarId
        v-if="
          $store.state.isUser ||
          (!$store.state.isUser &&
            $store.state.business.role &&
            ['owner', 'admin'].includes($store.state.business.role))
        "
        v-show="vandarId"
        @closeModal="closeCheckIfset()"
      />
    </v-dialog>

    <!-- p2p send successfull -->
    <general-dialog
      v-if="p2pDone"
      :active="p2pDone"
      logo="newIcon/check.svg"
      content-class="confirmationMessageDialog"
      title="انتقال وجه انجام شد"
      description="رسید انتقال به صورت عمومی از طریق لینک زیر قابل مشاهده است:"
      :width="360"
      btn1-text="بازگشت به لیست"
      btn2-text="درخواست جدید"
      @btn1Clicked="p2pDone = false"
      @btn2Clicked="open_modal()"
    >
      <template slot="extra">
        <v-layout align-center class="mt-8">
          <v-flex
            class="font_10 van_color11 ml-1 mr-0 pt-2"
            style="
              height: 36px;
              width: 218px;
              border-radius: 4px;
              text-align: center;
            "
          >
            {{ receiptUrl }}
          </v-flex>
          <v-btn
            ref="btn"
            text
            depressed
            class="btn_small_normal van_color06 tblCpy-receipt_url"
            style="
              min-width: 80px !important;
              margin: 0px !important;
              padding: 0;
              max-width: 80px;
              height: 36px !important;
            "
            @click="shareReceiptUrl()"
          >
            <v-icon color="van_color03" class="ml-1" size="14">
              content_copy
            </v-icon>
            <span class="font_4">کپی لینک</span>
            <v-tooltip v-model="receipt_url_tooltip_show" top>
              <template #activator="{ on }">
                <v-btn
                  style="
                    position: absolute;
                    opacity: 0 !important;
                    width: 1px;
                    margin: 0px;
                    padding: 0px;
                    z-index: -1;
                    left: 40px;
                  "
                  v-on="on"
                />
              </template>
              <span>کپی شد</span>
            </v-tooltip>
          </v-btn>
          <input
            id="show-hidden-input-receipt_url"
            v-model="receiptUrl"
            class="hidden-input"
          />
        </v-layout>
      </template>
    </general-dialog>
  </v-layout>
</template>

<script>
import topBoard from "../elements/topBoard.vue"
import add from "../modals/add.vue"
import vandarTable from "../elements/vandarTable.vue"
import VandarId from "../../modals/addVandarId"
import GeneralDialog from "@/components/modals/generalDialog"

export default {
  name: "Index",
  components: {
    add,
    topBoard,
    vandarTable,
    VandarId,
    GeneralDialog,
  },
  data() {
    return {
      componentKey: 0,
      //this is add modal status
      status: false,
      addModal: false,
      vandarId: false,
      mablagh: 0,
      blocked: true,
      updateSettlement: false,
      updateTable: false,
      //accept modal
      // addIsFinalize: false,
      loading: false,
      amount: null,
      avatar: null,
      slug: null,
      name: null,
      trackId: null,
      businessOwner: null,
      paymentNumber: null,
      description: null,
      //propData
      isSameTransfer: false,
      addProps: null,
      dontLetP2p: false,
      p2pDone: false,
      receiptUrl: null,
      receipt_url_tooltip_show: false,
    }
  },
  watch: {
    status: function (newValue) {
      if (!newValue) this.close_modal()
    },
  },
  mounted() {
    if (this.$store.state.isUser) {
      this.getVandarId()
    }
  },
  methods: {
    shareReceiptUrl() {
      /* Get the text field */
      let copyText = document.querySelector("#show-hidden-input-receipt_url")

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        //Alert the copied text
        this.receipt_url_tooltip_show = true
        setTimeout(() => {
          this.receipt_url_tooltip_show = false
        }, 2000)
      }
    },
    getVandarId() {
      this.$http
        .get(this.$store.getters.apiUrlParam + "/profiles", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response.data.result.profile.slug == null) {
            this.status = true
            this.vandarId = true
            this.dontLetP2p = true
          } else {
            this.status = false
            this.vandarId = false
            this.dontLetP2p = false
          }
        })
        .catch((error) => {
          //callsnackbarform
        })
    },
    do_same_transfer(transition) {
      this.close_modal()
      this.isSameTransfer = true
      this.addProps = transition
      this.addModal = true
      this.status = true
    },
    do_same_id(transition) {
      this.close_modal()
      this.isSameTransfer = false
      this.addProps = transition
      this.addModal = true
      this.status = true
    },
    sendForm() {
      // this.loading = true
      var data = {
        amount: this.amount,
        slug: this.slug,
        payment_number: this.paymentNumber,
        description: this.description,
        track_id: this.trackId,
      }
      this.$http
        .post(
          this.$store.getters.apiWithVersionParam("3") + "/p2p/store",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.loading = false
          this.allupdates()
          this.getRecieptUrl(response.data.data.p2p.gateway_transaction_id)
          this.$store.commit("changeP2pConfirmLoading", false)
          this.addProps = null
          this.status = false
          this.vandarId = false
          this.addModal = false
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
          this.$store.commit("changeP2pConfirmLoading", false)
          this.addProps = null
          this.status = false
          this.vandarId = false
          this.addModal = false
          // this.addIsFinalize = false
        })
    },
    done_update_settlement() {
      this.updateSettlement = false
    },
    done_update_table() {
      this.updateTable = false
    },
    update_user() {
      if (this.$store.state.isUser) {
        //erase wallet
        this.$store.commit("eraseWallet", "user")
        this.$store
          .dispatch("retrieveUserObject")
          .then((response) => {
            //DO Nothing
          })
          .catch((error) => {
            //callsnackbar
          })
      } else {
        //TODO: update business list
        //erase wallet
        this.$store.commit("eraseWallet", "business")
        this.$store
          .dispatch(
            "retrieveBusinessObject",
            this.$store.state.business.business_name
          )
          .then((response) => {
            //DO Nothing
          })
          .catch((error) => {
            //callsnackbar
          })
      }
    },
    updatejustSettlement() {
      //update settlement and wallet
      this.updateSettlement = true
      this.update_user()
    },
    allupdates() {
      //update settlement and table and wallet
      this.updateSettlement = true
      this.updateTable = true
      this.update_user()
    },
    closeCheckIfset(obj) {
      this.status = false
      this.vandarId = false
      if (obj && obj.success) {
        this.getVandarId()
      }
    },
    close_modal(obj) {
      // this.addIsFinalize = obj.status
      //close add modal
      this.addProps = null
      this.status = false
      this.vandarId = false
      this.addModal = false
      if (obj && obj.status) {
        this.amount = obj.amount
        this.avatar = obj.avatar
        this.name = obj.name
        this.slug = obj.slug
        this.businessOwner = obj.businessOwner
        this.paymentNumber = obj.paymentNumber
        this.description = obj.description
        this.trackId = obj.trackId
        this.sendForm()
      }
    },
    close_after_submit(obj) {
      if (obj.status) {
        this.amount = obj.amount
        this.avatar = obj.avatar
        this.name = obj.name
        this.slug = obj.slug
        this.businessOwner = obj.businessOwner
        this.paymentNumber = obj.paymentNumber
        this.description = obj.description
        this.trackId = obj.trackId
        this.componentKey = !this.componentKey
        this.sendForm()
      }
    },
    open_modal() {
      this.close_modal()
      this.status = true
      this.addModal = true
      this.p2pDone = false
      // this.addIsFinalize = false
    },
    getRecieptUrl(transactionId) {
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            "/transaction?id=" +
            transactionId,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.receiptUrl = response.data.data[0].receipt_url
            this.p2pDone = true
          }
        })
    },
  },
}
</script>
