<template>
  <ChartCardWrapper
    class="mmt-5"
    :title="kerdarLang.general.amountOfSuccessfulDeposits"
    :show-filter="true"
    :selected-custom-duration="defaultSelectedDuration"
    @changeFilter="changeIncomeCountChartFilter($event)"
  >
    <!-- :growth="incomeCountGrowth" -->
    <LineEChart
      v-if="incomeCountStatus"
      :key="updateKeyCounter"
      chart-id="overview-number-of-deposit"
      :custom-label="kerdarLang.general.deposit"
      :chart-name="kerdarLang.general.number"
      :x-axis-data="xValueIncomeCount"
      :y-axis-data="yValueIncomeCount"
      :price-unit="kerdarLang.general.digit"
      :date-time-list="timeIncomeCount"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </ChartCardWrapper>
</template>

<script>
import ChartCardWrapper from "@/components/kerdar/components/ChartCardWrapper"
import {
  generateWeekDaysV2,
  generateMonthsV2,
  generateHoursV2,
  changeHorizontalWeeklyFirstDayV2,
} from "@/components/kerdar/utils/generalMethods.js"
import kerdarServices from "@/components/kerdar/services/servicesRepository"
import LineEChart from "@/components/kerdar/components/LineEChart"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "OverViewSuccessDepositEChart",
  components: {
    ChartCardWrapper,
    LineEChart,
  },
  props: {
    kerdarLang: {
      type: Object,
      default: () => {},
    },
    defaultSelectedDuration: {
      type: Object,
      default: () => {
        return {
          name: "ماه جاری",
          value: "current_month",
        }
      },
    },
  },
  data() {
    return {
      incomeCountData: [],
      incomeCountStatus: false,
      incomeCountFilter: null,
      updateKeyCounter: 0,
    }
  },
  computed: {
    incomeCountGrowth() {
      if (this.yValueIncomeCount && this.yValueIncomeCount.length) {
        if (
          this.yValueIncomeCount[0] ==
          this.yValueIncomeCount[this.yValueIncomeCount.length - 1]
        )
          return 0
        return (
          Math.round(
            ((this.yValueIncomeCount[this.yValueIncomeCount.length - 1] -
              this.yValueIncomeCount[0]) /
              this.yValueIncomeCount[0] || 1) *
              100 *
              100
          ) / 100
        )
      }
      return 0
    },
    yValueIncomeCount() {
      return this.incomeCountData.map((item) => item.value)
    },
    xValueIncomeCount() {
      if (
        this.incomeCountFilter &&
        ["monthly", "hourly"].includes(this.incomeCountFilter.timePeriod)
      ) {
        let xValue = []
        switch (this.incomeCountFilter.timePeriod) {
          case "monthly":
            generateMonthsV2(xValue, this.incomeCountData)
            break
          case "hourly":
            for (let element of this.incomeCountData) {
              xValue.push(jmoment(element.date_time).format("HH"))
            }
            break
        }
        return xValue
      }

      return this.incomeCountData.map((item) => item.dateWithoutYear)
    },
    timeIncomeCount() {
      if (
        this.incomeCountFilter &&
        ["weekly", "monthly", "hourly"].includes(
          this.incomeCountFilter.timePeriod
        )
      ) {
        let times = []
        switch (this.incomeCountFilter.timePeriod) {
          case "weekly":
            generateWeekDaysV2(times, this.incomeCountData)
            break
          case "monthly":
            generateMonthsV2(times, this.incomeCountData)
            break
          case "hourly":
            generateHoursV2(times, this.incomeCountData)
            break
        }

        return times
      }
      return this.incomeCountData.map((item) => item.dateWithYear)
    },
  },
  methods: {
    clearIncomeCountData() {
      this.incomeCountStatus = false
      this.incomeCountData = []
    },
    changeIncomeCountChartFilter(item) {
      this.incomeCountFilter = item
      this.clearIncomeCountData()
      this.getIncomeCountData(item)
    },
    async getIncomeCountData(item) {
      const response = await kerdarServices(
        this.$http,
        this
      ).overView.httpOverViewSuccessDeposit(item)

      this.updateKeyCounter++

      const {
        data: { data },
      } = response

      changeHorizontalWeeklyFirstDayV2(data)
      this.incomeCountData = data
      this.incomeCountStatus = true
    },
  },
}
</script>

<style lang="scss" scoped></style>
