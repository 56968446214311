<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$store.state.isUser" class="pr-0">
        شباهای من
      </v-toolbar-title>
      <v-toolbar-title v-else class="pr-0"> شباهای تسویه</v-toolbar-title>
      <v-spacer />
      <v-btn text dark class="btn_small_normal" @click="open_addsheba()">
        افزودن شماره شبا
      </v-btn>
    </v-toolbar>
    <v-layout column>
      <SearchInputVandar
        v-model="search"
        input-class="iban-search-box"
        place-holder="جستجوی شبا، نام صاحب حساب"
        class="mb-8"
      />

      <v-flex>
        <v-card flat>
          <v-layout class="shebaList" column>
            <!-- skeleton -->
            <template v-if="!ibansLoad">
              <v-flex v-for="index in 2" :key="index" style="width: 100%">
                <v-layout align-center>
                  <v-flex
                    class="text-justify"
                    style="
                      max-width: 60px;
                      width: 60px;
                      height: 63px;
                      margin: 0px 0px 0px 10px;
                      border: 1px solid #eeeeee;
                    "
                  />
                  <v-flex>
                    <div class="font_2 bold" style="max-width: 50%">
                      <span class="default_gray" />
                    </div>
                    <div class="font_2">
                      <span class="default_gray" />
                    </div>
                  </v-flex>
                  <v-flex class="d-flex justify-end align-center">
                    <v-btn disabled icon size="24" fab small style="margin: 0">
                      <v-icon
                        :color="'van_color03'"
                        size="21"
                        style="line-height: 40px"
                      >
                        delete_outline
                      </v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-divider v-if="index < 2" />
              </v-flex>
            </template>
            <!-- add iban -->
            <v-flex v-if="ibansLoad && ibans.length == 0">
              <v-layout align-center>
                <v-flex style="max-width: 56px; margin-left: 10px">
                  <v-avatar color="van_color11" size="56">
                    <v-icon
                      class="justify-center"
                      dark
                      size="24"
                      color="van_color05"
                    >
                      image_aspect_ratio
                    </v-icon>
                  </v-avatar>
                </v-flex>
                <v-flex>
                  <div v-if="search" class="font_2">
                    شماره شبایی متناسب با مقدار وارد شده پیدا نشد
                  </div>
                  <div v-else class="font_2">
                    هنوز شماره شبایی ذخیره نشده است
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- iban list -->
            <v-flex
              v-for="(iban, index) in ibans"
              v-else
              :key="index"
              style="width: 100%"
            >
              <v-layout align-center>
                <v-flex
                  style="
                    max-width: 60px;
                    padding: 15px;
                    margin-left: 10px;
                    border: 1px solid #eeeeee;
                  "
                >
                  <v-img
                    width="30"
                    :src="
                      require(`@/assets/img/banks/${parseInt(
                        iban.IBAN.slice(4, 7)
                      )}.svg`)
                    "
                  />
                </v-flex>
                <v-flex>
                  <div
                    v-if="
                      iban.hasOwnProperty('account_owner') &&
                      iban.account_owner.length > 0
                    "
                    class="font_2 bold"
                  >
                    {{ iban.account_owner[0]?.firstName ?? "" }}
                    {{ iban.account_owner[0]?.lastName ?? "" }}
                  </div>
                  <div class="font_2">
                    {{ iban.IBAN }}
                  </div>
                </v-flex>
                <v-spacer />
                <v-flex style="max-width: 40px">
                  <v-btn
                    icon
                    size="24"
                    small
                    style="margin: 0"
                    @click="deleteIban(iban?.id, index)"
                  >
                    <v-icon
                      :color="'van_color03'"
                      size="21"
                      style="line-height: 40px"
                    >
                      delete_outline
                    </v-icon>
                  </v-btn>
                </v-flex>
                <observer
                  v-if="index == ibans.length - 1 && !endOfLoadMore"
                  @intersect="intersected"
                />
              </v-layout>
              <v-divider v-if="index + 1 < ibans.length" />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status1"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <addSheba
        :key="componentKey"
        @closeModal="close_modal"
        @setIban="set_iban"
      />
    </v-dialog>

    <v-dialog
      v-model="deleteStatus"
      width="360"
      content-class="confirmationMessage confirmSimple"
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex class="main-title font_1">
            از حذف شماره شبا اطمینان دارید؟
          </v-flex>
          <v-flex>
            <v-btn text dark class="btn_small_normal" @click="cancelDelete">
              خیر، حذف نشود
            </v-btn>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="finalDelete()"
            >
              بلی، حذف شود
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import observer from "../elements/observer.vue"
import addSheba from "./addSheba.vue"
import SearchInputVandar from "@/components/elements/searchInputVandar.vue"
import { mapMutations } from "vuex"

export default {
  name: "SettingFinancial",
  components: {
    SearchInputVandar,
    addSheba,
    observer,
  },
  props: [],
  data() {
    return {
      componentKey: 0,
      ibans: [],
      ibansLoad: false,
      status1: false,
      deleteStatus: false,
      deletedSelection: {
        id: null,
        index: null,
      },
      currentPage: 1,
      endOfLoadMore: false,
      defaultIbanInShaparak: null,
      search: "",
      timeOut: null,
    }
  },
  watch: {
    status1: function (newValue) {
      if (!newValue) this.$store.commit("callSecondModal", false)
    },
    search(newValue) {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }
      // setTimeOut for search
      if (newValue) {
        this.timeOut = setTimeout(() => {
          this.ibansLoad = false
          this.currentPage = 0
          this.ibans = []
          this.intersected()
        }, 1500)
      } else {
        this.ibansLoad = false
        this.currentPage = 0
        this.ibans = []
        this.intersected()
      }
    },
  },
  mounted() {
    this.ibansLoad = false
    this.getIbanList()
  },
  methods: {
    ...mapMutations("newbusiness", ["changeBusinessKey"]),
    getIbanList() {
      this.$http
        .get(this.$store.getters.ibanApiUrlParam)
        .then((response) => {
          this.ibansLoad = true
          this.ibans = response.data.data.data
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    intersected() {
      let cur = this.currentPage + 1
      let url = this.$store.getters.ibanApiUrlParam + "?page=" + cur
      if (this.search) {
        url += `&q=${this.search}`
      }
      this.$http
        .get(url)
        .then((response) => {
          var temporaryList = this.ibans

          response.data.data.data.forEach((element) => {
            temporaryList.push(element)
          })

          this.ibans = temporaryList

          this.currentPage = response.data.data.meta.current_page
          if (
            response.data.data.meta.last_page ==
            response.data.data.meta.current_page
          ) {
            this.endOfLoadMore = true
          }
          this.ibansLoad = true
        })
        .catch(() => {
          this.ibansLoad = true
        })
    },
    open_addsheba() {
      this.close_modal()
      this.$store.commit("callSecondModal", true)
      this.status1 = true
    },
    close_modal() {
      this.status1 = false
      this.componentKey++
    },
    set_iban(value) {
      this.ibans = value
      this.endOfLoadMore = false
    },
    deleteIban(id, index) {
      this.deletedSelection = {
        id: id,
        index: index,
      }
      this.deleteStatus = true
    },
    cancelDelete() {
      this.resetDeleteSelection()
      this.deleteStatus = false
    },
    resetDeleteSelection() {
      this.deletedSelection = {
        id: null,
        index: null,
      }
    },
    finalDelete() {
      this.$http
        .delete(
          this.$store.getters.ibanApiUrlParam + "/" + this.deletedSelection.id,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          const {
            data: {
              results: { message },
            },
          } = response.data
          this.$store.commit("callSuccessSnackbar", message)
          this.$delete(this.ibans, this.deletedSelection.index)
          this.resetDeleteSelection()
          this.deleteStatus = false
        })
        .catch((error) => {
          //callsnackbar
        })
    },
  },
}
</script>
