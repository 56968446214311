<template>
  <left-modal
    ref="leftModal"
    toolbar-name="انتخاب حساب بانکی"
    @closeModal="$emit('closeModal', 'chooseBank')"
  >
    <div slot="toolbarItems">
      <v-btn
        :disabled="!selectedBank"
        class="btn_small_normal"
        dark
        text
        @click="openMandateModal"
      >
        ادامه
      </v-btn>
    </div>
    <general-card>
      <div
        :class="{ 'd-flex justify-center': $vuetify.breakpoint.xsOnly }"
        class="font_2 mb-8"
      >
        کدام بانک را به حساب کاربری وندارتان متصل می‌کنید؟
      </div>

      <v-item-group>
        <v-layout
          :justify-center="$vuetify.breakpoint.smAndDown"
          :align-center="$vuetify.breakpoint.smAndDown"
          style="gap: 9px"
          wrap
        >
          <!-- bank list-->
          <template v-if="!skeleton">
            <v-flex
              v-for="(bank, index) in bankList"
              :key="'bank' + index"
              class="pa-0"
              style="flex: 0 0 103px !important"
              @click="cardClick(bank)"
            >
              <bank-card :bank="bank" :selectedbank="selectedBank" />
            </v-flex>
          </template>

          <!--  skeleton  -->
          <template v-else>
            <v-flex
              v-for="(skeleton, i) in 10"
              :key="'skeleton' + i"
              class="pa-0"
              style="flex: 0 0 103px !important"
            >
              <bank-card-skeleton />
            </v-flex>
          </template>
        </v-layout>
      </v-item-group>
    </general-card>

    <general-card>
      <v-flex class="info_panel pbv_info_panel">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0" style="min-height: 36px">
              <div class="font_2">بانک من اینجا نیست!</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="font_10 text-right pt-4">
                به زودی بانک‌های دیگر به «پرداخت با یک کلیک» اضافه می‌شوند و
                می‌توانید پرداخت‌های خود را از حساب مورد نظرتان انجام دهید.
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>
    </general-card>

    <v-dialog
      v-if="secondMandateModal"
      v-model="secondMandateModal"
      content-class="confirmationMessage withdata4row"
      width="360"
    >
      <v-card class="pa-8" height="100%">
        <v-layout align-center column justify-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/newIcon/error_close.svg')"
              height="63"
              width="63"
            />
          </v-flex>
          <v-flex class="font_29 mt-4 mb-2 bold">
            در این بانک یک حساب متصل دارید
          </v-flex>
          <v-flex class="font_10 mb-4 text-center">
            برای تغییر حساب خود در این بانک، ابتدا باید اتصال فعلی را لغو کنید.
          </v-flex>
          <v-layout
            align-center
            class="pa-5"
            style="background-color: #f5f5f5; width: 100%"
          >
            <v-flex
              style="
                max-width: 60px;
                padding: 15px;
                margin-left: 10px;
                border: 1px solid #eeeeee;
                background-color: white;
              "
            >
              <v-img
                :src="
                  require(`@/assets/img/banks/${parseInt(
                    selectedBank.code
                  )}.svg`)
                "
                width="30"
              />
            </v-flex>
            <v-flex style="text-align-last: right">
              <div class="font_2 bold pb-2">
                {{ selectedBank.mandate.bank_name }}
              </div>
              <div class="font_30">
                سقف
                {{ selectedBank.mandate.limit | currencyWithRialInput }}
                {{ currencyName }}
                تا
                {{ selectedBank.mandate.expiration_date }}
              </div>
            </v-flex>
          </v-layout>
          <v-flex>
            <v-btn
              :loading="deleteButtonLoading"
              class="btn_small_normal error"
              depressed
              text
              @click="finalDelete()"
            >
              لغو اتصال حساب فعلی
            </v-btn>
            <v-btn
              class="btn_small_normal"
              dark
              outlined
              text
              @click="cancelRevoking"
            >
              بازگشت
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="mandateWasRevoked"
      v-model="mandateWasRevoked"
      content-class="confirmationMessage"
      no-click-animation
      persistent
      width="360"
    >
      <v-card height="100%">
        <v-layout align-center column justify-center>
          <v-flex>
            <div style="position: relative">
              <v-img
                :src="
                  require(`@/assets/img/banks/${parseInt(
                    selectedBank.code
                  )}.svg`)
                "
                style="position: absolute; top: 22px; right: 23px"
                width="20"
              />
              <svg
                fill="none"
                height="63"
                viewBox="0 0 64 63"
                width="64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.88353 57.1356C8.18007 60.9173 12.6714 63 17.5301 63C18.332 63 19.1537 62.9435 19.9722 62.8321C31.6765 61.2392 42.1195 59.0562 42.5188 58.9724C43.9145 58.6099 45.3177 58.2838 46.6748 57.9684C51.545 56.8365 56.1451 55.7674 59.1796 53.2982C62.5127 50.5859 63.6245 46.4215 62.6785 40.1928C60.7381 27.4157 59.2617 21.441 58.7673 19.6215L58.3657 18.0215C56.93 11.9598 54.7238 7.5734 51.6353 4.63129C48.3643 1.51525 44.0759 0.000509977 38.5253 0.000509977L38.5205 0.000499994L38.516 0.000419974C38.5133 0.000369996 38.4901 0 38.4467 0C37.7219 0 31.0524 0.1078 20.2023 3.84161C15.2484 5.54639 9.6545 7.72072 5.72624 10.9216C3.84316 12.456 2.46737 14.1077 1.52026 15.9709C0.49728 17.9833 0 20.1879 0 22.7104V43.986C0 48.7825 1.77998 53.5753 4.88353 57.1356Z"
                  fill="#F5F5F5"
                />
              </svg>
            </div>
          </v-flex>
          <v-flex class="main-title font_29 bold"> اتصال حساب لغو شد </v-flex>
          <v-flex class="main-des font_4">
            حالا می‌توانید حساب دیگری در {{ selectedBank.bank_name }} را متصل
            کنید.
          </v-flex>
          <v-flex>
            <v-btn
              class="btn_small_normal mt-3"
              dark
              text
              @click="closeAfterMandateRevoked()"
            >
              فهمیدم
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </left-modal>
</template>

<script>
import leftModal from "@/components/modals/leftModal"
import generalCard from "@/components/elements/generalCard.vue"
import BankCard from "@/components/pbv/modals/bankCard"
import BankCardSkeleton from "@/components/pbv/modals/bankCardSkeleton"

export default {
  name: "ChooseBank",
  components: {
    BankCardSkeleton,
    BankCard,
    leftModal,
    generalCard,
  },
  data() {
    return {
      bankList: [],
      skeleton: true,
      selectedBank: null,
      secondMandateModal: false,
      deleteButtonLoading: false,
      mandateWasRevoked: false,
    }
  },
  mounted() {
    this.getBankList()
  },
  methods: {
    cardClick(bank) {
      this.selectedBank = bank
      if (this.selectedBank.mandate) {
        this.secondMandateModal = true
      }
    },
    getBankList() {
      this.$http
        .get(this.$store.state.api_pbv_url + "/user/banks", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.skeleton = false
          this.bankList = response?.data?.data?.banks ?? []
          this.bankList.sort((a, b) => {
            if (a.mandate == null) {
              return -1
            }
          })
        })
    },
    openMandateModal() {
      this.$emit("openMandateModal", this.selectedBank)
    },
    closeAfterMandateRevoked() {
      this.mandateWasRevoked = false
      this.$emit("openMandateModal", this.selectedBank)
    },
    finalDelete() {
      this.deleteButtonLoading = true
      this.$http
        .delete(
          this.$store.state.api_pbv_url +
            "/user/mandates/" +
            this.selectedBank.mandate.uuid,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.selectedBank.mandate = null
          this.secondMandateModal = false
          this.deleteButtonLoading = false
          this.mandateWasRevoked = true
        })
        .catch((error) => {})
    },
    cancelRevoking() {
      this.selectedBank = null
      this.secondMandateModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-wrap {
  flex-wrap: wrap;
}

.border_eee {
  border: 1px solid;
  border-color: $van_color10 !important;
}

.border_f5 {
  border-color: $van_color11 !important;
}

.border_d5 {
  border-color: #d5d5d5 !important;
}
</style>

<style lang="scss">
.pbv_info_panel.info_panel {
  padding: 4px !important;

  .font_10 {
    line-height: 24px;
  }

  .v-expansion-panel__header {
    min-height: 30px;
    padding: 0;
  }
}
</style>
