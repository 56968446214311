<template>
  <div class="add-business">
    <v-radio-group v-model="isBusinessHaghighi" row class="simpleRadio">
      <div
        class="font_28 ml-7"
        :style="
          isMobileMode ? 'width:100%; text-align:right;margin-bottom:16px' : ''
        "
      >
        نوع کسب‌و‌کار
      </div>
      <v-radio
        :style="isMobileMode ? 'margin-right:0' : ''"
        :value="true"
        color="van_green"
      >
        <template #label>
          <span class="font-12-bo-000-24">حقیقی</span>
        </template>
      </v-radio>
      <v-radio :value="false" class="mr-7" color="van_green">
        <template #label>
          <span class="font-12-bo-000-24">حقوقی</span>
        </template>
      </v-radio>
    </v-radio-group>

    <v-divider
      class="van_color05--text"
      style="font-size: 2px; border-style: dashed; margin: 20px 0 20px"
    />
    <v-form ref="form" v-model="valid" class="right-error">
      <v-layout :column="isMobileMode" justify-start align-start>
        <v-flex class="label font-11-no-999-18">
          نام برند <span>*</span>
        </v-flex>
        <v-flex class="withlabel big">
          <v-text-field
            ref="business_name_fa"
            v-model.trim="business_name_fa"
            :rules="business_name_fa_Rules"
            :error-messages="businessPersianNameMessage"
            solo
            flat
            height="40"
            outlined
            placeholder="مثال: وندار"
            validate-on-blur
            @focus="$emit('businessNameFaIsFocused')"
            @blur="$emit('businessNameFaIsBlurred')"
            @keydown="persianKeyHandler($event)"
          >
            <template #message="message">
              <div class="error-message" v-html="message.message"></div>
            </template>
          </v-text-field>
          <transition v-if="isMobileMode" name="list">
            <div
              v-show="
                $refs.business_name_fa && $refs.business_name_fa.isFocused
              "
              class="align-center max-width-250"
            >
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                نام کوتاه و اختصاری برند است </span
              ><br />
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                نام باید خاص و منحصر به فرد باشد </span
              ><br />
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                ۲۰ کاراکتر اول در درگاه پرداخت شما نمایش داده می‌شود </span
              ><br />
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                تا پیش از ثبت تراکنش، این نام قابل ویرایش است
              </span>
            </div>
          </transition>
        </v-flex>
      </v-layout>

      <v-layout
        :column="isMobileMode"
        justify-start
        align-start
        class="showDescription adding_vandarId"
      >
        <v-flex class="label font-11-no-999-18">
          نام انگلیسی برند <span>*</span>
        </v-flex>
        <v-flex class="withlabel big">
          <v-text-field
            ref="businessName"
            v-model.trim="business_name"
            placeholder="Vandar :مثال"
            :rules="business_name_Rules"
            :error-messages="business_name_message"
            solo
            flat
            height="40"
            outlined
            @focus="$emit('businessNameIsFocused')"
            @blur="$emit('businessNameIsBlurred')"
          >
            <template #message="message">
              <div class="error-message" v-html="message.message"></div>
            </template>
          </v-text-field>
          <transition v-if="isMobileMode" name="list">
            <div
              v-show="$refs.businessName && $refs.businessName.isFocused"
              class="align-center max-width-250"
            >
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                نام کوتاه و اختصاری برندتان به انگلیسی است
              </span>
              <br />
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                این نام پس از ثبت غیر قابل ویرایش است
              </span>
              <br />
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                فراخوانی سرویس‌های شما با این نام انجام می‌شود
              </span>
              <br />
            </div>
          </transition>
        </v-flex>
      </v-layout>

      <v-layout
        v-if="!isBusinessHaghighi"
        :column="isMobileMode"
        justify-start
        align-start
      >
        <v-flex class="label font-11-no-999-18">
          نام حقوقی <span>*</span>
        </v-flex>
        <v-flex class="withlabel big">
          <v-text-field
            ref="legal_business_name"
            v-model.trim="legal_business_name"
            :rules="legal_business_name_Rules"
            solo
            flat
            height="40"
            outlined
            placeholder="مثال: تجارت الکترونیک ارسباران"
            validate-on-blur
            @focus="$emit('legalBusinessNameIsFocused')"
            @blur="$emit('legalBusinessNameIsBlurred')"
          >
            <template #message="message">
              <div class="error-message" v-html="message.message"></div>
            </template>
          </v-text-field>
          <transition v-if="isMobileMode" name="list">
            <div
              v-show="
                $refs.legal_business_name && $refs.legal_business_name.isFocused
              "
              class="align-center max-width-250"
            >
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                این نام باید در روزنامه رسمی شما ثبت شده باشد
              </span>
              <br />
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                نام حقوقی مطابق نامی که در سامانه مالیاتی و اینماد ثبت شده
                می‌باشد
              </span>
              <br />
            </div>
          </transition>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!isBusinessHaghighi"
        :column="isMobileMode"
        justify-start
        align-start
      >
        <v-flex class="label font-11-no-999-18">
          شناسه ملی <span>*</span>
        </v-flex>
        <v-flex class="withlabel big">
          <nationalId
            :input-value="national_id"
            @setValue="national_id = $event"
          />
          <transition v-if="isMobileMode" name="list">
            <div
              v-show="$store.state.isNationalIdFocused"
              class="align-center max-width-250"
            >
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                این نام باید در روزنامه رسمی شما ثبت شده باشد
              </span>
              <br />
              <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                info_outline
              </v-icon>
              <span class="font-11-no-bbb-18">
                نام حقوقی مطابق نامی که در سامانه مالیاتی و اینماد ثبت شده
                می‌باشد
              </span>
              <br />
            </div>
          </transition>
        </v-flex>
      </v-layout>

      <v-layout :column="isMobileMode" justify-start align-start>
        <v-flex class="label font-11-no-999-18">
          حوزه فعالیت <span>*</span>
        </v-flex>

        <v-flex class="withlabel big">
          <v-autocomplete
            :key="forceUpdater"
            v-model="mcc_code"
            :rules="mcc_code_Rules"
            :items="senfs"
            item-text="value"
            item-value="key"
            solo
            flat
            height="40"
            outlined
            single-line
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            no-data-text="اطلاعاتی برای نمایش موجود نیست"
            placeholder="حوزه مورد نظر را جست‌وجو کنید"
            @click="mccFieldIsClicked"
            @blur="mccFieldIsBlurred"
          />
        </v-flex>
        <transition v-if="isMobileMode" name="list">
          <div
            v-show="isShowMccCodeGuidance"
            class="align-center max-width-250"
          >
            <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
              info_outline
            </v-icon>
            <span class="font-11-no-bbb-18">
              کد صنف ترمینال شما نزد شاپرک است
            </span>
            <br />
            <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
              info_outline
            </v-icon>
            <span class="font-11-no-bbb-18">
              حوزه فعالیت باید مطابق با اطلاعات سامانه مالیات و اینماد شما باشد
            </span>
            <br />
            <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
              info_outline
            </v-icon>
            <span class="font-11-no-bbb-18">
              در صورتیکه حوزه فعالیت نادرست باشد، درخواست درگاه پرداخت از سمت
              شاپرک رد می‌شود
            </span>
          </div>
        </transition>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import nationalId from "@/components/inputs/nationalId.vue"
import servicesRepository from "@/services/servicesRepository"
import { mapMutations } from "vuex"

export default {
  name: "AddBusiness",
  components: {
    nationalId,
  },
  props: {
    shouldSendUpData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      business_name_fa: "",
      businessPersianNameMessage: "",
      mcc_code: "",
      senfs: [],
      business_name: "",
      business_name_message: "",
      legal_business_name: "",
      national_id: null,
      valid: false,
      loading: false,
      active: null,
      businessCount: 0,
      forceUpdater: 0,
      isShowMccCodeGuidance: false,
      // validations rule
      business_name_fa_Rules: [
        (v) => !!v || "<span> نام برند الزامی است </span>",
        (v) =>
          (this.removeWhiteSpaces(v) && this.removeWhiteSpaces(v).length > 1) ||
          "<span> نام  برند حداقل باید 2 کاراکتر باشد </span>",
        (v) =>
          (v && v.length < 40) ||
          "<span> نام برند حداکثر باید 40 کاراکتر باشد </span>",
        (v) =>
          (v && this.isPersian(v)) || "<span>نام برند باید فارسی باشد </span>",
      ],
      mcc_code_Rules: [(v) => !!v || ""],
      business_name_Rules: [
        (v) => !!v || "<span> نام انگلیسی شرکت الزامی است </span>",
        (v) =>
          /^\S*$/.test(v) ||
          "<span> نام انگلیسی شرکت نباید دارای فاصله باشد </span>",
        (v) =>
          /^[a-zA-Z0-9_-]*$/.test(v) ||
          "<span> نام انگلیسی شرکت باید ترکیبی از حروف انگلیسی و اعداد و بدون فاصله باشد </span>",
        (v) =>
          (v && v.length < 40) ||
          "<span> نام انگلیسی شرکت حداکثر باید 40 کاراکتر باشد </span>",
        (v) =>
          (v && v.length > 1) ||
          "<span> نام انگلیسی شرکت حداقل باید 2 کاراکتر باشد </span>",
        //unique,
      ],
      legal_business_name_Rules: [
        (v) => !!v || "<span> وارد کردن نام الزامی است </span>",
        (v) =>
          (this.removeWhiteSpaces(v) && this.removeWhiteSpaces(v).length > 1) ||
          "<span> نام حقوقی شرکت حداقل باید 2 کاراکتر باشد </span>",
        (v) =>
          (v && v.length < 40) ||
          "<span> نام حقوقی شرکت حداکثر باید 40 کاراکتر باشد </span>",
        (v) =>
          //just farsi letters + space + half space
          (!!v && /^[ ‌آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهیئ\s]+$/.test(v)) ||
          "<span> نام حقوقی شرکت باید با حروف فارسی وارد شود </span>",
      ],
    }
  },
  computed: {
    isFormDisable() {
      if (!this.loading && this.valid) return false
      else return true
    },
    isBusinessHaghighi: {
      get() {
        return this.$store.state.newbusiness.isHaghighi
      },
      set(value) {
        this.changeStatus(value)
      },
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  watch: {
    business_name() {
      this.doClearTimeOut()
      this.timeOut = setTimeout(this.checkBusinessEnglishNameValidation(), 700)
    },
    business_name_fa() {
      this.doClearTimeOut()
      this.timeOut = setTimeout(this.checkBusinessPersianNameValidation(), 700)
    },
    isBusinessHaghighi() {
      this.mcc_code = ""
      this.forceUpdater++
    },
    isFormDisable(value) {
      this.$emit("is-form-disable", value)
    },
    shouldSendUpData(value) {
      if (value) {
        this.cookBasicInfoData()
      }
    },
  },
  mounted() {
    this.getSenfs()
  },
  methods: {
    ...mapMutations("newbusiness", ["fillSenfsList", "changeStatus"]),
    getSenfs() {
      servicesRepository(this)
        .informationService.httpGetSenfs()
        .then((response) => {
          const keysList = Object.keys(response.data.data)
          const indexOfCryptocurrency = Object.values(
            response.data.data
          ).findIndex((item) => item === "خدمات رمز ارزی")

          if (indexOfCryptocurrency !== -1) {
            const keyOfCryptocurrency = keysList.splice(
              indexOfCryptocurrency,
              1
            )[0]
            keysList.splice(0, 0, keyOfCryptocurrency)
          }

          this.senfs = keysList.map(function (key) {
            return { key: key, value: response.data.data[key] }
          })
          this.fillSenfsList(this.senfs)
        })
        .catch((error) => {
          //callsnackbar
          window.location.reload()
        })
    },
    checkBusinessEnglishNameValidation() {
      if (this.$refs.businessName.validate()) {
        let data = {
          company_name: this.business_name,
        }

        servicesRepository(this)
          .checkServices.httpCheckBusinessNameUniqueness(data)
          .then((res) => {
            const {
              data: { company_name },
            } = res

            if (company_name === "IS_DUPLICATE") {
              this.business_name_message =
                "<span> این نام قبلا انتخاب شده است </span>"
            } else {
              this.business_name_message = null
            }
          })
      } else {
        this.business_name_message = null
      }
    },
    checkBusinessPersianNameValidation() {
      if (this.$refs.business_name_fa.validate()) {
        let data = {
          company_name_fa: this.business_name_fa,
        }

        servicesRepository(this)
          .checkServices.httpCheckBusinessNameUniqueness(data)
          .then((res) => {
            const {
              data: { company_name_fa },
            } = res

            if (company_name_fa === "IS_DUPLICATE") {
              this.businessPersianNameMessage =
                "<span> این نام قبلا انتخاب شده است </span>"
            } else {
              this.businessPersianNameMessage = null
            }
          })
      } else {
        this.businessPersianNameMessage = null
      }
    },
    cookBasicInfoData() {
      let data = {
        business_type: this.isBusinessHaghighi ? "personal" : "legal",
        legal_business_name: this.legal_business_name,
        business_name: this.business_name,
        business_name_fa: this.business_name_fa,
        national_id: this.national_id,
        mcc_code: this.mcc_code,
      }

      this.$emit("business-data", data)
    },
    mccFieldIsClicked() {
      this.isShowMccCodeGuidance = true
      this.$emit("mccCodeIsFocused")
    },
    mccFieldIsBlurred() {
      this.isShowMccCodeGuidance = false
      this.$emit("mccCodeIsBlurred")
    },
    removeWhiteSpaces(text) {
      return text.replace(/^\s+|\s+$|\s+(?=\s)/g, "")
    },
    doClearTimeOut() {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
        this.timeOut = null
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.v-input {
  width: 100%;
}
.add-business {
  width: 536px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 32px;

  @media screen and (max-width: 599px) {
    width: unset;
    max-width: 536px;
  }

  @media screen and (min-width: 600px) and (max-width: 960px) {
    width: 500px;
    padding: 32px 20px;
  }
}
.business_name_loading {
  position: absolute;
  left: -30px;
  top: 10px;
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}

.error-message {
  position: relative;
  z-index: 2;
}

.max-width-250 {
  max-width: 250px !important;
}
</style>
<style>
.v-autocomplete__content.v-menu__content {
  width: 348px !important;
  min-width: unset !important;
}

@media screen and (max-width: 600px) {
  .v-autocomplete__content.v-menu__content {
    width: 250px !important;
  }
}
</style>
