const ipg = {
  state() {
    return {
      //$store.state.ipg.api_key
      urls: [],
      url: null,
      ips: [],
      api_key: "",
      wage: true,
      vip: true,
      payment: true,
      status: 0,
      transactions_count: 0,
      transactions_sum: 0,
      ipgFormSubmitBtnActiveState: false,
      isOpenIpgModal: false,
    }
  },
  mutations: {
    addIpg(state, obj) {
      state.url = obj.url
      state.website_name = obj.website_name
      state.modified_ipg_fields = obj.modified_ipg_fields
      state.urls = obj.urls
      state.ips = obj.ips
      state.api_key = obj.api_key
      state.wage = obj.wage
      state.vip = obj.vip
      state.payment = obj.payment
      state.status = obj.status
      state.transactions_count = obj.transactions_count
      state.transactions_sum = obj.transactions_sum
    },
    updateIpg(state, obj) {
      state.urls = obj.urls
      state.url = obj.url
      state.website_name = obj.website_name
      state.ips = obj.ips
      state.wage = obj.wage
      state.vip = obj.vip
      state.modified_ipg_fields = obj.modified_ipg_fields
    },
    destroyIpg(state) {
      ;(state.urls = []),
        (state.url = null),
        (state.website_name = null),
        (state.ips = []),
        (state.api_key = ""),
        (state.wage = true),
        (state.vip = true),
        (state.payment = true),
        (state.status = 0),
        (state.transactions_count = 0),
        (state.transactions_sum = 0),
        delete state.modified_ipg_fields
    },
    changeIpgFormSubmitBtnActiveState(state, ipgFormSubmitBtnActiveState) {
      state.ipgFormSubmitBtnActiveState = ipgFormSubmitBtnActiveState
    },
    changeIpgModalOpeningState(state, ipgModalOpeningState) {
      state.isOpenIpgModal = ipgModalOpeningState
    },
  },
  getters: {},
}

export default ipg
