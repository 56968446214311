<template>
  <v-layout column>
    <v-flex v-for="(value, index) in yValue" :key="index">
      <v-layout class="align-center">
        <v-flex style="border-left: #333333 1px solid">
          <v-tooltip top color="white">
            <template #activator="{ on, attrs }">
              <v-progress-linear
                :value="lineValue(value)"
                height="40"
                reverse
                :color="index == 0 ? 'van_green' : 'van_color05'"
                class="one-horizontal-chart"
                background-color="#F5F5F5"
                v-bind="attrs"
                v-on="index != 0 ? on : null"
              >
                <div
                  :class="[
                    'mmr-4 w-100',
                    index == 0 && lineValue(value) < 80
                      ? 'van_green--text'
                      : index == 0
                      ? 'white--text'
                      : '',
                  ]"
                  dir="rtl"
                >
                  {{
                    roundValue ? parseInt(value) : value || 0 | thousandSeprator
                  }}
                  <span v-if="value">
                    {{ currencyName }}
                  </span>
                  <span>{{ value?.length }}</span>
                </div>
              </v-progress-linear>
            </template>
            <div class="font_2 text-center" style="max-width: 230px">
              درآمد {{ xValue[index] }} نسبت به دیروز
              <span
                :class="[
                  'bold',
                  growth(index) >= 0 ? 'van_green--text' : 'van_red--text',
                ]"
              >
                <span v-if="growth(index) > 0">
                  {{ growth(index) }}٪ بیشتر</span
                >
                <span v-else-if="growth(index) < 0">
                  {{ Math.abs(growth(index)) }}٪ کمتر</span
                >
                <span v-else> بدون تغییر </span>
              </span>
              بوده است
            </div>
          </v-tooltip>
        </v-flex>
        <v-flex
          style="max-width: 170px"
          :class="['font_5 mmr-3', index == 0 ? 'van_green--text' : '']"
        >
          {{ xValue[index] }}
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "OneBarHorizontalBar",
  props: {
    xValue: {
      type: Array,
      default: () => [],
    },
    yValue: {
      type: Array,
      default: () => [],
    },
    roundValue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    growth(index) {
      const growthPercent =
        ((this.yValue[index] - this.yValue[0]) / this.yValue[index]) * 100
      return Math.floor(growthPercent)
    },
    lineValue(value) {
      const max = Math.max(...this.yValue)

      return this.roundValue
        ? parseInt(((value || 0) / max) * 100)
        : ((value || 0) / max) * 100
    },
  },
}
</script>

<style lang="scss">
.one-horizontal-chart {
  margin: 14px 0;
  &:hover {
    .v-progress-linear__determinate {
      background-color: $van_color03 !important;
    }
  }
  .v-progress-linear {
    &__determinate {
      border-radius: 0 8px 8px 0;
    }
  }
}
</style>
