<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">ویرایش نام برند</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          :disabled="!valid"
          class="btn_small_normal"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex>
        <v-form ref="form" v-model="valid">
          <div class="font_2">نام برند را مطابق با روزنامه رسمی وارد کنید</div>
          <v-divider class="mb-7" style="border-style: dashed" />
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              نام برند
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="business_name_fa"
                :rules="business_name_fa_Rules"
                solo
                flat
                height="40"
                outlined
                @keydown="persianKeyHandler($event)"
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "AddBusinessNameFa",
  data: function () {
    return {
      valid: false,
      business_name_fa: null,
      loading: false,
      business_name_fa_Rules: [
        (v) => !!v || "<span> نام برند الزامی است </span>",
        (v) =>
          (v && v.length > 2) ||
          "<span> نام  برند حداقل باید 3 کاراکتر باشد </span>",
        (v) =>
          (v && v.length < 40) ||
          "<span> نام برند حداکثر باید 40 کاراکتر باشد </span>",
      ],
    }
  },

  methods: {
    sendForm() {
      this.loading = true
      var data = {
        company_name_fa: this.convertNumbers2English(this.business_name_fa),
      }
      this.$http
        .post(
          this.$store.getters.apiUrlParam + "/update/company-name-fa",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.commit("callSuccessSnackbar", response.data.message)
          this.$store.commit("updateBusinessNameFa", this.business_name_fa)
          this.$emit("closeModal")
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
  },
}
</script>
