<template>
  <v-layout
    v-if="totalPage > 1"
    align-center
    fill-height
    style="height: 60px"
    class="van_color13"
  >
    <v-flex class="font_14 mr-7" style="text-align: right">
      {{ totalItems }} {{ label }}
    </v-flex>
    <v-flex style="text-align: left; padding-top: 15px; padding-bottom: 15px">
      <v-pagination
        v-model="currentPage"
        :length="totalPage"
        :total-visible="5"
        color="#4c9f8733"
        class="text--van_color02"
      />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    totalPage: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 1,
    },
    pageProps: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: "برداشت",
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.pageProps
      },
      set(newValue) {
        this.$emit("pageChanged", newValue)
      },
    },
  },
  watch: {},
}
</script>
