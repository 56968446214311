<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">
        <span>کیف‌پول داخلی چیست؟</span>
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-card flat>
          <v-layout>
            <v-flex class="font_30" style="text-align: justify">
              بسیاری از کسب‌وکارهایی که به یک کیف پول برای مشتریان خود نیاز
              دارند، با چالش‌های گوناگونی در زمینه توسعه، حسابداری و مغایرت‌گیری
              کیف پول‌های کاربران‌شان مواجه می‌شوند. راهکار وندار برای مقابله با
              این مسائل، ساخت کیف پول ریالی به ازای هر مشتری است.
              <p />
              کسب‌وکارها با استفاده از کیف پول ریالی وندار می‌توانند موجودی کیف
              پول کاربران خود را به سادگی مدیریت کنند. در واقع، کیف پول ریالی
              وندار، دفتر حساب داخلی شما با مشتری است که مقدار آن توسط وب سرویس
              یا به شکل دستی تغییر می‌کند. در حال حاضر، تراکنش‌های واریز و
              برداشت از درگاه، تسهیم یا دیگر ابزارها، تاثیری در مانده حساب کیف
              پول ندارد.
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "WalletDescription",
}
</script>
