<template>
  <div v-if="showSkeleton">
    <v-skeleton-loader class="mb-5" type="text"></v-skeleton-loader>
    <v-skeleton-loader
      v-for="i in 6"
      :key="i"
      :class="`${i === 1 ? 'mb-3' : ''} `"
      max-height="60"
      type="card"
    ></v-skeleton-loader>
  </div>
  <div v-else>
    <div class="font_2 mb-4">
      تراکنش‌ها تا
      {{
        jalaliDate(
          selectedSettlement.cancelable_datetime * 1000,
          "jYYYY/jM/jD - HH:mm"
        )
      }}
      امکان لغو دارند.
    </div>
    <vanCardWithCheckbox
      class="mb-4"
      border="1px solid #eee"
      hover-shadow="0"
      background-color="#f1f1f1"
      :checkbox-label="selectAllLabel"
      :checked-status="selectAllStatus"
      :is-indeterminate="isIndeterminate"
      @onChange="selectAllAction($event)"
    >
      <v-btn
        v-if="cancelList.length"
        text
        dark
        outlined
        class="btn_small_normal ma-0 mr-auto"
        @click="showConfirmCancelModal = true"
        >لغو</v-btn
      ></vanCardWithCheckbox
    >
    <vanCardWithCheckbox
      v-for="(settlement, index) in pendingSettlements"
      :key="settlement.id + index"
      :checked-status="isSelected(settlement.id)"
      :checkbox-label="handleAmount(settlement.amount)"
      class="checkbox-card"
      border="1px solid #eee"
      border-radius="0"
      hover-shadow="0"
      @onChange="onChange($event, settlement)"
    >
      <div
        class="d-flex w-100 text-truncate justify-space-between"
        :class="{
          'flex-column justify-start': reuseIsMobile,
          'align-center': !reuseIsMobile,
        }"
      >
        <span v-if="settlement.iban_owner_name" class="font_2">
          {{ settlement.iban_owner_name }}
        </span>
        <span v-else class="font_2"> استعلام نام...</span>
        <div
          class="font_2 d-flex align-center"
          :class="{ 'd-flex': reuseIsMobile }"
        >
          <span> {{ settlement.iban }} </span>

          <v-img
            width="16"
            max-height="16"
            max-width="16"
            :class="{ 'align-self-center': reuseIsMobile }"
            class="mr-1"
            :src="
              require(`@/assets/img/banks/${parseInt(
                settlement.iban.slice(4, 7)
              )}.svg`)
            "
          />
        </div>
      </div>
    </vanCardWithCheckbox>

    <paginationComponent
      :page-props="page"
      :total-items="totalItems"
      :total-page="totalPage"
      @pageChanged="pageChanged($event)"
    ></paginationComponent>

    <general-dialog
      :active="showConfirmCancelModal"
      logo="newIcon/error_close.svg"
      :title="cancelModalTitle"
      :description="cancelModalDescription"
      content-class="confirmationMessageDialog"
      btn1-text="بله، لغو شود"
      :btn1-loading="loadingBtn"
      btn1-class="btn_small_normal error"
      btn2-text="خیر، منصرف شدم"
      btn2-class="btn_small_normal"
      @btn1Clicked="cancelSelectedSettlements()"
      @btn2Clicked="showConfirmCancelModal = false"
    >
      <template slot="extra">
        <div class="pa-5 van_color11 mt-3 w-100">
          <div
            class="d-flex justify-space-between font_31 van_color04--text pb-3 dashed-border"
          >
            <span v-if="!allItemsAreSelected"
              >تعداد برداشت‌های انتخاب شده
            </span>
            <span v-else>تعداد برداشت‌ها</span>
            <span class="font_7">
              {{ cancelList.length }}
              <span v-if="allItemsAreSelected">تراکنش</span></span
            >
          </div>
          <div
            class="d-flex justify-space-between font_31 van_color04--text pt-3"
          >
            <span>جمع مبلغ</span>
            <span class="font_7">
              {{ amountSum | currencyWithRialInput }}
              {{ currencyName }}
            </span>
          </div>
        </div>
      </template>
    </general-dialog>
  </div>
</template>
<script>
import vanCardWithCheckbox from "@/components/VANComponents/vanCardWithCheckbox.vue"
import generalDialog from "@/components/modals/generalDialog"
import paginationComponent from "./pagination.vue"
import screenSize from "@/mixins/screenSize.js"

export default {
  components: { vanCardWithCheckbox, paginationComponent, generalDialog },
  mixins: [screenSize],
  props: {
    selectedSettlement: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showSkeleton: true,
      page: 1,
      totalItems: 0,
      totalPage: 1,
      pendingSettlements: [],
      allPendingIds: [],
      cancelList: [],
      selectAllStatus: false,
      loadingBtn: false,
      showConfirmCancelModal: false,
      amountSum: 0,
      totalPenningAmount: 0,
    }
  },
  computed: {
    selectAllLabel() {
      let selectedCount =
        this.cancelList.length > 0 ? `${this.cancelList.length} از ` : ""
      return selectedCount + `${this.totalItems}  تراکنش`
    },
    allItemsAreSelected() {
      return this.cancelList.length === this.totalItems
    },
    cancelModalTitle() {
      return this.allItemsAreSelected
        ? "برداشت گروهی لغو شود؟"
        : "تراکنش‌های انتخاب شده، لغو شود؟"
    },
    cancelModalDescription() {
      return this.allItemsAreSelected
        ? "در صورت تایید، این برداشت گروهی لغو می‌شود."
        : "در صورت تایید، تراکنش‌های انتخاب شده به بانک ارسال نمی‌شوند."
    },
    isIndeterminate() {
      return this.cancelList.length > 0 && !this.allItemsAreSelected
    },
  },
  watch: {
    page: function () {
      this.getPendingSettlements()
    },
  },
  mounted() {
    this.getPendingSettlements()
    this.getBatchData()
    this.getAllSettlementIds()
  },
  methods: {
    getBatchData() {
      this.$http
        .get(
          this.$store.getters.apiUrlBatchV2 +
            "/batches/" +
            this.selectedSettlement.batch_id,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.totalPenningAmount = response?.data?.status.pending_amount ?? 0
        })
    },
    handleAmount(amount) {
      return `${this.$options.filters.thousandSeprator(
        this.$options.filters.currencyWithRialInput(amount)
      )} ${this.currencyName}`
    },
    onChange(newValue, settlement) {
      if (!settlement.id || !settlement.amount) return

      if (newValue) {
        this.cancelList.push(settlement.id)
        this.amountSum += parseInt(settlement.amount)
      } else {
        let index = this.cancelList.indexOf(settlement.id)

        if (index !== -1) {
          this.cancelList.splice(index, 1)
          this.amountSum -= parseInt(settlement.amount)
        }
      }

      this.selectAllStatus = this.allItemsAreSelected
    },
    selectAllAction(isChecked) {
      this.selectAllStatus = false
      this.amountSum = 0
      this.cancelList = []

      if (isChecked) {
        this.selectAllStatus = true
        this.amountSum = parseInt(this.totalPenningAmount)
        this.cancelList = [...this.allPendingIds]
      }
    },
    pageChanged(newValue) {
      this.page = newValue
    },
    getPendingSettlements() {
      this.$http
        .get(
          this.$store.getters.apiUrlBatch +
            "/batch-settlements/" +
            this.selectedSettlement.batch_id +
            "?status=PENDING&page=" +
            this.page,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.pendingSettlements = response.data.data
          this.totalPage = response.data.meta.last_page
          this.totalItems = response.data.meta.total
          if (this.totalItems) this.showSkeleton = false
        })
        .catch((error) => {
          //callsnackbar
          this.showSkeleton = false
        })
    },
    getAllSettlementIds() {
      this.$http
        .get(
          this.$store.getters.apiUrlBatchV2 +
            "/batch-settlements-uuid/" +
            this.selectedSettlement.batch_id +
            "?status=PENDING&page=",
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response?.data?.ids) this.allPendingIds = response.data.ids
        })
    },
    isSelected(id) {
      return this.cancelList.includes(id)
    },
    cancelSelectedSettlements() {
      this.loadingBtn = true

      if (this.selectAllStatus) {
        this.deleteWholeRemainedSettlements()
      } else if (this.cancelList.length > 0) {
        this.deletePartially()
      }
    },
    deleteWholeRemainedSettlements() {
      const data = {
        batch_id: this.selectedSettlement.batch_id,
        exclude_ids: [],
      }
      this.$http
        .post(this.$store.getters.apiUrlBatchV2 + "/cancel-batches", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then(() => {
          this.$emit("canceledAll")
          this.afterDeleting()
        })
        .catch(() => {
          //callsnackbar
          this.loadingBtn = false
        })
    },
    deletePartially() {
      let list = []
      this.cancelList.forEach((element) => {
        list.push({ id: element })
      })

      const data = {
        batches_settlement: list,
      }
      this.$http
        .post(
          this.$store.getters.apiUrlBatchV2 + "/cancel-batches-settlement",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then(() => {
          this.$emit("goToCanceledTab")
          this.afterDeleting()
        })
        .catch(() => {
          //callsnackbar
          this.loadingBtn = false
        })
    },
    afterDeleting() {
      this.cancelList = []
      this.allPendingIds = []
      this.totalItems = 0
      this.selectAllStatus = false
      this.$emit("reloadBatchList")
      this.loadingBtn = false
      this.showConfirmCancelModal = false
      this.amountSum = 0
      this.getPendingSettlements()
      this.getAllSettlementIds()
      this.getBatchData()
    },
  },
}
</script>
<style lang="scss" scoped>
.checkbox-card {
  margin-bottom: -1px;
  @media screen and (max-width: 599px) {
    padding: 10px 12px !important;
  }
}
.dashed-border {
  border-bottom: 1px dashed $van_color05;
}
</style>
