import { render, staticRenderFns } from "./businessSetting.vue?vue&type=template&id=0c625296&scoped=true&"
import script from "./businessSetting.vue?vue&type=script&lang=js&"
export * from "./businessSetting.vue?vue&type=script&lang=js&"
import style0 from "./businessSetting.vue?vue&type=style&index=0&id=0c625296&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c625296",
  null
  
)

export default component.exports