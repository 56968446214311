<template>
  <tr class="big" @click="goToTicket">
    <td>
      <div v-if="loading">
        <v-skeleton-loader type="card-avatar"></v-skeleton-loader>
      </div>
      <div
        v-else-if="!loading && rowData.item.status"
        class="svg-wrapper"
        :style="{
          backgroundColor: returnDynamicContent(rowData.item.status)
            .iconBackgroundColor,
        }"
      >
        <v-tooltip top>
          <template #activator="{ on }">
            <span class="mt-2" v-on="on">
              <component
                :is="returnDynamicContent(rowData.item.status).componentName"
              />
            </span>
          </template>
          <span>
            {{ returnDynamicContent(rowData.item.status).persianStatus }}
          </span>
        </v-tooltip>
      </div>
    </td>
    <td style="color: #666666">
      <span v-if="loading">
        <v-skeleton-loader class="ml-4 pr-0" type="text"></v-skeleton-loader>
      </span>
      <span v-else-if="rowData.item.id">
        {{ rowData.item.id }}
      </span>
    </td>
    <td>
      <span v-if="loading">
        <v-skeleton-loader class="ml-4 pr-0" type="text"></v-skeleton-loader>
      </span>
      <span v-else-if="rowData.item.title" style="word-break: break-word">
        {{ rowData.item.title }}
      </span>
    </td>
    <td>
      <div class="category-wrapper">
        <span v-if="loading">
          <v-skeleton-loader class="ml-12 pr-0" type="text"></v-skeleton-loader>
        </span>
        <span
          v-else-if="rowData.item.category?.parent?.title"
          style="font-size: 9px; color: #bbbbbb"
        >
          {{ rowData.item.category?.parent?.title }}
        </span>
        <span v-if="loading">
          <v-skeleton-loader class="ml-4 pr-0" type="text"></v-skeleton-loader>
        </span>
        <span v-else-if="rowData.item.category?.title" style="color: #666666">
          {{ rowData.item.category?.title }}
        </span>
      </div>
    </td>
    <td>
      <span v-if="loading">
        <v-skeleton-loader class="ml-4 pr-0" type="text"></v-skeleton-loader>
      </span>
      <span v-else-if="rowData.item.created_at">
        {{ jalaliDate(rowData.item.created_at, "jYYYY/jM/jD - HH:mm") }}
      </span>
    </td>
    <td style="color: #666666">
      <span v-if="loading">
        <v-skeleton-loader class="ml-4 pr-0" type="text"></v-skeleton-loader>
      </span>
      <span v-else-if="rowData.item.updated_at">
        {{ jalaliDate(rowData.item.updated_at, "jYYYY/jM/jD - HH:mm") }}
      </span>
    </td>
  </tr>
</template>

<script>
import { returnDynamicContent } from "../utils/generalMethods"
import MessageSvg from "@/components/tickets/components/TextMessageSvg.vue"
import PendingSvg from "@/components/tickets/components/HourGlassSvg.vue"
import DoneSvg from "@/components/tickets/components/DoneSvg.vue"
import InprogressSvg from "@/components/tickets/components/InprogressSvg.vue"

export default {
  name: "TicketsListRowForDesktop",
  components: {
    MessageSvg,
    PendingSvg,
    DoneSvg,
    InprogressSvg,
  },
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      returnDynamicContent,
    }
  },
  methods: {
    goToTicket() {
      this.$emit("send-out", this.rowData)
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.category-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
