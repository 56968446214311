import XLSX from "xlsx"

export const accountantDailyReport = {
  data() {
    return {
      accountantObject: {
        count: "تعداد",
        transaction_amount: "واریز",
        total_amount: "مبلغ کل",
        settlement_amount: "برداشت",
        wage_amount: "کارمزد (با ارزش افزوده)",
        shaparak_wage: "کارمزد شاپرکی",
      },
    }
  },
  computed: {
    persianCurrency() {
      return this.$store.state.user.currency === "TOMAN" ? "تومان" : "ریال"
    },
  },
  methods: {
    getData(month, year) {
      let self = this
      return new Promise((resolve, reject) => {
        this.$http
          .get(
            this.$store.getters.apiWithVersionParam("3") +
              `/accountant/report/daily?year=${year}&month=${month}`,
            {
              timeout: 0,
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            const data = response.data.data
            if (data) {
              // sort tools based on the list in toolsList
              Object.keys(data).forEach(function (key) {
                let dayData = data[key]
                data[key] = Object.keys(dayData)
                  .sort(function (a, b) {
                    return (
                      self.getToolsInfo(a).order - self.getToolsInfo(b).order
                    )
                  })
                  .reduce((a, c) => ((a[c] = dayData[c]), a), {})
              })
              resolve(data)
            }
          })
          .catch((error) => {
            // reject(error)
          })
      })
    },
    getToolsInfo(whichTool) {
      switch (whichTool) {
        case "ipg":
          return { title: "درگاه پرداخت", order: 1 }
        case "p2p":
          return { title: "انتقال وجه", order: 2 }
        case "settlement":
          return { title: "برداشت", order: 3 }
        case "subscription":
          return { title: "پرداخت خودکار", order: 4 }
        case "cash_in":
          return { title: "واریز بانکی", order: 5 }
        case "request_money":
          return { title: "فرم پرداخت", order: 6 }
        case "refund":
          return { title: "بازگشت وجه", order: 7 }
        case "pos":
          return { title: "پایانه فروشگاهی", order: 8 }
        case "reconciliation":
          return { title: "مغایرت گیری", order: 9 }
        case "fee":
          return { title: "کارمزد", order: 10 }
        case "total":
          return { title: "جمع", order: 1000 }
        default:
          return { title: whichTool, order: 999 }
      }
    },
    getToolRowText(toolRowTitle) {
      let obj = { ...this.accountantObject }
      return obj[toolRowTitle]
    },
    getToolRowTextForExcel(toolRowTitle) {
      let excelObj = { ...this.accountantObject }
      excelObj.wage_amount = `کارمزد با ارزش افزوده(${this.persianCurrency})`
      for (const key in excelObj) {
        if (key !== "count" && key !== "wage_amount") {
          excelObj[key] += `(${this.persianCurrency})`
        }
      }
      return excelObj[toolRowTitle]
    },
    generateExcel(dataSample) {
      let dataArr = []

      for (let date in dataSample) {
        let toolObj = dataSample[date]
        for (let tool in toolObj) {
          let excelLine = {}

          excelLine["تاریخ"] = date
          excelLine["نام ابزار"] = this.getToolsInfo(tool).title

          let filedObj = toolObj[tool]
          for (let filed in filedObj) {
            excelLine[this.getToolRowTextForExcel(filed)] = filedObj[filed]

            if (
              filed !== "count" &&
              this.$store.state.user.currency === "TOMAN"
            ) {
              excelLine[`${this.getToolRowTextForExcel(filed)}`] /= 10
            }
          }
          dataArr.push(excelLine)
        }
      }

      let wb = XLSX.utils.book_new()

      let ws = XLSX.utils.json_to_sheet(dataArr)

      XLSX.utils.book_append_sheet(wb, ws, "accountant_daily_report")
      XLSX.writeFile(wb, "accountant_daily_report.xlsx")
    },
  },
}
