<template #subToolbar>
  <div :class="`d-flex align-center ${containerClasses}`">
    <div class="d-flex align-center">
      <slot name="iconSlot" />
    </div>

    <div>{{ description }}</div>
    <div>
      <slot name="actionSlot" />
    </div>
  </div>
</template>

<script>
export default {
  name: "VanModalAnnouncementBar",
  props: {
    description: {
      type: String,
      default: "",
    },
    containerClasses: {
      type: String,
      default: "",
    },
  },
}
</script>
