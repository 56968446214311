<template>
  <v-card>
    <v-toolbar class="fixed_toolbar" flat height="60">
      <v-btn icon @click="close_modal">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pa-0"> ویرایش پرداخت با وندار </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          :disabled="disable"
          :loading="loading"
          class="btn_small_normal"
          dark
          depressed
          text
          @click="sendForm"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout column>
      <v-flex>
        <v-layout class="rows" column>
          <v-flex>
            <v-form ref="form" v-model="valid" :disabled="loading">
              <v-layout
                :align-center="$vuetify.breakpoint.mdAndUp"
                :align-start="$vuetify.breakpoint.smAndDown"
                :column="$vuetify.breakpoint.xsOnly"
                justify-space-between
              >
                <v-flex class="label" style="width: 110px">
                  آدرس وب‌سایت
                  <span>*</span>
                </v-flex>
                <v-flex class="withlabel big comboboxSmallchip">
                  <v-combobox
                    v-model="domains"
                    :rules="urlsRule"
                    deletable-chips
                    dir="ltr"
                    flat
                    hide-selected
                    hint="بعد از وارد کردن هر آدرس، دکمه «اینتر» را بزنید. آدرس را با http یا https وارد کنید."
                    multiple
                    outlined
                    small-chips
                    solo
                  >
                    <template #message="message">
                      <div v-html="message.message" />
                    </template>
                  </v-combobox>
                </v-flex>
              </v-layout>

              <v-layout
                :align-center="$vuetify.breakpoint.mdAndUp"
                :align-start="$vuetify.breakpoint.smAndDown"
                :column="$vuetify.breakpoint.xsOnly"
                justify-space-between
              >
                <v-flex class="label">
                  آی‌ پی‌های شما
                  <span>*</span>
                </v-flex>
                <v-flex class="withlabel big comboboxSmallchip">
                  <v-combobox
                    v-model="ips"
                    :rules="ipsRule"
                    deletable-chips
                    dir="ltr"
                    flat
                    hide-selected
                    hint="بعد از وارد کردن هر آی‌‌پی، دکمه «اینتر» را بزنید"
                    multiple
                    outlined
                    small-chips
                    solo
                    @keypress="checkIps($event)"
                  >
                    <template #message="message">
                      <div v-html="message.message" />
                    </template>
                  </v-combobox>
                </v-flex>
              </v-layout>

              <v-layout
                :align-center="$vuetify.breakpoint.mdAndUp"
                :align-start="$vuetify.breakpoint.smAndDown"
                :column="$vuetify.breakpoint.xsOnly"
                justify-start
              >
                <v-flex class="label"> کارمزد تراکنش </v-flex>
                <v-flex class="withlabel">
                  <v-select
                    v-model="wage_type"
                    :items="selectItems"
                    flat
                    full-width
                    height="40"
                    item-text="title"
                    item-value="value"
                    outlined
                    solo
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "ApplicationEdit",
  components: {},
  data() {
    return {
      domains: [],
      ips: [],
      wage_type: 0,
      valid: false,
      loading: false,
      selectItems: [
        { title: "از خودم کسر شود", value: "APPLICATION_SELF" },
        {
          title: "از پرداخت‌کننده دریافت شود",
          value: "APPLICATION_USER",
        },
      ],
      urlsRule: [
        (v) => !!v || "<span>آدرس وب‌سایت الزامی است</span>",
        (v) =>
          (!!v && this.checkFormat(v, this.urlsPattern)) ||
          "<span> آدرس وب‌سایت به درستی وارد نشده است</span>",
      ],
      ipsRule: [
        (v) => !!v || "<span> وارد کردن آی‌ پی‌ الزامی است</span>",
        (v) =>
          (!!v && this.checkFormat(v, this.ipValidationPattern)) ||
          "<span> فرمت صحیح آی‌ پی‌ را وارد کنید</span>",
      ],
      urlsPattern: new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ),
      ipValidationPattern:
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    }
  },
  computed: {
    disable() {
      return (
        this.loading ||
        !this.valid ||
        !this.ips ||
        !this.ips.length ||
        !this.domains ||
        !this.domains.length
      )
    },

    application() {
      return this.$store.state.pbv.application
    },
  },
  mounted() {
    this.domains = this.application.domains
    this.ips = this.application.ips
    this.wage_type = this.application.wage_type
  },
  methods: {
    checkFormat(items, selectedPattern) {
      let doesAllItemsMatch = true

      for (let element of items) {
        if (element.includes("localhost")) continue
        //convert farsi digits to english
        element = this.convertNumbers2English(element)
        if (selectedPattern && !selectedPattern.test(element)) {
          // if item does not match the pattern return false
          doesAllItemsMatch = false
          return false
        }
      }

      return doesAllItemsMatch
    },
    checkIps(event) {
      if (
        !/(^[0-9۰-۹٫]*$)/.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "."
      ) {
        event.preventDefault()
      }
    },
    sendForm() {
      this.loading = true
      let data = {
        wage_type: this.wage_type,
        domains: this.domains,
        ips: this.ips,
      }
      this.$http
        .post(
          this.$store.state.api_pbv_url +
            this.$store.getters.urlParam +
            "/application/update",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.subscription = response.data.application
          const newBusiness = this.$store.state.business
          newBusiness.tools.PBV.is_created = true
          this.$store.commit("setBusiness", newBusiness)
          this.$store.commit(
            "callSuccessSnackbar",
            "ابزار پرداخت با وندار با موفقیت بروزرسانی شد."
          )
          this.$store.dispatch("setPbvApplication", {
            ...this.$store.state.pbv.application,
            wage_type: this.wage_type,
            domains: this.domains,
            ips: this.ips,
          })
          this.loading = false
          this.close_modal()
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },

    close_modal() {
      this.$emit("close-edit")
    },
  },
}
</script>

<style scoped>
.image-box {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.full-width {
  width: 100% !important;
}

.modal-image-box {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 38px;
  height: 38px;
  border-radius: 4px;
}

.modal-title {
  font-size: 16px;
  font-weight: 700;
}

.modal-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
