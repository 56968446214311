<template>
  <div>
    <v-btn icon fab class="businessFloating" @click="show">
      <svg
        style="fill: #666666"
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="5"
        viewBox="0 0 8 5"
      >
        <path
          class="a"
          d="M203.511,32.351h8l-4.1,5Z"
          transform="translate(-203.511 -32.351)"
        />
      </svg>
    </v-btn>
    <v-menu
      v-model="showMenu"
      transition="slide-y-transition"
      close-on-content-click
      min-width="220"
      max-height="365"
      :position-x="x"
      :position-y="y"
      absolute
    >
      <!-- this "on" slot sometime is undefined -->
      <!--      <template #activator />-->

      <v-card outlined class="drop_down_menu">
        <v-list class="menu_v_list">
          <v-list-item :to="home.to">
            <v-list-item-action>
              <svg
                class="svgs"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
              >
                <defs />
                <path
                  class="a"
                  d="M15.444,3A1.55,1.55,0,0,1,17,4.556V15.444A1.55,1.55,0,0,1,15.444,17H10.778V3M9.222,3V9.222H3V4.556A1.55,1.55,0,0,1,4.556,3m4.667,7.778V17H4.556A1.55,1.55,0,0,1,3,15.444V10.778"
                  transform="translate(-3 -3)"
                />
              </svg>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ home.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list class="menu-body">
          <v-list-item
            v-for="(business, index) in listOfBusiness"
            :key="'i-' + index"
            @click="onBusinessChange(index, business)"
          >
            <div class="d-flex pa-2 w-100" :style="checkActive(business)">
              <v-list-item-avatar v-if="index == 0">
                <v-img
                  v-if="user?.avatar"
                  :src="$store.state.storage_base_url + user?.avatar"
                  class="image"
                  alt="avatar"
                />
                <v-img
                  v-else
                  :src="require('@/assets/img/default.png')"
                  class="image"
                  alt="avatar"
                />
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <v-img
                  v-if="business?.avatar"
                  :src="$store.state.storage_base_url + business?.avatar"
                  class="image"
                  alt="avatar"
                />
                <v-img
                  v-else
                  :src="require('@/assets/img/businessDefault.png')"
                  class="image"
                  alt="avatar"
                />
              </v-list-item-avatar>
              <v-list-item-content v-if="index == 0">
                <v-list-item-title
                  v-if="user?.hasOwnProperty('name')"
                  class="font_7"
                  style="max-width: 132px"
                >
                  {{ user.name }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  <span class="default_gray" />
                </v-list-item-title>

                <!-- wallet -->
                <wallet
                  with-label
                  :load="user?.hasOwnProperty('status')"
                  :status="user?.status"
                  :amount="user?.wallet"
                  :blocked_amount="user?.blocked_amount"
                  class="mt-0 mb-0"
                />
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-list-item-title
                  v-if="business?.hasOwnProperty('business_name_fa')"
                  class="font_7"
                  style="max-width: 132px"
                >
                  {{ business.business_name_fa }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  <span class="default_gray" />
                </v-list-item-title>
                <!-- wallet -->
                <wallet
                  with-label
                  :load="business?.hasOwnProperty('status')"
                  :status="business?.status"
                  :amount="business?.wallet"
                  :blocked_amount="business?.blocked_amount"
                  :access="business?.role == 'developer' ? false : true"
                  class="mt-0"
                />
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import wallet from "./wallet"
export default {
  name: "NavigationDrawer",
  components: {
    wallet,
  },
  data() {
    return {
      home: { title: "همه کسب‌و‌کارها", icon: "home", to: "/" },
      loaded: false,
      showMenu: false,
      x: 0,
      y: 0,
    }
  },
  computed: {
    listOfBusiness() {
      if (this.$store.state.businessList != null) {
        if (this.$store.state.business.id) {
          let tempBusinessList = this.$store.state.businessList.filter(
            (item) => item.id !== this.$store.state.business.id
          )
          let currentBusiness = this.$store.state.businessList.find(
            (item) => item.id === this.$store.state.business.id
          )
          tempBusinessList.splice(1, 0, currentBusiness)
          return tempBusinessList
        } else {
          return this.$store.state.businessList
        }
      } else return null
    },
    user() {
      return this.$store.state.user
    },
    business() {
      if (!this.$store.state.isUser) return this.$store.state.business
      else return {}
    },
  },
  watch: {
    "$store.state.updateBusinessList": {
      //immediate: true,
      handler(newValue, oldValue) {
        if (newValue == true) this.getBusinessList()
      },
      deep: true,
    },
  },
  methods: {
    onBusinessChange(index, business) {
      this.$store.commit("changeBusinessRole", null)
      this.$store.commit("destroyIpg")
      this.$store.commit("eraseToolsObject")
      const path = index == 0 ? "/personal" : this.createLink(business)
      this.$router.push(path)
    },
    show(e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX + 110
      this.y = e.clientY + 10
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    getBusinessList() {
      this.$store
        .dispatch("retrieveBusinessList")
        .then((response) => {
          this.$store.commit("changeUpdateBusinessList", false)
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    createLink(business) {
      if (business?.role === "developer") {
        return "/business/" + business.business_name + "/ipg"
      } else {
        return "/business/" + business.business_name
      }
    },
    checkActive(business) {
      let backClass = "background: #a14e691a;border-radius: 4px;"
      if (this.$store.state.isUser && !business?.id) {
        return backClass
      } else if (this.$store.state.business.id === business?.id) {
        return backClass
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-body {
  background-color: #f5f5f5 !important;
  .image {
    border: #f5f5f5 2px solid;
  }
}
</style>
