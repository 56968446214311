<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 14 14"
    :transform="navigation ? 'translate(4 0)' : 'translate(0 0)'"
    class="svgs"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fillColor ? fillColor : ''"
      class="a"
      d="M10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0ZM6.68667 2.70667L7.39333 2L8.33333 2.94L9.27333 2L9.98 2.70667L9.04 3.64667L9.98 4.58667L9.27333 5.29333L8.33333 4.36L7.39333 5.3L6.68667 4.59333L7.62667 3.65333L6.68667 2.70667ZM2.16667 3.14667H5.5V4.14667H2.16667V3.14667ZM5.66667 8.66667H4.33333V10H3.33333V8.66667H2V7.66667H3.33333V6.33333H4.33333V7.66667H5.66667V8.66667ZM10 9.5H6.66667V8.5H10V9.5ZM10 7.83333H6.66667V6.83333H10V7.83333Z"
    />
  </svg>
</template>
<script>
export default {
  name: "AccountantIcon",
  props: {
    fillColor: {
      type: String,
      default: null,
    },
    navigation: {
      type: Boolean,
      default: null,
    },
  },
}
</script>
