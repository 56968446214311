<template>
  <GeneralCard>
    <div class="card-wrapper">
      <section class="d-flex align-center">
        <v-img
          width="40"
          max-width="40"
          aspect-ratio="1"
          :src="require('@/assets/img/newIcon/cash-in-icon.svg')"
        />
        <section class="d-flex flex-column">
          <section class="d-flex align-center mb-1">
            <span class="font-12-bo-333-18 ml-2"> واریز بانکی خودکار </span>
            <v-img
              width="14"
              max-width="14"
              aspect-ratio="1"
              :src="require('@/assets/img/info.svg')"
              style="cursor: pointer"
              @click="showInfo"
            />
          </section>
          <span class="font-11-no-666-17">
            روزانه و به صورت خودکار، کیف‌پول ونداری‌تان را شارژ کنید.
          </span>
        </section>
      </section>

      <v-btn
        dark
        text
        class="btn_small_normal"
        style="width: 95px"
        @click="setupCashIn"
      >
        تنظیمات
      </v-btn>
    </div>
    <v-dialog
      v-if="isOpenAutoCashInDetailModal"
      v-model="isOpenAutoCashInDetailModal"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <AutoCashInDetail
        v-if="isOpenAutoCashInDetailModal"
        @closeModal="closeAutoCashInDetailModal"
      />
    </v-dialog>
  </GeneralCard>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard"
import AutoCashInDetail from "@/components/report/modals/addCashIn/elements/AutoCashInDetail"
import { mapState } from "vuex"

export default {
  name: "AutoCashInCard",
  components: {
    GeneralCard,
    AutoCashInDetail,
  },
  data() {
    return {
      isOpenAutoCashInDetailModal: false,
    }
  },
  computed: {
    ...mapState(["showSecondModal"]),
  },
  watch: {
    isOpenAutoCashInDetailModal(val) {
      if (!val && this.showSecondModal) {
        this.$store.commit("changeShowSecondModal", false)
      }
    },
  },

  methods: {
    setupCashIn() {
      this.$emit('setup-auto-cash-in')
    },
    showInfo() {
      this.isOpenAutoCashInDetailModal = true
      this.$store.commit("changeShowSecondModal", true)
    },
    closeAutoCashInDetailModal() {
      this.isOpenAutoCashInDetailModal = false
      this.$store.commit("changeShowSecondModal", false)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
