<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">جزییات پرداخت خودکار</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          v-if="$store.state.business.role !== 'developer'"
          depressed
          outlined
          text
          dark
          class="btn_small_normal"
          @click="open_edit()"
        >
          ویرایش
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-layout column class="rows">
          <v-flex>
            <v-layout align-center>
              <v-flex class="label" style="width: 110px"> شناسه </v-flex>
              <v-flex>{{ $store.state.business.business_name }}</v-flex>
              <v-flex style="max-width: 80px; position: relative">
                <CopyBtnGeneral
                  input-id="hidden-input"
                  :value="$store.state.business.business_name"
                  style-btn="height:40px !important"
                  label="کپی شناسه"
                  color-btn="van_color06"
                  class-btn="van_color11 tblCpy-receipt_url px-2 ma-0"
                  :outlined="false"
                />
              </v-flex>
              <input
                id="hidden-input1"
                v-model="$store.state.business.business_name"
                class="hidden-input"
              />
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout>
              <v-flex class="label"> وب‌سایت </v-flex>
              <v-flex>
                <div
                  v-for="(url, i) in $store.state.subscription.urls"
                  :key="i"
                >
                  {{ url }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout>
              <v-flex class="label"> آی پی </v-flex>
              <v-flex style="max-width: 345px">
                <span v-for="(ip, i) in $store.state.subscription.ips" :key="i">
                  <span>{{ ip }}</span>
                  <span
                    v-if="i < $store.state.subscription.ips.length - 1"
                    style="padding: 0 5px"
                    >|</span
                  >
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout>
              <v-flex class="label"> کارمزد تراکنش </v-flex>
              <v-flex
                v-if="$store.state.subscription.wage_type == 'APPLICATION_SELF'"
              >
                از خودم کسر شود
              </v-flex>
              <v-flex v-else> از پرداخت کننده دریافت شود </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status1"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <add
        v-show="status1"
        :key="componentKey"
        type="edit"
        @closeModal="close_modal"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import add from "./add"
import CopyBtnGeneral from "../../elements/copyBtnGeneral"
export default {
  name: "Show",
  components: {
    CopyBtnGeneral,
    add,
  },
  data() {
    return {
      componentKey: 0,
      status1: false,
    }
  },
  watch: {
    status1: function (newValue) {
      if (!newValue) this.$store.commit("callEditSubFormModal", false)
    },
  },
  methods: {
    open_edit() {
      this.close_modal()
      this.$store.commit("callEditSubFormModal", true)
      this.status1 = true
    },
    close_modal(obj) {
      this.$store.commit("callEditSubFormModal", false)
      this.status1 = false
      if (obj && obj.status) this.componentKey = !this.componentKey
    },
  },
}
</script>

<style lang="scss" scoped>
nav.v-toolbar > .v-toolbar__content {
  padding: 0 7px 0 7px !important;
}
.v-tooltip__content {
  min-width: auto !important;
  top: 39px !important;
  padding: 5px 8px !important;
}
</style>
