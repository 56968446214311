<template>
  <div>
    <div class="d-flex justify-space-between w-full align-center mb-5">
      <h2 class="mb-0">پرداخت از حساب</h2>
      <div>
        <v-btn
          v-show="!loading && status === 'ACTIVE'"
          class="btn_small_normal"
          depressed
          outlined
          :disabled="!activeBanks.length"
          text
          @click="showManagement"
        >
          مدیریت
        </v-btn>
        <v-btn
          v-if="status !== 'PENDING' && status !== 'NOT_ASSIGNED'"
          :loading="loading"
          text
          depressed
          dark
          :outlined="status === 'ACTIVE'"
          class="btn_small_normal ma-0"
          @click="updateMethod(status == 'ACTIVE' ? 'DEACTIVATED' : 'ACTIVE')"
        >
          {{ statuses[status] }}
        </v-btn>
        <v-btn
          v-if="status == 'NOT_ASSIGNED'"
          :loading="loading"
          text
          depressed
          dark
          class="btn_small_normal ma-0"
          @click="addType()"
        >
          فعال‌سازی
        </v-btn>

        <div v-if="status === 'PENDING'" class="in-progress">
          {{ statuses[status] }}
        </div>
      </div>
    </div>
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      class="business"
      color="white"
      flat
    >
      <div
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
        class="d-flex full-width justify-space-between"
      >
        <div v-if="loading" class="titles">
          <div class="income" style="width: 332px">
            <div
              v-if="loading"
              class="default_gray"
              style="width: 5em; height: 2em"
            />
          </div>
          <div
            v-if="loading"
            class="default_gray"
            style="width: 6em; height: 1em"
          />
        </div>
        <div v-if="!loading && status == 'PENDING'" class="titles">
          <span class="font_36 van_color04--text"
            >شما هنوز بانک فعالی ندارید. برای دریافت و تایید مدارک تیم پشتیبانی
            وندار با شما تماس می‌گیرد.
          </span>
        </div>
        <div v-if="!loading && status == 'NOT_ASSIGNED'" class="titles">
          <span class="font_30"
            >در این روش، مبلغ خدمات شما به صورت مستقیم از حساب مشتریا‌ن‌تان،
            پرداخت می‌شود. کافی است مجوز‌ اتصال به حساب بانکی، یک‌بار به‌صورت
            آنلاین از مشتری دریافت شود تا پس از آن، مبلغ خدمات با درخواست شما از
            حساب بانکی او کسر و به کیف‌پول کسب‌وکار شما در وندار اضافه شود. وجوه
            واریز شده به کیف‌پولتان در لحظه قابل برداشت است.</span
          >
        </div>
        <div v-if="!loading && status == 'DEACTIVATED'" class="titles">
          <span class="font_30"
            >در این روش، مبلغ خدمات شما به صورت مستقیم از حساب مشتریا‌ن‌تان،
            پرداخت می‌شود. کافی است مجوز‌ اتصال به حساب بانکی، یک‌بار به‌صورت
            آنلاین از مشتری دریافت شود تا پس از آن، مبلغ خدمات با درخواست شما از
            حساب بانکی او کسر و به کیف‌پول کسب‌وکار شما در وندار اضافه شود. وجوه
            واریز شده به کیف‌پولتان در لحظه قابل برداشت است.</span
          >
        </div>
        <div v-if="!loading && status == 'ACTIVE'" class="titles">
          <v-layout align-center justify-center>
            <v-flex style="width: 320px">
              <div>
                <h2 class="font_36 mb-1">بانک‌های فعال</h2>
              </div>
              <div
                v-if="methods.length === activeBanks.length"
                class="income font_14"
              >
                همه
                {{ methods.length }}
                بانک موجود برای شما فعال هستند.
              </div>
              <div v-else class="income font_14">
                در حال حاضر شما
                {{ activeBanks.length }}
                بانک از
                {{ methods.length }}
                بانک وندار را فعال دارید.
                <br />
                برای فعال‌سازی بانک‌های دیگر با پشتیبانی تماس بگیرید.
              </div>
              <div v-show="status === 'ACTIVE'">
                <h2 class="font_35 mt-4 mb-1">کارمزد تراکنش :</h2>
                <span class="income font_14">{{
                  wageType === "APPLICATION_SELF"
                    ? "از خودم کسر شود"
                    : "از پرداخت‌کننده دریافت شود"
                }}</span>
              </div>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex v-if="$vuetify.breakpoint.mdAndUp">
              <div
                v-if="loading"
                class="default_gray"
                style="width: 4em; height: 2em"
              />
            </v-flex>
          </v-layout>
        </div>
        <div class="more-data">
          <div v-if="loading" class="bank-list">
            <div v-for="i in 12" :key="i" class="bank-item">
              <div
                class="default_gray"
                style="width: 46px; height: 46px; margin: 0"
              />
            </div>
          </div>
          <div v-if="!loading" class="bank-list">
            <div v-for="bank in methods" :key="bank.id" class="bank-item">
              <v-tooltip top>
                <template #activator="{ on }">
                  <div
                    :class="`image-box ${
                      bank.status === 'PENDING' || status === 'PENDING'
                        ? 'disable'
                        : ''
                    }`"
                    v-on="status !== 'PENDING' && on"
                  >
                    <v-img
                      :lazy-src="bank.logo"
                      :src="bank.logo"
                      contain
                      height="32px"
                      width="32px"
                    />
                  </div>
                </template>
                <span>{{
                  bank.status === "PENDING" ? "غیر‌فعال" : "فعال"
                }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <!-- <v-layout class="botton mr-auto" column>
        <v-flex v-if="$vuetify.breakpoint.mdAndUp">
          <div
            v-if="loading"
            class="default_gray"
            style="width: 4em; height: 2em"
          />
          <v-btn
            v-else
            class="btn_small_normal"
            depressed
            outlined
            :disabled="!activeBanks.length"
            text
            @click="showManagement"
          >
            مدیریت
          </v-btn>
        </v-flex>
      </v-layout> -->
      </div>
    </v-card>
    <v-card v-else class="business-md-down" color="white" flat>
      <v-layout justify-space-between wrap>
        <v-flex>
          <div v-if="loading" class="titles">
            <div class="income" style="width: 332px">
              <div
                v-if="loading"
                class="default_gray"
                style="width: 5em; height: 2em"
              />
            </div>
            <div
              v-if="loading"
              class="default_gray"
              style="width: 6em; height: 1em"
            />
          </div>
          <div v-if="!loading && status == 'PENDING'" class="titles">
            <span class="font_36 van_color04--text"
              >شما هنوز بانک فعالی ندارید. برای دریافت و تایید مدارک تیم
              پشتیبانی وندار با شما تماس می‌گیرد.
            </span>
          </div>
          <div v-if="!loading && status == 'DEACTIVATED'" class="titles">
            <span class="font_36">توضیح کوتاه در مورد پرداخت از حساب...</span>
          </div>
          <div v-if="!loading && status == 'ACTIVE'" class="titles">
            <div>
              <h2 class="font_36 mb-3">بانک‌های فعال</h2>
            </div>
            <div v-if="methods.length === activeBanks.length" class="income">
              همه
              {{ methods.length }}
              بانک موجود برای شما فعال هستند.
            </div>
            <div v-else class="income">
              در حال حاضر شما
              {{ activeBanks.length }}
              بانک از
              {{ methods.length }}
              بانک وندار را فعال دارید.
              <br />
              برای فعال‌سازی بانک‌های دیگر با پشتیبانی تماس بگیرید.
            </div>
          </div>
        </v-flex>
        <v-flex
          v-if="status === 'ACTIVE'"
          style="max-width: 40px; margin-left: -10px"
        >
          <v-menu
            bottom
            close-on-content-click
            content-class="up_menu"
            offset-y
            transition="slide-y-transition"
          >
            <!-- this on slot sometime is undefined -->
            <template #activator="{ on }">
              <v-btn fab icon size="24" small style="margin: 0" v-on="on">
                <v-icon color="van_color03" size="21" style="line-height: 40px">
                  more_vert
                </v-icon>
              </v-btn>
            </template>

            <v-card class="drop_down_menu">
              <v-list class="menu_v_list">
                <v-list-item @click="showManagement">
                  <v-list-item-content>
                    <v-list-item-title>مدیریت</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout align-center class="more-data" fill-height justify-center>
        <v-flex>
          <div class="more-data">
            <div v-if="loading" class="bank-list">
              <div v-for="i in 12" :key="i" class="bank-item">
                <div
                  class="default_gray"
                  style="width: 56px; height: 56px; margin: 0"
                />
              </div>
            </div>
            <div
              v-if="!loading && status !== 'DEACTIVATED'"
              class="bank-list"
              style="justify-content: center"
            >
              <div v-for="bank in methods" :key="bank.id" class="bank-item">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <div
                      :class="`image-box ${
                        bank.status === 'PENDING' || status === 'PENDING'
                          ? 'disable'
                          : ''
                      }`"
                      v-on="status !== 'PENDING' && on"
                    >
                      <v-img
                        :lazy-src="bank.logo"
                        :src="bank.logo"
                        contain
                        height="32px"
                        width="32px"
                      />
                    </div>
                  </template>
                  <span>{{
                    bank.status === "PENDING" ? "غیر‌فعال" : "فعال"
                  }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DebitMethods",
  props: {
    status: {
      type: String,
      required: true,
    },
    methods: {
      type: Array,
      required: true,
    },
    wageType: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      statuses: {
        ACTIVE: "غیرفعال شود",
        PENDING: "در حال بررسی",
        DEACTIVATED: "‌فعال‌سازی",
      },
      isHover: false,
      hover: false,
      banks: [],
    }
  },
  computed: {
    activeBanks() {
      return this.methods.filter((item) => item.status !== "PENDING")
    },
  },
  created() {},
  methods: {
    showManagement() {
      this.$emit("show-management")
    },
    addType() {
      this.loading = true
      this.$http
        .post(this.$store.getters.urlMPG + "/payment-method-types/", {
          payment_method_type_slug: "debit",
        })
        .then((res) => {
          this.$emit("reload")
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateMethod(status) {
      this.loading = true
      this.$http
        .put(this.$store.getters.urlMPG + "/payment-method-types/debit", {
          status: status,
        })
        .then((res) => {
          this.status = status
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.in-progress {
  color: $van_green;
  background-color: rgb($van_green, 0.1);
  width: 114px;
  height: 36px;
  padding: 9px 11px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  @media screen and (max-width: 960px) {
    margin-left: 0;
    margin-right: auto;
  }
}
.bank-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
  gap: 8px;
  justify-content: flex-end;
}

.bank-item {
  height: max-content;
}

.image-box {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  width: 46px;
  height: 46px;
  border-radius: 4px;
  &.disable {
    opacity: 0.3;
  }
}

.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}

.business {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  box-shadow: none;
  margin: auto;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.business-md-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }

  .more-data {
    display: flex;
    //width: 250px;
    margin-top: 16px;
  }

  .bus {
    background-color: $van_color11;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 11px 5px;
    height: 80px;
    width: 100%;
    margin: 5px;
    max-width: 95%;

    &.bus-right,
    &.bus-left {
      width: 95%;
    }

    &.flat {
      background: transparent;
      color: $van_pink;
      font-size: 12px;
      font-weight: bold;
      height: 28px;
    }
  }
}

.v-avatar {
  margin: 0 0 10px 0;
}

.titles {
  max-width: 432px;
}

h2 {
  margin: 0 0 9px 0;
  line-height: 20px;
}

.income {
  line-height: 24px;
  font-size: 12px;
  color: #666;
  font-weight: normal;

  h2 {
    font-size: 14px;
  }
}

.bus-right {
  margin-left: 10px;
}

.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;
  max-width: 120px;

  &.bus-right,
  &.bus-left {
    width: 50%;
  }

  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}

.business .more-data {
  display: flex;
  width: 384px;
}

.mablagh {
  font-size: 14px;
  font-weight: bold;
}

.sub-text {
  font-size: 9px;
  color: #999;
}

.layout.botton {
  max-width: 88px;

  .v-btn {
    width: calc(100% - 16px);
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.v-chip--small {
  height: 25px !important;
  margin-top: 5px !important;
}

.full-width {
  width: 100%;
}
</style>
