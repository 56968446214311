<template>
  <v-card>
    <div class="d-flex flex-column align-center justify-center pa-8">
      <h3 class="mb-4 van_color02--text font-size-16 bold">
        مراحل فعال‌سازی ابزار پرداخت یکپارچه
      </h3>

      <v-sheet
        v-for="(step, ind) in steps"
        :key="ind"
        class="pa-3 d-flex mb-2"
        color="#F5F5F5"
        width="100%"
        rounded
      >
        <div class="step-box">
          <svg
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5537 2.11651C18.0923 -0.0747896 21.9514 0.00129739 21.9514 0.00129739C28.3304 0.00129739 31.9023 3.42978 33.505 10.5774C33.505 10.5774 34.4512 13.4771 35.8161 22.2126C37.181 30.9481 30.7197 31.1597 24.2609 32.7948C24.2609 32.7948 18.3201 34.0122 11.5521 34.91C4.78409 35.8078 0 30.1713 0 24.3293V12.6941C0 6.84912 5.01417 4.30478 11.5537 2.11651Z"
              :fill="step.color"
            />
          </svg>
          <span class="step-index">{{ ind + 1 }}</span>
        </div>
        <div class="d-flex flex-column mr-4">
          <span class="pb-1 van_color02--text font-size-12 bold">{{
            step.title
          }}</span>
          <p class="font-size-11 van_color03--text mb-0">{{ step.desc }}</p>
        </div>
      </v-sheet>
      <div class="d-flex align-center justify-center full-width mt-4">
        <v-btn color="primary" depressed @click="activate()">
          <span class="modal-description">شروع فعال‌سازی</span>
        </v-btn>
        <v-btn color="#666" outlined @click="close()">
          <span class="modal-description">انصراف</span>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "StepsMPG",
  data() {
    return {
      steps: [
        {
          title: "انتخاب روش‌ پرداخت",
          desc: "روش پرداخت ارائه شده را مطالعه کرده و دکمه «ثبت و ادامه» را کلیک کنید.",
          color: "rgba(76, 159, 135, 0.2)",
        },
        {
          title: "تنظیمات",
          desc: "پس از انتخاب روش پرداخت، تنظیمات را وارد کنید.",
          color: "rgba(76, 159, 135, 0.2)",
        },
        {
          title: "ارسال مدارک",
          desc: "تیم پشتیبانی در اسرع وقت، برای ارسال مدارک با شما تماس خواهد گرفت. بعد از تایید مدارک، ابزار برای شما فعال خواهد شد.",
          color: "rgba(76, 159, 135, 0.2)",
        },
      ],
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    activate() {
      this.$emit("activate")
    },
  },
}
</script>

<style lang="scss" scoped>
.step-box {
  position: relative;
  .step-index {
    position: absolute;
    left: 14px;
    top: 8px;
  }
}
</style>
