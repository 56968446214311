<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">
        <span v-if="type == 'edit'">ویرایش مشتری</span>
        <span v-else>افزودن مشتری</span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="isSaveButtonDisabled"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout column>
      <v-flex>
        <v-card flat>
          <v-layout>
            <v-flex>
              <v-form ref="form" v-model="valid">
                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> نوع </v-flex>
                  <v-flex class="withlabel big">
                    <v-radio-group v-model="customerType" row>
                      <v-radio label="حقیقی" value="1" />
                      <v-radio label="حقوقی" value="2" />
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="customerType == '2'"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> نام حقوقی </v-flex>
                  <v-flex class="withlabel">
                    <v-text-field
                      v-model.trim="legal_name"
                      solo
                      flat
                      height="40"
                      outlined
                      :rules="[
                        (v) => !!v || '<span> نام حقوقی الزامی است </span>',
                      ]"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="customerType == '2'"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> نام نماینده </v-flex>
                  <v-flex class="withlabel">
                    <v-text-field
                      v-model.trim="agent_name"
                      solo
                      flat
                      height="40"
                      outlined
                      :rules="[
                        (v) => !!v || '<span> نام نماینده الزامی است </span>',
                      ]"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="customerType == '2'"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> تلفن همراه نماینده </v-flex>
                  <v-flex class="withlabel">
                    <mobileNumber
                      :is-disabled="isDisabledMobileField"
                      :input-value="agent_mobile"
                      @setValue="agent_mobile = $event"
                    />
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="customerType == '1'"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> نام </v-flex>
                  <v-flex class="withlabel">
                    <v-text-field
                      v-model.trim="first_name"
                      solo
                      flat
                      height="40"
                      outlined
                      :rules="[(v) => !!v || '<span> نام الزامی است </span>']"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="customerType == '1'"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> نام خانوادگی </v-flex>
                  <v-flex class="withlabel">
                    <v-text-field
                      v-model.trim="last_name"
                      solo
                      flat
                      height="40"
                      outlined
                      :rules="[
                        (v) => !!v || '<span> نام خانوادگی الزامی است </span>',
                      ]"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="customerType == '1'"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> تلفن همراه </v-flex>
                  <v-flex class="withlabel">
                    <mobileNumber
                      :is-disabled="isDisabledMobileField"
                      :input-value="mobile"
                      @setValue="mobile = $event"
                    />
                  </v-flex>
                </v-layout>
                <v-layout
                  v-if="!showMore"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label" />
                  <v-flex class="withlabel">
                    <div class="font_26 linkBorder mx-0">
                      <a @click="showMore = true">اطلاعات بیشتر</a>
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="customerType == '1' && showMore"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> کد ملی </v-flex>
                  <v-flex class="withlabel">
                    <v-text-field
                      ref="individual_national_code"
                      v-model="individual_national_code"
                      :rules="nationalCodeRules"
                      :error-messages="messageNationalCode"
                      solo
                      flat
                      height="40"
                      outlined
                      @keydown="numberKeyHandler($event)"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout
                  v-if="customerType == '2' && showMore"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> شناسه ملی </v-flex>
                  <v-flex class="withlabel">
                    <nationalId
                      :required="false"
                      :input-value="legal_national_code"
                      @setValue="legal_national_code = $event"
                    />
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="customerType == '2' && showMore"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> حوزه فعالیت </v-flex>
                  <v-flex class="withlabel big">
                    <v-autocomplete
                      v-model="mcc_code"
                      :items="senfs"
                      item-text="value"
                      item-value="key"
                      solo
                      flat
                      height="40"
                      single-line
                      no-data-text="اطلاعاتی برای نمایش موجود نیست"
                      placeholder="برای جستجو تایپ کنید"
                      menu-props="100%,full-width"
                    />
                  </v-flex>
                </v-layout>
                <v-layout
                  v-if="showMore"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> استان / شهر </v-flex>
                  <v-flex class="withlabel">
                    <v-autocomplete
                      v-model="city"
                      :items="ostans"
                      item-text="value"
                      item-value="key"
                      solo
                      flat
                      height="40"
                      outlined
                      single-line
                      no-data-text="اطلاعاتی برای نمایش موجود نیست"
                      placeholder="برای جستجو تایپ کنید"
                    />
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="showMore"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> آدرس </v-flex>
                  <v-flex class="withlabel big">
                    <v-textarea v-model="address" solo flat outlined rows="2">
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-textarea>
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="showMore"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> کد پستی </v-flex>
                  <v-flex class="withlabel">
                    <v-text-field
                      ref="postal_code"
                      v-model="postal_code"
                      :rules="postalCodeRules"
                      validate-on-blur
                      solo
                      flat
                      height="40"
                      outlined
                      @keydown="numberKeyHandler($event)"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout
                  v-if="showMore"
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> تلفن ثابت </v-flex>
                  <v-flex class="withlabel">
                    <v-text-field
                      ref="telephone"
                      v-model="telephone"
                      :rules="phone_number_Rules"
                      validate-on-blur
                      solo
                      flat
                      height="40"
                      outlined
                      @keydown="numberKeyHandler($event)"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import nationalId from "@/components/inputs/nationalId.vue"
import mobileNumber from "@/components/inputs/mobileNumber.vue"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "Add",
  components: {
    nationalId,
    mobileNumber,
  },
  props: ["type"],
  data: function () {
    return {
      loading: false,
      valid: false,
      ostans: [],

      //forms field
      first_name: "",
      last_name: "",
      legal_name: "",
      agent_name: "",
      customerType: "1",
      mobile: "",
      agent_mobile: "",
      telephone: "",
      individual_national_code: "",
      city: "",
      address: "",
      postal_code: "",
      senfs: [],
      mcc_code: "",
      legal_national_code: "",
      showMore: false,

      //validations
      postalCodeRules: [
        (v) => !v || v.length == 10 || "<span> کدپستی باید 10 رقم باشد </span>",
        (v) =>
          !v || this.postalCodeCheck(v) || "<span> کدپستی نامعتبر است </span>",
      ],
      nationalCodeRules: [
        (v) =>
          !v ||
          this.NationalCodeValidator(v) ||
          "<span> کدملی نادرست است </span>",
      ],
      messageNationalCode: "",
      phone_number_Rules: [
        (v) =>
          !v ||
          v.length >= 8 ||
          "<span> شماره تلفن ثابت نمی تواند کمتر از 8 رقم باشد </span>",
      ],
      isSaveButtonDisabled: true,
      /*
        address_Rules:[
        v => !!v || '<span> آدرس شرکت اجباری است </span>',
        v => /^[\u0600-\u06FF-0-9\s]+$/.test(v) || '<span> آدرس باید با حروف فارسی وارد شود </span>',
        v => (v && v.length > 10) || '<span> آدرس باید حداقل 10 کاراکتر باشد </span>',
      ],*/
    }
  },
  computed: {
    isDisabledMobileField() {
      return this.type === "edit" ? true : false
    },
  },
  watch: {
    individual_national_code: function (newValue) {
      if (newValue) {
        this.messageNationalCode = null
        this.individual_national_code = this.convertNumbers2English(
          newValue
        ).replace(/[^0-9]/g, "")
        //this.individual_national_code = this.individual_national_code.slice(0, 10)
      }
    },
    postal_code: function (newValue) {
      if (newValue) {
        this.postal_code = this.convertNumbers2English(newValue).replace(
          /[^0-9]/g,
          ""
        )
        this.postal_code = this.postal_code.slice(0, 10)
        if (this.$refs.postal_code) {
          this.$refs.postal_code.lazyValue = this.postal_code
        }
      }
    },
    telephone(newValue) {
      if (newValue) {
        this.telephone = this.convertNumbers2English(newValue).replace(
          /[^0-9]/g,
          ""
        )
      }
    },
    first_name() {
      this.handleActivatingStateOfSaveButton()
    },
    last_name() {
      this.handleActivatingStateOfSaveButton()
    },
    mobile() {
      this.handleActivatingStateOfSaveButton()
    },
    legal_name() {
      this.handleActivatingStateOfSaveButton()
    },
    agent_name() {
      this.handleActivatingStateOfSaveButton()
    },
    agent_mobile() {
      this.handleActivatingStateOfSaveButton()
    },
    customerType() {
      this.$refs.form.resetValidation()
      this.handleActivatingStateOfSaveButton()
    },
  },
  mounted() {
    this.getCities()
    this.getSenfs()
    if (this.type == "edit") {
      this.customerType =
        this.$store.state.customer.selectedCustomer.customer.type ==
        "INDIVIDUAL"
          ? "1"
          : "2"
      this.first_name =
        this.$store.state.customer.selectedCustomer.customer.first_name
      this.last_name =
        this.$store.state.customer.selectedCustomer.customer.last_name
      this.legal_name =
        this.$store.state.customer.selectedCustomer.customer.legal_name
      this.agent_name =
        this.$store.state.customer.selectedCustomer.customer.agent_name
      this.mobile = this.$store.state.customer.selectedCustomer.customer.mobile
      this.agent_mobile =
        this.$store.state.customer.selectedCustomer.customer.agent_mobile
      this.telephone =
        this.$store.state.customer.selectedCustomer.customer.telephone
      this.individual_national_code =
        this.$store.state.customer.selectedCustomer.customer.individual_national_code
      this.city = this.$store.state.customer.selectedCustomer.customer
        .province_id
        ? this.$store.state.customer.selectedCustomer.customer.province_id +
          "-" +
          this.$store.state.customer.selectedCustomer.customer.city_id
        : null
      this.address =
        this.$store.state.customer.selectedCustomer.customer.address
      this.postal_code =
        this.$store.state.customer.selectedCustomer.customer.postal_code
      this.mcc_code =
        this.$store.state.customer.selectedCustomer.customer.mcc_code
      this.legal_national_code =
        this.$store.state.customer.selectedCustomer.customer.legal_national_code
      this.showMore = true
    }
  },
  methods: {
    NationalCodeValidator(value) {
      value = this.individual_national_code
      if (value != null && value.length > 1 && value.length == 10) {
        if (
          value == "0000000000" ||
          value == "1111111111" ||
          value == "2222222222" ||
          value == "3333333333" ||
          value == "4444444444" ||
          value == "5555555555" ||
          value == "6666666666" ||
          value == "7777777777" ||
          value == "8888888888" ||
          value == "9999999999"
        ) {
          return false
        }
        var numbers = value.split("")
        if (numbers.length < 10) return false
        var sum = 0
        for (var i = 10; i > 1; i--) {
          sum += numbers[10 - i] * i
        }
        var r = sum % 11
        if ((r >= 2 && r == 11 - numbers[9]) || (r < 2 && r == numbers[9])) {
          return true
        }
        return false
      } else {
        this.messageNationalCode = null
        return false
      }
    },

    postalCodeCheck(value) {
      if (
        !(
          value == "0000000000" ||
          value == "1111111111" ||
          value == "2222222222" ||
          value == "3333333333" ||
          value == "4444444444" ||
          value == "5555555555" ||
          value == "6666666666" ||
          value == "7777777777" ||
          value == "8888888888" ||
          value == "9999999999"
        ) &&
        /^(\d{5}-?\d{5})$/.test(value)
      ) {
        return true
      } else {
        return false
      }
    },
    getSenfs() {
      this.$http
        .get(this.$store.state.api_base_url + "/information/mccs", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.senfs = Object.keys(response.data.data).map(function (key) {
            return { key: key, value: response.data.data[key] }
          })
        })
        .catch((error) => {
          //callsnackbar
          window.location.reload()
        })
    },
    getCities() {
      this.$http
        .get(this.$store.state.api_base_url + "/information/cities", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.ostans = Object.keys(response.data.data).map(function (key) {
            return { key: key, value: response.data.data[key] }
          })
        })
        .catch((error) => {
          this.$store.commit("callsnackbar", error)
          window.location.reload()
        })
    },
    sendForm() {
      // this.monthItem.forEach((element, index) => {
      //   if (element == this.expire.date.month) {
      //     this.convertMonth = index + 1
      //   }
      // })
      var data = {}
      if (this.customerType == "1") {
        data = {
          type: "INDIVIDUAL",
          first_name: this.first_name,
          last_name: this.last_name,
          mobile: this.mobile,
          telephone: this.telephone,
          individual_national_code: this.individual_national_code,
          province_id: this.city ? this.city.split("-")[0] : null,
          city_id: this.city ? this.city.split("-")[1] : null,
          address: this.address,
          postal_code: this.postal_code,
        }
      } else {
        data = {
          type: "LEGAL",
          legal_name: this.legal_name,
          agent_name: this.agent_name,
          agent_mobile: this.agent_mobile,
          mobile: this.agent_mobile,
          telephone: this.telephone,
          mcc_code: this.mcc_code,
          national_code: this.legal_national_code,
          province_id: this.city ? this.city.split("-")[0] : null,
          city_id: this.city ? this.city.split("-")[1] : null,
          address: this.address,
          postal_code: this.postal_code,
        }
      }

      if (this.type === "edit") {
        servicesRepository(this)
          .customers.httpUpdateCustomer(data)
          .then((response) => {
            this.$store.dispatch("retrieveSelectedCustomerInfo")
            this.$emit("updateTable")
            this.$store.commit("callSuccessSnackbar", response.data.message)
            this.$emit("closeModal", { status: true })
          })
          .catch((error) => {
            this.$store.commit("callsnackbar", error)
          })
      } else {
        servicesRepository(this)
          .customers.httpAddCustomer(data)
          .then((response) => {
            this.$store.commit("callSuccessSnackbar", response.data.message)
            this.$emit("updateTable")
            this.$emit("closeModal", { status: true })
          })
          .catch((error) => {
            this.$store.commit("callsnackbar", error)
          })
      }
    },
    disableSaveButton() {
      this.isSaveButtonDisabled = true
    },
    handleActivatingStateOfSaveButton() {
      let condition
      if (this.customerType === "1") {
        condition =
          this.first_name &&
          this.last_name &&
          this.mobile &&
          this.mobile.length === 11
      } else if (this.customerType === "2") {
        condition =
          this.agent_name &&
          this.legal_name &&
          this.agent_mobile &&
          this.agent_mobile.length === 11
      }

      if (condition) {
        this.isSaveButtonDisabled = false
      } else {
        this.disableSaveButton()
      }
    },
  },
}
</script>

<style scoped>
.v-radio.theme--light {
  border-radius: 4px !important;
}
</style>
