<template functional>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.44428 2.11111C6.4665 2.11111 5.6665 2.91111 5.6665 3.88889V6.70667C5.6665 7.17778 5.85317 7.63111 6.19095 7.96889L9.22206 11L6.18206 14.04C5.85317 14.3778 5.6665 14.8311 5.6665 15.3022V18.1111C5.6665 19.0889 6.4665 19.8889 7.44428 19.8889H14.5554C15.5332 19.8889 16.3332 19.0889 16.3332 18.1111V15.3022C16.3332 14.8311 16.1465 14.3778 15.8176 14.0489L12.7776 11L15.8087 7.97778C16.1465 7.64 16.3332 7.18667 16.3332 6.71556V3.88889C16.3332 2.91111 15.5332 2.11111 14.5554 2.11111H7.44428ZM14.5554 15V17.2222C14.5554 17.7111 14.1554 18.1111 13.6665 18.1111H8.33317C7.84428 18.1111 7.44428 17.7111 7.44428 17.2222V15L10.9998 11.4444L14.5554 15ZM10.9998 10.5556L7.44428 7V4.77778C7.44428 4.28889 7.84428 3.88889 8.33317 3.88889H13.6665C14.1554 3.88889 14.5554 4.28889 14.5554 4.77778V7L10.9998 10.5556Z"
      fill="#666666"
    />
  </svg>
</template>

<script>
export default {
  name: "HourGlassSvg",
}
</script>

<style lang="scss" scoped></style>
