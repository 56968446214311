<template>
  <v-flex>
    <v-layout>
      <v-flex class="label">
        <span class="default_gray ma-1" />
      </v-flex>
      <v-flex>
        <span :style="{ width: item.shimmerWidth }" class="default_gray ma-1" />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  props: ["item"],
}
</script>
<style lang="scss" scoped>
.label {
  span {
    width: 20%;
  }
}
</style>
