<template>
  <v-form class="creating-ipg-step mt-2">
    <inputContainer label="آی‌ پی‌های شما">
      <div :class="{ 'w-100': reuseIsMobile }">
        <v-flex class="withlabel big comboboxSmallchip">
          <v-combobox
            ref="ips"
            v-model="ipsList"
            hide-selected
            multiple
            small-chips
            solo
            :rules="ipsRule"
            flat
            outlined
            deletable-chips
            @input.native="convertNumbers2English($event.srcElement.value)"
            @keypress="checkIps($event)"
            @focus="focusIpsField"
            @blur="blurIpsField"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-combobox>
        </v-flex>
        <transition-group v-if="reuseIsMobile || isInModal" name="list">
          <div
            v-for="(item, index) in ipsGuidanceItems"
            v-show="$store.state.newbusiness.ipsFieldFocusState"
            :key="index"
            class="align-center max-width-340"
          >
            <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
              info_outline
            </v-icon>
            <span class="font-11-no-bbb-18"> {{ item }} </span>
            <br v-if="index !== ipsGuidanceItems.length - 1" />
          </div>
        </transition-group>
      </div>
    </inputContainer>

    <inputContainer label="دریافت کارمزد" :optional="true">
      <v-flex class="withlabel">
        <v-radio-group v-model="wage" row>
          <v-radio label="از خودم" :value="true" />
          <v-radio label="از پرداخت‌کننده" :value="false" />
        </v-radio-group>
      </v-flex>
    </inputContainer>
  </v-form>
</template>

<script>
import inputContainer from "@/components/elements/inputContainer.vue"
import { mapMutations } from "vuex"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "CreatingIpgThirdPart",
  components: {
    inputContainer,
  },
  mixins: [screenSize],
  inject: ["ipsGuidanceItems"],
  props: {
    isInModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ipsList: [],
      ipValidationPattern:
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      ipsRule: [
        (v) => v.length !== 0 || "<span> وارد کردن آی‌ پی‌ الزامی است</span>",
        (v) =>
          (!!v && this.checkFormat(v, this.ipValidationPattern)) ||
          "<span> فرمت صحیح آی‌ پی‌ را وارد کنید</span>",
      ],
      wage: false,
      formValidation: false,
    }
  },
  computed: {
    providePayload() {
      return {
        ipsList: this.ipsList,
        wage: this.wage,
        isValid: this.ipsList.length > 0,
      }
    },
  },
  watch: {
    providePayload(val) {
      this.$emit("third-form-validation", val)
    },
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.ips.lazySearch
      },
      (val) => {
        if (val) {
          this.$refs.ips.lazySearch = this.convertNumbers2English(val).replace(
            "٫",
            "."
          )
        }
      }
    )
  },
  methods: {
    ...mapMutations("newbusiness", [
      "changeIpsFieldFocusState",
      "changeWageFieldShowingState",
    ]),
    checkFormat(items, selectedPattern) {
      let doesAllItemsMatch = true

      for (let element of items) {
        if (element.includes("localhost")) continue
        //convert farsi digits to english
        element = this.convertNumbers2English(element)
        if (selectedPattern && !selectedPattern.test(element)) {
          // if item does not match the pattern return false
          doesAllItemsMatch = false
          return false
        }
      }

      if (doesAllItemsMatch) return true
      return false
    },
    checkIps(event) {
      if (
        !/(^[0-9۰-۹٫]*$)/.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "."
      ) {
        event.preventDefault()
      }
    },
    focusIpsField() {
      this.changeIpsFieldFocusState(true)
      this.changeWageFieldShowingState(false)
    },
    blurIpsField() {
      this.changeIpsFieldFocusState(false)
      this.changeWageFieldShowingState(true)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input--radio-group:not(.simpleRadio)
  .v-input__control
  .v-input__slot
  .v-radio {
  border-radius: 4px;
  padding: 7px 12px 7px;
  margin-left: 8px;
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}

.max-width-340 {
  max-width: 340px;
}
</style>
