<template>
  <div class="activationBlock">
    <span class="topBack pendingTopBack" />
    <v-layout column class="mx-auto twoPending" style="margin: -20px">
      <v-flex class="personal_title alldone">
        <v-layout justify-space-between align-center>
          <v-flex>
            <v-layout align-center>
              <v-flex style="max-width: 60px">
                <v-img
                  :src="require('@/assets/img/newIcon/IpgPendingNew.svg')"
                  alt="avatar"
                  width="40"
                />
              </v-flex>
              <v-flex>
                <div
                  v-if="
                    $store.state.business.ipg_status.url.time !=
                    'call operation'
                  "
                  class="font_5"
                >
                  اطلاعات درگاه پرداخت در حال بررسی است
                </div>
                <div v-else class="font_5" style="line-height: 22px">
                  بررسی درگاه پرداخت بیش از زمان تعیین شده طول کشیده است. با عرض
                  پوزش، به سرعت در حال پیگیری هستیم
                </div>
              </v-flex>
              <v-flex
                v-if="
                  ($store.state.business.ipg_status.url.time = 'call operation')
                "
              >
                <div
                  v-if="
                    $store.state.business.ipg_status.url.time &&
                    $store.state.business.ipg_status.url.time.includes(':') &&
                    time != 0
                  "
                  style="text-align: left"
                >
                  <span class="font_18"> حداکثر زمان انتظار: </span>
                  <span
                    class="font_12"
                    style="
                      font-size: 20px !important;
                      color: #333 !important;
                      display: inline-block;
                      width: 90px;
                    "
                  >
                    {{ convertToTime() }}
                  </span>
                </div>
                <div v-else style="text-align: left" class="font_7">
                  در انتظار تایید وندار
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "IpgEditBlock",
  data() {
    return {
      //this is modal status
      status: false,
      time: 0,
      downloadTimer: null,
    }
  },
  watch: {
    "$store.state.business.ipg_status": function (newValue) {
      clearInterval(this.downloadTimer)
      this.businessActiveCountDown()
    },
  },
  mounted() {
    this.businessActiveCountDown()
  },
  methods: {
    convertToTime() {
      var date = new Date(null)
      if (!this.time) this.time = 0
      date.setSeconds(this.time) // specify value for SECONDS here
      var hour = parseInt(this.time / 3600)
      var result = date.toISOString().substr(13, 6)
      result = hour == 0 ? "00" + result : hour + "" + result
      return result
    },
    close_modal() {
      this.status = false
    },
    businessActiveCountDown() {
      let secondTime = 0
      let urlTime = this.$store.state.business.ipg_status.url.time
        ? this.$store.state.business.ipg_status.url.time
        : "00:00:00"

      let urlTimeSecond =
        parseInt(urlTime.split(":")[2]) +
        parseInt(urlTime.split(":")[1]) * 60 +
        parseInt(urlTime.split(":")[0]) * 3600
      secondTime = urlTimeSecond
      this.countdownfunc(secondTime)
    },
    countdownfunc(startPoint) {
      this.downloadTimer = setInterval(() => {
        startPoint -= 1
        if (startPoint <= 0) {
          clearInterval(this.downloadTimer)
        }
        this.time = startPoint
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.twoPending {
  > .personal_title:nth-child(2) {
    margin-top: 30px;
  }
}
</style>
