<template>
  <div>
    <div v-if="noCustomer">
      <empty-customer @addCustomer="add_customer()" />
    </div>
    <div v-else>
      <v-layout
        justify-space-between
        :align-center="!$vuetify.breakpoint.xsOnly"
        :column="$vuetify.breakpoint.xsOnly"
      >
        <v-flex
          :class="{
            'd-flex align-center': $vuetify.breakpoint.xsOnly,
          }"
        >
          <h2 class="mb-4 mr-0 ml-auto">مشتریان</h2>

          <v-flex class="d-flex justify-end align-start">
            <v-btn
              text
              dark
              :class="{ 'd-none': $vuetify.breakpoint.smAndUp }"
              class="btn_small_normal ml-0 mt-0"
              @click="add_customer"
            >
              مشتری جدید
            </v-btn>
          </v-flex>
        </v-flex>
        <v-spacer />
        <div>
          <v-layout justify-space-between align-start>
            <v-flex :class="{ 'w-100 mb-2': $vuetify.breakpoint.xsOnly }">
              <SearchBar
                placeholder=" جستجو با نام و شماره موبایل و شناسه واریز"
                @searchClicked="search($event)"
              />
            </v-flex>
            <v-flex :class="{ 'd-none': $vuetify.breakpoint.xsOnly }">
              <v-btn
                text
                dark
                class="btn_small_normal ml-0 mt-0"
                @click="add_customer"
              >
                مشتری جدید
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>

      <div style="position: relative">
        <!-- table skeleton -->
        <v-data-table
          v-if="desserts[0] === 'skeleton'"
          :headers="headers"
          :items="desserts"
          :options.sync="pagination"
          :server-items-length="totalDesserts"
          :items-per-page-items="rows"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'elevation-1 vandarTable smAndDownTable'
              : 'elevation-1 vandarTable'
          "
          no-data-text="مخاطبی برای نمایش وجود ندارد"
          items-per-page-text="نمایش در هر صفحه "
          hide-default-footer
          :hide-default-header="totalDesserts === 0"
        >
          <template #item>
            <!-- empty responsive table -->
            <v-layout
              v-if="$vuetify.breakpoint.smAndDown"
              align-space-between
              justify-space-between
              fill-height
              class="big"
            >
              <v-flex class="right-number font_2">
                <span style="width: 20%" class="default_gray" />
              </v-flex>
              <v-flex>
                <v-layout column>
                  <v-flex class="mb-5">
                    <v-layout>
                      <!-- right-top part Start-->
                      <v-flex>
                        <div style="display: flex; align-items: center">
                          <span style="max-width: 60%" class="default_gray" />
                        </div>
                      </v-flex>
                      <!-- right-top part End-->
                    </v-layout>
                  </v-flex>
                  <v-flex>
                    <v-layout
                      :column="$vuetify.breakpoint.xsOnly"
                      class="font_2"
                    >
                      <!-- first description part Start-->
                      <v-flex class="mb-4" style="max-width: 250px">
                        <div>
                          <span class="default_gray" />
                        </div>
                      </v-flex>
                      <!-- first description part End -->
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- empty table -->
            <tr v-else class="big">
              <!-- 1 -->
              <td style="width: 50px" class="text-center">
                <span class="default_gray" />
              </td>

              <!-- 2 -->
              <td class="text-center">
                <span style="width: 80%" class="default_gray" />
              </td>

              <!-- 3 -->
              <td style="width: 370px" class="text-left">
                <span style="width: 70%" class="default_gray" />
              </td>
              <!-- 4 -->
              <td style="width: 170px" class="text-left">
                <span style="width: 70%" class="default_gray" />
              </td>
            </tr>
          </template>
          <template #no-data>
            <v-layout wrap justify-center align-center>
              <v-flex class="vandar-table-empty">
                مخاطبی برای نمایش وجود ندارد
              </v-flex>
            </v-layout>
          </template>
        </v-data-table>

        <!-- filled table -->
        <v-data-table
          v-if="desserts[0] != 'skeleton'"
          :headers="headers"
          :items="desserts"
          :options.sync="pagination"
          :server-items-length="totalDesserts"
          :items-per-page-items="rows"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'elevation-1 vandarTable smAndDownTable'
              : 'elevation-1 vandarTable'
          "
          no-data-text="مشتری برای نمایش وجود ندارد"
          no-results-text="در حال دریافت لیست مشتری‌ها"
          items-per-page-text="نمایش در هر صفحه "
          hide-default-footer
          :hide-default-header="totalDesserts !== 0 ? false : true"
        >
          <template #item="{ item }">
            <v-layout
              v-if="$vuetify.breakpoint.smAndDown"
              align-space-between
              justify-space-between
              fill-height
              class="big"
              @click="view_function(item)"
            >
              <v-flex class="right-number font_2">
                {{ item.rowNumber }}
              </v-flex>
              <v-flex>
                <v-layout column>
                  <v-flex class="mb-5">
                    <v-layout>
                      <!-- right-top part Start-->
                      <v-flex
                        v-if="
                          item.name !== ' ' &&
                          item.name !== '' &&
                          item.name !== null
                        "
                      >
                        {{ item.name }}
                      </v-flex>
                      <v-flex v-else>
                        {{ item.mobile }}
                      </v-flex>
                      <!-- left-top part End-->
                    </v-layout>
                  </v-flex>
                  <v-flex>
                    <v-layout
                      :column="$vuetify.breakpoint.xsOnly"
                      class="font_2"
                    >
                      <!-- description part Start-->
                      <v-flex class="mb-4" style="max-width: 250px">
                        {{ item.created_at }}
                      </v-flex>
                      <!-- description part End -->
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <tr v-else class="littleHeight" @click="view_function(item)">
              <!-- 1 -->
              <td style="width: 50px" class="text-center">
                {{ item.rowNumber }}
              </td>

              <!-- 2 -->
              <td
                v-if="
                  item.name !== ' ' && item.name !== '' && item.name !== null
                "
                class="text-right"
              >
                {{ item.name }}
              </td>
              <td v-else class="text-right">
                {{ item.mobile }}
              </td>

              <!-- 3 -->
              <td style="width: 170px" class="text-right">
                {{ item.created_at }}
              </td>
            </tr>
          </template>
          <template #no-data>
            <v-layout wrap justify-center align-center style="width: 100%">
              <v-flex class="vandar-table-empty">
                مشتری برای نمایش وجود ندارد
              </v-flex>
            </v-layout>
          </template>
        </v-data-table>
        <table-paginate
          v-if="totalDesserts > 20"
          :total-pages="totalPages"
          :items-length="totalDesserts"
          :rows="rows"
          @page="pagination.page = $event"
          @rowsPerPage="pagination.itemsPerPage = $event"
        />
      </div>
    </div>

    <!-- modals -->
    <v-dialog
      v-model="status"
      :content-class="
        $store.state.secondEditCustomerModal
          ? 'secondModal vanmodal'
          : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <add
        v-show="add"
        :key="componentKey"
        @updateTable="getDataAndAssignIt"
        @closeModal="close_modal"
      />
      <show
        v-if="view"
        :key="`show-${$store.state.customer.selectedCustomer.id}`"
        :item="selectedForm"
        @closeModal="close_modal"
        @updateTable="getDataAndAssignIt"
      />
    </v-dialog>
  </div>
</template>
<script>
import show from "../modals/show.vue"
import SearchBar from "@/components/inputs/SearchBar"
import EmptyCustomer from "@/components/customer/elements/emptyCustomer"
import add from "../modals/add.vue"
import TablePaginate from "../../elements/tablePaginate"
import servicesRepository from "@/services/servicesRepository"

export default {
  components: {
    TablePaginate,
    EmptyCustomer,
    SearchBar,
    show,
    add,
  },
  props: ["update"],
  data() {
    return {
      componentKey: 0,
      totalDesserts: 1,
      desserts: [],
      loading: true,
      //default value for pagination
      pagination: {
        descending: false,
        page: 1,
        itemsPerPage: 20,
        // sortBy: null,
        itemsLength: 0,
      },
      totalPages: 0,
      rows: ["20", "50", "200", "500"],
      headers: [
        {
          text: " ",
          align: "right",
          sortable: false,
          value: "status",
        },
        {
          text: "نام / تلفن همراه",
          align: "right",
          sortable: false,
          value: "status",
        },
        {
          text: "زمان ثبت",
          align: "right",
          sortable: false,
          value: "created_at",
        },
      ],
      selectedForm: {},
      downloadLoading: false,
      searchQuery: null,

      //modals status
      status: false,
      add: false,
      view: false,
      noCustomer: false,
    }
  },
  watch: {
    desserts: {
      handler() {
        if (this.desserts.length == 0 && this.loading == true) {
          for (let index = 0; index < this.pagination.itemsPerPage; index++) {
            this.desserts[index] = "skeleton"
          }
        }
      },
      immediate: true,
    },
    pagination: {
      handler() {
        this.getDataAndAssignIt()
      },
      deep: true,
    },
    update(newValue, oldValue) {
      this.getDataAndAssignIt()
    },
    status: function (newValue) {
      if (!newValue) {
        this.add = false
        this.view = false
      }
    },
  },
  methods: {
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.searchQuery = newValue
        this.getDataAndAssignIt()
      }
    },
    add_customer() {
      this.status = true
      this.add = true
    },
    view_function(obj) {
      this.selectedForm = obj
      this.$store.commit("setSelectedCustomer", obj)
      this.status = true
      this.view = true
    },
    add_form(form) {
      this.updatedForm = form
      this.addIsFinalize = true
      this.forms.unshift(form)
    },
    close_modal(obj) {
      this.add = false
      this.view = false
      this.status = false

      this.$store.commit("revokeCustomer")
      this.$store.commit("callSecondEditCustomerModal", false)
      this.selectedForm = {}
      this.componentKey++
      if (obj && obj.status) {
        this.getDataAndAssignIt()
      }
    },
    getDataFromApi() {
      this.loading = true
      this.desserts = []
      return new Promise((resolve, reject) => {
        const { sortBy, descending, page, itemsPerPage } = this.pagination
        var url

        url = "/customers?page=" + page + "&per_page=" + itemsPerPage

        //add search query
        if (this.searchQuery) {
          url = url + "&q=" + this.searchQuery
        }

        servicesRepository(this)
          .customers.httpGetCustomers(url)
          .then((response) => {
            this.noCustomer = false

            this.loading = false
            if (response.data.data !== null) {
              var items = response.data.data

              //add rowNumber to the array
              items.forEach((element, index) => {
                element.rowNumber =
                  index +
                  (response.data.meta.current_page - 1) *
                    response.data.meta.per_page +
                  1

                //test data
                if (!element.mobile || element.mobile == "")
                  element.mobile = "-"
              })

              var total = response.data.meta.total
              this.totalPages = response.data.meta.last_page
            }
            resolve({
              items,
              total,
            })
          })
          .catch((error) => {
            if (error === "عدم دسترسی کسب و کار") {
              this.noCustomer = true
            }
            // this.$store.commit('callsnackbar', error)
          })
      })
    },
    getDataAndAssignIt() {
      this.getDataFromApi().then((data) => {
        this.desserts = data.items
        this.totalDesserts = data.total
      })
    },
  },
}
</script>
