<template>
  <v-card
    flat
    class="card-hover d-flex px-5 py-3 mb-3 align-center radius-4"
    height="83"
  >
    <v-card
      flat
      min-width="36"
      max-width="36"
      width="36"
      height="36"
      color="default_gray"
      class="ml-4 radius-50"
    />
    <div class="font_24 default_gray ml-14 width120" />
    <div class="font_2 default_gray ml-14 width120" />
    <div class="font_2 default_gray width120" />
    <div
      class="font_24 text-left default_gray width120"
      style="margin-right: auto"
    />
  </v-card>
</template>

<script>
export default {
  name: "TableSkeleton",
}
</script>

<style lang="scss" scoped>
.width120 {
  width: 100px;
  max-width: 100px;
}
</style>
