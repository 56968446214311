<template>
  <v-hover v-slot="{ hover }">
    <v-img
      width="46px"
      aspect-ratio="1"
      :src="icon"
      class="ml-2 rounded-lg overflow-hidden"
    >
      <v-fade-transition>
        <v-overlay
          v-if="hover || loading"
          class="d-flex align-center justify-center position-relative full-width full-height"
        >
          <v-btn :loading="loading" small icon @click="deleteFile(file.name)">
            <v-img
              :src="require('@/assets/img/file-uploader/delete.svg')"
            ></v-img>
          </v-btn>
        </v-overlay>
      </v-fade-transition>
    </v-img>
  </v-hover>
</template>

<script>
export default {
  name: "FileUploaderUploadedFile",
  props: {
    file: {
      type: Object,
      required: true,
    },
    formId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      files: [],
      fileIcons: {
        pdf: require("@/assets/img/file-uploader/pdf.svg"),
        excel: require("@/assets/img/file-uploader/excel.png"),
      },
    }
  },
  computed: {
    icon() {
      switch (this.file.extension) {
        case "pdf":
          return this.fileIcons["pdf"]
        case"xlsx":
        case"xlsm":
        case"xlsb":
        case"xltx":
        case"xltm":
        case"xls":
        case"xml":
        case"csv":
        case"xla":
        case"xlw":
          return this.fileIcons["excel"]
        default:
          return this.file.url
      }
    },
  },
  methods: {
    deleteFile(fileName) {
      this.loading = true
      this.$http
        .delete(
          `${this.$store.state.file_uploader_api_base_url}/business/${this.$store.state.business.business_name}`,
          {
            params: {
              form_id: this.formId,
              file_name: fileName,
            },
          }
        )
        .then((response) => {
          this.$emit("delete-file", fileName)
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
</style>
