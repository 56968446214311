<template>
  <!-- upload logo -->
  <div class="d-flex align-center logo_upload">
    <v-flex class="position-relative" flat width="100%">
      <v-layout justify-start align-center>
        <v-flex
          :class="$vuetify.breakpoint.xsOnly ? 'label mb-1' : 'label ma-0'"
          style="width: 110px"
        >
          {{ labelText }}
          <span>*</span>
        </v-flex>
        <div v-if="!startUpload">
          <input
            id="file"
            ref="file"
            type="file"
            accept="image/*"
            @change="handleFile()"
            @focus="focusUploadInput"
            @blur="blurUploadInput"
          />
          <v-layout justify-center align-center class="uploadbox">
            <v-flex class="ml-2">
              <v-icon color="van_color05" size="20"> backup </v-icon>
            </v-flex>
            <v-flex class="font_18"> بارگذاری </v-flex>
          </v-layout>
        </div>
        <v-layout
          v-else
          justify-center
          align-content-space-around
          column
          class="uploadloader"
        >
          <v-flex>
            <v-progress-circular
              v-if="proggressBar != 100"
              :value="proggressBar"
              color="van_green"
              background-color="van_color06"
              size="45"
              style="z-index: 1"
              width="4"
              class="progressbar"
            >
              {{ proggressBar }}%
            </v-progress-circular>
            <v-hover v-slot="{ hover }">
              <v-card outlined class="mx-auto pa-0" color="grey lighten-4">
                <v-img
                  :src="image"
                  contain
                  class="thumb"
                  @click="deleteFile()"
                />
                <v-icon
                  v-if="proggressBar == 100 && hover"
                  class="delete"
                  dark
                  size="20"
                  color="van_color03"
                  @click="deleteFile()"
                />
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-flex>
    <v-dialog
      v-if="openCropDialog"
      v-model="openCropDialog"
      max-width="400px"
      width="100%"
      no-click-animation
      persistent
      content-class="cropdialog"
    >
      <cropper-dialog
        v-if="openCropDialog"
        :result="result"
        :aspect-ratio="aspectRatio"
        @uploadFormData="uploadCropedPhoto"
        @closeCrop="
          $refs.file.value = null
          openCropDialog = false
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import CropperDialog from "../elements/cropperDialog.vue"
import Compressor from "compressorjs"
import { mapMutations } from "vuex"

export default {
  name: "UploadBox",
  components: {
    CropperDialog,
  },
  props: {
    labelText: {
      type: String,
      default: "",
    },
    quality: {
      type: Number,
      default: 0.8,
    },
    maxWidth: {
      type: Number,
      default: 1000,
    },
    maxHeight: {
      type: Number,
      default: 1000,
    },
    convertSize: {
      type: Number,
      default: 1000000,
    },
    maxSize: {
      type: Number,
      default: 2000000,
    },
    aspectRatio: {
      type: String,
      default: "custom",
    },
  },
  data() {
    return {
      // upload photo
      file: "",
      proggressBar: 0,
      startUpload: false,
      uploadedfile: "",
      size: 0,
      format: "",
      success: false,
      result: null,
      openCropDialog: false,
      cropPhoto: "",
    }
  },
  computed: {
    image() {
      if (this.cropPhoto == "") {
        if (this.startUpload) {
          return (
            this.$store.state.storage_base_url +
            this.$store.state.business.avatar
          )
        }
        return
      }
      return URL.createObjectURL(this.cropPhoto)
    },
  },
  methods: {
    ...mapMutations("newbusiness", ["changeUploadInputFocusState"]),
    //upload method
    handleFile() {
      this.file = this.$refs.file.files[0]
      this.size = this.file.size
      this.format = this.file.type

      if (
        this.file["type"].split("/")[0] === "image" &&
        this.file["type"].split("/")[1] !== "gif"
      ) {
        this.compressAndUpload()
      } else if (
        this.file["type"].split("/")[0] !== "image" ||
        this.file["type"].split("/")[1] === "gif"
      ) {
        this.$store.commit(
          "callsnackbar",
          "فرمت فایل انتخاب شده قابل قبول نیست"
        )
      }
    },
    uploadCropedPhoto(formData) {
      //start uploading to server
      this.success = true
      this.startUpload = true
      this.openCropDialog = false
      this.cropPhoto = formData.get("image")
      this.$http
        .post(
          this.$store.state.api_base_url + "/modules/upload/image",
          formData,
          {
            timeout: 50000,
            onUploadProgress: (progressEvent) => {
              this.proggressBar = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            },
          }
        )
        .then((response) => {
          this.uploadedfile = response.data.data
          this.$emit("uploadedfile", this.uploadedfile)
        })
        .catch((error) => {
          this.deleteFile()
          this.$store.commit(
            "callsnackbar",
            "در بارگذاری فایل خطا رخ داده است،لطفا دوباره تلاش کنید"
          )
          //callsnackbar
        })
    },
    compressAndUpload() {
      new Compressor(this.file, {
        quality: this.quality,
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        convertSize: this.convertSize,
        success: (result) => {
          if (result.size > this.maxSize) {
            this.$store.commit(
              "callsnackbar",
              "حجم فایل انتخاب شده بیشتر از حد مجاز است"
            )
          } else {
            this.result = result
            this.openCropDialog = true
          }
        },
        error(err) {
          this.deleteFile()
          store.commit("callsnackbar", "لطفا دوباره تلاش کنید")
          Sentry.captureException(err)
        },
      })
    },
    deleteFile() {
      this.file = null
      this.proggressBar = 0
      this.startUpload = false
      this.cropPhoto = ""
      this.uploadedfile = ""
      this.$emit("uploadedfile", "")
    },
    focusUploadInput() {
      this.changeUploadInputFocusState(true)
    },
    blurUploadInput() {
      this.changeUploadInputFocusState(false)
    },
  },
}
</script>
<style lang="scss" scoped>
.progressbar {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.logo_upload {
  input {
    height: 100px;
    overflow: hidden;
    width: 100px;
    opacity: 0;
    z-index: 3;
    position: relative;
    cursor: pointer;
  }
  .uploadbox {
    border: 1px dashed #bbbbbb !important;
    position: absolute;
    height: 100px;
    width: 100px;
    border-radius: 4px;
    top: 0;
    > .flex {
      flex: 0 0 auto;
    }
  }
  .uploadloader {
    height: 100px;
    // border: 1px dashed #bbbbbb !important;
    width: 100px;
    border-radius: 4px;
    position: sticky;
    overflow: hidden;
    max-width: 100px;

    > .flex {
      flex: 0 0 auto;
      height: 100%;

      .v-card {
        height: 100%;
      }
    }
    .delete {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .thumb {
    height: 100%;

    &:hover {
      opacity: 0.2;
      cursor: pointer;
      z-index: 3;
    }
  }
}
</style>
