<template>
  <v-layout class="emptytool" justify-center align-center fill-height>
    <v-flex>
      <v-card outlined style="box-shadow: 0px 2px 20px #00000026">
        <v-layout justify-center align-center column>
          <v-flex class="font_1">مدیریت تعاملات با مشتریان</v-flex>
          <v-flex class="font_10 mt-2">
            <span v-if="$store.state.business.status !== 1">
              در این بخش امکان مشاهده اطلاعات مشتریان و افزودن مشتری جدید دارید.
              پس از تایید کسب‌وکار این ابزار برای شما فعال می‌شود.
            </span>
          </v-flex>
          <v-flex v-if="$store.state.business.status === 1" class="mt-7">
            <v-btn
              text
              dark
              class="btn_small_normal my-0"
              @click="$emit('addCustomer', true)"
            >
              افزودن مشتری جدید
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "EmptyCustomer",
}
</script>

<style scoped></style>
