<template functional>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_163_607)">
      <path
        d="M14.5125 7.51314C14.2935 6.18582 13.6224 4.97789 12.6156 4.09878C11.6087 3.21967 10.3296 2.72481 9 2.70001C7.9544 2.71151 6.9329 3.01921 6.05131 3.58823C5.16973 4.15724 4.46333 4.96482 4.0122 5.91939C2.88427 6.07549 1.85234 6.64555 1.11261 7.52119C0.372879 8.39684 -0.0230854 9.51702 -4.53379e-06 10.6688C-0.0280488 11.9061 0.429891 13.1043 1.27343 14.0005C2.11697 14.8968 3.27727 15.418 4.5 15.45H14.2497C15.2689 15.424 16.2362 14.9898 16.9393 14.2428C17.6425 13.4958 18.0239 12.497 18 11.4656C18.0201 10.4828 17.6715 9.52865 17.0245 8.79532C16.3774 8.06199 15.4799 7.60387 14.5125 7.51314ZM14.2497 13.8563H4.5C3.68461 13.8357 2.91064 13.4885 2.34816 12.8908C1.78568 12.2931 1.48072 11.4939 1.5003 10.6688C1.48143 9.90114 1.74353 9.15354 2.23624 8.56957C2.72895 7.98559 3.41753 7.60644 4.1697 7.50494L4.9725 7.41387L5.3478 6.65707C5.67583 5.95586 6.19237 5.3623 6.83828 4.94434C7.48419 4.52638 8.23335 4.30092 9 4.29376C9.97473 4.31338 10.9121 4.67681 11.6502 5.3213C12.3883 5.96578 12.8809 6.85086 13.0428 7.82369L13.2678 9.01855L14.4153 9.10962C15.0045 9.17715 15.5469 9.46712 15.9342 9.92162C16.3214 10.3761 16.5249 10.9617 16.5041 11.5614C16.4833 12.1611 16.2397 12.7308 15.822 13.1567C15.4042 13.5826 14.8431 13.8334 14.2506 13.859L14.2497 13.8563ZM6.0003 9.87189H7.9128V12.2625H10.0881V9.87189H12.0006L9 6.68439L6.0003 9.87189Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_163_607">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CloudUploadSvg",
}
</script>

<style lang="scss" scoped></style>
