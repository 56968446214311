<template>
  <wallet
    :load="entity.hasOwnProperty('status')"
    :status="entity.status"
    :with-label="withLabel"
    :dark="dark"
    :amount="entity.wallet"
    :blocked_amount="entity.blocked_amount"
    :center="center"
    :access="access"
  />
</template>

<script>
import wallet from "./wallet"
export default {
  name: "WalletForUserOrBusiness",
  components: {
    wallet,
  },
  props: {
    withLabel: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    access: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    entity() {
      if (this.$store.state.isUser) return this.$store.state.user
      else return this.$store.state.business
    },
  },
}
</script>

<style></style>
