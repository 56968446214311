import service from "./service"
import serviceV2 from "./serviceV2"

export default ($http, context) => ({
  httpIpgIncome(chartType) {
    const queryParam = "/stat?code=ipg_income_" + chartType
    return service.get($http, context, queryParam)
  },
  httpIpgIncomeCount(chartType) {
    const queryParam = "/stat?code=ipg_income_count_" + chartType
    return service.get($http, context, queryParam)
  },
  httpIpgUnsuccessfulDepositsAverage() {
    const queryParam = "/stat?code=ipg_unsuccessful_deposits_average"
    return service.get($http, context, queryParam)
  },
  httpIpgUnsuccessfulDepositsAverageV2() {
    const url = `/ipg/unsuccessful-deposit`
    return serviceV2.get($http, context, url)
  },
  httpIpgErrorResources(chartType) {
    const queryParam = "/stat?code=ipg_error_resources_" + chartType
    return service.get($http, context, queryParam)
  },
  httpIpgErrorResourcesV2(item) {
    const queryParam = `/ipg/error-resources?resolution=${item.timePeriod}`
    return serviceV2.get($http, context, queryParam)
  },
  httpIpgErrorFrequency(chartType) {
    const queryParam = "/ipg-stat?code=ipg_error_frequency_" + chartType
    return service.get($http, context, queryParam)
  },
  httpIpgErrorFrequencyV2(item) {
    const queryParam = `/ipg/error-frequency?resolution=${item.timePeriod}`
    return serviceV2.get($http, context, queryParam)
  },
  httpIpgDeposit(item) {
    const queryParam = `/ipg/deposit?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpIpgSuccessDeposit(item) {
    const queryParam = `/ipg/success-deposit?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
})
