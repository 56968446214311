<template>
  <v-card class="upload">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="closeModal()">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">بارگذاری روزنامه رسمی</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout class="modal-container">
      <div class="main-card-container w-100">
        <v-card outlined class="main-card mb-4" flat>
          <span class="font_13">روزنامه رسمی چیست؟</span><br />
          <span class="font-12-no-000-24">
            روزنامه رسمی را از سایت
            <a
              href="https://www.rrk.ir/News/NewsList.aspx"
              class="ma-0"
              target="_blank"
            >
              روزنامه رسمی
            </a>
            دریافت سپس بارگذاری کنید.
          </span>
        </v-card>
        <v-card outlined class="d-flex main-card pa-0" flat>
          <div
            class="d-flex align-center justify-center flex-column"
            style="background: #eef6f3; width: 80px; height: 144px"
          >
            <v-img
              width="48px"
              height="49px"
              max-height="49px"
              max-width="48px"
              :src="require('@/assets/img/guide-icon-second.svg')"
            />
            <span class="font_28">راهنما</span>
          </div>
          <ul class="d-flex flex-column justify-center mr-4 font-12-no-000-24">
            <li>واضح و تمام صفحه باشد</li>
            <li>نام مدیرعامل و صاحبین امضا را داشته باشد</li>
            <li>طبق آخرین تغییرات هیات مدیره باشد</li>
          </ul>
        </v-card>

        <UploadBox2
          :is-width100="true"
          @uploaded-file="uploadedFile = $event"
        />
      </div>
    </v-layout>
  </v-card>
</template>

<script>
import UploadBox2 from "@/components/elements/UploadBox2"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "AddOfficialNewspaper",
  components: {
    UploadBox2,
  },
  data() {
    return {
      loading: false,
      uploadedFile: "",
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.uploadedFile !== "") return true
      else return false
    },
  },
  methods: {
    sendForm() {
      if (this.uploadedFile !== "") {
        let data = {
          official_Newspaper: this.uploadedFile,
        }

        servicesRepository(this)
          .onBoardingService.httpUpload(data)
          .then((response) => {
            this.$store.commit("setBusiness", response.data.data)
            this.loading = false
            this.closeModal()
            this.$emit("handle-success-state")
          })
          .catch((error) => {
            this.loading = false
            //callsnackbar
          })
      }
    },
    closeModal() {
      this.$store.commit("callAddDocumentmodal", false)
      this.$emit("closeModal")
    },
  },
}
</script>

<style lang="scss">
ul {
  list-style-position: inside;
  list-style-image: url("@/assets/img/bullet.svg");
}
</style>
