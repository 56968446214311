<template>
  <div class="px-3 py-2">
    <v-layout v-if="showRequestCodeBtn" column align-center justify-center>
      <v-flex>
        <img
          style="max-width: inherit"
          :src="require('@/assets/img/newIcon/banksLogoCollection.svg')"
        />
      </v-flex>
      <v-flex class="font-size-16 font-weight-bold van_color02--text"
        >شناسه واریز</v-flex
      >
      <v-flex class="font_2 mt-2 text-center">
        <div>به صورت دستی از هر بانکی، کیف‌پول وندارتان را شارژ کنید.</div>
        <div class="font-size-11 van_color03--text">
          کارمزد هر تراکنش واریز با شناسه، «<span class="bold">{{
            $store.state.business.high_value_fee
          }}</span
          >٪» است.
        </div>
      </v-flex>
      <v-flex v-if="$store.state.business.status === 1" class="mt-2">
        <v-btn
          text
          dark
          :loading="loading"
          class="btn_small_normal"
          @click="getCashInCode"
        >
          دریافت شناسه واریز
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout v-else column>
      <v-flex class="font_2 mb-0 mt-5">
        <div>
          با استفاده از شناسه واریز بانک موردنظر، مبلغ مورد نظرتان را به حساب
          وندار‌ در همان بانک واریز کنید. پس از واریز وجه، موجودی کیف پول شما
          افزایش می‌یابد.
        </div>
        <ul class="mt-2 pa-0">
          <li style="list-style: inside" class="mt-3">
            کارمزد هر تراکنش واریز با شناسه، «<span class="bold">{{
              $store.state.business.high_value_fee
            }}</span
            >٪» است.
          </li>
          <li
            v-if="$route.name !== 'customer'"
            style="list-style: inside"
            class="mt-3"
          >
            <span>
              <span
                >واریز فقط از حساب حقوقی کسب‌و‌کار یا صاحبین امضا آن امکان‌پذیر
                است.</span
              >
              <span class="linkBorder ml-0 mr-0">
                <v-btn
                  class="small ma-0 pa-0 font-size-11"
                  text
                  :ripple="false"
                  @click="callAddIban()"
                  >افزودن شبا</v-btn
                >
              </span>
            </span>
          </li>
          <li style="list-style: inside" class="mt-3">
            در واریز شناسه‌دار، با انتخاب بانک مبدا و مقصد یکسان، تراکنش سریع‌تر
            انجام خواهد شد.
          </li>
        </ul>
        <p class="statement-style">
          واریز با همه روش‌های پرداخت، به <b>غیر از پل</b> امکان‌پذیر است.
        </p>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="showAddBusinessIban"
      :content-class="
        $store.state.addBusinessIbanIsActive
          ? 'secondModal vanmodal'
          : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <setting-business-ibans
        v-if="showAddBusinessIban"
        @closeModal="close_modal()"
      />
    </v-dialog>
  </div>
</template>

<script>
import CopyBtnGeneral from "@/components/elements/copyBtnGeneral"
import { store } from "@/store"
import SettingBusinessIbans from "@/components/modals/settingBusinessIbans.vue"
export default {
  name: "ManualCashIn",
  components: { SettingBusinessIbans },
  data() {
    return {
      loading: false,
      showAddBusinessIban: false,
    }
  },
  computed: {
    showRequestCodeBtn() {
      return !this.$store.state.business?.cash_in_code?.length
    },
  },
  watch: {
    showAddBusinessIban: function (newValue) {
      if (!newValue) {
        this.$store.commit("changeShowSecondModal", false)
      }
    },
  },
  methods: {
    callAddIban() {
      this.$store.commit("changeShowSecondModal", true)
      this.showAddBusinessIban = true
    },
    openDetails() {
      this.$emit("showManualCashInIntroduce")
    },
    getCashInCode() {
      this.loading = true
      this.$http
        .get(this.$store.getters.apiUrlParamV3 + "/cash-in/code")
        .then((response) => {
          this.$store.commit("changeCashInCode", response.data.data)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    close_modal() {
      this.showAddBusinessIban = false
    },
  },
}
</script>

<style lang="scss" scoped>
.statement-style {
  background-color: $van_color_yellow1;
  border-radius: 4px;
  padding: 8px;
  margin: 8px 0;
}
</style>
