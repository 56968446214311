<template>
  <div class="home">
    <v-layout v-if="$store.state.user.has_transactions">
      <v-flex style="height: 100%; width: 100%">
        <wallet-status />
      </v-flex>
    </v-layout>
    <new-business-card />

    <v-dialog
      v-model="openPbvModal"
      transition="slide-x-transition"
      :content-class="mandateModal ? 'left-modal secondModal' : 'left-modal'"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <choose-bank
        v-if="openPbvModal"
        @openMandateModal="openMandateModal"
        @closeModal="close_modal"
      />
    </v-dialog>
    <v-dialog
      v-if="mandateModal"
      v-model="mandateModal"
      transition="slide-x-transition"
      :content-class="'left-modal'"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <mandate-details :bank-info="bankInfo" @closeModal="close_modal" />
    </v-dialog>

    <v-dialog
      v-if="onboardingModal"
      v-model="onboardingModal"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '580'"
      no-click-animation
      persistent
    >
      <onboarding
        @onboardingModal="onboardingModal = false"
        @openPbvModal="
          close_modal()
          openPbvModal = true
        "
      />
    </v-dialog>
    <v-dialog
      v-if="mandateList"
      v-model="mandateList"
      transition="slide-x-transition"
      :content-class="
        mandateModal && openPbvModal
          ? 'left-modal thirdModal'
          : openPbvModal
          ? 'left-modal secondModal'
          : 'left-modal'
      "
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <mandate-list
        @closeModal="close_modal"
        @openPbvModal="openPbvModal = true"
        @updateMandateCard="updateMandateCard = true"
      />
    </v-dialog>

    <!--   callback-->
    <general-dialog
      v-if="approveMandate"
      :active="approveMandate"
      :logo="callbackInfo.status === 'SUCCEED' ? '1stCheck.svg' : 'Failed.svg'"
      content-class="confirmationMessageDialog"
      :title="callbackInfo.title"
      :width="360"
      :btn1-text="callbackInfo.status === 'SUCCEED' ? 'فهمیدم' : null"
      :btn2-text="callbackInfo.status !== 'SUCCEED' ? 'فهمیدم' : null"
      @btn1Clicked="approveMandate = false"
      @btn2Clicked="approveMandate = false"
    >
      <template
        v-if="callbackInfo.status === 'SUCCEED' && approvedMandateInfo"
        #extra
      >
        <v-card outlined flat class="d-flex pa-5 mt-2" color="van_color11">
          <v-card outlined flat color="#fff" border class="pa-4 ml-3">
            <v-img
              width="32"
              height="32"
              alt="لوگو بانک"
              class="ma-0"
              :src="
                require('@/assets/img/banks/' +
                  parseInt(approvedMandateInfo.bank_code) +
                  '.svg')
              "
            />
          </v-card>
          <div class="d-flex justify-space-between flex-column">
            <div class="font_2">
              {{ approvedMandateInfo.bank_name }}
            </div>
            <div class="font_2">
              سقف {{ approvedMandateInfo.limit | currencyWithRialInput }}
              {{ currencyName }} تا {{ approvedMandateInfo.expiration_date }}
            </div>
          </div>
        </v-card>
      </template>
      <template v-else #extra>
        <div class="font_2">
          {{ callbackInfo.subtitle }}
        </div>
      </template>
    </general-dialog>
  </div>
</template>

<script>
import WalletStatus from "../../elements/walletStatus"
import chooseBank from "@/components/pbv/modals/chooseBank"
import mandateDetails from "@/components/pbv/modals/mandateDetails"
import mandateList from "@/components/pbv/modals/mandateList"
import onboarding from "@/components/pbv/modals/onboarding.vue"
import NewBusinessCard from "@/components/pbv/elements/newBusinessCard"
import GeneralDialog from "@/components/modals/generalDialog"

export default {
  name: "PersonalHome",
  components: {
    NewBusinessCard,
    WalletStatus,
    chooseBank,
    mandateDetails,
    onboarding,
    mandateList,
    GeneralDialog,
  },
  data() {
    return {
      bankInfo: {},
      approveMandate: false,
      hasPbvMandate: null,
      approvedMandateInfo: {},
      callbackInfo: {},
      mandateModal: false,
      onboardingModal: false,
      status: false,
      openPbvModal: false,
      mandateList: false,
      updateMandateCard: false,
    }
  },
  mounted() {
    this.approveMandate =
      this.$route.params.approveMandate === true ? true : false
    this.callbackInfo = this.$route.params.callbackInfo ?? null
    if (this.callbackInfo && this.callbackInfo.status !== "SUCCEED")
      this.openPbvModal = true
    this.approvedMandateInfo = this.$route.params.approvedMandateInfo ?? null
  },
  methods: {
    openMandateModal(bankInfo) {
      this.bankInfo = bankInfo
      this.mandateModal = true
    },
    close_modal(event) {
      switch (event) {
        case "chooseBank":
          this.openPbvModal = false
          break
        case "mandateDetails":
          this.mandateModal = false
          break
        case "mandateList":
          this.mandateList = false
          break
      }
      this.onboardingModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .home {
    max-width: 920px;
    margin: auto;
  }
}
.flex.xs4 {
  align-self: flex-end;
  height: 288px;
  min-width: 250px;
}
.theme--light.v-divider {
  border-color: $van_color05;
  border-style: dashed;
}
</style>
