<template>
  <div
    class="mainDiv d-flex justify-space-between pb-2 mb-2 font_31 van_color02--text"
  >
    <div>{{ label }}</div>
    <div v-if="amount === -1">نامحدود</div>
    <div v-else>
      {{ amount | currency }}
      {{ currencyName }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowDailyAmount",
  props: {
    amount: {
      type: Number,
    },
    label: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.mainDiv {
  border-bottom: 1px dashed $van_color06;
}
</style>
