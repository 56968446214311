<template>
  <v-layout justify-center align-center fill-height>
    <v-layout column wrap justify-center align-start>
      <section class="chart-title">
        <h2
          v-if="
            $store.state.hasOwnProperty('business') &&
            $store.state.business.hasOwnProperty('tools') &&
            $store.state.business.tools.hasOwnProperty('ipg') &&
            $store.state.business.tools.ipg.is_created
          "
          class="mr-0 mb-7 ml-auto"
        >
          درگاه پرداخت
        </h2>
        <KerdarNavigationLink />
      </section>
      <!-- chart -->
      <v-flex
        v-if="
          $store.state.hasOwnProperty('business') &&
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.hasOwnProperty('ipg') &&
          $store.state.business.tools.ipg.is_created &&
          $store.state.business.status == 1 &&
          $store.state.business.role != 'developer'
        "
        style="width: 100%"
        class="mb-5"
      >
        <IpgDepositEchart :duration="duration" :kerdar-lang="kerdarLang" />
      </v-flex>
      <!-- cards -->
      <v-flex style="width: 100%">
        <listOfCards />
      </v-flex>
      <!-- guide -->
      <v-flex
        v-if="
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.ipg.status != 'ACTIVE' &&
          sum == 0
        "
        class="mt-4 mb-15"
        style="width: 100%"
      >
        <guide @openAddUser="openAddUser()" />
      </v-flex>
    </v-layout>
    <general-dialog
      :active="showForNotActiveBusiness"
      logo="newIcon/Ipg-warning.svg"
      title="درگاه ساخته شد اما فعال نیست"
      description="ابتدا باید کسب‌و‌کار فعال شود. بعد از آن درخواست شما جهت بررسی و تایید، برای شاپرک ارسال می شود"
      description-class="main-des font_10"
      custom-style="height:278px"
      :width="580"
      :image-width="174"
      content-class="messageDialog"
      btn1-text="ادامه"
      @btn1Clicked="step2Show = true"
    >
      <template slot="bottom">
        <div class="carousel_direction d-flex justify-center">
          <div class="circle active" />
          <div class="circle inactive" />
        </div>
      </template>
    </general-dialog>

    <general-dialog
      :active="showForActiveBusiness"
      logo="newIcon/check.svg"
      title="درخواست شما ثبت شد، در انتظار فعال‌سازی باشید"
      description="درخواست شما جهت بررسی و تایید، برای شاپرک ارسال شد. تا آمدن جواب باید منتظر باشیم"
      description-class="main-des font_10"
      custom-style="height:278px"
      :width="580"
      content-class="messageDialog"
      btn1-text="ادامه"
      @btn1Clicked="step2Show = true"
    >
      <template slot="bottom">
        <div class="carousel_direction d-flex justify-center">
          <div class="circle active" />
          <div class="circle inactive" />
        </div>
      </template>
    </general-dialog>

    <general-dialog
      v-if="step2Show"
      :active="step2Show"
      title="وقت توسعه درگاه است"
      description="این موارد در بخش‌های مختلف وب‌سایت و میزکار وندار در دسترس شما خواهند بود"
      description-class="main-des font_10"
      custom-style="height:552px"
      :width="580"
      content-class="messageDialog withoutIcon"
    >
      <template slot="extra">
        <v-layout column style="width: 100%">
          <v-flex class="mx-auto mb-5">
            <v-btn
              text
              outlined
              class="depressed btn_small_normal"
              @click="closeModal"
            >
              بعدا انجام می‌دهم
            </v-btn>
          </v-flex>
          <v-flex>
            <v-layout class="extra_box">
              <v-flex class="image">
                <img width="46" src="@/assets/img/newIcon/key.svg" />
              </v-flex>
              <v-flex>
                <div class="font_5 bold">API کلید</div>
                <div
                  v-if="
                    $store.state.hasOwnProperty('ipg') &&
                    $store.state.ipg.api_key
                  "
                  class="font_4"
                >
                  {{ $store.state.ipg.api_key }}
                </div>
              </v-flex>
              <v-flex class="align-self-center" style="max-width: 122px">
                <v-btn
                  text
                  dark
                  class="btn btn_small_normal primary ma-auto"
                  height="36px"
                  style="width: 122px !important"
                  @click="shareLink"
                >
                  کپی کنید
                </v-btn>
                <v-tooltip v-model="tooltip_show" top>
                  <template #activator="{ on }">
                    <v-btn
                      style="
                        position: absolute;
                        opacity: 0 !important;
                        width: 1px;
                        margin: 0;
                        padding: 0;
                        z-index: -1;
                        left: 105px;
                      "
                      v-on="on"
                    />
                  </template>
                  <span>کپی شد</span>
                </v-tooltip>
                <input
                  id="hidden-input1"
                  v-model="$store.state.ipg.api_key"
                  class="hidden-input"
                />
              </v-flex>
            </v-layout>
            <v-layout class="extra_box">
              <v-flex class="image">
                <img width="46" src="@/assets/img/newIcon/sandbox.svg" />
              </v-flex>
              <v-flex>
                <div class="font_5 bold">درخواست دسترسی به سندباکس</div>
                <div class="font_4">
                  فضای آزمون کدها مشابه یک کسب‌و‌کار فعال
                </div>
              </v-flex>
              <v-flex class="align-self-center" style="max-width: 122px">
                <v-btn
                  text
                  dark
                  class="btn btn_small_normal primary ma-auto"
                  height="36px"
                  :loading="sandboxRequestLoading"
                  style="width: 122px !important"
                  :disabled="$store.state.user.sandbox_member"
                  @click="sandboxRequest"
                >
                  <span v-if="$store.state.user.sandbox_member">ارسال شد</span>
                  <span v-else>ارسال درخواست</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout class="extra_box">
              <v-flex class="image">
                <img width="46" src="@/assets/img/newIcon/ipg-document.svg" />
              </v-flex>
              <v-flex>
                <div class="font_5 bold">مستندات فنی و پلاگین‌ها</div>
                <div class="font_4">ورود به بخش توسعه‌دهندگان وندار</div>
              </v-flex>
              <v-flex class="align-self-center" style="max-width: 122px">
                <v-btn
                  text
                  dark
                  class="btn btn_small_normal primary ma-auto"
                  height="36px"
                  style="width: 122px !important"
                  @click="openDoc()"
                >
                  توسعه‌دهندگان
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout class="extra_box">
              <v-flex class="image">
                <img width="46" src="@/assets/img/newIcon/add-user.svg" />
              </v-flex>
              <v-flex>
                <div class="font_5 bold">
                  اعطای دسترسی موارد فنی به برنامه‌نویس
                </div>
                <div class="font_4">
                  افزودن کاربر با نقش برنامه‌نویس و دسترسی محدود
                </div>
              </v-flex>
              <v-flex class="align-self-center" style="max-width: 122px">
                <v-btn
                  text
                  dark
                  class="btn btn_small_normal primary ma-auto"
                  height="36px"
                  style="width: 122px !important"
                  @click="openAddUser()"
                >
                  افزودن برنامه‌‌نویس
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
      <template slot="bottom">
        <div class="carousel_direction d-flex justify-center">
          <div class="circle active" />
          <div class="circle active" />
        </div>
      </template>
    </general-dialog>

    <v-dialog
      v-model="addUser"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <add-user
        v-show="addUser"
        :key="componentKey"
        :default-role="3"
        @closeModal="closeModal"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import AddUser from "../../modals/addUser.vue"
import GeneralDialog from "../../modals/generalDialog.vue"
import Guide from "../elements/guide.vue"
import listOfCards from "../elements/listOfCards.vue"
import IpgDepositEchart from "@/components/kerdar/tabs/ipg/elements/IpgDepositEchart"
import kerdarLang from "@/components/kerdar/utils/langResource.json"
import { duration } from "@/components/kerdar/utils/duration"
import KerdarNavigationLink from "@/components/kerdar/components/KerdarNavigationLink.vue"

export default {
  name: "Index",
  components: {
    listOfCards,
    GeneralDialog,
    AddUser,
    Guide,
    IpgDepositEchart,
    KerdarNavigationLink,
  },
  data() {
    return {
      sum: 0,
      componentKey: 0,
      status: false,
      loadBusiness: false,
      showForNotActiveBusiness: false,
      showForActiveBusiness: false,
      step2Show: false,
      sandboxRequestLoading: false,
      tooltip_show: false,
      addUser: false,
      kerdarLang,
      duration,
    }
  },
  watch: {
    "$store.state.business": {
      handler: function (newValue) {
        if (
          this.$store.state.hasOwnProperty("business") &&
          this.$store.state.business.hasOwnProperty("role") &&
          this.$store.state.business.role != "developer"
        ) {
          this.fetchIpgChart()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    //check if business object is loaded or not
    if (
      this.$store.state.hasOwnProperty("business") &&
      this.$store.state.business.hasOwnProperty("role") &&
      this.$store.state.business.role != "developer"
    ) {
      this.fetchIpgChart()
    }

    if (
      this.$route.params.filtered == "finishAdd" &&
      !this.$store.state.business.status
    ) {
      this.showForNotActiveBusiness = true
    } else if (
      this.$route.params.filtered == "finishAdd" &&
      this.$store.state.business.status
    ) {
      this.showForActiveBusiness = true
    }
  },
  beforeDestroy() {
    this.$store.commit("destroyIpg")
  },
  methods: {
    shareLink() {
      /* Get the text field */
      let copyText = document.querySelector("#hidden-input1")

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        /* Alert the copied text */
        this.tooltip_show = true
        setTimeout(() => {
          this.tooltip_show = false
        }, 2000)
      }
    },
    openDoc() {
      window.open("https://docs.vandar.io/ipg_service/ipg", "_blank")
    },
    openAddUser() {
      this.closeModal()
      this.addUser = true
    },
    sandboxRequest() {
      this.sandboxRequestLoading = true
      this.$http
        .post(this.$store.getters.apiWithVersionParam("3") + "/sandbox/join")
        .then((resp) => {
          if (resp.data.status) {
            this.$store.commit("changeSandBoxMember", true)
            this.sandboxRequestLoading = false
          }
        })
        .catch(() => {
          this.sandboxRequestLoading = false
        })
    },
    fetchIpgChart() {
      this.$http
        .get(this.$store.getters.apiUrlParam + "/chart?channel=IPG", {
          timeout: 0,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.sum = response.data.data.sum
          this.status = true
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    closeModal(obj) {
      this.showForNotActiveBusiness = false
      this.showForActiveBusiness = false
      this.step2Show = false
      this.addUser = false
      if (obj && obj.status) this.componentKey = !this.componentKey
    },
  },
}
</script>
<style lang="scss" scoped>
.carousel_direction {
  margin: 15px auto 0;
  .circle {
    height: 6px;
    max-width: 8px;
    margin-right: 5px;
    border-radius: 3px;
    &.active {
      background-color: white;
    }
    &.inactive {
      background-color: rgba(white, 0.3);
    }
  }
}
.extra_box {
  background-color: $van_color11;
  border-radius: 4px;
  padding: 17px 15px 20px;
  margin-top: 10px;
  height: 80px;
  > .image {
    max-width: 46px;
    margin-left: 15px;
  }
}
.chart-title {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}
</style>
