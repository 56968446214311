import { render, staticRenderFns } from "./settlementQueueCard.vue?vue&type=template&id=35df9907&scoped=true&"
import script from "./settlementQueueCard.vue?vue&type=script&lang=js&"
export * from "./settlementQueueCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35df9907",
  null
  
)

export default component.exports