const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpGetSuspiciousRefund(queryParam) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/cash-in/suspicious-payment/refund?page=${queryParam}`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetSearchedSuspiciousRefund(queryParam) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/cash-in/suspicious-payment/refund?page=${queryParam.page}&search=${queryParam.searchQuery}`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetSuspicious(queryParam) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/cash-in/suspicious-payment?page=${queryParam}`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetSearchedSuspicious(queryParam) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/cash-in/suspicious-payment?page=${queryParam.page}&search=${queryParam.searchQuery}`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetSuspiciousPaymentWage(id) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/cash-in/suspicious-payment/${id}/wage`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetSuspiciousPaymentOnTheWay(queryParam) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/cash-in/suspicious-payment/on-the-way?page=${queryParam}`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetSearchedSuspiciousPaymentOnTheWay(queryParam) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/cash-in/suspicious-payment/on-the-way?page=${queryParam.page}&search=${queryParam.searchQuery}`,
      {
        headers: apiHeaders(context),
      }
    )
  },
})
