<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">توکن‌ها</v-toolbar-title>
      <v-spacer />
      <v-btn text dark class="btn_small_normal" @click="open_addToken()">
        افزودن توکن جدید
      </v-btn>
    </v-toolbar>
    <v-layout class="pb-0">
      <v-flex class="pa-10 mb-0" style="text-align-last: right">
        <div class="font_2">
          توکن‌ها کلید دسترسی برنامه‌های شما به خدمات وندار هستند. هر توکن ۵ روز
          اعتبار دارد و می‌توانید پیش از پایان این مدت با استفاده از رفرش توکن
          آن را با توکن تازه‌ای جایگزین کنید. در این بخش می‌توانید توکن‌های فعلی
          کسب‌وکار خود را ببینید و در صورت نیاز توکن تازه‌ای بسازید یا توکن‌های
          پیشین را حذف کنید.<br />
          همچنین می‌توانید توکن‌های تازه ساخته شده را کپی کنید و در اختیار
          برنامه‌نویسان خود قرار دهید
        </div>
      </v-flex>
    </v-layout>
    <v-layout column>
      <v-flex>
        <v-card flat>
          <v-layout class="shebaList py-8" column>
            <!-- skeleton -->
            <v-flex v-if="tokensLoad == false" style="width: 100%">
              <v-layout align-center>
                <v-flex>
                  <div class="font_2 bold" style="max-width: 50%">
                    <span class="default_gray" />
                  </div>
                  <div class="font_2">
                    <span class="default_gray" />
                  </div>
                </v-flex>
                <v-flex style="max-width: 40px">
                  <v-btn text icon disabled>
                    <v-icon dark size="20" color="van_color03">
                      delete_outline
                    </v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-divider />
              <v-layout align-center>
                <v-flex>
                  <div class="font_2 bold" style="max-width: 50%">
                    <span class="default_gray" />
                  </div>
                  <div class="font_2">
                    <span class="default_gray" />
                  </div>
                </v-flex>
                <v-flex style="max-width: 40px">
                  <v-btn text icon disabled>
                    <v-icon dark size="20" color="van_color03">
                      delete_outline
                    </v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- add token -->
            <v-flex v-if="tokensLoad == true && tokens.length == 0">
              <v-layout align-center>
                <v-flex>
                  <div class="font_2">
                    توکن ساخته شده بعد از ۵ روز منقضی می‌شود. در حال حاضر توکن
                    فعالی ندارید.
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- token list -->
            <v-flex
              v-for="(token, index) in tokens"
              v-else
              :key="index"
              style="width: 100%"
            >
              <v-layout align-center>
                <v-flex>
                  <div class="font_2 bold">
                    {{ token.name }}
                    <v-tooltip
                      v-if="token.description != null"
                      top
                      content-class="tableTooltip"
                    >
                      <template #activator="{ on }">
                        <span v-on="on">
                          <v-icon
                            size="15"
                            class="van_color05--text mr-1 mb-1"
                            style="max-width: 22px; direction: rtl"
                            @click="$emit('openIntroduce')"
                          >
                            mdi_info_outline
                          </v-icon>
                        </span>
                      </template>
                      <span style="font-size: 12px">{{
                        token.description
                      }}</span>
                    </v-tooltip>
                  </div>
                  <div class="font_10">
                    از
                    {{
                      convertTime(
                        token.created_at * 1000,
                        "jDD jMMMM jYYYY [-] LT"
                      )
                    }}
                    | تا
                    {{
                      convertTime(
                        token.expires_at * 1000,
                        "jDD jMMMM jYYYY [-] LT"
                      )
                    }}
                  </div>
                </v-flex>
                <v-spacer />
                <v-flex style="max-width: 40px">
                  <v-btn text icon @click="deleteToken(token.id, index)">
                    <v-icon dark size="20" color="van_color03">
                      delete_outline
                    </v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-divider v-if="index + 1 < tokens.length" />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status1"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <add-token
        v-show="status1"
        :key="componentKey"
        @closeModal="close_modal"
      />
    </v-dialog>
    <general-dialog
      v-if="deleteStatus"
      :active="deleteStatus"
      content-class="confirmationMessageDialog"
      custom-style="height:207px"
      style="height: 100%"
      title="از حذف توکن اطمینان دارید؟"
      btn1-text="بله حذف شود"
      btn1-class="btn_small_normal error"
      :btn1-loading="loading"
      btn2-text="خیر منصرف شدم"
      @btn1Clicked="finalDelete()"
      @btn2Clicked="cancleDelete()"
    >
      <template slot="extra">
        <div class="font_10 text-center">
          وقتی توکنی حذف می‌شود دیگر سرویس‌های وندار به آن توکن خطای ۴۰۱
          برمیگردانند.
        </div>
      </template>
    </general-dialog>
  </v-card>
</template>

<script>
import AddToken from "./addToken.vue"
import GeneralDialog from "./generalDialog.vue"
export default {
  name: "SettingToken",
  components: {
    GeneralDialog,
    AddToken,
  },
  props: [],
  data: function () {
    return {
      componentKey: 0,
      tokens: [],
      tokensLoad: false,
      loading: false,
      status1: false,
      deleteStatus: false,
      deletedSelection: {
        id: null,
        index: null,
      },
      //load more
      currentPage: 1,
      endOfLoadMore: false,
    }
  },
  watch: {
    status1: function (newValue) {
      if (!newValue) this.$store.commit("callSecondModal", false)
    },
  },
  mounted() {
    this.getTokens()
  },
  methods: {
    convertTime(date, format) {
      const jmoment = require("moment-jalaali")
      jmoment.loadPersian({ dialect: "persian-modern" })
      return jmoment(date).format(format)
    },
    open_addToken() {
      this.close_modal()
      this.$store.commit("callSecondModal", true)
      this.status1 = true
    },
    close_modal(obj) {
      this.status1 = false
      this.$store.commit("callSecondModal", false)
      if (obj && obj.status) {
        this.componentKey = !this.componentKey
        this.getTokens()
      }
    },
    deleteToken(id, index) {
      this.deletedSelection = {
        id: id,
        index: index,
      }
      this.deleteStatus = true
    },
    cancleDelete() {
      this.resetDeleteSelection()
      this.deleteStatus = false
    },
    resetDeleteSelection() {
      this.deletedSelection = {
        id: null,
        index: null,
      }
    },
    finalDelete() {
      this.loading = true
      this.$http
        .post(
          this.$store.getters.apiWithVersionParam("3") + "/token/revoke",
          { token_id: this.deletedSelection.id },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$delete(this.tokens, this.deletedSelection.index)
          this.resetDeleteSelection()
          this.deleteStatus = false
          this.loading = false
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
    getTokens() {
      this.tokensLoad = false
      this.$http
        .get(this.$store.getters.apiWithVersionParam("3") + "/token/index", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.tokensLoad = true
          this.tokens = response.data
        })
        .catch((error) => {
          //callsnackbar
        })
    },
  },
}
</script>
