<template>
  <left-modal toolbar-name="اطلاعات بیشتر" @closeModal="$emit('closeModal')">
    <template>
      <general-card>
        <div class="font_2">
          <p>
            از تاریخ ۲۸ فروردین ۱۴۰۲ تراکنش‌های درگاه و فرم پرداخت نزد شاپرک
            تجمیع شده و روزانه طی یک تراکنش تسویه، توسط شاپرک به حساب بانکی شما
            واریز می‌شوند.
            <br />
            در واقع کسب‌و‌کار‌ها ‌می‌توانند تراکنش‌های درگاه و فرم پرداخت را طبق
            روال قبل در داشبورد وندار مشاهده کنند اما امکان برداشت و تسویه این
            مبالغ از طریق کیف پول برای کسب‌وکارها وجود ندارد.
            <br />
            درصورت نیاز به استفاده از قابلیت بازگشت وجه روی تراکنش درگاه یا فرم
            پرداخت، مبلغ از وجوه قابل برداشت کیف پول کسر می‌شود.
          </p>
        </div>
      </general-card>
    </template>
  </left-modal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal.vue"
import GeneralCard from "@/components/elements/generalCard.vue"

export default {
  name: "ShaparakDetails",
  components: { GeneralCard, LeftModal },
}
</script>

<style scoped></style>
