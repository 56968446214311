const customer = {
  state() {
    return {
      customers: [],
      selectedCustomer: {
        id: null,
        name: null,
        created_at: null,
        mobile: null,
        customer: {},
        balance: 0,
        ibans: [],
        transactions: [],
        summarize: null,
      },
    }
  },
  mutations: {
    setSelectedCustomer(state, payload) {
      state.selectedCustomer.id = payload.id
      state.selectedCustomer.balance = payload.balance
      state.selectedCustomer.name = payload.name
      state.selectedCustomer.created_at = payload.created_at
      state.selectedCustomer.mobile = payload.mobile
    },
    setCustomerSummarize(state, payload) {
      state.selectedCustomer.summarize = payload
    },
    setCustomerBalance(state, payload) {
      state.selectedCustomer.balance = payload
    },
    setCustomerTransactions(state, payload) {
      state.selectedCustomer.transactions = payload
    },
    setCustomerInfo(state, payload) {
      state.selectedCustomer.customer = payload.customer
    },
    setCustomerIbans(state, payload) {
      state.selectedCustomer.ibans = payload
    },
    addIbanToCustomerIbans(state, payload) {
      payload.forEach((element, index) => {
        if (state.selectedCustomer.ibans.indexOf(element) == -1) {
          state.selectedCustomer.ibans.push(element)
        }
      })
    },
    revokeCustomer(state) {
      ;(state.customers = []),
        (state.selectedCustomer = {
          id: null,
          name: null,
          created_at: null,
          mobile: null,
          customer: {},
          balance: 0,
          ibans: [],
          transactions: [],
          summarize: null,
        })
    },
  },
  getters: {},
}

export default customer
