import { render, staticRenderFns } from "./OnTheWaySuspiciousItemSkeleton.vue?vue&type=template&id=572fc179&scoped=true&"
import script from "./OnTheWaySuspiciousItemSkeleton.vue?vue&type=script&lang=js&"
export * from "./OnTheWaySuspiciousItemSkeleton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572fc179",
  null
  
)

export default component.exports