export default {
  get($http, context, queryParam) {
    return $http({
      method: "GET",
      url:
        context.$store.state.kerdar_base_url_v2 +
        "/api/v2" +
        "/business/" +
        context.$store.state.business.business_name +
        queryParam,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 0,
    })
  },
}
