<script>
import * as echarts from "echarts/core"
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components"
import { LineChart } from "echarts/charts"
import { UniversalTransition } from "echarts/features"
import { CanvasRenderer } from "echarts/renderers"

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
])

export default {
  name: "LineEChart",
  props: {
    xAxisData: {
      type: Array,
      default: () => [],
    },
    yAxisData: {
      type: Array,
      default: () => [],
    },
    yAxisDataSecond: {
      type: Array,
      default: () => [],
    },
    chartName: {
      type: String,
      default: "",
    },
    chartNameSecond: {
      type: String,
      default: "",
    },
    customLabel: {
      type: String,
      default: "",
    },
    dateTimeList: {
      type: Array,
      default: () => [],
    },
    chartId: {
      type: String,
      default: "default",
    },
  },
  computed: {
    determinePriceUnit() {
      return this.customLabel
        ? this.customLabel === "مجوز فعال"
          ? ""
          : this.customLabel
        : this.currencyName
    },
    yAxisName() {
      return this.customLabel ? this.customLabel : this.currencyName
    },
    determineYaxisLabelPadding() {
      const maxNumber = Math.round(Math.max(...this.yAxisData))
      const minNumber = Math.round(Math.min(...this.yAxisData))
      const middleNumber = Math.round((minNumber + maxNumber) / 2)
      const stringOfMiddleNumber = middleNumber.toString()
      const yAxisMiddleNumberLength = stringOfMiddleNumber.length
      return 8 + (yAxisMiddleNumberLength - 1) * 6
    },
  },
  mounted() {
    var chartDom = document.getElementById(this.chartId)
    var myChart = echarts.init(chartDom)
    var option
    let self = this

    // try to responsive
    // window.onresize = function () {
    //   const windowWidth = document.documentElement.clientWidth
    //   // if (windowWidth < 1000) {
    //   myChart.resize({
    //     width: 0.8 * windowWidth,
    //     height: 550,
    //   })
    //   // } else {
    //   //   myChart.resize({
    //   //     width: 850,
    //   //     // height: 540,
    //   //   })
    //   // }
    // }

    option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
        },
        // try to change tooltip position
        // position: function (point, params, dom, rect, size) {
        //   return [point[0], point[1] * 0.5]
        // },
        formatter(params) {
          const tooltipTemplate = `
          <div style='display:flex; flex-direction:column; flex-wrap: wrap; justify-content: center; min-width:200px;'>
            <span style='background:#F5F5F5; padding:6px 8px; text-align: center; margin-bottom:12px'>
              ${self.dateTimeList[params[0].dataIndex]}
            </span>
            ${self.createTooltipSubtitle(params)}
          </div>
          `
          return tooltipTemplate
        },
        textStyle: {
          fontFamily: "IRANSans",
          fontSize: 12,
          color: "#333333",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: this.xAxisData,
        axisLabel: {
          color: "#666666",
          fontFamily: "IRANSans",
          fontSize: 12,
          padding: 10,
        },
        axisLine: {
          lineStyle: {
            color: "#BBBBBB",
            fontFamily: "IRANSans",
            fontSize: 12,
            type: "dashed",
          },
        },
      },
      yAxis: {
        type: "value",
        name: this.yAxisName,
        nameLocation: "middle",
        nameTextStyle: {
          color: "#666666",
          fontFamily: "IRANSans",
          fontSize: 12,
          align: "middle",
          padding: [0, 0, this.determineYaxisLabelPadding, 0],
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#E5E5E5",
          },
        },
        axisLabel: {
          color: "#666666",
          fontFamily: "IRANSans",
          fontSize: 12,
          padding: 0,
        },
      },
      series: [
        {
          name: this.chartName,
          type: "line",
          // stack: "Total",
          symbol: "circle",
          symbolSize: 4,
          showAllSymbol: "auto",
          data: this.yAxisData,
          lineStyle: {
            color: "#4C9F87",
            width: 2,
          },
          // color of point style on the line
          itemStyle: {
            color: "#4C9F87",
          },
        },
        {
          name: this.chartNameSecond,
          type: "line",
          // stack: "Total",
          symbol: "circle",
          symbolSize: 4,
          showAllSymbol: "auto",
          data: this.yAxisDataSecond,
          lineStyle: {
            color: "#A14E69",
            width: 2,
          },
          // color of point style on the line
          itemStyle: {
            color: "#A14E69",
          },
        },
      ],
    }

    option && myChart.setOption(option)
  },
  methods: {
    createTooltipSubtitle(params) {
      let tooltipSubtitle = ""
      for (let i = 0; i < params.length; i++) {
        tooltipSubtitle += `<div style="display:flex; justify-content: space-between; align-items:center; margin: 2px 0 6px 2px;">
                  <div style="display: flex; align-items:center;">
                    ${params[i].marker}
                    <span style="margin-right: 4px"> ${
                      params[i].seriesName
                    }</span>
                  </div>
                  <span style='direction: rtl'>
                    ${Math.round(params[i].value).toLocaleString()}
                    <span style='color: #999999'>
                      ${this.determinePriceUnit}
                    </span>
                  </span>
              </div>`
      }

      return tooltipSubtitle
    },
  },
  render(createElement) {
    return createElement("div", {
      attrs: {
        id: this.chartId,
        style: "width: 100%; height: 400px",
      },
    })
  },
}
</script>

<style scoped></style>
