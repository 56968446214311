<template>
  <LeftModal
    toolbar-name="جزییات واریز بانکی خودکار"
    @closeModal="$emit('closeModal')"
  >
    <GeneralCard>
      <div class="font-12-bo-333-18">واریز بانکی خودکار چیست؟</div>
      <div class="font_30">
        این قابلیت امکان شارژ کیف پول را به صورت خودکار و بر اساس
        <strong>تنظیمات</strong> انجام شده فراهم می‌کند. از بخش تنطیمات
        می‌توانید شماره شبای مورد نظر خود را انتخاب کنید. با ثبت تنظیمات، مبلغ
        انتخاب شده به صورت خودکار به کیف‌پول‌تان در وندار واریز می‌شود.
        <br /><span>
          اگر از درگاه پرداخت وندار استفاده می‌کنید، با اتصال حساب بانکی به
          وندار و ثبت تنظیمات واریز بانکی خودکار، این امکان را دارید که مبالغ
          دریافتی از شاپرک را روزانه و به صورت خودکار به کیف‌پول وندارتان منتقل
          کنید و از خدمات تسویه وندار استفاده کنید.
        </span>
        <span>امکانات تعیین مبلغ واریز بانکی خودکار</span>
      </div>
      <br />
      <div class="font-12-bo-333-18">امکانات تعیین مبلغ واریز بانکی خودکار</div>
      <ul>
        <li class="font_30" style="margin-right: 16px">
          <span>انتخاب کل موجودی حساب بانکی:</span><br />
          <span>
            در صورتیکه کل موجودی حساب بانکی را انتخاب کنید، موجودی هر ۱۵ دقیقه
            یکبار به کیف‌پول وندارتان واریز می‌شود.
          </span>
        </li>
        <li class="font_30" style="margin-right: 16px">
          <span>انتخاب مبالغ تجمیعی تراکنش‌های درگاه:</span><br />
          <span>
            در صورتیکه مبالغ تجمیعی درگاه را انتخاب کنید، واریز هر روز پس از
            تسویه شاپرک به حساب بانکی شما انجام خواهد شد. زمان تسویه حساب شاپرک
            به صورت روزانه مشخص می‌شود. در صورتی که واریز به کیف‌پول با مشکل
            مواجه شود، هر ۱۵ دقیقه برای انجام آن تلاش می‌کنیم.
          </span>
        </li>
      </ul>
      <br />
      <div class="font-12-bo-333-18 back-ground-info">
        کارمزد واریز بانکی خودکار «صفر» است
      </div>
    </GeneralCard>
  </LeftModal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal.vue"
import GeneralCard from "@/components/elements/generalCard"

export default {
  name: "AutoCashInDetail",
  components: {
    LeftModal,
    GeneralCard,
  },
}
</script>

<style lang="scss" scoped>
.back-ground-info {
  background: #f5f5f5;
  padding: 12px;
  text-align: center;
}
</style>
