<template>
  <GeneralCard>
    <div class="d-flex flex-column">
      <section class="d-flex align-center">
        <v-img
          width="40"
          max-width="40"
          aspect-ratio="1"
          :src="require('@/assets/img/newIcon/cash-in-icon.svg')"
        />
        <section class="d-flex flex-column">
          <section class="d-flex align-center mb-1">
            <span class="font-12-bo-333-18 ml-2"> واریز بانکی خودکار </span>
            <v-img
              width="14"
              max-width="14"
              aspect-ratio="1"
              :src="require('@/assets/img/info.svg')"
              style="cursor: pointer"
              @click="showInfo"
            />
          </section>
        </section>
      </section>
      <section class="iban-card">
        <div class="iban-card__row">
          <span class="font-11-no-666-17">ساعت واریز بعدی</span>
          <span class="font_2">{{ autoCashInIban?.next_time }}</span>
        </div>
        <div class="iban-card__row">
          <span class="font-11-no-666-17">شماره شبا</span>
          <span class="font_2">
            <v-chip style="padding: 2px 4px; height: 20px" color="#E3CAD3">
              <span class="font_18"> حساب تسویه شاپرکی </span>
            </v-chip>
            <span>
              {{ autoCashInIban.IBAN }}
            </span>
          </span>
        </div>
        <div class="iban-card__row">
          <span class="font-11-no-666-17">نام صاحب حساب</span>
          <span class="font_2">
            {{ autoCashInIban.account_owner[0].firstName }}
            {{ autoCashInIban.account_owner[0].lastName }}
          </span>
        </div>
        <div class="iban-card__row">
          <span class="font-11-no-666-17">مبلغ</span>
          <span class="font_2">{{ autoCashInIban?.price_type }}</span>
        </div>
      </section>
    </div>
    <v-dialog
      v-if="isOpenAutoCashInDetailModal"
      v-model="isOpenAutoCashInDetailModal"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <AutoCashInDetail
        v-if="isOpenAutoCashInDetailModal"
        @closeModal="closeAutoCashInDetailModal"
      />
    </v-dialog>
  </GeneralCard>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard"
import AutoCashInDetail from "@/components/report/modals/addCashIn/elements/AutoCashInDetail"
import { mapState } from "vuex"

export default {
  name: "AutoCashInIbanInfo",
  components: {
    GeneralCard,
    AutoCashInDetail,
  },
  props: {
    autoCashInIban: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOpenAutoCashInDetailModal: false,
    }
  },
  computed: {
    ...mapState(["showSecondModal"]),
  },
  watch: {
    isOpenAutoCashInDetailModal(val) {
      if (!val && this.showSecondModal) {
        this.$store.commit("changeShowSecondModal", false)
      }
    },
  },
  methods: {
    //     [
    //   {
    //     "account_number": "0100373537004",
    //     "account_description": "حساب فعال است",
    //     "id": 11,
    //     "IBAN": "IR120620000000100373537004",
    //     "account_owner": [
    //       {
    //         "firstName": "مهدي",
    //         "lastName": "آزادبر"
    //       }
    //     ],
    //     "bank_name": "آینده",
    //     "account_status": {
    //       "code": "02",
    //       "slug": "ACTIVE",
    //       "description": "حساب فعال است"
    //     },
    //     "is_default": false
    //   }
    // ]

    setupCashIn() {
      this.$emit("setup-auto-cash-in")
    },
    showInfo() {
      this.isOpenAutoCashInDetailModal = true
      this.$store.commit("changeShowSecondModal", true)
    },
    closeAutoCashInDetailModal() {
      this.isOpenAutoCashInDetailModal = false
      this.$store.commit("changeShowSecondModal", false)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iban-card {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
  margin-top: 8px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: thin dashed #bbbbbb;
    padding: 4px 0;
  }

  &__row:last-child {
    border: none;
  }
}
</style>
