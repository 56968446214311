<template>
  <v-card>
    <v-toolbar flat height="68" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">{{ toolbarName }}</v-toolbar-title>
    </v-toolbar>

    <!-- container -->
    <v-layout class="modal-container">
      <!-- transaction-turnover box -->
      <div class="main-card-container mb-4">
        <v-card outlined class="main-card" flat>
          <div
            :class="[
              'd-flex justify-start',
              $vuetify.breakpoint.xsOnly
                ? 'flex-column align-start'
                : 'flex-row align-center',
            ]"
          >
            <v-img
              :src="require('@/assets/img/newIcon/transaction-turnover.svg')"
              height="40"
              alt="Vandar"
              contain
              class="icons"
            />
            <div class="d-flex flex-column font_2">
              <div class="bold">گردش تراکنش‌ها</div>
              <div>فقط واریز و برداشت‌های موثر در کیف‌ پول</div>
            </div>
            <div class="d-flex mr-md-auto">
              <v-btn
                text
                dark
                :class="[
                  'btn_small_normal ml-0',
                  $vuetify.breakpoint.xsOnly ? 'mr-0' : '',
                ]"
                @click="fireDownloadManager()"
              >
                درخواست خروجی
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>

      <!-- daily report box -->
      <div class="main-card-container mb-4">
        <v-card outlined class="main-card" flat>
          <div
            :class="[
              'd-flex justify-start',
              $vuetify.breakpoint.xsOnly
                ? 'flex-column align-start'
                : 'flex-row align-center',
            ]"
          >
            <v-img
              :src="require('@/assets/img/newIcon/daily-report.svg')"
              height="40"
              alt="Vandar"
              contain
              class="icons"
            />
            <div class="d-flex flex-column font_2">
              <div class="bold">جزییات تراکنش روزانه</div>
              <div>گزارش‌های مربوط به ریز تراکنش و کارمزد</div>
            </div>
            <div class="d-flex mr-md-auto">
              <v-btn
                text
                dark
                outlined
                :class="[
                  'btn_small_normal ml-0',
                  $vuetify.breakpoint.xsOnly ? 'mr-0' : '',
                ]"
                @click="$emit('openDailyReportModal')"
              >
                مشاهده
              </v-btn>
              <v-btn
                text
                dark
                :loading="loading"
                class="btn_small_normal ml-0"
                @click="exportDailyReports()"
              >
                دریافت
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>

      <!-- receipt box -->
      <!--      <div class="main-card-container mb-4">-->
      <!--        <v-card outlined class="main-card" flat>-->
      <!--          <div-->
      <!--            :class="[-->
      <!--              'd-flex justify-start',-->
      <!--              $vuetify.breakpoint.xsOnly-->
      <!--                ? 'flex-column align-start'-->
      <!--                : 'flex-row align-center',-->
      <!--            ]"-->
      <!--          >-->
      <!--            <v-img-->
      <!--              :src="require('@/assets/img/newIcon/receipt.svg')"-->
      <!--              height="40"-->
      <!--              alt="Vandar"-->
      <!--              contain-->
      <!--              class="icons"-->
      <!--            ></v-img>-->
      <!--            <div class="d-flex flex-column font_2">-->
      <!--              <div class="bold">فاکتور رسمی</div>-->
      <!--              <div>با مهر و امضا، قابل دریافت از روز ششم ماه بعد</div>-->
      <!--            </div>-->
      <!--            <div class="d-flex mr-md-auto">-->
      <!--              <v-btn-->
      <!--                flat-->
      <!--                dark-->
      <!--                :disabled="!isDownloadableInvoice"-->
      <!--                :class="[-->
      <!--                  'btn_small_normal ml-0',-->
      <!--                  $vuetify.breakpoint.xsOnly ? 'mr-0' : '',-->
      <!--                ]"-->
      <!--                :loading="invoiceLoading"-->
      <!--                @click="getInvoice"-->
      <!--                >دریافت-->
      <!--              </v-btn>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </v-card>-->
      <!--      </div>-->
    </v-layout>
    <v-snackbar
      v-model="showSnackBar"
      :color="snackbarStatus === 'succeed' ? 'van_green' : 'van_red'"
      bottom
      :timeout="snackbarTimeOut"
      class="vanSnackbar"
    >
      {{ snackbarMsg }}
      <v-progress-linear
        :buffer-value="1"
        height="4"
        color="rgba(255,255,255,0.6)"
        :value="Math.floor(100 * (snackbarCurrentTime / snackbarTimeOut))"
      />
    </v-snackbar>
  </v-card>
</template>
<script>
import { accountantDailyReport } from "@/mixins/accountantDailyReport.js"
import { dateTimeHanddler } from "@/mixins/dateTimeHanddler.js"

import jmoment from "moment-jalaali"

export default {
  name: "ExportData",
  mixins: [accountantDailyReport, dateTimeHanddler],
  props: ["toolbarName", "month", "year", "factorStatus"],
  data() {
    return {
      loading: false,
      invoiceLoading: false,
      dailyReportArr: [],
      showSnackBar: false,
      snackbarStatus: "succeed",
      snackbarCurrentTime: 0,
      snackbarTimeOut: 3000,
      snackbarMsg: "",
    }
  },
  computed: {
    isDownloadableInvoice() {
      return ["DOWNLOADED", "CREATED"].includes(this.factorStatus)
    },
  },
  watch: {
    snackbarStatus: function (newValue) {
      if (newValue) {
        this.progressBarStart()
      }
    },
    showSnackBar: function (newValue) {
      if (newValue) {
        this.progressBarStart()
      }
    },
  },
  methods: {
    fireDownloadManager() {
      let today = new Date()
      today = jmoment(today).format("jYYYYjMMjDD")

      let firstOfMonth = this.year.substring(2, 4) + this.month + "01"

      let end = 31
      if (
        this.year === today.substring(0, 4) &&
        this.month === today.substring(4, 6)
      )
        end = today.substring(6, 8)
      else end = this.endOfMonth(this.month)

      let lastOfMonth = this.year.substring(2, 4) + this.month + end

      this.$emit("openDownloadManagerModal", [firstOfMonth, lastOfMonth])
    },
    getInvoice() {
      this.invoiceLoading = true
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            `/accountant/report/invoice?year=${this.year}&month=${this.month}`,
          {
            timeout: 0,
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          const self = this
          let reader = new FileReader()
          reader.onload = function () {
            if (!reader.result.includes("error")) {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement("a")
              link.href = url
              link.setAttribute("download", "invoice.pdf") //or any other extension
              document.body.appendChild(link)
              link.click()
              self.$emit("changeFactorStatus", {
                year: self.year,
                month: self.month,
                status: "DOWNLOADED",
              })
            } else {
              self.callInternalFailedSnackbar(
                "فاکتور درخواستی وجود ندارد.",
                true
              )
            }
          }
          this.invoiceLoading = false

          reader.readAsText(response.data)
        })
        .catch((error) => {})
    },
    progressBarStart() {
      this.timeOut = setTimeout(() => {
        this.snackbarCurrentTime += 100
        if (this.snackbarTimeOut <= this.snackbarCurrentTime) {
          this.snackbarCurrentTime = 0
          clearTimeout(this.timeOut)
        } else {
          this.progressBarStart()
        }
      }, 100)
    },
    callInternalSnackBar() {
      this.callInternalFailedSnackbar(
        "خروجی در حال آماده سازی و دریافت است.",
        "succeed"
      )
    },
    callInternalFailedSnackbar(text, status) {
      this.snackbarMsg = text
      this.snackbarStatus = status
      this.showSnackBar = true
    },
    async exportDailyReports() {
      this.loading = true
      await this.getData(this.month, this.year).then((response) => {
        if (response) {
          this.callInternalSnackBar()
          this.generateExcel(response)
        }
      })

      this.loading = false
    },
  },
}
</script>
<style lang="scss" scoped>
.icons {
  margin-left: 12px;
  max-width: 40px;
}
.modal-container {
  min-height: 100vh;
}
.vanSnackbar {
  position: sticky;
  bottom: 40px;

  .v-progress-linear {
    position: absolute;
    bottom: -14px;
  }
}
</style>
