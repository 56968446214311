<template>
  <div class="showModalBoxesContainer">
    <v-flex class="showModalBoxes">
      <v-layout column class="rows mainBody">
        <v-flex>
          <v-layout :column="$vuetify.breakpoint.xsOnly">
            <v-flex class="van_color04--text"
              >{{ shaparakSettlementDetails?.ipg_transaction_count }} تراکنش
              واریز درگاه
            </v-flex>
            <v-flex
              :class="[
                'd-flex',
                { 'justify-end': $vuetify.breakpoint.smAndUp },
              ]"
              >{{
                parseInt(
                  shaparakSettlementDetails?.ipg_transaction_total_amount / 10
                ) | currency
              }}
              {{ currencyName }}
            </v-flex>
            <v-flex
              :class="[
                'd-flex',
                { 'justify-end': $vuetify.breakpoint.smAndUp },
                { 'is-visible': !isAmountIpgTransactionZero },
                { 'is-hidden': isAmountIpgTransactionZero },
              ]"
            >
              <a
                class="linkBorder ma-0"
                @click="
                  $emit('showDetails', {
                    id: shaparakSettlementDetails?.id,
                    type: 'ipg,mpg-ipg',
                  })
                "
              >
                مشاهده واریز‌ها
              </a>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout :column="$vuetify.breakpoint.xsOnly">
            <v-flex class="van_color04--text"
              >{{ shaparakSettlementDetails?.form_transaction_count }} تراکنش
              واریز فرم پرداخت
            </v-flex>
            <v-flex
              :class="[
                'd-flex',
                { 'justify-end': $vuetify.breakpoint.smAndUp },
              ]"
            >
              {{
                parseInt(
                  shaparakSettlementDetails?.form_transaction_total_amount / 10
                ) | currency
              }}
              {{ currencyName }}
            </v-flex>
            <v-flex
              :class="[
                'd-flex',
                { 'justify-end': $vuetify.breakpoint.smAndUp },
                { 'is-visible': !isAmountOfFormTransactionZero },
                { 'is-hidden': isAmountOfFormTransactionZero },
              ]"
            >
              <a
                class="linkBorder ma-0"
                @click="
                  $emit('showDetails', {
                    id: shaparakSettlementDetails?.id,
                    type: 'form',
                  })
                "
              >
                مشاهده واریز‌ها
              </a>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
export default {
  name: "ShaparakSettlementDetails",
  props: {
    shaparakSettlementDetails: {
      type: [Object, null],
      default: () => {},
    },
  },
  computed: {
    isAmountOfFormTransactionZero() {
      return this.shaparakSettlementDetails?.form_transaction_total_amount === 0
    },
    isAmountIpgTransactionZero() {
      return this.shaparakSettlementDetails?.ipg_transaction_total_amount === 0
    },
  },
}
</script>

<style scoped>
.is-visible {
  visibility: visible;
}

.is-hidden {
  visibility: hidden;
}
</style>
