<template>
  <v-card outlined color="white" height="40px" width="100%">
    <div>
      <v-img :src="require('@/assets/img/hourglass-empty.svg')" width="16" />
      <span class="font_14 pr-3"> <slot /> </span>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Pending",
}
</script>

<style lang="scss" scoped>
.v-card {
  cursor: default !important;
}
</style>
