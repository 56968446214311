<script>
import screenSize from "@/mixins/screenSize"

export default {
  name: "SkeletonDataTable",
  mixins: [screenSize],
  props: {
    shouldSetWidth: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <v-layout v-if="reuseSmAndDownBreakpoint" class="big">
    <v-flex class="right-number font_2" style="background-color: transparent">
      <span style="width: 100%; height: 20px" class="default_gray" />
    </v-flex>
    <v-flex>
      <v-layout column>
        <v-flex class="mb-5">
          <v-layout>
            <v-flex>
              <div style="display: flex; align-items: center">
                <v-img
                  max-width="16"
                  max-height="14"
                  style="
                    margin: 0 10px 0 25px;
                    filter: grayscale(100%);
                    opacity: 0.3;
                  "
                  :src="require('@/assets/img/Ascending.svg')"
                />
                <span style="max-width: 60%" class="default_gray" />
              </div>
            </v-flex>

            <v-flex style="text-align: left">
              <span style="float: left; max-width: 30%" class="default_gray" />
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex>
          <v-layout :column="reuseIsMobile" class="font_2">
            <v-flex style="margin-bottom: 15px; max-width: 250px">
              <div>
                <span class="default_gray" />
              </div>
              <div>
                <span style="max-width: 60%" class="default_gray" />
              </div>
            </v-flex>
            <v-divider vertical :column="reuseIsMobile" class="my-0 mx-5" />

            <v-flex>
              <div class="text-right">
                <span style="max-width: 50%" class="default_gray" />
                <div>
                  <span style="max-width: 30%" class="default_gray" />
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
  <tr v-else class="big">
    <td class="text-center" :style="shouldSetWidth ? 'width:80px' : ''">
      <span style="width: 25%; height: 20px" class="default_gray"></span>
    </td>
    <td class="text-right pr-0">
      <div class="d-flex align-center">
        <v-img
          max-width="16"
          max-height="14"
          style="margin: 0 10px 0 10px; filter: grayscale(100%); opacity: 0.3"
          :src="require('@/assets/img/Ascending.svg')"
        />
        <span class="default_gray" />
      </div>
    </td>
    <td class="text-right pr-0">
      <span class="default_gray" />
      <div>
        <span style="max-width: 40%" class="default_gray" />
      </div>
    </td>
    <td class="text-right pr-0">
      <span class="default_gray" />
    </td>
    <td class="text-right pr-0 big">
      <span class="default_gray" />
      <div>
        <span style="width: 50%" class="default_gray" />
      </div>
    </td>
    <td class="text-right pr-0 big">
      <span class="default_gray" />
    </td>
    <td class="text-right pr-0 big">
      <span class="default_gray" />
    </td>
  </tr>
</template>

<style scoped lang="scss"></style>
