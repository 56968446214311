<template>
  <div>
    <v-row align="center" justify="space-between">
      <v-col class="pb-2 pt-0">
        <span class="tickets-list-title"> لیست تیکت‌ها </span>
      </v-col>
      <v-col class="d-flex justify-end py-2">
        <v-btn
          color="#4C9F87"
          class="white--text d-flex justify-between"
          :width="isMobile ? 36 : 107"
          :min-width="isMobile ? 36 : 64"
          height="36"
          style="margin-left: 0"
          @click="addTicket"
        >
          <v-img
            :src="require('@/assets/img/plus.svg')"
            width="11px"
            height="11px"
            style="flex-grow: 0"
          ></v-img>
          <span v-show="isMobile ? false : true" class="button-text-style">
            تیکت جدید
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="isMobile"
      dense
      class="my-1 py-4 px-6 select-box-container"
      justify="start"
    >
      <v-col class="pa-0 ma-0" dense>
        <v-select
          v-model="selectedFilter"
          background-color="white"
          :items="filtrationItemsList"
          class="pa-0"
          style="max-width: 164px; margin: 0px 0px 0px 0px !important"
        ></v-select>
      </v-col>
    </v-row>
    <v-layout
      v-else
      align-center
      class="filter_row tickets_filter"
      justify-start
      wrap
    >
      <v-flex>
        <v-radio-group
          v-model="selectedFilter"
          :style="$vuetify.breakpoint.smAndDown ? 'width:100%' : ''"
          row
          class="py-1 px-2"
        >
          <v-radio label="همه" value="" />
          <v-radio label="در انتظار بررسی" value="pending" />
          <v-radio label="در حال بررسی" value="in_progress" />
          <v-radio label="پاسخ داده شده" value="answered" />
          <v-radio label="بسته شده" value="closed" />
        </v-radio-group>
      </v-flex>
    </v-layout>

    <TicketList
      :should-get-tickets-list="!showCreationTicketModal"
      :tickets-list-url="ticketsListUrl"
      :status="selectedFilter"
    />
    <v-dialog
      v-model="showCreationTicketModal"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="left-modal"
      no-click-animation
      transition="slide-x-transition"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      style="z-index: 1000003 !important"
    >
      <CreateTicket
        v-if="showCreationTicketModal"
        @closeModal="closeCreationTicketModal"
      />
    </v-dialog>
    <GeneralDialog
      :active="isConfirmModalOfCreationTicketOpen"
      logo="1stCheck.svg"
      content-class="confirmationMessageDialog"
      :title="generateTitle"
      :width="360"
      btn1-text="متوجه شدم"
      @btn1Clicked="isConfirmModalOfCreationTicketOpen = false"
    >
      <template #extra>
        <div v-if="getHoursOfNow >= 16" class="work-hour-info">
          با توجه به پایان ساعت پشتیبانی وندار، تیکت شما در اولین ساعات کاری
          پاسخ داده خواهد شد.
        </div>
        <div class="font_2 mt-2 pa-5 van_color11 w-100 align-center rounded">
          <v-layout align-center class="dashed-divider py-1">
            <v-flex class="font_8"> عنوان </v-flex>
            <v-flex class="value-style">
              {{ createdTicket.title }}
            </v-flex>
          </v-layout>

          <v-layout align-center class="py-1">
            <v-flex class="font_8"> ایجاد کننده </v-flex>
            <v-flex class="value-style">
              {{ createdTicket.user?.name }}
              <span v-if="createdTicket.user?.role">
                / {{ createdTicket.user?.role }}
              </span>
            </v-flex>
          </v-layout>
        </div>
      </template>
    </GeneralDialog>
  </div>
</template>

<script>
import TicketList from "./TicketsList"
import CreateTicket from "./CreateTicket.vue"
import GeneralDialog from "@/components/modals/generalDialog"

export default {
  name: "TicketsList",
  components: {
    TicketList,
    CreateTicket,
    GeneralDialog,
  },
  data() {
    return {
      selectedFilter: "",
      showCreationTicketModal: false,
      ticketsListUrl:
        this.$store.state.ticket_api_base_url +
        "/business/" +
        this.$store.state.business.business_name +
        "/tickets",
      filtrationItemsList: [
        { text: "همه", value: "" },
        { text: "در انتظار بررسی", value: "pending" },
        { text: "در حال بررسی", value: "in_progress" },
        { text: "پاسخ داده شده", value: "answered" },
        { text: "بسته شده", value: "closed" },
      ],
      isConfirmModalOfCreationTicketOpen: false,
      createdTicket: {},
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 600
    },
    generateTitle() {
      return `تیکت با شناسه ${this.createdTicket.id} با موفقیت ثبت شد`
    },
    getHoursOfNow() {
      return new Date().getHours()
    },
  },
  methods: {
    addTicket() {
      this.showCreationTicketModal = true
    },
    closeCreationTicketModal(payload) {
      if (payload) {
        this.createdTicket = payload
        this.isConfirmModalOfCreationTicketOpen = true
      }
      this.showCreationTicketModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.tickets_filter {
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 17px 20px 17px 15px !important;
}

:deep(.v-item--active) {
  border-radius: 4px !important;
}

.tickets-list-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
}

.button-text-style {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
  margin-right: 10px;
}

:deep(.v-text-field > .v-input__control > .v-input__slot:before) {
  border: none;
}

:deep(.v-input .v-input__slot) {
  border: 1px solid #999999 !important;
}

.dashed-divider {
  border-bottom: 1px dashed #bbbbbb;
}

.value-style {
  color: #666666;
  text-align: left;
}

.work-hour-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  background: #4c9f874d;
  border-radius: 4px;
  padding: 20px;
  color: #333333;
  font-size: 11px;
  line-height: 17px;
}

.select-box-container {
  background: #eee;
  border-radius: 4px;
  margin: 0 2px;
}
</style>
