<template>
  <div>
    <v-layout class="my-3 font_5 bold">
      <v-flex>{{ kerdarLang.ipg.numberOfDeposits }}</v-flex>
    </v-layout>
    <IpgDepositEchart :duration="duration" :kerdar-lang="kerdarLang" />

    <IpgSuccessDepositEchart
      :duration="duration"
      :kerdar-lang="kerdarLang"
    /><br />

    <!-- <IpgAverageDensityOfDeposits
      :duration="duration"
      :kerdar-lang="kerdarLang"
    /> -->
    <v-layout class="my-3 font_5 bold">
      <v-flex>{{ kerdarLang.ipg.unsuccessfulDeposits }}</v-flex>
    </v-layout>
    <IpgAverageStatus :duration="duration" :kerdar-lang="kerdarLang" />
    <IpgErrorResources :duration="duration" :kerdar-lang="kerdarLang" />
    <IpgErrorFrequencyPercentage
      :duration="duration"
      :kerdar-lang="kerdarLang"
    />
  </div>
</template>

<script>
import IpgAverageStatus from "@/components/kerdar/tabs/ipg/elements/IpgAverageStatus"
import IpgErrorResources from "@/components/kerdar/tabs/ipg/elements/IpgErrorResources"
import IpgErrorFrequencyPercentage from "@/components/kerdar/tabs/ipg/elements/IpgErrorFrequencyPercentage"
// import IpgAverageDensityOfDeposits from "@/components/kerdar/tabs/ipg/elements/IpgAverageDensityOfDeposits"
import IpgDepositEchart from "@/components/kerdar/tabs/ipg/elements/IpgDepositEchart"
import IpgSuccessDepositEchart from "@/components/kerdar/tabs/ipg/elements/IpgSuccessDepositEchart"

import kerdarLang from "@/components/kerdar/utils/langResource.json"
import { duration } from "@/components/kerdar/utils/duration"

export default {
  name: "Ipg",
  components: {
    IpgErrorFrequencyPercentage,
    IpgErrorResources,
    IpgAverageStatus,
    // IpgAverageDensityOfDeposits,
    IpgDepositEchart,
    IpgSuccessDepositEchart,
  },
  data() {
    return {
      kerdarLang,
      duration,
    }
  },
}
</script>

<style scoped></style>
