<template>
  <v-layout v-if="cellingValue !== null">
    <!-- label-->
    <v-flex v-if="label" class="d-flex justify-space-between font_31 mt-2">
      <v-tooltip v-if="ibanInfoText" top>
        <template #activator="{ on }">
          <div class="d-flex" v-on="on">
            {{ label }}
            <div v-if="ibanInfoText">
              <v-icon class="mr-1" color="van_color04" size="16px">
                info_outline
              </v-icon>
            </div>
          </div>
        </template>
        <span class="tooltipText">{{ ibanInfoText }}</span>
      </v-tooltip>
      <div v-else>{{ label }}</div>
      <div v-if="showSkeleton" class="d-flex align-center">
        <span class="default_gray px-8 ma-0" />
      </div>
      <div v-else>
        <!-- value (show zero) -->
        <span v-if="!isValueCurrency" class="bold mr-auto d-inline-flex">
          {{ cellingValue }} </span
        ><span v-else class="bold mr-0 d-inline-flex">
          {{ cellingValue | currencyWithRialInput }}
          {{ currencyName }}
        </span>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "IbanCellingItems",
  props: {
    cellingValue: {
      default: false,
      type: [Boolean, Number, String],
    },
    label: {
      default: null,
      type: String,
    },
    ibanInfoText: {
      default: null,
      type: String,
    },
    isValueCurrency: {
      default: false,
      type: Boolean,
    },
    skeleton: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    showSkeleton() {
      return this.skeleton
    },
  },
}
</script>
