<template>
  <div>
    <v-layout class="my-3 font_5 bold">
      <v-flex>{{ kerdarLang.paymentForm.depositsMass }}</v-flex>
    </v-layout>
    <PaymentFormDepositEChart :duration="duration" :kerdar-lang="kerdarLang" />

    <PaymentFormSuccessDepositEChart
      :duration="duration"
      :kerdar-lang="kerdarLang"
    />

    <!-- <PaymentFormDepositsAverageDensity
      :duration="duration"
      :kerdar-lang="kerdarLang"
    /> -->

    <v-layout class="my-3 font_5 bold">
      <v-flex>{{
        kerdarLang.paymentForm.unsuccessfulDepositsAndErrors
      }}</v-flex>
    </v-layout>

    <PaymentFormUnsuccessfulDeposit
      :duration="duration"
      :kerdar-lang="kerdarLang"
    />

    <PaymentFormErrorResources :duration="duration" :kerdar-lang="kerdarLang" />

    <PaymentFormErrorFrequencyPercentage
      :duration="duration"
      :kerdar-lang="kerdarLang"
    />
  </div>
</template>

<script>
import PaymentFormUnsuccessfulDeposit from "@/components/kerdar/tabs/payment-form/elements/PaymentFormUnsuccessfulDeposit"
import PaymentFormErrorResources from "@/components/kerdar/tabs/payment-form/elements/PaymentFormErrorResources"
import PaymentFormErrorFrequencyPercentage from "@/components/kerdar/tabs/payment-form/elements/PaymentFormErrorFrequencyPercentage"
// import PaymentFormDepositsAverageDensity from "@/components/kerdar/tabs/payment-form/elements/PaymentFormDepositsAverageDensity"
import kerdarLang from "@/components/kerdar/utils/langResource.json"
import { duration } from "@/components/kerdar/utils/duration"
import PaymentFormDepositEChart from "@/components/kerdar/tabs/payment-form/elements/PaymentFormDepositEChart"
import PaymentFormSuccessDepositEChart from "@/components/kerdar/tabs/payment-form/elements/PaymentFormSuccessDepositEChart"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "PaymentForm",
  components: {
    PaymentFormErrorFrequencyPercentage,
    PaymentFormErrorResources,
    PaymentFormUnsuccessfulDeposit,
    // PaymentFormDepositsAverageDensity,
    PaymentFormDepositEChart,
    PaymentFormSuccessDepositEChart,
  },
  data() {
    return {
      kerdarLang,
      duration,
    }
  },
}
</script>

<style scoped></style>
