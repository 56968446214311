<template>
  <v-flex class="vanSelectClass ml-1">
    <v-select
      v-model="selectedItem"
      :items="itemList"
      item-text="value"
      item-value="key"
      solo
      :multiple="selectType === 'multiple'"
      :label="label"
      flat
      height="40"
      outlined
      :menu-props="{ contentClass: 'withActiveStyle' }"
      @input="chooseSelectAction"
    >
      <!--  if v-select type is not multiple    -->
      <template v-if="selectType !== 'multiple'" #item="data">
        <v-layout align-center justify-center @click="openDialog()">
          <v-flex class="font_2"> {{ data.item.value }} </v-flex>
          <v-flex class="checkIcon">
            <v-img
              :src="require('@/assets/img/newIcon/simple-check.svg')"
              alt="Vandar"
              class="icons"
              contain
              height="18"
              width="18"
            ></v-img>
          </v-flex>
        </v-layout>
      </template>
      <template #selection="{ item, index }">
        <!--  is v-select type is date    -->
        <span
          v-if="
            selectType === 'date' &&
            (fromDateText != null || toDateText != null)
          "
          style="
            font-size: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 120px;
            overflow: hidden;
          "
        >
          {{ fromDateText }} {{ toDateText }}
        </span>
        <!-- if the v-select type is multiple(checkbox)-->
        <span v-else-if="selectType === 'multiple' && index === 1" class="pr-1">
          ({{ selectedItem.length - 1 }}+ )
        </span>
        <span v-else>{{ item.value }}</span>
      </template>
    </v-select>
    <v-dialog v-model="filterDateStatus" width="360" persistent>
      <DatePickerModal
        v-if="filterDateStatus"
        :has-hour="false"
        @send-form="sendDateForm"
        @close-date-picker-modal="filterDateStatus = false"
      />
    </v-dialog>
  </v-flex>
</template>

<script>
import DatePickerModal from "@/components/report/modals/DatePickerModal/DatePickerModal.vue"

export default {
  components: {
    DatePickerModal,
  },
  provide: {
    selectedFromDate: this?.wholeFromDate,
    selectedToDate: this?.wholeToDate,
    shouldReset: false,
  },
  props: {
    selectList: {
      type: Array,
      default: null,
    },
    selectType: {
      type: String,
      default: "defaultSelect",
    },
    label: {
      type: String,
      default: "همه",
    },
    resetFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: null,
      itemList: [],
      afterMountFlag: false,

      //date options
      dateItem: [
        {
          key: "0",
          value: "از ابتدای ثبت‌نام تاکنون",
        },
        {
          key: "day",
          value: "روز جاری",
        },
        {
          key: "week",
          value: "هفته جاری",
        },
        {
          key: "month",
          value: "ماه جاری",
        },
        {
          key: "year",
          value: "سال جاری",
        },
        {
          key: "5",
          value: "بازه زمانی دلخواه",
        },
      ],
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      lastDuration: "0",
      filterDateStatus: false,
      fromDate: null,
      toDate: null,
      fromDateText: null,
      toDateText: null,
    }
  },
  watch: {
    resetFilter: function (newValue, oldValue) {
      this.selectedItem = "0"
      this.fromDate = null
      this.toDate = null
      this.fromDateText = null
      this.toDateText = null
      this.$emit("setResetFilter")
    },
    selectedItem: function (newValue, oldValue) {
      //send selected item value to the parent
      if (this.afterMountFlag) {
        // if v-select type is not date, and selected date has not from/to date:
        this._provided.shouldReset = true

        if (
          this.selectType !== "date" ||
          (this.selectType === "date" && newValue !== "5")
        )
          this.$emit("selected", newValue)

        // if a value is changed, shake submit btn
        this.$emit("shakeIt", true)
        setTimeout(() => this.$emit("shakeIt", false), 300)
      }
    },
  },
  beforeMount() {
    // get items from props unless select type is date (in this case we take items from dateItem)
    this.itemList = this.selectType === "date" ? this.dateItem : this.selectList
    this.selectedItem = this.selectType === "multiple" ? [] : "0"

    //after 300ms set afterMountFlag to true (avoid watch to fire in mount cycle)
    setTimeout(() => (this.afterMountFlag = true), 300)
  },
  methods: {
    // open datepicker dialog if select type is date
    openDialog() {
      //if date is set to custom from and to date
      if (this.selectedItem === "5") {
        this.filterDateStatus = true
      } else {
        this.toDateText = null
        this.fromDateText = null
        this.fromDate = null
        this.toDate = null
        this.lastDuration = this.selectedItem
        this.filterDateStatus = false
        this.$store.commit("updateToAndFormDate", null)
      }
    },
    sendDateForm(payload) {
      this.filterDateStatus = false
      this.fromDate = payload.fromDate
      this.toDate = payload.toDate
      this.fromDateText = payload.fromDateText
      this.toDateText = payload.toDateText

      let customDate = {
        fromDate: this.fromDate ?? null,
        toDate: this.toDate ?? null,
      }

      this._provided.selectedFromDate = payload.wholeFromDate
      this._provided.selectedToDate = payload.wholeToDate
      this._provided.shouldReset = false
      
      this.$emit("selected", customDate)
    },
    chooseSelectAction() {
      return this.selectType === "date" ? this.openDialog() : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.checkIcon {
  max-width: 18px !important;
}
</style>
