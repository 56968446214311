import { store } from "@/store"

export const numbersHelper = {
  methods: {
    priceInLetters(price) {
      //return price in letters and digits
      // accept toman
      if (this.currencyName !== "تومان") price = (price * 10).toString()
      let unit = this.calcPriceUnit(price.length)

      let digits = this.calcDigits(price)
      return digits + " " + unit
    },
    calcDigits(price) {
      //calc digit part of the price
      let length = price.length
      if (length > 9) {
        return Math.round(parseFloat(price / (1000 * 1000 * 1000)) * 100) / 100
      } else if (length > 6) {
        return Math.round(parseFloat(price / (1000 * 1000)) * 100) / 100
      } else if (length > 3) {
        return Math.round(parseFloat(price / 1000) * 100) / 100
      } else {
        return Math.round(price * 100) / 100
      }
    },
    calcPriceUnit(length) {
      //calc price unit
      let result = ""

      if (length > 9) {
        result = "میلیارد"
      } else if (length > 6) {
        result = "میلیون"
      } else if (length > 3) {
        result = "هزار"
      }
      return result
    },
    wordifyCeilingAmount() {
      const currentCurrency =
        this.$store.state.user.currency == "RIAL" ? "ریال" : "تومان"

      const amount = this.$options.filters.currencyWithRialInput(
        this.$store.state?.businessCeilingAmount
      )
      return wordifyfa(amount ?? "0") + currentCurrency
    },
  },
}
