<template>
  <div id="nav_top">
    <div class="float-right">
      <div class="logo">
        <a href="https://vandar.io">
          <v-img :src="$store.state.vandarIconCdn" width="20" alt="Vandar" />
        </a>
      </div>
    </div>
    <div class="float-left">
      <!--      whats new -->
      <v-badge
        overlap
        left
        :value="$store.state.user.is_whats_new_exists"
        color="van_pink"
        :class="['whats_new', haveNotSeenWhatsNew ? 'blink' : '']"
      >
        <span />
        <v-btn
          fab
          small
          size="24"
          icon
          @click="showNotificationOrWhatsNew('whatsNew')"
        >
          <img src="@/assets/img/newIcon/whatsnew.svg" />
        </v-btn>
      </v-badge>
      <!-- notification List -->
      <v-badge
        overlap
        left
        :value="$store.state.user.notification_count > 0"
        color="van_pink"
        :class="['whats_new', haveNotSeenNotification ? 'blink' : '']"
      >
        <span />
        <v-btn
          fab
          small
          size="24"
          icon
          @click="showNotificationOrWhatsNew('notification')"
        >
          <svg
            style="fill: #fff; opacity: 0.5"
            xmlns="http://www.w3.org/2000/svg"
            width="11.9"
            height="14"
            viewBox="0 0 11.9 14"
          >
            <defs />
            <path
              class="a"
              d="M12.1,12.5H5.8V7.95a3.15,3.15,0,0,1,6.3,0m1.4,3.85V7.95A4.556,4.556,0,0,0,10,3.526V3.05a1.05,1.05,0,0,0-2.1,0v.476A4.548,4.548,0,0,0,4.4,7.95V11.8L3,13.2v.7H14.9v-.7M8.95,16a1.4,1.4,0,0,0,1.4-1.4H7.55A1.4,1.4,0,0,0,8.95,16Z"
              transform="translate(-3 -2)"
            />
          </svg>
        </v-btn>
      </v-badge>

      <!-- modals -->
      <v-dialog
        v-model="modalStatus"
        :content-class="
          showNotificationSetting ? 'secondModal vanmodal' : 'vanmodal'
        "
        transition="slide-x-transition"
        :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        no-click-animation
      >
        <notification
          v-if="modalStatus"
          @closeModal="close_modal()"
          @openSetting="showNotificationSetting = true"
        />
      </v-dialog>

      <!-- notification setting -->
      <v-dialog
        v-model="showNotificationSetting"
        :content-class="'vanmodal'"
        transition="slide-x-transition"
        :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        no-click-animation
      >
        <notification-setting
          v-if="showNotificationSetting"
          @closeModal="close_second_modal"
        />
      </v-dialog>

      <!--whats new-->
      <v-dialog
        v-model="whatsNewModal"
        :content-class="
          showNotificationSetting ? 'secondModal vanmodal' : 'vanmodal'
        "
        transition="slide-x-transition"
        :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        no-click-animation
      >
        <whats-new
          v-if="whatsNewModal"
          vandar-modal
          @closeModal="close_modal()"
        />
      </v-dialog>

      <v-btn icon size="24" fab small @click="show1">
        <v-icon style="opacity: 0.5; height: 19px" color="van_color13">
          more_vert
        </v-icon>
      </v-btn>
      <v-menu
        v-model="showMenu1"
        transition="slide-y-transition"
        close-on-content-click
        min-width="220"
        max-height="365"
        :position-x="x1"
        :position-y="y1"
        absolute
      >
        <v-card class="drop_down_menu">
          <v-list style="min-width: 100%" class="py-5 px-1">
            <v-list-item>
              <v-list-item-avatar>
                <v-img
                  v-if="user.avatar"
                  :src="$store.state.storage_base_url + user.avatar"
                  alt="avatar"
                />
                <v-img
                  v-else
                  :src="require('@/assets/img/default.png')"
                  alt="avatar"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-if="user.hasOwnProperty('name')"
                  class="font_7"
                  style="max-width: 132px"
                >
                  {{ user.name }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  <span class="default_gray" />
                </v-list-item-title>

                <!-- wallet -->
                <wallet
                  with-label
                  :load="user.hasOwnProperty('status')"
                  :status="user.status"
                  :amount="user.wallet"
                  :blocked_amount="user.blocked_amount"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list class="menu_v_list">
            <v-list-item to="/">
              <v-list-item-action>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  class="svgs"
                >
                  <path
                    d="M15.444,3A1.55,1.55,0,0,1,17,4.556V15.444A1.55,1.55,0,0,1,15.444,17H10.778V3M9.222,3V9.222H3V4.556A1.55,1.55,0,0,1,4.556,3m4.667,7.778V17H4.556A1.55,1.55,0,0,1,3,15.444V10.778"
                    transform="translate(-3 -3)"
                    class="a"
                  />
                </svg>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>همه کسب‌و‌کارها</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="userSettingstatus = true">
              <v-list-item-action>
                <svg
                  class="svgs"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <path
                    class="a"
                    d="M3,15.444v1.778H8.333V15.444H3M3,4.778V6.556h8.889V4.778H3M11.889,19V17.222H19V15.444H11.889V13.667H10.111V19h1.778M6.556,8.333v1.778H3v1.778H6.556v1.778H8.333V8.333H6.556M19,11.889V10.111H10.111v1.778H19M13.667,8.333h1.778V6.556H19V4.778H15.444V3H13.667Z"
                    transform="translate(19 -3) rotate(90)"
                  />
                </svg>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>تنظیمات کلی حساب</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logUserOut()">
              <v-list-item-action>
                <svg
                  class="svgs"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.118"
                  height="15"
                  viewBox="0 0 14.118 15"
                >
                  <path
                    class="a"
                    d="M15.082,5.153,13.8,6.432a5.282,5.282,0,1,1-5.5-.009L7.035,5.153a7.059,7.059,0,1,0,8.047,0M11.941,3H10.176v8.824h1.765"
                    transform="translate(-4 -3)"
                  />
                </svg>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ logout.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <!-- modals -->
      <v-dialog
        v-model="userSettingstatus"
        :content-class="
          $store.state.showThirdModal
            ? 'vanmodal thirdModal'
            : $store.state.secondUserSetting
            ? 'vanmodal secondModal'
            : 'vanmodal'
        "
        transition="slide-x-transition"
        :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        no-click-animation
      >
        <userSetting v-if="userSettingstatus" @closeModal="close_modal()" />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import notification from "../modals/notification"
import notificationSetting from "../modals/settingNotification.vue"

import userSetting from "../modals/userSetting"
import wallet from "./wallet"
import WhatsNew from "./whatsNew"

export default {
  name: "UserNavbar",
  components: {
    WhatsNew,
    notification,
    userSetting,
    wallet,
    notificationSetting,
  },
  data() {
    return {
      modalStatus: false,
      whatsNewModal: false,
      showMenu1: false,
      logout: { title: "خروج", icon: "power_settings_new", to: "/logout" },
      userSettingstatus: false,
      x1: 0,
      y1: 0,
      showNotificationSetting: false,
    }
  },
  computed: {
    haveNotSeenWhatsNew() {
      if (!this.$store.state.user.hasOwnProperty("actions")) return false
      let lastWhatsNewActions = this.$store.state.user.actions.find(
        (item) => item.action_name === "whats_new"
      )
      if (!lastWhatsNewActions) return true

      if (
        this.$store.state.user.hasOwnProperty("last_whats_new_time") &&
        lastWhatsNewActions &&
        parseInt(lastWhatsNewActions.action_time) <
          parseInt(this.$store.state.user.last_whats_new_time)
      )
        return true
      return false
    },
    haveNotSeenNotification() {
      if (!this.$store.state.user.hasOwnProperty("actions")) return false
      let lastNotificationActions = this.$store.state.user.actions.find(
        (item) => item.action_name === "notification"
      )
      if (!lastNotificationActions) return true

      if (
        this.$store.state.user.hasOwnProperty("last_notification_time") &&
        lastNotificationActions &&
        parseInt(lastNotificationActions.action_time) <
          parseInt(this.$store.state.user.last_notification_time)
      )
        return true
      return false
    },
    user() {
      return this.$store.state.user
    },
    business() {
      if (!this.$store.state.isUser) return this.$store.state.business
      else return {}
    },
  },
  methods: {
    showNotificationOrWhatsNew(item) {
      let data = {}
      if (item === "whatsNew") {
        data.name = "whats_new"
        this.whatsNewModal = !this.whatsNewModal
      } else {
        data.name = "notification"
        this.modalStatus = !this.modalStatus
      }
      this.$store.dispatch("saveAction", data)
    },
    show1(e) {
      e.preventDefault()
      this.showMenu1 = false
      this.x1 = e.clientX + 110
      this.y1 = e.clientY + 10
      this.$nextTick(() => {
        this.showMenu1 = true
      })
    },
    createLink(business) {
      if (business.role == "developer") {
        return "/business/" + business.business_name + "/ipg"
      } else {
        return "/business/" + business.business_name
      }
    },
    close_modal() {
      this.modalStatus = false
      this.userSettingstatus = false
      this.showNotificationSetting = false
      this.whatsNewModal = false
    },
    close_second_modal() {
      this.showNotificationSetting = false
    },
    logUserOut() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          this.$router.push("/app-entries")
        })
        .catch((error) => {
          this.$router.push("/app-entries")
        })
    },
  },
}
</script>
