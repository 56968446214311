<template>
  <div class="customerWrapper mb-n4">
    <v-layout v-if="!loading" align-center>
      <v-flex
        class="px-7 py-8 mb-0"
        style="text-align-last: right; margin-left: 5px"
      >
        <v-layout style="align-items: center">
          <v-flex style="max-width: 52px">
            <div
              style="
                display: flex;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 10px 10px 0px 10px;
                background-color: #4c9f874d;
              "
            >
              <v-img
                max-width="14"
                max-height="14"
                class="ma-auto"
                :src="require('@/assets/img/Ascending.svg')"
              />
            </div>
          </v-flex>
          <v-flex>
            <div class="font_9">
              {{
                ($store.state.customer.selectedCustomer.summarize.deposit
                  .amount /
                  10)
                  | currency
              }}
              {{ currencyName }}
            </div>
            <div class="font_14">
              در
              {{
                $store.state.customer.selectedCustomer.summarize.deposit.count
              }}
              واریز
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        class="px-7 py-8 mb-0"
        style="text-align-last: right; margin-right: 5px"
      >
        <v-layout style="align-items: center">
          <v-flex style="max-width: 52px">
            <div
              style="
                display: flex;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 10px 10px 0px 10px;
                background-color: #a14e694d;
              "
            >
              <v-img
                max-width="14"
                max-height="14"
                class="ma-auto"
                :src="require('@/assets/img/Descending.svg')"
              />
            </div>
          </v-flex>
          <v-flex>
            <div class="font_9">
              {{
                ($store.state.customer.selectedCustomer.summarize.withdraw
                  .amount /
                  10)
                  | currency
              }}
              {{ currencyName }}
            </div>
            <div class="font_14">
              در
              {{
                $store.state.customer.selectedCustomer.summarize.withdraw.count
              }}
              برداشت
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout v-else align-center>
      <v-flex
        class="pa-7 mb-0"
        style="text-align-last: right; margin-left: 5px"
      >
        <v-layout style="align-items: center">
          <v-flex style="max-width: 52px">
            <div
              style="
                display: flex;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 10px 10px 0px 10px;
                background-color: #4c9f874d;
              "
            >
              <v-img
                max-width="14"
                max-height="14"
                class="ma-auto"
                :src="require('@/assets/img/Ascending.svg')"
              />
            </div>
          </v-flex>
          <v-flex>
            <div class="font_9" style="max-width: 60%">
              <span class="default_gray" />
            </div>
            <div class="font_14">
              <span class="default_gray" />
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        class="pa-7 mb-0"
        style="text-align-last: right; margin-right: 5px"
      >
        <v-layout style="align-items: center">
          <v-flex style="max-width: 52px">
            <div
              style="
                display: flex;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 10px 10px 0px 10px;
                background-color: #a14e694d;
              "
            >
              <v-img
                max-width="14"
                max-height="14"
                class="ma-auto"
                :src="require('@/assets/img/Descending.svg')"
              />
            </div>
          </v-flex>
          <v-flex>
            <div class="font_9" style="max-width: 60%">
              <span class="default_gray" />
            </div>
            <div class="font_14">
              <span class="default_gray" />
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "Summarize",
  props: {
    loading: {
      default: false,
    },
  },
}
</script>

<style></style>
