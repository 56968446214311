<template>
  <v-layout align-center fill-height justify-center>
    <v-layout align-start column justify-center wrap>
      <h2
        v-if="
          $store.state.hasOwnProperty('business') &&
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.hasOwnProperty('subscription') &&
          $store.state.business.tools.PBV.is_created
        "
        class="my-6 mr-0 ml-auto"
      >
        پرداخت با وندار
      </h2>

      <!-- banks -->
      <v-flex style="width: 100%">
        <setting />
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import Setting from "../elements/index/Setting"

export default {
  name: "Index",
  components: { Setting },
  data() {
    return {
      xAxesValue: [],
      yAxesValue: [],
      sum: 0,
      status: false,
    }
  },
}
</script>
