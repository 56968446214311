<template>
  <v-container>
    <!-- before load -->
    <v-layout
      v-if="!load"
      wrap
      class="businessLayout"
      fill-height
      justify-center
    >
      <v-flex v-for="index in businessCount" :key="index" class="card_box">
        <accountCardSkeleton />
      </v-flex>
      <v-flex class="card_box">
        <accountCard v-if="accounts.length > 1" :account="nextNewBusiness" />
        <accountCard v-else :account="firstNewBusiness" />
      </v-flex>
      <v-flex v-if="$vuetify.breakpoint.width > 930" />
    </v-layout>
    <!-- after load -->
    <v-layout
      v-else
      wrap
      class="businessLayout"
      fill-height
      :justify-center="
        $vuetify.breakpoint.xsOnly ||
        ($vuetify.breakpoint.smAndUp && accounts.length > 1)
      "
      :justify-start="$vuetify.breakpoint.smAndUp && accounts.length <= 1"
    >
      <v-flex v-if="isFirstNewBusiness" class="first-new-business-container">
        <NewBusinessCard :account="firstNewBusiness" />
      </v-flex>
      <v-flex
        v-for="(account, index) in accounts"
        :key="index"
        class="card_box"
      >
        <accountCard :account="account" />
      </v-flex>
      <v-flex v-if="accounts.length > 1" class="card_box">
        <accountCard :account="nextNewBusiness" />
      </v-flex>
      <v-flex v-if="$vuetify.breakpoint.width > 930" />
    </v-layout>
  </v-container>
</template>

<script>
import accountCard from "../elements/accountCard.vue"
import accountCardSkeleton from "../elements/accountCardSkeleton"
import NewBusinessCard from "../elements/NewBusinessCard.vue"
import { mapState } from "vuex"

export default {
  name: "Dashbord",
  components: {
    accountCard,
    accountCardSkeleton,
    NewBusinessCard,
  },
  data() {
    return {
      load: false,
      extraBusinessCost: 10000,
      accounts: [],
      firstNewBusiness: {
        entity: "onboarding",
        avatar: require("@/assets/img/folder-plus-outline.svg"),
        account_name: "ایجاد کسب‌وکار",
        wallet:
          "برای ادامه فرآیند، ابتدا کسب‌وکار بسازید و پس از آن درخواست فعال‌سازی ابزار‌های مورد نیاز را بدهید.",
        status: -4,
        right_box: {
          value: "76,159,135",
          text: "افزودن کسب‌و‌کار",
        },
      },
    }
  },
  computed: {
    ...mapState(["businessList"]),
    nextNewBusiness() {
      return {
        entity: "onboarding",
        avatar: require("@/assets/img/folder-plus-outline.svg"),
        account_name: "ایجاد کسب‌وکار",
        wallet: "کسب و کار بعدی خود را بسازید",
        status: -4,
        right_box: {
          value: "76,159,135", //background of this block
          text: "افزودن کسب‌و‌کار",
        },
      }
    },
    businessCount() {
      return this.$store.state.user.business_count
        ? Number(this.$store.state.user.business_count) + 1
        : 1
    },
    verification() {
      if (
        (this.$store.state.user.postal_code == null &&
          this.$store.state.user.email == null) ||
        this.$store.state.startVerificationTab == true
      ) {
        return true
      } else {
        return false
      }
    },
    isFirstNewBusiness() {
      return (
        this.accounts.length === 0 ||
        (this.accounts.length === 1 && this.accounts[0].entity === "personal")
      )
    },
    hasInvitationToBusiness() {
      if (!this.$store.state?.user?.invitation) return null
      return Object.prototype.hasOwnProperty.call(
        this.$store.state.user?.invitation?.business,
        "id"
      )
    },
  },
  watch: {
    businessList: {
      handler(newValue, oldValue) {
        if (
          newValue?.length > 0 &&
          oldValue?.length > 0 &&
          newValue?.length !== oldValue?.length
        ) {
          this.getBusinessList()
        }

        if (
          newValue &&
          newValue?.length === 1 &&
          newValue[0]?.business_name === "personal" &&
          (this.hasInvitationToBusiness === null ||
            this.hasInvitationToBusiness !== true)
        ) {
          this.$router.push("/onboarding")
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getBusinessList()
  },
  methods: {
    getBusinessList() {
      if (this.$store.getters.loggedIn) {
        this.$store
          .dispatch("retrieveBusinessList")
          .then((response) => {
            var custom = response
            custom.forEach((element, index) => {
              if (index !== 0) {
                element.entity = "business"
              }
            })
            custom[0].entity = "personal"
            this.accounts = custom
            this.load = true
          })
          .catch((error) => {
            //callsnackbar
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 1210px;
}
.layout {
  margin: -10px;
}
.businessLayout {
  width: 105%;
  min-height: calc(100vh - 64px);
  align-content: center;
  > div:nth-child(1):not(.card_box, .first-new-business-container),
  > div:nth-child(2):not(.card_box),
  > div:nth-child(3):not(.card_box),
  > div:nth-child(4):not(.card_box) {
    display: none;
  }
}
.card_box {
  align-self: flex-end;
  height: 252px;
  max-width: 280px;
  width: 280px;
  margin: 10px 10px;
  position: relative;
}

.first-new-business-container {
  display: flex;
  height: 180px;
  width: 100%;
  margin: 10px 10px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .first-new-business-container {
    height: 252px;
    justify-content: center;
  }
}
</style>
