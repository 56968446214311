<template>
  <div>
    <GeneralCard
      v-for="array in Object.entries(computedCashInCodeGroupByCode)"
      :key="array[0]"
    >
      <template v-if="array[1].length > 1">
        <div
          class="d-flex justify-space-between"
          :class="reuseIsMobile ? 'align-start' : 'align-center'"
        >
          <div :class="{ 'align-self-center': reuseIsMobile }">
            <p
              class="mb-1"
              :class="reuseIsMobile ? 'font-12-bo-333-18' : 'font_35'"
            >
              شناسه واریز مشترک
            </p>
            <div v-if="!reuseIsMobile" class="d-flex flex-wrap">
              <span
                v-for="(item, index) in array[1]"
                :key="index"
                class="font_30"
              >
                {{ item.bank_persian_name
                }}<span v-if="index !== array[1].length - 1">،&nbsp; </span>
              </span>
            </div>
          </div>
          <div class="d-flex align-center">
            <span class="ml-2" :class="reuseIsMobile ? 'font_35' : 'font_9'">
              {{ array[1][0].code }}
            </span>
            <CopyBtnGeneral
              :value="array[1][0].code"
              :input-id="'hidden-input' + array[1][0]?.code"
            />
          </div>
        </div>
        <div v-if="reuseIsMobile" class="d-flex flex-wrap">
          <span v-for="(item, index) in array[1]" :key="index" class="font_30">
            {{ item.bank_persian_name
            }}<span v-if="index !== array[1].length - 1">,&nbsp;</span>
          </span>
        </div>
        <v-divider class="my-4" style="border-style: dashed" />
        <div v-for="item in array[1]" :key="item.bank_code">
          <NewCashInIbanItem
            :is-shared-cash-in="true"
            :cash-in-item="item"
          /><br />
        </div>
      </template>
      <template v-else>
        <NewCashInIbanItem :cash-in-item="array[1][0]" />
      </template>
    </GeneralCard>
  </div>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import NewCashInIbanItem from "@/components/report/modals/addCashIn/elements/NewCashInIbanItem.vue"
import CopyBtnGeneral from "@/components/elements/copyBtnGeneral.vue"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "NewCashInIban",
  components: {
    CopyBtnGeneral,
    GeneralCard,
    NewCashInIbanItem,
  },
  mixins: [screenSize],
  computed: {
    computedCashInCodeGroupByCode() {
      return { ...this.$store.getters.cashInCodeGroupByCode }
    },
  },
}
</script>
<style scoped lang="scss"></style>
