<template>
  <v-card
    class="d-flex align-center justify-center pa-3 ml-2 mb-2"
    elevation="0"
    :color="backgroundColor || defineColor"
    style="border: 1px solid #999999"
    outlined
    height="40px"
    width="100%"
    min-width="160px"
    @click="$emit('itemClicked')"
  >
    <span class="d-flex align-center font_30 pl-2" style="white-space: nowrap">
      <slot />
    </span>

    <v-img
      width="12px"
      height="12px"
      max-height="12px"
      max-width="12px"
      :src="require('@/assets/img/Arrow.svg')"
    />
  </v-card>
</template>

<script>
export default {
  name: "MustEdit",
  props: {
    // eslint-disable-next-line vue/require-default-prop
    backgroundColor: {
      type: String,
    },
  },
  computed: {
    businessHasRejected() {
      return this.$store.state.business.has_rejection
    },
    defineColor() {
      return this.businessHasRejected ? "#F6E8E9" : "#F1F1F1"
    },
  },
}
</script>

<style lang="scss" scoped></style>
