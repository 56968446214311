<template>
    <v-img
      width="46px"
      aspect-ratio="1"
      :src="icon"
      class="ml-2 rounded-lg overflow-hidden"
    >
        <v-overlay
          class="d-flex align-center justify-center position-relative full-width full-height"
        >
          <v-progress-circular :value="file.progress">
           <v-btn small icon class="d-flex align-center justify-center" @click="abort"><v-icon class="cancel-icon" small> close </v-icon>
           </v-btn>
          </v-progress-circular>
        </v-overlay>
    </v-img>
</template>

<script>
export default {
  name: "FileUploaderUploadingFile",
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      files: [],
      fileIcons: {
        pdf: require("@/assets/img/file-uploader/pdf.svg"),
        excel: require("@/assets/img/file-uploader/excel.png"),
      },
    }
  },
  computed: {
    icon() {
      switch (this.file.extension) {
        case "pdf":
          return this.fileIcons["pdf"]
        case"xlsx":
        case"xlsm":
        case"xlsb":
        case"xltx":
        case"xltm":
        case"xls":
        case"xml":
        case"csv":
        case"xla":
        case"xlw":
          return this.fileIcons["excel"]
        default:
          return this.file.preview
      }
    },
  },
  methods: {
    abort() {
      this.file.controller.abort()
      this.$emit("cancel", this.file.id)
    },
  },
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.cancel-icon{
  width: auto !important;
}
</style>
