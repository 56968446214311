import moment from "moment-jalaali"

export const dateTimeHanddler = {
  data() {
    return {
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
    }
  },
  methods: {
    dateTime(date) {
      const moment = require("moment-jalaali")
      const currentDate = moment(date, "HH:mm:ss - jYYYY/jM/jD")
      return this.jalaliDate(currentDate, "jYYYY/jM/jD - HH:mm")
    },
    getFirstDayOfWeek(d) {
      // it gets Gregorian date
      d = new Date(d)
      let day = d.getDay()
      let numberOfDay = 0
      switch (day) {
        case 6:
          numberOfDay = 6
          break
        default:
          numberOfDay = -1
      }
      let diff = d.getDate() - day + numberOfDay // adjust when day is sunday
      return new Date(d.setDate(diff))
    },
    endOfMonth() {
      switch (this.month) {
        case "07":
        case "08":
        case "09":
        case "10":
        case "11":
          return 30
        case "12":
          return "29"
        default:
          return 31
      }
    },
    getMonthText(month) {
      if (month.substring(0, 1) == "0") {
        return this.monthItem[parseInt(month.substring(1, 2)) - 1]
      } else {
        return this.monthItem[parseInt(month.substring(0, 2)) - 1]
      }
    },
    persianDateText(str) {
      //year
      var yearText = str.substring(0, 4)

      //month
      var monthText = this.getMonthText(str.substring(4, 6))

      //day
      var dayText
      if (str.substring(6, 7) == "0") {
        dayText = str.substring(7, 8)
      } else {
        dayText = str.substring(6, 8)
      }

      //make text
      var result = dayText + " " + monthText + " " + yearText
      return result
    },
  },
}
