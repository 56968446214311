<template>
  <div>
    <v-layout :column="$vuetify.breakpoint.xsOnly" justify-start align-start>
      <v-flex class="label">
        {{ label }}
        <span>*</span>
      </v-flex>
      <v-flex class="withlabel big">
        <v-text-field
          ref="code"
          v-model="thousandSeprator"
          :rules="!isShowBankWarning ? mablaghRules : []"
          solo
          flat
          height="40"
          outlined
          :suffix="currencyName"
          placeholder="مبلغ دلخواه را وارد کنید"
          autofocus
          :disabled="loading"
          @keydown="numberKeyHandler"
        >
          <template #message="message">
            <div v-html="message.message"></div>
          </template>
        </v-text-field>
      </v-flex>
    </v-layout>
    <!-- <div
      :column="$vuetify.breakpoint.xsOnly"
      class="gray-text-box mt-2 mr-auto ml-0 py-3 px-6"
    >
      <v-layout column class="ma-0 pa-0">
        <v-flex v-if="totalAmount != 0">
          <v-layout align-center class="font_2 my-2">
            <v-flex>
              کل موجودی:
              {{ totalAmount | currency }}
              {{ currencyName }}
            </v-flex>
            <v-flex style="text-align-last: left !important">
              <div class="linkBorder d-inline-flex">
                <v-btn
                  class="small"
                  flat
                  @click="selectAmount(totalAmount)"
                  :ripple="false"
                  >انتخاب</v-btn
                >
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div> -->

    <balance
      v-if="!isShowBankWarning"
      :balance-amount="availableBalance"
      :min-height="60"
      title="موجودی قابل برداشت: "
      :select-btn="true"
      @onSelectClick="selectAmount(availableBalance)"
    />

    <balance
      v-if="!isShowBankWarning"
      :balance-amount="totalAmount"
      title="کل موجودی حساب شما"
      :min-height="60"
      :select-btn="false"
      extra-text="است"
    />

    <div v-if="!isShowBankWarning" class="mb-5">
      <!-- amount in word -->
      <div v-if="mablagh != null" class="font_18 iconAndMablagh mt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g transform="translate(-404 -330)">
            <rect
              style="fill: #fff"
              width="16"
              height="16"
              transform="translate(404 330)"
            />
            <path
              style="fill: #666"
              d="M4,7H20V17H4V7m8,3c1.657,0,2,.343,2,2s-.343,2-2,2-2-.343-2-2,.343-2,2-2M8,9a2,2,0,0,1-2,2v2a2,2,0,0,1,2,2h8a2,2,0,0,1,2-2V11a2,2,0,0,1-2-2Z"
              transform="translate(400 326)"
            />
          </g>
        </svg>
        <span>
          {{ amountInWord }}
          {{ currencyName }}
        </span>
      </div>
      <!-- wage -->
      <div
        v-if="mablagh != null && wage != null"
        class="font_18 iconAndMablagh mb-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g transform="translate(-404 -330)">
            <rect
              style="fill: #fff"
              width="16"
              height="16"
              transform="translate(404 330)"
            />
            <path
              style="fill: #666"
              d="M-1664.947-518.21a1.681,1.681,0,0,1-1.373-.707l-3.68-5.188,3.68-5.18a1.688,1.688,0,0,1,1.373-.716h9.263a1.684,1.684,0,0,1,1.684,1.684v8.421a1.69,1.69,0,0,1-1.684,1.685Zm-3.193-5.9,2.99,4.414h9.671v-8.82h-9.671Zm4.712,1.884,4.576-4.576.807.808-4.575,4.575Zm3.452-.168a.958.958,0,0,1,.955-.955.959.959,0,0,1,.956.955.96.96,0,0,1-.956.956A.959.959,0,0,1-1659.976-522.39Zm-3.432-3.432a.959.959,0,0,1,.956-.955.958.958,0,0,1,.955.955.959.959,0,0,1-.955.956A.959.959,0,0,1-1663.408-525.821Z"
              transform="translate(2074 862.151)"
            />
          </g>
        </svg>
        <span v-if="wage != null">
          {{ wage | currency }}
          {{ currencyName }}
          کارمزد
        </span>
        <span
          v-else
          class="default_gray"
          style="width: 25%; margin: 4px 10px 0 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import balance from "@/components/report/modals/addCashIn/balance.vue"

function formatAsCurrency(value, dec) {
  dec = dec || 0
  if (value === null) {
    return null
  }
  return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}

export default {
  components: {
    balance,
  },
  props: {
    label: {
      type: String,
      default: "قیمت",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    wage: {
      type: Number,
      default: null,
    },
    totalAmount: {
      type: [Number, null],
      default: 0,
    },
    availableBalance: {
      type: [Number, null],
      default: 0,
    },
    minimumAmount: {
      type: Number,
      default: 0,
    },
    isShowBankWarning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mablagh: null, //v-model of mablagh
      mablaghRules: [
        (v) =>
          !v ||
          this.convertToToman(this.mablagh) <= this.availableBalance ||
          "<span> مبلغ وارد شده از موجودی قابل برداشت شما بیشتر است </span>",
        (v) =>
          !v ||
          this.mablagh >= this.minimumAmount ||
          "<span> حداقل مبلغ جهت استفاده از این سرویس " +
            this.minimumAmount +
            " " +
            this.currencyName +
            " است</span>",
      ],
    }
  },
  computed: {
    amountInWord: {
      get: function () {
        var convert
        if (this.mablagh === null) {
          convert = "0"
        } else {
          convert = this.mablagh
        }
        return wordifyfa(convert)
      },
    },
    thousandSeprator: {
      get() {
        return formatAsCurrency(this.mablagh, 0)
      },
      set(newValue) {
        if (newValue) {
          this.mablagh = Number(
            this.convertNumbers2English(newValue).replace(/[^0-9.]/g, "")
          )
        } else {
          this.mablagh = null
        }
      },
    },
  },
  watch: {
    mablagh(newValue) {
      this.$emit("mablaghSet", newValue)
    },
  },
  methods: {
    selectAmount(amount) {
      this.mablagh = parseInt(
        this.$options.filters.currency(amount).replace(/,/g, "")
      )
      this.$emit("mablaghSet", this.mablagh)
    },
    convertToToman(value) {
      if (value) {
        if (this.$store.state.user.currency === "RIAL") {
          value = Math.floor(value / 10)
          return value
        } else return value
      }
    },
  },
}
</script>
