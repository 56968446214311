<template>
  <ChartCardOneFilter
    class="mmt-5"
    :title="kerdarLang.directDebit.depositByLicense"
  >
    <BarEChart
      v-if="withdrawalMandateCountStatus"
      chart-id="withdrawal-by-license-direct-debit"
      :x-axis-data="monthWithYearDate"
      :y-axis-data="newWithdrawalMandateCount"
      :second-y-axis-data="oldWithdrawalMandateCount"
      :hover-title="monthWithYearDate"
      :first-value-hover-subtitle="kerdarLang.general.newLicense"
      :second-value-hover-subtitle="kerdarLang.general.oldLicense"
      :whole-value-hover-subtitle="kerdarLang.general.wholeLicenses"
      first-bar-color="#4C9F87"
      second-bar-color="#BBBBBB"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </ChartCardOneFilter>
</template>

<script>
import ChartCardOneFilter from "@/components/kerdar/components/chartCardOneFilter"
import BarEChart from "@/components/kerdar/components/BarEChart"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "DirectDebitWithdrawalByLicenseEChart",
  components: {
    ChartCardOneFilter,
    BarEChart,
  },
  props: {
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      withdrawalMandateCountData: [],
      withdrawalMandateCountStatus: false,
    }
  },
  computed: {
    monthWithYearDate() {
      return this.withdrawalMandateCountData.map((item) => item[3])
    },
    newWithdrawalMandateCount() {
      return this.withdrawalMandateCountData.map((item) => item[1])
    },
    oldWithdrawalMandateCount() {
      return this.withdrawalMandateCountData.map((item) => item[2])
    },
  },
  mounted() {
    this.getWithdrawalMandateCountData()
  },
  methods: {
    async getWithdrawalMandateCountData() {
      const response = await kerdarServices(
        this.$http,
        this
      ).directDebit.httpDirectDebitWithdrawalMandate()
      //httpDirectDebitWithdrawalMandateApiV2
      const tempData = this.parsWithdrawalMandateCount(response.data.data)
      tempData.map((item) => {
        item[3] = jmoment(item[0]).local("fa").format("jMMMM jYYYY")
        item[4] = jmoment(item[0]).local("fa").format("jD jMMMM jYYYY")
      })
      // let manipulatedData = []
      // for (let i = 0; i < tempData.length; i++) {
      //   manipulatedData.push({
      //     date_time: tempData[i][0],
      //     newValue: tempData[i][1],
      //     oldValue: tempData[i][2],
      //     month_year_date: jmoment(tempData[i][0])
      //       .local("fa")
      //       .format("jMMMM jYYYY"),
      //     full_date: jmoment(tempData[i][0])
      //       .local("fa")
      //       .format("jD jMMMM jYYYY"),
      //   })
      // }
      this.withdrawalMandateCountData = tempData
      this.withdrawalMandateCountStatus = true
    },
    parsWithdrawalMandateCount(data) {
      let tempData = []
      for (const item of data) {
        let currentData = tempData.find((x) => x[0] == item[0])
        if (currentData) {
          if (item[1] == "old") {
            currentData[1] = item[2]
          } else {
            currentData[2] = item[2]
          }
        } else {
          if (item[1] == "old") {
            tempData.push([item[0], item[1], 0])
          } else {
            tempData.push([item[0], 0, item[2]])
          }
        }
      }
      return tempData
    },
  },
}
</script>

<style lang="scss" scoped></style>
