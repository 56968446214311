<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">بارگذاری مدارک</v-toolbar-title>
    </v-toolbar>

    <v-layout>
      <v-flex>
        <v-layout column>
          <v-flex v-if="$store.state.business.business_type == 'legal'">
            <v-card
              v-if="
                $store.state.business.statusBox.introduction_letter ==
                'accepted'
              "
              outlined
              flat
              width="100%"
              color="white"
              class="accepted"
            >
              <div class="van_green--text">معرفی‌نامه</div>
              <v-icon color="van_green"> check_circle </v-icon>
            </v-card>
            <v-card
              v-else
              outlined
              flat
              class="add"
              width="100%"
              color="white"
              @click.native="add_letter()"
            >
              <div>معرفی‌نامه</div>
              <v-icon>arrow_right_alt</v-icon>
            </v-card>
            <v-divider />
          </v-flex>
          <v-flex v-if="$store.state.business.business_type == 'legal'">
            <v-card
              v-if="
                $store.state.business.statusBox.official_Newspaper == 'accepted'
              "
              outlined
              flat
              width="100%"
              color="white"
              class="accepted"
            >
              <div class="van_green--text">روزنامه رسمی</div>
              <v-icon color="van_green"> check_circle </v-icon>
            </v-card>
            <v-card
              v-else
              outlined
              flat
              class="add"
              width="100%"
              color="white"
              @click.native="add_newspaper()"
            >
              <div>روزنامه رسمی</div>
              <v-icon>arrow_right_alt</v-icon>
            </v-card>
            <v-divider />
          </v-flex>
          <v-flex>
            <v-card
              v-if="
                $store.state.business.statusBox.national_card_photo ==
                'accepted'
              "
              outlined
              flat
              width="100%"
              color="white"
              class="accepted"
            >
              <div
                v-if="$store.state.business.business_type == 'legal'"
                class="van_green--text"
              >
                کارت ملی مدیر عامل
              </div>
              <div v-else class="van_green--text">کارت ملی صاحب کسب‌و‌کار</div>
              <v-icon color="van_green"> check_circle </v-icon>
            </v-card>
            <v-card
              v-else
              outlined
              flat
              class="add"
              width="100%"
              color="white"
              @click.native="add_card()"
            >
              <div v-if="$store.state.business.business_type == 'legal'">
                کارت ملی مدیر عامل
              </div>
              <div v-else>کارت ملی صاحب کسب‌و‌کار</div>
              <v-icon>arrow_right_alt</v-icon>
            </v-card>
          </v-flex>
          <!-- <v-flex v-if="$store.state.user.statusBox.shahkar !== true">
            <v-card outlined
              v-if="$store.state.user.statusBox.shahkar == false"
              @click.native="add_nationalcard_photo()"
              flat
              class="add"
              width="100%"
              color="white"
            >
              <div>
                بارگذاری کارت ملی صاحب سیم‌کارت
                <span class="van_color04--text">(تنها اقوام درجه یک)</span>
              </div>
              <v-icon>arrow_right_alt</v-icon>
            </v-card>
            <v-card outlined
              flat
              width="100%"
              color="white"
              v-else-if="$store.state.user.statusBox.shahkar == null"
              class="accepted"
            >
              <div class="van_color03--text">بررسی هویت صاحب سیم‌کارت</div>
              <svg
                class="pendingSvg"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
              >
                <g transform="translate(-245 -555)">
                  <g class="a" transform="translate(245 555)">
                    <path
                      class="d"
                      d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                    />
                    <path
                      class="e"
                      d="M 11 2 C 6.037380218505859 2 2 6.037380218505859 2 11 C 2 15.96261978149414 6.037380218505859 20 11 20 C 15.96261978149414 20 20 15.96261978149414 20 11 C 20 6.037380218505859 15.96261978149414 2 11 2 M 11 0 C 17.07513046264648 0 22 4.924869537353516 22 11 C 22 17.07513046264648 17.07513046264648 22 11 22 C 4.924869537353516 22 0 17.07513046264648 0 11 C 0 4.924869537353516 4.924869537353516 0 11 0 Z"
                    />
                  </g>
                  <path
                    class="b"
                    d="M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
                    transform="translate(247 557)"
                  />
                  <path
                    class="c"
                    d="M6,2V5H6L8,7,6,9H6v3h6V9h0L10,7l2-2h0V2Z"
                    transform="translate(247 559)"
                  />
                </g>
              </svg>
            </v-card>
          </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status1"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
      @click:outside="close_modal"
    >
      <AddNationalCardPhoto v-show="card" @closeModal="close_modal" />
      <AddOfficialNewspaper v-show="newspaper" @closeModal="close_modal" />
      <AddIntroductionLetter v-show="letter" @closeModal="close_modal" />
      <addMobileOwnerCard
        v-show="addmobileownercard"
        @closeModal="close_modal"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import addMobileOwnerCard from "../../modals/addMobileOwnerCard.vue"
import AddNationalCardPhoto from "@/components/OnBoarding/elements/AddNationalCardPhoto.vue"
import AddOfficialNewspaper from "@/components/OnBoarding/elements/AddOfficialNewspaper.vue"
import AddIntroductionLetter from "@/components/OnBoarding/elements/AddIntroductionLetter.vue"

export default {
  name: "AddDocument",
  components: {
    AddNationalCardPhoto,
    AddOfficialNewspaper,
    AddIntroductionLetter,
    addMobileOwnerCard,
  },
  data() {
    return {
      status1: false,
      card: false,
      newspaper: false,
      letter: false,
      addmobileownercard: false,
    }
  },
  watch: {
    status1: function (newValue) {
      if (!newValue) this.$store.commit("callAddDocumentmodal", false)
    },
  },
  methods: {
    close_modal() {
      this.card = false
      this.newspaper = false
      this.letter = false
      this.addmobileownercard = false
      this.status1 = false
      this.$store.commit("callAddDocumentmodal", false)
      this.$store.commit("setShouldDeleteUploadedFile", false)
    },
    add_nationalcard_photo() {
      this.close_modal()
      this.$store.commit("callAddDocumentmodal", true)
      this.$store.commit("setShouldDeleteUploadedFile", true)
      this.addmobileownercard = true
      this.status1 = true
    },
    add_card() {
      this.close_modal()
      this.$store.commit("callAddDocumentmodal", true)
      this.$store.commit("setShouldDeleteUploadedFile", true)
      this.card = true
      this.status1 = true
    },
    add_newspaper() {
      this.close_modal()
      this.$store.commit("callAddDocumentmodal", true)
      this.$store.commit("setShouldDeleteUploadedFile", true)
      this.newspaper = true
      this.status1 = true
    },
    add_letter() {
      this.close_modal()
      this.$store.commit("callAddDocumentmodal", true)
      this.$store.commit("setShouldDeleteUploadedFile", true)
      this.letter = true
      this.status1 = true
    },
  },
}
</script>

<style lang="scss" scoped>
.pendingSvg {
  .a,
  .b {
    fill: none;
  }
  .c,
  .e {
    fill: #f9d358;
  }
  .d,
  .e {
    stroke: none;
  }
}
.v-icon {
  color: $van_color03;
}
.add .v-icon {
  transform: rotate(180deg);
}
.add,
.accepted {
  display: flex;
  justify-content: space-between;
}
.add {
  cursor: pointer !important;
}
.v-divider {
  margin: 20px 0;
}
</style>
