<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">اعلانات</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          outlined
          depressed
          class="btn_small_normal mx-0"
          :loading="loading"
          @click="readAll()"
        >
          همه را دیدم
        </v-btn>
        <v-btn
          text
          outlined
          depressed
          class="btn_small_normal mx-1"
          @click="$emit('openSetting')"
        >
          تنظیمات اعلان‌ها
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex class="pa-0">
        <v-list two-line class="notif-list">
          <v-list-item v-if="!load">
            <v-list-item-content class="notif-tile">
              <v-list-item-title class="font_2">
                <span class="default_gray" />
              </v-list-item-title>
              <v-list-item-subtitle class="font_4">
                <span class="default_gray" style="width: 20%" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list two-line class="notif-list">
          <div v-if="load && notificationList.length == 0" class="pa-10">
            اعلانی برای نمایش وجود ندارد
          </div>
          <div v-if="load">
            <template v-for="(item, index) in notificationList">
              <v-list-item
                :key="'i1' + index"
                ripple
                :style="
                  item.read == 0
                    ? 'background-color:rgba(76, 159, 136, 0.1);'
                    : ''
                "
                @click="
                  item.read == 0
                    ? read(item.id, index, true)
                    : read(item.id, index, false)
                "
              >
                <v-list-item-content class="notif-tile">
                  <div v-if="item.business" class="font_2">
                    کسب‌وکار <span>{{ item.business?.company_name_fa }}</span>
                  </div>

                  <div class="font_2">
                    {{ item.message }}
                  </div>
                  <v-list-item-subtitle class="font_4">
                    <v-tooltip v-if="item.read == 0" top>
                      <template #activator="{ on }">
                        <span class="read_circle" v-on="on" />
                      </template>
                      <span>به اعلانات «دیده» اضافه شود</span>
                    </v-tooltip>

                    <v-tooltip v-else top>
                      <template #activator="{ on }">
                        <span class="unread_circle" v-on="on" />
                      </template>
                      <span>به اعلانات «ندیده» اضافه شود</span>
                    </v-tooltip>
                    {{ item.created_at_jalali }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <observer
                  v-if="index == notificationList.length - 1 && !endOfLoadMore"
                  @intersect="intersected"
                />
              </v-list-item>
              <v-divider
                v-if="index + 1 < notificationList.length"
                :key="index"
              />
            </template>
          </div>
        </v-list>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import observer from "../elements/observer.vue"
export default {
  name: "Notification",
  components: {
    observer,
  },
  data() {
    return {
      load: false,
      notificationList: [],
      loading: false,
      currentPage: 1,
      endOfLoadMore: false,
    }
  },
  mounted() {
    if (
      this.$route.name !== "registerPage" &&
      this.$route.name !== "mobileInputPage"
    ) {
      this.loadNotification()
    }
  },
  methods: {
    loadNotification() {
      this.$http
        .get(this.$store.state.api_base_url + "/personal/notifications/list", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.load = true
          this.notificationList = response.data.data.notifications.data
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    read(id, index, boolean) {
      if (boolean == true) {
        this.notificationList[index].read = 1
        this.$store.commit("setUserData", {
          notification_count: parseInt(
            this.$store.state.user.notification_count - 1
          ),
        })
      } else {
        this.notificationList[index].read = 0
        this.$store.commit("setUserData", {
          notification_count: parseInt(
            this.$store.state.user.notification_count + 1
          ),
        })
      }

      this.$http
        .post(
          this.$store.state.api_base_url + "/personal/notifications/read",
          {
            id: id,
            read: boolean,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          //undo the fake action
          if (response.data.status !== 1) {
            if (boolean == true) {
              this.notificationList[index].read = 0
              this.$store.commit("setUserData", {
                notification_count: parseInt(
                  this.$store.state.user.notification_count + 1
                ),
              })
            } else {
              this.notificationList[index].read = 1
              this.$store.commit("setUserData", {
                notification_count: parseInt(
                  this.$store.state.user.notification_count - 1
                ),
              })
            }
          }
        })
        .catch((error) => {})
    },
    readAll() {
      this.loading = true
      this.endOfLoadMore = false
      this.$http
        .post(
          this.$store.state.api_base_url + "/personal/notifications/read/all",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 1) {
            this.notificationList.forEach((element) => {
              element.read = 1
            })
            this.$store.commit("setUserData", {
              notification_count: 0,
            })
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          //callsnackbar
        })
    },
    intersected() {
      var cur = this.currentPage + 1
      this.$http
        .get(
          this.$store.state.api_base_url +
            "/personal/notifications/list?page=" +
            cur,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          var temporaryList = this.notificationList

          response.data.data.notifications.data.forEach((element) => {
            temporaryList.push(element)
          })

          this.notificationList = temporaryList

          this.currentPage = response.data.data.notifications.current_page
          if (
            response.data.data.notifications.last_page ==
            response.data.data.notifications.current_page
          ) {
            this.endOfLoadMore = true
          }
        })
        .catch((error) => {
          //callsnackbar
        })
    },
  },
}
</script>
