<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> تنظیمات کلی حساب </v-toolbar-title>
    </v-toolbar>

    <v-layout column>
      <div class="font_14" style="margin: 30px 30px -15px 0">تنظیمات عمومی</div>
      <v-flex class="py-2 px-0">
        <v-layout column>
          <v-flex>
            <v-layout
              justify-start
              align-center
              style="height: 60px; padding: 10px 20px"
            >
              <v-flex
                class="font_10"
                style="max-width: 180px; text-align-last: right"
              >
                تصویر کلی حساب
              </v-flex>
              <v-flex style="text-align-last: right">
                <v-avatar size="40">
                  <v-img
                    v-if="$store.state.user.avatar"
                    size="40"
                    :src="
                      $store.state.storage_base_url + $store.state.user.avatar
                    "
                    alt="avatar"
                  />
                  <v-img
                    v-else
                    size="40"
                    :src="require('@/assets/img/default.png')"
                    alt="avatar"
                  />
                </v-avatar>
              </v-flex>
              <v-flex style="max-width: 30px">
                <div class="linkBorder">
                  <v-btn
                    class="small"
                    text
                    :ripple="false"
                    @click="change_Photo()"
                  >
                    تغییر
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-divider />
          <v-flex>
            <v-layout
              justify-start
              align-center
              style="height: 60px; padding: 10px 20px"
            >
              <v-flex
                class="font_10"
                style="max-width: 180px; text-align-last: right"
              >
                ایمیل
              </v-flex>
              <v-flex class="font_10" style="text-align-last: right">
                <span v-if="$store.state.user.email">
                  {{ $store.state.user.email }}
                </span>
              </v-flex>
              <v-flex style="max-width: 30px">
                <div class="linkBorder">
                  <v-btn class="small" text :ripple="false" @click="addemail()">
                    تغییر
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-divider />
          <v-flex>
            <v-layout
              justify-start
              align-center
              style="height: 60px; padding: 10px 20px"
            >
              <v-flex
                class="font_10"
                style="max-width: 180px; text-align-last: right"
              >
                واحد پولی حساب‌ها
              </v-flex>
              <v-flex />
              <v-flex
                class="small"
                style="max-width: 110px; text-align-last: left"
              >
                <v-select
                  v-model="currency"
                  :items="currencyItems"
                  item-text="text"
                  item-value="value"
                  solo
                  flat
                  height="40"
                  outlined
                  label="تومان"
                  style="max-width: 110px"
                  @change="setCurrency()"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <div class="font_14" style="margin: -3px 30px -15px 0px">
        موارد امنیتی
      </div>
      <v-flex style="padding: 10px 0">
        <v-layout column>
          <v-flex>
            <v-layout
              justify-start
              align-center
              style="height: 50px; padding: 10px 20px"
            >
              <v-flex
                class="font_10"
                style="max-width: 180px; text-align-last: right"
              >
                رمز عبور حساب
              </v-flex>
              <v-flex />
              <v-flex style="max-width: 30px">
                <div class="linkBorder">
                  <v-btn
                    class="small"
                    text
                    :ripple="false"
                    @click="changePassword()"
                  >
                    تغییر
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-divider />
          <v-flex>
            <v-layout
              justify-start
              align-center
              style="height: 60px; padding: 10px 20px"
            >
              <v-flex
                class="font_10"
                style="max-width: 180px; text-align-last: right"
              >
                ورود 2 مرحله‌ای
              </v-flex>
              <!-- TODO: connect to actual parameter -->
              <v-flex class="font_10" style="text-align-last: right">
                <span v-if="!$store.state.user.is_two_factor">غیر فعال</span>
                <span v-else-if="$store.state.user.two_factor_type === 'sms'"
                  >فعال با پیامک</span
                >
                <span v-else>فعال با رمزساز گوگل</span>
              </v-flex>
              <v-flex style="max-width: 30px">
                <div class="linkBorder">
                  <v-btn
                    class="small"
                    text
                    :ripple="false"
                    @click="twostepAuthentication()"
                  >
                    تغییر
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-divider />
          <v-flex>
            <v-layout
              justify-start
              align-center
              style="height: 50px; padding: 10px 20px"
            >
              <v-flex
                class="font_10"
                style="max-width: 180px; text-align-last: right"
              >
                دستگاه‌های امن
              </v-flex>
              <v-flex />
              <v-flex style="max-width: 30px">
                <div class="linkBorder">
                  <v-btn
                    class="small"
                    text
                    :ripple="false"
                    @click="trustedDevice()"
                  >
                    تغییر
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status1"
      :content-class="
        $store.state.showThirdModal ? 'vanmodal secondModal' : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <addEmail v-show="add_email" @closeModal="close_modal()" />
      <twoStepAuthentication
        v-if="two_step_authentication"
        @closeModal="close_modal()"
      />
      <settingPhoto
        v-show="changePhoto"
        :only-personal="true"
        @closeModal="close_modal()"
      />
      <trusted-devices v-if="showTrustedDevices" @closeModal="close_modal()" />
    </v-dialog>
  </v-card>
</template>

<script>
import addEmail from "./addEmail.vue"
import twoStepAuthentication from "./twoStepAuthentication.vue"
import settingPhoto from "./settingPhoto.vue"
import TrustedDevices from "./trustedDevices"

export default {
  name: "UserSetting",
  components: {
    TrustedDevices,
    addEmail,
    settingPhoto,
    twoStepAuthentication,
  },
  props: [],
  data: function () {
    return {
      status1: false,
      add_email: false,
      two_step_authentication: false,
      changePhoto: false,
      lastMobile: "",
      currencyItems: [
        { text: "تومان", value: "TOMAN" },
        { text: "ریال", value: "RIAL" },
      ],
      currency: this.$store.state.user.currency,
      showTrustedDevices: false,
    }
  },
  watch: {
    status1: function (newValue) {
      if (!newValue) {
        this.$store.commit("callUserSetting", false)
        this.$store.commit("changeShowThirdModal", false)
      }
    },
  },
  methods: {
    setCurrency() {
      this.$http
        .put(
          this.$store.state.api_base_url + "/personal/update/currency",
          {
            currency: this.currency,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.commit("setCurrency", this.currency)
          this.$store.commit("callSuccessSnackbar", "تغییر با موفقیت ذخیره شد")
          this.$forceUpdate()
        })
        .catch((error) => {})
    },
    close_modal() {
      this.add_email = false
      this.changePhoto = false
      this.two_step_authentication = false
      this.status1 = false
      this.showTrustedDevices = false
      this.$store.commit("callUserSetting", false)
    },
    addemail() {
      this.close_modal()
      this.$store.commit("callUserSetting", true)
      this.add_email = true
      this.status1 = true
    },
    twostepAuthentication() {
      this.close_modal()
      this.$store.commit("callUserSetting", true)
      this.two_step_authentication = true
      this.status1 = true
    },
    change_Photo() {
      this.close_modal()
      this.$store.commit("callUserSetting", true)
      this.changePhoto = true
      this.status1 = true
    },
    changePassword() {
      this.close_modal()
      this.lastMobile = this.$store.state.user.mobile
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href =
            process.env.VUE_APP_SSO_URL +
            "?callback=" +
            window.location.href +
            "&mobile=" +
            this.lastMobile +
            "&scope=changePassword"
        })
        .catch((error) => {
          window.location.href =
            process.env.VUE_APP_SSO_URL +
            "?callback=" +
            window.location.href +
            "&mobile=" +
            this.lastMobile +
            "&scope=changePassword"
        })
    },
    trustedDevice() {
      this.close_modal()
      this.$store.commit("callUserSetting", true)
      this.showTrustedDevices = true
      this.status1 = true
    },
  },
}
</script>
