<template>
  <div>
    <v-layout v-if="load" wrap class="cards" justify-start>
      <v-flex
        v-if="
          $store.state.hasOwnProperty('business') &&
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.hasOwnProperty('ipg') &&
          $store.state.business.tools.ipg.is_created
        "
      >
        <card v-if="isLoad" @view_form="view_function()" />
        <cardSkeleton v-else />
      </v-flex>
      <!-- <empty v-else @add_ipg="add_function()"></empty> -->
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status"
      :content-class="
        $store.state.secondEditFormmodal ? 'secondModal vanmodal' : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <!--      <add v-if="add" @closeModal="close_modal()" @finishAdd="finish_add()" />-->
      <show v-if="view" @closeModal="close_modal()" />
    </v-dialog>

    <v-dialog
      v-if="$store.state.ipg"
      v-model="addIsFinalize"
      width="360"
      content-class="confirmationMessage withlinks"
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/checkbox-marked-circle-outline.svg')"
              width="40"
              height="40"
            />
          </v-flex>
          <v-flex class="main-title font_3">
            بررسی و تایید تا
            <span v-if="$store.state.ipg.vip">72</span>
            <span v-else>12</span>
            ساعت آینده
          </v-flex>
          <v-flex class="main-des font_4">
            جهت فعال‌سازی، با مشاهده مستندات، تنظیمات مورد نیاز را انجام دهید
          </v-flex>
          <v-flex class="mt-5">
            <v-layout align-center justify-center>
              <v-flex
                v-if="$store.state.ipg.api_key"
                class="font_4 van_color11"
                style="padding: 9px 10px; margin-left: 3px"
              >
                {{ $store.state.ipg.api_key }}
              </v-flex>
              <v-flex style="max-width: 80px; position: relative">
                <v-btn
                  color="van_color06"
                  depressed
                  :ripple="false"
                  class="btn-with-icon"
                  style="margin: 0px !important"
                  @click="shareLink()"
                >
                  <v-icon color="van_color03" size="16"> content_copy </v-icon
                  >کپی کلید
                </v-btn>
                <v-tooltip v-model="tooltip_show" attach=".share" bottom>
                  <span>کپی شد</span>
                </v-tooltip>
              </v-flex>
              <input
                v-if="$store.state.ipg.api_key"
                id="hidden-input"
                v-model="$store.state.ipg.api_key"
                class="hidden-input"
              />
            </v-layout>
          </v-flex>
          <v-flex>
            <v-btn
              text
              dark
              class="btn_small_normal"
              to="//vandarpay.github.io/docs/ipg/"
              target="_blank"
            >
              مشاهده مستندات
            </v-btn>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="addIsFinalize = false"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import card from "./card"
import cardSkeleton from "./cardSkeleton"
import show from "../modals/show"

export default {
  name: "ListOfCards",
  components: {
    card,
    show,
    cardSkeleton,
  },
  data() {
    return {
      //this is modal status
      isLoad: false,
      status: false,
      add: false,
      view: false,
      ipg: {},
      /*ipg:{
          "urls": [
            'www.PaperPiano.com',
            'www.civil808.com'
          ],
          "ips": [
            '198.123.3.0',
            '198.156.3.0',
            '189.123.4.0'
          ],
          "api_key": "5644b6164ce311552c78d64da4418d18104e0d39",
          "wage": true,
          "vip": true,
          "payment": true,
          "status": 1,
          "transactions_count": 10,
          "transactions_sum": 200000
        },*/
      load: true,
      addIsFinalize: false,
      tooltip_show: false,
    }
  },
  mounted() {
    this.$http
      .get(this.$store.getters.apiWithVersionParam("3") + "/ipg/show", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + this.$store.state.token,
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.ipg = response.data.data.ipg
        if (this.ipg != null) {
          this.$store.commit("addIpg", this.ipg)
        }
        this.isLoad = true
      })
      .catch((error) => {
        //callsnackbar
      })
  },
  methods: {
    shareLink() {
      /* Get the text field */
      let copyText = document.querySelector("#hidden-input")

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        /* Alert the copied text */
        this.tooltip_show = true
        setTimeout(() => {
          this.tooltip_show = false
        }, 2000)
      }
    },
    close_modal() {
      this.add = false
      this.view = false
      this.status = false
    },
    add_function() {
      this.close_modal()
      this.add = true
      this.status = true
    },
    view_function() {
      this.close_modal()
      this.status = true
      this.view = true
    },
    finish_add() {
      this.addIsFinalize = true
    },
  },
}
</script>

<style lang="scss" scoped>
.addServiceCard {
  height: 160px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 0px 30px 0px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  cursor: pointer;
  .font_11 {
    color: $van_green !important;
  }
  div.backgound {
    height: 70px !important;
    width: 70px !important;
    background-color: #ebf5f3;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    > div {
      width: 50px;
      background-color: $van_green;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $van_color13;
    }
  }
}
.cards {
  margin-right: -5px;
  margin-left: -5px;
  > .flex {
    align-self: flex-end;
    // height: 120px;
    width: 100%;
    margin: 5px 5px;
  }
}
@media (max-width: 1200px) {
  .layout {
    justify-content: center;
  }
}
</style>
