<script>
export default {
  name: "SettlementErrorModal",
  props: {
    isErrorHappened: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: "مشاهده لیست",
    },
    modalTitle: {
      type: String,
      default: "احتمالا درخواست ثبت نشده است",
    },
    modalSubTitle: {
      type: String,
      default: "با مشاهده لیست برداشت‌ها، ثبت شدن یا نشدن برداشت را بررسی کنید",
    },
    hasSubTitle: {
      type: Boolean,
      default: false,
    },
    modalTitleAdditionalStyle: {
      type: String,
      default: "",
    },
  },
  computed: {
    computedIsErrorHappened() {
      return this.isErrorHappened
    },
  },
  methods: {
    doRequireAction() {
      this.$emit("do-require-action")
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="computedIsErrorHappened"
    width="360"
    content-class="confirmationMessage"
    no-click-animation
    persistent
  >
    <v-card outlined height="100%">
      <v-layout column justify-center align-center>
        <v-flex>
          <v-img
            :src="require('@/assets/img/close-circle-outline.svg')"
            width="40"
            height="40"
          />
        </v-flex>
        <v-flex
          class="main-title font_20 mt-5"
          :class="modalTitleAdditionalStyle"
        >
          {{ modalTitle }}
        </v-flex>
        <v-flex v-if="hasSubTitle" class="main-des font_4">
          {{ modalSubTitle }}
        </v-flex>
        <v-flex>
          <v-btn
            text
            depressed
            outlined
            class="btn_small_normal"
            @click="doRequireAction"
          >
            {{ btnText }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
