<template>
  <div class="location-info">
    <v-layout column justify-space-between>
      <v-flex>
        <v-form ref="form" v-model="locationFormValidation">
          <v-layout :column="reuseIsMobile" justify-start align-start>
            <v-flex class="label">
              کد پستی
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel big">
              <PostalCode
                :input-value="postal_code"
                @setValue="setPostalCode($event)"
                @setAddress="setAddress($event)"
              />
            </v-flex>
            <transition v-if="reuseIsMobile" name="list">
              <div
                v-show="$store.state.newbusiness.isPostalCodeFieldFocused"
                class="align-center max-width-250"
              >
                <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                  info_outline
                </v-icon>
                <span class="font-11-no-bbb-18">
                  کد پستی که در سامانه مالیاتی و اینماد ثبت شده می‌باشد </span
                ><br />
                <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                  info_outline
                </v-icon>
                <span class="font-11-no-bbb-18">
                  کد پستی باید با آدرس و شماره تلفن همخوانی داشته باشد
                </span>
              </div>
            </transition>
          </v-layout>
          <div v-if="postal_code">
            <v-layout
              :column="reuseIsMobile"
              justify-start
              align-start
              style="margin-bottom: 10px"
            >
              <v-flex class="label">
                استان و شهر
                <span>*</span>
              </v-flex>
              <v-flex v-if="addressDetailsRequired" class="withlabel">
                <v-autocomplete
                  v-model="city_province_id"
                  :rules="city_id_Rules"
                  :items="provincesList"
                  item-text="value"
                  item-value="key"
                  solo
                  flat
                  height="40"
                  outlined
                  single-line
                  no-data-text="اطلاعاتی برای نمایش موجود نیست"
                  placeholder="برای جستجو تایپ کنید"
                />
              </v-flex>
              <v-flex v-else class="withlabel">
                <v-text-field
                  v-model="cityAndProvince"
                  solo
                  flat
                  height="40"
                  outlined
                  readonly
                >
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout
              :column="reuseIsMobile"
              justify-start
              align-start
              style="margin-bottom: 10px"
            >
              <v-flex class="label">
                آدرس
                <span>*</span>
              </v-flex>
              <v-flex class="withlabel big">
                <v-text-field
                  v-model.trim="address"
                  :rules="address_Rules"
                  solo
                  flat
                  outlined
                  @focus="changeAddressFocusState"
                  @blur="changeAddressBlurState"
                >
                  <!-- :readonly="
                    (!$store.state.isUser &&
                      $store.state.business.address_detail.address != null &&
                      $store.state.business.has_shaparak_terminal) ||
                    (!$store.state.isUser &&
                      $store.state.business.status == 1) ||
                    ($store.state.isUser &&
                      $store.state.user.has_shaparak_terminal)
                  " -->
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-text-field>
              </v-flex>
              <transition v-if="reuseIsMobile" name="list">
                <div
                  v-show="addressIsFocused"
                  class="align-center max-width-250"
                >
                  <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                    info_outline
                  </v-icon>
                  <span class="font-11-no-bbb-18">
                    آدرس باید با کدپستی و شماره تلفن ثابت همخوانی داشته باشد
                  </span>
                </div>
              </transition>
            </v-layout>

            <v-layout :column="reuseIsMobile" justify-start align-start>
              <v-flex class="label">
                تلفن ثابت
                <span>*</span>
              </v-flex>
              <v-flex class="withlabel inputBeside">
                <v-text-field
                  ref="cityCode"
                  v-model="cityCode"
                  class="smallPart"
                  solo
                  flat
                  height="40"
                  outlined
                  disabled
                  placeholder="کد"
                >
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-text-field>
                <phoneNumber
                  :input-value="phone_number"
                  @setValue="phone_number = $event"
                />
              </v-flex>
              <transition v-if="reuseIsMobile" name="list">
                <div
                  v-show="$store.state.newbusiness.isPhoneNumberFieldFocused"
                  class="align-center max-width-250"
                >
                  <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
                    info_outline
                  </v-icon>
                  <span class="font-11-no-bbb-18">
                    تلفن ثابت باید با آدرس و کد پستی همخوانی داشته باشد
                  </span>
                </div>
              </transition>
            </v-layout>
          </div>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import PostalCode from "@/components/inputs/postalCode"
import phoneNumber from "@/components/inputs/phoneNumber.vue"
import servicesRepository from "@/services/servicesRepository"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "LocationInfo",
  components: {
    PostalCode,
    phoneNumber,
  },
  mixins: [screenSize],
  props: {
    shouldSendUpLocationData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      postal_code: "",
      city_province_id: "",
      city: "",
      city_id: "",
      province: "",
      province_id: "",
      address: "",
      locationFormValidation: false,
      cityCode: "",
      phone_number: "",
      provincesList: [],
      cityCodes: [],
      city_id_Rules: [(v) => !!v || ""],
      addressDetailsRequired: false,
      address_Rules: [
        (v) => !!v || "<span> آدرس شرکت را وارد کنید </span>",
        (v) =>
          /[\u0600-\u06FF]+/.test(v) ||
          "<span> آدرس باید با حروف فارسی وارد شود </span>",
        (v) =>
          (this.removeWhiteSpaces(v) && this.removeWhiteSpaces(v).length > 9) ||
          "<span> آدرس باید حداقل 10 کاراکتر باشد </span>",
      ],
      addressIsFocused: false,
    }
  },
  computed: {
    cityAndProvince() {
      return this.province + ", " + this.city
    },
  },
  watch: {
    city_province_id(newValue) {
      if (newValue !== "") {
        this.cityCode =
          this.cityCodes[parseInt(this.city_province_id.split("-")[0] - 1)]
        this.province_id = parseInt(this.city_province_id.split("-")[0])
        this.city_id = parseInt(this.city_province_id.split("-")[1])
      }
    },
    locationFormValidation(value) {
      this.$emit("is-location-info-form-valid", value)
    },
    shouldSendUpLocationData(value) {
      if (value) {
        this.cookLocationData()
      }
    },
    postal_code() {
      this.checkFormValidation()
    },
    address() {
      this.checkFormValidation()
    },
    phone_number() {
      this.checkFormValidation()
    },
  },
  created() {
    if (
      this.$store.state[this.$store.getters.userOrBuisenss].address_detail &&
      !this.$store.state[this.$store.getters.userOrBuisenss].address_detail.city
    ) {
      this.getCities()
      this.addressDetailsRequired = true
      this.city_province_id =
        this.$store.state[this.$store.getters.userOrBuisenss].address_detail
          .provinces_id +
        "-" +
        this.$store.state[this.$store.getters.userOrBuisenss].address_detail
          .city_id
    }
  },
  mounted() {
    if (
      this.$store.state[this.$store.getters.userOrBuisenss].status === 1 ||
      (this.$store.state.isUser && this.$store.state.user.has_shaparak_terminal)
    ) {
      this.locationFormValidation = false
    }
  },
  methods: {
    setAddress(response) {
      if (response !== null) {
        this.addressDetailsRequired = false
        this.city = response.city
        this.province = response.provinces
        this.address = response.address
        this.cityCode = response.provinces_code
        this.city_id = response.city_id
        this.province_id = response.provinces_id
      } else {
        this.getCities()
        this.addressDetailsRequired = true
        this.city = ""
        this.province = ""
        this.address = ""
        this.cityCode = ""
        this.city_province_id = ""
        this.city_id = ""
        this.province_id = ""
      }
    },
    setPostalCode(response) {
      this.postal_code = response
    },
    getCities() {
      servicesRepository(this)
        .informationService.httpGetCities()
        .then((response) => {
          this.provincesList = Object.keys(response.data.data).map(function (
            key
          ) {
            return { key: key, value: response.data.data[key] }
          })
          this.cityCodes = Object.keys(response.data.cityCode).map(function (
            key
          ) {
            return response.data.cityCode[key]
          })
        })
        .catch((error) => {
          window.location.reload()
        })
    },
    changeAddressFocusState() {
      this.addressIsFocused = true
      this.$emit("address-is-focused")
    },
    changeAddressBlurState() {
      this.addressIsFocused = false
      this.$emit("address-is-blurred")
    },
    cookLocationData() {
      const data = {
        postal_code: this.postal_code,
        postal_code_detail: {
          city_id: this.city_id,
          province_id: this.province_id,
          address: this.address,
          phone_number: this.phone_number,
        },
      }
      this.$emit("send-up-location-info", data)
    },
    removeWhiteSpaces(text) {
      return text.replace(/^\s+|\s+$|\s+(?=\s)/g, "")
    },
    checkFormValidation() {
      if (
        this.removeWhiteSpaces(this.address).length > 9 &&
        this.removeWhiteSpaces(this.postal_code).length === 10 &&
        this.cityAndProvince &&
        this.removeWhiteSpaces(this.phone_number).length === 8
      ) {
        this.locationFormValidation = true
      } else {
        this.locationFormValidation = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.location-info {
  width: 536px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 32px;

  @media screen and (max-width: 599px) {
    width: 316.66px;
    max-width: 536px;
  }

  @media screen and (min-width: 600px) and (max-width: 960px) {
    width: 500px;
    padding: 32px 20px;
  }
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
</style>
