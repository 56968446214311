<template>
  <v-layout wrap :column="$vuetify.breakpoint.xs">
    <v-flex class="pa-1 sm6 md4">
      <div class="box">
        <v-card outlined class="guideCard" color="white" flat :hover="true">
          <v-layout column align-center>
            <v-flex class="image">
              <img width="46" src="@/assets/img/newIcon/key.svg" />
            </v-flex>
            <v-flex class="mt-5">
              <div class="font_5 bold">API کلید</div>
            </v-flex>
            <v-flex>
              <div class="font_4">دریافت client_id , client_secret</div>
            </v-flex>
            <v-flex class="align-self-center mt-5" style="max-width: 106px">
              <v-btn
                text
                dark
                :loading="copyApiLoading"
                class="btn btn_small_normal primary ma-auto"
                height="36px"
                style="width: 106px !important"
                @click="shareLink"
              >
                کپی کنید
              </v-btn>
              <v-tooltip v-model="tooltip_show" top>
                <template #activator="{ on }">
                  <v-btn
                    style="
                      position: absolute;
                      opacity: 0 !important;
                      width: 1px;
                      margin: 0px;
                      padding: 0px;
                      z-index: -1;
                      left: 105px;
                    "
                    v-on="on"
                  />
                </template>
                <span>کپی شد</span>
              </v-tooltip>
              <input
                id="hidden-input1"
                :value="JSON.stringify(apiKey)"
                class="hidden-input"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-flex>
    <v-flex sm6 md4 class="pa-1">
      <div class="box">
        <v-card outlined class="guideCard" color="white" flat :hover="true">
          <v-layout column align-center>
            <v-flex class="image">
              <img width="46" src="@/assets/img/newIcon/ipg-document.svg" />
            </v-flex>
            <v-flex class="mt-5">
              <div class="font_5 bold">مستندات فنی و پلاگین‌ها</div>
            </v-flex>
            <v-flex>
              <div class="font_4">ورود به بخش توسعه‌دهندگان وندار</div>
            </v-flex>
            <v-flex class="align-self-center mt-5" style="max-width: 106px">
              <v-btn
                text
                dark
                class="btn btn_small_normal primary ma-auto"
                height="36px"
                style="width: 106px !important"
                @click="openDoc()"
              >
                توسعه‌دهندگان
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "MiandoGuide",

  data() {
    return {
      tooltip_show: false,
      apiKey: null,
      copyApiLoading: false,
    }
  },
  methods: {
    openDoc() {
      window.open("https://vandarpay.github.io/docs/miando/", "_blank")
    },
    shareLink() {
      this.copyApiLoading = true
      this.$http
        .get(
          this.$store.state.miando_base_url +
            `/applications/${this.$store.state.business.id}`,
          {
            headers: {
              "X-Requested-With": "user",
            },
          }
        )
        .then((resp) => {
          this.apiKey = {}
          this.apiKey.client_id = resp.data.data.client_id
          this.apiKey.client_secret = resp.data.data.client_secret
        })
        .then(() => {
          /* Get the text field */
          let copyText = document.querySelector("#hidden-input1")

          if (copyText) {
            navigator.clipboard.writeText(copyText.value)

            /* Alert the copied text */
            this.tooltip_show = true
            this.copyApiLoading = false

            setTimeout(() => {
              this.tooltip_show = false
            }, 2000)
          }
        })
        .catch(() => {
          this.copyApiLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.flex {
  .box {
    position: relative;
    height: 150px;
    transition: all ease 0.5s;
    // overflow: hidden;

    .guideCard {
      padding: 19px 16px;
      height: 150px;
      overflow: hidden;
      &.v-card--hover {
        position: absolute;
        top: 0%;
        width: 100%;

        &:hover {
          box-shadow: 0px 0px 10px #0000002b !important;
          height: 215px;
          transition: all ease 0.5s;
          z-index: 5;
        }
      }
    }
  }
}
</style>
