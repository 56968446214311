<template>
  <div>
    <div class="font_5 bold" style="margin: 23px 30px 18px">
      تاریخچه واریز و برداشت
    </div>
    <v-card>
      <v-layout class="pt-0">
        <v-flex class="mt-0 mb-8 px-7 py-4">
          <v-data-table
            :headers="headers"
            :items="$store.state.customer.selectedCustomer.transactions"
            class="elevation-0 vandarTable customer_transaaction_table noHover"
            no-data-text="تراکنشی برای نمایش وجود ندارد"
            no-results-text="در حال دریافت لیست تراکنش ها"
            hide-default-footer
            :hide-default-header="hideHeader"
          >
            <template #item="{ item }">
              <tr class="littleHeight">
                <!-- 1 -->
                <td style="width: 30px" class="text-center px-0">
                  <img
                    v-if="item.type == 'deposit'"
                    width="16"
                    height="14"
                    style="margin: 10px 2px 0px 14px"
                    :src="require('@/assets/img/Ascending.svg')"
                  />
                  <img
                    v-if="item.type == 'withdraw'"
                    width="16"
                    height="14"
                    style="margin: 10px 2px 0px 14px"
                    :src="require('@/assets/img/Descending.svg')"
                  />
                </td>

                <!-- 2 -->
                <td
                  v-if="item.type == 'deposit'"
                  class="text-right"
                  style="width: 110px"
                >
                  افزایش موجودی
                </td>
                <td v-else class="text-right">کاهش موجودی</td>

                <!-- 3 -->
                <td class="text-right">
                  {{ jalaliDate(item.created_at * 1000) }}
                </td>

                <!-- 4 -->
                <td class="text-right">
                  {{ Math.floor(Math.abs(item.amount / 10)) | currency }}
                </td>

                <!-- 4 -->
                <td class="text-right">
                  {{ Math.floor(item.balance / 10) | currency }}
                </td>

                <!-- 5 -->
                <td class="text-right" style="padding-top: 9px !important">
                  <v-tooltip
                    v-if="item.comment != null"
                    top
                    content-class="tableTooltip"
                  >
                    <template #activator="{ on }">
                      <span class="tsvg" v-on="on">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <path fill="none" class="a" d="M20,0H0V20H20Z" />
                          <path
                            fill="#333"
                            class="b"
                            d="M3.6,2H16.4A1.6,1.6,0,0,1,18,3.6V18l-3.2-3.2H3.6A1.6,1.6,0,0,1,2,13.2V3.6A1.6,1.6,0,0,1,3.6,2Zm0,11.2H15.464l.936.936V3.6H3.6ZM14,7.6H12.4V9.2H14Zm-6.4,0H6V9.2H7.6Zm3.2,0H9.2V9.2h1.6Z"
                          />
                        </svg>
                      </span>
                    </template>
                    <span style="font-size: 12px">
                      {{ item.comment }}
                    </span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template #no-data>
              <v-layout
                wrap
                justify-center
                align-center
                style="
                  width: 100%;
                  text-align-last: center;
                  margin-top: -20px;
                  padding-bottom: 2px;
                "
              >
                <v-flex class="vandar-table-empty">
                  برای این مشتری تراکنشی وجود ندارد.
                </v-flex>
              </v-layout>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Transactions",
  data() {
    return {}
  },
  computed: {
    headers() {
      return [
        {
          text: " ",
          align: "right",
          sortable: false,
          value: "uuid",
        },
        {
          text: "عنوان",
          align: "right",
          sortable: false,
          value: "title",
        },
        {
          text: "زمان",
          align: "right",
          sortable: false,
          value: "created_at",
        },
        {
          text: "مبلغ (" + this.currencyName + ")",
          align: "right",
          sortable: false,
          value: "amount",
        },
        {
          text: "مانده (" + this.currencyName + ")",
          align: "right",
          sortable: false,
          value: "balance",
        },
        {
          text: " ",
          align: "right",
          sortable: false,
          value: "comment",
        },
      ]
    },
    hideHeader() {
      if (
        this.$store.state.customer.selectedCustomer.transactions.length == 0
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.customer_transaaction_table {
  text-align-last: right;

  :deep(thead) {
    tr {
      height: 40px;
      th {
        padding: 0 !important;
        border-bottom: 1px dashed $van_color06 !important;
      }
    }
  }

  tbody {
    tr {
      &.littleHeight {
        @media screen and (max-width: 600px) {
          height: unset;
        }
      }
      td {
        padding: 0 !important;
      }

      &:hover {
        box-shadow: none;
        cursor: default;
        td {
          padding: 0 !important;
        }
      }

      &:not(:last-child) {
        td {
          border-bottom: 1px dashed $van_color06 !important;
          border-bottom-style: dashed !important;
        }
      }
    }
  }

  .v-data-table__wrapper {
    position: relative;
    z-index: 0;
    overflow-x: visible;
    overflow-y: visible;
  }
}
</style>
