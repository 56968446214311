<template>
  <div class="mb-5">
    <!-- skeleton -->
    <div v-if="skeleton">
      <v-layout
        v-for="item in 5"
        :key="item"
        :align-center="!reuseIsMobile"
        fill-height
        :column="reuseIsMobile"
        class="table_row van_color13"
      >
        <v-flex
          class="font_2"
          :style="reuseIsMobile ? '' : 'max-width:105px;width:105px'"
        >
          <span style="width: 70%" class="default_gray" />
        </v-flex>
        <v-flex
          class="font_2 d-flex"
          :style="reuseIsMobile ? '' : 'max-width:140px'"
        >
          <span style="width: 70%" class="default_gray" />
        </v-flex>
        <v-flex
          class="font_2"
          :style="reuseIsMobile ? '' : 'text-align:center'"
        >
          <span style="width: 50%" class="default_gray" />
        </v-flex>
        <v-flex class="font_26" :style="reuseIsMobile ? '' : 'text-align:left'">
          <span style="width: 70%" class="default_gray" />
        </v-flex>
      </v-layout>
    </div>

    <v-data-table
      v-else
      :items="items"
      hide-default-header
      hide-default-footer
      no-data-text="تراکنشی برای نمایش وجود ندارد"
    >
      <template #item="{ item }">
        <v-layout
          :align-center="!reuseIsMobile"
          fill-height
          class="table_row"
          :column="reuseIsMobile"
        >
          <v-flex
            class="font_2"
            :style="reuseIsMobile ? '' : 'max-width:105px;width:105px'"
          >
            {{ (item.amount / 10) | currency }} {{ currencyName }}
          </v-flex>
          <v-flex
            class="font_2 d-flex text-truncate"
            :class="{ 'justify-center': !reuseIsMobile }"
          >
            {{ item.iban_owner_name ?? "استعلام نام..." }}
          </v-flex>
          <v-flex
            class="font_2 d-flex align-center"
            :class="{ 'mr-auto': !reuseIsMobile }"
            :style="reuseIsMobile ? '' : 'max-width:160px'"
          >
            {{ item.iban }}
            <v-img
              width="16"
              max-height="16"
              max-width="16"
              :class="{ 'align-self-center': reuseIsMobile }"
              class="mr-1"
              :src="
                require(`@/assets/img/banks/${parseInt(
                  item.iban.slice(4, 7)
                )}.svg`)
              "
            />
          </v-flex>
        </v-layout>
      </template>
    </v-data-table>
    <v-layout
      v-if="totalPage > 1"
      align-center
      fill-height
      style="height: 60px"
      class="van_color13"
    >
      <v-flex v-if="skeleton" class="font_14 mr-7" style="text-align: right">
        <span style="width: 20%" class="default_gray" />
      </v-flex>
      <v-flex v-else class="font_14 mr-7" style="text-align: right">
        {{ totalItems }} برداشت
      </v-flex>
      <v-flex style="text-align: left; padding-top: 15px; paddint-bottom: 15px">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
          color="#4c9f8733"
          class="text--van_color02"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "CanceledSettlements",
  mixins: [screenSize],
  props: {
    batchId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      page: 1,
      totalPage: 1,
      items: [],
      totalItems: 0,
      skeleton: true,
    }
  },
  watch: {
    page: function () {
      this.getCanceledSettlements()
    },
  },
  mounted() {
    this.getCanceledSettlements()
  },
  methods: {
    getCanceledSettlements() {
      this.$http
        .get(
          this.$store.getters.apiUrlBatch +
            "/batch-settlements/" +
            this.batchId +
            "?status=CANCELED&page=" +
            this.page,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.items = response.data.data
          this.totalPage = response.data.meta.last_page
          this.totalItems = response.data.meta.total
          this.skeleton = false
        })
        .catch((error) => {
          //callsnackbar
          this.skeleton = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.table_row {
  margin-top: -1px;
  padding: 22px 32px;
  border: 1px solid #f5f5f5;
}
.v-pagination__item {
  height: 30px !important;
  min-width: 30px !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  color: #666666 !important;
}
.v-pagination__item--active {
  color: #333333 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
</style>
