var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex flex-column align-center justify-center container"},[_c('div',{staticClass:"d-flex align-center w-100 progress-bar-wrapper"},[_c('div',{staticClass:"d-flex flex-column align-center position-relative"},[_c('span',{staticClass:"font-14-bo-green-22 mb-2 titles-general-styles first-block-style",class:{ 'stepper-title-font-size-mobile': _vm.isMobileMode }},[_vm._v(" اطلاعات پایه ")]),_c('span',{staticClass:"circle success-circle"})]),_c('v-divider',{attrs:{"color":"#4c9f87"}}),_c('div',{staticClass:"d-flex flex-column align-center position-relative"},[_c('span',{staticClass:"font-14-bo-green-22 mb-2 titles-general-styles second-block-style",class:{ 'stepper-title-font-size-mobile': _vm.isMobileMode }},[_vm._v(" اطلاعات مکانی ")]),_c('span',{staticClass:"circle success-circle"})]),_c('v-divider',{attrs:{"color":"#4c9f87"}}),_c('div',{staticClass:"d-flex flex-column align-center position-relative"},[_c('span',{staticClass:"font-14-bo-green-22 mb-2 titles-general-styles third-block-style",class:{ 'stepper-title-font-size-mobile': _vm.isMobileMode },style:(!_vm.isOnboardingThirdStepDone ? 'color:#000000' : '')},[_vm._v(" بارگذاری مدرک ")]),_c('span',{staticClass:"circle",class:{
          'success-circle': _vm.isOnboardingThirdStepDone,
          'void-circle': !_vm.isOnboardingThirdStepDone,
        }})]),_c('v-divider',{attrs:{"color":"#4c9f87"}}),_c('div',{staticClass:"d-flex flex-column align-center position-relative"},[_c('span',{staticClass:"font-14-bo-green-22 mb-2 titles-general-styles fourth-block-style",class:{ 'stepper-title-font-size-mobile': _vm.isMobileMode },style:(!_vm.isOnboardingFourthStepDone ? 'color:#000000' : '')},[_vm._v(" درگاه پرداخت ")]),_c('span',{staticClass:"circle",class:{
          'success-circle': _vm.isOnboardingFourthStepDone,
          'void-circle': !_vm.isOnboardingFourthStepDone,
        }})])],1),_c('div',{staticClass:"d-flex flex-column align-center mt-16"},[(_vm.isOnboardingThirdStepDone && _vm.isOnboardingFourthStepDone)?[_c('p',{staticClass:"font_12 title-font-size-mobile"},[_vm._v("همه مراحل انجام شد")]),_c('span',{staticClass:"font_36 subtitle-font-size-mobile"},[_vm._v(" پس از فعال‌ شدن کسب‌وکار، اطلاعات درگاه پرداخت بررسی می‌شوند. ")])]:_vm._e(),(_vm.isOnboardingThirdStepDone && !_vm.isOnboardingFourthStepDone)?[_c('p',{staticClass:"font_12 title-font-size-mobile"},[_vm._v(" مراحل ساخت کسب‌وکار تکمیل شد ")]),_c('span',{staticClass:"font_36 subtitle-font-size-mobile"},[_vm._v(" پس از فعال شدن کسب‌وکار می‌تونی درخواست فعال‌سازی ابزارهای وندار رو از جعبه ابزار ثبت کنی. ")])]:_vm._e(),(!_vm.isOnboardingThirdStepDone && _vm.isOnboardingFourthStepDone)?[_c('p',{staticClass:"font_12 title-font-size-mobile"},[_vm._v(" مراحل ساخت کسب‌وکار تکمیل نشده ")]),_c('span',{staticClass:"font_36 subtitle-font-size-mobile"},[_vm._v(" برای فعال شدن درگاه باید مدارک کسب‌وکار رو تکمیل کنی! پس از تایید کسب‌وکار درگاه پرداخت تایید می‌شه. ")])]:_vm._e(),(!_vm.isOnboardingThirdStepDone && !_vm.isOnboardingFourthStepDone)?[_c('p',{staticClass:"font_12 title-font-size-mobile"},[_vm._v(" مراحل ساخت کسب‌وکار تکمیل نشده ")]),_c('span',{staticClass:"font_36 subtitle-font-size-mobile"},[_vm._v(" برای فعال شدن کسب‌وکار باید مدارک رو بارگذاری کنی! ")])]:_vm._e(),_c('v-btn',{staticClass:"mt-4",attrs:{"outlined":"","width":"120","ripple":false,"height":"46"},on:{"click":_vm.goToBusiness}},[_vm._v(" متوجه شدم ")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }