<template>
  <div>
    <v-layout align-start column justify-center wrap>
      <h2 v-if="withTitle" class="mb-4 mr-0 ml-auto">جعبه ابزار کسب‌و‌کار</h2>

      <!-- filter row -->
      <v-layout
        v-if="$route.name === 'Businesstools'"
        align-center
        class="filter_row mb-0 tools_filter"
        justify-start
        wrap
      >
        <v-flex>
          <v-radio-group
            v-model="filter"
            :style="$vuetify.breakpoint.smAndDown ? 'width:100%' : ''"
            class="py-1 px-2"
            row
          >
            <v-radio label="همه" value="all" />
            <v-radio label="ابزارهای واریز پول" value="transactions" />
            <v-radio label="ابزارهای برداشت پول" value="settlement" />
            <v-radio label="ابزارهای مدیریتی" value="management" />
          </v-radio-group>
        </v-flex>
      </v-layout>
      <v-layout class="cards" justify-start wrap>
        <v-flex style="width: 100%">
          <div
            v-for="(toolItem, index) in toolsInCategory"
            :key="toolItem.name + index"
          >
            <card
              v-if="toolItem.show"
              ref="toolCard"
              :tool-status="toolStatus(toolItem.toolName) ?? ''"
              :whichtool="toolItem.toolName"
              @activeTool="openActiveTool(toolItem.toolName)"
              @cancelActivation="openCancelActivationDialog(toolItem.toolName)"
              @openIntroduce="clickedIntroduce(toolItem.toolName)"
              @suspendTool="openSuspendTool(toolItem.toolName)"
            />
          </div>
        </v-flex>
      </v-layout>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-if="modalStatus1"
      v-model="modalStatus1"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      transition="slide-x-transition"
    >
      <subscriptionSettingComponent
        v-if="subscriptionSetting"
        @closeModal="UpdateIndexAndclose_modal()"
      />
    </v-dialog>

    <!-- MPG step modal -->
    <v-dialog
      v-model="showMpgSteps"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '580'"
      no-click-animation
      transition="slide-x-transition"
    >
      <steps-mpg
        @close="
          () => {
            showMpgSteps = false
          }
        "
        @activate="
          () => {
            showMpgSteps = false
            showMpgMethods = true
          }
        "
      />
    </v-dialog>
    <!-- MPG select method modal -->
    <v-dialog
      v-model="showMpgMethods"
      :content-class="showMpgActivation ? 'vanmodal secondModal' : 'vanmodal'"
      no-click-animation
      transition="slide-x-transition"
      :persistent="true"
    >
      <selectMpgMethod
        @close="
          () => {
            showMpgMethods = false
          }
        "
        @submit="selectMPGMethod"
      />
    </v-dialog>

    <!-- MPG activation modal -->
    <v-dialog
      v-model="showMpgActivation"
      no-click-animation
      content-class="vanmodal"
      transition="slide-x-transition"
      :persistent="true"
    >
      <activation-mpg
        :loading="mpgLoading"
        @submit="activeMpg"
        @close="
          () => {
            showMpgActivation = false
          }
        "
      ></activation-mpg>
    </v-dialog>
    <confirm-mpg
      v-if="showMpgConfirm"
      @close="
        () => {
          showMpgConfirm = false
          showMpgActivation = false
          showMpgMethods = false
        }
      "
    ></confirm-mpg>
    <!-- banks management modal -->
    <v-dialog
      v-if="showBanksManagementModal"
      v-model="showBanksManagementModal"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="left-modal"
      no-click-animation
      transition="slide-x-transition"
    >
      <banks-management
        :activation-step="true"
        @closeModal="showBanksManagementModal = false"
        @active-tool="actuallyActiveTool(toolName)"
      />
    </v-dialog>

    <!-- introduce modal -->
    <v-dialog
      v-if="modalStatus"
      v-model="modalStatus"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :content-class="
        showBanksManagementModal ? 'vanmodal secondModal' : 'vanmodal'
      "
      no-click-animation
      transition="slide-x-transition"
    >
      <introduce
        v-if="introduce"
        :tool-status="toolStatus(clickedTool)"
        :whichtool="clickedTool"
        @activeTool="openActiveTool(clickedTool)"
        @closeModal="close_modal()"
        @suspendTool="openSuspendTool(clickedTool)"
      />
      <pbvSetting v-if="pbvSetting" @closeModal="UpdateIndexAndclose_modal" />
    </v-dialog>

    <!-- active modals -->
    <v-dialog
      v-if="activeStatus"
      v-model="activeStatus"
      content-class="confirmationMessage"
      no-click-animation
      persistent
      width="360"
    >
      <v-card height="100%" outlined>
        <v-layout align-center column justify-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/checkbox-marked-circle-outline.svg')"
              height="40"
              width="40"
            />
          </v-flex>
          <v-flex class="main-title font_3"> فعالسازی موفقیت‌آمیز بود </v-flex>
          <v-flex class="main-des font_4">
            {{ tool.text }}
          </v-flex>
          <v-flex>
            <v-btn
              :target="tool.linkUrl === 'api' ? '_blank' : '_self'"
              :to="
                tool.linkUrl === 'api'
                  ? '//vandar.io/docs/'
                  : $store.getters.urlParam + tool.linkUrl
              "
              class="btn_small_normal"
              dark
              text
            >
              {{ tool.rightLink }}
            </v-btn>
            <v-btn
              class="btn_small_normal"
              depressed
              outlined
              text
              @click="activeStatus = false"
            >
              {{ tool.leftLink }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- waiting modals -->
    <v-dialog
      v-if="waitingStatus"
      v-model="waitingStatus"
      content-class="confirmationMessage height226"
      no-click-animation
      persistent
      width="360"
    >
      <v-card height="100%" outlined>
        <v-layout align-center column justify-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/checkbox-marked-circle-outline.svg')"
              height="40"
              width="40"
            />
          </v-flex>
          <v-flex class="main-title font_3">
            در خواست شما ثبت شد و در حال بررسی است
          </v-flex>
          <v-flex class="main-des font_4">
            {{ tool.text }}
          </v-flex>
          <v-flex>
            <v-btn
              class="btn_small_normal"
              depressed
              outlined
              text
              @click="waitingStatus = false"
            >
              {{ tool.leftLink }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- suspend modals -->
    <general-dialog
      :active="suspendStatus"
      :title="suspendToolTile"
      btn1-class="btn_small_normal error"
      btn1-text="غیرفعال شود"
      btn2-class="btn_small_normal"
      btn2-text="منصرف شدم"
      content-class="confirmationMessageDialog"
      description="این ابزار از منو حذف شده و از کار می‌افتد ولی اطلاعات آن حذف نمی‌شود و در فعال‌سازی بعدی قابل استفاده است"
      logo="Error.svg"
      @btn1Clicked="suspendTool(clickedTool)"
      @btn2Clicked="close_modal()"
    />

    <!-- suspend MPG modal -->
    <general-dialog
      :active="showSuspendMpg"
      title="پرداخت یکپارچه غیرفعال شود؟"
      description="این ابزار از کار می‌افتد ولی اطلاعات آن حذف نمی‌شود. اگر قرارداد شما مهلت داشته‌باشد در فعال‌سازی بعدی از آن استفاده می‌شود."
      btn1-class="btn_small_normal error"
      btn1-text="غیرفعال شود"
      btn2-class="btn_small_normal"
      btn2-text="منصرف شدم"
      content-class="confirmationMessageDialog"
      logo="Error.svg"
      @btn1Clicked="suspendTool('MPG')"
      @btn2Clicked="
        () => {
          showSuspendMpg = false
        }
      "
    />

    <!-- modals -->

    <!-- tax code requirement modal for business-->
    <general-dialog
      :active="shaparakTerminalModal"
      btn1-text="بله دارم"
      btn2-class="btn_small_normal"
      btn2-text="انصراف"
      content-class="confirmationMessageDialog"
      description="به دستور بانک مرکزی، داشتن «اینماد» و «کد رهگیری مالیاتی» برای فعال‌سازی درگاه پرداخت الزامی‌ست."
      logo="newIcon/ipgDialogNew.svg"
      title="اینماد و کد رهگیری مالیاتی دارید؟"
      @btn1Clicked="actuallyActiveTool('ipg')"
      @btn2Clicked="close_modal()"
    />

    <!-- cancel activation dialog -->
    <general-dialog
      :active="cancelActivationDialog"
      :description="cancelActivationDescription"
      :title="cancelActivationTitle"
      btn1-class="btn_small_normal error"
      btn1-text="بله، منصرف شدم"
      btn2-class="btn_small_normal"
      btn2-text="خیر، ادامه می‌دهم"
      content-class="confirmationMessageDialog"
      logo="Error.svg"
      @btn1Clicked="suspendTool(clickedTool)"
      @btn2Clicked="close_modal()"
    />

    <!-- request money need has_shaparak_terminal-->
    <general-dialog
      v-if="requestMoneyRequiredIpg"
      :active="requestMoneyRequiredIpg"
      :btn1-text="ipgIsPendingOrFailed() ? null : 'فعالسازی درگاه پرداخت'"
      :btn2-text="ipgIsPendingOrFailed() ? 'متوجه شدم' : 'انصراف'"
      :description="ipgDialogDescription"
      btn2-class="btn_small_normal"
      content-class="confirmationMessageDialog"
      logo="newIcon/request_money.svg"
      title="درگاه پرداخت فعال دارید؟"
      @btn1Clicked="closeOneAndOpenAnother('ipg')"
      @btn2Clicked="requestMoneyRequiredIpg = false"
    />

    <!-- need to active requestMoney first-->
    <general-dialog
      :active="needToActiveRequestMoneyFirst"
      btn1-class="btn_small_normal"
      btn1-text="فهمیدم"
      content-class="confirmationMessageDialog"
      description="اطلاعات قبلی که برای شاپرک ارسال کردیم، نیاز به اصلاح دارد، ابتدا نسبت به اصلاح، اقدام کنید سپس درخواست فعال‌سازی درگاه را ثبت کنید"
      logo="Warning.svg"
      title="ابتدا اطلاعات ترمینال شاپرکی را اصلاح کنید"
      @btn1Clicked="close_modal()"
    />

    <!-- miando modal, after clicking activate btn-->
    <general-dialog
      :active="sendTosuportMiandoModal"
      btn2-class="btn_small_normal"
      btn2-text="متوجه شدم"
      content-class="confirmationMessageDialog"
      description="درخواست شما ثبت شد. کارشناسان پشتیبانی ما در اسرع وقت با شما تماس خواهند گرفت."
      logo="newIcon/info.svg"
      title="در انتظار تماس پشتیبانی"
      @btn2Clicked="close_modal()"
    />

    <!-- need to active IPG first -->
    <general-dialog
      :active="needToActiveIpgFirst"
      btn2-class="btn_small_normal"
      btn2-text="فهمیدم"
      content-class="confirmationMessageDialog"
      description="درخواست ثبت شد. اطلاعات قبلی که برای شاپرک ارسال کردیم، نیاز به اصلاح دارد، نسبت به اصلاح، اقدام کنید"
      logo="Warning.svg"
      title="ابتدا اطلاعات ترمینال شاپرکی را اصلاح کنید"
      @btn2Clicked="close_modal()"
    />

    <!-- tax_code open -->
    <v-dialog
      v-model="requireTaxCodeOrPostalCode"
      :content-class="
        $store.state.secondShebamodal ? 'secondModal vanmodal' : 'vanmodal'
      "
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      transition="slide-x-transition"
      @click:outside="close_modal2"
    >
      <tax-tracking-code v-if="requireTaxCode" @closeModal="close_modal2()" />
      <location-info v-if="requirePostalCode" @closeModal="close_modal2()" />
      <AddIpgModal v-if="openIpgSetting" @close-ipg-modal="close_modal2" />
    </v-dialog>

    <!-- shaparak has problem -->
    <general-dialog
      :active="shaparakHasProblem"
      btn2-class="btn_small_normal"
      btn2-text="فهمیدم"
      content-class="confirmationMessageDialog"
      custom-style="height:390px"
      description="متاسفانه به دلیل اختلال در عملکرد سامانه‌های شاپرک، در حال حاضر ثبت درگاه تازه امکان‌پذیر نیست. با سپاس از شکیبایی شما، رفع اختلال ایجاد شده از طریق کانال تلگرام وندار اطلاع رسانی خواهد شد"
      logo="Warning.svg"
      style="height: 100%"
      title="اختلال در عملکرد سامانه‌های شاپرک"
      @btn2Clicked="close_modal()"
    >
      <template #extra>
        <div
          class="font_2 mt-5"
          style="
            text-align: center;
            display: flex;
            align-items: center;
            height: 61px;
            width: 100%;
            background-color: #f5f5f5;
          "
        >
          <a
            class="linkBorder van_green--text mt-0 mx-auto"
            href="https://t.me/vandarpay"
            style="text-decoration: none"
            target="_blank"
            >عضویت در کانال تلگرام وندار</a
          >
        </div>
      </template>
    </general-dialog>
  </div>
</template>

<script>
import card from "../elements/card"
import StepsMpg from "../modals/StepsMPG.vue"
import introduce from "../modals/introduce"
import GeneralDialog from "@/components/modals/generalDialog"
import TaxTrackingCode from "@/components/modals/taxTrackingCode"
import LocationInfo from "@/components/modals/locationInfo"
import AddIpgModal from "@/components/ipg/modals/AddIpgModal"
import subscriptionSettingComponent from "@/components/subscription/modals/add"
import pbvSetting from "@/components/pbv/modals/setting"
import BanksManagement from "../../A2A/modals/banksManagement"
import ActivationMpg from "../modals/ActivationMPG.vue"
import selectMpgMethod from "../modals/SelectMpgMethod.vue"
import ConfirmMpg from "../modals/ConfirmMPG.vue"
import { mapMutations } from "vuex"

export default {
  name: "Index",
  components: {
    StepsMpg,
    BanksManagement,
    AddIpgModal,
    LocationInfo,
    TaxTrackingCode,
    GeneralDialog,
    card,
    introduce,
    subscriptionSettingComponent,
    pbvSetting,
    selectMpgMethod,
    ActivationMpg,
    ConfirmMpg,
  },
  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      banksUpdateFlag: false,
      showMpgSteps: false,
      showMpgMethods: false,
      showMpgActivation: false,
      showMpgConfirm: false,
      mpgLoading: false,
      showSuspendMpg: false,
      sendTosuportMiandoModal: false,
      showBanksManagementModal: false,
      filter: "all",
      shaparakHasProblem: false,
      introduce: false,
      modalStatus: false,
      modalStatus1: false,
      subscriptionSetting: false,
      pbvSetting: false,

      clickedTool: "",

      activeStatus: false,
      waitingStatus: false,
      suspendStatus: false,
      offerStatus: false,
      shaparakTerminalModal: false,
      requireTaxCode: false,
      requirePostalCode: false,
      requireTaxCodeOrPostalCode: false,
      openIpgSetting: false,
      cancelActivationDialog: false,
      needToActiveRequestMoneyFirst: false,
      needToActiveIpgFirst: false,
      dontOpenTaxCodeOrPostalCode: false,
      requestMoneyRequiredIpg: false,
    }
  },
  computed: {
    toolsInCategory: function () {
      if (this.filter === "all") return this.tools
      return this.tools.filter((item) => item.category.includes(this.filter))
    },
    tools() {
      return [
        {
          toolName: "MPG",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store?.state?.business?.tools["MPG"]?.viewable,
          name: "پرداخت یکپارچه",
          category: ["transactions"],
        },
        {
          toolName: "miando",
          text: "در انتظار تماس پشتیبانی",
          name: "معامله امن",
          linkUrl: "/",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store?.state?.business?.tools["miando"]?.viewable,
          category: ["transactions"],
        },
        // {
        //   toolName: "PBV",
        //   text: "درگاه پرداخت، برای شما فعال شد. در ادامه می‌توانید درگاه خود را بسازید",
        //   rightLink: "ساخت درگاه",
        //   leftLink: "بعدا می‌سازم",
        //   name: "پرداخت با وندار",
        //   linkUrl: "/ipg",
        //   show: true,
        //   category: ["transactions"],
        // },
        {
          toolName: "A2A",
          name: "تسویه آنی",
          linkUrl: "/",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store?.state?.business?.tools["A2A"]?.viewable,
          category: ["settlement"],
        },
        {
          toolName: "ipg",
          text: "درگاه پرداخت، برای شما فعال شد. در ادامه می‌توانید درگاه خود را بسازید",
          rightLink: "ساخت درگاه",
          leftLink: "بعدا می‌سازم",
          name: "درگاه پرداخت",
          linkUrl: "/ipg",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.ipg?.viewable,
          category: ["transactions"],
        },
        {
          toolName: "requestMoney",
          text: " فرم پرداخت برای شما فعال شد. در ادامه می‌توانید اولین فرم پرداخت خود را بسازید",
          rightLink: "ساخت فرم",
          leftLink: "بعدا می‌سازم",
          name: "فرم پرداخت",
          linkUrl: "/request_money",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.requestMoney?.viewable,
          category: ["transactions"],
        },
        {
          toolName: "subscription",
          text: "پرداخت خودکار برای شما فعال شد. در ادامه می‌توانید تنظیمات مربوطه را انجام داده و بستر پرداخت خودکار خود را بسازید",
          rightLink: "ساخت پرداخت خودکار",
          leftLink: "بعدا می‌سازم",
          name: "پرداخت خودکار",
          linkUrl: "/subscription",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.subscription?.viewable,
          category: ["transactions"],
        },
        {
          toolName: "p2p",
          text: "به زودی نتیجه را به شما اطلاع می دهیم.",
          leftLink: "منتظر می‌مانم",
          name: "انتقال وجه",
          linkUrl: "/p2p",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.p2p?.viewable,
          category: ["transactions", "settlement"],
        },
        {
          toolName: "refund",
          text: "به زودی نتیجه را به شما اطلاع می دهیم.",
          leftLink: "منتظر می‌مانم",
          name: "بازگشت وجه",
          linkUrl: "/refund",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.refund?.viewable,
          category: ["settlement"],
        },
        {
          toolName: "settlement_batch",
          text: "برداشت گروهی برای شما فعال شد.",
          leftLink: "بازگشت",
          name: "برداشت گروهی",
          linkUrl: "/settlement_batch",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.settlement_batch?.viewable,
          category: ["settlement"],
        },
        {
          toolName: "customer",
          text: "مشتریان برای شما فعال شد. مشترکین ابزار «پرداخت خودکار» به صورت خودکار افزوده می‌شوند و سایر کاربران توسط شما قابل افزودن هستند",
          rightLink: "مشاهده مشتریان",
          leftLink: "بازگشت",
          name: "مشتریان",
          linkUrl: "/customer",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.customer?.viewable,
          category: ["management"],
        },
        {
          toolName: "kerdar",
          text: "داده‌های آماری برای تحلیل وضعیت کسب‌وکارها",
          leftLink: "بازگشت",
          name: "کردار",
          linkUrl: "",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.kerdar?.viewable,
          category: ["management"],
        },
        {
          toolName: "log",
          text: "گزارش فراخوانی برای شما فعال شد. در صفحه مربوط به این ابزار، تمامی گزارش‌های فراخوانی، قابل مشاهده خواهند بود",
          rightLink: "مشاهده گزارش‌ها",
          leftLink: "بازگشت",
          name: "گزارش فراخوانی",
          linkUrl: "/log",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.log?.viewable,
          category: ["management"],
        },
        {
          toolName: "api",
          text: "وب‌سرویس برای شما فعال شد. در ادامه می‌توانید وب‌سرویس‌های مورد نظر خود را فعال کرده و با کمک مستندات، ازتباط موثری ایجاد کنید",
          rightLink: "مشاهده وب‌سرویس‌ها",
          leftLink: "بازگشت",
          name: "وب‌سرویس",
          //no link, no page
          linkUrl: "api",
          show: false,
          category: ["management"],
        },
        {
          toolName: "invoice",
          text: "فاکتورها برای شما فعال شد.",
          rightLink: "مشاهده فاکتورها",
          leftLink: "بازگشت",
          name: "فاکتورها",
          linkUrl: "/invoice",
          show: false,
          category: ["management"],
        },
        {
          toolName: "accountant",
          text: "گزارش‌های مالی برای شما فعال شد.",
          rightLink: "مشاهده گزارش‌های مالی",
          leftLink: "بازگشت",
          name: "گزارش‌های مالی",
          linkUrl: "/accountant",
          show:
            this.$store.state.business.hasOwnProperty("tools") &&
            this.$store.state.business.tools.accountant?.viewable,
          category: ["management"],
        },
      ]
    },
    ipgDialogDescription() {
      if (this.ipgIsPendingOrFailed()) {
        return "برای ثبت فرم پرداخت، ابتدا باید درگاه پرداخت شما فعال شود. درخواست فعال‌سازی در حال بررسی است."
      } else {
        return "برای ثبت فرم پرداخت، ابتدا باید درگاه پرداخت خود را فعال کنید. به دستور بانک مرکزی، داشتن «اینماد» و «کد رهگیری مالیاتی» برای فعال‌سازی درگاه پرداخت الزامی‌ست."
      }
    },
    tool: function () {
      return this.tools.find((item) => item.toolName === this.clickedTool)
    },
    cancelActivationTitle() {
      if (this.clickedTool !== "") {
        return (
          "از فعال‌سازی «" +
          this.tools.find((item) => item.toolName === this.clickedTool).name +
          "» منصرف شدید؟"
        )
      }
    },
    cancelActivationDescription() {
      if (this.clickedTool !== "") {
        return (
          "هر موقع بخواهید می‌توانید دوباره از طریق جعبه ابزار، " +
          this.tools.find((item) => item.toolName === this.clickedTool).name +
          " را فعال کنید"
        )
      }
    },
    suspendToolTile() {
      if (this.tool && this.tool.hasOwnProperty("name"))
        return "«" + this.tool.name + "»" + " تعلیق شود؟"
    },
    pendingStatus() {
      return (
        this.$store.state.business.tax_code != null &&
        this.$store.state.business.tax_code_detail.tax_code_status ===
          "PENDING" &&
        this.$store.state.business.postal_code_detail.postal_code_status ===
          "PENDING"
      )
    },
    kerdarIsActive() {
      return this.$store.state.business.tools?.kerdar.status === "ACTIVE"
    },
  },
  mounted() {
    if (!this.kerdarIsActive) {
      this.filter = "management"
    }
  },
  methods: {
    ...mapMutations(["changeIpgModalOpeningState"]),
    activeMpg(body) {
      this.mpgLoading = true
      this.$http
        .post(
          this.$store.getters.urlMPG + "/active-tool",
          {
            ...body,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then(() => {
          this.mpgLoading = false
          this.showMpgConfirm = true
          this.$store.commit("changeMpgStatus", "ACTIVE")
        })
        .catch(() => {
          this.mpgLoading = false
        })
    },
    selectMPGMethod() {
      this.showMpgActivation = true
    },
    ipgIsPendingOrFailed() {
      return (
        this.$store.state.business.tools.ipg?.status === "PENDING" ||
        this.$store.state.business.tools.ipg?.status === "FAILED"
      )
    },

    UpdateIndexAndclose_modal() {
      this.resetTools()
      this.close_modal()
    },
    toolStatus(toolName) {
      if (
        this.$store.state.business.hasOwnProperty("tools") &&
        toolName &&
        toolName !== ""
      ) {
        switch (toolName) {
          case "customer":
            return this.$store.state.business.tools.customer.status ===
              "ACTIVE" ||
              this.$store.state.business.tools.subscription.status === "ACTIVE"
              ? "ACTIVE"
              : "INACTIVE"
          case "PBV":
            if (this.$store.state.business.status) {
              if (
                this.$store.state[this.$store.getters.userOrBuisenss].tools[
                  toolName
                ].status === "ACTIVE"
              ) {
                return "ACTIVE"
              }
              return this.$store.state[this.$store.getters.userOrBuisenss]
                .tools[toolName].status
            } else {
              if (
                this.$store.state[this.$store.getters.userOrBuisenss].tools[
                  toolName
                ].status === "ACTIVE"
              ) {
                return "PENDING"
              }
              return this.$store.state[this.$store.getters.userOrBuisenss]
                .tools[toolName].status
            }

          default:
            return this.$store.state[this.$store.getters.userOrBuisenss].tools[
              toolName
            ].status
        }
      } else return "INACTIVE"
    },
    openCancelActivationDialog(tool) {
      this.close_modal()
      this.clickedTool = tool
      this.cancelActivationDialog = true
    },
    handleBtnLoading(tool, status) {
      this.$refs.toolCard
        .find((t) => t._props.whichtool == tool)
        .handleLoading(status)
    },
    openActiveTool(tool) {
      if (tool === "MPG") {
        if (this.$store.state.business.tools[tool].is_created) {
          this.actuallyActiveTool("MPG")
          return
        }
        this.close_modal()
        this.showMpgSteps = true
        return
      }
      if (tool === "A2A") {
        this.showBanksManagementModal = true
        return
      }

      this.close_modal()
      if (tool === "subscription") {
        this.clickedSubscription(tool)
        return
      }
      if (tool === "PBV") {
        this.clickedPbv(tool)
        return
      }
      if (
        tool === "requestMoney" &&
        !this.$store.state.business.has_shaparak_terminal
      ) {
        this.requestMoneyRequiredIpg = true
        return
      }
      if (tool === "ipg" && !this.$store.state.business.has_shaparak_terminal) {
        if (this.$store.state.business.tools.ipg?.status === "FAILED") {
          this.actuallyActiveTool(tool)
          this.dontOpenTaxCodeOrPostalCode = true
          this.needToActiveIpgFirst = true
          return
        }

        this.shaparakTerminalModal = true
      } else {
        this.actuallyActiveTool(tool)
      }
    },
    setAllBanksToDeActive(tool) {
      //set is_active property of all banks to false before deactivating tool
      this.$http
        .post(
          this.$store.getters.apiWithVersionParam("3") +
            "/settlement/a2a/bank/status",
          [],
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.banksUpdateFlag = true
          this.suspendTool(tool)
        })
        .catch((error) => {
          //callsnackbar
          this.$store.commit("callsnackbar", "لطفا دوباره تلاش کنید")
        })
    },
    actuallyActiveTool(tool) {
      this.close_modal()
      this.handleBtnLoading(tool, true)

      if (tool === "ipg" && !this.$store.state.business.has_shaparak_terminal) {
        this.requireTaxCodeOrPostalCode = true
        this.openIpgSetting = true
        return
      }
      var data = {
        tools: tool,
        status: "PENDING",
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/tools/update", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.handleBtnLoading(tool, false)

          this.clickedTool = tool
          this.$store.commit("changeToolsStatus", {
            name: tool,
            status: response.data.data[tool],
          })
          this.introduce = false

          if (tool === "miando") this.sendTosuportMiandoModal = true

          let message
          if (tool === "customer" || tool === "p2p" || tool === "refund") {
            message =
              "ابزار‌ «" +
              this.tools.find((item) => item.toolName === tool).name +
              "» فعال شد"
          } else {
            if (
              tool === "ipg" &&
              response.data.message === "اطلاعات درگاه خود را وارد کنید"
            ) {
              //ToDO: fix in tools deviation
              this.requireTaxCodeOrPostalCode = true
              this.openIpgSetting = true
              return
            }
            message =
              "درخواست فعال‌سازی «" +
              this.tools.find((item) => item.toolName === tool).name +
              "» ثبت شد"
          }
          this.$store.commit("callSuccessSnackbar", message)

          //update index page
          this.resetTools()
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    resetTools() {
      this.$store.commit("eraseToolsObject")
      this.$store.dispatch(
        "retrieveBusinessObject",
        this.$store.state.business.business_name
      )
    },
    close_modal() {
      this.sendTosuportMiandoModal = false
      this.introduce = false
      this.subscriptionSetting = false
      this.pbvSetting = false
      this.modalStatus = false
      this.modalStatus1 = false
      this.activeStatus = false
      this.suspendStatus = false
      this.waitingStatus = false
      this.clickedTool = ""
      this.requireTaxCode = false
      this.requirePostalCode = false
      this.requireTaxCodeOrPostalCode = false
      this.shaparakTerminalModal = false
      this.openIpgSetting = false
      this.cancelActivationDialog = false
      this.needToActiveRequestMoneyFirst = false
      this.needToActiveIpgFirst = false
      this.dontOpenTaxCodeOrPostalCode = false
      this.shaparakHasProblem = false
      this.requestMoneyRequiredIpg = false
      this.showBanksManagementModal = false
    },
    close_modal2() {
      this.requireTaxCode = false
      this.requirePostalCode = false
      this.requireTaxCodeOrPostalCode = false
      this.openIpgSetting = false
      this.changeIpgModalOpeningState(false)
    },
    closeOneAndOpenAnother(tool) {
      this.close_modal()
      this.openActiveTool(tool)
    },
    suspendTool(tool) {
      if (tool === "A2A") {
        if (!this.banksUpdateFlag) {
          this.setAllBanksToDeActive(tool)
          this.close_modal()
          return
        } else {
          this.resetTools()
          this.banksUpdateFlag = false
        }
      }

      var data = {
        tools: tool,
        status: "INACTIVE",
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/tools/update", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (tool === "MPG") {
            this.showSuspendMpg = false
          }
          this.clickedTool = tool
          this.$store.commit("changeToolsStatus", {
            name: tool,
            status: response.data.data[tool],
          })
          let message =
            " ابزار «" +
            this.tools.find((item) => item.toolName === tool).name +
            "» غیرفعال شد"

          this.$store.commit("callSuccessSnackbar", message)
          this.close_modal()
        })
        .catch(() => {
          //callsnackbar
        })
    },
    openSuspendTool(tool) {
      this.clickedTool = tool
      if (tool === "MPG") {
        this.showSuspendMpg = true
      } else this.suspendStatus = true
    },

    clickedIntroduce(tool) {
      this.close_modal()
      this.clickedTool = tool
      this.modalStatus = true
      this.introduce = true
    },
    clickedSubscription(tool) {
      if (this.$store.state.business.tools.subscription.is_created)
        this.actuallyActiveTool(tool)
      else {
        this.close_modal()
        this.modalStatus1 = true
        this.subscriptionSetting = true
      }
    },
    clickedPbv(tool) {
      if (this.$store.state.business.tools.PBV.is_created)
        this.actuallyActiveTool(tool)
      else {
        this.close_modal()
        this.modalStatus = true
        this.pbvSetting = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cards {
  margin-bottom: 30px;
  width: 100%;

  > .flex {
    align-self: flex-end;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .layout {
    justify-content: center;
  }
}

.shaparak_gray_box {
  background-color: #f5f5f5;
  width: 100%;
  text-align: center;
  padding: 22px 10px;
  margin-top: 20px;

  a {
    color: #4c9f87;
  }
}

.cash_in_success_color {
  background-color: rgba(#4c9f87, 0.1);
}

.tools_filter {
  justify-content: flex-start !important;
  min-width: 100%;
}
</style>
