<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.30859 13H11.3086V15H9.30859V13ZM9.30859 5H11.3086V11H9.30859V5ZM10.2986 0C4.77859 0 0.308594 4.48 0.308594 10C0.308594 15.52 4.77859 20 10.2986 20C15.8286 20 20.3086 15.52 20.3086 10C20.3086 4.48 15.8286 0 10.2986 0ZM10.3086 18C5.88859 18 2.30859 14.42 2.30859 10C2.30859 5.58 5.88859 2 10.3086 2C14.7286 2 18.3086 5.58 18.3086 10C18.3086 14.42 14.7286 18 10.3086 18Z"
      :fill="fillColor"
    />
  </svg>
</template>
<script>
export default {
  name: "FailedIcon",
  props: {
    fillColor: {
      type: String,
      default: "#666666",
    },
  },
}
</script>
