<template>
  <left-modal
    ref="leftModal"
    :toolbar-name="
      $vuetify.breakpoint.mdAndUp ? 'مدیریت پرداخت با یک کلیک' : 'مدیریت'
    "
    @closeModal="$emit('closeModal', 'mandateDetails')"
  >
    <div slot="toolbarItems">
      <v-btn
        :disabled="!valid || loading"
        :loading="loading"
        class="btn_small_normal"
        dark
        text
        @click="goToBank()"
      >
        اتصال به بانک
      </v-btn>
    </div>

    <general-card>
      <div class="font_2">
        این تنظیمات تا
        {{ $store.getters.getPbvPersianMandateExpirationDuration }}
        آینده معتبر است و هر زمان که بخواهید می‌توانید زودتر از موعد اتصال به
        بانک را لغو کنید.
      </div>
    </general-card>

    <general-card>
      <div class="main-box d-flex flex-column align-center mb-6">
        <div class="font_5 bold align-self-start" />
        <v-card class="w-100 radius-4 px-6 py-4" color="#F5F5F5" flat>
          <div class="top-box">
            <div class="d-flex align-center justify-start">
              <div class="d-flex imageBox pa-1 ml-2">
                <v-img
                  :lazy-src="bankInfo.logo"
                  :src="bankInfo.logo"
                  contain
                  eager
                  height="32"
                  width="32"
                />
              </div>
              <span class="bank-title">{{ bankInfo.name }}</span>
            </div>
          </div>
        </v-card>
      </div>

      <v-form ref="form" v-model="valid">
        <div class="pb-8 mb-8" style="border-bottom: 1px dashed #d5d5d5">
          <div class="font_2 mb-5">
            پرداخت با یک کلیک تا چند بار در ماه قابل انجام باشد؟
          </div>

          <div style="max-width: 220px">
            <v-select
              ref="count"
              v-model="count"
              :items="countList"
              :menu-props="{ contentClass: 'withActiveStyle' }"
              flat
              height="40"
              item-text="value"
              item-value="key"
              outlined
              solo
            >
              <template #item="data">
                <v-layout align-center justify-center>
                  <v-flex class="font_2">
                    {{ data.item.value }}
                  </v-flex>
                  <v-flex class="checkIcon">
                    <v-img
                      :src="require('@/assets/img/newIcon/simple-check.svg')"
                      alt="Vandar"
                      class="icons"
                      contain
                      height="16"
                      width="16"
                    />
                  </v-flex>
                </v-layout>
              </template>
            </v-select>
          </div>
        </div>
        <div>
          <div class="font_2 mb-5">
            پرداخت با یک کلیک تا چه مبلغی در هر پرداخت مجاز باشد؟
          </div>
          <div class="withlabel">
            <v-text-field
              ref="amount_ref"
              v-model="thousandSeparator"
              :rules="amountRules"
              :suffix="currencyName"
              flat
              height="40"
              outlined
              placeholder="مبلغ را وارد کنید"
              solo
              @keydown="persianKeyHandler($event)"
            />
            <transition>
              <div
                v-show="$refs.amount_ref && $refs.amount_ref.isFocused"
                class="align-center mmt-1"
              >
                <v-icon class="font_14"> info_outline </v-icon>
                <span class="font_8">
                  حداکثر مبلغ {{ +bankInfo.max | currency }}
                  {{ currencyName }} است. </span
                ><br />
                <v-icon class="font_14"> info_outline </v-icon>
                <span class="font_8">
                  حداقل مبلغ {{ +bankInfo.min | currency }}
                  {{ currencyName }} است
                </span>
              </div>
            </transition>
          </div>
        </div>
      </v-form>
    </general-card>
  </left-modal>
</template>

<script>
import leftModal from "@/components/modals/leftModal"
import generalCard from "@/components/elements/generalCard.vue"

export default {
  name: "MandateDetails",
  components: { leftModal, generalCard },
  props: {
    bankInfo: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      count: 30,
      valid: false,
      loading: false,
      amount: +this.bankInfo.default,

      amountRules: [
        (v) =>
          !(!this.amount || this.amount < +this.bankInfo.min) ||
          ` حداقل هر پرداخت با حساب
          ${this.bankInfo.name}
           ${this.currencyFunction(+this.bankInfo.min)}
            ${this.currencyName}
             است `,
        (v) =>
          !(
            !this.amount ||
            (this.amount > +this.bankInfo.min &&
              this.convertToToman(this.amount) > +this.bankInfo.max)
          ) ||
          `  حداکثر هر پرداخت با حساب ${
            this.bankInfo.name
          } ${this.currencyFunction(+this.bankInfo.max)} ${
            this.currencyName
          } است. `,
      ],
    }
  },
  computed: {
    thousandSeparator: {
      get: function () {
        return this.formatAsCurrency(this.amount, 0)
      },
      set: function (newValue) {
        if (newValue) {
          this.amount = Number(
            this.convertNumbers2English(newValue).replace(/[^0-9.]/g, "")
          )
        } else {
          this.amount = null
        }
      },
    },
    countList() {
      const list = [...this.$store.getters.getPbvMandateCountList]
      const title = []
      list.forEach((item) => {
        if (item.is_default) this.count = item.count
        title.push({
          key: item.count,
          value: item.count + " بار در ماه",
        })
      })
      return title
    },
  },
  watch: {
    bankInfo: function () {
      this.amount = null
      this.$refs.amount_ref.focus()
    },
  },
  mounted() {
    if (!this.$store.state.user.currency === "RIAL")
      this.amount = this.amount / 10
    this.$refs.amount_ref.blur()
  },
  methods: {
    formatAsCurrency(value, dec) {
      dec = dec || 0
      if (value === null) {
        return null
      }
      return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    },
    goToBank() {
      this.loading = true
      //change
      this.$http
        .post(
          this.$store.state.api_pbv_url + "/user/mandates",

          {
            bank_code: this.bankInfo.code,
            limit: this.convertToRial(this.amount),
            count: this.count,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          window.location = response?.data?.data?.redirect_url
          this.loading = false
        })
        .catch((error) => {
          // call snackbar
        })
    },
    convertToRial(value) {
      if (this.currencyName === "تومان") {
        value = value * 10
        return value
      } else return value
    },
    convertToToman(value) {
      if (this.currencyName !== "تومان") {
        value = Math.floor(value / 10)
        return value
      } else return value
    },
    currencyFunction(value) {
      if (value === null || !value) {
        return 0
      }
      if (this.$store.state.user.currency === "RIAL") value = value * 10
      return "" + value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    },
  },
}
</script>

<style lang="scss" scoped>
.border_eee {
  border: 1px solid;
  border-color: $van_color10 !important;
}

.border_d5 {
  border-color: #d5d5d5 !important;
}

.top-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  width: 100%;

  > div {
    width: 100%;
  }

  /* Background/3 */

  border-radius: 4px;
}

.bank-title {
  color: #333;
  font-size: 14px;
  font-weight: 700;
}

.imageBox {
  max-width: 56px;
  min-width: 56px;
  max-height: 56px;
  min-height: 56px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
</style>
