<template>
  <div class="mb-10">
    <h2>تنظیمات مدیریت حساب</h2>
    <v-layout wrap class="setting_box">
      <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
        <v-card @click.native="open_notification()">
          <v-layout align-center>
            <v-flex style="max-width: 56px; margin-left: 10px">
              <v-avatar color="#E7EFED" size="56">
                <v-icon dark size="24" color="van_green">
                  notifications_none
                </v-icon>
              </v-avatar>
            </v-flex>
            <v-flex>
              <div class="font_9">اعلان‌ها</div>
              <div class="font_8">
                تنظیمات اعلان‌های سیستمی، پیامک، ایمیل و بات تلگرام
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <h2>تنظیمات هویتی</h2>
    <v-layout wrap class="setting_box">
      <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
        <v-card @click.native="open_info()">
          <v-layout align-center>
            <v-flex style="max-width: 56px; margin-left: 10px">
              <v-avatar color="#E7EFED" size="56">
                <!-- <v-icon dark size="24" color="van_green">credit_card</v-icon> -->
                <svg
                  style="fill: #4c9f87"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="18"
                  viewBox="0 0 24 18"
                >
                  <defs />
                  <path
                    class="a"
                    d="M22,3H2A2.074,2.074,0,0,0,0,5V19a2.074,2.074,0,0,0,2,2H22a2.074,2.074,0,0,0,2-2V5a2.074,2.074,0,0,0-2-2m0,16H2V5H22V19m-8-2V15.75c0-1.66-3.34-2.5-5-2.5s-5,.84-5,2.5V17H14M9,7a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,9,7m5,0V8h6V7H14m0,2v1h6V9H14m0,2v1h4V11H14"
                    transform="translate(0 -3)"
                  />
                </svg>
              </v-avatar>
            </v-flex>
            <v-flex>
              <div class="font_9">اطلاعات هویتی</div>
              <div class="font_8">مشاهده و ویرایش اطلاعات پایه حساب</div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
        <v-card @click.native="open_location()">
          <v-layout align-center>
            <v-flex style="max-width: 56px; margin-left: 10px">
              <v-avatar color="#E7EFED" size="56">
                <v-img
                  :src="require('@/assets/img/newIcon/location.svg')"
                  max-width="16"
                  height="22"
                  alt="Vandar"
                />
              </v-avatar>
            </v-flex>
            <v-flex>
              <div class="font_9">اطلاعات مکانی</div>
              <div class="font_8">مشاهده و ویرایش محل سکونت</div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
        <v-card @click.native="open_photo()">
          <v-layout align-center>
            <v-flex style="max-width: 56px; margin-left: 10px">
              <v-avatar color="#E7EFED" size="56">
                <v-icon dark size="24" color="van_green">
                  crop_original
                </v-icon>
              </v-avatar>
            </v-flex>
            <v-flex>
              <div class="font_9">تصویر حساب شخصی</div>
              <div class="font_8">تغییر تصویر حساب شخصی</div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
        <v-card @click.native="open_vandarId()">
          <v-layout align-center>
            <v-flex style="max-width: 56px; margin-left: 10px">
              <v-avatar color="#E7EFED" size="56">
                <svg
                  style="fill: #4c9f87"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="17.999"
                  viewBox="0 0 24 17.999"
                >
                  <path
                    class="a"
                    d="M8534,5245a2.072,2.072,0,0,1-2-2v-14a2.074,2.074,0,0,1,2-2h20a2.074,2.074,0,0,1,2,2v14a2.072,2.072,0,0,1-2,2Zm0-2h20v-14h-20Zm12.484-2.259a.258.258,0,0,1-.238-.159.254.254,0,0,1,.056-.279l1.7-1.692a.256.256,0,0,0-.182-.438h-2.384a.431.431,0,0,1-.43-.429v-5.149a.431.431,0,0,1,.43-.432h5.151a.43.43,0,0,1,.43.432v6.077a.868.868,0,0,1-.252.606l-1.215,1.213a.858.858,0,0,1-.605.251ZM8539,5238v-1h4v1Zm-2-2v-1h6v1Zm0-2v-1h6v1Z"
                    transform="translate(-8532 -5227)"
                  />
                </svg>
              </v-avatar>
            </v-flex>
            <v-flex>
              <div class="font_9">شناسه وندار</div>
              <div class="font_8">
                با این شناسه دیگران می‌توانند انتقال وجه به شما انجام دهند‌
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <h2>تنظیمات برداشت</h2>
    <v-layout wrap class="setting_box">
      <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
        <v-card @click.native="open_financial()">
          <v-layout align-center>
            <v-flex style="max-width: 56px; margin-left: 10px">
              <v-avatar color="#E7EFED" size="56">
                <v-icon dark size="24" color="van_green"> tune </v-icon>
              </v-avatar>
            </v-flex>
            <v-flex>
              <div class="font_9">شباهای من</div>
              <div class="font_8">مشاهده، افزودن و ویرایش شماره شبا</div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
        <v-card @click.native="openTipOrNot()">
          <v-layout align-center>
            <v-flex style="max-width: 56px; margin-left: 10px">
              <v-avatar color="#E7EFED" size="56">
                <v-icon dark size="24" color="van_green"> event </v-icon>
              </v-avatar>
            </v-flex>
            <v-flex>
              <div class="font_9">برداشت خودکار</div>
              <div class="font_8">
                زمانبندی واریز روزانه، هفتگی و ماهیانه موجودی کیف پول به حساب
                بانکی
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status"
      :content-class="
        $store.state.secondShebamodal || $store.state.secondAutoSettlementModal
          ? 'secondModal vanmodal'
          : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <Financial v-show="financial" @closeModal="close_modal" />
      <Info v-show="info" :key="componentKey" @closeModal="close_modal" />
      <Notification v-show="notification" @closeModal="close_modal" />
      <Photo v-show="photo" :key="componentKey" @closeModal="close_modal" />
      <automaticSettlement
        v-show="automaticSettlement"
        :key="componentKey"
        @closeModal="close_modal"
      />
      <VandarId v-show="vandarId" @closeModal="close_modal" />
      <Location
        v-show="location"
        :key="componentKey"
        setting
        @closeModal="close_modal"
      />
    </v-dialog>

    <v-dialog v-model="automaticSettlementIntro" width="360">
      <v-card height="100%" class="pa-5">
        <v-layout column justify-center align-start>
          <v-flex>
            <v-layout align-center class="mb-5">
              <v-flex>
                <v-icon size="24" color="van_color02"> error_outline </v-icon>
              </v-flex>
              <v-flex class="font_5 bold mr-2">
                برداشت خودکار چگونه کار می‌کند؟
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="main-des font_2">
            با برداشت خودکار، موجودی قابل برداشت کیف پول شما در زمانی که تعیین
            می‌کنید، به حساب بانکی‌تان واریز می‌شود:
          </v-flex>
          <v-flex class="rowsWithDivider" style="width: 100%">
            <v-layout justify-space-between>
              <v-flex>
                <v-layout>
                  <v-flex style="max-width: 45px">
                    <v-img
                      :src="require('@/assets/img/gingool.svg')"
                      width="34"
                      height="23"
                    />
                  </v-flex>
                  <v-flex>واریز روزانه</v-flex>
                </v-layout>
              </v-flex>
              <v-flex class="font_2" style="text-align: left">
                شنبه تا پنج‌شنبه ساعت ۱۴
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-layout>
                  <v-flex style="max-width: 45px">
                    <v-img
                      :src="require('@/assets/img/gingool.svg')"
                      width="34"
                      height="23"
                    />
                  </v-flex>
                  <v-flex>واریز هفتگی</v-flex>
                </v-layout>
              </v-flex>
              <v-flex class="font_2" style="text-align: left">
                شنبه هر هفته ساعت ۱۴
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-layout>
                  <v-flex style="max-width: 45px">
                    <v-img
                      :src="require('@/assets/img/gingool.svg')"
                      width="34"
                      height="23"
                    />
                  </v-flex>
                  <v-flex>واریز ماهیانه</v-flex>
                </v-layout>
              </v-flex>
              <v-flex class="font_2" style="text-align: left">
                یکم هر ماه ساعت ۱۴
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="grayBox" style="width: 100%">
            واریز به حساب‌های بانک آینده ساعت ۱۲ انجام می‌شود
          </v-flex>
          <v-flex class="grayBox">
            فعال بودن برداشت خودکار از ثبت برداشت عادی جلوگیری نمی‌کند
          </v-flex>
          <v-flex class="ma-auto">
            <v-btn
              dark
              text
              class="btn_small_normal mt-2"
              @click="open_automaticSettlement()"
            >
              تنظیمات برداشت خودکار
            </v-btn>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal mt-2 mr-0"
              @click="automaticSettlementIntro = false"
            >
              بازگشت
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Financial from "../modals/settingFinancial.vue"
import Info from "../modals/settingInfo.vue"
import Notification from "../modals/settingNotification.vue"
import Photo from "../modals/settingPhoto.vue"
import automaticSettlement from "../modals/automaticSettlement.vue"
import VandarId from "../modals/addVandarId"
import Location from "../modals/locationInfo"

export default {
  name: "Setting",
  components: {
    Financial,
    Info,
    Notification,
    Photo,
    automaticSettlement,
    VandarId,
    Location,
  },
  data() {
    return {
      componentKey: 0,
      //this is modal status
      status: false,
      financial: false,
      info: false,
      notification: false,
      photo: false,
      vandarId: false,
      location: false,
      automaticSettlement: false,
      automaticSettlementIntro: false,
    }
  },
  methods: {
    close_modal(obj) {
      this.financial = false
      this.info = false
      this.notification = false
      this.photo = false
      this.location = false
      this.status = false
      this.automaticSettlement = false
      this.vandarId = false
      if (obj && obj.status) this.componentKey = !this.componentKey
    },
    openTipOrNot() {
      if (!this.$store.state.user.automatic_settlement_tip) {
        this.automaticSettlementIntro = true
      } else {
        this.open_automaticSettlement()
      }
    },
    open_automaticSettlement() {
      this.close_modal()
      this.automaticSettlementIntro = false
      this.automaticSettlement = true
      this.status = true
    },
    open_financial() {
      this.close_modal()
      this.financial = true
      this.status = true
    },
    open_info() {
      this.close_modal()
      this.info = true
      this.status = true
    },
    open_notification() {
      this.close_modal()
      this.notification = true
      this.status = true
    },
    open_photo() {
      this.close_modal()
      this.photo = true
      this.status = true
    },
    open_vandarId() {
      this.close_modal()
      this.vandarId = true
      this.status = true
    },
    open_location() {
      this.close_modal()
      this.location = true
      this.status = true
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin: 40px 0 30px 0;
}
.font_9 {
  margin: 3px 0;
}
.setting_box {
  margin-left: 0px;
  margin-right: 0px;
  .flex {
    padding: 5px;
    .v-card {
      border-radius: 4px;
      border: 1px solid $van_color11;
      box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.05);
      padding: 15px 23px;
      cursor: pointer;

      .v-avatar {
        .v-icon {
          display: flex;
          justify-content: center;
          height: unset;
        }

        svg {
          width: 24px;
        }
      }
    }
  }
}
</style>
