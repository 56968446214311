<template>
  <v-flex
    class="info_panel roleInfo"
    style="margin-top: -20px; padding: 7px 8px !important"
  >
    <v-expansion-panels flat>
      <v-expansion-panel v-model="panel" expand>
        <v-expansion-panel-header class="pa-0">
          <div class="font_2 mb-0">
            <v-icon size="16" color="van_color02"> error_outline </v-icon>هر نقش
            چه سطح دسترسی دارد؟
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-card flat>
            <v-card-text>
              <v-data-table
                v-if="!reuseIsMobile"
                :headers="headers"
                :items="tableItems"
                :items-per-page="30"
                hide-default-footer
                class="small_description role_info"
              >
                <template #item="{ item }">
                  <tr>
                    <td
                      v-for="role of Object.keys(item)"
                      :key="role"
                      class="text-center info_table_cell"
                    >
                      <span v-if="role === 'title'">{{ item[role] }}</span>
                      <v-icon
                        v-else-if="item[role]"
                        color="van_green"
                        size="16"
                      >
                        check
                      </v-icon>
                      <v-icon v-else color="van_color07" size="16">
                        close
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <table v-else class="mobile-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ headers[1].text }}</th>
                    <th>{{ headers[2].text }}</th>
                    <th>{{ headers[3].text }}</th>
                    <th>{{ headers[4].text }}</th>
                    <th>
                      {{ headers[5].text }}
                    </th>
                    <th>{{ headers[6].text }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item of tableItems" :key="item.display_name">
                    <td
                      v-for="role of Object.keys(item)"
                      :key="role"
                      class="text-center info_table_cell"
                    >
                      <span v-if="role === 'title'">{{ item[role] }}</span>
                      <v-icon
                        v-else-if="item[role]"
                        color="van_green"
                        size="16"
                      >
                        check
                      </v-icon>
                      <v-icon v-else color="van_color07" size="16">
                        close
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-flex>
</template>

<script>
import servicesRepository from "@/services/servicesRepository"
import screenSize from "@/mixins/screenSize"

export default {
  name: "RoleInfo",
  mixins: [screenSize],
  data() {
    return {
      panel: [true],
      tableItems: [],
      headers: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "title",
        },
        {
          text: "مالک",
          align: "center",
          sortable: false,
          value: "owner",
        },
        {
          text: "مدیر",
          align: "center",
          sortable: false,
          value: "manager",
        },
        {
          text: "حسابدار",
          align: "center",
          sortable: false,
          value: "accountant",
        },
        {
          text: "اپراتور",
          align: "center",
          sortable: false,
          value: "operator",
        },
        {
          text: "ناظر",
          align: "center",
          sortable: false,
          value: "observer",
        },
        {
          text: "برنامه‌نویس",
          align: "center",
          sortable: false,
          value: "programmer",
        },
      ],
      rolesData: [],
    }
  },
  mounted() {
    this.getRolePermissions()
  },
  methods: {
    async getRolePermissions() {
      const res = await servicesRepository(
        this
      ).roleUser.httpGetRolePermissions()
      this.rolesData = [res.data.data]
      const permissions = this.rolesData[0]["owner"].permissions
      const englishKeyRoles = Object.keys(this.rolesData[0])
      this.setTableHeaders(englishKeyRoles)
      this.setTableData(permissions)
    },
    setTableHeaders(englishKeyRoles) {
      for (let i = 0; i < englishKeyRoles.length; i++) {
        this.headers[i + 1].text =
          this.rolesData[0][englishKeyRoles[i]].display_name
        this.headers[i + 1].value = englishKeyRoles[i]
      }
    },
    setTableData(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        this.tableItems.push({
          title: permissions[i],
          owner: true,
          admin: this.checkHasPermission("admin", permissions[i]),
          accountant: this.checkHasPermission("accountant", permissions[i]),
          operator: this.checkHasPermission("operator", permissions[i]),
          observer: this.checkHasPermission("observer", permissions[i]),
          developer: this.checkHasPermission("developer", permissions[i]),
        })
      }
    },
    checkHasPermission(role, permission) {
      return this.rolesData[0][role].permissions.includes(permission)
    },
  },
}
</script>
<style lang="scss" scoped>
.info_table_cell {
  text-align-last: center;
}

.mobile-table {
  border-collapse: collapse;
  display: flex;
  flex-wrap: wrap;
}
.mobile-table thead {
  background: #f5f5f5;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
}

.mobile-table thead tr {
  width: 100% !important;
}

.mobile-table thead tr th {
  font-size: 9px;
  line-height: 20px;
  font-weight: 700;
  color: #333;
  width: auto;
  padding: 2px 4px !important;
}

.mobile-table thead tr th:first-child {
  width: 31.5% !important;
  padding: 0 !important;
}

.mobile-table thead tr th:last-child {
  padding-left: 0 !important;
  width: 20%;
  text-align: center;
}

.mobile-table tbody {
  width: 100%;
}

.mobile-table tbody tr {
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.mobile-table tbody tr:last-child {
  border-bottom: none;
}

.mobile-table tbody tr td {
  font-size: 9px !important;
  padding: 2px 4px !important;
}

.mobile-table tbody tr td:first-child {
  padding-right: 0 !important;
  padding-left: 4px !important;
  width: 30% !important;
}

.mobile-table tbody tr td:last-child {
  width: 20% !important;
}
</style>
<style lang="scss">
.role_info {
  thead,
  th {
    border: none !important;
    height: 40px !important;
  }
}
</style>
