const pbv = {
  state() {
    return {
      appConfigs: null,
      application: null,
    }
  },
  mutations: {
    setPbvAppConfigs(state, payload) {
      state.appConfigs = payload
    },
    SET_APPLICATION(state, application) {
      state.application = { ...application }
    },
  },
  getters: {
    getAppConfig: (state) => {
      return state.appConfigs
    },
    getPbvPersianMandateExpirationDuration: (state) => {
      return `${state.appConfigs?.mandate?.expiration?.duration_fa ?? "سه"} ${
        state.appConfigs?.mandate?.expiration?.unit_fa ?? "سال"
      }`
    },
    getPbvMandateCountList: (state) => {
      return state.appConfigs?.mandate.counts
    },
  },
  actions: {
    setPbvApplication(state, application) {
      state.commit("SET_APPLICATION", application)
    },
  },
}

export default pbv
