<template>
  <div>
    <h2 class="pb-6 my-0 mr-0 ml-auto">
      {{ title }}
    </h2>

    <div v-for="item in 5" :key="item">
      <v-card flat class="baseCard">
        <v-layout align-center>
          <v-flex class="icon">
            <span
              style="width: 40px; height: 40px; border-radius: 50%"
              class="default_gray ma-0"
            />
          </v-flex>
          <v-flex class="settlement_details">
            <v-layout>
              <v-flex class="settlemented">
                <div>
                  <span style="width: 50%" class="default_gray" />
                </div>
              </v-flex>
              <v-flex>
                <div class="total">
                  <span style="width: 30%" class="default_gray" />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <div>
              <span style="width: 50%" class="default_gray" />
            </div>
          </v-flex>
          <v-flex>
            <div>
              <span style="width: 50%" class="default_gray" />
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="scss" scoped>
.baseCard {
  padding: 18px 20px;
  height: 76px;
  border-bottom: 1px solid #eee !important;

  .icon {
    max-width: 40px;
    margin-left: 10px;
  }
  .settlement_details {
    width: 55%;
    .settlemented {
      max-width: 170px;
    }
    .total {
      color: #999999 !important;
    }
  }
  .menu {
    max-width: 24px;
  }
}
</style>
