<template>
  <ChartCardWrapper
    class="mmt-5"
    :title="kerdarLang.directDebit.expirationOfUsersLicense"
    :show-filter="true"
    :selected-custom-duration="defaultSelectedDuration"
    @changeFilter="changeMandateChurnFilter($event)"
  >
    <BarEChart
      v-if="mandateChurnStatus"
      chart-id="DirectDebitUsersLicenseExpiration"
      :x-axis-data="mandateChurnTitle"
      :y-axis-data="canceledMandateChurn"
      :second-y-axis-data="expiredMandateChurn"
      :hover-title="hoverMandateChurn"
      :first-value-hover-subtitle="kerdarLang.general.hasCanceled"
      :second-value-hover-subtitle="kerdarLang.general.hasExpired"
      first-bar-color="#A14E69"
      second-bar-color="#F9D358"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </ChartCardWrapper>
</template>

<script>
import ChartCardWrapper from "@/components/kerdar/components/ChartCardWrapper"
import BarEChart from "@/components/kerdar/components/BarEChart"
import {
  generateMonthsWithYearV2,
  generateWeekDaysV2,
} from "@/components/kerdar/utils/generalMethods.js"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "DirectDebitUsersLicenseExpirationEChart",
  components: {
    ChartCardWrapper,
    BarEChart,
  },
  props: {
    kerdarLang: {
      type: Object,
      default: () => {},
    },
    defaultSelectedDuration: {
      type: Object,
      default: () => {
        return {
          name: "ماه جاری",
          value: "current_month",
        }
      },
    },
  },
  data() {
    return {
      mandateChurnData: {},
      mandateChurnStatus: false,
    }
  },
  computed: {
    canceledMandateChurn() {
      return this.mandateChurnData.revoked
    },
    expiredMandateChurn() {
      return this.mandateChurnData.expired
    },
    mandateChurnTitle() {
      if (
        this.mandateChurnFilter &&
        ["weekly", "monthly", "hourly"].includes(
          this.mandateChurnFilter.timePeriod
        )
      ) {
        let times = []
        switch (this.mandateChurnFilter.timePeriod) {
          case "weekly":
            generateWeekDaysV2(times, this.mandateChurnData.revoked)
            break
          case "monthly":
            generateMonthsWithYearV2(times, this.mandateChurnData.revoked)
            break
          case "hourly":
            for (let element of this.mandateChurnData.revoked) {
              times.push(jmoment(element.date_time).format("H"))
            }
            break
        }
        return times
      }
      return this.mandateChurnData.revoked.map((item) => item.dateWithYear)
    },
    hoverMandateChurn() {
      if (
        this.mandateChurnFilter &&
        ["weekly", "monthly", "hourly"].includes(
          this.mandateChurnFilter.timePeriod
        )
      ) {
        let times = []

        switch (this.mandateChurnFilter.timePeriod) {
          case "weekly":
            generateWeekDaysV2(times, this.mandateChurnData.revoked)
            break
          case "monthly":
            generateMonthsWithYearV2(times, this.mandateChurnData.revoked)
            break
          case "hourly":
            for (let element of this.mandateChurnData.revoked) {
              times.push(
                `${this.kerdarLang.general.hour} ` +
                  jmoment(element.date_time).format("H")
              )
            }
            break
        }

        return times
      }
      return this.mandateChurnData.revoked.map((item) => item.dateWithYear)
    },
  },
  methods: {
    clearMandateChurnData() {
      this.mandateChurnStatus = false
      this.mandateChurnData = []
    },
    changeMandateChurnFilter(item) {
      this.mandateChurnFilter = item
      this.clearMandateChurnData()
      this.getMandateChurnData(item)
    },
    async getMandateChurnData(item) {
      const chartType = item.duration + "_" + item.timePeriod
      if (!chartType) {
        return
      }
      const response = await kerdarServices(
        this.$http,
        this
      ).directDebit.httpDirectDebitMandateChurn(chartType)
      //httpDirectDebitMandateChurnApiV2(item)

      const {
        data: { data },
      } = response
      const pushData = this.pushMandateChurnData(data)
      this.mandateChurnData = { revoked: [], expired: [] }

      for (let i = 0; i < pushData.length; i++) {
        this.mandateChurnData.revoked.push({
          date_time: pushData[i][0],
          value: pushData[i][1],
          dateWithoutYear: jmoment(pushData[i][0])
            .local("fa")
            .format("jD jMMMM"),
          dateWithYear: jmoment(pushData[i][0])
            .local("fa")
            .format("jD jMMMM jYYYY"),
        })

        this.mandateChurnData.expired.push({
          date_time: pushData[i][0],
          value: pushData[i][2],
          dateWithoutYear: jmoment(pushData[i][0])
            .local("fa")
            .format("jD jMMMM"),
          dateWithYear: jmoment(pushData[i][0])
            .local("fa")
            .format("jD jMMMM jYYYY"),
        })
      }
      this.mandateChurnData.revoked = this.mandateChurnData.revoked.sort(
        (a, b) => jmoment(a.date_time) - jmoment(b.date_time)
      )
      this.mandateChurnData.expired = this.mandateChurnData.expired.sort(
        (a, b) => jmoment(a.date_time) - jmoment(b.date_time)
      )
      // pushData.map((item) => {
      //   item[3] = jmoment(item[0]).local("fa").format("jD jMMMM")
      //   item[4] = jmoment(item[0]).local("fa").format("jD jMMMM jYYYY")
      // })
      // this.mandateChurnData = pushData
      this.mandateChurnStatus = true
    },
    pushMandateChurnData(data) {
      let tempData = []
      for (const element of data) {
        if (element[0] == "REVOKED" || element[0] == "REVOKED_AUTO") {
          tempData.push([element[1], element[2], 0])
        }
      }
      for (const element of data) {
        if (element[0] == "EXPIRED") {
          let itemArray = tempData.find((item) => item[0] == element[1])
          if (itemArray) {
            itemArray[2] = element[2]
          } else {
            tempData.push([element[1], 0, element[2]])
          }
        }
      }
      tempData = tempData.sort((a, b) => jmoment(a[0]) - jmoment(b[0]))
      return tempData
    },
  },
}
</script>

<style lang="scss" scoped></style>
