<template>
  <v-card flat class="ppa-8">
    <v-layout class="align-center mmb-12">
      <v-flex v-if="title" class="font_9">{{ title }}</v-flex>
      <v-spacer></v-spacer>
      <v-flex
        v-if="growth"
        style="max-width: 240px; width: fit-content"
        class="mmx-4"
      >
        <div class="insight-text-box-wrapper">
          <div
            :style="{ background: determineColorStatus() }"
            class="insight-text-box font_2 d-flex align-center"
          >
            <div>
              <span v-if="growth >= 0" class="bold van_green--text">
                <span v-if="growth > 500">
                  {{ kerdarLang.general.growMoreThan500Percent }}
                </span>
                <span v-else>
                  ٪{{ growth }} {{ kerdarLang.general.growth }}
                </span>
              </span>
              <span v-else class="bold van_red--text">
                ٪{{ Math.abs(growth) }} {{ kerdarLang.general.decrease }}
              </span>

              <span v-if="isNow">
                {{ kerdarLang.general.fromTheBegining }} {{ witchDuration }}
                {{ kerdarLang.general.untlNow }}
              </span>
              <span v-else>
                {{ kerdarLang.general.fromTheBegining }} {{ witchDuration }}
                {{ kerdarLang.general.untilEnd }}
              </span>
            </div>
            <div
              class="triangle-badge"
              :style="{ borderBottom: `12px solid ${determineColorStatus()}` }"
            ></div>
          </div>
        </div>
      </v-flex>
      <v-flex v-if="showFilter" style="max-width: fit-content">
        <v-layout style="width: fit-content" class="mx-0">
          <v-flex>
            <v-select
              v-model="durationSelected"
              :items="currentDuration"
              item-text="name"
              solo
              flat
              height="40"
              outlined
              return-object
              style="width: 136px"
              :menu-props="{ contentClass: 'withActiveStyle' }"
            >
            </v-select>
          </v-flex>
          <v-flex class="mr-2">
            <v-select
              v-model="timePeriodSelected"
              :items="timePeriod"
              item-text="name"
              solo
              flat
              height="40"
              outlined
              return-object
              style="width: 136px"
              :menu-props="{ contentClass: 'withActiveStyle' }"
            >
            </v-select>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <slot></slot>
  </v-card>
</template>
<script>
import kerdarLang from "@/components/kerdar/utils/langResource.json"

export default {
  name: "ChartCard",
  props: {
    title: null,
    showFilter: {
      default: false,
      type: Boolean,
    },
    growth: null,
    customDurationSelected: null,
    customDuration: {
      default: () => [
        "current_day",
        "current_week",
        "current_month",
        "current_quarter",
        "current_year",
        "last_day",
        "last_week",
        "last_month",
        "last_quarter",
        "last_year",
      ],
    },
  },
  data() {
    return {
      durationSelected: null,
      timePeriodSelected: null,
      duration: [
        {
          name: "روز جاری",
          value: "current_day",
        },
        {
          name: "هفته جاری",
          value: "current_week",
        },
        {
          name: "ماه جاری",
          value: "current_month",
        },
        {
          name: "فصل جاری",
          value: "current_quarter",
        },
        {
          name: "سال جاری",
          value: "current_year",
        },
        {
          name: "دیروز",
          value: "last_day",
        },
        {
          name: "هفته گذشته",
          value: "last_week",
        },
        {
          name: "ماه گذشته",
          value: "last_month",
        },
        {
          name: "فصل گذشته",
          value: "last_quarter",
        },
        {
          name: "سال گذشته",
          value: "last_year",
        },
      ],
      kerdarLang,
    }
  },
  computed: {
    witchDuration() {
      if (this.durationSelected) return this.durationSelected.name
      return ""
    },
    currentDuration() {
      let tempDuration = []
      if (this.customDuration) {
        for (const item of this.customDuration) {
          const find = this.duration.find((x) => x.value === item)
          if (find) {
            tempDuration.push(find)
          }
        }
      }
      return tempDuration
    },
    timePeriod() {
      if (!this.durationSelected)
        return [
          {
            name: "براساس روز",
            value: "daily",
          },
        ]
      if (["current_day", "last_day"].includes(this.durationSelected.value)) {
        return [
          {
            name: "براساس ساعت",
            value: "hourly",
          },
        ]
      }
      if (["current_week", "last_week"].includes(this.durationSelected.value)) {
        return [
          {
            name: "براساس روز",
            value: "daily",
          },
        ]
      }
      if (
        ["current_month", "last_month"].includes(this.durationSelected.value)
      ) {
        return [
          {
            name: "براساس روز",
            value: "daily",
          },
          {
            name: "براساس هفته",
            value: "weekly",
          },
        ]
      }
      if (
        ["current_quarter", "last_quarter"].includes(
          this.durationSelected.value
        )
      ) {
        return [
          {
            name: "براساس روز",
            value: "daily",
          },
          {
            name: "براساس هفته",
            value: "weekly",
          },
          {
            name: "براساس ماه",
            value: "monthly",
          },
        ]
      }
      if (["current_year", "last_year"].includes(this.durationSelected.value)) {
        return [
          {
            name: "براساس هفته",
            value: "weekly",
          },
          {
            name: "براساس ماه",
            value: "monthly",
          },
        ]
      }
    },
    isNow() {
      const current = [
        "current_day",
        "current_week",
        "current_month",
        "current_quarter",
        "current_year",
      ]
      if (
        this.durationSelected &&
        current.includes(this.durationSelected.value)
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    durationSelected: function (newValue) {
      if (this.timePeriod && this.timePeriod.length)
        this.timePeriodSelected = this.timePeriod[0]
    },
    timePeriodSelected: function (newValue) {
      this.returnToParent()
    },
  },
  mounted() {
    if (!this.customDurationSelected) {
      this.durationSelected = {
        name: "روز جاری",
        value: "current_day",
      }
    } else {
      this.durationSelected =
        this.currentDuration.find(
          (x) => x.value === this.customDurationSelected
        ) || this.currentDuration[0]
    }
  },
  methods: {
    returnToParent() {
      this.$emit("changeFilter", {
        duration: this.durationSelected.value,
        timePeriod: this.timePeriodSelected.value,
      })
    },
    determineColorStatus() {
      if (this.growth > 0) {
        return "#E7EFED"
      } else if (this.growth === 0) {
        return "#F1F1F1"
      } else {
        return "#D44D421A"
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/components/kerdar/utils/sharedStyles/insight-box.scss";
</style>
