import { render, staticRenderFns } from "./generalYellowBarMessage.vue?vue&type=template&id=36307483&"
import script from "./generalYellowBarMessage.vue?vue&type=script&lang=js&"
export * from "./generalYellowBarMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports