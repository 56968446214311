<template>
  <div>
    <div
      class="d-flex"
      :class="{
        'justify-center': isMobileMode,
        'flex-column-reverse align-center': isUploadDocStep && isMobileMode,
      }"
      style="position: relative"
    >
      <slot />
    </div>
    <div class="d-flex align-center justify-start">
      <v-btn
        color="primary"
        elevation="0"
        :width="firstButtonWidth"
        :height="firstButtonHeight"
        class="continue-button-style white--text"
        :class="{ 'mx-0': $vuetify.breakpoint.lgAndUp }"
        :disabled="isFirstButtonDisabled"
        @click="$emit('confirm')"
      >
        {{ firstButtonText }}
      </v-btn>
      <v-btn
        v-show="isFirstButtonDisabled && hasSecondButton"
        elevation="0"
        outlined
        :width="secondButtonWidth"
        :height="secondButtonHeight"
        class="mr-2 van_color03--text"
        :class="{ 'mx-0': $vuetify.breakpoint.lgAndUp }"
        @click="$emit('skip')"
      >
        {{ secondButtonText }}
      </v-btn>
      <v-btn
        v-show="hasBackButton"
        elevation="0"
        outlined
        :width="backButtonWidth"
        :height="backButtonHeight"
        class="mr-2 van_color03--text"
        :class="{ 'mx-0': $vuetify.breakpoint.lgAndUp }"
        @click="$emit('skip')"
      >
        {{ backButtonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepperContentWrapper",
  props: {
    isFirstButtonDisabled: {
      type: Boolean,
      default: false,
    },
    firstButtonText: {
      type: String,
      default: "ادامه",
    },
    firstButtonWidth: {
      type: String,
      default: "120",
    },
    firstButtonHeight: {
      type: String,
      default: "46",
    },
    secondButtonText: {
      type: String,
      default: "بعدا انجام میدم",
    },
    secondButtonWidth: {
      type: String,
      default: "150",
    },
    secondButtonHeight: {
      type: String,
      default: "46",
    },
    isUploadDocStep: {
      type: Boolean,
      default: false,
    },
    hasSecondButton: {
      type: Boolean,
      default: false,
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
    backButtonWidth: {
      type: String,
      default: "150",
    },
    backButtonHeight: {
      type: String,
      default: "46",
    },
    backButtonText: {
      type: String,
      default: "بازگشت به مرحله قبل",
    },
    shouldRemoveSecondBtnWithEnablingFirstBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.theme--light.v-btn.v-btn--disabled.v-btn--has-bg) {
  background: #dcece7 !important;
  color: #fff !important;
}

@media screen and (min-width: 960px) and (max-width: 1264px) {
  .continue-button-style {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .continue-button-style {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 425px) {
  .continue-button-style {
    margin-right: 52px !important;
  }
}

@media screen and (max-width: 375px) {
  .continue-button-style {
    margin-right: 28px !important;
  }
}

@media screen and (max-width: 360px) {
  .continue-button-style {
    margin-right: 20px !important;
  }
}

@media screen and (max-width: 320px) {
  .continue-button-style {
    margin-right: 2px !important;
  }
}
</style>
