<template>
  <chart-card-one-filter
    class="mmt-5"
    :title="kerdarLang.directDebit.bankActiveLicensesFrequency"
    :show-filter="false"
  >
    <one-bar-horizontal-bar-type2
      v-if="activeMandateStatus"
      :x-value="activeMandateLineTitle"
      :y-value="activeMandateValue"
      :y-result="activeMandateLineTooltip"
      :first-bar-color="activeMandateBarColor"
      :y-unit="kerdarLang.general.license"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </chart-card-one-filter>
</template>

<script>
import ChartCardOneFilter from "@/components/kerdar/components/chartCardOneFilter"
import OneBarHorizontalBarType2 from "@/components/kerdar/components/oneBarHorizontalBarType2"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

export default {
  name: "DirectDebitBankActiveLicense",
  components: {
    "chart-card-one-filter": ChartCardOneFilter,
    "one-bar-horizontal-bar-type2": OneBarHorizontalBarType2,
  },
  props: {
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeMandateData: [],
      activeMandateStatus: false,
      activeMandateBarColor: "#4C9F87",
    }
  },
  computed: {
    activeMandateLineTitle() {
      return this.activeMandateData.map((item) => item[0])
    },
    activeMandateValue() {
      return this.activeMandateData.map((item) => Number(item[1]))
    },
    activeMandateLineTooltip() {
      let totalCount = 0
      if (this.activeMandateData && this.activeMandateData.length) {
        for (let data of this.activeMandateData) {
          totalCount += data[1]
        }
      }

      return this.activeMandateData.map((item) => {
        let percentage =
          totalCount == 0
            ? 0
            : Math.round(
                ((100 * item[1]) / totalCount + Number.EPSILON) * 100
              ) / 100
        let text =
          '<span style="font-weight: bold;color: #4C9F87">' +
          percentage +
          "% " +
          `${this.kerdarLang.directDebit.activeLicense}` +
          "</span>"
        text +=
          ` ${this.kerdarLang.general.belongTo} ` +
          item[0] +
          ` ${this.kerdarLang.general.is}.`
        return text
      })
    },
  },
  mounted() {
    this.getActiveMandateData()
  },
  methods: {
    async getActiveMandateData() {
      const response = await kerdarServices(
        this.$http,
        this
      ).directDebit.httpDirectDebitActiveMandate()

      this.activeMandateData = response.data.data
      this.activeMandateStatus = true
    },
  },
}
</script>

<style lang="scss" scoped></style>
