<template>
  <v-card
    v-if="$vuetify.breakpoint.mdAndUp"
    class="business"
    color="white"
    flat
  >
    <div
      :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      class="d-flex full-width"
    >
      <div v-if="loading" class="titles">
        <div class="income" style="width: 332px">
          <div
            v-if="loading"
            class="default_gray"
            style="width: 5em; height: 2em"
          />
        </div>
        <div
          v-if="loading"
          class="default_gray"
          style="width: 6em; height: 1em"
        />
      </div>
      <div v-else class="titles">
        <v-layout align-center justify-center>
          <v-flex style="width: 320px">
            <div class="income mb-2">
              <h2 style="display: inline">لیست بانک‌ها</h2>
            </div>
            <div v-if="banks.length === activeBanksCount" class="income">
              همه
              {{ banks.length }}
              بانک موجود برای شما فعال هستند.
            </div>
            <div v-else class="income">
              از بین
              {{ banks.length }}
              بانک شما
              {{ activeBanksCount }}
              بانک را فعال کرده‌اید.
            </div>
          </v-flex>
        </v-layout>
      </div>
      <div class="more-data">
        <div v-if="loading" class="bank-list">
          <div v-for="i in 12" :key="i" class="bank-item">
            <div
              class="default_gray"
              style="width: 56px; height: 56px; margin: 0"
            />
          </div>
        </div>
        <div v-else class="bank-list">
          <div v-for="bank in activeBanks" :key="bank.name" class="bank-item">
            <div class="image-box">
              <v-img
                :lazy-src="bank.logo"
                :src="bank.logo"
                contain
                height="32px"
                width="32px"
              />
            </div>
          </div>
        </div>
      </div>
      <v-layout class="botton mr-auto" column>
        <v-flex v-if="$vuetify.breakpoint.mdAndUp">
          <div
            v-if="loading"
            class="default_gray"
            style="width: 4em; height: 2em"
          />
          <v-btn
            v-else-if="$store.state.business.role !== 'developer'"
            class="btn_small_normal"
            depressed
            outlined
            text
            @click="showManagement"
          >
            مدیریت
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
  <v-card v-else class="business-md-down" color="white" flat>
    <v-layout justify-space-between wrap>
      <v-flex>
        <div v-if="loading" class="titles">
          <div class="income" style="width: 332px">
            <div
              v-if="loading"
              class="default_gray"
              style="width: 5em; height: 2em"
            />
          </div>
          <div
            v-if="loading"
            class="default_gray"
            style="width: 6em; height: 1em"
          />
        </div>
        <div v-else class="titles">
          <div class="income mb-2">
            <h2 style="display: inline">لیست بانک‌ها</h2>
          </div>
          <div v-if="banks.length === activeBanksCount" class="income">
            همه
            {{ banks.length }}
            بانک موجود برای شما فعال هستند.
          </div>
          <div v-else class="income">
            از بین
            {{ banks.length }}
            بانک شما
            {{ activeBanksCount }}
            بانک را فعال کرده‌اید.
          </div>
        </div>
      </v-flex>
      <v-flex
        v-if="$store.state.business.role !== 'developer'"
        style="max-width: 40px; margin-left: -10px"
      >
        <v-menu
          bottom
          close-on-content-click
          content-class="up_menu"
          offset-y
          transition="slide-y-transition"
        >
          <!-- this on slot sometime is undefined -->
          <template #activator="{ on }">
            <v-btn fab icon size="24" small style="margin: 0" v-on="on">
              <v-icon color="van_color03" size="21" style="line-height: 40px">
                more_vert
              </v-icon>
            </v-btn>
          </template>

          <v-card class="drop_down_menu">
            <v-list class="menu_v_list">
              <v-list-item @click="showManagement">
                <v-list-item-content>
                  <v-list-item-title>مدیریت</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout align-center class="more-data" fill-height justify-center>
      <v-flex>
        <div class="more-data">
          <div v-if="loading" class="bank-list">
            <div v-for="i in 12" :key="i" class="bank-item">
              <div
                class="default_gray"
                style="width: 56px; height: 56px; margin: 0"
              />
            </div>
          </div>
          <div v-else class="bank-list" style="justify-content: center">
            <div v-for="bank in activeBanks" :key="bank.name" class="bank-item">
              <div class="image-box">
                <v-img
                  :lazy-src="bank.logo"
                  :src="bank.logo"
                  contain
                  height="32px"
                  width="32px"
                />
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "BankListCard",
  data() {
    return {
      isHover: false,
      hover: false,
      banks: [],
      loading: false,
    }
  },
  computed: {
    activeBanksCount() {
      return this.banks.filter((item) => item.is_active).length
    },
    activeBanks() {
      return this.$store.state.subscription.banks.filter(
        (item) => item.is_active
      )
    },
  },
  created() {
    this.loading = true
    this.$http
      .get(this.$store.getters.apiUrlParam + "/subscription/banks", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + this.$store.state.token,
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.banks = response.data.result.banks
        this.$store.commit("setBanks", this.banks)

        this.isLoad = true
      })
      .catch((error) => {
        //callsnackbar
      })
      .finally((response) => {
        this.loading = false
      })
  },
  methods: {
    showManagement() {
      this.$emit("show-management")
    },
  },
}
</script>

<style lang="scss" scoped>
.bank-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.image-box {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  width: 56px;
  height: 56px;
  border-radius: 4px;
}

.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}

.business {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  box-shadow: none;
  margin: auto;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.business-md-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }

  .more-data {
    display: flex;
    //width: 250px;
    margin-top: 16px;
  }

  .bus {
    background-color: $van_color11;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 11px 5px;
    height: 80px;
    width: 100%;
    margin: 5px;
    max-width: 95%;

    &.bus-right,
    &.bus-left {
      width: 95%;
    }

    &.flat {
      background: transparent;
      color: $van_pink;
      font-size: 12px;
      font-weight: bold;
      height: 28px;
    }
  }
}

.v-avatar {
  margin: 0 0 10px 0;
}

.titles {
  max-width: 295px;
}

h2 {
  margin: 0 0 9px 0;
  line-height: 20px;
}

.income {
  font-size: 12px;
  color: #666;
  font-weight: normal;

  h2 {
    font-size: 14px;
  }
}

.bus-right {
  margin-left: 10px;
}

.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;
  max-width: 120px;

  &.bus-right,
  &.bus-left {
    width: 50%;
  }

  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}

.business .more-data {
  display: flex;
  width: 384px;
}

.mablagh {
  font-size: 14px;
  font-weight: bold;
}

.sub-text {
  font-size: 9px;
  color: #999;
}

.layout.botton {
  max-width: 88px;

  .v-btn {
    width: calc(100% - 16px);
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.v-chip--small {
  height: 25px !important;
  margin-top: 5px !important;
}

.full-width {
  width: 100%;
}
</style>
