import kerdarLang from "./langResource.json"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export function minNumbersLength(numbers) {
  const minValue = parseInt(Math.min.apply(null, numbers.filter(Boolean)))
  return minValue.toString().length
}

export function calculatePriceUnit(length) {
  let result = ""

  if (length > 9) {
    result = "میلیارد"
  } else if (length > 6) {
    result = "میلیون"
  } else if (length > 3) {
    result = "هزار"
  }

  return result
}

export function calculateGrowPercentage(item1, item2) {
  if (!item2 && !item1) return 0
  if (!item2) return 100
  let percent = ((item1 - item2) / item2) * 100
  return Math.round(percent)
}

export function sortData(data) {
  let result = data.sort((a, b) => {
    if (a[1] < b[1]) {
      return 1
    }
    if (a[1] > b[1]) {
      return -1
    }
    return 0
  })
  return result
}

export function generateWeekDays(timesList, dataList) {
  function generateJalaliMoment(dataList, index) {
    if (dataList.length === 0) {
      return
    } else if (dataList.length === 1) {
      return jmoment(dataList[0][0])
    } else {
      return jmoment(dataList[index][0])
    }
  }

  function generateFridayOfWeek(dataList, index, dateFormat = "jD jMMMM") {
    return generateJalaliMoment(dataList, index)
      .endOf("jweek")
      .format(dateFormat)
  }

  function generateDay(dataList, index, dateFormat = "jD jMMMM") {
    return generateJalaliMoment(dataList, index).format(dateFormat)
  }

  function generateDaysDifference(
    dataList,
    index,
    daysDifference,
    dateFormat = "jD jMMMM jYYYY"
  ) {
    return generateJalaliMoment(dataList, index)
      .add(daysDifference, "days")
      .format(dateFormat)
  }

  function generateStartDayOfMonth(dataList, index, dateFormat = "jD jMMMM") {
    return generateJalaliMoment(dataList, index)
      .startOf("jmonth")
      .format(dateFormat)
  }

  function generateEndDayOfMonth(dataList, index, dateFormat = "jD") {
    return generateJalaliMoment(dataList, index)
      .endOf("jMonth")
      .format(dateFormat)
  }

  const saturdayOfFirstWeek = generateDay(dataList, 1)
  const firstMonth = generateJalaliMoment(dataList, 0).jMonth()
  const secondMonth = generateJalaliMoment(dataList, 1).jMonth()
  const startDayOfFirstMonth = generateStartDayOfMonth(dataList, 0)
  const startDayOfFirstMonthWithYear = generateStartDayOfMonth(
    dataList,
    0,
    "jD jMMMM jYYYY"
  )
  const startDayOfSecondMonth = generateStartDayOfMonth(dataList, 1)
  const until = " تا "

  for (let index = 0; index < dataList.length; index++) {
    if (index === 0) {
      if (firstMonth === secondMonth) {
        if (saturdayOfFirstWeek !== startDayOfFirstMonth) {
          if (startDayOfFirstMonth !== generateFridayOfWeek(dataList, 0)) {
            timesList.push(
              startDayOfFirstMonth + until + generateFridayOfWeek(dataList, 0),
              saturdayOfFirstWeek +
                until +
                generateDaysDifference(dataList, 1, -1)
            )
          } else {
            timesList.push(startDayOfFirstMonthWithYear)
          }
        } else {
          timesList.push(
            saturdayOfFirstWeek +
              until +
              generateDaysDifference(dataList, 1, -1)
          )
        }
      } else {
        timesList.push(
          startDayOfSecondMonth +
            until +
            generateDaysDifference(dataList, 1, -1)
        )
      }
    } else {
      if (index < dataList.length - 1) {
        timesList.push(
          generateDay(dataList, index) +
            until +
            generateFridayOfWeek(dataList, index + 1, "jD jMMMM jYYYY")
        )
      } else {
        const lastFirstDay = generateDay(dataList, index, "jD")
        const lastDayOfMonth = generateEndDayOfMonth(dataList, index)

        if (lastDayOfMonth - lastFirstDay > 6) {
          timesList.push(
            generateDay(dataList, index) +
              until +
              generateDaysDifference(dataList, index, 6)
          )
        } else {
          timesList.push(
            generateDay(dataList, index) +
              until +
              generateEndDayOfMonth(dataList, index, "jD jMMMM jYYYY")
          )
        }
      }
    }
  }
}

export function generateWeekDaysV2(timesList, dataList) {
  function generateJalaliMoment(dataList, index) {
    if (dataList.length === 0) {
      return
    } else if (dataList.length === 1) {
      return jmoment(dataList[0].date_time)
    } else {
      return jmoment(dataList[index].date_time)
    }
  }

  function generateFridayOfWeek(dataList, index, dateFormat = "jD jMMMM") {
    return generateJalaliMoment(dataList, index)
      .endOf("jweek")
      .format(dateFormat)
  }

  function generateDay(dataList, index, dateFormat = "jD jMMMM") {
    return generateJalaliMoment(dataList, index).format(dateFormat)
  }

  function generateDaysDifference(
    dataList,
    index,
    daysDifference,
    dateFormat = "jD jMMMM jYYYY"
  ) {
    return generateJalaliMoment(dataList, index)
      .add(daysDifference, "days")
      .format(dateFormat)
  }

  function generateStartDayOfMonth(dataList, index, dateFormat = "jD jMMMM") {
    return generateJalaliMoment(dataList, index)
      .startOf("jmonth")
      .format(dateFormat)
  }

  function generateEndDayOfMonth(dataList, index, dateFormat = "jD") {
    return generateJalaliMoment(dataList, index)
      .endOf("jMonth")
      .format(dateFormat)
  }

  const saturdayOfFirstWeek = generateDay(dataList, 0)
  const firstMonth = generateJalaliMoment(dataList, 0).jMonth()
  const secondMonth = generateJalaliMoment(dataList, 1).jMonth()
  const startDayOfFirstMonth = generateStartDayOfMonth(dataList, 0)
  const startDayOfFirstMonthWithYear = generateStartDayOfMonth(
    dataList,
    0,
    "jD jMMMM jYYYY"
  )
  const startDayOfSecondMonth = generateStartDayOfMonth(dataList, 1)
  const until = " تا "

  for (let index = 0; index < dataList.length; index++) {
    if (index === 0) {
      if (firstMonth === secondMonth) {
        if (saturdayOfFirstWeek !== startDayOfFirstMonth) {
          if (startDayOfFirstMonth !== generateFridayOfWeek(dataList, 0)) {
            timesList.push(
              startDayOfFirstMonth + until + generateFridayOfWeek(dataList, 0),
              saturdayOfFirstWeek +
                until +
                generateDaysDifference(dataList, 1, -1)
            )
          } else {
            timesList.push(startDayOfFirstMonthWithYear)
          }
        } else {
          timesList.push(
            saturdayOfFirstWeek +
              until +
              generateDaysDifference(dataList, 1, -1)
          )
        }
      } else {
        timesList.push(
          startDayOfSecondMonth +
            until +
            generateDaysDifference(dataList, 1, -1)
        )
      }
    } else {
      if (index < dataList.length - 1) {
        timesList.push(
          generateDay(dataList, index) +
            until +
            generateFridayOfWeek(dataList, index + 1, "jD jMMMM jYYYY")
        )
      } else {
        const lastFirstDay = generateDay(dataList, index, "jD")
        const lastDayOfMonth = generateEndDayOfMonth(dataList, index)

        if (lastDayOfMonth - lastFirstDay > 6) {
          timesList.push(
            generateDay(dataList, index) +
              until +
              generateDaysDifference(dataList, index, 6)
          )
        } else {
          timesList.push(
            generateDay(dataList, index) +
              until +
              generateEndDayOfMonth(dataList, index, "jD jMMMM jYYYY")
          )
        }
      }
    }
  }
}

export function generateMonths(timesList, dataList) {
  for (let element of dataList) {
    let date = jmoment(element[0]).format("jMMMM")
    timesList.push(date)
  }
}

export function generateMonthsV2(timesList, dataList) {
  for (let element of dataList) {
    let date = jmoment(element.date_time).format("jMMMM")
    timesList.push(date)
  }
}

export function generateMonthsWithYear(timesList, dataList) {
  for (let element of dataList) {
    let date =
      jmoment(element[0]).format("jMMMM") +
      " " +
      jmoment(element[0]).format("jYYYY")
    timesList.push(date)
  }
}

export function generateMonthsWithYearV2(timesList, dataList) {
  for (let element of dataList) {
    let date =
      jmoment(element.date_time).format("jMMMM") +
      " " +
      jmoment(element.date_time).format("jYYYY")
    timesList.push(date)
  }
}

export function generateHours(timesList, dataList) {
  for (let element of dataList) {
    timesList.push("ساعت " + jmoment(element[0]).format("H"))
  }
}

export function generateHoursV2(timesList, dataList) {
  for (let element of dataList) {
    timesList.push(
      `${kerdarLang.general.hour} ` + jmoment(element.date_time).format("H")
    )
  }
}

export function generateHoursWithDateV2(timesList, dataList) {
  for (let element of dataList) {
    timesList.push(
      `${kerdarLang.general.hour} ` +
        jmoment(element.date_time).format("H") +
        " -  " +
        element.dateWithYear
    )
  }
}

export function changeHorizontalWeeklyFirstDay(data, firstIndex, secondIndex) {
  function addPersianDateOfDayWithMonthToItemList(item, firstIndex) {
    item[firstIndex] = jmoment(item[0]).local("fa").format("jD jMMMM")
  }

  function addPersianDateOfDayWithMonthAndYearToItemList(item, secondIndex) {
    item[secondIndex] = jmoment(item[0]).local("fa").format("jD jMMMM jYYYY")
  }

  if (data.length > 1) {
    const firstMonth = jmoment(data[0][0]).local("fa").jMonth()
    const secondMonth = jmoment(data[1][0]).local("fa").jMonth()

    return data.map((item, index) => {
      if (index === 0 && firstMonth !== secondMonth) {
        item[firstIndex] = jmoment(data[1][0])
          .startOf("jmonth")
          .format("jD jMMMM")
      } else {
        addPersianDateOfDayWithMonthToItemList(item, firstIndex)
        addPersianDateOfDayWithMonthAndYearToItemList(item, secondIndex)
      }
    })
  } else {
    return data.map((item) => {
      addPersianDateOfDayWithMonthToItemList(item, firstIndex)
      addPersianDateOfDayWithMonthAndYearToItemList(item, secondIndex)
    })
  }
}

export function changeHorizontalWeeklyFirstDayV2(data) {
  function addPersianDateToItemList(item) {
    item.dateWithoutYear = jmoment(item.date_time)
      .local("fa")
      .format("jD jMMMM")
    item.dateWithYear = jmoment(item.date_time)
      .local("fa")
      .format("jD jMMMM jYYYY")
  }

  if (data.length > 1) {
    const firstMonth = jmoment(data[0].date_time).local("fa").jMonth()
    const secondMonth = jmoment(data[1].date_time).local("fa").jMonth()
    return data.map((item, index) => {
      if (index === 0 && firstMonth !== secondMonth) {
        item.dateWithoutYear = jmoment(data[1].date_time)
          .startOf("jmonth")
          .format("jD jMMMM")

        item.dateWithYear = jmoment(data[1].date_time)
          .startOf("jmonth")
          .format("jD jMMMM jYYYY")
      } else {
        addPersianDateToItemList(item)
      }
    })
  } else {
    return data.map((item) => {
      addPersianDateToItemList(item)
    })
  }
}

export function generateTextOfAverageLastSomeDays(num) {
  const str = kerdarLang.general.averageLastSomeDays
  return str?.replace("{number}", num)
}

export function generateTextOfAverageUnsuccessfulDepositsLastSomeDays(num) {
  const str = kerdarLang.general.averageUnsuccessfulDepositsLastSomeDays
  return str?.replace("{number}", num)
}

export function removeNumberSeparator(number) {
  return number.replace(/,/g, "")
}

export function shouldShowComponent(whiteListBusinessNames) {
  if (whiteListBusinessNames) {
    const isBusinessInWhiteList = whiteListBusinessNames.find(
      (item) => item === this.$store.state.business.business_name
    )
    return !!isBusinessInWhiteList
  }
}

export function calculateTotal(list) {
  let total = 0
  for (let item of list) {
    total += item.value
  }
  return total
}
