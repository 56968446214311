<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> ویرایش درگاه </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!isEnable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout class="modal-container">
      <v-form ref="form" v-model="valid">
        <div class="main-card-container">
          <div class="font_5 bold mb-4 align-self-start">
            اطلاعات ترمینال شاپرکی
          </div>
          <v-card outlined class="main-card" flat>
            <div>
              <InputContainer label="کد رهگیری مالیاتی" :optional="false">
                <template>
                  <v-flex class="withlabel big">
                    <TaxCode
                      :disable="$store.state.business.tax_code != null"
                      :input-value="$store.state.business.tax_code + ''"
                      @setValue="tax_code = $event"
                    />
                  </v-flex>
                </template>
              </InputContainer>
              <InputContainer label="آدرس وب‌سایت" :optional="false">
                <template>
                  <v-flex class="withlabel big">
                    <v-text-field
                      ref="url"
                      v-model="url"
                      solo
                      flat
                      height="40"
                      outlined
                      readonly
                      :hint="urlHint"
                      :rules="urlRule"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-text-field>
                  </v-flex>
                </template>
              </InputContainer>
              <InputContainer label="آی‌ پی‌های شما" :optional="false">
                <template>
                  <v-flex class="withlabel big comboboxSmallchip">
                    <v-combobox
                      ref="ips"
                      v-model="ips"
                      hide-selected
                      multiple
                      small-chips
                      solo
                      flat
                      outlined
                      :rules="ipsRule"
                      :hint="ipsHint"
                      deletable-chips
                      @input.native="
                        convertNumbers2English($event.srcElement.value)
                      "
                      @keypress="checkIps($event)"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-combobox>
                  </v-flex>
                </template>
              </InputContainer>
              <InputContainer label="شماره شبا">
                <div
                  v-if="ibanOwner"
                  class="mx-0 mt-2"
                  style="width: 100%"
                  :class="
                    ibanError
                      ? 'withError gray-text-box with-data'
                      : 'gray-text-box with-data'
                  "
                >
                  <v-layout
                    v-if="ibanOwner"
                    class="d-flex flex-wrap"
                    align-right
                  >
                    <v-flex class="iban-bank-container">
                      <v-img
                        size="30"
                        :src="require(`@/assets/img/banks/${bankSvgCode}.svg`)"
                      />
                    </v-flex>
                    <v-flex class="d-flex flex-column justify-center">
                      <div class="font_2 bold">
                        {{ ibanOwner }}
                      </div>
                      <div class="font_2">
                        {{ $store.state.business.default_iban }}
                      </div>
                    </v-flex>
                  </v-layout>
                  <span v-else-if="ibanError">{{ ibanError }}</span>
                </div>
              </InputContainer>

              <InputContainer label="دریافت کارمزد" :optional="true">
                <template>
                  <v-flex class="withlabel">
                    <v-radio-group v-model="wage" row>
                      <v-radio label="از خودم" :value="true" />
                      <v-radio label="از پرداخت‌کننده" :value="false" />
                    </v-radio-group>
                  </v-flex>
                </template>
              </InputContainer>
            </div>
          </v-card>
        </div>

        <div class="main-card-container">
          <v-card outlined class="main-card" flat>
            <uploadBox
              label-text="لوگوی کسب‌و‌کار"
              :aspect-ratio="'1'"
              :max-width="400"
              :max-height="400"
              @uploadedfile="uploadedFile = $event"
            />
          </v-card>
        </div>
      </v-form>
    </v-layout>
  </v-card>
</template>

<script>
import TaxCode from "@/components/inputs/taxCode"
import InputContainer from "../../elements/inputContainer.vue"
import uploadBox from "../../elements/uploadBox.vue"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "EditIpgModal",
  components: {
    TaxCode,
    InputContainer,
    uploadBox,
  },
  data() {
    return {
      valid: false,
      loading: false,
      registerDate: "",
      registerDateText: "",
      ipg: {},
      url: null,
      englishAndDigitpattern: /^[a-zA-Z0-9_-]*$/,
      urlPattern: new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ),
      callbackUrlPattern: new RegExp(
        // "^(https?:\\/\\/)" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ),
      ipValidationPattern:
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      urlHint:
        '<div class="d-flex align-center"><img style="max-width:10px;margin-left:5px" src="' +
        require("@/assets/img/info.svg") +
        '" >این آدرس در درگاه پرداخت شما نمایش داده می‌شود.</div>',
      ipsHint:
        '<div class="d-flex align-center"><img style="max-width:10px;margin-left:5px" src="' +
        require("@/assets/img/info.svg") +
        '" >بعد از وارد کردن هر آی‌‌پی، دکمه «اینتر» را بزنید.</div>',
      ips: [],
      tax_code: "",
      wage: false,
      selectItems: ["از خودم کسر شود", "از پرداخت‌کننده دریافت شود"],
      headers: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "title",
        },
        {
          text: "اشتراکی",
          align: "center",
          sortable: false,
          value: "notVip",
        },
        {
          text: "اختصاصی",
          align: "center",
          sortable: false,
          value: "vip",
        },
      ],
      // upload photo
      uploadedFile: "",
      urlRule: [
        (v) => !!v || "<span>آدرس وب‌سایت الزامی است</span>",
        (v) =>
          (!!v && this.checkFormat([v], this.urlPattern)) ||
          "<span> فرمت صحیح آدرس وب سایت را وارد کنید</span>",
      ],
      ipsRule: [
        (v) => !!v || "<span> وارد کردن آی‌ پی‌ الزامی است</span>",
        (v) =>
          (!!v && this.checkFormat(v, this.ipValidationPattern)) ||
          "<span> فرمت صحیح آی‌ پی‌ را وارد کنید</span>",
      ],
      defaultIbanInShaparak: null,
      ibanOwner: "",
      bankSvgCode: null,
      ibanError: null,
    }
  },
  computed: {
    isEnable() {
      return !this.loading && this.valid && this.ips && this.ips.length > 0
    },
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.ips.lazySearch
      },
      (val) => {
        if (val) {
          this.$refs.ips.lazySearch = this.convertNumbers2English(val).replace(
            "٫",
            "."
          )
        }
      }
    )
    this.wage = this.$store.state.ipg.wage
    this.url = this.$store.state.ipg.url
    this.ips = this.$store.state.ipg.ips

    if (this.$store.state.business.avatar) {
      this.uploadedFile = this.$store.state.business.avatar
      this.startUpload = true
      this.proggressBar = 100
    }

    this.checkIbanInfo()
  },
  methods: {
    checkFormat(items, selectedpattern) {
      let doesAllItemsMatch = true

      for (let element of items) {
        if (element.includes("localhost")) continue
        //convert farsi digits to english
        element = this.convertNumbers2English(element)
        if (selectedpattern && !selectedpattern.test(element)) {
          // if item does not match the pattern return false
          doesAllItemsMatch = false
          return false
        }
      }

      if (doesAllItemsMatch) return true
      return false
    },
    checkIps(event) {
      if (
        !/(^[0-9۰-۹٫]*$)/.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "."
      ) {
        event.preventDefault()
      }
    },
    sendForm() {
      this.loading = true
      var data = {
        wage: this.wage,
        ips: this.ips,
        avatar: this.uploadedFile,
      }
      // if (this.$store.state.business.business_type == "personal") {
      //   data.birth_certificate_number =
      //     this.$store.state.business.owner.birthdate.slice(0, 4) <= 1367
      //       ? this.birthcertificateNumber
      //       : this.$store.state.business.owner.national_code
      // }

      servicesRepository(this)
        .ipgServices.httpUpdateIpg(data)
        .then((response) => {
          this.ipg = response.data.data.ipg
          this.$store.commit("addIpg", this.ipg)
          this.$store.commit("changeIpgStatus", this.ipg.modified_ipg_fields)
          this.$store.commit("callSuccessSnackbar", "اطلاعات با موفقیت ثبت شد")

          this.$emit("closeModal", { status: true })
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          //callsnackbar
        })
    },
    setTheDate(event) {
      this.registerDateText = event.dateText
      this.registerDate = event.date
    },
    checkIbanInfo() {
      const iban = this.$store.state.business.default_iban
      this.bankSvgCode = parseInt(iban.slice(4, 7))

      servicesRepository(this)
        .checkServices.httpCheckIban({
          IBAN: iban,
        })
        .then((response) => {
          this.ibanOwner = `${response.data.data.ibans.account_owner[0].firstName} ${response.data.data.ibans.account_owner[0].lastName}`
        })
        .catch((error) => {
          this.ibanError = error
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.progressbar {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.v-input--radio-group:not(.simpleRadio)
  .v-input__control
  .v-input__slot
  .v-radio {
  border-radius: 4px;
  padding: 7px 12px 7px;
  margin-left: 8px;
}

.v-form {
  .d-flex {
    .v-card {
      .input-container {
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.iban-bank-container {
  max-width: 60px;
  padding: 15px;
  margin-left: 10px;
  border: 1px solid #eeeeee;
  background-color: white;
}
</style>
