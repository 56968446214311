<template>
  <v-flex v-if="isDefaultIban">
    <v-chip
      v-if="shaparakIbanStatus === 'SUCCESS'"
      style="padding: 2px 4px; height: 20px"
      color="#E3CAD3"
    >
      <span class="font_18"> حساب تسویه شاپرکی </span>
    </v-chip>
    <v-chip
      v-else-if="
        shaparakIbanStatus === 'PENDING' || shaparakIbanStatus === 'INIT'
      "
      style="padding: 2px 4px; height: 20px"
      color="yellow-orange"
    >
      <span class="font_18"> در انتظار تایید شاپرک </span>
    </v-chip>
    <v-chip
      v-else-if="shaparakIbanStatus === 'FAILED'"
      style="padding: 2px 4px; height: 20px"
      color="van_red"
    >
      <span class="font_18"> رد شده توسط شاپرک </span>
    </v-chip>
  </v-flex>
</template>

<script>
export default {
  name: "IbanShaparakChips",
  props: {
    isDefaultIban: {
      type: Boolean,
      required: true,
    },
    shaparakIbanStatus: {
      type: String,
      default: "",
    },
  },
}
</script>

<style scoped lang="scss">
.yellow-orange {
  background-color: #fcc114 !important;
}
</style>
