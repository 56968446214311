<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    class="svgs"
    :transform="navigation ? 'translate(2 0)' : 'translate(0 0)'"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fillColor ? fillColor : ''"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 2.33331L12 1.33331L11 2.33331L10 1.33331L9
    2.33331L8 1.33331L7 2.33331L6 1.33331L5 2.33331L4
    1.33331V10.6666H2V12.6666C2 13.7733 2.89333 14.6666 4 14.6666H12C13.1067
    14.6666 14 13.7733 14 12.6666V1.33331L13 2.33331ZM12.6667 12.6666C12.6667
    13.0333 12.3667 13.3333 12 13.3333C11.6333 13.3333 11.3333 13.0333 11.3333
    12.6666V10.6666H5.33333V3.33331H12.6667V12.6666ZM6
    4.66665V5.99998H10V4.66665H6ZM10.6667 4.66665H12V5.99998H10.6667V4.66665ZM6
    6.66665V7.99998H10V6.66665H6ZM10.6667
    6.66665H12V7.99998H10.6667V6.66665Z"
    />
  </svg>
</template>
<script>
export default {
  name: "CheckoutsIcon",
  props: {
    fillColor: {
      type: String,
      default: null,
    },
    navigation: {
      type: Boolean,
      default: null,
    },
  },
}
</script>
