<template>
  <ChartCardOneFilter
    class="mmt-5"
    :show-filter="true"
    :duration="duration"
    :selected-duration-prop="duration[0]"
    :title="kerdarLang.directDebit.successfulLicenseDemandRatio"
    @changeFilter="changeConversionDurationFilterName($event)"
  >
    <BarEChart
      v-if="licenseDemandRatioStatus"
      :key="counter"
      chart-id="conversion-echart"
      :x-axis-data="customerMandateCountTitle"
      :y-axis-data="successDemandValue"
      :second-y-axis-data="failedDemandValue"
      :hover-title="hoverTitle"
      :first-value-hover-subtitle="kerdarLang.general.wholeDemand"
      :second-value-hover-subtitle="kerdarLang.general.successfulDemand"
      :whole-value-hover-subtitle="kerdarLang.general.unsuccessfulDemand"
      first-bar-color="#4C9F87"
      second-bar-color="#D44D42"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </ChartCardOneFilter>
</template>

<script>
import ChartCardOneFilter from "@/components/kerdar/components/chartCardOneFilter"
import BarEChart from "@/components/kerdar/components/BarEChart"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "DirectDebitSuccessfulLicenseDemandRatioEChart",
  components: {
    ChartCardOneFilter,
    BarEChart,
  },
  props: {
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mandateConversionData: [],
      licenseDemandRatioStatus: false,
      duration: [
        {
          name: this.kerdarLang.general.basedOnDay,
          value: "based_on_day",
        },
        {
          name: this.kerdarLang.general.basedOnWeek,
          value: "based_on_week",
        },
        {
          name: this.kerdarLang.general.basedOnMonth,
          value: "based_on_month",
        },
      ],
      selectedDurationValue: "",
      counter: 0,
    }
  },
  computed: {
    customerMandateCountTitle() {
      return this.selectedDurationValue === "based_on_month"
        ? this.mandateConversionData.map((item) => item.monthOfDate)
        : this.mandateConversionData.map((item) => item.dateMinusYear)
    },
    successDemandValue() {
      return this.mandateConversionData.map((item) => item.success)
    },
    failedDemandValue() {
      return this.mandateConversionData.map((item) => item.failed)
    },
    hoverTitle() {
      return this.mandateConversionData.map((item) => item.fullDate)
    },
  },
  methods: {
    async changeConversionDurationFilterName(duration) {
      this.setSelectedDurationValue(duration)
      this.turnOffSkeleton()
      await this.getMandateConversionData(duration)
      this.updateCounter()
    },
    async getMandateConversionData(duration) {
      const response = await kerdarServices(
        this.$http,
        this
      ).directDebit.httpDirectDebitMandateConversion(duration.value)
      const {
        data: { data },
      } = response
      const manipulateResponse = JSON.parse(JSON.stringify(data))
      manipulateResponse.map((item) => {
        item.total = item.success + item.failed
        item.monthOfDate = jmoment(item.date_time).local("fa").format("jMMMM")
        item.fullDate = jmoment(item.date_time)
          .local("fa")
          .format("jD jMMMM jYYYY")
        item.dateMinusYear = jmoment(item.date_time)
          .local("fa")
          .format("jD jMMMM")
      })

      this.mandateConversionData = manipulateResponse
      this.turnOnSkeleton()
    },
    turnOnSkeleton() {
      this.licenseDemandRatioStatus = true
    },
    turnOffSkeleton() {
      this.licenseDemandRatioStatus = false
    },
    updateCounter() {
      this.counter++
    },
    setSelectedDurationValue(duration) {
      this.selectedDurationValue = duration.value
    },
  },
}
</script>

<style lang="scss" scoped></style>
