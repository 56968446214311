<template>
  <v-layout
    class="mb-3 input-container"
    :column="$vuetify.breakpoint.xsOnly"
    justify-start
    align-start
  >
    <v-flex class="label" style="width: 110px">
      {{ label }}
      <span v-if="!optional">*</span>
    </v-flex>
    <slot />
  </v-layout>
</template>

<script>
export default {
  name: "InputContainer",
  props: {
    label: {
      type: String,
      default: "",
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
