export default {
  computed: {
    reuseIsMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    reuseSmAndDownBreakpoint() {
      return this.$vuetify.breakpoint.smAndDown
    },
    reuseSmOnlyBreakpoint() {
      return this.$vuetify.breakpoint.smOnly
    },
    reuseMdAndUpBreakpoint() {
      return this.$vuetify.breakpoint.mdAndUp
    },
  },
}
