import { store } from "@/store"
import Vue from "vue"

Vue.mixin({
  computed: {
    currencyName() {
      return store.state.user.currency == "RIAL" ? "ریال" : "تومان"
    },
  },
  methods: {
    jalaliDate: function (
      date,
      format = "jYYYY/jM/jD - HH:mm:ss",
      withHourText = false,
      withTime = true
    ) {
      if (date) {
        var withSecond = true
        if (format == "jYYYY/jM/jD - HH:mm" || format == "HH:mm")
          withSecond = false
        const jmoment = require("moment-jalaali")
        var today =
          store.state.user.hasOwnProperty("current_date") &&
          store.state.user.current_date
            ? jmoment(store.state.user.current_date)
            : jmoment(new Date())

        var setDate = jmoment(date)
        const todayZeroTime = jmoment(today.format("YYYY/MM/DD"))
        const setDateZeroTime = jmoment(setDate.format("YYYY/MM/DD"))
        var diff = todayZeroTime.diff(setDateZeroTime, "days")

        if (diff == 1) {
          return withTime
            ? withSecond
              ? "دیروز" + " ساعت " + setDate.format("HH:mm:ss")
              : "دیروز" + " ساعت " + setDate.format("HH:mm")
            : "دیروز"
        }
        if (diff == 0) {
          return withTime
            ? withSecond
              ? "امروز" + " ساعت " + setDate.format("HH:mm:ss")
              : "امروز" + " ساعت " + setDate.format("HH:mm")
            : "امروز"
        }
        if (diff == -1) {
          return withTime
            ? withSecond
              ? "فردا" + " ساعت " + setDate.format("HH:mm:ss")
              : "فردا" + " ساعت " + setDate.format("HH:mm")
            : "فردا"
        }
        if (withHourText) {
          const dateFormat = format.split(" - ")[0]
          const timeFormat = format.split(" - ")[1]
          return setDate.format(dateFormat + " ساعت " + timeFormat)
        }
        return setDate.format(format)
      }
    },
    delay: function (timeOut, func) {
      clearTimeout(timeOut)
      return setTimeout(func, 700)
    },
    numberKeyHandler: function (event) {
      if (
        !/(^[0-9۰-۹Vvرؤ]*$)/.test(event.key) &&
        event.key !== "Backspace" &&
        (event.key !== "Tab") & (event.key !== "Enter")
      ) {
        event.preventDefault()
      }
    },
    isPersian(event) {
      for (var i = 0; i < event.length; i++) {
        var ev = this.convertNumbers2English(event.charAt(i))

        if (
          !/^[\u0600-\u06FF 0-9 \u200c\s]+$/.test(ev) &&
          ev !== "Backspace" &&
          (ev !== "Tab") & (ev !== "Enter")
        ) {
          return false
        }
      }
      return true
    },
    persianKeyHandler: function (event) {
      var ev = this.convertNumbers2English(event.key)
      if (
        !/^[\u0600-\u06FF 0-9\s]+$/.test(ev) &&
        ev !== "Backspace" &&
        (ev !== "Tab") & (ev !== "Enter")
      ) {
        event.preventDefault()
      }
    },
    convertNumbers2English: function (string) {
      return string
        .replace(/[\u0660-\u0669]/g, function (c) {
          return c.charCodeAt(0) - 0x0660
        })
        .replace(/[\u06f0-\u06f9]/g, function (c) {
          return c.charCodeAt(0) - 0x06f0
        })
    },
    keepOnlyDigits(amount) {
      return (
        Number(this.convertNumbers2English(amount).replace(/[^0-9.]/g, "")) ??
        null
      )
    },
    arabicCharacterToPersian: function (string) {
      return string
        .replace(/ي/g, function (c) {
          return "ی"
        })
        .replace(/ك/g, function (c) {
          return "ک"
        })
    },
  },
})
