import "material-design-icons-iconfont/dist/material-design-icons.css"
import Vue from "vue"
import Vuetify from "vuetify"
// import variable from '../assets/sass/abstracts/_exportVariables.scss'

const themeOpt = {
  primary: "#4c9f87", //van_green
  van_green: "#4c9f87",
  van_green_shade_1: "#edf5f3",
  van_green_shade_2: "#EDF5F3",
  van_dark: "#3c3b39",
  van_pink: "#a14e69",
  van_red: "#d44d42",
  van_color00: "#000000",
  van_color01: "#212529",
  van_color02: "#333333",
  van_color03: "#666666",
  van_color04: "#999999",
  van_color05: "#bbbbbb",
  van_color06: "#e5e5e5",
  van_color07: "#e1e1e1",
  van_color08: "#f1f1f1",
  van_color09: "#f0f0f0",
  van_color10: "#eeeeee",
  van_color11: "#f5f5f5",
  van_color12: "#fafafa",
  van_color13: "#ffffff",
  van_color15: "#f7dbd9",
  van_color16: "#ece4cb",
  van_color17: "#eee0b4",
}

const opts = {
  rtl: true,
  theme: {
    dark: false,
    themes: {
      light: themeOpt,
      dark: themeOpt,
    },
  },
  lang: {
    locales: {
      fa: {
        dataIterator: {
          rowsPerPageText: "تعداد در هر صفحه",
          rowsPerPageAll: "همه",
          pageText: "{0}-{1} of {2}",
          noResultsText: "محتوایی پیدا نشد",
          nextPage: "صفحه بعد",
          prevPage: "صفحه قبل",
        },
        dataTable: {
          rowsPerPageText: "تعداد سطر در هر صفحه",
        },
        noDataText: "اطلاعاتی برای نمایش موجود نیست",
      },
    },
  },
  noDataText: "اطلاعاتی برای نمایش موجود نیست",
  icons: {
    iconfont: "md", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
}

Vue.use(Vuetify)

export default new Vuetify(opts)
