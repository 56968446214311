<template>
  <LeftModal
    :toolbar-name="accountData.name"
    @closeModal="$emit('close-modal')"
  >
    <div slot="toolbarItems">
      <v-btn
        ref="btn"
        text
        dark
        outlined
        class="btn_small_normal"
        @click="openEditAccountNameModal"
      >
        ویرایش نام حساب
      </v-btn>
    </div>
    <div class="account-details-general radius-4">
      <v-row>
        <v-col class="d-flex align-center">
          <v-img
            v-if="accountData?.iban"
            width="30"
            max-height="30"
            max-width="30"
            height="30"
            class="ml-2"
            :src="
              require(`@/assets/img/banks/${parseInt(
                accountData?.iban?.slice(4, 7)
              )}.svg`)
            "
          />
          <span class="font_22">
            {{ accountData.name }}
          </span>
        </v-col>
        <v-col
          v-if="!accountData?.bank_status"
          class="d-flex align-center justify-end"
        >
          <span class="font-11-no-333-17">اختلال موقت بانک </span>
          <v-img
            width="20"
            height="20"
            max-height="20"
            max-width="20"
            class="mr-1"
            :src="require('@/assets/img/error-warning.svg')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex align-center justify-space-between background-color radius-4"
        >
          <span class="font_30"> موجودی کل </span>
          <div>
            <span class="font_22">
              {{ balanceAmountWithThousandSeparator(accountData.balance) }}
            </span>
            <span class="font-11-no-000-18">
              {{ currencyName }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex align-center justify-space-between background-color radius-4 mt-2"
        >
          <span class="font_30"> قابل برداشت </span>
          <div>
            <span class="font_22">
              {{
                balanceAmountWithThousandSeparator(
                  accountData.available_balance
                )
              }}
            </span>
            <span class="font-11-no-000-18">
              {{ currencyName }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="account-details-general radius-4 mt-4">
      <v-row>
        <v-col
          class="d-flex align-center justify-space-between font-12-no-000-24"
        >
          <span> شماره شبا </span>
          <span class="d-flex align-center">
            {{ accountData.iban }}

            <CopyBtn
              class="mr-3"
              :input-id="'item' + accountData.iban"
              :value="accountData.iban"
            />
          </span>
        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>
      <v-row>
        <v-col
          class="d-flex align-center justify-space-between font-12-no-000-24"
        >
          <span> شماره حساب </span>
          <span class="d-flex align-center">
            {{ accountData.account }}
            <CopyBtn
              class="mr-3"
              :input-id="'item' + accountData.account"
              :value="accountData.account"
            />
          </span>
        </v-col>
      </v-row>
    </div>
    <div class="account-details-general radius-4 mt-4">
      <v-row>
        <v-col>
          <div class="d-flex align-center">
            <v-img
              width="20"
              height="20"
              max-height="20"
              max-width="20"
              class="ml-2"
              :src="require('@/assets/img/moneyHint.svg')"
            />
            <span class="font_22"> جزییات سقف بانک </span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="third-part-child radius-4 pa-5 mt-4">
        <v-col cols="4">
          <span class="font-12-bo-333-18"> برداشت پایا </span>
        </v-col>
        <v-col cols="8">
          <v-row dense>
            <v-col
              class="d-flex align-center justify-space-between font-11-no-333-17"
            >
              <span>سقف روزانه</span>
              <span v-if="hasAchDailyValue">
                {{
                  balanceAmountWithThousandSeparator(achDailyWithdrawalLimit)
                }}
                {{ currencyName }}
              </span>
              <span v-else class="ml-3"> - </span>
            </v-col>
          </v-row>
          <v-divider class="my-2" style="border-style: dashed"></v-divider>
          <v-row dense>
            <v-col
              class="d-flex align-center justify-space-between font-11-no-333-17"
            >
              <span>سقف ماهانه</span>
              <span v-if="hasAchMonthlyValue">
                {{
                  balanceAmountWithThousandSeparator(achMonthlyWithdrawalLimit)
                }}
                {{ currencyName }}
              </span>
              <span v-else class="ml-3"> - </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class="third-part-child radius-4 pa-5 mt-4">
        <v-col cols="4">
          <span class="font-12-bo-333-18"> برداشت آنی </span>
        </v-col>
        <v-col cols="8">
          <v-row dense>
            <v-col
              class="d-flex align-center justify-space-between font-11-no-333-17"
            >
              <span>سقف روزانه</span>
              <span v-if="hasA2aDailyValue">
                {{
                  balanceAmountWithThousandSeparator(a2aDailyWithdrawalLimit)
                }}
                {{ currencyName }}
              </span>
              <span v-else class="ml-3"> - </span>
            </v-col>
          </v-row>
          <v-divider class="my-2" style="border-style: dashed"></v-divider>
          <v-row dense>
            <v-col
              class="d-flex align-center justify-space-between font-11-no-333-17"
            >
              <span>سقف ماهانه</span>
              <span v-if="hasA2aMonthlyValue">
                {{
                  balanceAmountWithThousandSeparator(a2aMonthlyWithdrawalLimit)
                }}
                {{ currencyName }}
              </span>
              <span v-else class="ml-3"> - </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="editAccountNameModal"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="reuseIsMobile ? '100%' : '600'"
      no-click-animation
      :fullscreen="reuseIsMobile"
      @click:outside="closeModal"
    >
      <AccountNameEdition
        v-if="editAccountNameModal"
        :account="accountData"
        @edit-account-name="accountData.name = $event"
        @close-modal="closeModal"
      />
    </v-dialog>
  </LeftModal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal"
import AccountNameEdition from "./AccountNameEdition"
import CopyBtn from "@/components/report/elements/show/details/copyBtn.vue"
import screenSize from "@/mixins/screenSize.js"
import currencyConvert from "@/mixins/currencyConvert.js"

export default {
  name: "AccountDetails",
  components: {
    LeftModal,
    CopyBtn,
    AccountNameEdition,
  },
  mixins: [screenSize, currencyConvert],
  props: {
    account: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editAccountNameModal: false,
      accountData: {},
    }
  },
  computed: {
    achDailyWithdrawalLimit() {
      return this.accountData.withdrawal_daily_ach_limit
    },
    achMonthlyWithdrawalLimit() {
      return this.accountData.withdrawal_monthly_ach_limit
    },
    a2aDailyWithdrawalLimit() {
      return this.accountData.withdrawal_daily_a2a_limit
    },
    a2aMonthlyWithdrawalLimit() {
      return this.accountData.withdrawal_monthly_a2a_limit
    },
    hasAchDailyValue() {
      return this.accountData.withdrawal_daily_ach_limit !== null
    },
    hasAchMonthlyValue() {
      return this.accountData.withdrawal_monthly_ach_limit !== null
    },
    hasA2aDailyValue() {
      return this.accountData.withdrawal_daily_a2a_limit !== null
    },
    hasA2aMonthlyValue() {
      return this.accountData.withdrawal_monthly_a2a_limit !== null
    },
  },
  mounted() {
    this.accountData = { ...this.account }
  },
  methods: {
    balanceAmountWithThousandSeparator(balanceAmount) {
      const convertedAmount =
        this.reuseConvertCurrencyForRialAmount(balanceAmount)
      return this.$options.filters.thousandSeprator(convertedAmount)
    },
    openEditAccountNameModal() {
      this.editAccountNameModal = true
      this.changeAccountDetailsSecondModal(true)
    },
    closeModal() {
      this.editAccountNameModal = false
      this.changeAccountDetailsSecondModal(false)
    },
    changeAccountDetailsSecondModal(state) {
      this.$store.commit("changeAccountDetailsSecondModal", state)
    },
  },
}
</script>

<style lang="scss" scoped>
.account-details-general {
  border: 1px solid #e5e5e5;
  padding: 24px;
  background: #fff;
}

.third-part-child {
  border: 1px solid #f5f5f5;
}

.background-color {
  background: #f5f5f5;
}

.radius-4 {
  border-radius: 4px;
}
</style>
