<template>
  <div
    v-if="pendingStatus || businessHasRejected || !isAllDocsComplete"
    class="activationBlock py-6 px-2"
  >
    <span :class="backGroundColorBasedOnStatus" />
    <v-layout v-if="pendingStatus" class="mx-auto" style="margin: -20px">
      <v-flex class="personal_title alldone">
        <v-layout
          justify-space-between
          align-center
          :class="{
            'd-flex flex-column justify-start': $vuetify.breakpoint.xsOnly,
          }"
        >
          <v-flex>
            <v-layout class="d-flex align-center">
              <v-flex style="max-width: 60px">
                <v-img
                  :src="require('@/assets/img/newIcon/pendingBusiness.svg')"
                  alt="avatar"
                  width="50"
                />
              </v-flex>
              <v-flex>
                <div
                  v-if="
                    $store.state.business.accept_business_time_prediction !=
                    'call operation'
                  "
                  class="font_5"
                >
                  در حال بررسی اطلاعات و مدارک کسب‌و‌کار
                </div>
                <div v-else class="font_5" style="line-height: 22px">
                  بررسی «حساب کسب‌و‌کار» شما بیش از زمان تعیین شده طول کشیده
                  است. با عرض پوزش، به سرعت در حال پیگیری هستیم
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="
              $store.state.business.accept_business_time_prediction !=
              'call operation'
            "
            class="d-flex align-center justify-end"
          >
            <div
              v-if="
                $store.state.business.accept_business_time_prediction &&
                $store.state.business.accept_business_time_prediction.includes(
                  ':'
                ) &&
                time != 0
              "
              :class="{
                'd-flex flex-row align-center justify-end ':
                  $vuetify.breakpoint.smAndUp,
                'd-flex flex-column': $vuetify.breakpoint.xsOnly,
              }"
            >
              <span
                class="font_18 ml-1"
                :class="{ 'align-self-center': $vuetify.breakpoint.smAndUp }"
              >
                حداکثر زمان انتظار:
              </span>
              <span
                class="font_12"
                :class="{ 'align-self-center': $vuetify.breakpoint.smAndUp }"
                style="
                  font-size: 20px !important;
                  color: #333 !important;
                  display: inline-block;
                  width: 90px;
                "
              >
                {{ convertToTime() }}
              </span>
            </div>
            <div v-else style="text-align: left" class="font_7">
              در انتظار تایید وندار
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout v-else :align-center="$vuetify.breakpoint.smAndUp">
      <v-img
        v-if="businessHasRejected"
        width="100px"
        height="100px"
        max-width="100px"
        max-height="100px"
        class="ml-4"
        :class="{ 'd-none': $vuetify.breakpoint.xsOnly }"
        :src="require('@/assets/img/soft-warning.svg')"
      />
      <v-img
        v-else
        width="100px"
        height="100px"
        max-width="100px"
        max-height="100px"
        class="ml-4"
        :class="{ 'd-none': $vuetify.breakpoint.xsOnly }"
        :src="require('@/assets/img/not-complete.svg')"
      />
      <v-flex
        class="soft-reject-banner"
        :class="{ 'flex-column flex-start': $vuetify.breakpoint.xsOnly }"
      >
        <section :class="{ 'mb-2': $vuetify.breakpoint.xsOnly }">
          <template v-if="businessHasRejected">
            <h1 class="soft-reject-banner__title">
              {{ businessHomeLang?.correctionOfBusinessInfo }}
            </h1>
            <span class="soft-reject-banner__subtitle">
              {{ businessHomeLang?.correctionOfBusinessInfoSubtitle }}
            </span>
          </template>
          <template v-else>
            <h1 class="soft-reject-banner__title">
              {{ businessHomeLang?.completeBusinessInfo }}
            </h1>
            <span class="soft-reject-banner__subtitle">
              {{ businessHomeLang?.completeBusinessInfoSubtitle }}
            </span>
          </template>
        </section>
        <v-btn
          color="white"
          width="126"
          height="48"
          elevation="0"
          class="pa-3 mr-0"
          style="border: 1px solid #999999 !important"
          @click="openModal"
        >
          <span class="font-14-no-666-24">
            {{
              businessHasRejected
                ? businessHomeLang?.correction
                : businessHomeLang?.completion
            }}
          </span>
          <v-img
            width="18px"
            height="18px"
            max-height="18px"
            max-width="18px"
            class="mr-2"
            :src="require('@/assets/img/Arrow.svg')"
          />
        </v-btn>
        <v-dialog
          v-model="modalStatus"
          :content-class="
            $store.state.secondAddDocumentmodal
              ? 'thirdModal vanmodal'
              : secondModalStatus
              ? 'secondModal vanmodal'
              : 'vanmodal'
          "
          transition="slide-x-transition"
          :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
          :fullscreen="$vuetify.breakpoint.xsOnly"
          no-click-animation
          @click:outside="closeModals"
        >
          <SoftRejectItems
            v-show="softRejectItemsModal"
            :toolbar-title="determineModalTitle"
            @second-modal-status="($event) => (secondModalStatus = $event)"
            @closeModal="closeModals"
          ></SoftRejectItems>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import SoftRejectItems from "../modals/SoftRejectItems.vue"
import { mapState } from "vuex"

export default {
  name: "BusinessActivationBlock",
  components: {
    SoftRejectItems,
  },
  data() {
    return {
      modalStatus: false,
      time: 0,
      businessHomeLang: null,
      softRejectItemsModal: false,
      secondModalStatus: false,
    }
  },
  computed: {
    ...mapState("newbusiness", [
      "isOnboardingThirdStepDone",
      "isOnboardingFourthStepDone",
    ]),
    pendingStatus() {
      return (
        ((this.legalDocumentHandler("accepted") &&
          this.$store.state.business.legal_business_name &&
          this.$store.state.business.national_id) ||
          (this.businessType === "personal" &&
            Object.prototype.hasOwnProperty.call(
              this.$store.state.business,
              "statusBox"
            ) &&
            this.businessStatusBox.national_card_photo === "accepted")) &&
        this.$store.state.business.postal_code &&
        !this.$store.state.business.rejects.phone_number &&
        this.$store.state.business.address_detail.address &&
        this.$store.state.business.business_name_fa
      )
    },
    backGroundColorBasedOnStatus() {
      return this.pendingStatus
        ? "topBack pendingTopBack"
        : this.businessHasRejected
        ? "topBack "
        : "topBack not-complete-state"
    },
    businessHasRejected() {
      return this.$store.state.business.has_rejection
    },
    businessStatusBox() {
      return this.$store.state.business.statusBox
    },
    businessType() {
      return this.$store.state.business.business_type
    },
    determineModalTitle() {
      return this.businessHasRejected
        ? this.businessHomeLang?.correctionOfBusinessInfo
        : this.businessHomeLang?.completeBusinessInfo
    },
    isAllDocsComplete() {
      return this.isOnboardingThirdStepDone && this.isOnboardingFourthStepDone
    },
  },
  mounted() {
    this.businessActiveCountDown()
    this.getBusinessHomeLang()
  },
  methods: {
    convertToTime() {
      var date = new Date(null)
      if (!this.time) this.time = 0
      date.setSeconds(this.time) // specify value for SECONDS here
      var hour = parseInt(this.time / 3600)
      var result = date.toISOString().substr(13, 6)
      result = hour == 0 ? "00" + result : hour + "" + result
      return result
    },
    legalDocumentHandler(status) {
      return (
        this.businessType === "legal" &&
        Object.prototype.hasOwnProperty.call(
          this.$store.state.business,
          "statusBox"
        ) &&
        this.businessStatusBox.introduction_letter === status &&
        this.businessStatusBox.national_card_photo === status &&
        this.businessStatusBox.official_Newspaper === status
      )
    },
    businessActiveCountDown() {
      var secondTime = 0
      var startTime = this.$store.state.business.accept_business_time_prediction
        ? this.$store.state.business.accept_business_time_prediction
        : "00:00:00"
      var sec = parseInt(startTime.split(":")[2])
      var min = parseInt(startTime.split(":")[1])
      var hour = parseInt(startTime.split(":")[0])

      secondTime = hour * 3600 + min * 60 + sec

      this.countDownFunc(secondTime)
    },
    countDownFunc(startPoint) {
      var downloadTimer = setInterval(() => {
        startPoint -= 1
        if (startPoint <= 0) {
          clearInterval(downloadTimer)
        }
        this.time = startPoint
      }, 1000)
    },
    closeModals() {
      this.modalStatus = false
      this.softRejectItemsModal = false
    },
    async getBusinessHomeLang() {
      const lang = await import("@/lang/languageResource.json")
      this.businessHomeLang = lang.businessHome
    },
    openModal() {
      this.softRejectItemsModal = true
      this.modalStatus = true
    },
  },
}
</script>

<style lang="scss" scoped>
.soft-reject-banner {
  z-index: 0;
  display: flex;
  justify-content: space-between;

  &__title {
    font-size: 20px;
    color: $van_color02;
    line-height: 30px;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-size: 11px;
    font-weight: normal;
    color: $van_color02;
    line-height: 17px;
  }
}
</style>
