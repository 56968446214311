<template>
  <ChartCardWrapper
    class="mmt-5"
    :title="kerdarLang.overView.turnOver"
    :show-filter="true"
    :selected-custom-duration="defaultSelectedDuration"
    @changeFilter="changeTurnoverChartFilter($event)"
  >
    <LineEChart
      v-if="turnoverStatus"
      :key="updateKeyCounter"
      chart-id="overview-turn-over"
      :chart-name="kerdarLang.general.income"
      :chart-name-second="kerdarLang.general.withdrawal"
      :x-axis-data="xValueTurnover"
      :y-axis-data="calculateFirstYaxisValueTurnover()"
      :y-axis-data-second="yValueTurnover2()"
      :date-time-list="timeTurnover"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </ChartCardWrapper>
</template>

<script>
import ChartCardWrapper from "@/components/kerdar/components/ChartCardWrapper"
import LineEChart from "@/components/kerdar/components/LineEChart"
import {
  minNumbersLength,
  generateWeekDaysV2,
  generateMonthsV2,
  generateMonthsWithYearV2,
  changeHorizontalWeeklyFirstDayV2,
} from "@/components/kerdar/utils/generalMethods.js"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "OverViewTurnOversEChartApiV2",
  components: {
    ChartCardWrapper,
    LineEChart,
  },
  props: {
    kerdarLang: {
      type: Object,
      default: () => {},
    },
    defaultSelectedDuration: {
      type: Object,
      default: () => {
        return {
          name: "ماه جاری",
          value: "current_month",
        }
      },
    },
  },
  data() {
    return {
      turnoverData: {},
      turnoverStatus: false,
      minAllTurnoverNumberLength: 0,
      turnoverFilter: null,
      updateKeyCounter: 0,
    }
  },
  computed: {
    xValueTurnover() {
      if (
        this.turnoverFilter &&
        ["monthly", "hourly"].includes(this.turnoverFilter.timePeriod)
      ) {
        let xValue = []
        switch (this.turnoverFilter.timePeriod) {
          case "monthly":
            generateMonthsV2(xValue, this.turnoverData.incomes)
            break
          case "hourly":
            for (let element of this.turnoverData.incomes) {
              xValue.push(jmoment(element.date_time).format("HH"))
            }
            break
        }
        return xValue
      }

      return this.turnoverData.incomes.map((item) => item.dateWithoutYear)
    },
    timeTurnover() {
      if (
        this.turnoverFilter &&
        ["weekly", "monthly", "hourly"].includes(this.turnoverFilter.timePeriod)
      ) {
        let times = []
        switch (this.turnoverFilter.timePeriod) {
          case "weekly":
            generateWeekDaysV2(times, this.turnoverData.incomes)
            break
          case "monthly":
            generateMonthsWithYearV2(times, this.turnoverData.incomes)
            break
          case "hourly":
            for (let element of this.turnoverData.incomes) {
              times.push("ساعت " + jmoment(element.date_time).format("H"))
            }
            break
        }

        return times
      }
      return this.turnoverData.incomes.map((item) => item.dateWithYear)
    },
  },
  watch: {
    currencyName() {
      this.updateKeyCounter++
    },
  },
  methods: {
    changeMinTurnoverLength(min) {
      this.minAllTurnoverNumberLength = min
    },
    changeTurnoverChartFilter(item) {
      this.turnoverFilter = item
      this.clearTurnoverData()
      this.getTurnoverData(item)
    },
    clearTurnoverData() {
      this.turnoverStatus = false
      this.turnoverData = {}
      this.minAllTurnoverNumberLength = 0
    },
    async getTurnoverData(item) {
      const chartType = item.duration + "_" + item.timePeriod
      if (!chartType) {
        return
      }
      const response = await kerdarServices(
        this.$http,
        this
      ).overView.httpOverViewTurnOversApiV2(item)

      const {
        data: { data },
      } = response

      this.updateKeyCounter++

      changeHorizontalWeeklyFirstDayV2(data.incomes)
      this.turnoverData = data
      this.turnoverStatus = true
    },
    calculateFirstYaxisValueTurnover() {
      let amounts1 = this.turnoverData.incomes.map((item) => {
        return this.$store.state.user.currency == "RIAL"
          ? item.value
          : item.value / 10
      })
      let amounts2 = this.turnoverData.outcomes.map((item) => {
        return this.$store.state.user.currency == "RIAL"
          ? item.value
          : item.value / 10
      })
      let allAmounts = [].concat(amounts1, amounts2)
      const minLength = minNumbersLength(allAmounts)
      this.changeMinTurnoverLength(minLength)

      return amounts1
    },
    yValueTurnover2() {
      let amounts = this.turnoverData.outcomes.map((item) => {
        return this.$store.state.user.currency == "RIAL"
          ? item.value
          : item.value / 10
      })

      return amounts
    },
  },
}
</script>

<style lang="scss" scoped></style>
