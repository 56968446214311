<template>
  <v-flex>
    {{ item.value }}
  </v-flex>
</template>

<script>
export default {
  props: ["item"],
}
</script>
