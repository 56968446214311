<template>
  <general-card custom-card-style="padding: 16px !important;">
    <div class="d-flex align-center">
      <div class="font-size-11">
        <div class="font-weight-bold">
          <v-skeleton-loader :width="reuseIsMobile ? 160 : 220" type="text" />
        </div>
        <div class="van_color05--text my-2">
          <v-skeleton-loader :width="reuseIsMobile ? 185 : 300" type="text" />
        </div>
        <v-divider class="van_color11 mb-2" />
        <div class="van_color05--text d-flex">
          <v-skeleton-loader :width="reuseIsMobile ? 160 : 220" type="text" />
        </div>
      </div>
      <v-spacer />
      <div class="d-flex flex-column">
        <v-btn disabled dark text class="btn_small_normal"></v-btn>
        <v-btn disabled outlined text class="btn_small_normal"></v-btn>
      </div>
    </div>
  </general-card>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import screenSize from "@/mixins/screenSize"

export default {
  name: "SuspiciousItemSkeleton",
  components: { GeneralCard },
  mixins: [screenSize],
}
</script>

<style scoped></style>
