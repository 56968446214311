<template>
  <v-layout column wrap justify-center align-start>
    <v-flex class="mb-7" style="width: 100%">
      <vandarTable />
    </v-flex>
  </v-layout>
</template>

<script>
import vandarTable from "../elements/vandarTable.vue"

export default {
  name: "Index",
  components: {
    vandarTable,
  },
}
</script>

<style lang="scss"></style>
