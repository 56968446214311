<template>
  <div>
    <div
      v-for="(data, index) in findMessages"
      :key="index"
      class="showModalBoxesContainer"
    >
      <v-flex
        v-if="!data.revised || transactionInfo.item.revised_transaction_id"
        class="showModalBoxes"
      >
        <v-layout class="d-flex align-center">
          <v-flex align-center class="d-flex ml-2 align-center">
            <v-img
              v-if="data.icon"
              size="30"
              width="30"
              height="30"
              :src="require('@/assets/img/' + data.icon)"
            />
          </v-flex>

          <v-flex class="font_2" style="text-align-last: right">
            <div v-if="data.title" class="bold">
              {{ data.title }}
            </div>

            <span
              v-on="
                data.revised || transactionInfo.refund.data?.length
                  ? { click: clickHandler }
                  : {}
              "
              v-html="data.msg"
            />
          </v-flex>
          <v-flex v-if="data.moreDetails">
            <a
              class="linkBorder text--van_green font-size-11"
              @click="moreDetailsClick(data.moreDetails)"
              >اطلاعات بیشتر</a
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </div>
  </div>
</template>

<script>
import jmoment from "moment-jalaali"

export default {
  props: ["transactionInfo"],
  computed: {
    jtime() {
      const jmoment = require("moment-jalaali")

      if (
        ["settlement_tashim_pending", "settlement_refund_pending"].includes(
          this.transactionInfo.statusChannel
        )
      ) {
        return (
          "ساعت " +
          jmoment(this.transactionInfo.timing, "HH:mm:ss - jYYYY/jM/jD").format(
            "HH:mm"
          )
        )
      }

      //else
      const timePrediction = jmoment(
        this.transactionInfo.timing,
        "HH:mm:ss - jYYYY/jM/jD"
      )
      return this.jalaliDate(timePrediction, "jYYYY/jM/jD - HH:mm", true)
    },
    settlementDoneTimePrediction() {
      const timePrediction = jmoment(
        this.transactionInfo.time_prediction.settlement_done_time_prediction,
        "HH:mm:ss - jYYYY/jM/jD"
      )
      return this.jalaliDate(timePrediction, "jYYYY/jM/jD - HH:mm", true)
    },
    findMessages() {
      let arr = []

      // front messages
      let frontMsgs =
        this.messageBoxArray[this.transactionInfo.statusChannel] ?? []

      if (frontMsgs.length !== 0) {
        frontMsgs.messages.forEach((element) => {
          arr.push({
            msg: element.msg,
            icon: element.icon,
            revised: element.revised ?? false,
          })
        })
      }

      //is shaparak port
      if (
        [
          "transaction_requestMoney_successful",
          "transaction_ipg_successful",
        ].includes(this.transactionInfo.statusChannel) &&
        this.transactionInfo.item.is_shaparak_port
      ) {
        arr.push({
          icon: "newIcon/ipg-square.svg",
          msg: `مبلغ این تراکنش به حساب تجمیعی شاپرک واریز شده است.`,
          moreDetails: "shaparak",
        })
      }

      //subscription messages
      if (
        this.transactionInfo.withdrawDetail &&
        this.transactionInfo.statusChannel ==
          "transaction_directDebit_unsuccessful"
      ) {
        let subscriptionMessages =
          this.transactionInfo.withdrawDetail.error_message

        if (!subscriptionMessages.endsWith(".")) subscriptionMessages += "."
        arr.push({
          msg: subscriptionMessages,
          icon: "Failed.svg",
        })
      }

      //pbv messages
      if (
        this.transactionInfo.pbvDetail &&
        this.transactionInfo.statusChannel == "transaction_pbv_unsuccessful" &&
        this.transactionInfo.item.description
      ) {
        let subscriptionMessages = this.urlReplacer(
          this.transactionInfo.item.description
        )

        arr.push({
          msg: subscriptionMessages,
          icon: "Failed.svg",
        })
      }

      //transaction with refund
      if (this.transactionInfo.refund.data?.length) {
        let refundMessage = ` مبلغ
        ${this.$options.filters.thousandSeprator(
          this.transactionInfo.refund.data[0].price
        )}
         تومان از این واریز، طی تراکنشی به شناسه
         <a class="revisedId" >${this.transactionInfo.refund.data[0].id}</a>
          در تاریخ
         ${this.transactionInfo.refund.data[0].created_at}
          به حساب مبدا بازگشت داده شده است.`
        arr.push({
          msg: refundMessage,
          icon: "refund-message.svg",
        })
      }

      //log messages
      let backMsgs = this.parseLog ?? []

      if (backMsgs.length != 0) {
        backMsgs.forEach((element) => {
          arr.push({
            title: element.group,
            msg: element.result_message,
            icon: "Failed.svg",
          })
        })
      }

      return arr
    },
    p2pstatusMessage() {
      let msg = ""
      if (
        this.transactionInfo.hasTimePrediction &&
        this.transactionInfo.p2p.secondAmount == 0 &&
        this.transactionInfo.p2p.thirdAmount == 0
      ) {
        msg = `کل مبلغ در ${this.transactionInfo.p2p.instantDate} قابل برداشت است `
      } else {
        msg = ""
        if (this.transactionInfo.p2p.instantAmount > 0) {
          msg += this.p2pPartialMessage(
            this.transactionInfo.p2p.instantAmount,
            "",
            " در لحظه و "
          )
        }
        if (this.transactionInfo.p2p.secondAmount > 0) {
          msg += this.p2pPartialMessage(
            this.transactionInfo.p2p.secondAmount,
            this.transactionInfo.p2p.secondDate,
            " در "
          )
        }
        if (
          this.transactionInfo.p2p.secondAmount > 0 &&
          this.transactionInfo.p2p.thirdAmount > 0
        ) {
          msg += " و "
        }
        if (this.transactionInfo.p2p.thirdAmount > 0) {
          msg += this.p2pPartialMessage(
            this.transactionInfo.p2p.thirdAmount,
            this.transactionInfo.p2p.thirdDate,
            " در "
          )
        }

        msg += " قابل برداشت است"
      }
      return msg
    },
    parseLog() {
      var logs = []
      if (this.transactionInfo.item.logs.length != 0) {
        logs = this.transactionInfo.item.logs.filter((element) => {
          return element.group != null && element.result_message != null
        })
      }
      return logs
    },
    settlementTashimPendingMsg() {
      if (this.transactionInfo.time_prediction?.is_admin_check_status)
        return "به علت اختلال بانک، زمان تخمینی واریز در حال محاسبه است."

      if (this.transactionInfo.time_prediction?.has_change_port_to_paya)
        return "تراکنش به علت اختلال بانک به صورت پایا انجام می‌شود. زمان تخمینی واریز به‌روزرسانی شد."

      if (this.transactionInfo.time_prediction?.has_change_done_time_prediction)
        return "به علت اختلال بانک، زمان تخمینی واریز به‌روزرسانی شد."

      if (!this.transactionInfo.time_prediction?.is_cancelable)
        return "تراکنش در صف بانک است."

      if (this.transactionInfo.time_prediction?.is_after_cancelable) {
        return "تراکنش در صف بانک است."
      }
      return `تراکنش تا ${this.jtime} امکان لغو دارد. پس از آن به بانک ارسال می‌شود.`
    },
    transactionCashInMessage() {
      if (this.transactionInfo.is_auto_cash_in === true) {
        return "شارژ کیف‌پول از طریق واریز بانکی به صورت خودکار با موفقیت انجام شد."
      } else if (this.transactionInfo.is_auto_cash_in === false) {
        return "شارژ کیف‌پول از طریق واریز بانکی به صورت دستی با موفقیت انجام شد."
      } else {
        return ""
      }
    },
    messageBoxArray() {
      const message1 = `این برداشت جهت «بازگشت وجه» واریز موفق به شناسه یکتای <a class="revisedId" >${this.transactionInfo.item.revised_transaction_id}</a> ایجاد شده است.`

      const messageList = {
        settlement_tashim_pending: {
          messages: [
            {
              icon: this.transactionInfo.time_prediction?.is_after_cancelable
                ? "newIcon/info.svg"
                : "Pending.svg",
              msg: this.settlementTashimPendingMsg,
            },
          ],
        },
        settlement_refund_pending: {
          messages: [
            {
              icon: "Pending.svg",
              msg: `درخواست بازگشت وجه تا ${this.jtime} به بانک ارسال می‌شود.`,
            },
          ],
        },
        settlement_refund_returned: {
          messages: [
            {
              icon: "newIcon/failed-circle.svg",
              msg: this.message2("شما"),
              revised: true,
            },
            {
              msg: "تراکنش به علت مشکل در حساب مقصد، برگشت خورد.",
              icon: "newIcon/warning-circle.svg",
            },
          ],
        },
        settlement_tashim_unsuccessful: {
          messages: [
            {
              icon: "Failed.svg",
              msg: `طبق گزارش پایا، این برداشت ناموفق بوده است.`,
            },
          ],
        },
        settlement_p2p_successful: {
          messages: [{ icon: "secondCheck.svg", msg: this.p2pstatusMessage }],
        },
        settlement_tashim_successful_port1: {
          messages: [
            {
              icon: "secondCheck.svg",
              msg: `طبق آخرین استعلام، تراکنش با موفقیت انجام شده است و در ${this.settlementDoneTimePrediction} به حساب مقصد واریز شده است`,
            },
          ],
        },
        settlement_tashim_successful_port2: {
          messages: [
            { icon: "secondCheck.svg", msg: "تراکنش با موفقیت انجام شد." },
          ],
        },
        settlement_tashim_successful_first: {
          messages: [
            {
              icon: "1stCheck.svg",
              msg: `طبق آخرین استعلام، تراکنش با موفقیت انجام شده است و تا ${this.settlementDoneTimePrediction} به حساب مقصد واریز می شود`,
            },
          ],
        },
        settlement_tashim_successful_final: {
          messages: [
            {
              icon: "secondCheck.svg",
              msg: "تراکنش با موفقیت انجام شد.",
            },
          ],
        },
        settlement_refund_successful: {
          messages: [
            {
              icon: "newIcon/Done.svg",
              msg: message1,
              revised: true,
            },
          ],
        },
        settlement_subscriptionReturned_successful: {
          messages: [
            {
              revised: true,
              icon: "newIcon/Union.svg",
              msg: message1,
            },
          ],
        },
        transaction_directDebit_successful: {
          messages: [
            {
              revised: true,
              icon: "newIcon/Correction.svg",
              msg: `از آنجایی که روی این واریز، عملیات «بازگشت وجه» انجام شده است، تراکنشی به شناسه یکتای <a  class="revisedId" >${this.transactionInfo.item.revised_transaction_id}</a> ایجاد شده که این مبلغ را به حساب مبدا بازگردانیده است.`,
            },
          ],
        },
        settlement_tashim_returned: {
          messages: [
            {
              msg: `این تراکنش لغو شده‌است. مبلغ آن طی تراکنش <a  class="revisedId" >${this.transactionInfo.item.revised_transaction_id}</a>  به حساب مبدا بازگردانده شده‌‌است.`,
              icon: "newIcon/failed-circle.svg",
              revised: true,
            },
            {
              msg: `تراکنش توسط شما  ${this.jtime} لغو شد.`,
              icon: "newIcon/warning-circle.svg",
            },
          ],
        },
        settlement_tashim_refund_returned: {
          messages: [
            {
              revised: true,
              icon: "newIcon/failed-circle.svg",
              msg: this.message2("مبدا"),
            },
            {
              msg: "تراکنش به علت مشکل در حساب مقصد، برگشت خورد.",
              icon: "newIcon/warning-circle.svg",
            },
          ],
        },
        transaction_cancelRevised: {
          messages: [
            {
              revised: true,
              icon: "newIcon/Done.svg",
              msg: `این واریز جهت بازگرداندن مبلغ برداشت لغو شده به شناسه یکتای <a class="revisedId" >${this.transactionInfo.item.revised_transaction_id}</a> ایجاد شده است.`,
            },
          ],
        },
        transaction_ipg_transactionRevised_successful: {
          messages: [
            {
              revised: true,
              icon: "newIcon/Done.svg",
              msg: `این واریز موفق بوده اما به اشتباه به کیف پول شما اضافه نشده است. برای اصلاح آن، واریز موفقی به شناسه یکتای <a class="revisedId" >${this.transactionInfo.item.revised_transaction_id}</a> ایجاد
         و مبلغ به کیف پول شما اضافه شده است.`,
            },
          ],
        },
        transaction_p2p_successful: {
          messages: [{ icon: "secondCheck.svg", msg: this.p2pstatusMessage }],
        },
        transaction_cashIn_successful: {
          messages: [
            {
              icon: "secondCheck.svg",
              msg: this.transactionCashInMessage,
            },
          ],
        },
        transaction_correction: {
          messages: [
            {
              revised: true,
              icon: "newIcon/Done.svg",
              msg: `این واریز جهت بازگرداندن مبلغ برداشت ناموفق به  شناسه یکتای <a class="revisedId" >${this.transactionInfo.item.revised_transaction_id}</a>  ایجاد شده است.`,
            },
          ],
        },
        transaction_revised_successful: {
          messages: [
            {
              revised: true,
              icon: "newIcon/Done.svg",
              msg: `این واریز جهت اصلاح مبلغ واریز ناموفق به شناسه یکتای <a class="revisedId" >${this.transactionInfo.item.revised_transaction_id}</a>  ایجاد شده است.`,
            },
          ],
        },
        settlement_shaparak_approved: {
          messages: [
            {
              icon: "newIcon/Done.svg",
              msg: `درخواست به بانک مرکزی ارسال شد. درصورتی که مبلغ تا تاریخ ${this.transactionInfo?.shaparakSettlementDetails?.estimate_payment_time} واریز نشد، وضعیت را از طریق پشتیبانی پیگیری کنید.`,
            },
          ],
        },
        settlement_shaparak_successful: {
          messages: [
            {
              icon: "newIcon/Done.svg",
              msg: `تسویه توسط شاپرک تایید شد.`,
            },
          ],
        },
        settlement_shaparak_revised: {
          messages: [
            {
              msg: `این تراکنش لغو شده‌ و مبلغ آن طی تراکنش <a  class="revisedId" >${this.transactionInfo.item.revised_transaction_id}</a>  به کیف پول شما اضافه شده است. علت خطا در جزییات این تراکنش قابل مشاهده است.`,
              icon: "newIcon/failed-circle.svg",
              revised: true,
            },
            {
              msg: `به علت خطا در فرایند انتقال پول از شاپرک به حساب شما، تسویه انجام نشد.`,
              icon: "newIcon/warning-circle.svg",
            },
          ],
        },
      }

      return messageList
    },
  },
  methods: {
    message2(value) {
      return `این تراکنش برگشت خورده‌است. مبلغ آن طی تراکنش <a class="revisedId" >${this.transactionInfo.item.revised_transaction_id}</a> به حساب ${value} بازگردانده شده‌‌است.`
    },
    clickHandler(event) {
      if (event.target.getAttribute("class") == "revisedId") {
        this.revisedClick()
      }
    },
    revisedClick() {
      if (this.transactionInfo.refund.data?.length) {
        this.$emit("revisedClick", this.transactionInfo.refund.data[0].id)
      } else {
        this.$emit(
          "revisedClick",
          this.transactionInfo.item.revised_transaction_id
        )
      }
    },
    moreDetailsClick() {
      this.$emit("moreDetailsClick")
    },
    p2pPartialMessage(amount, date, text) {
      const currencyName = " " + this.currencyName

      let msg =
        this.$options.filters.currency(amount) + currencyName + text + date

      return msg
    },
    urlReplacer(str) {
      if (!str) return
      let match = str.match(
        /(\b(https:\/\/vand.ar\/)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
      )
      let final = str
      if (match) {
        match.map((url) => {
          final = final.replace(
            url,
            '<a href="' + url + '" target="_BLANK"> مشاهده رسید </a>'
          )
        })

        final = final.replace("لینک رسید:", "")
      }

      return final
    },
  },
}
</script>

<style lang="scss" scoped>
.showModalBoxes {
  padding: 20px;

  @media screen and (max-width: 599px) {
    padding: 15px;
  }

  > .layout > .flex {
    &:first-child {
      max-width: 30px;
    }
  }
}
</style>
