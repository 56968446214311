<template>
  <v-layout :id="`bank-${bank.code}`" align-center>
    <div class="image-box ml-2">
      <v-img
        :lazy-src="bank.logo"
        :src="bank.logo"
        contain
        height="32px"
        width="32px"
      ></v-img>
    </div>
    <v-flex>{{ bank.name }}</v-flex>
    <v-flex style="max-width: 80px; position: relative">
      <v-btn
        v-if="!bank.is_active"
        :loading="loading"
        class="btn_small_normal full-width"
        color="#4C9F87"
        depressed
        text
        @click="activate(bank)"
      >
        <span class="text--white" style="color: #fff !important">فعال شود</span>
      </v-btn>
      <v-btn
        v-else
        :loading="loading"
        class="btn_small_normal full-width"
        depressed
        text
        outlined
        @click="showDeactivateModal(bank)"
      >
        <span>غیرفعال شود</span>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "BankManagementItem",
  props: ["bank"],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    activate(bank) {
      this.loading = true
      this.$http
        .post(
          `${this.$store.getters.apiUrlParam}/subscription/banks/${bank.code}/activate`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.commit("setBankActive", bank.code)
          this.$store.commit(
            "callSuccessSnackbar",
            `${bank.name} برای شما فعال شد.`
          )
          bank.is_active = true
        })
        .catch((error) => {
          //callsnackbar
        })
        .finally(() => {
          this.loading = false
        })
    },
    showDeactivateModal(bank) {
      this.$emit("showDeactivateModal", bank)
    },
  },
}
</script>

<style scoped>
.full-width {
  width: 100% !important;
}
</style>
