<template>
  <!-- if no data was available -->
  <div class="mb-2">
    <v-card
      class="d-flex flex-column align-center justify-center pa-8 mt-12"
      color="white"
    >
      <div class="background mb-3">
        <div><checkout-icon fill-color="white" /></div>
      </div>
      <h2>فعلا صورتحسابی ندارید.</h2>
      <div class="font_2 text-center mb-7">
        بعد از اولین پرداخت، می‌توانید صورتحساب‌های پرداخت با یک کلیک را اینجا
        ببینید.
      </div>
      <v-card outlined color="rgb(238 238 238 / 0.4)" class="pa-4 radius-50">
        <v-avatar size="48" class="radius-50" style="background: #eeeeee">
          <checkout-icon />
        </v-avatar>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import checkoutIcon from "@/assets/img/newIcon/CheckoutIcon.vue"

export default {
  name: "EmptyTable",
  components: {
    checkoutIcon,
  },
}
</script>
