<template>
  <left-modal toolbar-name="مدیریت بانک‌ها" @closeModal="$emit('closeModal')">
    <div slot="toolbarItems">
      <v-btn
        :disabled="!hasChanged"
        class="btn_small_normal"
        dark
        text
        :loading="loading"
        @click="sendForm"
      >
        ذخیره
      </v-btn>
    </div>

    <general-card>
      <div
        :class="{ 'd-flex justify-center': $vuetify.breakpoint.xsOnly }"
        class="font_2 mb-8"
      >
        {{ descriptionText }}
      </div>
      <v-divider />
      <div v-if="bankList?.length > 0">
        <bank-management-item
          v-for="(bank, index) in bankList"
          :key="bank.code + index"
          :bank="bank"
          @hasChanged="onSelectChange($event, index)"
        />
      </div>
      <div v-for="(i, index) in 2" v-else :key="i + index" class="mt-3">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          tile
          type="list-item-avatar"
        />
      </div>
    </general-card>
  </left-modal>
</template>

<script>
import LeftModal from "../../modals/leftModal"
import GeneralCard from "../../elements/generalCard"
import BankManagementItem from "../elements/bankManagementItem"
export default {
  name: "BanksManagement",
  components: { BankManagementItem, GeneralCard, LeftModal },
  props: ["activationStep"],
  data() {
    return {
      hasChanged: false,
      bankList: [],
      loading: false,
    }
  },
  computed: {
    descriptionText() {
      return `  سقف روزانه هر بانک و زمان سرویس‌دهی آن‌ها متفاوت است. کارمزد برای همه
        بانک‌ها ۰.۰۲ ٪ است.`
    },
    noBankIsSelected() {
      return !this.bankList?.filter((b) => b.a2a?.is_active)?.length
    },
    payloadObj() {
      let banks = []

      for (let bank in this.bankList) {
        banks.push({
          bank_code: this.bankList[bank]?.code,
          status: this.bankList[bank]?.a2a?.is_active ? "ACTIVE" : "INACTIVE",
        })
      }

      return { banks }
    },
  },
  mounted() {
    this.getBankList()
  },
  methods: {
    onSelectChange(event, index) {
      this.bankList[index].a2a.is_active = event

      //if no banks is selected, keep save btn disabled
      if (this.noBankIsSelected) this.hasChanged = false
      else {
        this.hasChanged = true
      }
    },
    getBankList() {
      // api.vandar.io/v3/business/{business}/settlement/banks
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") + "/settlement/banks",
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.bankList = response?.data?.data?.filter((b) => b.a2a.has_ability)
        })
        .catch(() => {
          //callsnackbar
        })
    },
    setActionsOnSave(message) {
      this.loading = false
      this.$emit("closeModal")

      if (this.activationStep) {
        this.$emit("activeTool", "A2A")
        this.$router.push({
          name: "businessHome",
          params: {
            a2aSnackbar: true,
            snackbarMessage: message,
          },
        })
      } else this.$emit("onBanksUpdate")
      this.$store.commit("callSuccessSnackbar", "تغییرات مورد نظر اعمال شد")
    },
    noBankError() {
      this.loading = false
      this.$store.commit("callsnackbar", "بانکی انتخاب نشده است")
    },
    sendForm() {
      this.loading = true
      if (this.noBankIsSelected) {
        this.noBankError()
      } else {
        //update banks
        this.$http
          .post(
            this.$store.getters.apiWithVersionParam("3") +
              "/settlement/a2a/bank/status",
            this.payloadObj,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.setActionsOnSave(response?.data?.message)
          })
          .catch((error) => {
            //callsnackbar
            this.loading = false
            this.$store.commit("callsnackbar", "لطفا دوباره تلاش کنید")
          })
      }
    },
  },
}
</script>
