<template>
  <v-flex>
    <a
      style="
        border-bottom: 1px dashed;
        text-decoration: none;
        margin-left: 3px;
        margin-right: 3px;
      "
      @click="$emit('revisedClick', item.value)"
      >{{ item.value }}</a
    >
  </v-flex>
</template>

<script>
export default {
  props: ["item"],
}
</script>
