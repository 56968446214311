<template>
  <v-app>
    <whatsNew v-if="$store.state.user.is_whats_new_exists == true" />
    <!-- navigationDrawer: in big screen  -->
    <v-navigation-drawer
      v-if="show_navigation_drawer"
      right
      app
      class="white navigation vandarNavigation"
      width="240"
    >
      <userNavbar />
      <navigationDrawer v-if="isDashboard" />
      <dashboardNavigationDrawer v-else />
      <buttonNavigationDrawer v-if="isDashboard" />
    </v-navigation-drawer>

    <!-- navigationDrawer: in small screen is hidden -->
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndDown"
      v-model="drawer"
      :disable-resize-watcher="!show_navigation_drawer"
      fixed
      right
      app
      class="white navigation vandarNavigation"
      width="240"
      :style="isDashboard ? 'height: 100%' : null"
    >
      <userNavbar />
      <navigationDrawer v-if="isDashboard" />
      <dashboardNavigationDrawer v-else />
      <buttonNavigationDrawer v-if="isDashboard" />
    </v-navigation-drawer>

    <navbar v-if="$store.state.templateStatus == 2" />

    <div
      v-else-if="
        $vuetify.breakpoint.mdAndDown && $store.state.templateStatus != 3
      "
      id="header"
    >
      <v-app-bar-nav-icon
        style="position: absolute; right: 10px; top: 0px; color: white"
        @click.stop="drawer = !drawer"
      />
      <div class="logo">
        <a href="https://vandar.io">
          <v-img :src="$store.state.vandarIconCdn" width="24" alt="Vandar" />
        </a>
      </div>
    </div>

    <v-main style="position: relative">
      <v-container fluid :class="containerClass">
        <activationBar
          v-if="
            $store.state.user.hasOwnProperty('tools') &&
            show_navigation_drawer &&
            $route.fullPath != '/'
          "
        />
        <router-view
          :key="$route.path"
          style="z-index: 2; position: relative"
        />
      </v-container>
      <v-img
        v-if="
          $route.name === 'Onboarding' || $route.name === 'OnboardingResult'
        "
        :class="{ 'd-none': $vuetify.breakpoint.xsOnly }"
        style="position: absolute; left: 0; bottom: 0"
        :src="require('@/assets/img/onBoardingLayoutIcon.png')"
      />
    </v-main>

    <v-snackbar
      v-model="$store.state.snackbar.status"
      color="van_red"
      bottom
      :timeout="snackbarTimeOut"
      class="mb-7 vanSnackbar"
    >
      {{ $store.state.snackbar.message }}
      <v-progress-linear
        :buffer-value="1"
        height="4"
        color="rgba(255,255,255,0.6)"
        style="position: absolute; bottom: 0; right: 0"
        :value="Math.floor(100 * (snackbarCurrentTime / snackbarTimeOut))"
      />
    </v-snackbar>

    <v-snackbar
      v-model="$store.state.successSnackbar.status"
      color="van_green"
      bottom
      :timeout="snackbarTimeOut"
      class="mb-7 vanSnackbar"
    >
      {{ $store.state.successSnackbar.message }}
      <v-progress-linear
        :buffer-value="1"
        height="4"
        color="rgba(255,255,255,0.6)"
        style="position: absolute; bottom: 0; right: 0"
        :value="Math.floor(100 * (snackbarCurrentTime / snackbarTimeOut))"
      />
    </v-snackbar>

    <!-- after payment was successfull -->
    <v-dialog
      v-model="$store.state.confirmationMessage.status"
      width="360"
      content-class="confirmationMessage"
      no-click-animation
      persistent
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/checkbox-marked-circle-outline.svg')"
              width="40"
              height="40"
            />
          </v-flex>
          <v-flex class="main-title font_3">
            {{ $store.state.confirmationMessage.title }}
          </v-flex>
          <v-flex
            class="main-des font_4"
            v-html="$store.state.confirmationMessage.description"
          />
          <v-flex>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="closeAfterPaymentMessage()"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- after payment was failed -->
    <v-dialog
      v-model="$store.state.errorMessage.status"
      width="360"
      content-class="confirmationMessage"
      no-click-animation
      persistent
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/close-circle-outline.svg')"
              width="40"
              height="40"
            />
          </v-flex>
          <v-flex class="main-title font_20">
            {{ $store.state.errorMessage.title }}
          </v-flex>
          <v-flex
            class="main-des font_4"
            v-html="$store.state.errorMessage.description"
          />
          <v-flex>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="closeAfterPaymentError()"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- error for errorStatus -->
    <v-dialog
      v-model="errorStatus"
      width="360"
      content-class="confirmationMessage height226"
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/close-circle-outline.svg')"
              width="40"
              height="40"
            />
          </v-flex>
          <v-flex class="main-title font_20">
            {{ error }}
          </v-flex>
          <!-- <v-flex class="main-des font_4">{{error}}</v-flex> -->
          <v-flex>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="errorStatus = false"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- message for messageStatus -->
    <v-dialog
      v-model="messageStatus"
      width="360"
      content-class="confirmationMessage height226"
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/checkbox-marked-circle-outline.svg')"
              width="40"
              height="40"
            />
          </v-flex>
          <v-flex class="main-title font_3">
            {{ message }}
          </v-flex>
          <!-- <v-flex class="main-des font_4">{{message}}</v-flex> -->
          <v-flex>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="messageStatus = false"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- message for invitation -->
    <v-dialog
      v-if="
        $store.state.user.invitation && $store.state.user.invitation.business
      "
      v-model="$store.state.user.invitation"
      width="360"
      content-class="confirmationMessage height196"
      no-click-animation
      persistent
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex class="main-title font_1 mt-0">
            شما به «
            {{ $store.state.user.invitation.business.company_name_fa }}
            » دعوت شده‌اید
          </v-flex>
          <v-flex class="main-des font_4">
            « {{ $store.state.user.invitation.inviter.fname }}
            {{ $store.state.user.invitation.inviter.lname }} » شما را برای
            همکاری در کسب‌و‌کار خود دعوت کرده است. در ادامه باید انتخاب کنید :
          </v-flex>
          <v-flex>
            <v-btn
              text
              dark
              class="btn_small_normal"
              @click="updateInvitation(1)"
            >
              ملحق می‌شوم
            </v-btn>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal"
              @click="updateInvitation(0)"
            >
              ملحق نمی‌شوم
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import navbar from "./components/elements/navbar.vue"
import userNavbar from "./components/elements/userNavbar.vue"
import navigationDrawer from "./components/elements/navigationDrawer.vue"
import buttonNavigationDrawer from "./components/elements/buttonNavigationDrawer.vue"
import dashboardNavigationDrawer from "./components/elements/dashboardNavigationDrawer.vue"
import whatsNew from "./components/elements/whatsNew.vue"
import ActivationBar from "./components/elements/activationBar.vue"
import socket from "./socket"
import { setCookie } from "@/utils/cookie"
import { mapState } from "vuex"

const jmoment = require("moment-jalaali")

export default {
  name: "App",
  components: {
    navbar,
    userNavbar,
    navigationDrawer,
    buttonNavigationDrawer,
    dashboardNavigationDrawer,
    whatsNew,
    ActivationBar,
  },
  props: ["error", "message"],
  data() {
    return {
      messageStatus: false,
      errorStatus: false,
      drawer: false,
      snackbarTimeOut: 6000,
      snackbarCurrentTime: 0,
      timeOut: "",
      setting: {
        title: "تنظیمات",
        icon: "settings",
        to: this.$store.getters.urlParam + "/setting",
      },
    }
  },
  computed: {
    ...mapState(["token", "businessList"]),
    show_navigation_drawer() {
      return this.$store.state.templateStatus == 2 ||
        this.$store.state.templateStatus == 3
        ? false
        : true
    },
    localTemplateStatus() {
      return this.$store.state.templateStatus
    },
    isDashboard() {
      if (this.$route.name !== "Dashboard") {
        return true
      } else {
        return false
      }
      //return false
    },
    containerClass() {
      var classString = ""
      if (this.$store.state.templateStatus == 1) {
        classString = classString.concat(" withNavbar withNavigation")
      } else if (this.$store.state.templateStatus == 2) {
        classString = classString.concat(" withNavbar")
        if (this.$route.name !== "pageNotFound") {
          classString = classString.concat(" vandarBackground")
        } else {
          classString = classString.concat(" page404")
        }
      }
      if (this.$store.state.templateStatus == 3) {
        classString = classString.concat(" without15")
      }

      if (this.$route.name === "Onboarding") {
        classString = classString.concat(" backgroundWhite")
      }

      return classString
    },
  },
  watch: {
    localTemplateStatus: {
      handler(newValue, oldValue) {
        if (newValue == 2 || newValue == 3) this.drawer = false
      },
      deep: true,
    },
    error: {
      immediate: true,
      handler(newValue, oldValue) {
        //if(newValue != '') this.errorStatus = true
      },
    },
    message: {
      immediate: true,
      handler(newValue, oldValue) {
        //if(newValue != '') this.messageStatus = true
      },
    },
    "$store.state.snackbar.status": function (newValue) {
      if (newValue) {
        this.progressBarStart()
      }
    },
    "$store.state.successSnackbar.status": function (newValue) {
      if (newValue) {
        this.progressBarStart()
      }
    },
    businessList(newValue) {
      socket.disconnect()
      if (newValue) {
        socket.auth = {
          token: this.token,
          userUuid: this.$store.state.user.uuid,
          business_list: newValue,
        }
        socket.connect()
      }
    },
  },
  created() {
    if (this.token && this.businessList) {
      socket.auth = {
        token: this.token,
        userUuid: this.$store.state.user.uuid,
        business_list: this.businessList,
      }
      socket.connect()
      if (
        this.businessList.length === 1 &&
        this.businessList[0].business_name === "personal"
      ) {
        this.$router.push("/onboarding")
      }
    }

    socket.on("export_excel", (payload) => {
      var now = jmoment(new Date()).format("jYYYY/jMM/jDD HH:mm:ss")
      var timeDiff = moment(
        moment(now).diff(payload.data.created_at, "seconds")
      )

      if (payload.data.status === "DONE") {
        if (timeDiff > 3.5) {
          this.$store.commit(
            "callSuccessSnackbar",
            'خروجی "' + payload.data.file_name_fa + '" آماده شد'
          )
        }
        if (payload.data.download_url) {
          this.downloadFile(payload.data)
        }
      } else if (payload.data.status === "FAILED") {
        this.$store.commit(
          "callsnackbar",
          'دریافت خروجی "' + payload.data.file_name_fa + '" ناموفق بود'
        )
      }
    })

    socket.on("connect_error", (err) => {})
  },
  mounted() {
    window.addEventListener("goftino_ready", () => {
      this.$store.dispatch("retrieveUserObject").then((res) => {
        Goftino.setUser({
          email: res.data.data.email ? res.data.data.email : null,
          name: res.data.data.name ? res.data.data.name : null,
          phone: res.data.data.mobile ? res.data.data.mobile : null,
          about: res.data.data.id ? res.data.data.id : null,
          tags: null,
          forceUpdate: true,
        })
      })
    })
  },
  destroyed() {
    socket.off("export_excel")
    socket.off("connect_error")
    socket.disconnect()
  },
  methods: {
    downloadFile(data) {
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            "/transaction/export/download?uuid=" +
            data.uuid,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then(() => {
          window.open(data.download_url, "_blank")
        })
    },
    progressBarStart() {
      this.timeOut = setTimeout(() => {
        this.snackbarCurrentTime += 100
        if (this.snackbarTimeOut <= this.snackbarCurrentTime) {
          this.snackbarCurrentTime = 0
          clearTimeout(this.timeOut)
        } else {
          this.progressBarStart()
        }
      }, 100)
    },
    closeAfterPaymentError() {
      this.$store.commit("closeErrorMessage")
      this.$store.commit("setStartVerificationBusiness", true)
    },
    closeAfterPaymentMessage() {
      this.$store.commit("closeConfirmarionMessage")
      this.$store.commit("setStartVerificationBusiness", true)
    },
    updateInvitation(val) {
      this.$http
        .post(
          this.$store.state.api_base_url + "/personal/update/invitation/status",
          {
            status: val,
            business_id: this.$store.state.user.invitation.business.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.commit("setUserData", {
            invitation: null,
          })
          if (val === 1) {
            this.$router.push("/")
            this.$store.dispatch("retrieveBusinessList")
          }
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    // getCurrentTime() {
    //   const currentHour = new Date(
    //     new Date().toLocaleString("en", { timeZone: "Asia/Tehran" })
    //   ).getHours()

    //   if (currentHour >= 16 || currentHour < 8) {
    //     if (document.getElementById("goftino_w")) {
    //       document.getElementById("goftino_w").style.display = "none"
    //     }
    //   }
    // },
  },
}
</script>
<style>
.v-snack:not(.v-snack--absolute) {
  z-index: 1000010 !important;
}

.backgroundWhite {
  background: white;
}
</style>
