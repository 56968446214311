<template>
  <v-flex sm6 class="px-7 py-6 min-details">
    <v-layout style="align-items: center">
      <v-flex style="max-width: 52px">
        <div
          style="
            display: flex;
            align-items: center;
            border-radius: 10px 10px 0px 10px;
          "
          :style="`background-color:${iconBoxColor};width:${iconBoxWidth};height:${iconBoxHeight}`"
        >
          <v-img
            max-width="14"
            max-height="14"
            class="ma-auto"
            :src="require(`@/assets/img/${icon}`)"
          />
        </div>
      </v-flex>
      <v-flex>
        <div class="font_9" :dir="titleDir">
          {{ title }}
        </div>
        <div class="font_14">
          {{ subtitle }}
        </div>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  name: "MinDetailsBoxWithIcon",
  props: {
    icon: {
      type: String,
      default: "Ascending.svg",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    iconBoxColor: {
      type: String,
      default: "#4c9f874d",
    },
    titleDir: {
      type: String,
      default: "rtl",
    },
    iconBoxWidth: {
      type: String,
      default: "36px",
    },
    iconBoxHeight: {
      type: String,
      default: "36px",
    },
  },
}
</script>

<style scoped>
.min-details {
  text-align-last: right;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  background-color: white;
}
</style>
