<template>
  <div
    class="checkbox-card d-flex"
    :class="{
      'flex-column align-start': $vuetify.breakpoint.xsOnly,
      'align-center': $vuetify.breakpoint.smAndUp,
    }"
    :style="cssStyles"
    @click="$emit('onClick')"
  >
    <div class="ml-2 checkboxSection d-flex align-center">
      <v-checkbox
        v-model="isChecked"
        class="ma-0"
        :color="color"
        :indeterminate="isIndeterminate"
        @click.stop
      ></v-checkbox>
      <span class="font_2">{{ checkboxLabel }}</span>
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  name: "VanCardWithCheckbox",
  props: {
    checkboxLabel: {
      default: "انتخاب",
      type: String,
    },
    checkedStatus: {
      default: false,
      type: Boolean,
    },
    isIndeterminate: {
      default: false,
      type: Boolean,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    border: {
      type: String,
      default: "none",
    },
    borderRadius: {
      type: String,
      default: "4px",
    },
    color: {
      type: String,
      default: "van_green",
    },
    padding: {
      type: String,
      default: "10px 32px",
    },
    hoverShadow: {
      type: String,
      default: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    },
    extraStyle: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    cssStyles() {
      let style = {
        "background-color": this.backgroundColor,
        border: this.border + "!important",
        "border-radius": this.borderRadius,
        padding: this.padding,
        "--hoverShadow": this.hoverShadow,
      }

      for (const key in this.extraStyle) {
        style[key] = this.extraStyle[key]
      }

      return style
    },
    isChecked: {
      get() {
        return this.isIndeterminate ? false : this.checkedStatus
      },
      set(newValue) {
        this.$emit("onChange", newValue)
      },
    },
  },
}
</script>
<style scoped>
.checkbox-card:hover {
  box-shadow: var(--hoverShadow) !important;
  cursor: pointer;
}
.v-input--checkbox {
  width: fit-content;
}
.checkboxSection {
  min-width: 140px;
}
</style>
