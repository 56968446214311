<template>
  <v-layout wrap :column="$vuetify.breakpoint.xs">
    <v-flex
      :class="
        $store.state.user.sandbox_member ? 'pa-1 sm6 md4' : 'pa-1 sm6 md3'
      "
    >
      <div class="box">
        <v-card outlined class="guideCard" color="white" flat :hover="true">
          <v-layout column align-center>
            <v-flex class="image">
              <img width="46" src="@/assets/img/newIcon/key.svg" />
            </v-flex>
            <v-flex class="mt-5">
              <div class="font_5 bold">API کلید</div>
            </v-flex>
            <v-flex>
              <div
                v-if="
                  $store.state.hasOwnProperty('ipg') && $store.state.ipg.api_key
                "
                class="font_4 text-truncate"
                style="direction: ltr; line-height: 20px !important"
              >
                {{ $store.state.ipg.api_key }}
              </div>
            </v-flex>
            <v-flex class="align-self-center mt-5" style="max-width: 106px">
              <v-btn
                text
                dark
                class="btn btn_small_normal primary ma-auto"
                height="36px"
                style="width: 106px !important"
                @click="shareLink"
              >
                کپی کنید
              </v-btn>
              <v-tooltip v-model="tooltip_show" top>
                <template #activator="{ on }">
                  <v-btn
                    style="
                      position: absolute;
                      opacity: 0 !important;
                      width: 1px;
                      margin: 0px;
                      padding: 0px;
                      z-index: -1;
                      left: 105px;
                    "
                    v-on="on"
                  />
                </template>
                <span>کپی شد</span>
              </v-tooltip>
              <input
                id="hidden-input1"
                v-model="$store.state.ipg.api_key"
                class="hidden-input"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-flex>
    <v-flex v-if="!$store.state.user.sandbox_member" sm6 md3 class="pa-1">
      <div class="box">
        <v-card outlined class="guideCard" color="white" flat :hover="true">
          <v-layout column align-center>
            <v-flex class="image">
              <img width="46" src="@/assets/img/newIcon/sandbox.svg" />
            </v-flex>
            <v-flex class="mt-5">
              <div class="font_5 bold">دسترسی به سندباکس</div>
            </v-flex>
            <v-flex>
              <div class="font_4">فضای آزمون کدها مشابه یک کسب‌و‌کار فعال</div>
            </v-flex>
            <v-flex class="align-self-center mt-5" style="max-width: 106px">
              <v-btn
                text
                dark
                class="btn btn_small_normal primary ma-auto"
                height="36px"
                :loading="sandboxRequestLoading"
                style="width: 106px !important"
                :disabled="sandboxMemberAdd"
                @click="sandboxRequest"
              >
                <span v-if="sandboxMemberAdd">ارسال شد</span>
                <span v-else>ارسال درخواست</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-flex>
    <v-flex
      sm6
      :md3="!$store.state.user.sandbox_member"
      :md4="$store.state.user.sandbox_member"
      class="pa-1"
    >
      <div class="box">
        <v-card outlined class="guideCard" color="white" flat :hover="true">
          <v-layout column align-center>
            <v-flex class="image">
              <img width="46" src="@/assets/img/newIcon/ipg-document.svg" />
            </v-flex>
            <v-flex class="mt-5">
              <div class="font_5 bold">مستندات فنی و پلاگین‌ها</div>
            </v-flex>
            <v-flex>
              <div class="font_4">ورود به بخش توسعه‌دهندگان وندار</div>
            </v-flex>
            <v-flex class="align-self-center mt-5" style="max-width: 106px">
              <v-btn
                text
                dark
                class="btn btn_small_normal primary ma-auto"
                height="36px"
                style="width: 106px !important"
                @click="openDoc()"
              >
                توسعه‌دهندگان
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-flex>
    <v-flex
      sm6
      :md3="!$store.state.user.sandbox_member"
      :md4="$store.state.user.sandbox_member"
      class="pa-1"
    >
      <div class="box">
        <v-card outlined class="guideCard" color="white" flat :hover="true">
          <v-layout column align-center>
            <v-flex class="image">
              <img width="46" src="@/assets/img/newIcon/add-user.svg" />
            </v-flex>
            <v-flex class="mt-5">
              <div class="font_5 bold">افزودن برنامه‌نویس</div>
            </v-flex>
            <v-flex>
              <div class="font_4">اعطای دسترسی موارد فنی به برنامه‌نویس</div>
            </v-flex>
            <v-flex class="align-self-center mt-5" style="max-width: 106px">
              <v-btn
                text
                dark
                class="btn btn_small_normal primary ma-auto"
                height="36px"
                style="width: 106px !important"
                @click="$emit('openAddUser')"
              >
                افزودن برنامه‌‌نویس
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Guide",
  data() {
    return {
      tooltip_show: false,
      sandboxRequestLoading: false,
      sandboxMemberAdd: false,
    }
  },
  methods: {
    shareLink() {
      /* Get the text field */
      let copyText = document.querySelector("#hidden-input1")
      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        /* Alert the copied text */
        this.tooltip_show = true
        setTimeout(() => {
          this.tooltip_show = false
        }, 2000)
      }
    },
    openDoc() {
      window.open("https://docs.vandar.io/ipg_service/ipg", "_blank")
    },
    sandboxRequest() {
      this.sandboxRequestLoading = true
      this.$http
        .post(this.$store.getters.apiWithVersionParam("3") + "/sandbox/join")
        .then((resp) => {
          if (resp.data.status) {
            this.sandboxRequestLoading = false
            this.sandboxMemberAdd = true
          }
        })
        .catch(() => {
          this.sandboxRequestLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.flex {
  .box {
    position: relative;
    height: 150px;
    transition: all ease 0.5s;
    // overflow: hidden;

    // &:hover{
    //   box-shadow: 0px 0px 15px #0000000d;
    //   height: 215px;
    //   transition: all ease 0.5s;
    //   z-index: 5;
    // }
    .guideCard {
      padding: 19px 16px;
      height: 150px;
      overflow: hidden;
      &.v-card--hover {
        position: absolute;
        top: 0%;
        width: 100%;

        &:hover {
          box-shadow: 0px 0px 10px #0000002b !important;
          height: 215px;
          transition: all ease 0.5s;
          z-index: 5;
        }
      }
    }
  }
}
</style>
