import axios from "axios"
import router from "@/router"
import { store } from "@/store"
import { getCookie } from "@/utils/cookie"

axios.defaults.headers.common = {
  "Content-Type": "application/json",
  Accept: "application/json",
}

axios.defaults.headers.authorization = "Bearer " + getCookie("access_token")

axios.defaults.timeout = 20000

function refresh(config) {
  let returnIt1 = false
  return new Promise((resolve, reject) => {
    let checkIfTokenIsRefreshed2 = setInterval(function () {
      if (getCookie("access_token")) {
        returnIt1 = true
        config.headers.authorization = "Bearer " + getCookie("access_token")
        axios.defaults.headers.authorization =
          "Bearer " + getCookie("access_token")
        clearInterval(checkIfTokenIsRefreshed2)
      }
      if (returnIt1) {
        resolve(config)
      }
    }, 100)
  })
}

axios.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    //if (store.state.token)
    config.headers.authorization = "Bearer " + getCookie("access_token")
    if (
      getCookie("access_token") ||
      config.url ===
        store.state.api_base_url_withoutV + "/account/v1/refresh-token"
    )
      return config

    if (!getCookie("access_token")) {
      if (!getCookie("refresh_token")) {
        return config
      } else {
        if (!store.state.waitForRefreshToken) {
          store.commit("updateWaitForRefreshToken", true)
          await store.dispatch("refreshToken")
          config.headers.authorization = "Bearer " + getCookie("access_token")
          axios.defaults.headers.authorization =
            "Bearer " + getCookie("access_token")
          return await refresh(config)
        } else {
          return await refresh(config)
        }
      }
    }
  },
  function (error) {
    // Do something with request error

    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    var output = null

    if (error.response.status && error.response.status === 400) {
      output = error.response.data.message
    }

    if (error.response.status && error.response.status === 429) {
      output =
        "وندار درخواست‌های زیادی از جانب شما دریافت کرده است. لطفاً مدتی صبر کنید و مجدداً تلاش کنید."

      store.commit("callsnackbar", output)
    }

    if (
      error.response != undefined &&
      error.response.status &&
      error.response.status >= 500
    ) {
      output = "پاسخ مناسب از سمت سرور دریافت نشد!"
    }

    if (
      error.hasOwnProperty("response") &&
      error.response &&
      error.response !== undefined &&
      error.response.hasOwnProperty("data")
    ) {
      if (
        error.response.data.hasOwnProperty("errors") &&
        error.response.data.errors &&
        error.response.data.errors != null &&
        error.response.data.errors != ""
      ) {
        var errorsObj =
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
        if (Array.isArray(errorsObj)) {
          output = errorsObj[0]
        } else {
          output = errorsObj
        }
      } else if (
        error.response.data.hasOwnProperty("error") &&
        error.response.data.error &&
        error.response.data.error != null &&
        error.response.data.error != ""
      ) {
        output = error.response.data.error
      }
    }

    if (
      error.response != undefined &&
      error.response.status &&
      error.response.status === 503
    ) {
      output = error.response.data.error
    }

    /* این کد تا الان فقط برای /business/name کار می کرده تست کن ببین مشکلی نداشته باشه*/
    if (
      error.response != undefined &&
      error.response.status &&
      error.response.status === 404 &&
      error.config.url !=
        store.getters.apiUrlParam +
          "/subscription/withdrawal?page=1&per_page=20" &&
      ["production", "stage"].includes(process.env.VUE_APP_ENV)
    ) {
      router.push({ name: "pageNotFound" })
    }

    if (
      error.response != undefined &&
      error.response.status &&
      error.response.status == 401
    ) {
      output = null
      store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href =
            process.env.VUE_APP_SSO_URL + "?callback=" + window.location.href
        })
        .catch((error) => {
          window.location.href =
            process.env.VUE_APP_SSO_URL + "?callback=" + window.location.href
        })
    }

    //full error send for batch settlement store
    if (
      error.config.url === store.getters.apiUrlBatch + "/batches-settlement" &&
      error.response.status === 422
    ) {
      return Promise.reject(error)
    }

    var authUrl = [
      store.state.api_base_url + "/mobile/verify",
      store.state.api_base_url + "/register/check/mobile",
      store.state.api_base_url + "/mobile/send",
      store.state.api_base_url + "/change/password",
      store.state.api_base_url + "/dash/verify-two-step",
      store.state.api_base_url + "/personal/update/emailAndPostalcode",
      store.state.api_base_url + "/business/validation/name",
      store.state.api_base_url + "/business/validation/company-name-fa",
      store.state.api_base_url + "/business/validation/uniqueness",
      store.getters.apiWithVersionParam("3") + "/settlement/store",
      store.getters.apiWithVersionParam("2.1") + "/settlement/store",
      store.getters.ibanApiUrlParam + "/check",
      store.getters.apiUrlParam + "/postalcode",
      store.getters.apiWithVersionParam("3") + "/transaction/export", //excel exports
      store.getters.apiWithVersionParam("2.1") + "/settlement/time-prediction ", // addSettlement modal time-prediction
      store.getters.apiUrlParam + "/settlement/wage", //addSettlementModal wage
    ]

    //add check iban request to this array

    var ShowSnackbar = authUrl.indexOf(error.config.url) == -1 ? true : false

    const customerPageUrl =
      store.getters.apiWithVersionParam("3") + "/customers?page=1&per_page=20"

    const customerNotActiveBusinessErrorConditions =
      error.response.status === 400 &&
      error.response.data.error === "عدم دسترسی کسب و کار"

    if (output != "" && output != null && ShowSnackbar) {
      if (error.config.url === customerPageUrl) {
        if (!customerNotActiveBusinessErrorConditions) {
          store.commit("callsnackbar", output)
        }
      } else {
        store.commit("callsnackbar", output)
      }
    }

    if (
      error.config.url ==
      store.getters.apiWithVersionParam("2.1") + "/settlement/store"
    ) {
      return Promise.reject(error)
    } else {
      return Promise.reject(output)
    }
  }
)

export default axios
