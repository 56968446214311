<template>
  <div class="activationBlock">
    <span class="topBack pendingTopBack" />
    <v-layout column class="mx-auto twoPending" style="margin: -20px">
      <v-flex class="personal_title alldone">
        <v-layout justify-space-between align-center>
          <v-flex>
            <v-layout align-center>
              <v-flex style="max-width: 60px">
                <v-img
                  :src="require('@/assets/img/newIcon/refundPending.svg')"
                  alt="avatar"
                  width="40"
                />
              </v-flex>
              <v-flex>
                <div
                  v-if="
                    $store.state.business.tools.refund.time != 'call operation'
                  "
                  class="font_5"
                >
                  بررسی فعال‌سازی بازگشت وجه
                </div>
                <div v-else class="font_5" style="line-height: 22px">
                  بررسی درخواست فعال‌سازی «بازگشت وجه» شما بیش از زمان تعیین شده
                  طول کشیده است. با عرض پوزش، به سرعت در حال پیگیری هستیم
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="$store.state.business.tools.refund.time != 'call operation'"
          >
            <div
              v-if="
                $store.state.user.status != 1 &&
                $store.state.business.role == 'owner'
              "
              style="text-align: left"
              class="font_7"
            >
              در انتظار تایید حساب شخصی
            </div>
            <div
              v-else-if="$store.state.business.status != 1"
              style="text-align: left"
              class="font_7"
            >
              در انتظار تایید کسب‌و‌کار
            </div>
            <div
              v-else-if="
                $store.state.business.tools.refund.time &&
                $store.state.business.tools.refund.time.includes(':') &&
                time != 0
              "
              style="text-align: left"
            >
              <span class="font_18"> حداکثر زمان انتظار: </span>
              <span
                class="font_12"
                style="
                  font-size: 20px !important;
                  color: #333 !important;
                  display: inline-block;
                  width: 90px;
                "
              >
                {{ convertToTime }}
              </span>
            </div>
            <div v-else style="text-align: left" class="font_7">
              در انتظار تایید وندار
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "RefundActivationBlock",
  data() {
    return {
      //this is modal status
      status: false,
      time: 0,
    }
  },
  mounted() {
    this.businessActiveCountDown()
  },
  methods: {
    convertToTime() {
      var date = new Date(null)
      if (!this.time) this.time = 0
      date.setSeconds(this.time) // specify value for SECONDS here
      var hour = parseInt(this.time / 3600)
      var result = date.toISOString().substr(13, 6)
      result = hour == 0 ? "00" + result : hour + "" + result
      return result
    },
    close_modal() {
      this.status = false
    },
    businessActiveCountDown() {
      var secondTime = 0
      var startTime = this.$store.state.business.tools.refund.time
        ? this.$store.state.business.tools.refund.time
        : "00:00:00"
      var sec = parseInt(startTime.split(":")[2])
      var min = parseInt(startTime.split(":")[1])
      var hour = parseInt(startTime.split(":")[0])

      secondTime = hour * 3600 + min * 60 + sec

      this.countdownfunc(secondTime)
    },
    countdownfunc(startPoint) {
      var downloadTimer = setInterval(() => {
        startPoint -= 1
        if (startPoint <= 0) {
          clearInterval(downloadTimer)
        }
        this.time = startPoint
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.twoPending {
  > .personal_title:nth-child(2) {
    margin-top: 30px;
  }
}
</style>
