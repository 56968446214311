<template>
  <div>
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      class="business"
      color="white"
      flat
      outlined
    >
      <v-layout align-center justify-space-between style="width: 100%">
        <v-flex d-flex style="width: 145px">
          <v-layout justify-start>
            <div class="ml-2">
              <v-img :src="getIcon" alt="avatar" width="32" />
            </div>
            <v-flex>
              <div class="income d-inline-block">
                <h2 class="font_22">
                  {{ tool?.title }}
                </h2>
              </div>
              <span
                v-if="
                  tool?.badge && !$store.state.business.tools.MPG.is_created
                "
                class="toolBadge"
                >{{ tool?.badge }}</span
              >
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex d-flex style="width: 500px">
          <v-layout align-center justify-center>
            <v-flex>
              <div class="income d-flex" style="align-items: center">
                {{ tool?.subTitle }}
                <v-icon
                  class="van_color05--text mr-1"
                  size="24"
                  style="max-width: 22px; direction: rtl"
                  @click="$emit('openIntroduce')"
                >
                  mdi_info_outline
                </v-icon>
              </div>
            </v-flex>
            <v-flex
              style="max-width: 263px; text-align: left; position: relative"
            >
              <v-layout align-center justify-space-between>
                <v-flex class="font_4" style="text-align: left">
                  <v-btn
                    v-if="
                      (!ipgHardReject &&
                        (toolStatus === 'FAILED' ||
                          (whichtool === 'cash-in' &&
                            toolStatus === 'PENDING'))) ||
                      (whichtool === 'miando' && toolStatus === 'PENDING')
                    "
                    :ripple="false"
                    class="small linkBorder van_green--text"
                    text
                    @click="$emit('cancelActivation')"
                  >
                    از فعال‌سازی منصرف شدم
                  </v-btn>
                </v-flex>
                <div
                  v-if="
                    !$store.state[$store.getters.userOrBuisenss].hasOwnProperty(
                      'tools'
                    )
                  "
                  class="mr-2"
                >
                  <v-flex>
                    <div class="btn-skeleton" />
                  </v-flex>
                </div>
                <div v-else class="mr-2">
                  <v-flex style="text-align: left">
                    <v-btn
                      v-if="toolStatus === 'INACTIVE' || ipgHardReject"
                      :disabled="ipgHardReject"
                      class="btn_small_normal"
                      dark
                      text
                      :loading="loading"
                      @click="activeBtnClicked()"
                    >
                      فعال‌سازی
                    </v-btn>
                    <div
                      v-else-if="
                        (toolStatus === 'PENDING' && whichtool !== 'cash-in') ||
                        toolStatus === 'TERMINATED'
                      "
                      :class="StyleClassOfLabel"
                    >
                      <span v-if="isBusinessActive">در حال بررسی</span>
                      <span v-else>در انتظار تایید کسب‌وکار</span>
                    </div>
                    <div
                      v-else-if="
                        toolStatus === 'PENDING' && whichtool === 'cash-in'
                      "
                      class="waitForContract"
                    >
                      در انتظار مدارک
                    </div>
                    <v-btn
                      v-else-if="toolStatus === 'ACTIVE'"
                      :disabled="isCustomerDiactivationDisabled"
                      class="btn_small_normal"
                      depressed
                      outlined
                      text
                      @click="$emit('suspendTool')"
                    >
                      غیرفعال شود
                    </v-btn>
                    <div v-else-if="toolStatus === 'FAILED'" class="failed">
                      در انتظار اصلاح
                    </div>
                  </v-flex>
                </div>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card
      v-if="!$vuetify.breakpoint.mdAndUp"
      class="business-xs"
      color="white"
      flat
      outlined
    >
      <v-layout justify-space-between style="width: 100%">
        <v-flex d-flex>
          <v-layout justify-start>
            <div class="ml-2">
              <v-img :src="getIcon" alt="avatar" width="32" />
            </div>
            <v-flex>
              <div class="income d-flex align-center">
                <h2 class="font_22">
                  {{ tool?.title }}
                </h2>
                <span v-if="tool?.badge" class="toolBadge">{{
                  tool?.badge
                }}</span>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="justify-end align-center" d-flex>
          <div
            v-if="
              !$store.state[$store.getters.userOrBuisenss].hasOwnProperty(
                'tools'
              )
            "
          >
            <v-flex>
              <div class="btn-skeleton" />
            </v-flex>
          </div>
          <div v-else>
            <v-flex style="text-align: left; position: relative">
              <div
                v-if="
                  (toolStatus === 'PENDING' && whichtool !== 'cash-in') ||
                  toolStatus === 'TERMINATED'
                "
                :class="StyleClassOfLabel"
              >
                <span v-if="isBusinessActive">در حال بررسی</span>
                <span v-else>در انتظار تایید کسب‌وکار</span>
              </div>
              <div
                v-else-if="toolStatus === 'PENDING' && whichtool === 'cash-in'"
                class="waitForContract"
              >
                در انتظار مدارک
              </div>
              <div
                v-else-if="toolStatus === 'INACTIVE' || ipgHardReject"
                class="d-flex"
                style="max-width: 114px; float: left"
              >
                <v-btn
                  :disabled="ipgHardReject"
                  class="btn_small_normal"
                  dark
                  text
                  @click="$emit('activeTool')"
                >
                  فعال‌سازی
                </v-btn>
              </div>

              <v-btn
                v-else-if="toolStatus === 'ACTIVE'"
                :disabled="isCustomerDiactivationDisabled"
                class="btn_small_normal"
                depressed
                outlined
                text
                @click="$emit('suspendTool')"
              >
                غیرفعال شود
              </v-btn>
              <div
                v-else-if="
                  toolStatus === 'FAILED' && $vuetify.breakpoint.mdAndUp
                "
                class="failed"
              >
                در انتظار اصلاح
              </div>
            </v-flex>

            <template
              v-if="
                !ipgHardReject ||
                (whichtool === 'miando' && toolStatus === 'PENDING')
              "
            >
              <v-flex
                v-if="
                  toolStatus === 'FAILED' ||
                  (whichtool === 'cash-in' && toolStatus === 'PENDING') ||
                  (whichtool === 'miando' && toolStatus === 'PENDING')
                "
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'text-align: right'
                    : 'text-align: left'
                "
                class="font_4"
              >
                <v-btn
                  :ripple="false"
                  class="small linkBorder van_green--text"
                  text
                  @click="$emit('cancelActivation')"
                >
                  از فعال‌سازی منصرف شدم
                </v-btn>
              </v-flex>
            </template>
          </div>
        </v-flex>
      </v-layout>
      <v-layout
        align-center
        class="van_color11 pa-3 mt-4"
        justify-space-between
        rounded
        style="width: 100%; border-radius: 4px"
      >
        <v-flex class="one-line" xs-11>
          <div class="income d-flex" style="align-items: center">
            {{ tool?.subTitle }}
          </div>
        </v-flex>
        <v-flex style="text-align: left" xs-1>
          <v-icon
            class="van_color04--text mr-1"
            size="24"
            style="max-width: 24px; direction: rtl"
            @click="$emit('openIntroduce')"
          >
            mdi_info_outline
          </v-icon>
        </v-flex>
      </v-layout>
    </v-card>
    <!-- suspend modals -->
    <v-dialog
      v-if="shaparakTerminalModal"
      v-model="shaparakTerminalModal"
      content-class="confirmationMessage height374"
      no-click-animation
      persistent
      width="360"
    >
      <v-card height="100%" outlined>
        <v-layout align-center column justify-center>
          <v-flex class="main-title font_9">
            به زودی دوباره می‌توانید
            <span v-if="whichtool === 'ipg'"> درگاه </span>
            <span v-if="whichtool === 'requestMoney'"> فرم </span>
            تازه‌ای بسازید
          </v-flex>
          <v-flex class="main-des font_4">
            با عرض پوزش به دلیل همگام‌سازی زیرساخت‌ها با قوانین تازه‌ی شاپرک و
            سازمان امور مالیاتی موقتا نمی‌توانید
            <span v-if="whichtool === 'ipg'"> درگاه پرداخت </span>
            <span v-if="whichtool === 'requestMoney'"> فرم پرداخت </span>
            تازه‌ای بسازید. به زودی با پایان پیاده‌سازی مقررات تازه این امکان
            دوباره فعال می شود.
          </v-flex>
          <v-flex class="" />
          <v-flex>
            <v-btn
              class="btn_small_normal"
              depressed
              outlined
              text
              @click="shaparakTerminalModal = false"
            >
              فهمیدم
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Card",
  props: {
    whichtool: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            "requestMoney",
            "ipg",
            "subscription",
            "api",
            "log",
            "customer",
            "invoice",
            "p2p",
            "refund",
            "settlement_batch",
            "cash-in",
            "kerdar",
            "accountant",
            "PBV",
            "miando",
            "A2A",
            "MPG",
          ].indexOf(value) !== -1
        )
      },
    },
    toolStatus: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isHover: false,
      loading: false,
      hover: false,
      shaparakTerminalModal: false,
      tools: {
        MPG: {
          enable: true,
          title: "پرداخت یکپارچه",
          subTitle: "روش دریافت پول از مشتریان ",
          badge: "جدید",
        },
        miando: {
          enable: true,
          title: "معامله امن",
          subTitle:
            "بهبود تجربه مشتریان و افزایش نرخ تبدیل با کاهش ریسک در معاملات ",
        },
        // PBV: {
        //   enable: true,
        //   title: "پرداخت با وندار",
        //   subTitle: "ویژه دارندگان وب‌سایت و اپلیکیشن",
        // },
        A2A: {
          enable: true,
          title: "تسویه آنی",
          subTitle: "تسویه به صورت آنی برای همه کسب‌وکارها",
        },
        requestMoney: {
          enable: true,
          title: "فرم پرداخت",
          subTitle: "دریافت پول بدون نیاز به وب‌سایت و برنامه‌نویسی",
        },
        ipg: {
          enable: true,
          title: "درگاه پرداخت",
          subTitle: "ویژه دارندگان وب‌سایت و اپلیکیشن",
        },
        subscription: {
          enable: true,
          title: "پرداخت خودکار",
          subTitle: "پرداخت خودکار از مشتریان",
        },
        api: {
          enable: true,
          title: "وب‌سرویس",
          subTitle: "ظرفیت توسعه بی‌حد و مرز",
        },
        log: {
          enable: true,
          title: "گزارش فراخوانی",
          subTitle: "همه‌ چیز زیر نظر شما",
        },
        customer: {
          enable: true,
          title: "مشتریان",
          subTitle: "مدیریت تعاملات با مشتریان و شرکا",
        },
        invoice: {
          enable: true,
          title: "فاکتور",
          subTitle: "برای هر درخواست پرداخت خودکار، یک فاکتور تولید می‌شود",
        },
        p2p: {
          enable: true,
          title: "انتقال وجه",
          subTitle:
            "ارسال پول از کیف پول شما به کیف پول اشخاص و کسب‌وکارهای فعال در وندار",
        },
        refund: {
          enable: true,
          title: "بازگشت وجه",
          subTitle:
            "بازگرداندن پول مشتری‌های ناراضی یا اصلاح تراکنش‌های اشتباه درگاه",
        },
        settlement_batch: {
          enable: true,
          title: "برداشت گروهی",
          subTitle: "ثبت هزار برداشت، با یک دستور",
        },
        "cash-in": {
          enable: true,
          title: "واریز بانکی",
          subTitle: "شارژ کیف پول وندار با استفاده از حساب بانک آینده",
        },
        kerdar: {
          enable: true,
          title: "کردار",
          subTitle: "داده‌های آماری برای تحلیل وضعیت کسب‌وکارها",
        },
        accountant: {
          enable: true,
          title: "گزارش‌های مالی",
          subTitle: "گزارش جامعی از واریز، برداشت و کارمزدها",
        },
      },
    }
  },
  computed: {
    ...mapGetters(["isBusinessActive"]),
    ipgHardReject() {
      return (
        ["ipg", "requestMoney"].includes(this.whichtool) &&
        this.$store.state.business.hasOwnProperty("tools") &&
        this.$store.state.business.tools.ipg?.status === "HARD_REJECT"
      )
    },
    isCustomerDiactivationDisabled() {
      return (
        this.$store.state.business.hasOwnProperty("tools") &&
        this.whichtool === "customer" &&
        this.$store.state.business.tools.subscription?.status !== "INACTIVE"
      )
    },
    getIcon() {
      if (this.whichtool === "p2p")
        return require("@/assets/img/newIcon/tools/p2p_new.svg")
      return require("@/assets/img/newIcon/tools/" + this.whichtool + ".svg")
    },
    grayImage() {
      return require("@/assets/img/tools/" + this.whichtool + ".svg")
    },
    redImage() {
      return require("@/assets/img/tools/" + this.whichtool + "Green.svg")
    },
    yellowImage() {
      return require("@/assets/img/tools/" + this.whichtool + "Yellow.svg")
    },
    tool: {
      get() {
        return this.tools[this.whichtool]
      },
      set(newVal) {
        this.tools[this.whichtool].enable = newVal
      },
    },
    StyleClassOfLabel() {
      return this.isBusinessActive
        ? "in-progress"
        : "wait-for-business-activation font_2"
    },
  },
  methods: {
    handleLoading(status) {
      this.loading = status
    },
    activeBtnClicked() {
      this.$emit("activeTool")
    },
  },
}
</script>

<style lang="scss" scoped>
.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}

.v-btn.small {
  color: $van_green !important;
}

.toolBadge {
  background: #a14e69;
  color: white;
  font-size: 11px;
  margin-right: 12px;
  border-radius: 16px;
  padding: 3.5px 8px;
}
.business {
  height: 75px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: 10px auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.business-xs {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: 10px auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.v-avatar {
  margin: 0 0 10px 0;
}

.titles {
  min-width: 133px;
}

h2 {
  margin: 6px 0 9px 0;
  line-height: 22px;
}

.income {
  font-size: 10px;
  color: #333333;
}

.bus-right {
  margin-left: 10px;
}

.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;

  &.bus-right,
  &.bus-left {
    width: 50%;
  }

  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}

.mablagh {
  font-size: 14px;
  font-weight: bold;
}

.sub-text {
  font-size: 9px;
  color: #999;
}

.btn-skeleton {
  width: 114px;
  height: 36px;
  background-color: $van_color10;
  border-radius: 5px;
  border: $van_color00 1px;
  margin-right: auto;
  margin-left: 0;
}

.in-progress,
.waitForContract,
.failed {
  color: $van_green;
  background-color: rgb($van_green, 0.1);
  width: 114px;
  height: 36px;
  padding: 9px 11px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  @media screen and (max-width: 960px) {
    margin-left: 0;
    margin-right: auto;
  }
}

.wait-for-business-activation {
  background: #fce9ac;
  padding: 9px 11px;
  border-radius: 4px;
}

.waitForContract {
  color: $van_color03;
  background-color: $van_color06;
}

.failed {
  color: $van_color04;
  background-color: rgb($van_color10, 1);
}

.btn_small_normal {
  margin-left: 0px;
  margin-right: 0px;
  width: 114px;
}

.v-tooltip {
  display: none;

  &__content {
    max-width: 320px;
    background-color: $van_color03;
    font-size: 11px;
    font-weight: 400;
    padding: 8px;
    text-align: center;
  }
}
</style>
