<template>
  <div>
    <v-layout class="cards" justify-start wrap>
      <v-flex class="full-width full-height">
        <setting-card @view-setting="viewSetting" />
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="settingModal"
      :content-class="editModal ? 'secondModal vanmodal' : 'vanmodal'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      transition="slide-x-transition"
    >
      <setting-management
        @open-edit="setOpenModal"
        @close-modal="closeSetting"
      />
    </v-dialog>
    <!-- modals -->
    <v-dialog
      ref="banksList"
      v-model="editModal"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      transition="slide-x-transition"
    >
      <application-edit @close-edit="editModal = false" />
      <v-card />
    </v-dialog>
  </div>
</template>

<script>
import SettingCard from "@/components/pbv/elements/index/SettingCard"
import SettingManagement from "@/components/pbv/elements/index/SettingManagement"
import ApplicationEdit from "@/components/pbv/elements/index/UpdateApplication"

export default {
  name: "Setting",
  components: {
    ApplicationEdit,
    SettingManagement,
    SettingCard,
  },
  data() {
    return {
      settingModal: false,
      application: null,
      editModal: false,
    }
  },
  mounted() {
    this.$http
      .get(
        `${this.$store.state.api_pbv_url}/business/${this.$store.state.business.business_name}/application/show`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        this.application = response.data.data.application
        if (this.application !== null) {
          this.$store.dispatch("setPbvApplication", this.application, {
            root: true,
          })
        }
        this.isLoad = true
      })
      .catch((error) => {
        //callsnackbar
      })
  },
  methods: {
    viewSetting() {
      this.settingModal = true
    },
    closeSetting() {
      this.settingModal = false
    },

    setOpenModal(val) {
      this.editModal = val
    },
  },
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.full-height {
  height: 100% !important;
}

.cards {
  margin-right: -5px;
  margin-left: -5px;

  > .flex {
    align-self: flex-end;
    height: 120px;
    width: 100%;
    margin: 5px 5px;
  }
}

@media (max-width: 1200px) {
  .layout {
    justify-content: center;
  }
}
</style>
