export default {
  methods: {
    reuseConvertCurrencyForRialAmount(amount) {
      if (this.$store.state.user.currency === "TOMAN") {
        return (amount = amount / 10)
      } else if (this.$store.state.user.currency === "RIAL") {
        return amount
      }
    },
    reuseConvertCurrencyForTomanAmount(amount) {
      if (this.$store.state.user.currency === "TOMAN") {
        return amount
      } else if (this.$store.state.user.currency === "RIAL") {
        return (amount = amount * 10)
      }
    },
  },
}
