<template>
  <v-layout column class="adding_sheba">
    <v-flex>
      <div class="font_2 mb-4" style="text-align: right">
        شماره شبای تسویه حساب شاپرک را وارد کنید:
      </div>
      <v-form :value="shebaValidation">
        <v-text-field
          ref="shebaField"
          v-model="shebaInputComputed"
          :rules="shebaInputRules"
          placeholder="XX XXXX XXXX XXXX XXXX XXXX XX"
          solo
          flat
          height="40"
          prefix="IR"
          maxlength="26"
          outlined
          @keydown="numberKeyHandler"
          @focus="focusShebaField"
          @blur="blurShebaField"
        >
          <template #message="message">
            <div v-html="message.message"></div>
          </template>
        </v-text-field>
        <transition-group v-if="isMobileMode || isInModal" name="list">
          <div
            v-for="(item, index) in shebaGuidanceItems"
            v-show="$store.state.newbusiness.shebaFieldFocusState"
            :key="index"
            class="align-center max-width-250"
          >
            <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
              info_outline
            </v-icon>
            <span class="font-11-no-bbb-18"> {{ item }} </span>
            <br v-if="index !== shebaGuidanceItems?.length - 1" />
          </div>
        </transition-group>
      </v-form>
      <div
        v-if="isShowBoxUnderShebaField"
        class="mx-0 mt-2"
        :class="
          error
            ? 'withError gray-text-box with-data'
            : 'gray-text-box with-data'
        "
      >
        <v-layout v-if="persianName && ibanOwner && !error" align-right>
          <v-flex
            style="
              max-width: 60px;
              padding: 15px;
              margin-left: 10px;
              border: 1px solid #eeeeee;
              background-color: white;
            "
          >
            <v-img
              size="30"
              :src="require(`@/assets/img/banks/${ibank}.svg`)"
            />
          </v-flex>
          <v-flex>
            <div class="font_2 bold">
              {{ ibanOwner }}
            </div>
            <div class="font_4">
              {{ persianName }}
            </div>
          </v-flex>
        </v-layout>
        <span v-else-if="error">{{ error }}</span>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapMutations } from "vuex"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "AddSheba",
  inject: ["shebaGuidanceItems"],
  props: {
    defaultIban: {
      type: Boolean,
      default: false,
    },
    forCustomer: {
      type: Boolean,
      default: false,
    },
    bankName: {
      type: String,
      default: null,
    },
    isInModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shebaValidation: false,
      shebaInput: "",
      shebaInputRules: [
        (v) =>
          (v && v?.length === 24) ||
          (v?.length < 24
            ? "<span>شبا را بصورت کامل وارد کنید</span>"
            : "<span>تعداد ارقام شبا (بدون احتساب IR) ۲۴ عدد می‌باشد.</span>"),
      ],
      ibank: null,
      persianName: "",
      ibanOwner: "",
      error: "",
      isAyandeh: false,
      ifAyandehOwner: null,
      loading: false,
    }
  },
  computed: {
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isShowBoxUnderShebaField() {
      return (
        (this.persianName &&
          this.ibanOwner &&
          !this.error &&
          this.shebaValidation) ||
        this.error
      )
    },
    shebaInputComputed: {
      get() {
        return this.shebaInput
      },
      set(newValue) {
        let inputtedValue
        if (newValue?.slice(0, 2) === "IR") {
          inputtedValue = newValue?.slice(2)
        } else inputtedValue = newValue

        if (!/^[IRir 0-9۰-۹-]*$/.test(inputtedValue)) {
          inputtedValue = ""
          this.shebaInput = ""
          this.error = ""
        }
        this.shebaInput = this.convertNumbers2English(inputtedValue).replace(
          /[a-zA-Z -]/g,
          ""
        )
        this.$refs.shebaField.lazyValue = this.shebaInput
        this.error = ""
        this.ibanOwner = ""
        this.shebaValidation = false
        if (this.shebaInput?.length >= 5) {
          if (!this.bankName) {
            this.checkBank()
          } else if (this.bankName === "ayandeh") {
            this.isAyandeh = this.checkAyandeh(
              parseInt(this.shebaInput?.slice(2, 5))
            )
            if (this.isAyandeh) {
              this.checkBank()
            } else {
              this.error = "شماره شبا وارد شده، بانک آینده نیست."
            }
          }
        }
      },
    },
    isOpenIpgModalStatus() {
      return this.$store.state.ipg.isOpenIpgModal
    },
  },
  watch: {
    isShowBoxUnderShebaField(val) {
      this.changeShowingBoxUnderShebaFieldState(val)
    },
    isOpenIpgModalStatus(val) {
      if (!val) this.resetErrors()
    },
    shebaValidation(val) {
      if (val) {
        this.$emit("is-sheba-valid", val)
      } else {
        this.$emit("is-sheba-valid", val)
        this.changeIpgFormSubmitBtnActiveState(false)
        this.changeOnboardingFirstBtnActiveState(false)
      }
    },
  },
  methods: {
    ...mapMutations(["changeIpgFormSubmitBtnActiveState"]),
    ...mapMutations("newbusiness", [
      "changeShebaFieldFocusState",
      "changeWageFieldShowingState",
      "changeShowingBoxUnderShebaFieldState",
      "changeOnboardingFirstBtnActiveState",
    ]),
    isPersonal() {
      if (this.$store.state.business.business_type === "personal") {
        return "مالک کسب‌وکار"
      }
      return "حقوقی شرکت"
    },
    checkAyandeh(item) {
      // this.ibank = parseInt(item.slice(5, 7))
      let ayandehProperties = this.$getMyBanksProperty.find(
        (element) => element.persianName === "بانک آینده"
      )

      if (ayandehProperties && item === ayandehProperties.code) return true
      return false
    },
    checkBank() {
      if (this.shebaInputComputed?.length >= 5) {
        this.ibank = parseInt(this.shebaInputComputed?.slice(2, 5))
        this.$getMyBanksProperty.forEach((element, index) => {
          if ("0" + this.ibank === element.code) {
            this.persianName = element.persianName
          }
        })
      } else {
        this.ibank = null
        this.persianName = ""
      }

      if (this.shebaInputComputed?.length === 24) {
        this.shebaValidation = false
        this.ifAyandehOwner = false
        const IBAN = "IR" + this.shebaInputComputed
        //validation Api

        servicesRepository(this)
          .checkServices.httpCheckIban({
            IBAN,
          })
          .then((response) => {
            this.ibanOwner =
              response.data.data.ibans.account_owner[0].firstName +
              " " +
              response.data.data.ibans.account_owner[0].lastName
            this.ifAyandehOwner = response.data.data.is_owner
            if (this.ifAyandehOwner === false)
              this.error =
                "شماره شبا وارد شده، به نام " + this.isPersonal() + " نیست."

            if (this.bankName != "ayandeh" || this.ifAyandehOwner) {
              this.error = ""
              this.shebaValidation = true
            }
            this.setIban(IBAN)
          })
          .catch((error) => {
            //callsnackbar
            this.shebaValidation = false
            this.changeIpgFormSubmitBtnActiveState(false)
            this.changeOnboardingFirstBtnActiveState(false)
            this.error = error
          })
      } else {
        this.shebaValidation = false
        this.ibanOwner = ""
        this.error = ""
      }
    },
    setIban(value) {
      this.$emit("set-iban", value)
    },
    focusShebaField() {
      this.changeShebaFieldFocusState(true)
      this.changeWageFieldShowingState(false)
    },
    blurShebaField() {
      this.changeShebaFieldFocusState(false)
    },
    resetErrors() {
      this.$refs.shebaField.reset()
    },
  },
}
</script>
<style lang="scss" scoped>
:deep(.v-input) {
  margin-right: 0 !important;
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
</style>
