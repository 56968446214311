<template>
  <v-layout justify-space-between align-center>
    <v-flex class="search-input backWhite" style="position: sticky">
      <v-text-field
        v-model.trim="searchQuery"
        solo
        flat
        height="36"
        outlined
        :placeholder="placeholder"
        :prepend-inner-icon="searchQuery ? 'close' : 'search'"
        @click:prepend-inner="searchQuery = ''"
      >
        <template #message="message">
          <div v-html="message.message"></div>
        </template>
      </v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchQuery: "",
      timeoutId: "",
    }
  },
  watch: {
    searchQuery(newValue) {
      clearTimeout(this.timeoutId)
      if (newValue?.length >= 2 || newValue === "") {
        this.timeoutId = setTimeout(() => {
          this.emitSearchQuery(newValue)
        }, 500)
      }
    },
  },
  created() {
    this.searchQuery = this.inputValue
  },
  methods: {
    emitSearchQuery(value) {
      this.$emit("searchClicked", value)
    },
  },
}
</script>

<style scoped></style>
