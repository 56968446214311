<template>
  <div
    :class="['main-box d-flex flex-column align-center', parentClass]"
    :style="[parentStyle, marginLastChildCss]"
    @click="$emit('clicked')"
  >
    <div v-if="cardTitle" class="font_5 bold mb-4 align-self-start">
      {{ cardTitle }}
    </div>
    <v-card
      outlined
      flat
      :style="customCardStyle"
      :class="[
        'w-100 main-card radius-4',
        customCardClass,
        $vuetify.breakpoint.xsOnly ? 'pa-6' : 'pa-8',
      ]"
    >
      <slot />
    </v-card>
  </div>
</template>

<script>
export default {
  name: "GeneralCard",
  props: {
    cardTitle: {
      type: String,
      default: "",
    },
    parentClass: {
      type: String,
      default: "mb-6",
    },
    parentStyle: {
      type: String,
      default: "",
    },
    customCardStyle: {
      type: String,
      default: "",
    },
    customCardClass: {
      type: String,
      default: "",
    },
    marginLastChild: {
      type: String,
      default: "0",
    },
  },
  computed: {
    marginLastChildCss() {
      return {
        "--margin-last-child": this.marginLastChild,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main-box:last-child {
  margin-bottom: var(--margin-last-child) !important;
}
:global(.main-card) {
  border: 1px solid $van_color11 !important;
  background-color: $van_color13 !important;
}
</style>
