<template>
  <chart-card-one-filter
    class="mmt-5"
    :title="kerdarLang.general.errorsResource"
    :show-filter="true"
    :growth-text="errorResourcesGrowth"
    :duration="localDuration"
    @changeFilter="changeErrorResourcesFilter($event)"
  >
    <one-bar-horizontal-bar-type2
      v-if="errorResourcesStatus"
      :x-value="errorResourcesTitle"
      :not-show-less-than="1"
      :y-value="errorResourcesValue"
      :y-result="errorResourcesTooltip"
      :y-unit="kerdarLang.general.error"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </chart-card-one-filter>
</template>

<script>
import OneBarHorizontalBarType2 from "@/components/kerdar/components/oneBarHorizontalBarType2"
import ChartCardOneFilter from "@/components/kerdar/components/chartCardOneFilter"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

const jmoment = require("moment-jalaali")
jmoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "PaymentFormErrorResources",
  components: {
    OneBarHorizontalBarType2,
    ChartCardOneFilter,
  },
  props: {
    duration: {
      type: Object,
      default: () => {},
    },
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      errorResourcesData: [],
      errorResourcesStatus: false,
      errorResourcesFilter: null,
      localDuration: [
        {
          name: this.kerdarLang.general.currentDay,
          value: "current_day",
        },
        {
          name: this.kerdarLang.general.currentWeek,
          value: "current_week",
        },
        {
          name: this.kerdarLang.general.lastDay,
          value: "last_day",
        },
        {
          name: this.kerdarLang.general.lastWeek,
          value: "last_week",
        },
      ],
    }
  },
  computed: {
    errorResourcesGrowth() {
      if (this.errorResourcesFilter) {
        let totalErrorCount = 0
        if (this.errorResourcesValue && this.errorResourcesValue.length) {
          for (let data of this.errorResourcesData) {
            totalErrorCount += data[1]
          }
        }
        if (!totalErrorCount) return null
        return (
          `${this.kerdarLang.general.at} ` +
          this.duration[this.errorResourcesFilter.value] +
          " " +
          totalErrorCount.toLocaleString() +
          ` ${this.kerdarLang.general.haveUnsuccessfulDeposit}`
        )
      }
      return null
    },
    errorResourcesTitle() {
      return this.errorResourcesData.map((item) => item[0])
    },
    errorResourcesValue() {
      return this.errorResourcesData.map((item) => Number(item[1]))
    },
    errorResourcesTooltip() {
      let totalErrorCount = 0
      if (this.errorResourcesValue && this.errorResourcesValue.length) {
        for (let data of this.errorResourcesData) {
          totalErrorCount += data[1]
        }
      }

      return this.errorResourcesData.map((item) => {
        let percentage =
          Math.round(
            ((100 * item[1]) / totalErrorCount + Number.EPSILON) * 100
          ) / 100
        let text =
          '<span style="font-weight: bold;color: rgb(212, 77, 66)">' +
          percentage +
          "%</span>"
        text += ` ${this.kerdarLang.general.becauseOf} ` + item[0]
        return text
      })
    },
  },
  methods: {
    clearErrorResourcesData() {
      this.errorResourcesStatus = false
      this.errorResourcesData = []
    },
    changeErrorResourcesFilter(item) {
      this.errorResourcesFilter = item
      this.clearErrorResourcesData()
      this.getErrorResourcesData(item)
    },
    async getErrorResourcesData(item) {
      const chartType = item.value
      if (!chartType) {
        return
      }
      const response = await kerdarServices(
        this.$http,
        this
      ).paymentForm.httpFormErrorResources(chartType)

      this.errorResourcesData = response.data.data
      this.errorResourcesStatus = true
    },
  },
}
</script>

<style scoped></style>
