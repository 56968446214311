<template>
  <v-card outlined class="multi-box">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal', { status: false })">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">سریال {{ item._id }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-layout column>
      <v-layout class="new-modal-boxes">
        <v-flex>
          <v-layout
            height="100%"
            align-stretch
            justify-center
            :class="
              item.status_code >= 200 && item.status_code < 400 ? '' : 'redBack'
            "
            :column="$vuetify.breakpoint.xsOnly"
          >
            <v-flex d-flex xs6 class="miniBox rightBold">
              <v-layout align-center justify-center column>
                <v-flex xs1>
                  <div class="font_27 text-center">
                    <div class="font_2">وضعیت</div>
                    <div class="pl-1 bold">
                      {{ status }}
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout align-center justify-center column>
                <v-flex
                  class="miniBox font_2"
                  d-flex
                  justify-space-between
                  align-center
                  style="position: relative"
                >
                  <span class="pl-4">شناسه یکتا:</span>
                  <span
                    style="
                      width: calc(100% - 104px);
                      text-align: left;
                      padding-left: 10px;
                    "
                  >
                    <span v-if="item.transaction_id != null">{{
                      item.transaction_id
                    }}</span>
                    <span v-else style="margin-left: -10px">--</span>
                  </span>

                  <v-btn
                    v-if="item.transaction_id != null"
                    ref="btn"
                    text
                    depressed
                    outlined
                    :class="'btn_small_normal tblCpy' + item.transaction_id"
                    style="
                      min-width: 32px !important;
                      margin: 0px !important;
                      padding: 0;
                      max-width: 32px;
                      height: 32px !important;
                    "
                    @click="shareLink()"
                  >
                    <v-icon color="van_color03" size="14">
                      content_copy
                    </v-icon>
                  </v-btn>
                  <v-tooltip v-model="tooltip_show" top>
                    <template #activator="{ on }">
                      <v-btn
                        style="
                          position: absolute;
                          opacity: 0 !important;
                          width: 1px;
                          margin: 0px;
                          padding: 0px;
                          z-index: -1;
                          left: 40px;
                        "
                        v-on="on"
                      />
                    </template>
                    <span>کپی شد</span>
                  </v-tooltip>
                  <input
                    :id="'show-hidden-input-' + item.transaction_id"
                    v-model="item.transaction_id"
                    class="hidden-input"
                  />
                </v-flex>
                <v-flex class="miniBox font_2">
                  <span class="pl-10">تاریخ:</span>
                  <span style="float: left">{{
                    dateTime(item.created_at)
                  }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <div style="margin: 34px">
        <!-- full_url -->
        <v-layout
          v-if="item.request.full_url && item.request.full_url != null"
          column
        >
          <v-flex class="font_28 mb-3"> آدرس درخواست </v-flex>
          <v-flex class="font_2 inner_data">
            <div>{{ item.request.full_url }}</div>
          </v-flex>
        </v-layout>

        <!-- IP -->
        <v-layout
          v-if="item.request.client_ip && item.request.client_ip != null"
          class="mt-4"
          column
        >
          <v-flex class="font_28 mb-3"> IP </v-flex>
          <v-flex class="font_2 inner_data">
            <div>{{ item.request.client_ip }}</div>
          </v-flex>
        </v-layout>

        <!-- method -->
        <v-layout
          v-if="item.channel && item.channel != null"
          class="mt-4"
          column
        >
          <v-flex class="font_28 mb-3"> کانال </v-flex>
          <v-flex class="font_2 inner_data">
            <div>{{ item.channel_name_fa }}</div>
          </v-flex>
        </v-layout>

        <!-- method -->
        <v-layout v-if="item.method && item.method != null" class="mt-4" column>
          <v-flex class="font_28 mb-3"> متد </v-flex>
          <v-flex class="font_2 inner_data">
            <div>{{ item.method }}</div>
          </v-flex>
        </v-layout>

        <!-- request body -->
        <v-layout
          v-if="item.request.body && item.request.body != null"
          class="mt-4"
          column
        >
          <v-flex class="font_28 mb-3"> مقادیر ورودی </v-flex>
          <v-flex class="font_2 inner_data">
            <div>
              <pre>{{ item.request.body }}</pre>
            </div>
          </v-flex>
        </v-layout>

        <!-- response body -->
        <v-layout
          v-if="item.response.body && item.response.body != null"
          class="mt-4"
          column
        >
          <v-flex class="font_28 mb-3"> مقادیر خروجی </v-flex>
          <v-flex class="font_2 inner_data">
            <div>
              <pre>{{ item.response.body }}</pre>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "Show",
  props: ["item", "secoundModal"],
  data() {
    return {
      tooltip_show: false,
      receipt_url_tooltip_show: false,
    }
  },
  computed: {
    status() {
      switch (this.item.status_code) {
        case 200:
          return "۲۰۰ | موفق"
        case 301:
          return "۳۰۱ | موفق"
        case 302:
          return "۳۰۲ | موفق"
        case 422:
          return "۴۲۲ | ورودی نادرست"
        case 401:
          return "۴۰۱ | توکن نامعتبر"
        case 400:
          return "۴۰۰ | درخواست نادرست"
        case 403:
          return "۴۰۳ | دسترسی ندارید"
        case 404:
          return "۴۰۴ | آدرس نادرست"
        case 405:
          return "۴۰۵ | متد نامعتبر"
        case 429:
          return "۴۲۹ | ترافیک درخواست"
        case 500:
          return "۵۰۰ | خطای سرور"
        case 503:
          return "۵۰۳ | اختلال موقت"
        default:
          if (this.item.status_code < 400) {
            return `${this.item.status_code} | موفق`
          } else if (
            this.item.status_code >= 400 &&
            this.item.status_code < 500
          ) {
            return `${this.item.status_code} | درخواست نادرست`
          } else if (
            this.item.status_code >= 500 &&
            this.item.status_code < 600
          ) {
            return `${this.item.status_code} | خطای سرور`
          }
          return "-"
      }
    },
  },
  methods: {
    dateTime(date) {
      const moment = require("moment-jalaali")
      return this.jalaliDate(date, "jYYYY/jM/jD - HH:mm")
    },

    shareLink() {
      /* Get the text field */
      let copyText = document.querySelector(
        "#show-hidden-input-" + this.item.transaction_id
      )

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        //Alert the copied text
        this.tooltip_show = true
        setTimeout(() => {
          this.tooltip_show = false
        }, 2000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.multi-box {
  .v-tooltip__content {
    min-width: auto !important;
    top: 39px !important;
    padding: 5px 8px !important;
  }
  > .layout > .flex:nth-child(3) {
    margin-top: -10px !important;
  }
}
.new-modal-boxes {
  margin: 30px 30px 10px;
  .miniBox {
    background-color: rgba(76, 159, 136, 0.1);
    border-radius: 4px;
    padding: 23px;
    margin: 5px;
    width: 100%;
    &.rightBold {
      margin-left: 10px;
    }
    > .layout {
      height: 100%;
    }
  }
  .redBack {
    .miniBox {
      background-color: rgba(212, 77, 66, 0.2);
    }
  }
}
.inner_data {
  direction: ltr;
  text-align: left;
  text-align-last: left;
  padding: 20px;
  border: 1px #f5f5f5 solid;
  border-radius: 4px;
  background-color: #ffffff;
}
</style>
