<template>
  <div class="mx-auto full-width" />
</template>

<script>
export default {
  name: `Callback`,
  data() {
    return {
      isLoaded: false,
      whichPage: "",
    }
  },
  computed: {},
  beforeMount() {
    if (this.$route.query.status === "SUCCEED") {
      this.getMandateInfo(this.$route.query.mandate_id)
    } else {
      this.$router.push({
        name: "personalHome",
        params: {
          approveMandate: true,
          callbackInfo: this.modalsInfo().find(
            (m) => m.status === this.$route.query.status
          ),
        },
      })
    }
  },
  methods: {
    modalsInfo() {
      return [
        {
          status: "SUCCEED",
          title: "اتصال به بانک برقرار شد",
        },
        {
          status: "FAILED_TO_ACCESS_BANK",
          title: "بانک در دسترس نیست",
          subtitle:
            "لطفا چند دقیقه بعد تلاش کرده یا بانک دیگری را انتخاب کنید.",
        },
        {
          status: "ACCOUNT_NOT_USABLE",
          title: "اتصال به این حساب امکان‌پذیر نیست",
          subtitle: "لطفا بانک دیگری را انتخاب کنید.",
        },
        {
          status: "MANDATE_RETRYABLE",
          title: "مشکلی پیش آمده...",
          subtitle: "لطفا بانک دیگری را انتخاب کنید.",
        },
      ]
    },

    getMandateInfo(authorizationId) {
      this.$http
        .get(
          this.$store.state.api_pbv_url + "/user/mandates?q=" + authorizationId,

          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "personalHome",
            params: {
              approveMandate: true,
              approvedMandateInfo:
                response?.data?.data?.mandates?.data[0] ?? null,
              callbackInfo: this.modalsInfo().find(
                (m) => m.status === "SUCCEED"
              ),
            },
          })
        })
        .catch((error) => {
          // call snackbar
        })
    },
  },
}
</script>

<style lang="scss">
.paymentLoading {
  h3 {
    color: #333333 !important;
  }

  .body1 {
    color: #666666 !important;
  }

  .countDown {
    display: inline-block;
    padding: 0 2px;
    width: 15px;
  }
}
</style>
