<template>
  <v-form ref="form1" class="right-error otp">
    <v-layout column>
      <v-flex style="direction: ltr">
        <v-otp-input
          ref="otp"
          v-model="otp"
          :length="typeOfOtp === 'sms' ? 5 : 6"
          type="tel"
          class="van_otp"
          @keydown="numberKeyHandler"
          @finish="$emit('setValue', otp)"
        />
      </v-flex>
    </v-layout>
    <div v-if="typeOfOtp === 'sms'" class="linkBorder">
      <v-btn
        class="small"
        text
        :ripple="false"
        :disabled="resend"
        :style="resend ? 'margin-left: 9px' : ''"
        @click="resendfunc()"
      >
        ارسال دوباره کد
        <span v-if="resend" id="countdown" style="padding-right: 3px">
          در {{ countdown }}
        </span>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  name: "Otp",
  props: {
    typeOfOtp: {
      type: String,
      default: "sms",
    },
  },
  data: function () {
    return {
      otp: null,
      countdown: 300,
      resend: true,
    }
  },
  watch: {
    otp: function (newValue) {
      this.otp = this.convertNumbers2English(newValue)
    },
  },
  created() {
    this.clearFrom(false)
  },
  mounted() {
    this.$refs.otp.focus()
  },
  methods: {
    clearFrom(afterCreate) {
      this.otp = null
      this.resend = true
      this.countdown = 300
      this.$emit("setValue", null)
      this.countdownfunc()
      if (afterCreate) {
        this.$refs.otp.focus()
      }
    },
    countdownfunc() {
      var downloadTimer = setInterval(() => {
        this.countdown -= 1
        if (this.countdown <= 0) {
          clearInterval(downloadTimer)
          this.resend = false
        }
      }, 1000)
    },
    resendfunc() {
      this.$http
        .post(
          this.$store.state.api_base_url +
            "/personal/update/two-factor-send-code",
          { two_factor_type: "sms" },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.clearFrom(true)
          this.$store.commit(
            "callSuccessSnackbar",
            "کد تایید برای شماره همراه شما ارسال شد."
          )
        })
        .catch((error) => {
          //callsnackbar
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input {
  margin-bottom: 0 !important;
}
.btn_wild {
  max-width: 270px;
  min-width: 270px;
}
</style>
