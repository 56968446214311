<template>
  <div>
    <v-layout column justify-center align-center>
      <v-flex class="mt-2">
        <img :src="require('@/assets/img/newIcon/ayandehLogo.svg')" />
      </v-flex>
      <v-flex class="font-size-16 font-weight-bold van_color02--text mt-3"
        >واریز بانکی</v-flex
      >
      <v-flex class="font_2 mt-2 text-center">
        از طریق بانک آینده، به صورت آنی کیف‌پول وندارتان را شارژ کنید.
        <v-icon style="width: 16px" @click="openDetails">
          mdi_info_outline
        </v-icon>
      </v-flex>
      <v-flex class="mt-4">
        <div
          v-if="$store.state.business.tools['cash-in'].status === 'PENDING'"
          class="waitForContract"
        >
          در انتظار مدارک
        </div>
        <v-btn
          v-else-if="$store.state.business.status === 1"
          text
          dark
          :loading="loading"
          class="btn_small_normal mr-0"
          @click="activeTools"
        >
          اتصال به بانک
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "CashInActivation",
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    openDetails() {
      this.$emit("showIntroduce")
    },
    activeTools() {
      this.$emit("openActiveTools")
    },
  },
}
</script>

<style lang="scss" scoped>
.waitForContract {
  color: $van_color03;
  background-color: $van_color06;
  width: 114px;
  height: 36px;
  padding: 9px 11px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  @media screen and (max-width: 960px) {
    margin-left: 0;
    margin-right: auto;
  }
}
</style>
