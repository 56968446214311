<template>
  <v-card class="singleField">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title v-if="edit" class="pr-0">
        اصلاح کد رهگیری مالیاتی
      </v-toolbar-title>
      <v-toolbar-title v-else class="pr-0"> کد رهگیری مالیاتی </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          :disabled="!valid"
          class="btn_small_normal"
          @click="sendForm()"
        >
          تایید و ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex>
        <v-form ref="form" v-model="valid">
          <div v-if="edit" class="font_10" style="text-align: center">
            کد رهگیری که قبلا اعلام کردید، توسط شاپرک تایید نشد. لطفا کد صحیح را
            در کادر زیر وارد کنید
          </div>
          <div v-else class="font_10" style="text-align: center">
            طبق اطلاعیه جدید بانک مرکزی و بر اساس ماده ۱۱ قانون «پایانه‌های
            فروشگاهی و سامانه مودیان» هر ابزار پذیرندگی باید کد رهگیری و پرونده
            مالیاتی داشته باشد
          </div>
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-center
            class="mt-7 mb-0"
          >
            <v-flex class="scopedWithlabel big">
              <tax-code
                :input-value="tax_code"
                place-holder="اینجا وارد کنید"
                @setValue="tax_code = $event"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>

      <!-- <v-flex class="info_panel py-0" style="margin-top: -20px">
        <v-layout class="my-5" align-center justify-space-between>
          <div class="font_2">
            <v-icon size="16" style="direction: rtl; width: 18px"
              >mdi_info_outline</v-icon
            >
            هنوز کد رهگیری مالیاتی دریافت نکردید؟
          </div>
          <div class="font_2 ma-0" style="text-align: left !important">
            <a class="linkBorder ma-0">راهنمای دریافت کد رهگیری مالیاتی</a>
          </div>
        </v-layout>
      </v-flex> -->
    </v-layout>
  </v-card>
</template>

<script>
import TaxCode from "@/components/inputs/taxCode"
export default {
  name: "TaxTrackingCode",
  components: { TaxCode },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      tax_code: null,
      valid: false,
      loading: false,
    }
  },
  watch: {
    tax_code: function (newValue) {
      if (newValue) {
        this.tax_code = this.convertNumbers2English(newValue).replace(
          /[^0-9]/g,
          ""
        )
        this.tax_code = this.tax_code.slice(0, 10)
        if (this.tax_code.length == 10 && this.$refs.taxCode)
          this.$refs.taxCode.blur()
      }
    },
  },
  methods: {
    sendForm() {
      this.loading = true
      var data = {
        tax_code: this.tax_code,
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/taxcode", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.commit("callSuccessSnackbar", response.data.message)
          this.$store.commit("updateUserOrBusinessValue", {
            key: "tax_code",
            value: this.tax_code,
          })
          this.$store.commit("updateTaxCodeStatus", "PENDING")
          if (!this.$store.state.isUser) {
            this.$store
              .dispatch(
                "retrieveBusinessObject",
                this.$store.state.business.business_name
              )
              .then((response) => {
                //DO Nothing
              })
              .catch((error) => {
                //callsnackbar
              })
          }
          this.$emit("closeModal")
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.scopedWithlabel {
  &.big {
    max-width: 340px;
    @media screen and (max-width: 599px) {
      width: 100%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 599px) {
    width: 100%;
    max-width: 100%;
  }

  .v-input {
    @media screen and (max-width: 599px) {
      width: 100% !important;
    }

    input {
      text-align: center;
    }
  }
}
</style>
