<template>
  <div />
</template>

<script>
export default {
  name: "Logout",
  created() {
    this.$store
      .dispatch("destroyToken")
      .then((response) => {
        this.$router.push("/enter").catch(() => {})
      })
      .catch((error) => {
        this.$router.push("/enter").catch(() => {})
        //callsnackbar
      })
  },
}
</script>
