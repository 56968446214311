<template>
  <!-- responsive table -->
  <v-layout
    v-if="$vuetify.breakpoint.smAndDown"
    align-space-between
    justify-space-between
    fill-height
    :class="rowClass(trans.state)"
    @click="showDetail(trans)"
  >
    <v-flex class="right-number font_2">{{ trans.rowNumber }}</v-flex>
    <v-flex>
      <v-layout column>
        <v-flex class="mmb-5">
          <v-layout>
            <!-- right-top part Start-->
            <v-flex>
              <div style="display: flex; align-items: center">
                <span>
                  {{ trans.title }}
                </span>
              </div>
              <div class="font-size-11 van_color03--text">
                {{ trans.id }}
              </div>
            </v-flex>
            <!-- right-top part End-->
            <!-- left-top part Start-->
            <v-flex style="text-align: left">
              <span class="text-right font_2">
                {{ trans.priceToman | currency }} {{ currencyName }}
              </span>
            </v-flex>
            <!-- left-top part End-->
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout :column="$vuetify.breakpoint.xsOnly" class="font_2">
            <!-- first description part Start-->
            <v-flex style="max-width: 250px">
              {{ trans.state }}
            </v-flex>
            <v-divider
              vertical
              :column="$vuetify.breakpoint.xsOnly"
              class="mmy-0 mmx-5"
            ></v-divider>
            <v-flex style="max-width: 220px">
              {{ jalaliDate(trans.updated_at * 1000) }}
            </v-flex>
            <v-flex style="max-width: 220px">
              {{ trans.buyer }}
            </v-flex>
            <v-flex style="max-width: 220px">
              {{ trans.seller }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>

  <!-- table -->
  <!--  -->
  <tr v-else :class="rowClass(trans.state)" @click="showDetail(trans)">
    <!-- 1 -->
    <td class="text-center">
      <div class="d-flex justify-center">{{ trans.rowNumber }}</div>
    </td>

    <!-- 2 -->
    <td class="text-right padding-right-0 inner">
      <div style="display: flex; align-items: center">
        <span>
          {{ trans.title }}
        </span>
      </div>
      <div class="font-size-11 van_color03--text">
        {{ trans.id }}
      </div>
    </td>

    <!-- 3 -->
    <td class="text-right inner">{{ trans.state }}</td>

    <!-- 4 -->
    <!-- succeed 1 AND failed -1 -->
    <td class="text-right tdes inner">
      {{ trans.priceToman | currency }}
    </td>

    <!-- 5 -->
    <td class="text-right inner">{{ trans.buyer }}</td>

    <!-- 6 -->
    <td class="text-right">{{ trans.seller }}</td>

    <!-- 7 -->
    <td class="text-right">{{ jalaliDate(trans.updated_at * 1000) }}</td>
  </tr>
</template>

<script>
export default {
  name: "MiandoTableRow",
  components: {
    // cancelSettlement,
  },
  props: ["transaction"],
  data() {
    return {
      tooltip_show: false,
      mouseIsOnBtn: false,
    }
  },
  computed: {
    trans() {
      return this.transaction
    },
  },
  methods: {
    showDetail(item) {
      if (!this.mouseIsOnBtn) this.$emit("showDetail", item)
    },
    rowClass(state) {
      switch (state) {
        case "رد شده":
        case "لغو شده":
          return "failed big"
        case "ثبت مشکل شده":
          return "pending big"
        default:
          return "succeed big"
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.inner {
  padding-left: 55px !important;
}
</style>
