<template>
  <v-card class="singleBox detailSettlement">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal', { status: false })">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">توضیحات بیشتر</v-toolbar-title>
    </v-toolbar>

    <v-layout v-if="!isOwner" column>
      <v-flex>
        <v-layout column class="rows">
          <v-flex class="font_2 mb-0" style="border-bottom: none">
            <p class="text-justify font_2">
              طبق اعلام بانک مرکزی در تاریخ ۹ تیرماه ۱۴۰۱ مبنی بر محدودیت سقف
              تراکنش واریز وجه، واریز به حساب‌هایی غیر از حساب حقوقی یا حساب
              مالکان کسب‌وکارهای حقیقی، روزانه تا سقف ۱۰۰ میلیون تومان
              امکان‌پذیر است.
            </p>
            <v-card outlined elevation="0" class="px-5 py-4 van_color06">
              واریز به حساب مالک کسب‌وکار حقیقی یا حساب حقوقی کسب‌وکار حقوقی
              همچنان نامحدود است.
            </v-card>
          </v-flex>
          <v-flex v-if="dailySettlementLimit">
            <v-layout class="bold" align-center wrap>
              <v-flex> واریزی امروز </v-flex>
              <v-flex>
                <v-flex
                  style="text-align-last: left !important; margin-right: 15px"
                >
                  <span dir="ltr">{{
                    parseInt(dailySettlementLimit[0].settled_amount / 10)
                      | currency
                  }}</span>

                  {{ currencyName }}
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="dailySettlementLimit">
            <v-layout class="bold" align-center wrap>
              <v-flex> باقی مانده امروز </v-flex>
              <v-flex>
                <v-flex
                  style="text-align-last: left !important; margin-right: 15px"
                >
                  <span dir="ltr">{{
                    parseInt(dailySettlementLimit[0].remain_amount / 10)
                      | currency
                  }}</span>

                  {{ currencyName }}
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout v-else>
      <v-flex>
        <v-layout column class="rows">
          <v-flex class="font_2 mb-0" style="border-bottom: none">
            <p class="text-justify font_2 mb-0">
              واریز به حساب مالک کسب‌وکار حقیقی یا حساب حقوقی کسب‌وکار حقوقی
              شامل محدودیت سقف تراکنش واریز روزانه نبوده و نامحدود است.
            </p>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "DetailDailySettlementForIban",
  props: ["isOwner", "dailySettlementLimit"],
  data: function () {
    return {}
  },
}
</script>
