<template>
  <left-modal
    ref="leftModal"
    toolbar-name="خروجی تراکنش‌ها"
    @closeModal="$emit('closeModal')"
  >
    <template>
      <!-- new export box skeleton -->
      <template v-if="getListLoading">
        <general-card class="downloadManager">
          <v-layout class="column mb-4 van_green_shade_1 py-5 px-4">
            <v-flex class="font_34">
              <span style="width: 70%" class="default_gray mx-auto" />
            </v-flex>
            <v-flex class="mt-3">
              <v-layout class="font_2" wrap>
                <v-flex>
                  <span style="width: 70%" class="default_gray" />
                </v-flex>
                <span class="mx-3">|</span>
                <v-flex>
                  <span style="width: 70%" class="default_gray" />
                </v-flex>
                <span class="mx-3">|</span>
                <v-flex>
                  <span style="width: 70%" class="default_gray" />
                </v-flex>
                <span class="mx-3">|</span>
                <v-flex>
                  <span style="width: 70%" class="default_gray" />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <span
            style="width: 110px; height: 36px"
            class="default_gray mx-auto radius-4"
          />
        </general-card>

        <v-flex v-for="n in 4" :key="n" class="mb-4">
          <general-card class="">
            <v-layout column class="column">
              <v-layout align-center justify-space-between>
                <v-flex class="font_2 bold">
                  <span style="width: 30%" class="default_gray" />
                </v-flex>

                <v-flex style="max-width: fit-content">
                  <span
                    style="width: 110px; height: 36px; border-radius: 4px"
                    class="default_gray mx-auto"
                  />
                </v-flex>
              </v-layout>
              <v-layout class="font_2" wrap>
                <v-layout class="font_2" wrap>
                  <v-flex>
                    <span style="width: 70%" class="default_gray" />
                  </v-flex>
                  <span>|</span>
                  <v-flex>
                    <span style="width: 70%" class="default_gray" />
                  </v-flex>
                  <span>|</span>
                  <v-flex>
                    <span style="width: 70%" class="default_gray" />
                  </v-flex>
                  <span>|</span>
                  <v-flex>
                    <span style="width: 70%" class="default_gray" />
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-layout>
          </general-card>
        </v-flex>
      </template>

      <!-- new export box-->
      <general-card
        v-else-if="
          !recentlyDownloaded && !(downloadLoading || haveInitDownload)
        "
        :custom-card-style="'border:1px dashed #e1e1e1!important'"
      >
        <v-layout column>
          <v-flex class="d-flex flex-column align-center justify-center">
            <v-layout
              class="column mb-4 van_green_shade_1 py-5 px-4 w-100 radius-4"
              align-center
            >
              <v-flex class="font_34"> خروجی جدید: {{ newFileName }} </v-flex>
              <v-flex class="mt-3">
                <v-layout class="font_2 d-block">
                  <v-flex class="d-inline">
                    {{ getText(kindFilterItem, kindFilter) }}
                  </v-flex>
                  <span class="mx-4">|</span>
                  <v-flex class="d-inline">
                    {{ getText(statusFilterItem, statusFilters) }}
                  </v-flex>
                  <span class="mx-4">|</span>
                  <v-flex
                    v-if="channelFilterText && channelFilterText.length !== 0"
                    class="d-inline"
                  >
                    <span
                      v-for="(filter, index) in channelFilterText"
                      :key="index"
                    >
                      <span v-if="index === 0">{{ filter }}</span>
                      <span v-if="index === 1">
                        + {{ channelFilter.length - 1 }} ابزار دیگر
                      </span>
                    </span>
                  </v-flex>
                  <v-flex v-else class="d-inline"> همه ابزارها </v-flex>
                  <span class="mx-4">|</span>
                  <v-flex class="d-inline">
                    {{ durationText }}
                  </v-flex>
                  <template
                    v-if="
                      search != '' &&
                      search != null &&
                      search != 'VANDAR_ACCOUNTING_EXCEL_EXPORT'
                    "
                  >
                    <span class="mx-4">|</span>
                    <v-flex
                      v-if="search != '' && search != null"
                      class="d-inline"
                    >
                      {{ search }}
                    </v-flex>
                  </template>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-btn
              text
              dark
              justify-center
              class="btn_small_normal"
              @click="callExportApi()"
            >
              درخواست خروجی
            </v-btn>
          </v-flex>
        </v-layout>
      </general-card>

      <!--  export list  -->
      <template v-if="!getListLoading">
        <general-card v-for="(exp, index) in exports" :key="index + exp">
          <v-layout
            align-center
            justify-space-between
            class="pb-4 mb-5"
            :style="
              initDownload(exp)
                ? 'padding-bottom:0'
                : 'border-bottom: 1px dashed #bbbbbb'
            "
          >
            <v-flex class="font_2 bold">
              {{ exp.file_name_fa || exp.file_name }}
              <span v-if="failedDownload(exp)" class="failed_download"
                >ناموفق</span
              >
            </v-flex>
            <v-flex style="max-width: fit-content">
              <v-btn
                text
                :loading="initDownload(exp)"
                :dark="
                  initDownload(exp) || (doneDownload(exp) && !isDownloaded(exp))
                "
                :depressed="failedDownload(exp) || isDownloaded(exp)"
                :outlined="failedDownload(exp) || isDownloaded(exp)"
                class="btn_small_normal downloadBtn ml-0"
                style="width: 80px"
                @click="
                  failedDownload(exp)
                    ? retry(exp)
                    : initDownload(exp)
                    ? ''
                    : downloadFile(exp)
                "
              >
                <span v-if="failedDownload(exp)">تلاش دوباره</span>
                <span v-else>دریافت</span>
              </v-btn>
            </v-flex>
          </v-layout>

          <v-layout
            v-if="initDownload(exp)"
            class="van_color11 mb-2"
            align-center
          >
            <v-flex class="infoBox pr-3 py-3 w-100">
              <v-layout wrap>
                <v-flex style="text-align-last: right; line-height: 22px">
                  <v-icon color="van_color03" size="20" class="ml-2">
                    error_outline
                  </v-icon>
                  پس از آماده‌سازی، فایل خروجی به صورت خودکار دریافت می‌شود.
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout v-else class="filters-container font_2 d-block">
            <template
              v-if="
                !exp.filters.q ||
                exp.filters.q != 'VANDAR_ACCOUNTING_EXCEL_EXPORT'
              "
            >
              <v-flex v-if="exp.filters.hasOwnProperty('statusKind')">
                {{ getText(kindFilterItem, exp.filters.statusKind) }}
              </v-flex>
              <v-flex v-else>
                {{ getText(kindFilterItem, 0) }}
              </v-flex>
              <span class="mx-3">|</span>
              <v-flex v-if="exp.filters.hasOwnProperty('status')">
                {{ getText(statusFilterItem, exp.filters.status) }}
              </v-flex>
              <v-flex v-else>
                {{ getText(statusFilterItem, 0) }}
              </v-flex>
              <span class="mx-3">|</span>
              <v-flex
                v-if="
                  exp.filters.hasOwnProperty('channel') &&
                  exp.filters.channel.length !== 0
                "
              >
                <span
                  v-for="(filter, index) in channelToArray(exp.filters.channel)"
                  :key="index"
                >
                  <span v-if="index === 0">{{
                    getText(channelItem, filter)
                  }}</span>
                  <span v-if="index === 1">
                    + {{ channelToArray(exp.filters.channel).length - 1 }} ابزار
                    دیگر
                  </span>
                </span>
              </v-flex>
              <v-flex v-else> همه ابزارها </v-flex>
              <span class="mx-3">|</span>
              <v-flex>
                {{ getDate(exp) }}
              </v-flex>
              <span v-if="exp.filters.hasOwnProperty('q')">|</span>
              <v-flex v-if="exp.filters.hasOwnProperty('q')">
                {{ exp.filters.q }}
              </v-flex>
            </template>
            <template v-else>
              <v-flex>واریز و برداشت‌های موثر در کیف‌پول </v-flex>
              <span class="mx-3">|</span>
              <v-flex>
                {{ handleAccountantText(exp) }}
              </v-flex>
            </template>
          </v-layout>
        </general-card>
      </template>
      <!--info box-->
      <general-card v-if="!getListLoading" :custom-card-class="'px-10 py-4'">
        <v-layout class="infoBox pa-0" wrap>
          <v-flex style="text-align-last: right; line-height: 22px">
            <v-icon size="16" color="#333333"> error_outline </v-icon>
            همواره دریافت 12 خروجی‌ آخر شما در این بخش مقدور خواهد بود
          </v-flex>
        </v-layout>
      </general-card>
    </template>
  </left-modal>
</template>

<script>
import { mapState } from "vuex"
import socket from "../../../socket"
import leftModal from "@/components/modals/leftModal.vue"
import generalCard from "@/components/elements/generalCard.vue"
import { dateTimeHanddler } from "@/mixins/dateTimeHanddler.js"

const jmoment = require("moment-jalaali")

export default {
  name: "DownloadManager",
  props: {
    page: {
      type: [Number, String],
      default: 1,
    },
    rowsPerPage: {
      type: [Number, String],
      default: 20,
    },
    search: {
      type: [String, Object],
      default: null,
    },
    accountantCall: {
      type: Boolean,
      default: false,
    },
    downloadOnMounted: {
      type: Boolean,
      default: false,
    },
    selectedSearchType: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      exports: [],
      fileName: null,
      currentUrl: null,
      clickOnDownload: false,
      downloadLoading: false,
      getListLoading: true,
      fromDateValue: null,
      toDateValue: null,
      recentlyDownloaded: false,
      channelItem: [
        {
          key: "ipg",
          value: "درگاه پرداخت",
        },
        {
          key: "settlements",
          value: "تسویه و تسهیم",
        },
        {
          key: "refund",
          value: "بازگشت وجه",
        },
        {
          key: "form",
          value: "فرم پرداخت",
        },
        {
          key: "subscription",
          value: "پرداخت خودکار",
        },
        {
          key: "pos",
          value: "کارتخوان",
        },
        {
          key: "p2p",
          value: "انتقال وجه",
        },
        {
          key: "cash-in",
          value: "واریز بانکی",
        },
        {
          key: "dedicated_channel",
          value: "حساب اختصاصی",
        },
        {
          key: "cash-in-by-code",
          value: "واریز با شناسه",
        },
      ],
      statusFilterItem: [
        {
          key: "0",
          value: "همه وضعیت‌ها",
        },
        {
          key: "succeed",
          value: "موفق",
        },
        {
          key: "failed",
          value: "ناموفق",
        },
        {
          key: "pending",
          value: "در صف بانک",
        },
        {
          key: "canceled",
          value: "برگشتی",
        },
      ],
      kindFilterItem: [
        {
          key: "0",
          value: "همه تراکنش‌ها",
        },
        {
          key: "transactions",
          value: "واریز",
        },
        {
          key: "settlements",
          value: "برداشت",
        },
      ],
      dateItem: [
        {
          key: "0",
          value: "از ابتدای ثبت‌نام تاکنون",
        },
        {
          key: "yesterday",
          value: "دیروز",
        },
        {
          key: "day",
          value: "روز جاری",
        },
        {
          key: "week",
          value: "هفته جاری",
        },
        {
          key: "month",
          value: "ماه جاری",
        },
        {
          key: "year",
          value: "سال جاری",
        },
        {
          key: "5",
          value: "بازه زمانی دلخواه",
        },
      ],
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
    }
  },
  computed: {
    newFileName() {
      if (
        this.exports &&
        this.exports.length > 0 &&
        this.exports[0].file_name_fa
      ) {
        let split = this.exports[0].file_name_fa.split(" - ")
        return split[0] + " - " + (parseInt(split[1]) + 1)
      } else {
        let name = this.$store.state.isUser
          ? this.$store.state.user.name
          : this.$store.state.business.business_name_fa
        return name + " - " + 1
      }
    },
    haveInitDownload() {
      if (!this.exports || this.exports.length === 0) return false
      let itemWithInit = this.exports.filter((item) => this.initDownload(item))
      return itemWithInit.length > 0
    },
    durationText() {
      if (this.duration != 5) return this.getText(this.dateItem, this.duration)
      else {
        return this.getDuration(
          this.calcFullYear(this.toDate),
          this.calcFullYear(this.fromDate)
        )
      }
    },
    channelFilterText() {
      let result = []
      if (this.channelFilter != null) {
        this.channelFilter.forEach((el) => {
          this.channelItem.forEach((element) => {
            if (element.key == el) {
              result.push(element.value)
            }

            if (el === "ipg,mpg-ipg" && element.key === "ipg") {
              result.push(element.value)
            }
          })
        })
      }
      return result
    },
    ...mapState({
      kindFilter: (state) => state.report.kindFilter,
      statusFilters: (state) => state.report.statusFilters,
      channelFilter: (state) => state.report.channelFilter,
      fromDate: (state) => state.report.fromDate,
      toDate: (state) => state.report.toDate,
      duration: (state) => state.report.duration,
    }),
  },
  mounted() {
    this.getLastExports()

    if (this.downloadOnMounted) {
      this.callExportApi()
    }
  },
  created() {
    socket.on("export_excel", (payload) => {
      this.changeExports(payload)
    })
  },
  methods: {
    channelToArray(channels) {
      return channels.split(",")
    },
    getDate(item) {
      if (item) {
        let createdAt = item.created_at
        // createdAt = createdAt.substr(2)
        createdAt = createdAt.split(" ")[0]
        createdAt = createdAt?.replaceAll("/", "")
        if (item.filters.duration) {
          let startOfYear, startOfMonth, startOfWeek
          switch (item.filters.duration) {
            case "year":
              startOfYear = createdAt.substr(0, 4) + "0101"
              return this.getDuration(createdAt, startOfYear)
            case "month":
              startOfMonth = createdAt.substr(0, 6) + "01"
              return this.getDuration(createdAt, startOfMonth)
            case "week":
              startOfWeek = jmoment(item.created_at, "jYYYY/jMM/jDD HH:mm:ss")
              startOfWeek = startOfWeek.startOf("week").format("jYYYYjMMjDD")
              return this.getDuration(createdAt, startOfWeek)
            case "day":
              return this.convertDateToText(createdAt)
            case "yesterday":
              return "دیروز"
          }
        } else if (
          item.filters.hasOwnProperty("toDate") ||
          item.filters.hasOwnProperty("fromDate")
        ) {
          let fromDate = item.filters.hasOwnProperty("fromDate")
            ? item.filters.fromDate
            : null
          let toDate = item.filters.hasOwnProperty("toDate")
            ? item.filters.toDate
            : createdAt
          let result = fromDate
            ? this.getDuration(toDate, fromDate)
            : "از ابتدای ثبت‌نام " + this.getDuration(toDate, fromDate)
          return result
        } else {
          return "از ابتدای ثبت‌نام تا " + this.convertDateToText(createdAt)
        }
      }
    },
    calcFullYear(date) {
      if (date) {
        if (date.slice(0, 2) > 90) {
          return "13" + date
        } else {
          return "14" + date
        }
      }
      return date
    },
    changeExports(payload) {
      let current = this.exports.find((item) => item.uuid == payload.data.uuid)
      if (current) {
        let index = this.exports.indexOf(current)
        this.exports[index] = payload.data
        const tempExport = this.exports
        this.exports = []
        this.exports = tempExport
      }
    },
    isDownloaded(item) {
      return item.is_downloaded === 1
    },
    initDownload(item) {
      return item.status === "INIT"
    },
    failedDownload(item) {
      return item.status === "FAILED"
    },
    doneDownload(item) {
      return item.status === "DONE"
    },
    retry(item) {
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            "/transaction/export/retry?uuid=" +
            item.uuid,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then(() => {
          this.exports.filter((element) => element == item).status = "INIT"
          this.$emit("exportsChange")
        })
    },
    getLastExports(item) {
      this.getListLoading = true
      this.$http
        .get(this.$store.getters.apiUrlParam + "/transaction/report-filter", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.exports = response.data.data
          this.getListLoading = false
        })
        .catch((error) => {
          this.getListLoading = false
          //callsnackbar
        })
    },
    imageExists(file_url) {
      let http = new XMLHttpRequest()

      http.open("HEAD", file_url, false)
      http.send()

      return http.status
    },
    downloadFile(data) {
      if (data.uuid) {
        this.$http
          .get(
            this.$store.getters.apiWithVersionParam("3") +
              "/transaction/export/download?uuid=" +
              data.uuid,
            {
              timeout: 0,
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then(() => {
            // this.callInternalSnackBar()
            window.open(data.download_url)
            this.getLastExports()
          })
      } else {
        window.open(data.download_url)
        // this.callInternalSnackBar()
      }
    },
    fillDate() {
      //add date
      let toDateValue
      if (this.toDate != null) {
        // toDateValue = this.calcFullYear(this.toDate)
        toDateValue = this.toDate
      }

      let fromDateValue
      if (this.fromDate != null) {
        // fromDateValue = this.calcFullYear(this.fromDate)
        fromDateValue = this.fromDate
      }

      if (this.duration && this.duration != 5 && this.duration != 0) {
        let today = new Date()
        // toDateValue = jmoment(today).format("jYYYY/jMM/jDD")
        toDateValue = jmoment(today).format("jYY/jMM/jDD")
        toDateValue = toDateValue?.replaceAll("/", "")

        // let jmomentFromDate = jmoment(toDateValue, "jYYYY/jMM/jDD")
        //   .format("jYYYY/jMM/jDD")
        //   .replaceAll("/", "")
        let jmomentFromDate = jmoment(toDateValue, "jYY/jMM/jDD")
          .format("jYY/jMM/jDD")
          ?.replaceAll("/", "")

        switch (this.duration) {
          case "day":
            fromDateValue = toDateValue
            break
          case "week":
            // fromDateValue = jmoment(toDateValue, "jYYYY/jMM/jDD")
            //   .startOf("week")
            //   .format("jYYYY/jMM/jDD")
            fromDateValue = jmoment(toDateValue, "jYYYY/jMM/jDD")
              .startOf("week")
              .format("jYY/jMM/jDD")
            break
          case "month":
            fromDateValue = jmomentFromDate.substr(2, 6) + "01"
            // fromDateValue = jmomentFromDate.substr(0, 6) + "01"
            break
          case "year":
            fromDateValue = jmomentFromDate.substr(2, 4) + "0101"
            // fromDateValue = jmomentFromDate.substr(0, 4) + "0101"
            break
        }
        fromDateValue = fromDateValue?.replaceAll("/", "")
      }
      return { fromDate: fromDateValue, toDate: toDateValue }
    },
    callExportApi() {
      // fill date
      let date = this.fillDate()

      //make data
      let dataSample = {
        filters: {
          fromDate: date.fromDate,
          page: "1",
          per_page: this.rowsPerPage,
          toDate: date.toDate,
        },
        file_name: this.newFileName,
        file_name_fa: this.newFileName,
        status: "INIT",
        is_downloaded: false,
        download_url: null,
        uuid: null,
        created_at: jmoment(new Date()).format("jYYYY/jMM/jDD HH:mm:ss"),
      }
      if (!this.downloadOnMounted) this.exports.unshift(dataSample)

      this.clickOnDownload = true
      this.downloadLoading = true
      let url
      url =
        "/transaction/export?page=" +
        this.page +
        "&per_page=" +
        this.rowsPerPage
      if (this.kindFilter != "0") {
        url = url + "&statusKind=" + this.kindFilter
      }
      if (this.statusFilters != "0") {
        url = url + "&status=" + this.statusFilters
      }

      //add channel
      let channelF = "&channel="
      if (this.channelFilter != null) {
        this.channelFilter.forEach((element, index) => {
          channelF = channelF + element
          if (index != this.channelFilter.length - 1) channelF = channelF + ","
        })
        url = url + channelF
      }

      //add duration
      if (this.duration != "0" && this.duration != "5") {
        url = url + "&duration=" + this.duration
      }

      //add date
      if (date.fromDate != null) {
        url = url + "&fromDate=" + date.fromDate
      }

      if (date.toDate != null) {
        url += `&toDate=${date.toDate}`
      }
      //add search query
      if (this.search !== null) {
        url += `&q=${this.search}`
        if (this.selectedSearchType)
          url += `&search_field=${this.selectedSearchType}`
      }

      this.$http
        .get(this.$store.getters.apiWithVersionParam("3") + url, {
          timeout: 0,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$refs.leftModal.callInternalSnackBar(
            "succeed",
            "خروجی در حال دریافت است، لطفا صبر کنید..."
          )
          const data = response.data.data
          //replace uID
          if (!this.downloadOnMounted) this.exports.shift()
          this.exports.unshift(data)
          this.$emit("exportsChange")
          this.recentlyDownloaded = true
          this.downloadLoading = false
        })
        .catch((error) => {
          this.downloadLoading = false
          if (error && error !== "" && this.$refs.leftModal)
            this.$refs.leftModal.callInternalSnackBar("failed", error)
        })
        .finally(() => (this.downloadLoading = false))
    },
    closeModal() {
      this.recentlyDownloaded = false
      this.$emit("closeModal", { status: false })
    },
    handleAccountantText(exp) {
      let end = this.endOfMonth(exp.filters.toDate.substring(4, 6))
      if (exp.filters.toDate.substring(6, 8) != end) {
        let fromDateText = this.convertDateToText(exp.filters.fromDate)
        let toDateText = this.convertDateToText(exp.filters.toDate)

        return fromDateText + " تا " + toDateText
      }

      let monthText = ""
      if (exp.filters.toDate.substring(4, 5) === "0") {
        monthText =
          this.monthItem[parseInt(exp.filters.toDate.substring(5, 6)) - 1]
      } else {
        monthText =
          this.monthItem[parseInt(exp.filters.toDate.substring(4, 6)) - 1]
      }

      return " ماه " + monthText
    },
    getDuration(toDate, fromDate) {
      let finalResult = ""
      if (fromDate && toDate && this.duration === "day") {
        finalResult = this.convertDateToText(fromDate)
      } else {
        if (fromDate) {
          let text = this.convertDateToText(fromDate)
          finalResult = " از " + text
        }
        if (toDate) {
          let text1 = this.convertDateToText(toDate)
          finalResult = finalResult + " تا " + text1
        }
      }
      return finalResult
    },
    getText(arr, val) {
      let result
      arr.forEach((element) => {
        if (element.key == val) {
          result = element.value
        }
      })
      return result
    },
    convertDateToText(str) {
      //year
      let yearText = str.substring(0, 4)

      //month
      let monthText
      if (str.substring(4, 5) == "0") {
        monthText = this.monthItem[parseInt(str.substring(5, 6)) - 1]
      } else {
        monthText = this.monthItem[parseInt(str.substring(4, 6)) - 1]
      }

      //day
      let dayText
      if (str.substring(6, 7) == "0") {
        dayText = str.substring(7, 8)
      } else {
        dayText = str.substring(6, 8)
      }

      //make text
      let result = dayText + " " + monthText + " " + yearText

      if (str?.length === 12) {
        result += " - " + str.substring(8, 10) + ":" + str.substring(10, 12)
      }
      return result
    },
  },
  components: { leftModal, generalCard },
  mixins: [dateTimeHanddler],
}
</script>

<style lang="scss" scoped>
.failed_download {
  width: 46px;
  height: 24px;
  font-size: 11px;
  color: white;
  background: #d44d42;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
}
.filters-container {
  text-align-last: right;
  > * {
    display: inline;
  }
}
</style>
