var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$vuetify.breakpoint.smAndDown)?_c('tbody',_vm._l((10),function(i){return _c('v-layout',{key:i,staticClass:"big",attrs:{"align-space-between":"","justify-space-between":"","fill-height":""}},[_c('v-flex',{staticClass:"right-number font_2"},[_c('span',{staticClass:"default_gray",staticStyle:{"width":"20%"}})]),_c('v-flex',[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"mmb-5"},[_c('v-layout',[_c('v-flex',[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"default_gray",staticStyle:{"max-width":"60%"}})])]),_c('v-flex',{staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"default_gray",staticStyle:{"float":"left","max-width":"30%"}})])],1)],1),_c('v-flex',[_c('v-layout',{staticClass:"font_2",attrs:{"column":_vm.$vuetify.breakpoint.xsOnly}},[_c('v-flex',{staticStyle:{"max-width":"250px"}},[_c('div',[_c('span',{staticClass:"default_gray"})]),_c('div',[_c('span',{staticClass:"default_gray",staticStyle:{"max-width":"60%"}})])]),_c('v-divider',{staticClass:"mmy-0 mmx-5",attrs:{"vertical":"","column":_vm.$vuetify.breakpoint.xsOnly}}),_c('v-flex',[_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"default_gray",staticStyle:{"max-width":"50%"}})])])],1)],1)],1)],1)],1)}),1):_c('tbody',_vm._l((10),function(n,index){return _c('tr',{key:index,staticClass:"big"},[_vm._m(0,true),_vm._m(1,true),_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true)])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center",staticStyle:{"width":"70px"}},[_c('span',{staticClass:"default_gray",staticStyle:{"width":"36px","height":"36px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right padding-right-0"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"default_gray",staticStyle:{"width":"80%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right padding-right-0"},[_c('span',{staticClass:"default_gray",staticStyle:{"width":"50%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right padding-right-0"},[_c('span',{staticClass:"default_gray",staticStyle:{"width":"50%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right padding-right-0 big"},[_c('span',{staticClass:"default_gray",staticStyle:{"width":"50%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right padding-right-0 big"},[_c('span',{staticClass:"default_gray"})])
}]

export { render, staticRenderFns }