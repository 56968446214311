var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ChartCard',{staticClass:"mmt-5",attrs:{"title":_vm.kerdarLang.overView.comparisoningOfIncome}},[(_vm.averageStatus)?_c('OneBarHorizontalBar',{attrs:{"x-value":[
      _vm.kerdarLang.overView.lastDayIncome,
      _vm.createDynamicTextForAverageDaily(7),
      _vm.createDynamicTextForAverageDaily(30),
      _vm.createDynamicTextForAverageDaily(120),
      _vm.createDynamicTextForAverageDaily(365),
    ],"y-value":_vm.calculateYaxisValueAverage(),"round-value":true}}):_c('div',{staticClass:"skeleton_box2 mmt-10",staticStyle:{"min-height":"276px"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }