<template>
  <v-layout class="emptytool" justify-center align-center fill-height>
    <v-flex>
      <span class="back">
        <v-img :src="require('@/assets/img/empty_subscription@2x.png')" />
      </span>
      <v-card outlined height="100%" width="100%">
        <v-layout
          justify-center
          align-center
          :column="$vuetify.breakpoint.smAndDown"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'flex-direction: column-reverse;'
              : ''
          "
        >
          <v-flex>
            <h2>دریافت حق‌اشتراک، به صورت خودکار</h2>
            <div
              style="
                max-width: 258px;
                margin: 20px 0px 20px 20px;
                text-align: justify;
              "
              class="font_2"
            >
              برای دریافـت حق اشـتراک دوره‌ای از کاربران تنها کافی است یک بار به
              صورت آنلاین اجازه‌ی برداشت مستقیم از حسـاب بانـکی کاربر را
              بگیـرید. هر مـاه بی‌دردسـر و شفاف کاربر آگاه می‌شود و حق اشتراک از
              حساب بانکی او کم خواهد شد
            </div>
            <div>
              <v-btn
                ref="btn"
                text
                dark
                class="btn_small_normal"
                style="margin-left: -3px"
                @click="openAddOrModal()"
              >
                ایجاد اشتراک
              </v-btn>
              <v-btn
                ref="btn"
                text
                outlined
                class="btn_small_normal"
                target="_blank"
                @click="pushToDoc()"
              >
                معرفی ابزار
              </v-btn>
            </div>
          </v-flex>
          <v-flex
            :style="
              !$vuetify.breakpoint.smAndDown
                ? 'max-width:358px;margin-right: 44px;'
                : 'max-width:358px;width:100%;'
            "
          >
            <v-img
              :src="require('@/assets/img/empty_subscription.png')"
              width="358"
              height="314"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>

    <!-- modals -->
    <v-dialog
      v-model="status"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <Info v-show="info" :key="componentKey" @closeModal="close_modal" />
      <Photo v-show="photo" :key="componentKey" @closeModal="close_modal" />
    </v-dialog>

    <v-dialog
      v-model="avatarAndCityRequireModal"
      width="360"
      content-class="confirmationMessage height226 padd20"
      class="pa-5"
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/close-circle-outline.svg')"
              width="40"
              height="40"
            />
          </v-flex>
          <v-flex class="main-title font_20"> متاسفانه </v-flex>
          <v-flex class="main-des font_2">
            اطلاعات کسب‌و‌کار و لوگوی شما کامل نیست. لطفا پیش از ادامه عملیات
            اطلاعات
            <b>شهر و استان</b>
            خود را بروز نمایید
          </v-flex>
          <v-flex>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal mt-2"
              @click="openRequireCenterModal()"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="avatarRequireModal"
      width="360"
      content-class="confirmationMessage height226 padd20"
      class="pa-5"
    >
      <v-card outlined height="100%">
        <v-layout column justify-center align-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/close-circle-outline.svg')"
              width="40"
              height="40"
            />
          </v-flex>
          <v-flex class="main-title font_20"> متاسفانه </v-flex>
          <v-flex class="main-des font_2">
            لوگوی کسب‌و‌کار شما ثبت نشده است. لطفا پیش از ادامه عملیات اطلاعات
            <b>لوگوی</b>
            خود را بروز نمایید
          </v-flex>
          <v-flex>
            <v-btn
              text
              depressed
              outlined
              class="btn_small_normal mt-2"
              @click="openRequireCenterModalJustLogo()"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Info from "../../modals/settingBusinessInfo.vue"
import Photo from "../../modals/settingPhoto.vue"
export default {
  name: "Empty",
  components: {
    Info,
    Photo,
  },
  data() {
    return {
      componentKey: 0,
      avatarRequireModal: false,
      avatarAndCityRequireModal: false,
      //this is modal status
      status: false,
      info: false,
      photo: false,
    }
  },
  methods: {
    open_info() {
      this.close_modal()
      this.info = true
      this.status = true
    },
    open_photo() {
      this.close_modal()
      this.photo = true
      this.status = true
    },
    close_modal(obj) {
      this.photo = false
      this.info = false
      this.status = false
      if (obj && obj.status) this.componentKey = !this.componentKey
    },
    openRequireCenterModal() {
      this.avatarAndCityRequireModal = false
      this.open_info()
    },
    openRequireCenterModalJustLogo() {
      this.avatarRequireModal = false
      this.open_photo()
    },
    pushToDoc() {
      var url = this.$store.state.base_url + "/subscribe"
      window.open(url)
    },
    openAddOrModal() {
      if (this.$store.state.business.avatar == null) {
        if (this.$store.state.business.city_id == null) {
          this.avatarAndCityRequireModal = true
        } else {
          this.avatarRequireModal = true
        }
      } else {
        this.$emit("add_subscription")
      }
    },
  },
}
</script>
