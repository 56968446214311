<template>
  <div>
    <vandar-table />
  </div>
</template>

<script>
import VandarTable from "@/components/pbv/checkouts/elements/vandarTable"

export default {
  name: "Checkouts",
  components: { VandarTable },
}
</script>
