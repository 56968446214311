<template>
  <div v-if="mountee" class="activationBlock">
    <span :class="pendingStatus ? 'topBack pendingTopBack' : 'topBack '" />
    <v-layout v-if="pendingStatus" class="mx-auto" style="margin: -20px">
      <v-flex class="personal_title alldone">
        <v-layout justify-space-between align-center>
          <v-flex>
            <v-layout align-center>
              <v-flex style="max-width: 60px">
                <v-img
                  :src="require('@/assets/img/newIcon/pendingBusiness.svg')"
                  alt="avatar"
                  width="50"
                />
              </v-flex>
              <v-flex>
                <div
                  v-if="
                    $store.state.user.accept_user_time_prediction !=
                    'call operation'
                  "
                  class="font_5"
                >
                  در حال بررسی اطلاعات و مدارک حساب شخصی
                </div>
                <div v-else class="font_5" style="line-height: 22px">
                  بررسی حساب شما بیش از زمان تعیین شده طول کشیده است. با عرض
                  پوزش، به سرعت در حال پیگیری هستیم
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="
              $store.state.user.accept_user_time_prediction != 'call operation'
            "
          >
            <div
              v-if="
                $store.state.user.accept_user_time_prediction &&
                $store.state.user.accept_user_time_prediction.includes(':') &&
                time != 0
              "
              style="text-align: left"
            >
              <span class="font_18"> حداکثر زمان انتظار: </span>
              <span
                class="font_12"
                style="
                  font-size: 20px !important;
                  color: #333 !important;
                  display: inline-block;
                  width: 90px;
                "
              >
                {{ convertToTime }}
              </span>
            </div>
            <div v-else style="text-align: left" class="font_7">
              در انتظار تایید وندار
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout v-else-if="mountee" :column="$vuetify.breakpoint.xsOnly">
      <v-flex class="personal_title">
        <h1>حساب شخصی فعال نیست</h1>
        <span class="under_title">رسیدگی به موارد مقابل، الزامی است</span>
        <!-- modals -->
        <v-dialog
          v-model="status"
          content-class="vanmodal"
          transition="slide-x-transition"
          :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
          :fullscreen="$vuetify.breakpoint.xsOnly"
          no-click-animation
        >
          <addMobileOwnerCard
            v-show="addmobileownercard"
            @closeModal="close_modal()"
          />
          <settingInfo v-show="addinfo" @closeModal="close_modal()" />
        </v-dialog>
        <!-- modals -->
      </v-flex>
      <v-layout column align-center class="boxes">
        <v-flex>
          <mustEditCard
            v-if="$store.state.user.statusBox.kyc == false"
            @click.native="add_info()"
          >
            کد ملی شما با نام و تاریخ تولدتان همخوانی ندارد
          </mustEditCard>
          <pendingCard v-else-if="$store.state.user.statusBox.kyc == null">
            بررسی اطلاعات هویتی
          </pendingCard>
        </v-flex>
        <v-flex>
          <mustEditCard
            v-if="$store.state.user.statusBox.shahkar == false"
            @click.native="add_nationalcard_photo()"
          >
            <span>
              بارگذاری کارت ملی صاحب سیم‌کارت
              <span class="van_color04--text">(تنها اقوام درجه یک)</span>
            </span>
          </mustEditCard>
          <pendingCard v-else-if="$store.state.user.statusBox.shahkar == null">
            بررسی هویت صاحب سیم‌کارت
          </pendingCard>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import mustEditCard from "../../statusBox/mustEdit.vue"
import pendingCard from "../../statusBox/pending.vue"
import addMobileOwnerCard from "../../modals/addMobileOwnerCard.vue"
import settingInfo from "../../modals/settingInfo.vue"
export default {
  name: "ActivationBlock",
  components: {
    addMobileOwnerCard,
    settingInfo,
    mustEditCard,
    pendingCard,
  },
  data() {
    return {
      //this is modal status
      status: false,
      mountee: false,
      addmobileownercard: false,
      addinfo: false,
      time: 0,
    }
  },
  computed: {
    pendingStatus() {
      return (
        this.$store.state.user.statusBox.kyc === true &&
        this.$store.state.user.statusBox.shahkar === true
      )
    },
  },
  mounted() {
    this.mountee = true
    this.businessActiveCountDown()
  },
  methods: {
    convertToTime() {
      var date = new Date(null)
      if (!this.time) this.time = 0
      date.setSeconds(this.time) // specify value for SECONDS here
      var hour = parseInt(this.time / 3600)
      var result = date.toISOString().substr(13, 6)
      result = hour == 0 ? "00" + result : hour + "" + result
      return result
    },
    businessActiveCountDown() {
      var secondTime = 0
      var startTime = this.$store.state.user.accept_user_time_prediction
        ? this.$store.state.user.accept_user_time_prediction
        : "00:00:00"
      var sec = parseInt(startTime.split(":")[2])
      var min = parseInt(startTime.split(":")[1])
      var hour = parseInt(startTime.split(":")[0])

      secondTime = hour * 3600 + min * 60 + sec

      this.countdownfunc(secondTime)
    },
    countdownfunc(startPoint) {
      var downloadTimer = setInterval(() => {
        startPoint -= 1
        if (startPoint <= 0) {
          clearInterval(downloadTimer)
        }
        this.time = startPoint
      }, 1000)
    },
    close_modal() {
      this.addmobileownercard = false
      this.addinfo = false
      this.status = false
    },
    add_nationalcard_photo() {
      this.close_modal()
      this.addmobileownercard = true
      this.status = true
    },
    add_info() {
      this.close_modal()
      this.addinfo = true
      this.status = true
    },
  },
}
</script>

<style lang="scss" scoped></style>
