<template>
  <v-card
    outlined
    color="#fff"
    height="100%"
    class="pa-8 justify-center flex-column radius-4"
    flat
  >
    <h2 class="mb-4 text-center">به دنیای پرداخت بدون کارت خوش آمدید!</h2>
    <div class="d-flex justify-center">
      <v-img
        class="mb-4"
        :src="require('@/assets/img/onboarding/onboarding.gif')"
        height="196"
        width="215"
        max-height="196"
        max-width="215"
      />
    </div>
    <div class="font_5 van_color03--text text-center mb-4">
      با استفاده از «پرداخت با وندار» خریدهای بعدی را تنها با یک کلیک بپردازید.
      با اتصال حساب بانکی به حساب کاربری‌تان در وندار، دیگر نیازی به شماره کارت،
      CVV2 و رمز پویا نخواهید داشت!
    </div>
    <div class="text-center">
      <v-btn text dark class="btn_small_normal" @click="$emit('openPbvModal')">
        اتصال به بانک </v-btn
      ><v-btn
        text
        outlined
        class="btn_small_normal"
        @click="$emit('onboardingModal')"
      >
        بازگشت
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Onboarding",
}
</script>
<style lang="scss" scoped>
:deep(.v-image__image) {
  background-size: 226% !important;
}
</style>
