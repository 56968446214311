<template>
  <v-layout column>
    <v-flex
      v-if="
        !$store.state.customer.selectedCustomer.ibans ||
        $store.state.customer.selectedCustomer.ibans.length == 0
      "
    >
      <v-layout class="shebaList" column>
        <!-- add iban -->
        <v-flex>
          <v-layout align-center>
            <v-flex style="max-width: 56px; margin-left: 10px">
              <v-avatar color="van_color11" size="56">
                <img
                  style="max-width: 24px"
                  :src="require('@/assets/img/newIcon/ibans.svg')"
                />
              </v-avatar>
            </v-flex>
            <v-flex>
              <div class="font_2">
                برای تسویه به این مشتری، شماره شبا اضافه کنید
              </div>
            </v-flex>
            <v-flex>
              <v-btn
                text
                dark
                class="btn_small_normal"
                @click="open_addsheba()"
              >
                افزودن شماره شبا
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <!-- iban list -->
    <div v-else>
      <v-flex
        v-for="(iban, index) in $store.state.customer.selectedCustomer.ibans"
        :key="index"
        class="ibanList"
      >
        <v-layout align-center>
          <v-flex
            style="
              max-width: 40px;
              height: 40px;
              padding: 6px;
              margin-left: 10px;
              border: 1px solid #eeeeee;
            "
          >
            <v-img
              v-if="iban && iban.hasOwnProperty('iban')"
              width="25"
              class="ma-auto"
              :src="
                require(`@/assets/img/banks/${parseInt(
                  iban.iban.slice(4, 7)
                )}.svg`)
              "
            />
          </v-flex>
          <v-flex>
            <!-- <div class="font_2 bold">
                {{ iban.account_owner[0].firstName }}
                {{ iban.account_owner[0].lastName }}
              </div> -->
            <div
              v-if="iban && iban.hasOwnProperty('iban')"
              class="font_2"
              style="text-align-last: right"
            >
              {{ iban.iban }}
            </div>
          </v-flex>
          <v-spacer />
          <v-spacer />
          <v-flex style="max-width: 40px; margin-left: -10px">
            <v-menu
              transition="slide-y-transition"
              offset-y
              close-on-content-click
              bottom
              content-class="up_menu"
            >
              <!-- this on slot sometime is undefined -->
              <template #activator="{ on }">
                <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                  <v-icon
                    color="van_color03"
                    size="21"
                    style="line-height: 40px"
                  >
                    more_vert
                  </v-icon>
                </v-btn>
              </template>

              <v-card outlined class="drop_down_menu">
                <v-list class="menu_v_list">
                  <!-- <v-list-tile
                        :to="{
                          name: 'businessInvoice',
                          params: {
                            filtered: 'allTransaction',
                            uuid: auth.authorization_id,
                          },
                        }"
                      >
                      <v-list-tile-content>
                          <v-list-tile-title>مشاهده فاکتورها</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile> -->
                  <!-- <v-list-tile
                        :to="{
                          name: 'businessInvoice',
                          params: {
                            filtered: 'successfullTransaction',
                            uuid: auth.authorization_id,
                          },
                        }"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title
                            >درخواست برداشت</v-list-tile-title
                          >
                        </v-list-tile-content>
                      </v-list-tile> -->
                  <v-list-item @click="deleteIban(iban, index)">
                    <v-list-item-content>
                      <v-list-item-title>حذف شبا</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-flex>
      <observer @intersect="intersected" />
    </div>

    <div
      v-if="
        $store.state.customer.selectedCustomer.ibans &&
        $store.state.customer.selectedCustomer.ibans.length > 0
      "
      class="mt-5"
      style="padding: 0 20px"
    >
      <v-btn text dark class="btn_small_normal" @click="open_addsheba()">
        افزودن شماره شبا
      </v-btn>
    </div>

    <!-- modals -->
    <v-dialog
      v-show="status"
      v-model="status"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <addSheba
        :key="componentKey"
        for-customer
        @closeModal="close_modal"
        @setIban="set_iban"
      />
    </v-dialog>

    <GeneralDialog
      v-if="deleteStatus"
      :active="deleteStatus"
      btn1-text="بله، حذف شود"
      btn1-class="btn_small_normal error"
      btn2-text="خیر، حذف نشود"
      custom-style="height:311px"
      @btn1Clicked="sendDeleteIban()"
      @btn2Clicked="deleteStatus = false"
    >
      <template slot="extra">
        <v-layout column align-center class="mt-10 mb-2">
          <v-flex class="main-title font_1 mb-5">
            از حذف شماره شبا اطمینان دارید؟
          </v-flex>
          <v-flex
            class="van_color11 font_8 pa-5"
            style="border-radius: 4px; width: 300px"
          >
            <v-layout justify-space-between align-center>
              <v-flex class="float-right"> صاحب حساب </v-flex>
              <v-flex class="text-left font_2">
                {{ accountOwnerFirstName }}
              </v-flex>
            </v-layout>
            <v-divider class="my-2" style="border-style: dashed" />
            <v-layout justify-space-between align-center>
              <v-flex class="float-right"> شماره شبا </v-flex>
              <v-flex class="text-left font_2">
                {{ selectedIban?.iban }}
              </v-flex>
            </v-layout>
            <v-divider class="my-2" style="border-style: dashed" />
            <v-layout justify-space-between align-center>
              <v-flex class="float-right"> بانک </v-flex>
              <v-flex class="text-left font_2">
                {{ selectedIban?.bank_name }}
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </GeneralDialog>
  </v-layout>
</template>

<script>
import addSheba from "@/components/modals/addSheba.vue"
import observer from "@/components//elements/observer.vue"
import GeneralDialog from "../../modals/generalDialog.vue"
import servicesRepository from "@/services/servicesRepository"
import lang from "@/lang/languageResource.json"
import { mapActions } from "vuex"

export default {
  name: "Ibans",
  components: {
    addSheba,
    observer,
    GeneralDialog,
  },
  props: {
    ibanItemsPerPage: {
      type: Number,
      default: 0,
    },
    ibanItemsTotal: {
      type: Number,
      default: 0,
    },
    ibanLastPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      componentKey: 0,
      deleteStatus: false,
      status: false,
      endOfLoadMore: false,
      iban: null,
      currentPage: 1,
      selectedIban: null,
      selectedIndex: null,
      lang,
    }
  },
  computed: {
    accountOwnerFirstName() {
      return this.selectedIban?.account_owners
        ? this.selectedIban?.account_owners[0]?.firstName
        : ""
    },
  },
  watch: {
    status: function (newValue) {
      if (!newValue) {
        this.$store.commit("callSecondEditCustomerModal", false)
      }
    },
  },
  methods: {
    ...mapActions(["retrieveSelectedCustomerIbans"]),
    open_addsheba() {
      this.status = true
      this.$store.commit("callSecondEditCustomerModal", true)
    },
    close_modal(obj) {
      this.status = false
      this.$store.commit("callSecondEditCustomerModal", false)
    },
    set_iban(iban) {
      this.iban = "IR" + iban

      const payload = { iban: this.iban, has_inquiry: true, is_default: true }
      servicesRepository(this)
        .customers.httpAddCustomerIban(payload)
        .then((response) => {
          //this.form = response.data.data.form
          // this.$emit('addForm', response.data.data.form)
          this.$store.commit(
            "callSuccessSnackbar",
            lang.customer.creatingIbanSuccessMessage
          )
          this.retrieveSelectedCustomerIbans()
          this.iban = null
          this.close_modal()
          this.componentKey = !this.componentKey
        })
        .catch((error) => {
          this.$store.commit("callsnackbar", error)
        })
    },
    intersected() {
      if (this.ibanItemsTotal > this.ibanItemsPerPage) {
        let nextPage = this.currentPage + 1
        if (nextPage <= this.ibanLastPage) {
          this.retrieveSelectedCustomerIbans(nextPage)
            .then((response) => {
              this.currentPage = response.current_page
              if (response.last_page == response.current_page) {
                this.endOfLoadMore = true
              }
            })
            .catch((error) => {
              //callsnackbar
            })
        }
      }
    },
    deleteIban(iban, index) {
      this.selectedIban = iban
      this.selectedIndex = index
      this.deleteStatus = true
    },
    sendDeleteIban() {
      servicesRepository(this)
        .customers.httpDeleteCustomerIban(this.selectedIban.iban)
        .then((response) => {
          this.$store.commit("callSuccessSnackbar", response.data.message)
          this.retrieveSelectedCustomerIbans()
          this.deleteStatus = false
        })
        .catch((error) => {
          this.$store.commit("callsnackbar", error)
          this.deleteStatus = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.ibanList {
  margin-bottom: 0 !important;
  padding: 20px 30px !important;

  &:not(:first-child) {
    margin: 0px 30px !important;
  }
}
</style>
