const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpUpdateRoleUser(queryParam, payload) {
    return context.$http.post(
      context.$store.getters.apiUrlParam + "/iam/" + queryParam + "/update",
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpAddUser(payload) {
    return context.$http.post(
      context.$store.getters.apiUrlParam + "/iam/store",
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetRolePermissions() {
    return context.$http.get(
      `${context.$store.state.api_base_url}/role-permissions`
    )
  },
})
