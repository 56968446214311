<template>
  <div>
    <v-layout
      v-if="
        $store.state.business.hasOwnProperty('tools') &&
        $store.state.business.tools.miando.status === 'ACTIVE'
      "
      column
      wrap
      justify-center
      align-start
    >
      <v-flex class="mb-7" style="width: 100%">
        <miando-table />
      </v-flex>
    </v-layout>
    <v-layout v-else justify-center align-center fill-height>
      <v-layout column wrap justify-center align-start>
        <!-- guide -->
        <v-flex class="mt-4 mb-15" style="width: 100%">
          <guide />
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import Guide from "../elements/guide.vue"
import MiandoTable from "../elements/vandarTable"

export default {
  name: "MiandoMainPage",
  components: {
    MiandoTable,
    Guide,
  },
  data() {
    return {
      //
    }
  },
}
</script>
