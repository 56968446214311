<template functional>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4_1189)">
      <path
        d="M10.7333 2.11111C10.6444 2.11111 10.6444 2.11111 10.5555 2.11111C10.5555 2.11111 10.5555 2.11111 10.4666 2.11111C10.2888 2.11111 10.2 2.11111 10.0222 2.11111L10.2 3.88889C10.5555 3.88889 11 3.88889 11.3555 3.88889C14.9111 4.15556 17.8444 7 18.0222 10.6444C18.2 14.5556 15.1777 17.9333 11.2666 18.1111C11.2666 18.1111 11.1777 18.1111 11.0888 18.1111C10.8222 18.1111 10.4666 18.1111 10.2 18.1111L10.1111 19.8889C10.4666 19.8889 10.8222 19.8889 11.2666 19.8889C11.3555 19.8889 11.5333 19.8889 11.6222 19.8889C16.4222 19.5333 20.0666 15.4445 19.8888 10.6444C19.7111 6.11112 16.0666 2.55556 11.6222 2.2C11.4444 2.2 11.3555 2.2 11.1777 2.2C11 2.11111 10.9111 2.11111 10.7333 2.11111ZM7.62217 2.73334C7.17773 3 6.73328 3.17778 6.28884 3.44445L7.26662 4.95556C7.53329 4.77778 7.88884 4.6 8.2444 4.42223L7.62217 2.73334ZM4.33328 5.13334C3.97773 5.48889 3.71106 5.93334 3.4444 6.28889L4.95551 7.17778C5.13328 6.91111 5.39995 6.64445 5.66662 6.28889L4.33328 5.13334ZM2.46662 8.33334C2.28884 8.77778 2.19995 9.31112 2.19995 9.75556L3.97773 10.0222C4.06662 9.66667 4.06662 9.22223 4.2444 8.86667L2.46662 8.33334ZM3.97773 11.8889L2.19995 12.0667C2.19995 12.1556 2.19995 12.2444 2.19995 12.3333C2.28884 12.6889 2.37773 13.1333 2.46662 13.4889L4.15551 12.9556C4.06662 12.6889 3.97773 12.3333 3.97773 11.9778V11.8889ZM4.95551 14.7333L3.4444 15.7111C3.71106 16.1556 3.97773 16.5111 4.33328 16.8667L5.66662 15.7111C5.39995 15.3556 5.13328 15.0889 4.95551 14.7333ZM7.26662 17.0444L6.28884 18.5556C6.73328 18.8222 7.17773 19 7.62217 19.2667L8.33328 17.6667C7.88884 17.4 7.53329 17.2222 7.26662 17.0444Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_1189">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(0.333252 0.333344)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "InprogressSvg",
}
</script>

<style lang="scss" scoped></style>
