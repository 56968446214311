<template>
  <v-layout column>
    <v-flex class="pa-7 mb-1">
      <v-layout align-center>
        <v-flex>
          <v-layout justify-space-between>
            <v-flex style="text-align-last: right">
              <v-layout style="align-items: center">
                <v-flex style="max-width: 52px">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      width: 40px;
                      height: 40px;
                      border-radius: 10px 10px 0px 10px;
                      background-color: #e1e1e1;
                    "
                  >
                    <v-img
                      max-width="20"
                      max-height="20"
                      class="ma-auto"
                      :src="require('@/assets/img/newIcon/ascending_pack.svg')"
                    />
                  </div>
                </v-flex>
                <v-flex>
                  <div class="font_9">
                    <span
                      v-if="loading"
                      style="width: 100px; height: 1em"
                      class="default_gray"
                    />

                    <span v-else>
                      {{
                        ($store.state.customer.selectedCustomer.balance / 10)
                          | currency
                      }}
                      {{ currencyName }}
                    </span>
                  </div>
                  <div class="font_14">کیف‌پول</div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex style="text-align-last: left">
              <v-btn
                outlined
                class="btn_small_normal mx-0"
                @click="showChangeWallet()"
              >
                تغییر موجودی
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex v-if="desc" class="py-5 mt-2">
      <v-layout align-center justify-space-between>
        <div class="font_2" style="text-align: right">
          <v-icon
            size="16"
            style="direction: rtl; width: 18px; margin-left: 4px"
          >
            mdi_info_outline </v-icon
          >این کیف پول، حساب و کتاب داخلی شما با مشتری است
        </div>
        <div class="font_2 mb-0" style="text-align: left !important">
          <a class="linkBorder" @click="showWalletDescription()"
            >بیشتر بخوانید</a
          >
        </div>
      </v-layout>
    </v-flex>

    <!-- modals -->
    <v-dialog
      v-model="modalStatus"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <change-wallet
        v-show="changeWalletModal"
        :key="componentKey"
        @closeModal="closeModal"
      />
      <wallet-description
        v-show="WalletDescriptionModal"
        :key="componentKey"
        @closeModal="closeModal"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import changeWallet from "../modals/changeWallet.vue"
import walletDescription from "../modals/walletDescription.vue"
export default {
  name: "WalletBalance",
  components: {
    changeWallet,
    walletDescription,
  },
  props: ["desc", "loading"],
  data() {
    return {
      componentKey: 0,
      modalStatus: false,
      changeWalletModal: false,
      WalletDescriptionModal: false,
    }
  },
  watch: {
    modalStatus: function (newValue) {
      if (!newValue) this.$store.commit("callSecondEditCustomerModal", false)
    },
  },
  methods: {
    showChangeWallet() {
      this.closeModal()
      this.modalStatus = true
      this.changeWalletModal = true
      this.$store.commit("callSecondEditCustomerModal", true)
    },
    showWalletDescription() {
      this.closeModal()
      this.modalStatus = true
      this.WalletDescriptionModal = true
    },
    closeModal(obj) {
      this.modalStatus = false
      this.changeWalletModal = false
      this.WalletDescriptionModal = false
      this.$store.commit("callSecondEditCustomerModal", false)
      if (obj && obj.status) this.componentKey = !this.componentKey
    },
  },
}
</script>
