<template>
  <v-form>
    <CreatingIpgFirstPart
      :is-in-modal="isInModal"
      class="creating-ipg-step"
      @first-form-validation="firstFormValidation"
    />
    <CreatingIpgSecondPart
      :is-in-modal="isInModal"
      class="creating-ipg-step mt-2"
      @second-form-validation="secondFormValidation"
    />
    <CreatingIpgThirdPart
      :is-in-modal="isInModal"
      class="creating-ipg-step mt-2"
      @third-form-validation="thirdFormValidation"
    />

    <div class="creating-ipg-step mt-2">
      <AddSheba
        :is-in-modal="isInModal"
        default-iban
        @is-sheba-valid="isShebaValid = $event"
        @set-iban="iban = $event"
      />
    </div>
    <div class="creating-ipg-step mt-2">
      <uploadBox
        label-text="لوگوی کسب‌و‌کار"
        :aspect-ratio="'1'"
        :max-width="400"
        :max-height="400"
        @uploadedfile="uploadedFile = $event"
      />
      <transition-group v-if="isMobileMode || isInModal" name="list">
        <div
          v-for="(item, index) in logoGuidanceItems"
          v-show="$store.state.newbusiness.uploadFieldFocusState"
          :key="index"
          class="align-center max-width-250"
        >
          <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
            info_outline
          </v-icon>
          <span class="font-11-no-bbb-18"> {{ item }} </span>
          <br v-if="index !== logoGuidanceItems.length - 1" />
        </div>
      </transition-group>
    </div>
  </v-form>
</template>

<script>
import uploadBox from "@/components/elements/uploadBox.vue"
import AddSheba from "../AddSheba.vue"
import CreatingIpgFirstPart from "./CreatingIpgFirstPart.vue"
import CreatingIpgSecondPart from "./CreatingIpgSecondPart.vue"
import CreatingIpgThirdPart from "./CreatingIpgThirdPart.vue"
import { mapState, mapMutations } from "vuex"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "CreatingIpgStep",
  components: {
    uploadBox,
    AddSheba,
    CreatingIpgFirstPart,
    CreatingIpgSecondPart,
    CreatingIpgThirdPart,
  },
  inject: ["logoGuidanceItems"],
  props: {
    isInModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadedFile: "",
      websiteUrl: "",
      isFirstFormValid: false,
      mcc_code: "",
      tax_code: "",
      birthcertificateNumber: null,
      registerDate: null,
      businessRegistrationCode: null,
      isSecondFormValid: false,
      ipsList: [],
      wage: false,
      isThirdFormValid: false,
      iban: "",
      isShebaValid: false,
    }
  },
  computed: {
    ...mapState("newbusiness", ["shouldCreateIpg"]),
    isIpgCreatingFormValid() {
      return (
        this.isFirstFormValid &&
        this.isSecondFormValid &&
        this.isThirdFormValid &&
        !!this.uploadedFile &&
        !!this.iban &&
        this.isShebaValid
      )
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  watch: {
    shouldCreateIpg(val) {
      if (val) {
        this.sendForm()
        this.changeCreatingIpgStatus(false)
      }
    },
    isIpgCreatingFormValid(val) {
      if (val) {
        this.changeOnboardingFirstBtnActiveState(true)
        this.changeIpgFormSubmitBtnActiveState(true)
      } else {
        this.changeOnboardingFirstBtnActiveState(false)
        this.changeIpgFormSubmitBtnActiveState(false)
      }
    },
  },
  mounted() {
    if (this.isIpgCreatingFormValid) {
      this.changeOnboardingFirstBtnActiveState(true)
      this.changeIpgFormSubmitBtnActiveState(true)
    } else {
      this.changeOnboardingFirstBtnActiveState(false)
      this.changeIpgFormSubmitBtnActiveState(false)
    }
  },
  methods: {
    ...mapMutations([
      "changeIpgFormSubmitBtnActiveState",
      "changeIpgModalOpeningState",
    ]),
    ...mapMutations("newbusiness", [
      "changeCreatingIpgStatus",
      "changeOnboardingFirstBtnActiveState",
      "changeOnboardingFourthStepDoneStatus",
    ]),

    sendForm() {
      this.determineLoadingState(true)
      var data = {
        wage: this.wage,
        url: this.websiteUrl,
        ips: this.ipsList,
        tax_code: this.tax_code,
        avatar: this.uploadedFile,
        register_number: this.businessRegistrationCode,
        register_date: this.registerDate,
        birth_certificate_number: null,
        mcc_code: this.mcc_code,
        iban: this.iban,
      }
      if (this.$store.state.business.business_type === "personal") {
        data.birth_certificate_number =
          this.$store.state.business.owner.birthdate.slice(0, 4) <= 1367
            ? this.birthcertificateNumber
            : this.$store.state.business.owner.national_code
      }

      servicesRepository(this)
        .ipgServices.httpCreateIpg(data)
        .then((response) => {
          this.ipg = response.data.data.ipg
          this.$store.commit("addIpg", this.ipg)
          let newBusiness = this.$store.state.business
          newBusiness.tools.ipg.is_created = true
          this.$store.commit("setBusiness", newBusiness)

          this.$store.commit("callSuccessSnackbar", "اطلاعات با موفقیت ثبت شد")
          this.$store.commit("updateUserOrBusinessValue", {
            key: "tax_code",
            value: this.tax_code,
          })
          this.$store.commit("updateTaxCodeStatus", "PENDING")
          this.$store.commit("changeToolsStatus", {
            name: "ipg",
            status: "PENDING",
          })

          if (this.isInModal) {
            this.$router.push({
              name: "ipg",
              params: { filtered: "finishAdd" },
            })
            this.$emit("finishAdd")
            this.changeIpgModalOpeningState(false)
          } else {
            this.changeOnboardingFourthStepDoneStatus(true)
            this.goToOnboardingResult()
          }
          this.determineLoadingState(false)
        })
        .catch((error) => {
          this.determineLoadingState(false)
          this.changeOnboardingFirstBtnActiveState(false)
          //callsnackbar
        })
    },
    goToOnboardingResult() {
      this.$router.push({ name: "OnboardingResult" })
    },
    firstFormValidation(e) {
      this.websiteUrl = e.websiteUrl
      this.isFirstFormValid = e.isValid
      this.mcc_code = this.$store.state.business.mcc_code
    },
    secondFormValidation(e) {
      this.tax_code = e.tax_code
      this.businessRegistrationCode = e.businessRegistrationCode
      this.registerDate = e.registerDate
      this.birthcertificateNumber = e.birthcertificateNumber
      this.isSecondFormValid = e.isValid
    },
    thirdFormValidation(e) {
      this.ipsList = e.ipsList
      this.wage = e.wage
      this.isThirdFormValid = e.isValid
    },
    determineLoadingState(loadingState) {
      this.$emit("loading-state", loadingState)
    },
  },
}
</script>

<style lang="scss" scoped>
.creating-ipg-step {
  width: 536px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 32px;

  @media screen and (min-width: 600px) and (max-width: 960px) {
    width: 500px;
    padding: 32px 20px;
  }

  @media screen and (max-width: 599px) {
    width: 316.66px;
    max-width: 536px;
    margin: auto;
  }
}

:deep(.v-input--selection-controls) {
  padding-top: 0;
}
</style>
<style>
.v-autocomplete__content.v-menu__content {
  width: 348px !important;
  min-width: unset !important;
}

@media screen and (max-width: 600px) {
  .v-autocomplete__content.v-menu__content {
    width: 250px !important;
  }
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
</style>
