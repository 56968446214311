<template>
  <v-card class="upload">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$store.state.isUser || onlyPersonal" class="pr-0">
        تغییر تصویر حساب شخصی
      </v-toolbar-title>
      <v-toolbar-title v-else class="pr-0"> تغییر لوگو </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout>
      <v-flex>
        <div v-if="!startUpload">
          <input
            id="file"
            ref="file"
            type="file"
            accept="image/*"
            @change="handleFile()"
          />
          <v-layout
            justify-center
            align-content-space-around
            column
            class="uploadbox"
          >
            <v-flex>
              <v-icon color="van_color05" size="40"> backup </v-icon>
            </v-flex>
            <v-flex class="font_2"> فرمت‌های مجاز PNG و JPG است </v-flex>
            <v-flex class="font_8 mt-1"> جهت بارگذاری کلیک کنید </v-flex>
          </v-layout>
        </div>
        <v-layout
          v-else
          justify-center
          align-content-space-around
          column
          class="uploadloader"
        >
          <v-flex>
            <v-progress-circular
              :value="proggressBar"
              color="van_green"
              background-color="van_color06"
              size="45"
              width="4"
            >
              {{ proggressBar }}%
            </v-progress-circular>
          </v-flex>
          <v-flex class="font_6 mt-4">
            {{ file.name }}
          </v-flex>
          <v-flex v-if="proggressBar == 100" class="delete">
            <v-btn text icon @click="deleteFile()">
              <v-icon dark size="20" color="van_color03">
                delete_outline
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog
      v-if="openCropDialog"
      v-model="openCropDialog"
      max-width="400px"
      width="100%"
      no-click-animation
      persistent
      content-class="cropdialog"
    >
      <cropperDialog
        v-if="openCropDialog"
        :result="result"
        :aspect-ratio="1"
        @uploadFormData="uploadCropedPhoto"
        @closeCrop="
          $refs.file.value = null
          openCropDialog = false
        "
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Compressor from "compressorjs"
import cropperDialog from "../elements/cropperDialog"
export default {
  name: "SettingPhoto",
  components: {
    cropperDialog,
  },
  props: {
    onlyPersonal: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      file: "",
      loading: false,
      proggressBar: 0,
      startUpload: false,
      uploadedfile: "",
      size: 0,
      format: "",
      success: false,
      result: null,
      openCropDialog: false,
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.uploadedfile !== "" && this.success) return true
      else return false
    },
  },
  methods: {
    handleFile() {
      this.file = this.$refs.file.files[0]
      this.size = this.file.size
      this.format = this.file.type

      if (
        this.file["type"].split("/")[0] === "image" &&
        this.file["type"].split("/")[1] !== "gif"
      ) {
        this.compressAndUpload()
      } else if (
        this.file["type"].split("/")[0] !== "image" ||
        this.file["type"].split("/")[1] === "gif"
      ) {
        this.$store.commit(
          "callsnackbar",
          "فرمت فایل انتخاب شده قابل قبول نیست"
        )
      }
    },
    uploadCropedPhoto(formData) {
      //start uploading to server
      this.success = true
      this.startUpload = true
      this.openCropDialog = false
      this.$http
        .post(
          this.$store.state.api_base_url + "/modules/upload/image",
          formData,
          {
            timeout: 50000,
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
            onUploadProgress: (progressEvent) => {
              this.proggressBar = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            },
          }
        )
        .then((response) => {
          this.uploadedfile = response.data.data
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    compressAndUpload() {
      new Compressor(this.file, {
        quality: 0.8,
        maxWidth: 400,
        maxHeight: 400,
        convertSize: 1000000,
        success: (result) => {
          if (result.size > 2000000) {
            this.$store.commit(
              "callsnackbar",
              "حجم فایل انتخاب شده بیشتر از حد مجاز است"
            )
          } else {
            this.result = result
            this.openCropDialog = true
          }
        },
        error(err) {
          this.deleteFile()
          store.commit("callsnackbar", "لطفا دوباره تلاش کنید")
          Sentry.captureException(err)
        },
      })
    },
    sendForm() {
      if (this.uploadedfile !== "") {
        var data = {
          avatar: this.uploadedfile,
        }
        var url = ""
        if (this.$store.state.isUser || this.onlyPersonal) {
          url = "/personal/change/avatar"
        } else {
          url =
            "/business/" + this.$store.state.business.business_name + "/update"
          data = {
            avatar: this.uploadedfile,
            // business_type: this.$store.state.business.business_type,
            // business_name: this.$store.state.business.business_name,
            // business_name_fa: this.$store.state.business.business_name_fa,
            // mcc_code: this.$store.state.business.mcc_code,
            // city_id: this.$store.state.business.city_id,
            // postal_code: this.$store.state.business.postal_code,
            // phone_number: this.$store.state.business.phone_number,
            // address: this.$store.state.business.address,
          }
        }
        this.loading = true
        this.$http
          .post(this.$store.state.api_base_url + url, data, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (this.$store.state.isUser || this.onlyPersonal) {
              this.$store.commit("setUser", response.data.data.user)
            } else {
              this.$store.commit("setBusiness", response.data.data)
            }
            this.$store.commit("changeUpdateBusinessList", true)
            this.loading = false
            this.$emit("closeModal", { status: true })
          })
          .catch((error) => {
            this.loading = false
            //callsnackbar
          })
      }
    },
    deleteFile() {
      this.file = null
      this.proggressBar = 0
      this.startUpload = false
    },
  },
}
</script>
