<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="closeModal()">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">دعوت کاربر جدید</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          class="btn_small_normal"
          :disabled="disable1"
          :loading="formLoading"
          @click="sendForm()"
        >
          ارسال دعوتنامه
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <div class="font_2">
          با وارد کردن اطلاعات شخص مورد نظر، دعوتنامه برای ایشان ارسال می‌شود.
          در صورتی که کاربر در وندار حساب کاربری نداشته باشد، ابتدا باید ثبت‌نام
          کند تا بتواند به کسب‌و‌کار شما ملحق شود
        </div>
        <v-divider />
        <v-form ref="form" :value="valid1">
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label"> تلفن همراه </v-flex>
            <v-flex class="withlabel addingUser">
              <v-combobox
                ref="combo"
                v-model="mobile"
                solo
                flat
                height="40"
                outlined
                mask="###########"
                :rules="mobileRules"
                :search-input.sync="mobileNumber"
                :loading="loadingUserSearch"
              >
                <template #no-data>
                  <v-list v-if="items.length !== 0">
                    <template v-for="(user, index) in items">
                      <v-list-item
                        :key="user.mobile"
                        @click="setSelection(user.mobile)"
                      >
                        <v-list-item-avatar size="32">
                          <v-img
                            v-if="user.avatar"
                            :src="$store.state.storage_base_url + user.avatar"
                            alt="avatar"
                          />
                          <v-img
                            v-else
                            :src="
                              $store.state.storage_base_url +
                              'users/default.png'
                            "
                            alt="avatar"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="font_7">
                            {{ user.mobile }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                        v-if="index + 1 < items.length"
                        :key="index"
                        light
                      />
                    </template>
                  </v-list>
                  <v-list
                    v-else-if="
                      items &&
                      mobileNumber &&
                      items.length == 0 &&
                      mobileNumber.length == 11
                    "
                    dense
                  >
                    <v-list-item @click="setSelection(mobileNumber)">
                      <v-list-item-avatar color="#eee" size="32">
                        <v-icon size="24"> person </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font_7">
                          {{ mobileNumber }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </template>
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label"> سطح دسترسی </v-flex>
            <v-flex class="withlabel">
              <v-select
                ref="rulesRef"
                v-model="role"
                :items="rolesList"
                solo
                flat
                height="40"
                item-text="value"
                item-value="key"
                outlined
                label="انتخاب کنید"
                :rules="roleRules"
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-select>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label"> پیام دعوتنامه </v-flex>
            <v-flex class="withlabel big">
              <v-textarea
                v-model="message"
                solo
                flat
                outlined
                rows="3"
                :rules="messageRules"
                disabled
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-textarea>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>

      <!-- info component -->
      <roleInfo />
    </v-layout>
  </v-card>
</template>

<script>
import roleInfo from "../elements/roleInfo.vue"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "AddUser",
  components: {
    roleInfo,
  },
  props: {
    defaultRole: {
      default: "",
    },
  },
  data: function () {
    return {
      formLoading: false,
      loadingUserSearch: false,
      valid1: false,
      mobile: null,
      role: this.defaultRole,
      rolesList: [
        {
          key: 1,
          value: "مدیر",
        },
        {
          key: 2,
          value: "حسابدار",
        },

        {
          key: 4,
          value: "اپراتور",
        },
        {
          key: 5,
          value: "ناظر",
        },
        {
          key: 3,
          value: "برنامه‌نویس",
        },
      ],
      message:
        "سلام. از شما دعوت می‌شود که ضمن عضویت در سامانه وندار، در مدیریت کسب‌و‌کار »" +
        this.$store.state.business.business_name_fa +
        "« همراه من باشید",
      mobileRules: [
        //v => !!v || '<span> وارد کردن شماره موبایل الزامی است </span>',
        (v) => !!v || "",
        (v) =>
          (v && v.length > 0 && /(^[0-9]*$)/.test(v)) ||
          "<span> کیبورد خود را انگلیسی کنید </span>",
      ],
      roleRules: [(v) => !!v || "<span>وارد کردن نقش الزامی است</span>"],
      messageRules: [(v) => !!v || "<span>وارد کردن پیام الزامی است</span>"],
      items: [],
      mobileNumber: "",
      mobileValidation: false,
    }
  },
  computed: {
    disable1() {
      if (!this.formLoading && this.valid1) return false
      else return true
    },
  },
  watch: {
    mobileValidation: function (newValue, oldValue) {
      if (newValue && this.$refs.rulesRef.validate()) {
        this.valid1 = true
      } else {
        this.valid1 = false
      }
    },
    role: function (newValue, oldValue) {
      if (newValue !== "" && this.mobileValidation) {
        this.valid1 = true
      } else {
        this.valid1 = false
      }
    },
    mobileNumber: function (newNumber, oldNumber) {
      this.mobile = newNumber
      if (newNumber && newNumber.length >= 8 && /(^[0-9]*$)/.test(newNumber)) {
        this.loadingUserSearch = true
        this.searchMobile(newNumber)
      } else {
        this.mobileValidation = false
        this.items = []
        this.loadingUserSearch = false
      }
    },
  },
  methods: {
    setSelection(selectedMobile) {
      this.mobile = selectedMobile
      this.$refs.combo.blur()
      this.mobileValidation = true
    },
    closeModal(value = false) {
      this.$store.commit("callSecondAddUserModal", false)
      this.$emit("closeModal", { status: value })
    },
    sendForm() {
      this.formLoading = true

      const payload = {
        mobile: this.convertPersianDigitsToEnglish(this.mobile),
        role_id: this.role,
      }

      servicesRepository(this)
        .roleUser.httpAddUser(payload)
        .then((response) => {
          this.formLoading = false
          this.closeModal(true)
          this.$store.commit("callSuccessSnackbar", response.data.data.message)
          this.$emit("setUser")
        })
        .catch((error) => {
          this.formLoading = false
          //callsnackbar
        })
    },
    searchMobile(number) {
      if (number) {
        this.$http
          .get(this.$store.getters.apiUrlParam + "/iam/mobile?q=" + number, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            this.items = response.data.data.users
            this.loadingUserSearch = false
          })
          .catch((error) => {
            //callsnackbar
            this.loadingUserSearch = false
          })
      }
    },
    convertPersianDigitsToEnglish(num) {
      return num
        .replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d))
        .replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d))
    },
  },
}
</script>
