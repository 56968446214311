<template>
  <v-layout class="d-flex justify-space-between">
    <v-flex class="label"> </v-flex>
    <v-flex
      class="celling-limits withlabel big van_color11 pa-2 pa-sm-3 pt-sm-0"
    >
      <v-divider class="mb-1"></v-divider>
      <slot />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "IbanCelling",
}
</script>
