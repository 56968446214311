<template>
  <v-text-field
    ref="nationalId"
    v-model.trim="national_id"
    :rules="required ? national_id_Rules : []"
    solo
    flat
    height="40"
    outlined
    @keydown="numberKeyHandler($event)"
    @focus="changeFocusState(true)"
    @blur="changeFocusState(false)"
  >
    <template #message="message">
      <div class="error-message" v-html="message.message"></div>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "NationalId",
  props: {
    inputValue: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      national_id: null,
      national_id_Rules: [
        (v) => !!v || "<span> شناسه ملی الزامی است </span>",
        (v) =>
          this.NationalIdValidator || "<span> شناسه ملی نادرست است </span>",
      ],
    }
  },
  computed: {
    NationalIdValidator(value) {
      if (this.national_id) {
        value = this.national_id
        var numbers = value.split("").map(Number)
        if (numbers.length < 11) return false
        var code = numbers[9] + 2
        var x =
          (numbers[0] + code) * 29 +
          (numbers[1] + code) * 27 +
          (numbers[2] + code) * 23 +
          (numbers[3] + code) * 19 +
          (numbers[4] + code) * 17 +
          (numbers[5] + code) * 29 +
          (numbers[6] + code) * 27 +
          (numbers[7] + code) * 23 +
          (numbers[8] + code) * 19 +
          (numbers[9] + code) * 17
        var A = x % 11
        var codeController
        if (A == 10) {
          codeController = 0
        } else {
          codeController = A
        }
        return numbers[10] == codeController
      } else return false
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  watch: {
    national_id: function (newValue) {
      if (newValue) {
        this.national_id = this.convertNumbers2English(newValue).replace(
          /[^0-9]/g,
          ""
        )
        this.national_id = this.national_id.slice(0, 11)
      }
      this.$refs.nationalId.lazyValue = this.national_id
      this.$emit("setValue", newValue)
    },
  },
  created() {
    this.national_id = this.inputValue
  },
  methods: {
    changeFocusState(focusState) {
      this.$store.commit("changeNationalIdFocusState", focusState)
    },
  },
}
</script>

<style lang="scss" scoped>
.error-message {
  position: relative;
  z-index: 2;
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
</style>
