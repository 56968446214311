<template>
  <v-layout column wrap justify-center align-start>
    <v-flex style="width: 100%">
      <vandarTable
        :update="updateTable"
        @updateSettelment="allupdates()"
        @doneUpdateTable="done_update_table()"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import vandarTable from "../elements/vandarTable.vue"
export default {
  name: "Index",
  components: {
    vandarTable,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from.name === "businessHome" &&
        vm.$store.state.isRoutedFromSuspiciousItemsModal
      ) {
        vm.$store.commit("changeTheStateOfShouldFilterWithCashInByCode", true)
      } else if (vm.$store.state.shouldFilterWithCashInByCode) {
        vm.$store.commit("changeTheStateOfShouldFilterWithCashInByCode", false)
      }
    })
  },
  data() {
    return {
      componentKey: 0,
      //this is modal status
      status: false,
      name: "",
      sheba: "",
      time: "",
      mablagh: 0,
      blocked: true,
      updateSettlement: false,
      updateTable: false,
      errorHappen: false,
      settlementDone: false,
      receiptUrl: null,
      //cash in data
      cashInModal: false,
      cashInSuccess: false,
    }
  },
  watch: {
    "$store.state.report.update": {
      handler: function (newValue) {
        if (newValue) {
          this.allupdates()
          this.$store.commit("updateUpdate", false)
        }
      },
      deep: true,
      immediate: true,
    },
    "$route.params.filtered": {
      handler: function (newValue) {
        if (newValue == "addCashIn") {
          this.openCashIn()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    newSettlement() {
      this.settlementDone = false
      this.status = true
    },
    done_update_settlement() {
      this.updateSettlement = false
    },
    done_update_table() {
      this.updateTable = false
    },
    update_user() {
      if (this.$store.state.isUser) {
        //erase wallet
        this.$store.commit("eraseWallet", "user")
        this.$store
          .dispatch("retrieveUserObject")
          .then((response) => {
            //DO Nothing
          })
          .catch((error) => {
            //callsnackbar
          })
      } else {
        //TODO: update business list
        //erase wallet
        this.$store.commit("eraseWallet", "business")
        this.$store
          .dispatch(
            "retrieveBusinessObject",
            this.$store.state.business.business_name
          )
          .then((response) => {
            //DO Nothing
          })
          .catch((error) => {
            //callsnackbar
          })
      }
      //update business list
      //TODO: it seems this line call business/v3 api twice
      this.$store.commit("changeUpdateBusinessList", true)
    },
    updatejustSettlement() {
      //update settlement and wallet
      this.updateSettlement = true
      this.update_user()
    },
    allupdates() {
      //update settlement and table and wallet
      this.updateSettlement = true
      this.updateTable = true
      this.update_user()
    },
    close_modal(obj) {
      this.status = false
      if (obj && obj.receiptUrl) {
        this.receiptUrl = obj.receiptUrl
        this.settlementDone = true
      } else {
        this.receiptUrl = null
      }
      if (obj && obj.status) {
        this.mablagh = obj.amount
        this.name = obj.name
        this.sheba = obj.sheba
        this.time = obj.time
        this.allupdates()
        this.componentKey = !this.componentKey
      }
    },
    close_cashIn_modal(obj) {
      this.cashInModal = false
      if (obj?.status) {
        if (obj["receiptUrl"]) {
          this.receiptUrl = obj.receiptUrl
        } else {
          this.receiptUrl = null
        }
        this.cashInSuccess = true
        this.allupdates()
        this.componentKey = !this.componentKey
      }
    },
    error_happened() {
      this.status = false
      this.errorHappen = true
      this.allupdates()
    },
    open_modal() {
      this.status = true
    },
    openCashIn() {
      this.cashInSuccess = false
      this.cashInModal = true
    },
    checkAmount() {
      var param
      this.$store.state.isUser ? (param = "user") : (param = "business")
      var currentWallet = this.$store.state[param].wallet
      return currentWallet >= 5000
    },
  },
}
</script>
