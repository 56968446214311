<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">{{ toolbarTitle }}</v-toolbar-title>
    </v-toolbar>
    <v-dialog
      v-model="secondModalStatus"
      :content-class="
        $store.state.secondAddDocumentmodal
          ? 'secondModal vanmodal'
          : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
      @click:outside="secondModalStatus = false"
    >
      <addDocument v-show="isShowAddDocument" @closeModal="close_modal()" />
      <AddOfficialNewspaper v-show="addNewspaper" @closeModal="close_modal()" />
      <AddIntroductionLetter
        v-show="addIntroduction"
        @closeModal="close_modal()"
      />
      <AddNationalCardPhoto
        v-show="addNationalImage"
        @closeModal="close_modal()"
      />
      <settingInfo v-show="isShowSettingInfo" @closeModal="close_modal()" />

      <locationInfo
        v-show="addLocationStatus"
        setting
        :edit-address="editAddress"
        :edit-phone="editPhone"
        @closeModal="close_modal()"
      />
      <AddNationalId v-show="addNationalId" @closeModal="close_modal()" />
      <AddLegalBusinessName
        v-show="addLegalBusinessName"
        @closeModal="close_modal()"
      />
      <AddBusinessNameFa
        v-show="addBusinessNameFa"
        @closeModal="close_modal()"
      />
    </v-dialog>
    <v-layout style="flex-wrap: wrap; position: relative; top: 76px">
      <v-card
        v-if="businessHasRejected"
        color="#f7eae9"
        class="w-100 mx-6 mb-4 d-flex align-center py-3 px-5"
      >
        <v-img
          width="16px"
          height="16px"
          max-height="16px"
          max-width="16px"
          class="ml-2"
          :src="require('@/assets/img/round-nonField-warning.svg')"
        />
        <span class="font_2">
          {{ businessHomeLang?.warningMessage }}
        </span>
      </v-card>
      <!-- kyc -->
      <div
        v-if="$store.state.user.statusBox.kyc == false"
        class="card-element-wrapper"
      >
        <CardElement @click.native="add_info()">
          {{ businessHomeLang?.notCompatibleNationalCode }}
        </CardElement>
      </div>

      <!-- مدارک + شاهکار -->
      <template
        v-if="
          !$store.state.business.rejects.business_license_image &&
          !$store.state.business.rejects.introduction_letter &&
          !$store.state.business.rejects.national_image
        "
      >
        <!-- fileds to show for the first time -->
        <div
          v-for="(element, index) in addDocumentsFirstTime"
          :key="element.name + index"
          class="card-element-wrapper"
        >
          <CardElement @itemClicked="add_Document(element.type)">
            {{ element.text }}
          </CardElement>
        </div>
      </template>

      <!-- fileds to show after reject or accept -->
      <div
        v-for="(item, index) in listOfItemsShouldEdit"
        :key="index + item.clickAction"
        class="card-element-wrapper"
      >
        <CardElement
          v-if="item.mustEditCondition"
          @itemClicked="openModalHandler(item.clickAction)"
        >
          <template v-if="item.spanCondition">
            {{ item.spanCondition }}
          </template>
          <template v-else-if="item.elseText">
            {{ item.elseText }}
          </template>
        </CardElement>
      </div>
    </v-layout>
  </v-card>
</template>

<script>
import addDocument from "./addDocument.vue"
import locationInfo from "@/components/modals/locationInfo.vue"
import AddNationalId from "@/components/modals/addNationalId.vue"
import AddLegalBusinessName from "@/components/modals/addLegalBusinessName.vue"
import AddBusinessNameFa from "@/components/modals/addBusinessNameFa.vue"
import settingInfo from "@/components/modals/settingInfo.vue"
import AddOfficialNewspaper from "@/components/OnBoarding/elements/AddOfficialNewspaper.vue"
import AddIntroductionLetter from "@/components/OnBoarding/elements/AddIntroductionLetter.vue"
import AddNationalCardPhoto from "@/components/OnBoarding/elements/AddNationalCardPhoto.vue"
import CardElement from "@/components/businessHome/elements/CardElement.vue"

export default {
  name: "SoftRejectItems",
  components: {
    addDocument,
    locationInfo,
    AddNationalId,
    AddLegalBusinessName,
    AddBusinessNameFa,
    AddOfficialNewspaper,
    AddIntroductionLetter,
    AddNationalCardPhoto,
    settingInfo,
    CardElement,
  },
  props: {
    toolbarTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      secondModalStatus: false,
      isShowAddDocument: false,
      addDocumentText: "",
      addNewspaper: false,
      addIntroduction: false,
      addNationalImage: false,
      isShowSettingInfo: false,
      addLocationStatus: false,
      editAddress: false,
      editPhone: false,
      addNationalId: false,
      addLegalBusinessName: false,
      addBusinessNameFa: false,
      businessHomeLang: null,
    }
  },
  computed: {
    addDocumentsFirstTime() {
      let comps = []
      //if business is personal and national code photo in not uploaded
      if (
        this.businessType === "personal" &&
        this.noPersonalDocumentIsUploaded
      ) {
        let uploadAllDocuments = {
          name: "mustEditCard",
          type: "nationalCardPhoto",
          text: "کارت ملی صاحب کسب‌وکار",
        }
        comps.push(uploadAllDocuments)
        return comps
      }
      //else if legal business, have No document uploaded
      else if (this.legalDocumentHandler("add")) {
        let uploadAllDocuments = {
          name: "mustEditCard",
          type: "uploadAllDocuments",
          text: "بارگذاری مدارک مورد نیاز",
        }
        comps.push(uploadAllDocuments)
        return comps
      }

      //else if legal business, have at  least 1 document uploaded
      if (this.businessType === "legal") {
        if (this.businessStatusBox.introduction_letter !== "accepted") {
          let introductionLetter = {
            name: "mustEditCard",
            type: "introductionLetter",
            text: "بارگذاری معرفی‌نامه",
          }
          comps.push(introductionLetter)
        }

        if (this.businessStatusBox.national_card_photo !== "accepted") {
          let nationalCardPhoto = {
            name: "mustEditCard",
            type: "nationalCardPhoto",
            text: "بارگذاری کارت ملی مدیر عامل",
          }
          comps.push(nationalCardPhoto)
        }

        if (this.businessStatusBox.official_Newspaper !== "accepted") {
          let officialNewspaper = {
            name: "mustEditCard",
            type: "officialNewspaper",
            text: "بارگذاری کامل روزنامه رسمی",
          }
          comps.push(officialNewspaper)
        }
      }
      return comps
    },
    noPersonalDocumentIsUploaded() {
      return (
        Object.prototype.hasOwnProperty.call(
          this.$store.state.business,
          "statusBox"
        ) && this.businessStatusBox.national_card_photo !== "accepted"
      )
    },
    nationalPhoto() {
      return {
        mustEditCondition: this.businessStatusBox.national_card_photo === "add",
        clickAction: "add_national_image",
        spanCondition: this.$store.state.business.rejects.national_image,
      }
    },
    licenseImage() {
      return {
        mustEditCondition: this.businessStatusBox.official_Newspaper === "add",
        clickAction: "add_official_newspaper",
        spanCondition:
          this.$store.state.business.rejects.business_license_image,
      }
    },
    introductionLetter() {
      return {
        mustEditCondition: this.businessStatusBox.introduction_letter === "add",
        clickAction: "add_introduction_letter",
        spanCondition: this.$store.state.business.rejects.introduction_letter,
      }
    },
    location() {
      let location = {}
      if (!this.$store.state.business.postal_code) {
        location = {
          mustEditCondition:
            !this.$store.state.business.postal_code &&
            this.$store.state.business.postal_code_detail.postal_code_status !==
              "SUCCEED",
          clickAction: "add_location",
          spanCondition: this.$store.state.business.rejects.postal_code,
          elseText: "اطلاعات مکانی کسب‌و‌کار",
        }
      } else if (
        !this.$store.state.business.address_detail.address &&
        !this.$store.state.business.phone_number
      ) {
        location = {
          mustEditCondition: true,
          clickAction: "add_location",
          spanCondition: this.$store.state.business.rejects.address,
          elseText: "اطلاعات مکانی کسب‌و‌کار",
        }
      } else if (
        !this.$store.state.business.address_detail.address &&
        this.$store.state.business.phone_number
      ) {
        location = {
          mustEditCondition: true,
          clickAction: "add_location",
          spanCondition: this.$store.state.business.rejects.address,
          elseText: "آدرس خود را کامل وارد کنید",
        }
      } else if (
        this.$store.state.business.address_detail.address &&
        !this.$store.state.business.phone_number
      ) {
        location = {
          mustEditCondition: true,
          clickAction: "add_location",
          spanCondition: this.$store.state.business.rejects.phone_number,
          elseText: "اصلاح شماره تلفن ثابت",
        }
      } else if (
        this.$store.state.business.postal_code &&
        this.$store.state.business.rejects.address
      ) {
        location = {
          mustEditCondition: true,
          clickAction: "add_location",
          spanCondition: this.$store.state.business.rejects.address,
        }
      }

      return location
    },
    nationalCode() {
      return {
        mustEditCondition: !this.$store.state.business.national_id,
        clickAction: "add_national_id",
        spanCondition: this.$store.state.business.rejects.national_code,
        elseText: "اصلاح شناسه ملی",
      }
    },
    farsiName() {
      return {
        mustEditCondition: !this.$store.state.business.business_name_fa,
        clickAction: "add_business_name_fa",
        spanCondition: this.$store.state.business.rejects.company_name_fa,
        elseText: "نام برند صحیح را وارد کنید",
      }
    },
    businessName() {
      return {
        mustEditCondition: !this.$store.state.business.legal_business_name,
        clickAction: "add_legal_business_name",
        spanCondition: this.$store.state.business.rejects.legal_name,
        elseText: "اصلاح نام حقوقی",
      }
    },
    mustEditAndAcceptedList() {
      let list = []

      //national card photo
      if (this.$store.state.business.rejects.national_image) {
        list.push(this.nationalPhoto)
      }

      //business_license_image
      if (this.$store.state.business.rejects.business_license_image) {
        list.push(this.licenseImage)
      }

      //introduction_letter
      if (this.$store.state.business.rejects.introduction_letter) {
        list.push(this.introductionLetter)
      }

      //location info
      if (Object.keys(this.location).length !== 0) {
        list.push(this.location)
      }

      if (this.businessType === "legal") {
        //national code
        list.push(this.nationalCode)

        //legal name
        list.push(this.businessName)
      }

      //farsi name
      list.push(this.farsiName)

      return list
    },
    businessHasRejected() {
      return this.$store.state.business.has_rejection
    },
    businessStatusBox() {
      return this.$store.state.business.statusBox
    },
    businessType() {
      return this.$store.state.business.business_type
    },
    listOfItemsShouldEdit() {
      return this.mustEditAndAcceptedList.filter(
        (item) => item.mustEditCondition === true
      )
    },
  },
  watch: {
    secondModalStatus(val) {
      this.$emit("second-modal-status", val)
      this.$store.commit("setShouldDeleteUploadedFile", val)
    },
  },
  mounted() {
    this.getBusinessHomeLang()
  },
  methods: {
    close_modal() {
      this.isShowAddDocument = false
      this.addNewspaper = false
      this.addIntroduction = false
      this.addNationalImage = false
      this.isShowSettingInfo = false
      this.addLocationStatus = false
      this.editAddress = false
      this.editPhone = false
      this.addNationalId = false
      this.addLegalBusinessName = false
      this.addBusinessNameFa = false
      this.secondModalStatus = false
      this.updateBusinessObject()
    },
    updateBusinessObject() {
      this.$store.dispatch(
        "retrieveBusinessObject",
        this.$store.state.business.business_name
      )
    },
    openModalHandler(functionName) {
      this[functionName]()
    },
    add_location(value) {
      this.close_modal()
      if (value == "editAddress") {
        this.editAddress = true
      } else if (value == "editPhone") {
        this.editPhone = true
      }
      this.addLocationStatus = true
      this.secondModalStatus = true
    },
    add_national_id() {
      this.close_modal()
      this.addNationalId = true
      this.secondModalStatus = true
    },
    add_business_name_fa() {
      this.close_modal()
      this.addBusinessNameFa = true
      this.secondModalStatus = true
    },
    add_legal_business_name() {
      this.close_modal()
      this.addLegalBusinessName = true
      this.secondModalStatus = true
    },
    add_Document(elementType) {
      this.close_modal()

      switch (elementType) {
        case "acceptedCard":
          break
        case "uploadAllDocuments":
          this.isShowAddDocument = true
          this.secondModalStatus = true
          break
        case "introductionLetter":
          this.add_introduction_letter()
          break
        case "nationalCardPhoto":
          this.add_national_image()
          break
        case "officialNewspaper":
          this.add_official_newspaper()
          break
      }
    },
    add_official_newspaper() {
      this.close_modal()
      this.addNewspaper = true
      this.secondModalStatus = true
    },
    add_introduction_letter() {
      this.close_modal()
      this.addIntroduction = true
      this.secondModalStatus = true
    },
    add_national_image() {
      this.close_modal()
      this.addNationalImage = true
      this.secondModalStatus = true
    },
    add_info() {
      this.close_modal()
      this.isShowSettingInfo = true
      this.secondModalStatus = true
    },
    legalDocumentHandler(status) {
      return (
        this.businessType === "legal" &&
        Object.prototype.hasOwnProperty.call(
          this.$store.state.business,
          "statusBox"
        ) &&
        this.businessStatusBox.introduction_letter === status &&
        this.businessStatusBox.national_card_photo === status &&
        this.businessStatusBox.official_Newspaper === status
      )
    },
    async getBusinessHomeLang() {
      const lang = await import("@/lang/languageResource.json")
      this.businessHomeLang = lang.businessHome
    },
  },
}
</script>

<style lang="scss" scoped>
.card-element-wrapper {
  width: 100%;
  margin: 0 24px 16px;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  background-color: white;
  user-select: none;
}
</style>
