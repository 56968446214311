<template functional>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 5.33334L12 9.33334L11.06 10.2733L8 7.22001L4.94 10.2733L4 9.33334L8 5.33334Z"
      fill="#333333"
    />
  </svg>
</template>

<script>
export default {
  name: "ChevronUpSvg",
}
</script>

<style lang="scss" scoped></style>
