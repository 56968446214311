export const getters = {
  loggedIn(state) {
    return state.token !== null
  },
  userOrBuisenss(state) {
    return state.isUser ? "user" : "business"
  },
  apiUrlParam(state) {
    return state.isUser
      ? state.api_base_url + "/personal"
      : state.api_base_url + "/business/" + state.business.business_name
  },
  apiUrlParamV3(state) {
    return state.isUser
      ? state.api_base_url_v3 + "/personal"
      : state.api_base_url_v3 + "/business/" + state.business.business_name
  },
  apiWithVersionParam: (state) => (version) => {
    var output = state.isUser
      ? "/personal"
      : "/business/" + state.business.business_name
    return state.api_base_url_withoutV + "/v" + version + output
  },
  urlParam(state) {
    return state.isUser
      ? "/personal"
      : "/business/" + state.business.business_name
  },
  urlMPG(state) {
    return state.mpg_base_url + "/business/" + state.business.business_name
  },
  ibanApiUrlParam(state) {
    var output = state.isUser
      ? "/personal"
      : "/business/" + state.business.business_name
    return state.api_base_url_withoutV + "/v2.1" + output + "/iban"
  },
  apiUrlBatch(state) {
    return state.batch_base_url + "/business/" + state.business.business_name
  },
  apiUrlBatchV2(state) {
    return state.batch_base_url_v2 + "/business/" + state.business.business_name
  },
  isShaparakBoxShow(state) {
    if (state?.isUser) {
      if (
        state?.user?.hasOwnProperty("tools") &&
        state?.user?.tools?.requestMoney?.is_created &&
        (["FAILED", "PENDING"].includes(
          state?.user?.tools?.requestMoney?.status
        ) ||
          !state.user.has_shaparak_terminal)
      )
        return true
      else return false
    } else if (
      state?.business?.hasOwnProperty("tools") &&
      (state?.business?.tools?.requestMoney?.is_created ||
        state?.business?.tools?.ipg?.is_created) &&
      (["FAILED", "PENDING"].includes(
        state?.business?.tools?.requestMoney?.status
      ) ||
        ["FAILED", "PENDING"].includes(state?.business?.tools?.ipg?.status) ||
        !state?.business?.has_shaparak_terminal)
    )
      return true
    else return false
  },
  isBusinessActive(state) {
    return state?.business?.status === 1
  },
  cashInCodeGroupByCode(state) {
    return Object.groupBy(state.business.cash_in_code, ({ code }) => code)
  },
}
