<template>
  <general-card
    :parent-class="
      $store.state.business?.pic_suspicious_check &&
      !$vuetify.breakpoint.smAndDown
        ? 'w-50'
        : 'w-100'
    "
  >
    <div
      v-if="isNotFirstTime"
      :class="
        $store.state.business?.pic_suspicious_check ||
        $vuetify.breakpoint.smAndDown
          ? null
          : 'd-flex column justify-space-between'
      "
    >
      <div>
        <div
          class="d-flex"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <div
            class="d-flex align-center font-size-16 bold van_color02--text mr-2"
          >
            برداشت‌ در نوبت
            <v-icon
              size="20"
              style="width: 20px"
              class="van_color05--text mr-1 ml-3"
              @click="showDetails = true"
            >
              mdi_info_outline
            </v-icon>
          </div>
          <v-spacer v-if="$store.state.business?.pic_suspicious_check" />
          <div
            class="van_color06 radius-4 text-center pa-2"
            :class="{ 'mt-3': $vuetify.breakpoint.xsOnly }"
            style="width: 120px"
          >
            <div>
              <span class="font-11-bo-333-17">
                {{ count }}
              </span>
              <span class="font-11-no-333-17"> تراکنش </span>
            </div>
          </div>
        </div>
        <div class="font-size-12 mt-5">
          جمع کل مبلغ:
          <span class="bold font-size-14">{{
            sumAmount | currencyWithRialInput
          }}</span>
          {{ currencyName }}
        </div>
        <div class="font-size-12 mt-2">
          پس از بالا رفتن موجودی کیف‌پول، برداشت‌ها به ترتیب، در صف بانک قرار
          می‌گیرند و در واریز و برداشت قابل مشاهده هستند.
        </div>
      </div>
      <div class="d-flex align-center mt-5">
        <v-btn
          outlined
          text
          width="120"
          class="ma-0 van_color03--text"
          @click="showItems"
        >
          <span class="ml-3 font-size-12"> مشاهده </span>
          <v-icon size="12" style="width: 12px"> arrow_back </v-icon>
        </v-btn>
      </div>
    </div>

    <div
      v-else
      :class="
        $store.state.business?.pic_suspicious_check ||
        $vuetify.breakpoint.smAndDown
          ? null
          : 'd-flex column justify-space-between'
      "
    >
      <div>
        <div class="d-flex">
          <div
            class="d-flex align-center font-size-16 bold van_color02--text mr-2"
          >
            برداشت‌ در نوبت
          </div>
        </div>
        <div class="font-size-12 mt-5">
          در صورت کمبود موجودی کیف‌پول، می‌توانید برداشت‌های خود را ثبت کرده و
          تا پس از افزایش موجودی تراکنش‌ها در صف بانک قرار گیرند.
        </div>
      </div>
      <div class="d-flex align-center mt-5">
        <v-btn
          outlined
          text
          width="120"
          class="ma-0 van_color03--text font-size-12"
          @click="showFirstTimeDetails"
        >
          <span> اطلاعات بیشتر </span>
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-if="showQueueItems"
      v-model="showQueueItems"
      :content-class="`left-modal ${modalClass}`"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <SettlementQueueItems
        @closeModal="showQueueItems = false"
        @refreshData="getData"
      />
    </v-dialog>

    <v-dialog
      v-if="showDetails"
      v-model="showDetails"
      :content-class="
        $store.state.showSecondModal ? 'secondModal left-modal' : 'left-modal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <queue-details @closeModal="showDetails = false" />
    </v-dialog>
  </general-card>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import SettlementQueueItems from "@/components/businessHome/elements/settlementQueue/settlementQueueItems.vue"
import QueueDetails from "@/components/A2A/modals/queueDetails.vue"

export default {
  name: "SettlementQueueCard",
  components: { QueueDetails, SettlementQueueItems, GeneralCard },
  data() {
    return {
      showQueueItems: false,
      count: 0,
      sumAmount: 0,
      showDetails: false,
    }
  },
  computed: {
    isNotFirstTime() {
      return this.$store.state.user?.actions?.find(
        (item) => item.action_name === "queued_settlement_onboarding"
      )
    },
    modalClass() {
      if (this.$store.state.showThirdModal) {
        return "thirdModal"
      }

      if (this.$store.state.showSecondModal) {
        return "secondModal"
      }

      return ""
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    showItems() {
      this.showQueueItems = true
    },
    getData() {
      this.$http
        .get(this.$store.getters.apiUrlParamV3 + "/settlement/queued/summary")
        .then((response) => {
          this.count = response.data?.data?.count
          this.sumAmount = response.data?.data?.sum_amount
        })
    },
    showFirstTimeDetails() {
      this.$store.dispatch("saveAction", {
        name: "queued_settlement_onboarding",
      })
      this.showDetails = true
    },
  },
}
</script>

<style scoped></style>
