var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-6",class:{ 'pa-8': _vm.$vuetify.breakpoint.smAndUp }},[_c('v-layout',{staticClass:"mmb-12",class:{
      'flex-column': _vm.$vuetify.breakpoint.xsOnly,
      'align-center': _vm.$vuetify.breakpoint.smAndUp,
    }},[(_vm.title)?_c('v-flex',{staticClass:"font_9",class:{ 'mb-1': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-spacer'),(_vm.growthText)?_c('v-flex',{class:{
        'mx-4': _vm.$vuetify.breakpoint.smAndUp,
        'mb-2': _vm.$vuetify.breakpoint.xsOnly,
      },staticStyle:{"max-width":"260px","width":"fit-content"}},[_c('div',{staticClass:"insight-text-box-wrapper"},[_c('div',{staticClass:"insight-text-box font_2 d-flex align-center",style:('background:' + _vm.growthColor)},[_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.growthText)}})])]),(_vm.showFilter)?_c('div',{staticClass:"triangle-badge",style:({
            borderBottom: `12px solid ${_vm.growthColor}`,
          })}):_vm._e()])]):_vm._e(),(_vm.showFilter)?_c('v-flex',{staticStyle:{"max-width":"fit-content"}},[_c('v-layout',{staticClass:"mx-0",staticStyle:{"width":"fit-content"}},[_c('v-flex',[_c('v-select',{staticStyle:{"width":"132px"},attrs:{"items":_vm.duration,"item-text":"name","solo":"","flat":"","height":"40","outlined":"","return-object":"","menu-props":{ contentClass: 'withActiveStyle' }},on:{"change":function($event){return _vm.emitSelectedFilteration()}},model:{value:(_vm.selectedDuration),callback:function ($$v) {_vm.selectedDuration=$$v},expression:"selectedDuration"}})],1)],1)],1):_vm._e()],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }