<template>
  <v-card class="pa-8">
    <v-form class="d-flex flex-column align-center">
      <span class="font_9 pb-6">انتخاب بازه زمانی دلخواه</span>
      <section class="date-pickers-container">
        <div
          class="d-flex align-center justify-space-between pt-4 pb-3 divider"
        >
          <span v-if="hasHour" class="font-11-no-666-17">از تاریخ و ساعت:</span>
          <span v-else class="font-11-no-666-17">از تاریخ:</span>
          <CustomDatePicker :has-hour="hasHour" @date-value="assignFromDate" />
        </div>
        <div class="d-flex align-center justify-space-between pt-3 pb-4">
          <span v-if="hasHour" class="font-11-no-666-17">تا تاریخ و ساعت:</span>
          <span v-else class="font-11-no-666-17">تا تاریخ:</span>
          <CustomDatePicker
            :is-to-date="true"
            :has-hour="hasHour"
            class-name="custom-input-second"
            @date-value="assignToDate"
          />
        </div>
      </section>
      <v-flex class="pt-4">
        <v-btn
          ref="btn"
          text
          dark
          class="btn_small_normal ml-1"
          :disabled="isDisable"
          @click="sendForm()"
        >
          انتخاب
        </v-btn>
        <v-btn
          text
          outlined
          class="btn_small_normal mr-1"
          @click="closeDatePickerModal"
        >
          منصرف شدم
        </v-btn>
      </v-flex>
    </v-form>
  </v-card>
</template>

<script>
import CustomDatePicker from "./CustomDatePicker.vue"

export default {
  name: "DatePickerModal",
  components: {
    CustomDatePicker,
  },
  props: {
    hasHour: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fromDate: "",
      fromDateText: "",
      fromDatePayload: "",
      toDate: "",
      toDatePayload: "",
      toDateText: "",
      toDateComplete: "",
      isDisable: false,
    }
  },

  methods: {
    assignFromDate(fromDate) {
      this.fromDate = fromDate
      this.fromDatePayload = this.creatingDatePayload(fromDate)
      this.fromDateText = "از " + this.creatingDateText(fromDate)
      this.fromDateComplete = new Date(fromDate)
      this.calculateTwoDatesDifferences()
      this.checkIsDisableSubmitButton()
    },
    assignToDate(toDate) {
      this.toDate = toDate
      this.toDatePayload = this.creatingDatePayload(toDate)
      this.toDateText = "تا " + this.creatingDateText(toDate)
      this.toDateComplete = new Date(toDate)
      this.calculateTwoDatesDifferences()
      this.checkIsDisableSubmitButton()
    },
    closeDatePickerModal() {
      this.$emit("close-date-picker-modal")
    },
    sendForm() {
      const payload = {
        wholeToDate: this.toDate,
        wholeFromDate: this.fromDate,
        toDate: this.toDatePayload ? this.toDatePayload : null,
        toDateText: this.toDateText,
        fromDate: this.fromDatePayload ? this.fromDatePayload : null,
        fromDateText: this.fromDateText,
      }
      this.$emit("send-form", payload)
    },
    creatingDatePayload(date) {
      const splittedDate = date.split(/[/: ]+/)
      return splittedDate.join("")
    },
    creatingDateText(date) {
      const justDate = date.split(" ")[0]
      const justTime = date.split(" ")[1]
      return this.hasHour ? `${justDate} ساعت ${justTime}` : `${justDate}`
    },
    removeFirstTwoDigitOfDate(date) {
      return date.substring(2)
    },
    calculateTwoDatesDifferences() {
      if (this.fromDateComplete && this.toDateComplete) {
        return this.toDateComplete - this.fromDateComplete
      }
    },
    checkIsDisableSubmitButton() {
      if (this.calculateTwoDatesDifferences() > 0) {
        this.isDisable = false
      } else if (this.calculateTwoDatesDifferences() <= 0) {
        this.isDisable = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.divider {
  border-bottom: 1px dashed $van_color05;
}

.date-pickers-container {
  background: $van_color11;
  width: 300px;
  padding: 20px;
}
</style>
