<template>
  <v-layout class="tableFilterClass wrap" justify-start align-center>
    <slot></slot>
    <v-flex class="filterBut">
      <v-btn
        text
        dark
        :disabled="disableBtn"
        :class="['btn_small_normal', shakeIt ? 'shake' : '']"
        @click="setFilters()"
      >
        اعمال فیلتر‌ها
      </v-btn>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  props: {
    shakeIt: {
      default: false,
      type: Boolean,
    },
    isChanged: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      disableBtn: true,
    }
  },
  watch: {
    isChanged: function (newValue, oldValue) {
      //if any filter value has changed, set disabled to false
      this.disableBtn = !newValue
    },
  },
  methods: {
    setFilters() {
      //filter btn click
      this.disableBtn = true
      this.$emit("clicked")
    },
  },
}
</script>

<style lang="scss" scoped>
.tableFilterClass {
  background-color: #eee;
  padding: 17px 20px 17px 15px;
  margin: 5px 0 20px 0;
}
</style>
