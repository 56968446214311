<template>
  <div>
    <v-layout v-if="load" class="cards" justify-start wrap>
      <v-flex
        v-if="
          $store.state.hasOwnProperty('business') &&
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.hasOwnProperty('subscription') &&
          $store.state.business.tools.subscription.is_created
        "
      >
        <card v-if="isLoad" @view_form="view_function()" />
        <cardSkeleton v-else />
      </v-flex>
      <empty v-else @add_subscription="add_function()" />
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status"
      :content-class="
        $store.state.secondEditSubFormmodal
          ? 'secondModal vanmodal'
          : 'vanmodal'
      "
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      transition="slide-x-transition"
    >
      <add
        v-show="add"
        :key="componentKey"
        @closeModal="close_modal"
        @finishAdd="finish_add()"
      />
      <show v-if="view" @closeModal="close_modal()" />
    </v-dialog>

    <v-dialog
      v-if="$store.state.subscription"
      v-model="addIsFinalize"
      content-class="confirmationMessage withlinks"
      width="360"
    >
      <v-card height="100%" outlined>
        <v-layout align-center column justify-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/checkbox-marked-circle-outline.svg')"
              height="40"
              width="40"
            />
          </v-flex>
          <v-flex class="main-title font_3">
            درخواست ابزار پرداخت خودکار ثبت شد
          </v-flex>
          <v-flex class="main-des font_4">
            جهت فعال‌سـازی با مشاهده مستندات، تنظیمات مورد نیاز را انجام دهید:
          </v-flex>
          <v-flex style="margin-top: 17px; margin-bottom: -5px">
            <v-layout align-center justify-center>
              <v-flex
                v-if="$store.state.business.business_name"
                class="font_4"
                style="
                  padding: 10px 10px;
                  background-color: #f5f5f5;
                  min-width: 187px;
                  text-align: center;
                "
              >
                شناسه اپلیکیشن:
                {{ $store.state.business.business_name }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-btn
              class="btn_small_normal"
              dark
              target="_blank"
              text
              @click="pushToDoc()"
            >
              مشاهده مستندات
            </v-btn>
            <v-btn
              class="btn_small_normal"
              depressed
              outlined
              text
              @click="addIsFinalize = false"
            >
              ادامه
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import empty from "./empty"
import card from "./card"
import cardSkeleton from "./cardSkeleton"
import add from "../modals/add"
import show from "../modals/show"

export default {
  name: "ListOfCards",
  components: {
    card,
    add,
    show,
    empty,
    cardSkeleton,
  },
  data() {
    return {
      componentKey: 0,
      //this is modal status
      isLoad: false,
      status: false,
      add: false,
      view: false,
      subscription: {},
      load: true,
      addIsFinalize: false,
    }
  },
  mounted() {
    this.$http
      .get(`${this.$store.getters.apiUrlParam}/subscription/application/show`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + this.$store.state.token,
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.subscription = response.data.result.application
        if (this.subscription != null) {
          this.$store.commit("addSubscription", this.subscription)
          this.$store.commit(
            "setSignatureHasLoaded",
            this.subscription.has_secret_key
          )
        }
        this.isLoad = true
      })
      .catch(() => {
        //callsnackbar
      })
  },
  methods: {
    close_modal(obj) {
      this.add = false
      this.view = false
      this.status = false
      if (obj && obj.status) this.componentKey = !this.componentKey
    },
    add_function() {
      this.close_modal()
      this.add = true
      this.status = true
    },
    view_function() {
      this.close_modal()
      this.status = true
      this.view = true
    },
    finish_add() {
      this.addIsFinalize = true
    },
    pushToDoc() {
      var url = "https://vandarpay.github.io/docs/subscription/#directdebit"
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.addServiceCard {
  height: 160px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 0px 30px 0px;
  border: 1px solid #f5f5f5;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  cursor: pointer;

  .font_11 {
    color: $van_green !important;
  }

  div.backgound {
    height: 70px !important;
    width: 70px !important;
    background-color: #ebf5f3;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    > div {
      width: 50px;
      background-color: $van_green;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $van_color13;
    }
  }
}

.cards {
  margin-right: -5px;
  margin-left: -5px;

  > .flex {
    align-self: flex-end;
    //height: 120px;
    width: 100%;
    margin: 5px 5px;
  }
}

@media (max-width: 1200px) {
  .layout {
    justify-content: center;
  }
}
</style>
