<template>
  <v-card v-if="skeleton" outlined class="baseCard">
    <v-layout align-center>
      <v-flex class="icon">
        <span
          style="width: 40px; height: 40px; border-radius: 50%"
          class="default_gray ma-0"
        />
      </v-flex>
      <v-flex class="settlement_details">
        <v-layout>
          <v-flex class="settlemented">
            <div>
              <span style="width: 50%" class="default_gray" />
            </div>
          </v-flex>
          <v-flex>
            <div class="total">
              <span style="width: 30%" class="default_gray" />
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex>
        <div>
          <span style="width: 50%" class="default_gray" />
        </div>
      </v-flex>
      <v-flex>
        <div>
          <span style="width: 50%" class="default_gray" />
        </div>
      </v-flex>
      <v-flex class="menu">
        <v-menu
          transition="slide-y-transition"
          offset-y
          close-on-content-click
          bottom
          disabled
          content-class="up_menu"
        >
          <!-- this on slot sometime is undefined -->
          <template #activator="{ on }">
            <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
              <v-icon color="#6666664d" size="21" style="line-height: 40px">
                more_vert
              </v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-flex>
    </v-layout>
  </v-card>
  <v-card v-else outlined class="baseCard">
    <v-layout align-center class="font_2">
      <v-flex class="icon">
        <v-avatar v-if="!batchDone" tile size="40" color="#f9d3584d">
          <v-img
            max-width="16"
            max-height="20"
            :src="require('@/assets/img/newIcon/file-excel-outline.svg')"
          />
        </v-avatar>
        <v-avatar v-else size="40" color="#4c9f8726">
          <v-img
            max-width="16"
            max-height="20"
            :src="require('@/assets/img/newIcon/file-excel-outline.svg')"
          />
        </v-avatar>
      </v-flex>
      <section
        class="d-flex w-100 align-center"
        :class="{ 'flex-column justify-center': $vuetify.breakpoint.xsOnly }"
      >
        <div class="d-flex w-100 align-center">
          <v-flex class="settlement_details">
            <v-layout>
              <v-flex class="settlemented">
                <div
                  v-if="batchDone"
                  :class="{ 'd-flex': $vuetify.breakpoint.xsOnly }"
                >
                  {{ batchItem.status.submitted_count }} برداشت ثبت شده
                  <div
                    class="mr-1 total"
                    :class="{ 'd-none': $vuetify.breakpoint.smAndUp }"
                  >
                    از {{ batchItem.status.total_count }} برداشت
                  </div>
                </div>
                <div v-else>درحال ثبت ...</div>
              </v-flex>
              <v-flex>
                <div
                  v-if="batchDone"
                  :class="{ 'd-none': $vuetify.breakpoint.xsOnly }"
                  class="total"
                >
                  از {{ batchItem.status.total_count }} برداشت
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex :class="{ 'd-none': $vuetify.breakpoint.xsOnly }">
            <div>
              {{ (batchItem.total_amount / 10) | currency }}
              {{ currencyName }}
            </div>
          </v-flex>
          <v-flex :class="{ 'd-none': $vuetify.breakpoint.xsOnly }">
            <div>
              {{
                jalaliDate(batchItem.created_at * 1000, "jYYYY/jM/jD - HH:mm")
              }}
            </div>
          </v-flex>
          <v-flex class="menu">
            <v-menu
              transition="slide-y-transition"
              offset-y
              close-on-content-click
              bottom
              :disabled="!hasINIT"
              content-class="up_menu"
            >
              <!-- this on slot sometime is undefined -->
              <template #activator="{ on }">
                <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                  <v-icon
                    :color="hasINIT ? 'van_color03' : '#6666664d'"
                    size="21"
                    style="line-height: 30px"
                  >
                    more_vert
                  </v-icon>
                </v-btn>
              </template>

              <v-card outlined class="drop_down_menu">
                <v-list class="menu_v_list">
                  <template
                    v-if="
                      batchItem.cancelable && batchItem.status.pending_count > 0
                    "
                  >
                    <v-list-item @click="$emit('viewBatchDetail', batchItem)">
                      <v-list-item-content>
                        <v-list-item-title
                          >مشاهده و درخواست لغو</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <v-list-item @click="$emit('viewBatchDetail', batchItem)">
                      <v-list-item-content>
                        <v-list-item-title
                          >مشاهده لیست برداشت‌ها</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="$emit('exportBatch', batchItem.batch_id)">
                      <v-list-item-content>
                        <v-list-item-title
                          >خروجی ثبت برداشت‌ها</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-menu>
          </v-flex>
        </div>
        <div
          :class="{
            'd-none': $vuetify.breakpoint.smAndUp,
            'd-flex w-100 justify-space-between font-11-no-666-17':
              $vuetify.breakpoint.xsOnly,
          }"
        >
          <div>
            {{ (batchItem.total_amount / 10) | currency }}
            {{ currencyName }}
          </div>
          <div>
            {{ jalaliDate(batchItem.created_at * 1000, "jYYYY/jM/jD - HH:mm") }}
          </div>
        </div>
      </section>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: {
    batchItem: {
      type: Object,
      default: () => {},
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasINIT() {
      return !this.skeleton && this.batchItem.status.init_count === 0
    },
    batchDone() {
      if (this.skeleton) return false
      else
        return (
          this.batchItem.status.pending_count === 0 &&
          this.batchItem.status.init_count === 0
        )
    },
  },
}
</script>

<style lang="scss" scoped>
.v-avatar {
  .v-image {
    border-radius: 0;
  }
}
.baseCard {
  padding: 18px 20px;
  margin-top: 10px;
  height: 76px;
  border: none !important;

  .icon {
    max-width: 40px;
    margin-left: 10px;
  }
  .settlement_details {
    width: 55%;
    .settlemented {
      max-width: 170px;
    }
    .total {
      color: #999999 !important;
    }
  }
  .menu {
    max-width: 24px;
  }
}

@media screen and (max-width: 600px) {
  .baseCard {
    padding: 8px 20px;
  }
}
</style>
