<template>
  <v-card
    outlined
    flat
    :width="$vuetify.breakpoint.smAndDown ? '100%' : 470"
    class="pa-8 my-10"
  >
    <div class="font_9 mb-2">کسب‌وکار بسازید!</div>
    <p class="font_2 mb-5">
      با ثبت کسب‌وکار حقیقی یا حقوقی، به امکانات بیشتری دست پیدا کنید.
    </p>
    <v-btn
      class="linkBorder small font_2"
      text
      :to="'/onboarding'"
      style="color: #4c9f87 !important"
    >
      ساخت کسب‌وکار
    </v-btn>
  </v-card>
</template>
<script>
export default {
  name: "NewBusinessCard",
}
</script>

<style lang="scss" scoped>
.v-card {
  border: 1px solid;
  border-color: $van_color06 !important;
  background-color: $van_color08 !important;
}
</style>
