<template>
  <v-layout class="filter_row" justify-start align-center wrap>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="statusFilter"
        :items="statusFilterItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="همه وضعیت‌ها"
        style="max-width: 160px"
        :menu-props="{ contentClass: 'withActiveStyle' }"
      >
        <template #item="data">
          <v-layout align-center justify-center>
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="methodFilter"
        :items="methodFilterItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="همه متدها"
        style="max-width: 160px"
        :menu-props="{ contentClass: 'withActiveStyle', maxHeight: '365px' }"
      >
        <template #item="data">
          <v-layout align-center justify-center>
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="channelFilter"
        :items="channelItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="همه کانال‌ها"
        style="max-width: 160px"
        :menu-props="{ contentClass: 'withActiveStyle', maxHeight: '405px' }"
      >
        <template #item="data">
          <v-layout align-center justify-center>
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="duration"
        :items="dateItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="روز جاری"
        style="max-width: 160px"
        :menu-props="{ maxHeight: '305px', contentClass: 'withActiveStyle' }"
      >
        <template #selection="{ item }">
          <span>{{ item.value }}</span>
        </template>
        <template #item="data">
          <v-layout align-center justify-center>
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="filterBut">
      <v-btn
        text
        dark
        :class="addShake ? 'btn_small_normal shake' : 'btn_small_normal'"
        :loading="$store.state.logFilterLoading"
        @click="putFilter()"
      >
        اعمال فیلتر‌ها
      </v-btn>
    </v-flex>
    <!-- :disabled="disableFilter" -->
    <!-- modals -->

    <!-- <v-dialog
      content-class="confirmationMessage height294"
      v-model="filterDateStatus"
      width="360"
      no-click-animation
      persistent
    >
      <dateFilter
        v-if="filterDateStatus"
        @closeModal="close_dateFilter_modal"
        :toDateProp="toDate"
        :fromDateProp="fromDate"
      />
    </v-dialog> -->
  </v-layout>
</template>

<script>
// import dateFilter from '../modals/dateFilter.vue'
export default {
  name: "LogTableFilter",
  props: {
    resetFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addShake: false,
      //filter models
      duration: "DAY",
      statusFilter: "0",
      methodFilter: "0",
      channelFilter: "0",
      statusFilterItem: [
        {
          key: "0",
          value: "همه وضعیت‌ها",
        },
        {
          key: "200",
          value: "۲۰۰ | موفق",
        },
        {
          key: "301",
          value: "۳۰۱ | موفق",
        },
        {
          key: "302",
          value: "۳۰۲ | موفق",
        },
        {
          key: "422",
          value: "۴۲۲ | ورودی نادرست",
        },
        {
          key: "401",
          value: "۴۰۱ | توکن نامعتبر",
        },
        {
          key: "400",
          value: "۴۰۰ | درخواست نادرست",
        },
        {
          key: "403",
          value: "۴۰۳ |  دسترسی ندارید",
        },
        {
          key: "404",
          value: "۴۰۴ |  آدرس نادرست",
        },
        {
          key: "405",
          value: "۴۰۵ | متد نامعتبر",
        },
        {
          key: "429",
          value: "۴۲۹ | ترافیک درخواست",
        },
        {
          key: "500",
          value: "۵۰۰ | خطای سرور",
        },
        {
          key: "503",
          value: "۵۰۳ | اختلال موقت",
        },
      ],
      dateItem: [
        {
          key: "DAY",
          value: "روز جاری",
        },
        {
          key: "WEEK",
          value: "هفته جاری",
        },
        {
          key: "MONTH",
          value: "۳۰ روز اخیر",
        },

        // {
        //   key: 'year',
        //   value: 'سال جاری',
        // },
        // {
        //   key: '5',
        //   value: 'بازه زمانی دلخواه',
        // },
      ],
      lastDuration: "0",
    }
  },

  computed: {
    methodFilterItem() {
      switch (this.channelFilter) {
        case "IPG":
          return [
            {
              key: "0",
              value: "همه متدها",
            },
            {
              key: "POST",
              value: "POST",
            },
            {
              key: "GET",
              value: "GET",
            },
            {
              key: "PUT",
              value: "PUT",
            },
            {
              key: "DELETE",
              value: "DELETE",
            },
          ]
        case "FORM":
        case "SETTLEMENT":
        case "P2P":
        case "REFUND":
        case "CUSTOMER":
          return [
            {
              key: "0",
              value: "همه متدها",
            },
            {
              key: "POST",
              value: "POST",
            },
            {
              key: "GET",
              value: "GET",
            },
            {
              key: "PUT",
              value: "PUT",
            },
            {
              key: "DELETE",
              value: "DELETE",
            },
          ]
        case "PBV":
          return [
            {
              key: "0",
              value: "همه متدها",
            },
            {
              key: "SEND",
              value: "SEND",
            },
            {
              key: "VERIFY",
              value: "VERIFY",
            },
            {
              key: "CHECKOUT_INDEX",
              value: "CHECKOUT_INDEX",
            },
            {
              key: "CHECKOUT_SHOW",
              value: "CHECKOUT_SHOW",
            },
            {
              key: "POST",
              value: "POST",
            },
            {
              key: "GET",
              value: "GET",
            },
          ]
        case "DIRECT_DEBIT":
          return [
            {
              key: "0",
              value: "همه متدها",
            },
            {
              key: "POST",
              value: "POST",
            },
            {
              key: "GET",
              value: "GET",
            },
            {
              key: "PUT",
              value: "PUT",
            },
            {
              key: "DELETE",
              value: "DELETE",
            },
            {
              key: "PATCH",
              value: "PATCH",
            },
          ]
        default:
          return [
            {
              key: "0",
              value: "همه متدها",
            },
            {
              key: "POST",
              value: "POST",
            },
            {
              key: "GET",
              value: "GET",
            },
            {
              key: "PUT",
              value: "PUT",
            },
            {
              key: "DELETE",
              value: "DELETE",
            },
            {
              key: "PATCH",
              value: "PATCH",
            },
            {
              key: "CHECKOUT_INDEX",
              value: "CHECKOUT_INDEX",
            },
            {
              key: "CHECKOUT_SHOW",
              value: "CHECKOUT_SHOW",
            },
          ]
      }
    },
    channelItem() {
      switch (this.methodFilter) {
        case "SEND":
        case "VERIFY":
        case "REQUEST":
        case "CALLBACK":
        case "CONFIRM":
        case "TRANSACTION_DETAIL":
          return [
            {
              key: "0",
              value: "همه کانال‌ها",
            },
            {
              key: "IPG",
              value: "درگاه",
            },
            {
              key: "PBV",
              value: "پرداخت با وندار",
            },
          ]
        case "CHECKOUT_INDEX":
        case "CHECKOUT_SHOW":
          return [
            {
              key: "0",
              value: "همه کانال‌ها",
            },
            {
              key: "PBV",
              value: "پرداخت با وندار",
            },
          ]
        case "POST":
        case "GET":
        case "PUT":
        case "DELETE":
          return [
            {
              key: "0",
              value: "همه کانال‌ها",
            },
            {
              key: "IPG",
              value: "درگاه",
            },
            {
              key: "FORM",
              value: "فرم",
            },
            {
              key: "SETTLEMENT",
              value: "تسویه",
            },
            {
              key: "P2P",
              value: "انتقال وجه",
            },
            {
              key: "REFUND",
              value: "بازگشت وجه",
            },
            {
              key: "DIRECT_DEBIT",
              value: "پرداخت خودکار",
            },
            {
              key: "CASH-IN",
              value: "واریز بانکی",
            },
            {
              key: "CUSTOMER",
              value: "مشتریان",
            },
            {
              key: "PBV",
              value: "پرداخت با وندار",
            },
            {
              key: "MPG",
              value: "پرداخت یکپارچه",
            },
          ]
        default:
          return [
            {
              key: "0",
              value: "همه کانال‌ها",
            },
            {
              key: "IPG",
              value: "درگاه",
            },
            {
              key: "FORM",
              value: "فرم",
            },
            {
              key: "SETTLEMENT",
              value: "تسویه",
            },
            {
              key: "P2P",
              value: "انتقال وجه",
            },
            {
              key: "REFUND",
              value: "بازگشت وجه",
            },
            {
              key: "DIRECT_DEBIT",
              value: "پرداخت خودکار",
            },
            {
              key: "CASH-IN",
              value: "واریز بانکی",
            },
            {
              key: "CUSTOMER",
              value: "مشتریان",
            },
            {
              key: "PBV",
              value: "پرداخت با وندار",
            },
            {
              key: "MPG",
              value: "پرداخت یکپارچه",
            },
          ]
      }
    },
  },
  watch: {
    //   '$store.state.report.updateFilters': {
    //     handler: function (newValue) {
    //       if (newValue) {
    //         this.getFilters()
    //         this.$emit('putFilter')
    //         this.$store.commit('syncFilterDone')
    //         this.lastDuration = '0'
    //         this.fromDateText = null
    //         this.toDateText = null
    //       }
    //     },
    //     deep: true,
    //   },
    //   $route: {
    //     handler: function (to, from) {
    //       this.$store.commit('deleteAllFilters')
    //     },
    //     immediate: true,
    //   },
    resetFilter: function (newValue) {
      if (newValue) {
        this.duration = "DAY"
        this.statusFilter = "0"
        this.methodFilter = "0"
        this.channelFilter = "0"
        this.resetFilter = false
        this.$emit("reset")
      }
    },
    statusFilter: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
    methodFilter: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
    channelFilter: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
    duration: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
  },
  methods: {
    disableAddShake() {
      this.addShake = false
    },
    putFilter() {
      var data = {
        status: true,
        duration: this.duration,
        statusFilter: this.statusFilter,
        channelFilter: this.channelFilter,
        methodFilter: this.methodFilter,
        // toDate: this.toDate,
        // fromDate: this.fromDate,
      }
      // this.$store.commit('updateFilters', data)
      this.$emit("putFilter", data)
    },
  },
}
</script>
