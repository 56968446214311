<template>
  <v-layout class="filter_row" justify-start align-center wrap>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="roleFilter"
        :items="roleFilterItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="همه"
        style="max-width: 160px"
        :menu-props="{ contentClass: 'withActiveStyle' }"
      >
        <template #item="data">
          <v-layout align-center justify-center>
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="stateFilter"
        :items="stateFilterItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="همه متدها"
        style="max-width: 160px"
        :menu-props="{ contentClass: 'withActiveStyle', maxHeight: '365px' }"
      >
        <template #item="data">
          <v-layout align-center justify-center>
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="duration"
        :items="dateItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="روز جاری"
        style="max-width: 160px"
        :menu-props="{ maxHeight: '305px', contentClass: 'withActiveStyle' }"
      >
        <template #selection="{ item }">
          <span
            v-if="
              duration === '5' && (fromDateText != null || toDateText != null)
            "
            style="
              font-size: 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 120px;
              overflow: hidden;
            "
          >
            {{ fromDateText }} {{ toDateText }}
          </span>
          <span v-else>{{ item.value }}</span>
        </template>
        <template #item="data">
          <v-layout
            align-center
            justify-center
            @click="openDialog(data.item.key)"
          >
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="filterBut">
      <v-btn
        text
        dark
        :class="addShake ? 'btn_small_normal shake' : 'btn_small_normal'"
        :loading="$store.state.logFilterLoading"
        @click="putFilter()"
      >
        اعمال فیلتر‌ها
      </v-btn>
    </v-flex>
    <!-- modals -->

    <v-dialog
      v-model="filterDateStatus"
      content-class="confirmationMessage height294"
      width="360"
      no-click-animation
      persistent
    >
      <date-filter
        v-if="filterDateStatus"
        :to-date-prop="toDate"
        :from-date-prop="fromDate"
        @closeModal="close_dateFilter_modal"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import DateFilter from "../../report/modals/dateFilter"
export default {
  name: "MiandoTableFilter",
  components: { DateFilter },
  props: {
    resetFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addShake: false,
      //filter models
      fromDate: null,
      toDate: null,
      duration: "0",
      roleFilter: "0",
      stateFilter: "0",
      roleFilterItem: [
        {
          key: "0",
          value: "همه",
        },
        {
          key: "buyer",
          value: "خریدار",
        },
        {
          key: "seller",
          value: "فروشنده",
        },
      ],
      stateFilterItem: [
        {
          key: "0",
          value: "همه وضعیت‌ها",
        },
        {
          key: "draft",
          value: "پیش‌نویس",
        },
        {
          key: "contract",
          value: "توافق شده",
        },
        {
          key: "paid",
          value: "پرداخت شده",
        },
        {
          key: "rejected",
          value: "رد شده",
        },
        {
          key: "canceled",
          value: "لغو شده",
        },
        {
          key: "investigation",
          value: "ثبت مشکل شده",
        },
        {
          key: "finalized",
          value: "تکمیل شده",
        },
      ],
      dateItem: [
        {
          key: "0",
          value: "از ابتدای ثبت‌نام تاکنون",
        },
        {
          key: "day",
          value: "روز جاری",
        },
        {
          key: "week",
          value: "هفته جاری",
        },
        {
          key: "month",
          value: "ماه جاری",
        },
        {
          key: "year",
          value: "سال جاری",
        },
        {
          key: "5",
          value: "بازه زمانی دلخواه",
        },
      ],
      filterDateStatus: false,
      fromDateText: null,
      toDateText: null,
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      lastDuration: "0",
    }
  },

  watch: {
    resetFilter: function (newValue) {
      if (newValue) {
        this.duration = "0"
        this.roleFilter = "0"
        this.stateFilter = "0"
        this.channelFilter = "0"
        this.resetFilter = false
        this.$emit("reset")
      }
    },
    roleFilter: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
    stateFilter: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
    channelFilter: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
    duration: function (newValue) {
      if (newValue !== "5") {
        this.addShake = true
        const timeOut = setTimeout(() => this.disableAddShake(), 300)
      }
    },
    fromDate: function (newValue) {
      if (newValue != null) {
        this.addShake = true
        const timeOut = setTimeout(() => this.disableAddShake(), 300)
      }
    },
    toDate: function (newValue) {
      if (newValue != null) {
        this.addShake = true
        const timeOut = setTimeout(() => this.disableAddShake(), 300)
      }
    },
  },
  methods: {
    disableAddShake() {
      this.addShake = false
    },
    putFilter() {
      let data = {
        status: true,
        duration: this.duration,
        roleFilter: this.roleFilter,
        channelFilter: this.channelFilter,
        stateFilter: this.stateFilter,
        toDate: this.toDate,
        fromDate: this.fromDate,
      }
      this.$store.commit("updateFiltersMiando", data)
      this.$emit("putFilter", data)
    },
    close_dateFilter_modal(obj) {
      this.filterDateStatus = false
      if (obj.status) {
        this.fromDate = obj.fromDate
        this.toDate = obj.toDate
        this.fromDateText = obj.fromDateText
        this.toDateText = obj.toDateText
      } else {
        this.duration = this.lastDuration
      }
    },
    openDialog(item) {
      this.duration = item
      if (item === "5") {
        this.filterDateStatus = true
      } else {
        this.toDateText = null
        this.fromDateText = null
        this.fromDate = null
        this.toDate = null
        this.lastDuration = this.duration
        this.$store.commit("updateToAndFormDateMiando", null)
      }
    },
  },
}
</script>
