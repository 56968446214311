const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpCashInEnable() {
    return context.$http.post(
      context.$store.getters.apiWithVersionParam("3") + "/cash-in/enable",
      null,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpCashInAccountBalance(payload) {
    return context.$http.post(
      context.$store.getters.apiWithVersionParam("3") +
        "/cash-in/account/balance",
      payload
    )
  },
  httpCashInAccountDeposit(payload) {
    return context.$http.post(
      context.$store.getters.apiWithVersionParam("3") +
        "/cash-in/account/deposit",
      payload
    )
  },
  httpCashInAccount() {
    return context.$http.get(
      context.$store.getters.apiWithVersionParam("3") + "/cash-in/account",
      {
        timeout: 0,
        headers: apiHeaders(context),
      }
    )
  },
  httpActiveAutoCashIn(payload) {
    return context.$http.post(
      context.$store.getters.apiUrlParamV3 + "/cash-in/auto/",
      payload
    )
  },
  httpDeActiveAutoCashIn(queryParam) {
    return context.$http.delete(
      context.$store.getters.apiUrlParamV3 + `/cash-in/auto/${queryParam}`
    )
  },
  httpGetAutoCashInInfo() {
    return context.$http.get(
      context.$store.getters.apiUrlParamV3 + "/cash-in/auto/"
    )
  },
  httpRevokeCashIn() {
    return context.$http.post(
      context.$store.getters.apiWithVersionParam("3") + "/cash-in/revoke",
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpTerminateCashIn() {
    return context.$http.post(
      context.$store.getters.apiWithVersionParam("3") + "/cash-in/terminate",
      null,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpPostSuspiciousPayment(payload, id) {
    return context.$http.post(
      `${context.$store.getters.apiUrlParamV3}/cash-in/suspicious-payment/${id}`,
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
})
