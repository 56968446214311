<template>
  <ul>
    <div class="d-flex align-center">
      <v-img
        width="32"
        height="32"
        max-width="32"
        max-height="32"
        :src="require(`@/assets/img/${svgName}.svg`)"
      />
      <span class="mr-2 font-12-bo-999-18"> {{ guidanceTitle }} </span>
    </div>
    <li v-for="item in guidanceItems" :key="item" class="font_28 mr-10">
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "GuidanceComponent",
  props: {
    guidanceTitle: {
      type: String,
      default: "",
    },
    svgName: {
      type: String,
      default: "guide-icon",
    },
    guidanceItems: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style-position: inside;
  list-style-image: url("@/assets/img/bullet.svg");
}

@media screen and (max-width: 600px) {
  ul {
    width: 316.66px;
    padding-left: 0 !important;
  }
}
</style>
