<template>
  <v-layout column>
    <v-flex>
      <!-- toolbar-->
      <v-toolbar flat height="60" color="van_color13">
        <v-btn icon fab @click.native="$emit('closeModal')">
          <v-icon color="van_color03" size="21"> close </v-icon>
        </v-btn>
        <v-toolbar-title class="pr-0 bold subheading">
          {{ toolbarName }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items class="d-flex align-center">
          <slot name="toolbarItems" />
        </v-toolbar-items>
      </v-toolbar>

      <!--  modal body-->
      <v-layout
        column
        :class="[
          'modal-body van_color12',
          $vuetify.breakpoint.xsOnly ? 'pa-6' : 'pa-8',
        ]"
      >
        <v-flex> <slot /></v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "TicketsLeftModal",
  props: {
    toolbarName: { default: "", type: String },
  },
}
</script>
<style lang="scss" scoped>
.modal-body {
  overflow: auto;
  height: calc(100vh - 68px);
}

:deep(.v-toolbar__content) {
  padding: 0 30px !important;
}

:deep(.v-toolbar__content > .v-btn.v-btn--icon:first-child) {
  margin-right: -8px !important;
}

:deep(.v-icon) {
  width: auto !important;
}
</style>
