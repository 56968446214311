<script>
export default {
  name: "AscendingIcon",
  props: {
    width: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 12,
    },
    color: {
      type: String,
      default: "#666666",
    },
  },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 16 13.996"
  >
    <path
      :fill="color"
      d="M145.789,14l4.018-5,2.059,2.233A1.349,1.349,0,0,0,154,11.062l3.539-5.829,1.172.913a.736.736,0,0,0,.446.173h0c.29,0,.5-.22.553-.575l.729-5.023a.672.672,0,0,0-.126-.552.608.608,0,0,0-.714-.1L154.91,2a.637.637,0,0,0-.433.484.637.637,0,0,0,.288.582l1.119.871-3.108,5.181L150.7,6.871a1.342,1.342,0,0,0-2.142.187l-4.1,5.138Z"
      transform="translate(-144.453)"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
