<template>
  <left-modal
    toolbar-name="راهنما و فایل نمونه"
    @closeModal="$emit('closeModal')"
  >
    <general-card>
      <div class="d-flex align-center">
        <div>
          <div class="font_13 mt-4">شرایط فایل اکسل</div>
          <div class="font_2 mt-1">
            <ul class="conditions">
              <li>در هر فایل حداکثر، امکان ثبت {{ maxRecordsCount }} برداشت وجود دارد.</li>
              <li>واحد پولی مبلغ در فایل اکسل را به ریال وارد کنید.</li>
              <li>سقف واریز به هر شماره شبا، ۱۰۰٬۰۰۰٬۰۰۰ تومان است.</li>
              <li>کف واریز به هر شماره شبا، {{minAmountEachRecord | currency}} تومان است.</li>
            </ul>
          </div>
        </div>
        <a
          :href="sampleFileCdn"
          class="d-flex align-center font_2 pa-4 green_back_2 radius-4"
        >
          <div class="pa-4 rounded-edge">
            <download-outline fillColor="#4C9F87" />
          </div>
         <span>فایل نمونه</span>
        </a>
      </div>
    </general-card>
    <general-card>
      <div class="font_13 mt-4">شناسه تسویه (Track id) در فایل اکسل چیست؟</div>
      <div class="font_2 mt-1">
        شناسه تسویه با تعداد کاراکتر دلخواه و به صورت اختیاری توسط شما برای هر ردیف برداشت، در فایل اکسل وارد شده، این کد برای جلوگیری از تکرار تراکنش استفاده می‌شود.<p class="pt-5">فرمت پیشنهادی:
          <ul class="conditions">
          <li>
            شماره شبا هر ردیف و تاریخ امروز: IR۷۷۰۷۰۰۰۰۱۰۰۰۱۱۱۲۶۹۹۰۳۰۰۱۱\۱\۰۰
          </li>
        </ul>
        </p>
        
      </div>
    </general-card>
  </left-modal>
</template>

<script>
import leftModal from "@/components/modals/leftModal.vue"
import generalCard from "@/components/elements/generalCard"
import downloadOutline from "@/assets/img/download-outline.vue"

export default {
  name: "GuidelineModal",
  components: {
    generalCard,
    leftModal,
    downloadOutline,
  },
  props:{
    maxRecordsCount:{
      default:1000,
      type:Number
    },
    minAmountEachRecord:{
      default:5000,//tooman
      type:Number
    },
    sampleFileCdn: {
      default: "",
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.conditions {
  list-style: inside;
  li {
    &::marker {
      color: $van-color03;
    }
  }
}
.rounded-edge {
  display: flex;
  border-radius: 50%;
  border: 2px solid $van_green;
  margin-left: 8px;
}

.green_back_2 {
  &:hover {
    background-color: #4c9f874d !important;
  }
}

a{
  text-decoration: none;
}
</style>
