<template>
  <v-flex class="copy" style="max-width: fit-content">
    <v-btn
      ref="btn"
      text
      :style="styleBtn"
      :depressed="depressed"
      :min-width="minWidth"
      :outlined="outlined"
      :class="classBtn"
      :disabled="disabled"
      :color="colorBtn"
      @click="copyLink(inputId)"
    >
      <v-tooltip v-if="beforeCopyHoverText && !showTooltip" top center>
        <template #activator="{ on }">
          <v-icon
            color="van_color03"
            :class="label ? 'ml-1' : null"
            size="14"
            v-on="on"
          >
            content_copy
          </v-icon>
        </template>
        <span>{{ beforeCopyHoverText }}</span>
      </v-tooltip>
      <v-icon
        v-else
        color="van_color03"
        :class="label ? 'ml-1' : null"
        size="14"
      >
        content_copy
      </v-icon>

      <span v-if="label" class="font_4">{{ label }}</span>
      <v-tooltip v-model="showTooltip" top center :open-on-hover="openOnHover">
        <template #activator="{ on }">
          <v-btn
            style="
              position: absolute;
              opacity: 0 !important;
              width: 1px;
              margin: 0px;
              padding: 0px;
              z-index: -1;
            "
            :v-on="showTooltip ? on : null"
          />
        </template>
        <span>کپی شد</span>
      </v-tooltip>
    </v-btn>
    <input :id="inputId" v-model="value" class="hidden-input" />
  </v-flex>
</template>

<script>
export default {
  name: "CopyBtnGeneral",
  props: {
    inputId: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    beforeCopyHoverText: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    colorBtn: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    minWidth: {
      type: Number,
      default: 32,
    },
    depressed: { type: Boolean, default: true },
    styleBtn: {
      type: String,
      default: "height: 32px !important; border-color: #bbb ;",
    },
    classBtn: {
      type: String,
      default: "tblCpy-receipt_url px-2 ma-0 btn_small_normal",
    },
    openOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  methods: {
    copyLink(inputId) {
      let copyText = document.querySelector("#" + inputId)

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        this.showTooltip = true
        setTimeout(() => {
          this.showTooltip = false
        }, 2000)
      }
    },
  },
}
</script>
