<template>
  <v-flex
    :class="`miniBox font_2 ${shakeIt ? 'shake' : ''}`"
    d-flex
    justify-space-between
    align-center
    style="position: relative"
  >
    <span class="pl-4" style="max-width: fit-content">{{ label }}:</span>
    <v-tooltip v-if="label == 'آخرین به‌روزرسانی'" top>
      <template #activator="{ on }">
        <v-icon
          size="20"
          style="direction: rtl; width: 20px"
          class="van_color05--text mr-n3"
          v-on="on"
        >
          mdi_info_outline
        </v-icon>
      </template>
      <span class="font_33"
        >تاریخ اخرین به‌روزرسانی، بر اساس تاریخ انجام و تایید تراکنش و تاثیر آن
        روی کیف پول است.</span
      >
    </v-tooltip>
    <span style="text-align: left; flex: 1 1 auto">{{ value }}</span>
    <copyBtn
      v-if="containCopy"
      :value="value"
      class="mr-2"
      :input-id="'show-hidden-input-' + value"
    />
  </v-flex>
</template>

<script>
import copyBtn from "@/components/report/elements/show/details/copyBtn.vue"

export default {
  components: {
    copyBtn,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    containCopy: {
      type: String,
      default: "",
    },
    shakeIt: {
      default: false,
      type: Boolean,
    },
  },
}
</script>
