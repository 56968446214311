<template>
  <div>
    <!-- if no data was available -->
    <div
      v-if="
        ($store.state[$store.getters.userOrBuisenss].hasOwnProperty('tools') &&
          !$store.state[$store.getters.userOrBuisenss].tools.p2p.is_created) ||
        ($store.state[$store.getters.userOrBuisenss].hasOwnProperty('tools') &&
          $store.state[$store.getters.userOrBuisenss].tools.p2p.is_created &&
          !loading &&
          transfers.length == 0)
      "
    >
      <v-layout
        column
        wrap
        justify-center
        align-center
        style="padding: 30px 10px; background-color: white; border-raduis: 4px"
      >
        <v-flex class="mb-1">
          <h2 v-if="dontLet">شما شناسه وندار ندارید</h2>
          <h2 v-else>هنوز انتقالی انجام نشده است</h2>
        </v-flex>
        <v-flex>
          <div v-if="dontLet" class="font_10 mb-7" style="margin-bottom: 30px">
            برای دریافت شناسه وندار خود، به بخش تنظیمات مراجعه کنید
          </div>
          <div v-else class="font_10 mb-7" style="margin-bottom: 30px">
            تاریخچه‌ی انتقال‌های درون‌ونداری شما اینجا نمایش داده می‌شود
          </div>
        </v-flex>
        <v-flex>
          <div
            style="
              background-color: rgb(238 238 238 / 0.4);
              border-radius: 50%;
              padding: 16px;
            "
          >
            <v-avatar size="48" style="border-radius: 50%; background: #eeeeee">
              <svg
                style="width: 17.003px; height: 15.449px; overflow: initial"
                xmlns="http://www.w3.org/2000/svg"
                width="17.003"
                height="15.449"
                viewBox="0 0 17.003 15.449"
              >
                <g transform="translate(-194.234 -462.004)">
                  <path
                    style="fill: #666; fill-rule: evenodd"
                    d="M225.544,21.469l-1.618,1.616a1.145,1.145,0,0,1-.807.334H219.85a.341.341,0,0,1-.242-.584l2.258-2.251a.341.341,0,0,0-.242-.584h-3.172a.572.572,0,0,1-.572-.572V12.572a.572.572,0,0,1,.572-.572h6.855a.572.572,0,0,1,.572.572v8.09A1.144,1.144,0,0,1,225.544,21.469Z"
                    transform="translate(-23.646 450.004)"
                  />
                  <g transform="translate(189 458.809)">
                    <path
                      style="fill: #666"
                      d="M16.75,9.146,14.444,6.834a.413.413,0,0,0-.705.29V8.608H10.709a.829.829,0,1,0,0,1.657H13.73v1.483a.411.411,0,0,0,.705.29l2.307-2.312a.407.407,0,0,0,.008-.58Z"
                      transform="translate(5.369 1.483)"
                    />
                    <path
                      style="fill: #666"
                      d="M8.642,10.824,6.336,13.136a.425.425,0,0,0,0,.588l2.307,2.312a.413.413,0,0,0,.705-.29V14.255h3.021a.829.829,0,1,0,0-1.657H9.348V11.114A.411.411,0,0,0,8.642,10.824Z"
                      transform="translate(6.369 2.483)"
                    />
                  </g>
                </g>
              </svg>
            </v-avatar>
          </div>
        </v-flex>
      </v-layout>
    </div>

    <div v-else>
      <v-layout
        justify-space-between
        :align-center="!$vuetify.breakpoint.xsOnly"
        :column="$vuetify.breakpoint.xsOnly"
      >
        <v-flex>
          <h2 class="my-6 mr-0 ml-auto">آخرین انتقال‌های من</h2>
        </v-flex>
      </v-layout>

      <div />

      <!-- skeleton for table -->
      <div v-if="loading">
        <div v-if="!$vuetify.breakpoint.smAndDown" class="loading">
          <v-layout
            v-for="index in 10"
            :key="index"
            align-center
            class="cardRow"
          >
            <v-flex class="ml-4" style="max-width: 40px">
              <v-avatar
                color="#eee"
                size="40"
                class="default_gray ma-0"
                style="border-radius: 50%"
              />
            </v-flex>
            <v-flex style="max-width: 195px">
              <span class="default_gray" />
            </v-flex>
            <v-flex> <span class="default_gray" /></v-flex>
            <v-flex style="max-width: 140px">
              <span class="default_gray" />
            </v-flex>
            <v-flex style="max-width: 150px">
              <span class="default_gray" />
            </v-flex>
            <v-flex style="max-width: 40px">
              <span class="default_gray" />
            </v-flex>
          </v-layout>
        </div>
        <div v-else>
          <v-layout
            v-for="index in 10"
            :key="index"
            class="cardRow"
            column
            align-space-between
          >
            <v-flex>
              <v-layout>
                <v-flex>
                  <v-layout align-center>
                    <v-flex style="max-width: 41px">
                      <v-avatar
                        color="#eee"
                        size="40"
                        class="default_gray ma-0"
                        style="border-radius: 50%"
                      />
                    </v-flex>
                    <v-flex class="mr-5" style="max-width: 40%">
                      <div class="van_color02--text" style="font-size: 14px">
                        <span class="default_gray" />
                      </div>
                      <div
                        class="van_color03--text"
                        style="font-size: 10px; max-width: 60%"
                      >
                        <span class="default_gray" />
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex style="max-width: 40px">
                  <span class="default_gray" />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout class="mt-5">
                <v-flex
                  style="
                    background-color: #f5f5f5;
                    display: flex;
                    align-item: center;
                    justify-content: center;
                    padding: 14px 27px;
                    margin-left: 8px;
                  "
                />
                <v-flex
                  style="
                    background-color: #f5f5f5;
                    display: flex;
                    align-item: center;
                    justify-content: center;
                    padding: 14px 27px;
                  "
                />
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <!-- filled table -->
      <div v-else-if="transfers.length != 0">
        <div v-if="!$vuetify.breakpoint.smAndDown">
          <v-layout
            v-for="(item, index) in transfers"
            :key="index"
            align-center
            class="cardRow"
          >
            <v-flex style="max-width: 40px; margin-left: 15px">
              <v-avatar
                v-if="item.receiver.avatar != null"
                size="40"
                style="border: 1px solid #eee; border-radius: 50%"
              >
                <v-img
                  :src="$store.state.storage_base_url + item.receiver.avatar"
                  alt="avatar"
                />
              </v-avatar>
              <v-avatar v-else color="#eee" size="40">
                <v-icon size="15"> person </v-icon>
              </v-avatar>
            </v-flex>
            <v-flex style="width: 20%">
              {{ item.receiver.name }}
            </v-flex>
            <v-flex v-if="item.receiver.slug != null">
              {{ item.receiver.slug }}‌‌‌‌‌‌‌‌‌‌‌@
            </v-flex>
            <v-flex style="max-width: 140px">
              <span class="font_2" style="margin-left: 4px">{{
                (item.amount - item.wage) | currency
              }}</span>
              <span class="font_18">{{ currencyName }}</span>
            </v-flex>
            <v-flex style="max-width: 150px">
              {{ item.payment_date }}
            </v-flex>
            <v-flex style="max-width: 40px">
              <v-menu
                transition="slide-y-transition"
                offset-y
                close-on-content-click
                bottom
                content-class="up_menu"
              >
                <!-- this on slot sometime is undefined -->
                <template #activator="{ on }">
                  <v-btn
                    icon
                    size="24"
                    fab
                    small
                    style="margin: 0px 8px"
                    v-on="on"
                  >
                    <v-icon
                      color="van_color03"
                      size="21"
                      style="line-height: 40px"
                    >
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>

                <v-card outlined class="drop_down_menu">
                  <v-list class="menu_v_list">
                    <v-list-item
                      :disabled="
                        !(
                          $store.state[
                            $store.getters.userOrBuisenss
                          ].hasOwnProperty('wallet') &&
                          convertToRial(
                            $store.state[$store.getters.userOrBuisenss].wallet
                          ) >= convertToRial(1000) &&
                          ($store.state.isUser ||
                            (!$store.state.isUser &&
                              !['observer', 'operator'].includes(
                                $store.state.business.role
                              )))
                        )
                      "
                      @click="$emit('doSameTransfer', item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>تکرار انتقال وجه</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      :disabled="
                        !(
                          $store.state[
                            $store.getters.userOrBuisenss
                          ].hasOwnProperty('wallet') &&
                          convertToRial(
                            $store.state[$store.getters.userOrBuisenss].wallet
                          ) >= convertToRial(1000) &&
                          ($store.state.isUser ||
                            (!$store.state.isUser &&
                              !['observer', 'operator'].includes(
                                $store.state.business.role
                              )))
                        )
                      "
                      @click="$emit('doSameId', item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          انتقال جدید به همین شناسه
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showDetail(item)">
                      <v-list-item-content>
                        <v-list-item-title>جزییات تراکنش</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-flex>
          </v-layout>

          <!-- load more -->
          <observer
            v-if="!endOfLoadMore"
            @intersect="getDataFromApi('loadMore')"
          />

          <div v-if="loadMoreSkeleton && !endOfLoadMore">
            <v-layout
              v-for="index in 10"
              :key="index"
              align-center
              class="cardRow"
            >
              <v-flex style="max-width: 40px; margin-left: 15px">
                <v-avatar
                  color="#eee"
                  size="40"
                  class="default_gray"
                  style="border-radius: 50%; margin: 0px"
                />
              </v-flex>
              <v-flex style="max-width: 195px">
                <span class="default_gray" />
              </v-flex>
              <v-flex> <span class="default_gray" /></v-flex>
              <v-flex style="max-width: 140px">
                <span class="default_gray" />
              </v-flex>
              <v-flex style="max-width: 150px">
                <span class="default_gray" />
              </v-flex>
              <v-flex style="max-width: 40px">
                <span class="default_gray" />
              </v-flex>
            </v-layout>
          </div>
        </div>
        <div v-else>
          <v-layout
            v-for="(item, index) in transfers"
            :key="index"
            class="cardRow"
            column
            align-space-between
          >
            <v-flex>
              <v-layout>
                <v-flex>
                  <v-layout align-center>
                    <v-flex style="max-width: 41px">
                      <v-avatar
                        v-if="item.receiver.avatar != null"
                        size="50"
                        style="border: 1px solid #eee; border-radius: 50%"
                      >
                        <v-img
                          :src="
                            $store.state.storage_base_url + item.receiver.avatar
                          "
                          alt="avatar"
                        />
                      </v-avatar>
                      <v-avatar v-else color="#eee" size="50">
                        <v-icon size="15"> person </v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex style="margin-right: 20px">
                      <div class="van_color02--text" style="font-size: 14px">
                        {{ item.receiver.name }}
                      </div>
                      <div class="van_color03--text" style="font-size: 10px">
                        {{ item.receiver.slug }}‌‌‌‌‌‌‌‌‌‌‌@
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex style="max-width: 40px">
                  <v-menu
                    transition="slide-y-transition"
                    offset-y
                    close-on-content-click
                    bottom
                    content-class="up_menu"
                  >
                    <!-- this on slot sometime is undefined -->
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        size="24"
                        fab
                        small
                        style="margin: 0px 8px"
                        v-on="on"
                      >
                        <v-icon
                          color="van_color03"
                          size="21"
                          style="line-height: 40px"
                        >
                          more_vert
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-card outlined class="drop_down_menu">
                      <v-list class="menu_v_list">
                        <v-list-item
                          :disabled="
                            !(
                              $store.state[
                                $store.getters.userOrBuisenss
                              ].hasOwnProperty('wallet') &&
                              convertToRial(
                                $store.state[$store.getters.userOrBuisenss]
                                  .wallet
                              ) >= convertToRial(1000) &&
                              ($store.state.isUser ||
                                (!$store.state.isUser &&
                                  !['observer', 'operator'].includes(
                                    $store.state.business.role
                                  )))
                            )
                          "
                          @click="$emit('doSameTransfer', item)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              تکرار انتقال وجه
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          :disabled="
                            !(
                              $store.state[
                                $store.getters.userOrBuisenss
                              ].hasOwnProperty('wallet') &&
                              convertToRial(
                                $store.state[$store.getters.userOrBuisenss]
                                  .wallet
                              ) >= convertToRial(1000) &&
                              ($store.state.isUser ||
                                (!$store.state.isUser &&
                                  !['observer', 'operator'].includes(
                                    $store.state.business.role
                                  )))
                            )
                          "
                          @click="$emit('doSameId', item)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              انتقال جدید به همین شناسه
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="showDetail(item)">
                          <v-list-item-content>
                            <v-list-item-title>جزییات تراکنش</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout style="margin-top: 20px">
                <v-flex
                  style="
                    background-color: #f5f5f5;
                    display: flex;
                    align-item: center;
                    justify-content: center;
                    padding: 14px 27px;
                    margin-left: 8px;
                  "
                >
                  <span class="font_2" style="margin-left: 4px">{{
                    (item.amount - item.wage) | currency
                  }}</span>
                  <span class="font_18" style="line-height: 23px">{{
                    currencyName
                  }}</span>
                </v-flex>
                <v-flex
                  style="
                    background-color: #f5f5f5;
                    display: flex;
                    align-item: center;
                    justify-content: center;
                    padding: 14px 27px;
                  "
                >
                  {{ item.payment_date }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <!-- load more -->
          <observer
            v-if="!endOfLoadMore"
            @intersect="getDataFromApi('loadMore')"
          />

          <div v-if="loadMoreSkeleton && !endOfLoadMore">
            <v-layout
              v-for="index in 10"
              :key="index"
              class="cardRow"
              column
              align-space-between
            >
              <v-flex>
                <v-layout>
                  <v-flex>
                    <v-layout align-center>
                      <v-flex style="max-width: 41px">
                        <v-avatar
                          color="#eee"
                          size="40"
                          class="default_gray"
                          style="border-radius: 50%; margin: 0px"
                        />
                      </v-flex>
                      <v-flex style="margin-right: 20px; max-width: 40%">
                        <div class="van_color02--text" style="font-size: 14px">
                          <span class="default_gray" />
                        </div>
                        <div
                          class="van_color03--text"
                          style="font-size: 10px; max-width: 60%"
                        >
                          <span class="default_gray" />
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex style="max-width: 40px">
                    <span class="default_gray" />
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout style="margin-top: 20px">
                  <v-flex
                    style="
                      background-color: #f5f5f5;
                      display: flex;
                      align-item: center;
                      justify-content: center;
                      padding: 14px 27px;
                      margin-left: 8px;
                    "
                  />
                  <v-flex
                    style="
                      background-color: #f5f5f5;
                      display: flex;
                      align-item: center;
                      justify-content: center;
                      padding: 14px 27px;
                    "
                  />
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>
    </div>

    <!-- modals -->
    <v-dialog
      v-model="status"
      :content-class="'vanmodal'"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <show v-if="status" :item="selectedForm" @closeModal="close_modal" />
    </v-dialog>
  </div>
</template>
<script>
import show from "../../report/modals/show.vue"
import observer from "../../elements/observer.vue"
export default {
  name: "VandarTable",
  components: {
    show,
    observer,
  },
  props: ["update", "dontLet"],
  data() {
    return {
      transfers: [],
      loading: true,
      selectedForm: {},
      //modals status
      status: false,
      add: false,
      view: false,
      //load more
      currentPage: 1,
      endOfLoadMore: false,
      emptySearchResult: false,
      loadMoreSkeleton: false,
    }
  },
  watch: {
    update: function (newValue, oldValue) {
      this.getDataFromApi()
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    convertToRial(value) {
      if (this.$store.state.user.currency == "RIAL") {
        value = value * 10
        return value
      } else return value
    },
    showDetail(item) {
      this.selectedForm = item
      this.status = true
    },
    close_modal(obj) {
      this.add = false
      this.view = false
      this.status = false
      this.selectedForm = {}
    },
    getDataFromApi(kind) {
      if (kind == "loadMore") {
        //load more call this func, keep search?
        this.currentPage = this.currentPage + 1
        this.loadMoreSkeleton = true
      } else {
        //searching
        this.currentPage = 1
        this.loading = true
      }
      var url

      url =
        "/transaction?page=" +
        this.currentPage +
        "&per_page=10&statusKind=settlements&channel=p2p"

      var metaUrl =
        "/transaction/meta?page=" +
        this.currentPage +
        "&per_page=10&statusKind=settlements&channel=p2p"
      this.$http
        .get(this.$store.getters.apiWithVersionParam("3") + url, {
          timeout: 0,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response.data.data !== null) {
            if (kind == "loadMore") {
              var temporaryList = this.transfers

              response.data.data.forEach((element) => {
                temporaryList.push(element)
              })

              this.transfers = temporaryList
            } else {
              this.transfers = response.data.data
            }
            this.$http
              .get(this.$store.getters.apiWithVersionParam("3") + metaUrl, {
                timeout: 0,
                headers: {
                  "Content-Type": "application/json",
                  authorization: "Bearer " + this.$store.state.token,
                  Accept: "application/json",
                },
              })
              .then((response) => {
                if (response.data.data.pages == this.currentPage) {
                  this.endOfLoadMore = true
                }
              })
              .catch((error) => {})

            this.loading = false
            this.loadMoreSkeleton = false
            this.$emit("doneUpdateTable")
          }
        })
        .catch((error) => {
          this.$store.commit("callsnackbar", error)
        })
    },
  },
}
</script>
