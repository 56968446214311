<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">
        <span>تغییر موجودی</span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!valid"
          :loading="loading"
          @click="sendForm()"
        >
          اعمال تغییر
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-card flat>
          <v-layout>
            <v-flex>
              <v-form ref="form" v-model="valid">
                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> نوع تغییر </v-flex>
                  <v-flex class="withlabel big">
                    <v-radio-group v-model="type" row>
                      <v-radio label="افزایش موجودی" value="1" />
                      <v-radio label="کاهش موجودی" value="2" />
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label">
                    مبلغ
                    <span>*</span>
                  </v-flex>
                  <v-flex class="withlabel big">
                    <v-text-field
                      ref="code"
                      v-model="thousandSeprator"
                      :rules="[(v) => !!v || '<span> مبلغ الزامی است </span>']"
                      solo
                      flat
                      height="40"
                      outlined
                      placeholder="مبلغ را وارد کنید"
                      autofocus
                      :suffix="currencyName"
                      @keydown="numberKeyHandler"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> یادداشت </v-flex>
                  <v-flex class="withlabel big">
                    <v-textarea
                      v-model="comment"
                      solo
                      flat
                      outlined
                      rows="3"
                      placeholder="متنی اختیاری که فقط برای شما قابل مشاهده است"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-textarea>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid"

function formatAsCurrency(value, dec) {
  dec = dec || 0
  if (value === null) {
    return null
  }
  return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}
export default {
  name: "ChangeWallet",
  data: function () {
    return {
      loading: false,
      valid: false,
      type: "1",
      amount: null,
      comment: "",
      trackId: null,
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.valid) return true
      else return false
    },
    thousandSeprator: {
      get: function () {
        return formatAsCurrency(this.amount, 0)
      },
      set: function (newValue) {
        if (newValue) {
          this.amount = Number(
            this.convertNumbers2English(newValue).replace(/[^0-9.]/g, "")
          )
        } else {
          this.amount = null
        }
      },
    },
  },
  mounted() {
    this.trackId = uuidv4()
  },
  methods: {
    sendForm() {
      this.loading = true
      var url
      var amount = this.currencyName == "تومان" ? this.amount * 10 : this.amount

      if (this.type == "1") {
        url = "/wallet/deposit"
      } else {
        url = "/wallet/withdraw"
      }
      this.$http
        .post(
          this.$store.getters.apiUrlParam +
            "/customers/" +
            this.$store.state.customer.selectedCustomer.id +
            url,
          {
            amount: amount,
            comment: this.comment,
            track_id: this.trackId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.dispatch("retrieveSelectedCustomerBalance")
          this.$store.dispatch("retrieveSelectedCustomerSummarizeObject")
          this.$store.dispatch("retrieveSelectedCustomerTransactions")

          this.$store.commit("callSuccessSnackbar", response.data.message)

          this.$emit("closeModal", { status: true })
          this.loading = false
        })
        .catch((error) => {
          this.$store.commit("callsnackbar", error)
        })
    },
  },
}
</script>

<style scoped>
.v-radio.theme--light {
  border-radius: 4px !important;
}
.v-input--radio-group:not(.simpleRadio)
  .v-input__control
  .v-input__slot
  .v-radio {
  padding: 8px 12px 10px;
}
</style>
