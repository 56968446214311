<template>
  <v-text-field
    ref="mobile"
    v-model="mobile"
    :placeholder="myplaceholder"
    :rules="mobileRules"
    solo
    flat
    height="40"
    outlined
    validate-on-blur
    autocomplete="on"
    type="tel"
    :disabled="isDisabled"
    @keydown="numberKeyHandler($event)"
    @input="$emit('myinput', $event)"
    @keypress="$emit('mykeypress', $event)"
  >
    <template #message="message">
      <div v-html="message.message"></div>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "MobileNumber",
  props: {
    inputValue: {
      type: String,
      default: "",
    },
    myplaceholder: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mobile: "",
      mobileRules: [
        (v) => !!v || "<span>  وارد کردن موبایل الزامی است </span>",
        (v) =>
          (v && v.length == 11) ||
          "<span> شماره موبایل باید 11 رقم باشد </span>",
      ],
    }
  },
  watch: {
    mobile: function (newValue) {
      if (newValue) {
        this.mobile = this.convertNumbers2English(newValue).replace(
          /[^0-9]/g,
          ""
        )
        this.mobile = this.mobile.slice(0, 11)
      }
      this.$refs.mobile.lazyValue = this.mobile
      this.$emit("setValue", newValue)
    },
    inputValue: function (newValue) {
      this.mobile = newValue
    },
  },
  created() {
    this.mobile = this.inputValue
  },
}
</script>
