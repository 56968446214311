<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="closeModal()">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">تغییر سطح دسترسی</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <div class="font_2 des">
          دسترسی مورد نظر را برای «
          {{ user.name }}
          » انتخاب کنید:
        </div>
        <v-form ref="form" v-model="valid">
          <v-radio-group v-model="role" class="addUserStyle" row>
            <v-radio label="مالک" :value="0" disabled />
            <v-radio label="مدیر" :value="1" />
            <v-radio label="حسابدار" :value="2" />
            <v-radio label="اپراتور" :value="4" />
            <v-radio label="ناظر" :value="5" />
            <v-radio label="برنامه نویس" :value="3" />
          </v-radio-group>
        </v-form>
      </v-flex>

      <!-- info component -->
      <roleInfo />
    </v-layout>
  </v-card>
</template>

<script>
import roleInfo from "../elements/roleInfo.vue"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "ChangeRole",
  components: {
    roleInfo,
  },
  props: ["user"],
  data() {
    return {
      role: "",
      loading: false,
      valid: false,
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.valid) return true
      else return false
    },
  },
  mounted() {
    this.role = this.user.role_id
  },
  methods: {
    closeModal() {
      this.$store.commit("callSecondAddUserModal", false)
      this.$emit("closeModal")
    },
    sendForm() {
      this.loading = true

      const queryParam = this.user.id
      const payload = {
        role_id: this.role,
      }
      servicesRepository(this)
        .roleUser.httpUpdateRoleUser(queryParam, payload)
        .then((response) => {
          const {
            data: { data },
          } = response
          this.$emit("change_Role", this.user, this.role)
          this.$store.commit("callSuccessSnackbar", data.message)
          this.loading = false
          this.closeModal()
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-divider {
  margin: 25px 0;
  border-color: $van_color10;
}
.des {
  margin-bottom: 20px;
}
</style>
