const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpGetSenfs() {
    return context.$http.get(
      `${context.$store.state.api_base_url}/information/mccs`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetCities() {
    return context.$http.get(
      `${context.$store.state.api_base_url}/information/cities`,
      {
        headers: apiHeaders(context),
      }
    )
  },
})
