<template>
  <general-card custom-card-style="padding: 16px !important;">
    <div class="d-flex align-center">
      <div class="font-size-11 w-100">
        <div
          class="font-weight-bold"
          :class="{
            'd-flex align-center justify-space-between': !reuseIsMobile,
          }"
        >
          <v-skeleton-loader :width="reuseIsMobile ? 220 : 250" type="text" />
          <v-skeleton-loader v-if="!reuseIsMobile" width="50" type="text" />
        </div>
        <div class="van_color05--text my-2">
          <v-skeleton-loader :width="reuseIsMobile ? 250 : 300" type="text" />
        </div>
        <v-divider class="van_color11 mb-2" />
        <div
          class="van_color05--text d-flex"
          :class="reuseIsMobile ? 'flex-column' : 'justify-space-between'"
        >
          <v-skeleton-loader width="150" type="text" />
          <v-skeleton-loader width="100" type="text" />
        </div>
      </div>
    </div>
  </general-card>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import screenSize from "@/mixins/screenSize"

export default {
  name: "RejectedSuspiciousItemSkeleton",
  components: { GeneralCard },
  mixins: [screenSize],
}
</script>

<style scoped></style>
