<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> دستگاه‌های امن </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex>
        <template v-if="!devices || !devices.length">
          <div class="font_2 mb-0">
            در حال حاضر دستگاهی به عنوان دستگاه امن معرفی نکرده‌اید.
          </div>
        </template>
        <template v-else>
          <div class="font_2">
            در این بخش می‌توانید، دستگاه‌های امن خود را مشاهده یا حذف کنید.
          </div>
          <div v-if="devices?.length" class="d-flex row justify-space-between">
            <div v-for="device in devices" :key="device.id" class="d-flex mt-3">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="py-3 device-card"
                  :class="{ current: device.is_current }"
                >
                  <v-icon v-if="device.is_current" class="current-icon" size="8"
                    >mdi_circle_small</v-icon
                  >
                  <div
                    v-else-if="hover"
                    class="device-delete"
                    @click="removeDevice(device.id)"
                  >
                    <v-icon size="16">mdi_delete_outline</v-icon>
                  </div>
                  <div class="column justify-center align-center">
                    <div class="d-flex device-img mx-auto">
                      <img
                        class="ma-auto"
                        :src="
                          require('@/assets/img/trustedDevice/' +
                            device.device_type +
                            '.svg')
                        "
                      />
                    </div>
                    <div class="d-flex justify-center font_24 mt-1">
                      <span
                        v-if="
                          device.device_os !== '0' &&
                          device.device_browser !== '0'
                        "
                        >{{ device.device_os }} -
                        {{ device.device_browser }}</span
                      >
                      <span v-else>unknown</span>
                    </div>
                    <div class="d-flex justify-center font-size-11">
                      <span v-if="device.is_current">دستگاه فعال</span>
                      <span v-else>{{
                        device.last_used_at_in_human_readable
                      }}</span>
                    </div>
                  </div>
                </v-card>
              </v-hover>
            </div>
          </div>
        </template>
      </v-flex>
    </v-layout>
    <general-dialog
      v-if="showDialog"
      :active="showDialog"
      content-class="confirmationMessageDialog"
      logo="newIcon/error_close.svg"
      title="از حذف این دستگاه اطمینان دارید؟"
      description="با حذف کردن این دستگاه امن، برای ورود از طریق این دستگاه، لازم است با رمز عبور وارد داشبورد شوید."
      btn1-text="بله، مطمئنم"
      btn1-class="btn_small_normal error"
      btn2-text="انصراف"
      @btn1Clicked="sendRevoke()"
      @btn2Clicked="cancel()"
    />
  </v-card>
</template>

<script>
import GeneralDialog from "./generalDialog"

export default {
  name: "TrustedDevices",
  components: {
    GeneralDialog,
  },
  data: function () {
    return {
      devices: [],
      deviceIdForRevoke: null,
      showDialog: false,
      loading: false,
    }
  },
  mounted() {
    this.getTrustedDevices()
  },
  methods: {
    getTrustedDevices() {
      this.$http
        .get(this.$store.state.api_base_url + `/personal/trusted-devices`)
        .then((resp) => {
          this.devices = resp.data.data
        })
    },
    removeDevice(deviceId) {
      this.deviceIdForRevoke = deviceId
      this.showDialog = true
    },
    sendRevoke() {
      this.loading = true
      this.$http
        .delete(
          this.$store.state.api_base_url +
            `/personal/trusted-devices/${this.deviceIdForRevoke}`
        )
        .then(() => {
          this.getTrustedDevices()
          this.loading = false
          this.showDialog = false
        })
        .catch(() => {
          this.loading = false
          this.showDialog = false
        })
    },
    cancel() {
      this.showDialog = false
      this.deviceIdForRevoke = null
    },
  },
}
</script>
<style lang="scss" scoped>
.device-card {
  border: 1px solid #d5d5d5 !important;
  border-radius: 4px;
  width: 235px;
  height: 112px;
  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  }
}
.current {
  background-color: rgba(76, 159, 135, 0.1) !important;
  border: 1px solid rgba(76, 159, 135, 0.5) !important;
  &-icon {
    position: absolute !important;
    justify-content: center !important;
    width: 8px;
    top: 5px;
    color: $van_green !important;
  }
}
.device-delete {
  position: absolute;
  left: 5px;
  top: 5px;
  & i {
    width: 16px;
    cursor: pointer;
  }
}
.device-img {
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
