<template>
  <v-card
    outlined
    class="business-card"
    color="white"
    max-width="280px"
    :ripple="false"
    flat
    :hover="account.status == -4 ? false : true"
    :to="
      account.status == -4
        ? ''
        : account.entity == 'personal'
        ? '/personal'
        : account.role != 'developer'
        ? '/business/' + account.business_name
        : '/business/' + account.business_name + '/ipg'
    "
  >
    <!-- avatar -->
    <v-avatar
      v-if="account.status !== -4 && account.entity == 'personal'"
      size="80"
      style="border: 1px solid #eee; border-radius: 50%"
    >
      <v-img
        v-if="account.avatar"
        :src="$store.state.storage_base_url + account.avatar"
        alt="avatar"
      />
      <v-img v-else :src="require('@/assets/img/default.png')" alt="avatar" />
    </v-avatar>

    <div
      v-if="account.status !== -4 && account.entity == 'business'"
      style="position: relative"
    >
      <v-avatar size="80" style="border: 1px solid #eee; border-radius: 50%">
        <v-img
          v-if="account.avatar"
          :src="$store.state.storage_base_url + account.avatar"
          alt="avatar"
        />
        <v-img
          v-else
          :src="require('@/assets/img/businessDefault.png')"
          alt="avatar"
        />
      </v-avatar>
    </div>

    <!-- new business -->
    <div v-if="account.status == -4" class="new-business v-avatar">
      <div class="new-business__avatar">
        <img
          v-if="account.avatar"
          :src="account.avatar"
          :alt="account.business_name_fa"
        />
      </div>
    </div>
    <h2 v-if="account.business_name_fa" class="mb-1">
      {{ account.business_name_fa }}
    </h2>
    <div v-else style="min-height: 28px" />

    <!-- wallet -->
    <wallet
      v-if="account.status != -4"
      with-label
      :load="account.hasOwnProperty('wallet')"
      :status="account.status == 1 ? 1 : 0"
      :amount="account.wallet"
      :blocked_amount="0"
      :access="account.role == 'developer' ? false : true"
      center
      style="margin-bottom: 6px; height: 32px"
    />
    <div v-else class="income">
      {{ account.wallet }}
    </div>

    <!-- two box -->
    <div v-if="!moreDataLoad && account.status !== -4" class="more-data">
      <div class="bus skeletonShadowLoading" />
    </div>
    <div
      v-else-if="account.status == 1 && account.role !== 'developer'"
      class="more-data"
    >
      <div class="bus bus-right">
        <div dir="ltr" class="mablagh">
          {{ today_settlements | currency }}
        </div>
        <div class="sub-text">{{ currencyName }} واریزی امروز</div>
      </div>
      <div class="bus bus-left">
        <div class="mablagh">
          {{ today_transactions }}
        </div>
        <div class="sub-text">تراکنش امروز</div>
      </div>
    </div>
    <div v-else class="more-data">
      <div
        v-if="
          account.status !== -4 &&
          account.role !== 'developer' &&
          account.status !== -3
        "
        class="bus pending"
      >
        <div v-if="account.status == -1" class="sub-text">
          حساب شما پس از بررسی رد شده است
        </div>
        <div
          v-else-if="account.status == 0 || account.status == -2"
          class="sub-text"
        >
          نیاز به اقداماتی جهت فعال‌سازی این حساب است
          <span class="istyle1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
            >
              <path
                id="Warning"
                d="M9.876,16.877a2.191,2.191,0,0,1-1.267-.671L3.548,11.142a2.191,2.191,0,0,1-.671-1.267,2.191,2.191,0,0,1,.671-1.266L8.609,3.548a2.191,2.191,0,0,1,1.267-.671,2.191,2.191,0,0,1,1.266.671l5.063,5.061a2.188,2.188,0,0,1,.67,1.266,2.188,2.188,0,0,1-.67,1.267l-5.063,5.063A2.191,2.191,0,0,1,9.876,16.877ZM9,9v5h2V9ZM9,6V8h2V6Z"
                transform="translate(-2.876 -2.876)"
                fill="#f9d358"
              />
            </svg>
          </span>
        </div>
      </div>
      <div
        v-else-if="
          account.status !== -4 &&
          (account.role == 'developer' || account.status == -3)
        "
        class="bus"
      >
        <div v-if="account.role == 'developer'" class="sub-text">
          شما برنامه‌نویس این کسب‌و‌کار هستید
        </div>
      </div>
      <div v-else class="bus flat">
        <div v-if="account.status == -4" color="van_pink">
          <v-btn
            text
            depressed
            outlined
            class="btn_small_normal mt-6"
            @click="goToCreatingNewBusinessPage"
          >
            {{ account.right_box.text }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import Wallet from "../../elements/wallet.vue"
export default {
  name: "AccountCard",
  components: {
    Wallet,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
      role: "",
      entity: {
        validator: function (value) {
          // The value must match one of these strings
          return ["personal", "business"].indexOf(value) !== -1
        },
      },
      avatar: String,
      status: Number,
      //-2: 'نیاز به اقداماتی جهت فعالسازی حساب '
      //-3: 'در انتظار پرداخت هزینه کسب‌و‌کار'
      //-4:addBusiness
      business_name: {
        type: String,
        //only if entity is 'business' in english
      },
      business_name_fa: {
        type: String,
      },
      wallet: {
        type: Number,
        default: 0,
      },
      today_transactions: null,
      today_settlements: null,
      right_box: {
        type: Object,
        // Object or array defaults must be returned from
        // a factory function
        default: function () {
          return {
            value: "0",
            text: "",
          }
        },
      },
      left_box: {
        type: Object,
        // Object or array defaults must be returned from
        // a factory function
        default: function () {
          return {
            value: "0",
            text: "تراکنش امروز",
          }
        },
      },
    },
  },
  data() {
    return {
      hover: false,
      moreDataLoad: false,
      today_transactions: 0,
      today_settlements: 0,
    }
  },
  mounted() {
    this.onMounTCall()
  },
  methods: {
    onMounTCall() {
      if (this.account.entity == "personal") {
        this.account.business_name_fa = this.$store.state.user.name
        if (this.account.status !== 1) {
          this.account.status =
            this.$store.state.user.statusBox.email == "add" ||
            this.$store.state.user.statusBox.mobile == "add" ||
            this.$store.state.user.statusBox.personal_information == "add"
              ? -2
              : this.$store.state.user.status
        }
        this.moreDataLoad = true
      } else {
        this.callMoreData()
      }
    },
    callMoreData() {
      if (this.account.business_name)
        this.$http
          .get(
            this.$store.state.api_base_url_withoutV +
              "/v3/business/" +
              this.account.business_name,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            response = response.data.data
            this.today_settlements = response.today_settlements
            this.today_transactions = response.today_transactions

            //set entity attribute + -2 and -3 status
            if (this.account.entity == "business" && response.status == 0) {
              response.status =
                response.statusBox.introduction_letter == "add" ||
                response.statusBox.national_card_photo == "add" ||
                response.statusBox.official_Newspaper == "add"
                  ? -2
                  : response.status
            }
            this.moreDataLoad = true
          })
          .catch((error) => {
            //callsnackbar
          })
    },
    goToCreatingNewBusinessPage() {
      if (this.account.status === -4) {
        this.$router.push("/" + this.account.entity)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}
.business-card {
  height: 250px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0 0px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  user-select: none;
  cursor: default;
  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
  border: 0px;
  &.v-card--hover {
    position: absolute;
    bottom: 0%;
    width: 100%;
  }
}
.new-business {
  min-height: 110px !important;
  width: 110px !important;
  background-color: rgba(76, 159, 135, 0.2);
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
  &__avatar {
    min-width: 70px;
    background-color: rgba(76, 159, 135);
    height: 70px;
    border-radius: 50%;
    img {
      width: 24px;
    }
  }
}
.v-avatar {
  margin: 0 0 10px 0;
  flex: 1 0 auto;
}
h2 {
  margin: 0 0 7px 0;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 25px;
  max-width: 85%;
}
.income {
  margin: 0 0 15px 0;
  font-size: 10px;
  color: #999;
}
.bus-right {
  margin-left: 10px;
}
.bus {
  &.pending {
    background-color: rgba(249, 211, 88, 0.2);
    position: relative;
    .sub-text {
      color: $van_color02;
    }
    .istyle1 {
      position: absolute;
      right: -3px;
    }
  }
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 60px;
  width: 100%;
  &.bus-right,
  &.bus-left {
    width: 50%;
  }
  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}
.business-card .more-data {
  display: flex;
  width: calc(100% - 40px);
  margin: 0 20px 20px 20px;
  flex: 1 0 auto;
}
.mablagh {
  font-size: 14px;
  font-weight: bold;
}
.sub-text {
  font-size: 9px;
  color: #999;
}
.v-card--hover:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1) !important;
  height: 250px;
  transition: all ease 0.5s;
  z-index: 5;
}
.layout.row.botton {
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  &:last-child .v-btn {
    margin: 0;
    border-left: 1px solid #afafaf;
  }
  .v-btn {
    width: 100%;
    font-size: 10px;
    color: #ffffffcc;
    background-color: #666 !important;
    border-radius: 0px;
    text-decoration: none;
    &:active,
    &:focus,
    &:hover {
      &:before {
        display: none;
      }
      background-color: $van_green !important;
      color: #fff;
    }
  }
}
</style>
