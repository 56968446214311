<template functional>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.85825 11.1533H16.2833V12.9133H8.85825V11.1533ZM16.2833 7.63334H6.38325V9.39334H16.2833V7.63334ZM19.5833 4.99334V20.8333L16.2833 17.3133H4.73325C4.29564 17.3133 3.87596 17.1279 3.56653 16.7978C3.25709 16.4678 3.08325 16.0201 3.08325 15.5533V4.99334C3.08325 4.02534 3.82575 3.23334 4.73325 3.23334H17.9333C18.3709 3.23334 18.7905 3.41877 19.1 3.74883C19.4094 4.07889 19.5833 4.52656 19.5833 4.99334ZM17.9333 4.99334H4.73325V15.5533H16.968L17.9333 16.5829V4.99334Z"
      fill="#666666"
    />
  </svg>
</template>

<script>
export default {
  name: "TextMessageSvg",
}
</script>

<style lang="scss" scoped></style>
