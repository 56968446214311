var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activationBlock"},[_c('span',{staticClass:"topBack pendingTopBack"}),_c('v-layout',{staticClass:"mx-auto twoPending",staticStyle:{"margin":"-20px"},attrs:{"column":""}},[_c('v-flex',{staticClass:"personal_title alldone"},[_c('v-layout',{attrs:{"justify-space-between":"","align-center":""}},[_c('v-flex',[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{staticStyle:{"max-width":"60px"}},[_c('v-img',{attrs:{"src":require('@/assets/img/newIcon/refundPending.svg'),"alt":"avatar","width":"40"}})],1),_c('v-flex',[(
                  _vm.$store.state.business.tools.refund.time != 'call operation'
                )?_c('div',{staticClass:"font_5"},[_vm._v(" بررسی فعال‌سازی بازگشت وجه ")]):_c('div',{staticClass:"font_5",staticStyle:{"line-height":"22px"}},[_vm._v(" بررسی درخواست فعال‌سازی «بازگشت وجه» شما بیش از زمان تعیین شده طول کشیده است. با عرض پوزش، به سرعت در حال پیگیری هستیم ")])])],1)],1),(_vm.$store.state.business.tools.refund.time != 'call operation')?_c('v-flex',[(
              _vm.$store.state.user.status != 1 &&
              _vm.$store.state.business.role == 'owner'
            )?_c('div',{staticClass:"font_7",staticStyle:{"text-align":"left"}},[_vm._v(" در انتظار تایید حساب شخصی ")]):(_vm.$store.state.business.status != 1)?_c('div',{staticClass:"font_7",staticStyle:{"text-align":"left"}},[_vm._v(" در انتظار تایید کسب‌و‌کار ")]):(
              _vm.$store.state.business.tools.refund.time &&
              _vm.$store.state.business.tools.refund.time.includes(':') &&
              _vm.time != 0
            )?_c('div',{staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"font_18"},[_vm._v(" حداکثر زمان انتظار: ")]),_c('span',{staticClass:"font_12",staticStyle:{"font-size":"20px !important","color":"#333 !important","display":"inline-block","width":"90px"}},[_vm._v(" "+_vm._s(_vm.convertToTime)+" ")])]):_c('div',{staticClass:"font_7",staticStyle:{"text-align":"left"}},[_vm._v(" در انتظار تایید وندار ")])]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }