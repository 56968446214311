<script>
import * as echarts from "echarts/core"
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
} from "echarts/components"
import { BarChart } from "echarts/charts"
import { CanvasRenderer } from "echarts/renderers"

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  BarChart,
  CanvasRenderer,
])

export default {
  name: "BarEChart",
  props: {
    xAxisData: {
      type: Array,
      default: () => [],
    },
    yAxisData: {
      type: Array,
      default: () => [],
    },
    secondYAxisData: {
      type: Array,
      default: () => [],
    },
    firstBarColor: {
      type: String,
      default: "",
    },
    secondBarColor: {
      type: String,
      default: "",
    },
    hoverTitle: {
      type: Array,
      default: () => [],
    },
    firstValueHoverSubtitle: {
      type: String,
      default: "",
    },
    secondValueHoverSubtitle: {
      type: String,
      default: "",
    },
    wholeValueHoverSubtitle: {
      type: String,
      default: "",
    },
    yAxisLabel: {
      type: String,
      default: "",
    },
    chartName: {
      type: String,
      default: "",
    },
    secondChartName: {
      type: String,
      default: "",
    },
    chartId: {
      type: String,
      default: "default",
    },
  },
  mounted() {
    var chartDom = document.getElementById(this.chartId)
    var myChart = echarts.init(chartDom)
    var option
    let self = this

    option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
        },
        formatter(params) {
          const tooltipTemplate = `
          <div style='display:flex; flex-direction:column; flex-wrap: wrap; justify-content: center; min-width:200px;'>
            <span style='background:#F5F5F5; padding:6px 8px; text-align: center; margin-bottom:12px; font-family:IRANSans'>
              ${self.hoverTitle[params[0].dataIndex]}
            </span>
            ${self.createWholeValueSubtitle(params)}
            ${self.createTooltipSubtitle(params)}
          </div>
          `
          return tooltipTemplate
        },
        textStyle: {
          fontFamily: "IRANSans",
          fontSize: 12,
          color: "#333333",
        },
      },
      legend: {
        show: false,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: this.xAxisData,
          axisLabel: {
            align: "center",
            color: "#666666",
            fontFamily: "IRANSans",
            fontSize: 12,
            padding: [10, 0, 0, 20],
          },
          axisLine: {
            lineStyle: {
              color: "#666666",
              fontFamily: "IRANSans",
              fontSize: 12,
              type: "dashed",
            },
          },
        },
      ],
      yAxis: {
        type: "value",
        name: this.yAxisLabel,
        nameLocation: "middle",
        nameTextStyle: {
          color: "#666666",
          fontFamily: "IRANSans",
          fontSize: 12,
          align: "middle",
          padding: [0, 0, 20, 0],
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#E5E5E5",
          },
        },
        axisLabel: {
          color: "#666666",
          fontFamily: "IRANSans",
          fontSize: 12,
          padding: 0,
        },
      },
      series: [
        {
          name: this.firstValueHoverSubtitle,
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: this.yAxisData,
          color: this.firstBarColor,
        },
        {
          name: this.secondValueHoverSubtitle,
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: this.secondYAxisData,
          color: this.secondBarColor,
        },
      ],
    }

    option && myChart.setOption(option)
  },
  methods: {
    createTooltipSubtitle(params) {
      let tooltipSubtitle = ""
      for (let i = 0; i < params.length; i++) {
        tooltipSubtitle += `<div style="display:flex; justify-content: space-between; align-items:center; margin: 2px 0 6px 2px; font-family:IRANSans">
          <div style="display: flex; align-items:center;">
                    ${params[i].marker}
                    <span style="margin-right: 4px"> ${
                      params[i].seriesName
                    }</span>
                  </div>
                  <span style='direction: rtl'>
                    ${params[i]?.value?.toLocaleString()}
                  </span>
              </div>`
      }

      return tooltipSubtitle
    },
    createWholeValueSubtitle(params) {
      if (this.wholeValueHoverSubtitle) {
        return `<div style="display:flex; justify-content: space-between; align-items:center; margin: 2px 0 6px 2px; font-family:IRANSans">
                  <div style="display: flex; align-items:center;">
                    <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color: #007527;"></span>
                    <span style="margin-right: 4px"> ${
                      this.wholeValueHoverSubtitle
                    }</span>
                  </div>
                  <span style='direction: rtl'>
                    ${(params[0].data + params[1].data).toLocaleString()}
                  </span>
              </div>`
      }
      return ""
    },
  },
  render(createElement) {
    return createElement("div", {
      attrs: { id: this.chartId, style: "width: 100%; height: 400px" },
    })
  },
}
</script>

<style lang="scss" scoped></style>
