<template>
  <div>
    <div class="mpg-box">
      <h1 class="mb-0">پرداخت یکپارچه</h1>
      <list-of-cards />
    </div>

    <bank-list />
  </div>
</template>

<script>
import listOfCards from "@/components/mpg/elements/ListOfCards.vue"

import BankList from "@/components/mpg/elements/BankList.vue"
export default {
  name: "MPGPage",
  components: { BankList, listOfCards },
}
</script>

<style lang="scss" scoped>
.mpg-box {
  margin-bottom: 60px;
  background-color: #eee;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 920px) {
    position: relative;
    width: 100vw;
    left: calc((100vw - 890px) / 2);
    padding: 0 calc(((100vw - 890px) / 2));
  }
  @media screen and (max-width: 919px) {
    position: relative;
    width: 100vw;
    left: 15px;
    padding: 0 15px;
  }
}
</style>
