<template>
  <v-card class="singleBox detailSettlement">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal', { status: false })">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">توضیحات بیشتر</v-toolbar-title>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-layout column class="rows">
          <v-flex class="font_2 mb-0">
            <div class="bold mb-3">فرآیندهای برداشت از کیف‌پول</div>
            <p class="text-justify font_2">
              موجودی کیف‌پول یک کسب‌وکار در هر زمان مجموع تمام پول‌های واریزی به
              آن کیف‌پول منهای مبلغ تمام برداشت‌ها تا آن لحظه‌ است. اما تمامی
              این موجودی در لحظه آماده‌ی واریز به حساب بانکی نیست.
            </p>
            <p class="text-justify font_2">
              برای نمونه، تراکنش‌هایی که در درگاه پرداخت یک کسب‌وکار انجام
              می‌شوند، بلافاصله مبلغ کیف‌پول آن کسب‌وکار را افزایش می‌دهند، اما
              شاپرک مبلغ این تراکنش‌ها را تا روز کاری بعد به حساب بانکی شرکت‌های
              پرداخت الکترونیک واریز نمی‌کند. بنابراین مبلغ این تراکنش‌ها روز
              کاری بعد به مبلغ قابل برداشت کیف‌پول اضافه می‌شوند.
            </p>
            <v-layout class="align-center">
              <v-flex class="ml-2" style="max-width: fit-content">
                <img
                  :src="require('@/assets/img/gingool.svg')"
                  max-width="34"
                  height="23"
                  class="mt-1"
                />
              </v-flex>
              <v-flex class="bold">
                برای روشن‌تر شدن این مطلب لازم است مفاهیم زیر را در ذهن داشته
                باشیم:
              </v-flex>
            </v-layout>
            <v-layout column class="mt-5">
              <v-flex class="bold mb-2"> برداشت </v-flex>
              <v-flex>
                دستور کم کردن پول از کیف‌پول یک کسب‌وکار و ارسال این مبلغ به
                بانک.
              </v-flex>
            </v-layout>
            <v-divider
              class="dashed my-5"
              style="font-size: 2px; border-style: dashed"
            />
            <v-layout column>
              <v-flex class="bold mb-2"> چرخه تسویه بانکی </v-flex>
              <v-flex class="text-justify font_2 mb-0">
                هر نیم‌ساعت یک‌بار (۳۹بار در روز از ۴ بامداد تا ۱۱شب) یک چرخه
                تسویه بانکی اجرا شده و دستورهای برداشت به بانک ارسال می‌شوند. در
                این زمان یک کد رهگیری از سوی بانک به دستور برداشت اختصاص داده
                می‌شود.
              </v-flex>
            </v-layout>
            <v-divider
              class="dashed my-5"
              style="font-size: 2px; border-style: dashed"
            />
            <v-layout column>
              <v-flex class="bold mb-2"> واریز به حساب مقصد </v-flex>
              <v-flex class="text-justify font_2 mb-0">
                اگر حساب مقصد نزد بانک آینده ساخته‌شده باشد، بلافاصله پس از
                چرخه‌ تسویه بانکی، مبلغ به حساب مقصد واریز می‌شود. اما اگر حساب
                مقصد نزد بانک‌های دیگر باشد، مبلغ در نخستین چرخه‌ی پایای پیش‌رو
                به حساب مقصد واریز می‌شود.
              </v-flex>
            </v-layout>
            <v-divider
              class="dashed my-5"
              style="font-size: 2px; border-style: dashed"
            />
            <v-layout column>
              <v-flex class="bold mb-2"> چرخه‌ تسویه پایا </v-flex>
              <v-flex class="text-justify font_2 mb-0">
                با تصمیم بانک مرکزی، انتقال‌های بین‌بانکی در ساعت‌های مشخصی از
                روزهای کاری انجام می‌شوند. در حال حاضر هر روز ۴ چرخه‌ تسویه پایا
                در ساعت‌های ۴:۰۰بامداد، ۱۱:۰۰ صبح، ۲:۰۰ظهر و ۷:۰۰ بعدازظهر انجام
                می‌شوند.
              </v-flex>
            </v-layout>
            <v-divider
              class="dashed my-5"
              style="font-size: 2px; border-style: dashed"
            />
            <v-layout column>
              <v-flex class="bold mb-2"> تراکنش‌های یک روز درگاه </v-flex>
              <v-flex class="text-justify font_2 mb-0">
                کلیه تراکنش‌هایی که بین ساعت ۲۲:۴۵ روز قبل تا ۲۲:۴۵ روز حاضر
                انجام شده باشند، تراکنش‌های امروز درگاه نامیده می‌شوند.
              </v-flex>
            </v-layout>
            <v-divider
              class="dashed my-5"
              style="font-size: 2px; border-style: dashed"
            />
            <v-layout column>
              <v-flex class="bold mb-2"> ساعت تسویه پرداخت‌یاری </v-flex>
              <v-flex class="text-justify font_2 mb-0">
                در حال حاضر شاپرک مبلغ کل تراکنش‌های یک روز هر درگاه پرداخت‌یاری
                را ساعت ۱۴:۰۰ روز کاری بعد به حساب شرکت پرداخت الکترونیک واریز
                می‌کند.
              </v-flex>
            </v-layout>
            <v-divider
              class="dashed my-5"
              style="font-size: 2px; border-style: dashed"
            />
            <v-layout column>
              <v-flex class="bold mb-2"> مبلغ قابل برداشت </v-flex>
              <v-flex class="text-justify font_2 mb-0">
                در هر زمان مجموع مبلغی در کیف‌پول یک کسب‌وکار که بلافاصله
                آماده‌ی ارسال به بانک باشد، مبلغ قابل برداشت آن کیف‌پول را تشکیل
                می‌دهد.
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "DetailWalletMoreDescription",
  data: function () {
    return {}
  },
}
</script>
