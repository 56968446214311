<template>
  <v-card class="singleBox addsettlement">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal', { status: false })">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">درخواست انتقال</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="checkForm()"
        >
          انتقال وجه
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout>
      <v-flex>
        <v-form ref="form" v-model="valid">
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              انتقال وجه به
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel big vandarIdCombobox">
              <v-combobox
                v-model="shenaseSelect"
                :items="shenaseList"
                item-text="value"
                item-value="key"
                class="p2p_search"
                solo
                flat
                height="40"
                no-filter
                outlined
                deletable-chips
                :hide-no-data="hideNoData"
                :search-input.sync="searchValue"
                label="جستجوی شناسه"
                no-data-text="حسابی با این نام یافت نشد"
                :menu-props="{ contentClass: 'chooseVandarID' }"
                :loading="apiCallLoading"
              >
                <template slot="selection" slot-scope="data">
                  <v-layout align-center justify-space-between>
                    <v-flex>
                      <div
                        style="
                          max-width: 290px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ data.item[1] }} | {{ data.item[2] }}@
                      </div>
                    </v-flex>
                    <v-flex
                      style="
                        max-width: 48px;
                        position: absolute;
                        left: -15px;
                        z-index: 100;
                      "
                    >
                      <v-btn icon @click.native="shenaseSelect = null">
                        <v-icon color="van_color03" size="21"> close </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </template>
                <template slot="item" slot-scope="data">
                  <v-flex v-if="data.item != []">
                    <v-layout align-center>
                      <v-flex style="max-width: 41px">
                        <v-avatar
                          v-if="data.item[0] != null"
                          size="32"
                          style="border: 1px solid #eee; border-radius: 50%"
                        >
                          <v-img
                            :src="$store.state.storage_base_url + data.item[0]"
                            alt="avatar"
                          />
                        </v-avatar>
                        <v-avatar v-else color="#eee" size="32">
                          <v-icon size="10"> person </v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex>
                        <div class="van_color02--text" style="font-size: 10px">
                          {{ data.item[1] }}
                        </div>
                        <div class="van_color04--text" style="font-size: 10px">
                          {{ data.item[2] }}@
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
                <template #no-data>
                  <v-layout wrap justify-center align-center>
                    <v-flex
                      class="van_color04--text"
                      style="
                        text-align: center;
                        padding: 20px 10px;
                        cursor: default;
                        font-size: 10px;
                      "
                    >
                      حسابی با این نام یافت نشد
                    </v-flex>
                  </v-layout>
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
          <!-- <transition name="fade"> -->
          <div v-if="isIdSelected" class="inputWrapper">
            <v-layout
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
            >
              <v-flex class="label">
                مبلغ
                <span>*</span>
              </v-flex>
              <v-flex class="withlabel big">
                <v-text-field
                  ref="code"
                  v-model="thousandSeprator"
                  :rules="mablaghRules"
                  solo
                  flat
                  height="40"
                  outlined
                  :suffix="currencyName"
                  placeholder="مبلغ را وارد کنید"
                  autofocus
                  @keydown="numberKeyHandler"
                >
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>

            <div>
              <transition name="fade1">
                <div v-if="mablagh != null" class="font_18 iconAndMablagh">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g transform="translate(-404 -330)">
                      <rect
                        style="fill: #fff"
                        width="16"
                        height="16"
                        transform="translate(404 330)"
                      />
                      <path
                        style="fill: #666"
                        d="M4,7H20V17H4V7m8,3c1.657,0,2,.343,2,2s-.343,2-2,2-2-.343-2-2,.343-2,2-2M8,9a2,2,0,0,1-2,2v2a2,2,0,0,1,2,2h8a2,2,0,0,1,2-2V11a2,2,0,0,1-2-2Z"
                        transform="translate(400 326)"
                      />
                    </g>
                  </svg>
                  <span>
                    {{ amountInWord }}
                    {{ currencyName }}
                  </span>
                </div>
              </transition>
              <transition name="fade1">
                <div
                  v-if="mablagh != null && mablagh >= minimumAmount"
                  class="font_18 iconAndMablagh"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g transform="translate(-404 -330)">
                      <rect
                        style="fill: none"
                        width="16"
                        height="16"
                        transform="translate(404 330)"
                      />
                      <path
                        style="fill: #666"
                        d="M-1664.947-518.21a1.681,1.681,0,0,1-1.373-.707l-3.68-5.188,3.68-5.18a1.688,1.688,0,0,1,1.373-.716h9.263a1.684,1.684,0,0,1,1.684,1.684v8.421a1.69,1.69,0,0,1-1.684,1.685Zm-3.193-5.9,2.99,4.414h9.671v-8.82h-9.671Zm4.712,1.884,4.576-4.576.807.808-4.575,4.575Zm3.452-.168a.958.958,0,0,1,.955-.955.959.959,0,0,1,.956.955.96.96,0,0,1-.956.956A.959.959,0,0,1-1659.976-522.39Zm-3.432-3.432a.959.959,0,0,1,.956-.955.958.958,0,0,1,.955.955.959.959,0,0,1-.955.956A.959.959,0,0,1-1663.408-525.821Z"
                        transform="translate(2074 862.151)"
                      />
                    </g>
                  </svg>
                  <span v-if="wage != null">
                    {{ wage | currency }} {{ currencyName }}
                    کارمزد
                  </span>
                  <span
                    v-else
                    class="default_gray"
                    style="width: 25%; margin: 4px 10px 0 0"
                  />
                </div>
              </transition>
              <transition name="fade1">
                <div
                  v-if="
                    mablagh != null &&
                    mablagh >= minimumAmount &&
                    timePrediction != null &&
                    timePrediction['instant'] &&
                    timePrediction['instant']['amount'] != null &&
                    timePrediction['instant']['amount'] > 0
                  "
                  class="font_18 iconAndMablagh"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g transform="translate(-404 -356)">
                      <rect
                        style="fill: #fff"
                        width="16"
                        height="16"
                        transform="translate(404 356)"
                      />
                      <path
                        class="b"
                        d="M9,14.6A5.6,5.6,0,1,0,3.4,9,5.6,5.6,0,0,0,9,14.6M9,2A7,7,0,1,1,2,9,7,7,0,0,1,9,2m.35,3.5V9.175l3.15,1.869-.525.861L8.3,9.7V5.5Z"
                        transform="translate(403 355)"
                      />
                    </g>
                  </svg>
                  <span
                    v-if="
                      timePrediction != null &&
                      timePrediction['instant']['amount'] != null
                    "
                  >
                    قابل برداشت در لحظه<span
                      v-if="timePrediction['instant']['amount'] > 0"
                      style="margin-right: 0"
                      >: {{ timePrediction["instant"]["amount"] | currency }}
                      {{ currencyName }}
                    </span>
                  </span>
                  <span
                    v-else
                    class="default_gray"
                    style="width: 50%; margin: 4px 10px 0 0"
                  />
                </div>
              </transition>
              <transition name="fade1">
                <div
                  v-if="
                    mablagh != null &&
                    mablagh >= minimumAmount &&
                    timePrediction &&
                    timePrediction['second_part'] &&
                    timePrediction['second_part']['amount'] > 0
                  "
                  class="font_18 iconAndMablagh"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g transform="translate(-404 -356)">
                      <rect
                        style="fill: #fff"
                        width="16"
                        height="16"
                        transform="translate(404 356)"
                      />
                      <path
                        class="b"
                        d="M9,14.6A5.6,5.6,0,1,0,3.4,9,5.6,5.6,0,0,0,9,14.6M9,2A7,7,0,1,1,2,9,7,7,0,0,1,9,2m.35,3.5V9.175l3.15,1.869-.525.861L8.3,9.7V5.5Z"
                        transform="translate(403 355)"
                      />
                    </g>
                  </svg>
                  <span
                    v-if="
                      timePrediction != null &&
                      timePrediction['second_part']['amount'] > 0
                    "
                  >
                    قابل برداشت در
                    {{
                      convertTimePredictionDate(
                        timePrediction["second_part"]["date"]
                      )
                    }}:
                    {{ timePrediction["second_part"]["amount"] | currency }}
                    {{ currencyName }}
                  </span>
                  <span
                    v-else
                    class="default_gray"
                    style="width: 50%; margin: 4px 10px 0 0"
                  />
                </div>
              </transition>
              <transition name="fade1">
                <div
                  v-if="
                    mablagh != null &&
                    mablagh >= minimumAmount &&
                    timePrediction &&
                    timePrediction['third_part'] &&
                    timePrediction['third_part']['amount'] > 0
                  "
                  class="font_18 iconAndMablagh"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g transform="translate(-404 -356)">
                      <rect
                        style="fill: #fff"
                        width="16"
                        height="16"
                        transform="translate(404 356)"
                      />
                      <path
                        class="b"
                        d="M9,14.6A5.6,5.6,0,1,0,3.4,9,5.6,5.6,0,0,0,9,14.6M9,2A7,7,0,1,1,2,9,7,7,0,0,1,9,2m.35,3.5V9.175l3.15,1.869-.525.861L8.3,9.7V5.5Z"
                        transform="translate(403 355)"
                      />
                    </g>
                  </svg>
                  <span
                    v-if="
                      timePrediction != null &&
                      timePrediction['third_part']['amount'] > 0
                    "
                  >
                    قابل برداشت در
                    {{
                      convertTimePredictionDate(
                        timePrediction["third_part"]["date"]
                      )
                    }}:
                    {{ timePrediction["third_part"]["amount"] | currency }}
                    {{ currencyName }}
                  </span>
                  <span
                    v-else
                    class="default_gray"
                    style="width: 50%; margin: 4px 10px 0 0"
                  />
                </div>
              </transition>
            </div>
            <div
              v-if="showTimePrediction"
              class="p2p-time-prediction van_color11"
            >
              <div
                v-if="
                  timePrediction != null &&
                  timePrediction['instant'] &&
                  timePrediction['instant']['amount'] > 0
                "
                :column="$vuetify.breakpoint.xsOnly"
                :class="
                  timePrediction != null &&
                  ((timePrediction['second_part'] &&
                    timePrediction['second_part']['amount'] > 0) ||
                    (timePrediction['third_part'] &&
                      timePrediction['third_part']['amount'] > 0)) &&
                  timePrediction['instant'] &&
                  timePrediction['instant']['amount'] > 0
                    ? 'gray-text-box with-data pt-4'
                    : 'gray-text-box with-data'
                "
                style="margin: 0 auto 0 0px; min-height: 30px"
              >
                <v-layout column>
                  <v-flex>
                    <v-layout
                      v-if="$vuetify.breakpoint.xsOnly"
                      :column="$vuetify.breakpoint.xsOnly"
                      align-center
                      class="font_2 ma-1"
                    >
                      <v-layout justify-space-between style="width: 100%">
                        <v-flex>
                          تا
                          {{ timePrediction["instant"]["amount"] | currency }}
                          {{ currencyName }}
                        </v-flex>
                        <v-flex
                          style="
                            text-align-last: left !important;
                            padding-bottom: 4px;
                          "
                        >
                          <div class="linkBorder">
                            <v-btn
                              class="small"
                              text
                              :ripple="false"
                              @click="
                                selectAmount(
                                  timePrediction['instant']['amount']
                                )
                              "
                            >
                              انتخاب
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>
                      <v-flex class="mt-1" style="width: 100%">
                        قابل برداشت در لحظه
                      </v-flex>
                    </v-layout>
                    <v-layout v-else align-center class="font_2 mb-1">
                      <v-flex>
                        تا
                        {{ timePrediction["instant"]["amount"] | currency }}
                        {{ currencyName }}
                        <div class="linkBorder">
                          <v-btn
                            class="small"
                            text
                            :ripple="false"
                            @click="
                              selectAmount(timePrediction['instant']['amount'])
                            "
                          >
                            انتخاب
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex style="text-align-last: left !important">
                        قابل برداشت در لحظه
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
              <div
                v-if="
                  timePrediction != null &&
                  timePrediction['second_part'] &&
                  timePrediction['second_part']['amount'] > 0 &&
                  timePrediction['second_part']['date'] != null
                "
                :column="$vuetify.breakpoint.xsOnly"
                :class="
                  timePrediction &&
                  timePrediction['instant'] &&
                  timePrediction['instant']['amount'] > 0 &&
                  timePrediction['third_part'] &&
                  timePrediction['third_part']['amount'] == 0
                    ? 'gray-text-box with-data pb-4'
                    : (timePrediction['third_part']['amount'] == 0 &&
                        timePrediction['instant']['amount'] == 0) ||
                      (timePrediction['third_part']['amount'] != 0 &&
                        timePrediction['instant']['amount'] != 0)
                    ? 'gray-text-box with-data'
                    : 'gray-text-box with-data pt-4'
                "
                :style="
                  timePrediction['third_part'] &&
                  timePrediction['third_part']['amount'] > 0
                    ? 'margin: 0 auto 0 0; min-height: 30px'
                    : 'margin: 0 auto 20px 0px; min-height: 30px'
                "
              >
                <v-layout column>
                  <v-flex>
                    <v-layout
                      v-if="$vuetify.breakpoint.xsOnly"
                      :column="$vuetify.breakpoint.xsOnly"
                      align-center
                      class="font_2 ma-1"
                    >
                      <v-layout justify-space-between style="width: 100%">
                        <v-flex>
                          تا
                          {{
                            timePrediction["second_part"]["amount"] | currency
                          }}
                          {{ currencyName }}
                        </v-flex>
                        <v-flex
                          style="
                            text-align-last: left !important;
                            padding-bottom: 4px;
                          "
                        >
                          <div class="linkBorder">
                            <v-btn
                              class="small"
                              text
                              :ripple="false"
                              @click="
                                selectAmount(
                                  timePrediction['second_part']['amount']
                                )
                              "
                            >
                              انتخاب
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>
                      <v-flex class="mt-1" style="width: 100%">
                        قابل برداشت در
                        {{
                          timePrediction["second_part"]["date"].split(" - ")[1]
                        }}
                      </v-flex>
                    </v-layout>
                    <v-layout v-else align-center class="font_2 mb-1">
                      <v-flex>
                        تا
                        {{ timePrediction["second_part"]["amount"] | currency }}
                        {{ currencyName }}
                        <div class="linkBorder">
                          <v-btn
                            class="small"
                            text
                            :ripple="false"
                            @click="
                              selectAmount(
                                timePrediction['second_part']['amount']
                              )
                            "
                          >
                            انتخاب
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex style="text-align-last: left !important">
                        قابل برداشت در
                        {{
                          convertTimePredictionDate(
                            timePrediction["second_part"]["date"]
                          )
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
              <div
                v-if="
                  timePrediction != null &&
                  timePrediction['third_part'] &&
                  timePrediction['third_part']['amount'] > 0 &&
                  timePrediction['third_part']['date'] != null
                "
                :column="$vuetify.breakpoint.xsOnly"
                :class="
                  (timePrediction &&
                    timePrediction['instant'] &&
                    timePrediction['instant']['amount'] > 0) ||
                  (timePrediction['second_part'] &&
                    timePrediction['second_part']['amount'] > 0)
                    ? 'gray-text-box with-data pb-4'
                    : 'gray-text-box with-data'
                "
                style="margin: 0 auto 20px 0px; min-height: 30px"
              >
                <v-layout column>
                  <v-flex>
                    <v-layout
                      v-if="$vuetify.breakpoint.xsOnly"
                      :column="$vuetify.breakpoint.xsOnly"
                      align-center
                      class="font_2 ma-1"
                    >
                      <v-layout justify-space-between style="width: 100%">
                        <v-flex>
                          تا
                          {{
                            timePrediction["third_part"]["amount"] | currency
                          }}
                          {{ currencyName }}
                        </v-flex>
                        <v-flex
                          style="
                            text-align-last: left !important;
                            padding-bottom: 4px;
                          "
                        >
                          <div class="linkBorder">
                            <v-btn
                              class="small"
                              text
                              :ripple="false"
                              @click="
                                selectAmount(
                                  timePrediction['third_part']['amount']
                                )
                              "
                            >
                              انتخاب
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>
                      <v-flex class="mt-1" style="width: 100%">
                        قابل برداشت در
                        {{
                          timePrediction["third_part"]["date"].split(" - ")[1]
                        }}
                      </v-flex>
                    </v-layout>
                    <v-layout v-else align-center class="font_2 mb-1">
                      <v-flex>
                        تا
                        {{ timePrediction["third_part"]["amount"] | currency }}
                        {{ currencyName }}
                        <div class="linkBorder">
                          <v-btn
                            class="small"
                            text
                            :ripple="false"
                            @click="
                              selectAmount(
                                timePrediction['third_part']['amount']
                              )
                            "
                          >
                            انتخاب
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex style="text-align-last: left !important">
                        قابل برداشت در
                        {{
                          convertTimePredictionDate(
                            timePrediction["third_part"]["date"]
                          )
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
            </div>

            <v-layout
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
            >
              <v-flex class="label"> شناسه انتقال </v-flex>
              <v-flex class="withlabel">
                <payment-number
                  placeholder="اختیاری"
                  @setValue="paymentNumber = $event"
                />
              </v-flex>
            </v-layout>

            <v-layout
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
            >
              <v-flex class="label"> یادداشت محرمانه </v-flex>
              <v-flex class="withlabel big">
                <v-textarea
                  v-model="description"
                  solo
                  flat
                  outlined
                  rows="3"
                  :hint="commentHint"
                >
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-textarea>
              </v-flex>
            </v-layout>
          </div>
          <!-- </transition> -->
        </v-form>
      </v-flex>
    </v-layout>

    <!-- confirm modal -->
    <general-dialog
      v-if="approveP2p"
      :active="approveP2p"
      content-class="confirmationMessageDialog"
      title="از انتقال وجه اطمینان دارید؟"
      description="این عملیات قابل بازگشت نیست"
      :width="360"
      btn1-text="منتقل شود"
      :btn1-loading="$store.state.p2pConfirmLoading"
      btn2-text="خیر، ویرایش می‌کنم"
      :btn2-disabled="$store.state.p2pConfirmLoading"
      @btn1Clicked="sendForm()"
      @btn2Clicked="approveP2p = false"
    >
      <template #extra>
        <div
          class="font_2 mt-12 pa-5 pt-10"
          style="
            align-items: center;
            width: 100%;
            background-color: #f5f5f5;
            position: sticky;
          "
        >
          <v-avatar
            size="60"
            color="white"
            style="
              position: absolute;
              top: -30px;
              left: 120px;
              box-shadow: 0px 0px 6px #00000029;
            "
          >
            <v-img
              v-if="shenaseSelect[0] != null"
              :src="$store.state.storage_base_url + shenaseSelect[0]"
              alt="avatar"
            />
            <v-icon v-else size="20"> person </v-icon>
          </v-avatar>
          <v-layout align-center>
            <v-flex class="font_8"> صاحب حساب </v-flex>
            <v-flex style="text-align: left; direction: ltr">
              {{ shenaseSelect[3] ? shenaseSelect[3] : shenaseSelect[1] }}
            </v-flex>
          </v-layout>
          <hr class="my-2" style="border: 1px dashed #bbbbbb; width: 100%" />
          <v-layout align-center>
            <v-flex class="font_8"> کل مبلغ </v-flex>
            <v-flex class="text-left">
              {{ mablagh | thousandSeprator }}
              {{ currencyName }}
            </v-flex>
          </v-layout>
        </div>
        <div
          v-if="timePrediction && timePrediction['instant']"
          class="align-center font_2 justify-center mt-2 pa-5 text-right"
          style="background-color: #e4f1ee; width: 100%"
        >
          <v-layout v-if="timePrediction['instant']['amount'] > 0" align-center>
            <v-flex class="font_8"> قابل برداشت در لحظه </v-flex>
            <v-flex class="text-left">
              {{ timePrediction["instant"]["amount"] | currency }}
              {{ currencyName }}
            </v-flex>
          </v-layout>
          <hr
            v-if="
              timePrediction['instant']['amount'] > 0 &&
              timePrediction['second_part']['amount'] > 0
            "
            class="my-2"
            style="border: 1px dashed #bbbbbb; width: 100%"
          />
          <v-layout
            v-if="timePrediction['second_part']['amount'] > 0"
            align-center
          >
            <v-flex class="font_8">
              قابل برداشت در
              {{
                convertTimePredictionDate(timePrediction["second_part"]["date"])
              }}
            </v-flex>
            <v-flex class="text-left">
              {{ timePrediction["second_part"]["amount"] | currency }}
              {{ currencyName }}
            </v-flex>
          </v-layout>
          <hr
            v-if="
              timePrediction['third_part']['amount'] > 0 &&
              (timePrediction['instant']['amount'] > 0 ||
                timePrediction['second_part']['amount'] > 0)
            "
            class="my-2"
            style="border: 1px dashed #bbbbbb; width: 100%"
          />
          <v-layout
            v-if="timePrediction['third_part']['amount'] > 0"
            align-center
          >
            <v-flex class="font_8">
              قابل برداشت در
              {{
                convertTimePredictionDate(timePrediction["third_part"]["date"])
              }}
            </v-flex>
            <v-flex class="text-left">
              {{ timePrediction["third_part"]["amount"] | currency }}
              {{ currencyName }}
            </v-flex>
          </v-layout>
        </div>
      </template>
    </general-dialog>
  </v-card>
</template>

<script>
import PaymentNumber from "@/components/inputs/paymentNumber.vue"
import { v4 as uuidv4 } from "uuid"
import GeneralDialog from "@/components/modals/generalDialog"

const jmoment = require("moment-jalaali")
function formatAsCurrency(value, dec) {
  dec = dec || 0
  if (value === null) {
    return null
  }
  return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}
export default {
  name: "AddP2p",
  components: {
    PaymentNumber,
    GeneralDialog,
  },
  props: {
    isSameTransfer: {
      type: Boolean,
      default: false,
    },
    addProps: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      approveP2p: false,
      show: true,
      select: null,
      loading: false,
      valid: false,
      mablagh: null, //v-model of mablagh
      mablaghRules: [
        (v) =>
          this.checkAmount ||
          "<span> مبلغ وارد شده از کیف پول شما بیشتر است </span>",
      ],
      commentHint:
        '<div class="d-flex align-center"><img style="max-width:10px;margin-left:5px" src="' +
        require("@/assets/img/info.svg") +
        '" >متنی که فقط برای شما قابل مشاهده است</div>',
      shenaseSelect: "",
      shenaseList: [],
      pureIbans: [],
      amount: 0,
      all: 0,
      today: 0,
      blocked: 0,
      //search api
      searchinput: "",
      searchValue: "",
      //load more
      currentPage: 1,
      endOfLoadMore: false,
      //CHECK IBAN
      input: "",
      ibank: null,
      persianName: "",
      ibanOwner: "",
      error: "",
      lastEmptyResultString: null,
      wage: null,
      walletPrediction: null,
      deductiblePrediction: null,
      //settlement date just for input amount
      inputSettlementDate: null,
      deductableSettlementDate: null,
      walletSettlementDate: null,
      paymentNumber: null,
      description: null,
      infoIsGet: false,
      p2p_deductible: null,
      apiCallLoading: false,
      timeOut: null,
      trackId: null,
      timePrediction: null,
      showTimePrediction: false,
      apiCalled: false,
    }
  },
  computed: {
    hideNoData() {
      return this.searchinput == "" || !this.apiCalled
    },
    isIdSelected() {
      if (
        this.shenaseSelect &&
        this.shenaseSelect != null &&
        this.shenaseSelect != "undefined" &&
        this.shenaseSelect.length != 0
      )
        return true
      else return false
    },
    minimumAmount() {
      if (this.$store.state.user.currency == "RIAL") return 10000
      else return 1000
    },
    amountInWord: {
      get: function () {
        var convert
        if (this.mablagh == null) {
          convert = "0"
        } else {
          convert = this.mablagh
        }
        return wordifyfa(convert)
      },
    },
    thousandSeprator: {
      get: function () {
        return formatAsCurrency(this.mablagh, 0)
      },
      set: function (newValue) {
        if (newValue) {
          this.mablagh = Number(
            this.convertNumbers2English(newValue).replace(/[^0-9.]/g, "")
          )
        } else {
          this.mablagh = null
        }
      },
    },
    disable() {
      if (
        !this.loading &&
        this.valid &&
        this.mablagh != null &&
        this.mablagh >= this.minimumAmount &&
        this.shenaseSelect &&
        this.shenaseSelect != "undefined" &&
        this.shenaseSelect.length != 0
      )
        return true
      else return false
    },
    checkAmount(value) {
      if (this.mablagh != null) {
        var param
        var testMablagh = this.mablagh
        testMablagh = testMablagh.toString().replace(/\D/g, "")
        this.$store.state.isUser ? (param = "user") : (param = "business")
        return this.all >= testMablagh ? true : false
      } else {
        return true
      }
    },
  },
  watch: {
    addProps: {
      handler(newValue, oldValue) {
        if (newValue != null) {
          this.shenaseSelect = [
            this.addProps.receiver.avatar,
            this.addProps.receiver.name,
            this.addProps.receiver.slug,
            this.addProps.receiver.business_owner,
          ]
          if (this.isSameTransfer) {
            this.description = this.addProps.description
            this.paymentNumber = this.addProps.payment_number
            if (this.infoIsGet) {
              let temp = this.addProps.amount - this.addProps.wage
              this.mablagh = this.convertToRial(temp)
            }
          } else {
            this.paymentNumber = null
            this.description = null
            this.mablagh = null
          }
        }
      },
      deep: true,
      immediate: true,
    },
    searchValue: function (newValue, oldValue) {
      if (
        newValue &&
        newValue != "" &&
        newValue != null &&
        newValue.length >= 3
      ) {
        this.searchinput = newValue
        this.timeOut = this.delay(this.timeOut, this.searchIban)
      } else {
        this.searchinput = ""
        this.shenaseList = []
        this.apiCalled = false
        //this.shenaseList = null
      }
    },
    mablagh: function (newValue, oldValue) {
      if (
        this.mablagh &&
        this.mablagh >= this.minimumAmount &&
        this.mablagh <= this.all
      ) {
        //this.computeSettlementDate()
        this.getWage()
        this.timeOut = this.delay(this.timeOut, this.getTimePrediction)
      } else {
        this.inputSettlementDate = null
        this.wage = null
        this.timePrediction = null
      }
      if (this.mablagh == null) {
        this.getTimePrediction()
      }
    },
    shenaseSelect: {
      deep: true,
      handler(newValue, oldValue) {
        if (
          !(
            newValue &&
            newValue != null &&
            newValue != "undefined" &&
            typeof newValue === "object" &&
            newValue.length == 4
          )
        ) {
          this.$nextTick(() => {
            this.shenaseSelect = null
          })
        } else {
          this.getTimePrediction()
        }
      },
    },
  },
  mounted() {
    this.trackId = uuidv4()
    this.getApi()
    this.getTimePrediction
  },
  methods: {
    convertTimePredictionDate(date) {
      if (this.timePrediction != null && date != null) {
        return this.jalaliDate(
          jmoment(date, "HH:mm:ss - jYYYY/jM/jD"),
          "jYYYY/jM/jD",
          false,
          false
        )
      }
      return null
    },
    convertNumbers2English: function (string) {
      return string
        .replace(/[\u0660-\u0669]/g, function (c) {
          return c.charCodeAt(0) - 0x0660
        })
        .replace(/[\u06f0-\u06f9]/g, function (c) {
          return c.charCodeAt(0) - 0x06f0
        })
    },
    convertToRial(value) {
      if (this.$store.state.user.currency == "RIAL") {
        value = value * 10
        return value
      } else return value
    },
    convertToToman(value) {
      if (this.$store.state.user.currency == "RIAL") {
        value = Math.floor(value / 10)
        return value
      } else return value
    },
    moment: function () {
      return moment()
    },
    selectAmount(amount) {
      this.mablagh = parseInt(
        this.$options.filters.currency(amount).replace(/,/g, "")
      )
    },
    getWage() {
      var data = {
        amount: this.convertToToman(this.mablagh),
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/p2p/wage", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.wage = response.data.wage
        })
        .catch((error) => {})
    },
    getApi() {
      this.$http
        .get(this.$store.getters.apiUrlParam + "/settlement/info", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          var res = response.data.data
          this.all = this.convertToRial(res.wallet)
          this.tomorrow = this.convertToRial(res.tomorrow_deductible_amount)
          this.today = this.convertToRial(res.deductible_amount)
          this.blocked = this.convertToRial(res.blocked_amount)
          if (res.overdraft != null)
            this.p2p_deductible = this.convertToRial(
              res.overdraft.p2p_deductible
            )
          this.load = true
          this.infoIsGet = true
        })
        .catch((error) => {})
    },
    searchIban() {
      if (
        this.searchinput != "" &&
        (this.lastEmptyResultString == null ||
          !this.searchinput.includes(this.lastEmptyResultString))
      ) {
        this.apiCallLoading = true
        this.$http
          .get(
            this.$store.getters.apiUrlParam +
              "/profiles/search?q=" +
              this.searchinput,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.apiCalled = true
            var data = response.data.data
            this.apiCallLoading = false
            //TODO: this is getting error: Error in render: "TypeError: Cannot read property 'length' of undefined"
            if (
              data &&
              data != undefined &&
              data != "undefined" &&
              data.length == 0
            ) {
              this.lastEmptyResultString = this.searchinput
              this.shenaseList = []
            } else {
              this.lastEmptyResultString = null
            }
            if (
              data &&
              data != undefined &&
              data != "undefined" &&
              data.length != 0
            ) {
              this.shenaseList = Object.keys(data).map(function (key) {
                return [
                  data[key].avatar,
                  data[key].name,
                  data[key].slug,
                  data[key].business_owner,
                ]
              })
            } else {
              this.shenaseList = []
            }
          })
          .catch((error) => {
            this.apiCallLoading = false
            this.apiCalled = true
            //callsnackbar
          })
      } else {
        if (this.searchinput.includes(this.lastEmptyResultString))
          this.lastEmptyResultString = this.searchinput
        else {
          this.lastEmptyResultString = null
        }
      }
    },
    getTimePrediction() {
      if (
        (this.mablagh &&
          this.mablagh >= this.minimumAmount &&
          this.mablagh <= this.all) ||
        this.mablagh == null
      ) {
        this.$http
          .post(
            this.$store.getters.apiUrlParam + "/p2p/time-prediction",
            {
              amount:
                this.mablagh == 0 || this.mablagh == null
                  ? null
                  : this.convertToToman(this.mablagh),
              slug: this.shenaseSelect[2],
            },
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.timePrediction = response.data
            if (this.mablagh == null || this.mablagh == 0) {
              this.showTimePrediction = true
              return
            }
            this.showTimePrediction = false
          })
          .catch(() => {
            this.showTimePrediction = false
          })
      }
    },
    checkForm() {
      if (this.$refs.form.validate()) {
        this.approveP2p = true
      }
    },
    sendForm() {
      if (this.$refs.form.validate()) {
        this.$store.commit("changeP2pConfirmLoading", true)
        this.$emit("closeAfterSubmit", {
          status: true,
          amount: this.convertToToman(this.mablagh),
          avatar: this.shenaseSelect[0],
          name: this.shenaseSelect[1],
          slug: this.shenaseSelect[2],
          businessOwner: this.shenaseSelect[3],
          paymentNumber: this.paymentNumber,
          description: this.description,
          trackId: this.trackId,
        })
      }
    },
  },
}
</script>
<style lang="scss">
.p2p_search {
  .v-select__slot {
    .v-input__append-inner {
      display: none !important;
    }
  }
}
</style>
<style lang="scss" scopped>
.gray-text-box {
  &.with-data {
    > .layout {
      padding: 4px 18px !important;
    }
  }
}
.p2p-time-prediction {
  margin: 10px auto 20px 0;
  max-width: 350px;
  border-radius: 4px;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
}
</style>
