<template>
  <div>
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      outlined
      class="business"
      color="white"
      flat
    >
      <div class="titles">
        <div
          v-if="$store.state.ipg && $store.state.ipg.urls"
          class="income d-flex align-center"
          style="width: 300px"
        >
          <h2
            style="
              display: block;
              margin: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              flex: 0 1 auto !important;
            "
          >
            {{
              $store.state.ipg.url != null
                ? $store.state.ipg.url
                : $store.state.ipg.urls[0]
            }}
          </h2>
        </div>
      </div>
      <div v-if="$store.state.ipg.status == 1" class="more-data">
        <div class="bus bus-right">
          <div v-if="$store.state.business.role != 'developer'" class="mablagh">
            {{ $store.state.ipg.transactions_count | thousandSeprator }}
          </div>
          <div v-else class="mablagh">-</div>
          <div class="sub-text">تراکنش</div>
        </div>
        <div class="bus bus-left">
          <div
            v-if="$store.state.business.role != 'developer'"
            dir="ltr"
            class="mablagh"
          >
            {{ $store.state.ipg.transactions_sum | currency }}
          </div>
          <div v-else class="mablagh">-</div>
          <div class="sub-text">{{ currencyName }} واریزی</div>
        </div>
      </div>
      <v-layout column class="botton">
        <v-flex
          v-if="
            $store.state.business.role != 'developer' &&
            $store.state.ipg.status == 1
          "
        >
          <v-btn
            text
            depressed
            outlined
            class="btn_small_normal"
            :to="{
              name: 'businessReport',
              params: { filtered: 'successfullTransaction' },
            }"
          >
            واریز‌ها
          </v-btn>
        </v-flex>
        <v-flex>
          <v-btn
            text
            depressed
            outlined
            class="btn_small_normal"
            @click="$emit('view_form')"
          >
            تنظیمات
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card
      v-if="!$vuetify.breakpoint.mdAndUp"
      outlined
      class="business-md-down"
      color="white"
      flat
    >
      <v-layout wrap justify-space-between align-center>
        <v-flex style="max-width: calc(100% - 90px)">
          <div class="titles">
            <div
              v-if="$store.state.ipg && $store.state.ipg.urls"
              class="income d-flex align-center"
            >
              <h2
                style="
                  flex: 0 1 auto !important;
                  margin: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{
                  $store.state.ipg.url != null
                    ? $store.state.ipg.url
                    : $store.state.ipg.urls[0]
                }}
              </h2>
            </div>
          </div>
        </v-flex>
        <v-flex style="max-width: 40px; margin-left: -10px">
          <v-menu
            transition="slide-y-transition"
            offset-y
            close-on-content-click
            bottom
            content-class="up_menu"
          >
            <!-- this on slot sometime is undefined -->
            <template #activator="{ on }">
              <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                <v-icon color="van_color03" size="21" style="line-height: 40px">
                  more_vert
                </v-icon>
              </v-btn>
            </template>

            <v-card outlined class="drop_down_menu">
              <v-list class="menu_v_list">
                <v-list-item
                  :disabled="
                    !(
                      $store.state.business.role != 'developer' &&
                      $store.state.ipg.status == 1
                    )
                  "
                  :to="{
                    name: 'businessReport',
                    params: { filtered: 'successfullTransaction' },
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title>واریز‌ها</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$emit('view_form')">
                  <v-list-item-content>
                    <v-list-item-title>تنظیمات</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="$store.state.ipg.status == 1"
        class="more-data"
        align-center
        justify-center
        fill-height
      >
        <div class="bus bus-right">
          <div v-if="$store.state.business.role != 'developer'" class="mablagh">
            {{ $store.state.ipg.transactions_count | thousandSeprator }}
          </div>
          <div v-else class="mablagh">-</div>
          <div class="sub-text">تراکنش</div>
        </div>
        <div class="bus bus-left">
          <div
            v-if="$store.state.business.role != 'developer'"
            dir="ltr"
            class="mablagh"
          >
            {{ $store.state.ipg.transactions_sum | currency }}
          </div>
          <div v-else class="mablagh">-</div>
          <div class="sub-text">{{ currencyName }} واریزی</div>
        </div>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Card",
  data() {
    return {
      isHover: false,
      hover: false,
    }
  },
  methods: {
    toPay() {
      window.location =
        this.$store.state.pay_base_url +
        "/business/" +
        this.$store.state.business.business_name +
        "/ipg/payment/request"
    },
  },
}
</script>

<style lang="scss" scoped>
.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}
.business {
  // height: 120px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;
  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}
.business-md-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;
  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
  .more-data {
    display: flex;
    //width: 250px;
    margin-top: 16px;
  }
}
.v-avatar {
  margin: 0 0 10px 0;
}
//.titles {
//  max-width: calc(100% - 100px);
//}
h2 {
  margin: 0 0 9px 0;
  line-height: 22px;
}
.income {
  font-size: 10px;
  color: #999;
}
.bus-right {
  margin-left: 10px;
}
.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;
  &.bus-right,
  &.bus-left {
    width: 95%;
  }
  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}
.business .more-data {
  display: flex;
  width: 250px;
}
.mablagh {
  font-size: 14px;
  font-weight: bold;
}
.sub-text {
  font-size: 9px;
  color: #999;
}
.layout.botton {
  max-width: 88px;
  .v-btn {
    width: calc(100% - 16px);
    margin-top: 3px;
    margin-bottom: 3px;
  }
}
</style>
