<template>
  <v-card>
    <v-toolbar class="fixed_toolbar" flat height="60">
      <v-btn icon @click="$emit('close-modal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pa-0"> تنظیمات پرداخت با وندار </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          class="btn_small_normal"
          dark
          depressed
          outlined
          text
          @click="open_edit()"
        >
          ویرایش
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout column>
      <v-flex>
        <v-layout class="rows" column>
          <v-flex>
            <div
              class="details d-flex full-width flex-column align-center justify-center pa-4"
            >
              <div class="d-flex full-width align-center justify-start">
                <span class="details-title" style="max-width: 100px">
                  کلید API
                </span>
                <span class="details-value details-value-underlined">{{
                  application.api_key
                }}</span>
                <input
                  id="apiKeyHidden"
                  :value="application.api_key"
                  class="hidden-input"
                />
                <v-spacer />
                <v-btn
                  ref="btn"
                  class="btn_small_normal tblCpy-receipt_url"
                  depressed
                  outlined
                  style="
                    min-width: 58px !important;
                    margin: 0 !important;
                    padding: 0;
                    max-width: 58px;
                    height: 36px !important;
                  "
                  text
                  @click="copyApiKey()"
                >
                  <v-icon class="ml-1" color="van_color03" size="14">
                    content_copy
                  </v-icon>
                  <span class="font_4">کپی</span>
                  <v-tooltip v-model="apiKeyCopied" top>
                    <template #activator="{ on }">
                      <v-btn
                        style="
                          position: absolute;
                          opacity: 0 !important;
                          width: 1px;
                          margin: 0px;
                          padding: 0px;
                          z-index: -1;
                          left: 0px;
                        "
                        v-on="on"
                      />
                    </template>
                    <span>کپی شد</span>
                  </v-tooltip>
                </v-btn>
              </div>
              <v-divider class="dashed-divider my-3" />

              <div class="d-flex full-width align-center justify-start">
                <span class="details-title"> آدرس وب‌سایت </span>
                <span class="details-value details-value">{{ domain }}</span>
                <v-spacer />
              </div>
              <v-divider class="dashed-divider my-3" />

              <div class="d-flex full-width align-center justify-start">
                <span class="details-title"> آی‌پی </span>
                <span class="details-value details-value">{{ ips }}</span>
                <v-spacer />
              </div>
              <v-divider class="dashed-divider my-3" />

              <div class="d-flex full-width align-center justify-start">
                <span class="details-title"> کارمزد تراکنش </span>
                <span class="details-value details-value">{{
                  persianWageType
                }}</span>
                <v-spacer />
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "SettingManagement",
  components: {},
  data() {
    return {
      apiKeyCopied: false,
    }
  },
  computed: {
    application() {
      return this.$store.state.pbv.application
    },
    persianWageType() {
      return this.application.wage_type === "APPLICATION_SELF"
        ? "از خودم کسر شود"
        : "از پرداخت‌کننده دریافت شود"
    },
    ips() {
      const ips = this.application.ips
      return ips.join(" | ")
    },
    domain() {
      const domains = this.application?.domains
      return domains ? domains[0] : "-"
    },
  },
  methods: {
    copyApiKey() {
      /* Get the text field */
      let copyText = document.querySelector("#apiKeyHidden")

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        //Alert the copied text
        this.apiKeyCopied = true
        setTimeout(() => {
          this.apiKeyCopied = false
        }, 2000)
      }
    },

    //edit
    open_edit() {
      this.$emit("open-edit", true)
    },
    close_modal() {
      this.$emit("open-edit", false)
    },
  },
}
</script>

<style lang="scss" scoped>
.dashed-divider {
  border-style: dashed !important;
  border-color: #d5d5d5;
  width: 100% !important;
}

.image-box {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.full-width {
  width: 100% !important;
}

.modal-image-box {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 38px;
  height: 38px;
  border-radius: 4px;
}

.modal-title {
  font-size: 16px;
  font-weight: 700;
}

.modal-description {
  font-size: 12px;
  font-weight: 400;
}

.details {
  &-title {
    color: #999999;
    font-size: 11px;
    min-width: 100px;
  }

  &-value {
    color: #333333;
    font-size: 12px;

    &-underlined {
      text-decoration: underline;
    }
  }
}
</style>
