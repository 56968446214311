const report = {
  state() {
    return {
      //$store.state.report.kindFilter
      kindFilter: "0",
      statusFilters: "0",
      channelFilter: null,
      fromDate: null,
      toDate: null,
      duration: "0",
      updateFilters: false,
      loading: false,
      update: false,
      isWalletAffected: false,
      aggregateTransactionData: {},
    }
  },
  mutations: {
    updateUpdate(state, val) {
      state.update = val
    },
    updateLoading(state, val) {
      state.loading = val
    },
    syncFilterDone(state) {
      state.updateFilters = false
    },
    updateToAndFormDate(state, date) {
      state.toDate = date
      state.fromDate = date
    },
    updatePartOfFilters(state, obj) {
      state.kindFilter = obj.kindFilter
      state.statusFilters = obj.statusFilters
      state.channelFilter = obj.channelFilter
      state.loading = true
    },
    updateFilters(state, obj) {
      state.kindFilter = obj.kindFilter
      state.statusFilters = obj.statusFilters
      state.channelFilter = obj.channelFilter
      state.fromDate = obj.fromDate
      state.toDate = obj.toDate
      state.duration = obj.duration
      state.loading = true
    },
    updateChannelFilter(state, obj) {
      state.channelFilter = obj
    },
    DELETE_ALL_REPORT_FILTERS(state) {
      state.kindFilter = "0"
      state.statusFilters = "0"
      state.channelFilter = null
      state.fromDate = null
      state.toDate = null
      state.duration = "0"
      state.updateFilters = true
      state.loading = false
    },
    setTransactionIsWalletAffected(state, payload) {
      state.isWalletAffected = payload
    },
    setAggregateTransactionData(state, payload) {
      state.aggregateTransactionData = payload
    },
  },
  getters: {
    channelFilterImported(state) {
      if (state.channelFilter == null) return false
      else if (state.channelFilter != null && state.channelFilter.length == 0) {
        return false
      } else return true
    },
    isFilterApplied(state, getters) {
      if (
        state.kindFilter == "0" &&
        state.statusFilters == "0" &&
        state.duration == "0" &&
        !getters.channelFilterImported &&
        state.fromDate == null &&
        state.fromDate == null
      )
        return false
      else return true
    },
  },
  actions: {
    deleteAllReportFilters({ commit }, payload) {
      commit("DELETE_ALL_REPORT_FILTERS")
      commit("changeTheStateOfShouldFilterWithCashInByCode", payload, {
        root: true,
      })
    },
  },
}

export default report
