<template>
  <v-text-field
    ref="shenase"
    v-model="paymentNumber"
    solo
    flat
    height="40"
    outlined
    @keydown="numberKeyHandler($event)"
  >
    <template #message="message">
      <div v-html="message.message"></div>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "PaymentNumber",
  data() {
    return {
      paymentNumber: null,
    }
  },
  watch: {
    paymentNumber: function (newValue) {
      this.paymentNumber = this.convertNumbers2English(newValue).replace(
        /[^0-9]/g,
        ""
      )
      this.paymentNumber = this.paymentNumber.slice(0, 30)
      this.$refs.shenase.lazyValue = this.paymentNumber
      this.$emit("setValue", newValue)
    },
  },
}
</script>

<style></style>
