<template>
  <v-card>
    <v-toolbar class="fixed_toolbar" flat height="60">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> کاربران </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          class="btn_small_normal"
          dark
          text
          @click="open_addUser()"
        >
          افزودن کاربر جدید
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-text-field
        ref="searchInput"
        v-model.trim="searchQuery"
        :prepend-inner-icon="searchQuery ? 'close' : 'search'"
        height="36"
        flat
        hide-details
        outlined
        solo
        placeholder="جستجو نام کاربر"
        class="user-search mt-8"
        @click:prepend-inner="clearSearch()"
      >
        <template #message="message">
          <div v-html="message.message" />
        </template>
      </v-text-field>
      <v-flex>
        <v-layout class="shebaList" column>
          <!-- skeleton -->
          <v-flex v-if="!usersLoad" style="width: 100%">
            <v-layout align-center>
              <v-avatar
                class="skeleton_avatar skeletonShadowLoading"
                size="60"
              />
              <v-flex style="width: 100%; max-width: 200px">
                <div class="font_2 bold" style="max-width: 50%">
                  <span class="default_gray" />
                </div>
              </v-flex>
              <v-flex>
                <div class="font_2 bold" style="max-width: 70%">
                  <span class="default_gray" />
                </div>
              </v-flex>
              <v-spacer />
              <v-flex style="max-width: 40px">
                <v-btn disabled icon text>
                  <v-icon color="van_color03" dark size="20">
                    more_vert
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-divider />
            <v-layout align-center>
              <v-avatar
                class="skeleton_avatar skeletonShadowLoading"
                size="60"
              />
              <v-flex style="width: 100%; max-width: 200px">
                <div class="font_2 bold" style="max-width: 50%">
                  <span class="default_gray" />
                </div>
              </v-flex>
              <v-flex>
                <div class="font_2 bold" style="max-width: 70%">
                  <span class="default_gray" />
                </div>
              </v-flex>
              <v-spacer />
              <v-flex style="max-width: 40px">
                <v-btn disabled icon text>
                  <v-icon color="van_color03" dark size="20">
                    more_vert
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- user lists -->
          <v-flex
            v-for="(user, index) in users"
            v-else-if="users.length"
            :key="index"
            style="width: 100%"
          >
            <v-layout align-center wrap>
              <v-flex style="max-width: 56px; margin-left: 10px">
                <v-avatar size="60" style="border: 1px solid #eee">
                  <v-img v-if="user.avatar" :src="user.avatar" />
                  <v-img
                    v-else
                    :src="require('@/assets/img/default.png')"
                    alt="avatar"
                  />
                </v-avatar>
              </v-flex>
              <v-flex style="width: 100%; max-width: 196px">
                <div
                  class="font_2 bold"
                  style="
                    text-align-last: right;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 80%;
                  "
                >
                  {{ user.name }}
                </div>
                <div
                  v-if="user.status !== 1"
                  class="font_6"
                  style="text-align-last: right"
                >
                  (دعوت شده)
                </div>
              </v-flex>
              <v-flex>
                <div class="font_2" style="text-align-last: right">
                  {{ user.role }}
                </div>
              </v-flex>
              <v-spacer />
              <v-flex v-if="user.role_id != 0" style="max-width: 40px">
                <v-menu
                  bottom
                  close-on-content-click
                  offset-y
                  transition="slide-y-transition"
                >
                  <!-- this on slot sometime is undefined -->
                  <template #activator="{ on }">
                    <v-btn fab icon size="24" small v-on="on">
                      <v-icon
                        color="van_color03"
                        size="21"
                        style="line-height: 40px"
                      >
                        more_vert
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card class="drop_down_menu" outlined>
                    <v-list class="menu_v_list">
                      <v-list-item @click="open_changeRole(user, index)">
                        <v-list-item-content>
                          <v-list-item-title>ویرایش دسترسی</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="deleteUser(user.id, index)">
                        <v-list-item-content>
                          <v-list-item-title>حذف کاربر</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-flex>
              <observer
                v-if="
                  index == users.length - 1 &&
                  !endOfLoadMore &&
                  !currentPageApiCalled
                "
                @intersect="intersected"
              />
            </v-layout>
            <v-divider v-if="index != users.length - 1" />
          </v-flex>
          <v-flex v-else-if="users.length === 0" class="font-size-12">
            کاربر جهت نمایش وجود ندارد
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status1"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      transition="slide-x-transition"
    >
      <addUser
        v-show="addUserModal"
        :key="componetnKey"
        @closeModal="close_modal()"
        @setUser="getUpdatedUsersList"
      />
      <changeRole
        v-if="changeRoleModal"
        :user="changeRoleuser"
        @change_Role="changeRole"
        @closeModal="close_modal()"
      />
    </v-dialog>

    <v-dialog
      v-model="notActive"
      content-class="confirmationMessage"
      width="360"
    >
      <v-card height="100%" outlined>
        <v-layout align-center column justify-center>
          <v-flex>
            <v-img
              :src="require('@/assets/img/close-circle-outline.svg')"
              height="40"
              width="40"
            />
          </v-flex>
          <v-flex class="main-title font_20">
            امکان افزودن کاربر وجود ندارد
          </v-flex>
          <v-flex class="main-des font_4">
            تا زمانی که حساب شما فعال نیست، امکان افزودن کاربر را ندارید. موارد
            مورد نیاز برای فعال‌سازی در میز کار شما اعلام شده است
          </v-flex>
          <v-flex>
            <v-btn
              :to="$store.getters.urlParam"
              class="btn_small_normal"
              depressed
              outlined
              text
              @click="notActive = false"
            >
              رفتن به میز کار
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteStatus"
      content-class="confirmationMessage confirmSimple"
      width="360"
    >
      <v-card height="100%" outlined>
        <v-layout align-center column justify-center>
          <v-flex class="main-title font_1">
            از حذف شماره کاربر اطمینان دارید؟
          </v-flex>
          <v-flex class="main-des font_4">
            شما در حال حذف کاربر «
            {{ users[deletedSelection.index] | getName }} » هستید.
          </v-flex>
          <v-flex>
            <v-btn class="btn_small_normal" dark text @click="cancleDelete()">
              خیر، حذف نشود
            </v-btn>
            <v-btn
              :loading="loading"
              class="btn_small_normal"
              depressed
              outlined
              text
              @click="finalDelete()"
            >
              بلی، حذف شود
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import observer from "../elements/observer.vue"
import addUser from "./addUser.vue"
import changeRole from "./changeRole.vue"

export default {
  name: "UserList",
  filters: {
    getName: function (value) {
      if (!value) return ""
      let object = value
      return object.name
    },
  },
  components: {
    addUser,
    changeRole,
    observer,
  },
  props: {
    isShowUserListModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componetnKey: 0,
      status1: false,
      users: [],
      changeRoleModal: false,
      addUserModal: false,
      changeRoleuser: null,
      notActive: false,
      deleteStatus: false,
      deletedSelection: {
        id: null,
        index: null,
      },
      usersLoad: false,
      //load more
      currentPage: 0,
      endOfLoadMore: false,
      currentPageApiCalled: false,
      loading: false,
      lastPage: 1,

      //search
      searchQuery: "",
      timeOut: null,
    }
  },
  watch: {
    status1: function (newValue) {
      if (!newValue) this.$store.commit("callSecondAddUserModal", false)
    },
    searchQuery(newValue) {
      if (newValue?.length >= 2) {
        if (this.timeOut) clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
          this.resetUsersList()
          this.usersLoad = false
          this.currentPage = 0
          this.intersected()
        }, 1200)
      } else if (!newValue) {
        this.currentPage = 0
        this.resetUsersList()
        this.intersected()
        this.timeOut = null
        this.usersLoad = false
      } else {
        this.timeOut = null
      }
    },
    isShowUserListModal: {
      handler(val) {
        if (!val && this.searchQuery) {
          this.searchQuery = ""
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.intersected()
  },
  methods: {
    intersected() {
      this.currentPageApiCalled = true
      let nextPage = this.currentPage + 1

      if (nextPage > this.lastPage) {
        nextPage = this.lastPage
      }

      let url =
        this.$store.getters.apiUrlParam +
        "/iam?page=" +
        nextPage +
        "&per_page=20"

      if (this.searchQuery) {
        url += `&search=${this.convertNumbers2English(this.searchQuery)}`
      }

      this.$http
        .get(url)
        .then((response) => {
          let temporaryList = this.users

          response.data.data.users.forEach((element) => {
            temporaryList.push(element)
          })

          this.users = temporaryList
          this.currentPage = response.data.data.page
          this.currentPageApiCalled = false
          this.usersLoad = true
          this.lastPage = response.data.data.last_page

          if (response.data.data.last_page === nextPage) {
            this.endOfLoadMore = true
          } else {
            this.endOfLoadMore = false
          }
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    changeRole(user, new_role) {
      var user_id = user.id
      var thisRole = new_role
      this.users.forEach((element, index) => {
        if (element.id == user_id) {
          element.role_id = thisRole
          switch (thisRole) {
            case 0:
              element.role = "مالک"
              break
            case 1:
              element.role = "مدیر"
              break
            case 2:
              element.role = "حسابدار"
              break
            case 4:
              element.role = "اپراتور"
              break
            case 5:
              element.role = "ناظر"
              break
            case 3:
              element.role = "برنامه نویس"
              break
            default:
              break
          }
        }
      })
    },
    open_changeRole(user, index) {
      this.close_modal()
      this.changeRoleuser = user
      this.changeRoleModal = true
      this.status1 = true
      this.$store.commit("callSecondAddUserModal", true)
    },
    open_addUser() {
      this.close_modal()
      if (this.$store.state.business.status !== 1) {
        this.notActive = true
      } else {
        this.addUserModal = true
        this.status1 = true
        this.$store.commit("callSecondAddUserModal", true)
      }
    },
    close_modal() {
      this.status1 = false
      this.changeRoleuser = null
      this.changeRoleModal = false
      this.addUserModal = false
    },
    deleteUser(id, index) {
      this.deletedSelection = {
        id: id,
        index: index,
      }
      this.deleteStatus = true
    },
    cancleDelete() {
      this.resetDeleteSelection()
      this.deleteStatus = false
    },
    resetDeleteSelection() {
      this.deletedSelection = {
        id: null,
        index: null,
      }
    },
    finalDelete() {
      this.loading = true
      this.$http
        .delete(
          this.$store.getters.apiUrlParam + "/iam/" + this.deletedSelection.id,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.loading = false
          //if user deleting itself
          var object = this.users[this.deletedSelection.index]
          if (object.user_id == this.$store.state.user.id) {
            this.$router.push("/")
          }

          this.$delete(this.users, this.deletedSelection.index)
          this.resetDeleteSelection()
          this.deleteStatus = false
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
    clearSearch() {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
        this.timeOut = null
      }
      this.searchQuery = ""
    },
    resetUsersList() {
      this.users = []
    },
    getUpdatedUsersList() {
      this.resetUsersList()
      this.currentPage = 0
      this.intersected()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-divider {
  margin: 25px 0;
  border-color: $van_color10 !important;
}

.skeleton_avatar {
  border-radius: 50%;
  background-color: $van_color10;
}
</style>
