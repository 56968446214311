<template>
  <div :class="{ 'accounts-list-wrapper': !reuseIsMobile }">
    <h2 class="font_1">حساب‌ها</h2>
    <div
      class="pa-3 my-2 d-flex align-center account-row-wrapper"
      @click="openWalletDetails"
    >
      <v-row>
        <v-col class="d-flex align-center" :cols="reuseIsMobile ? '12' : ''">
          <v-img
            width="30"
            max-height="30"
            max-width="30"
            height="30"
            class="ml-2"
            :src="walletData.icon"
          />
          <span class="font_13">{{ walletData.name }}</span>
        </v-col>
        <v-col v-if="reuseIsMobile" cols="12" class="py-0">
          <v-divider></v-divider>
        </v-col>
        <v-col
          :cols="reuseIsMobile ? '12' : ''"
          class="d-flex align-center"
          :class="{ 'justify-space-between pb-0': reuseIsMobile }"
        >
          <span class="font_31 ml-3"> موجودی: </span>
          <div>
            <span class="font_22 ml-1" dir="ltr">
              {{ balanceAmountWithThousandSeparator(walletData.balance) }}
            </span>
            <span class="font-11-no-000-18">
              {{ currencyName }}
            </span>
          </div>
        </v-col>
        <v-col
          class="d-flex align-center"
          :class="{
            'justify-end': !reuseIsMobile,
            'justify-start': reuseIsMobile,
          }"
        >
          <span class="font_31 ml-2"> موجودی به‌روز است </span>
        </v-col>
      </v-row>
    </div>
    <div
      v-for="account in $store.state.accountsList"
      :key="account.account"
      class="pa-3 my-2 d-flex align-center account-row-wrapper"
      @click="openAccountDetails(account)"
    >
      <v-row>
        <v-col
          class="d-flex align-center justify-space-between"
          :cols="reuseIsMobile ? '12' : ''"
        >
          <div class="d-flex align-center">
            <v-img
              v-if="account?.iban"
              width="30"
              max-height="30"
              max-width="30"
              height="30"
              class="ml-2"
              :src="
                require(`@/assets/img/banks/${parseInt(
                  account?.iban?.slice(4, 7)
                )}.svg`)
              "
            />
            <div class="d-flex flex-column">
              <span class="font_13">{{ account.name }}</span>
              <span class="font-11-no-000-18">{{ account.account }}</span>
            </div>
          </div>
          <v-tooltip v-if="!account.bank_status && reuseIsMobile" top>
            <template #activator="{ on, attrs }">
              <v-img
                width="20"
                height="20"
                max-height="20"
                max-width="20"
                class="mr-1 mt-1"
                :src="require('@/assets/img/error-warning.svg')"
                v-bind="attrs"
                v-on="on"
                @click.stop=""
              />
            </template>
            <span class="font-11-no-000-18 white--text">اختلال موقت بانک </span>
          </v-tooltip>
        </v-col>
        <v-col v-if="reuseIsMobile" cols="12" class="py-0">
          <v-divider></v-divider>
        </v-col>

        <v-col
          :cols="reuseIsMobile ? '12' : ''"
          class="d-flex align-center"
          :class="{ 'justify-space-between pb-0': reuseIsMobile }"
        >
          <span class="font_31 ml-3"> موجودی: </span>
          <div>
            <span class="font_22">
              {{ balanceAmountWithThousandSeparator(account.balance) }}
            </span>
            <span class="font-11-no-000-18">
              {{ currencyName }}
            </span>
          </div>
          <v-tooltip v-if="!account.bank_status && !reuseIsMobile" top>
            <template #activator="{ on, attrs }">
              <v-img
                width="20"
                height="20"
                max-height="20"
                max-width="20"
                class="mr-1 mt-1"
                :src="require('@/assets/img/error-warning.svg')"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <span class="font-11-no-000-18 white--text">اختلال موقت بانک </span>
          </v-tooltip>
        </v-col>

        <v-col
          class="d-flex align-center"
          :class="{
            'justify-end': !reuseIsMobile,
            'justify-space-between': reuseIsMobile,
          }"
        >
          <span class="font_31">
            آخرین به‌روز رسانی:
            {{
              jalaliDate(
                account.balance_updated_at * 1000,
                "jYYYY/jM/jD - HH:mm"
              )
            }}
          </span>
          <v-btn
            height="28"
            width="28"
            outlined
            class="pa-3"
            :loading="account?.loading"
            style="min-width: unset; border-color: #999"
            @click.stop="getLastBalance(account)"
          >
            <v-img
              width="20"
              height="20"
              max-height="20"
              max-width="20"
              :src="require('@/assets/img/reloadIcon.svg')"
            />
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="dialogStatus"
      :content-class="
        $store.state.accountDetailsThirdModal
          ? 'thirdModal vanmodal'
          : $store.state.accountDetailsSecondModal
          ? 'secondModal vanmodal'
          : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="reuseIsMobile ? '100%' : '600'"
      :fullscreen="reuseIsMobile"
      no-click-animation
      @click:outside="closeModals"
    >
      <AccountDetails
        v-if="isShowAccountDetailsDialog"
        :account="selectedAccount"
        @close-modal="closeModals"
      />

      <WalletDetails
        v-if="isShowWalletDetailsDialog"
        :wallet-data="walletData"
        @close-modal="closeModals"
      />
    </v-dialog>

    <GeneralDialog
      v-if="isShowUpdateAccountErrorModal"
      :active="isShowUpdateAccountErrorModal"
      logo="newIcon/warning.svg"
      content-class="confirmationMessageDialog"
      title="اختلال بانک"
      :width="360"
      btn2-text="متوجه شدم"
      @btn2Clicked="isShowUpdateAccountErrorModal = false"
    >
      <template #extra>
        <span class="font_30 text-center">
          در حال حاضر امکان به‌روزرسانی موجودی حساب وجود ندارد.
        </span>
      </template>
    </GeneralDialog>
  </div>
</template>

<script>
import AccountDetails from "./AccountDetails.vue"
import WalletDetails from "./WalletDetails.vue"
import servicesRepository from "@/services/servicesRepository"
import GeneralDialog from "@/components/modals/generalDialog"
import screenSize from "@/mixins/screenSize.js"
import currencyConvert from "@/mixins/currencyConvert.js"

export default {
  name: "AccountsList",
  components: {
    AccountDetails,
    GeneralDialog,
    WalletDetails,
  },
  mixins: [screenSize, currencyConvert],
  data() {
    return {
      dialogStatus: false,
      isShowAccountDetailsDialog: false,
      isShowWalletDetailsDialog: false,
      walletData: {
        icon: require("@/assets/img/vandar-logo-sign.svg"),
        name: "کیف‌ پول وندار",
        balance: this.$store.state.business.wallet,
      },
      accountsList: [],
      selectedAccount: null,
      isShowUpdateAccountErrorModal: false,
    }
  },
  computed: {
    convertWalletToRial() {
      return this.$store.state.business.wallet * 10
    },
  },
  mounted() {
    this.walletData.balance = this.convertWalletToRial
  },
  methods: {
    openAccountDetails(account) {
      this.dialogStatus = true
      this.isShowAccountDetailsDialog = true
      this.selectedAccount = account
    },
    balanceAmountWithThousandSeparator(balanceAmount) {
      const convertedAmount =
        this.reuseConvertCurrencyForRialAmount(balanceAmount)
      return this.$options.filters.thousandSeprator(convertedAmount)
    },
    async getLastBalance(selectedAccount) {
      try {
        selectedAccount.loading = true

        const response = await servicesRepository(
          this
        ).settlementServices.httpGetLastBalance(selectedAccount.iban)

        const accountIndex = this.$store.state.accountsList.findIndex(
          (item) => item.account === selectedAccount.account
        )
        if (accountIndex !== -1) {
          const updatedAccount = response.data.data
          const list = [...this.$store.state.accountsList]
          list[accountIndex] = updatedAccount
          this.$store.commit(
            "setTheMostUpdatedTime",
            updatedAccount.balance_updated_at
          )
          this.$store.commit("callSuccessSnackbar", response.data.message)
          this.$store.commit("setAccountsList", list)
        }
      } catch (error) {
        this.isShowUpdateAccountErrorModal = true
      } finally {
        selectedAccount.loading = false
      }
    },
    openWalletDetails() {
      this.dialogStatus = true
      this.isShowWalletDetailsDialog = true
    },
    changeAccountDetailsSecondModal(state) {
      this.$store.commit("changeAccountDetailsSecondModal", state)
    },
    closeModals() {
      this.dialogStatus = false
      this.isShowAccountDetailsDialog = false
      this.isShowWalletDetailsDialog = false
      this.changeAccountDetailsSecondModal(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.accounts-list-wrapper {
  margin-top: 80px;
}

.account-row-wrapper {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}
</style>
