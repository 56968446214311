<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">اصلاح شماره شناسنامه</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          :disabled="!valid"
          :loading="loading"
          class="btn_small_normal"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex>
        <v-form ref="form" v-model="valid">
          <div v-if="$store.state.isUser" class="font_2">
            شماره شناسنامه خود را وارد کنید.
          </div>
          <div v-else class="font_2">
            شماره شناسنامه مالک کسب‌وکار را وارد کنید.
          </div>
          <v-divider class="mb-7" style="border-style: dashed" />
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <input-container label="شماره شناسنامه" :optional="false">
              <template>
                <v-flex class="withlabel big">
                  <v-text-field
                    ref="birthcertificateNumber"
                    v-model="birthcertificateNumber"
                    solo
                    flat
                    height="40"
                    outlined
                    :rules="birthcertificateNumberRules"
                  >
                    <template #message="message">
                      <div v-html="message.message"></div>
                    </template>
                  </v-text-field>
                </v-flex>
              </template>
            </input-container>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import inputContainer from "../elements/inputContainer.vue"

export default {
  name: "AddBirthcertificateNumber",
  components: {
    inputContainer,
  },
  props: {
    isForShaparak: {
      default: false,
    },
  },
  data: function () {
    return {
      valid: false,
      loading: false,
      birthcertificateNumber: null,
      birthcertificateNumberRules: [
        (v) => !!v || "<span> وارد کردن شماره شناسنامه الزامی است</span>",
      ],
    }
  },
  watch: {
    birthcertificateNumber: function (newValue) {
      if (newValue) {
        this.birthcertificateNumber = this.convertNumbers2English(
          newValue
        ).replace(/[^0-9]/g, "")
        this.birthcertificateNumber = this.birthcertificateNumber.slice(0, 10)
      }
      this.$refs.birthcertificateNumber.lazyValue = this.birthcertificateNumber
    },
  },
  methods: {
    sendForm() {
      this.loading = true
      let data, url
      if (this.isForShaparak) {
        data = {
          birth_certificate_number: this.birthcertificateNumber, //new field
        }
        if (this.$store.state.isUser) {
          url =
            this.$store.getters.apiUrlParam + "/update/birth-certificate-number"
        } else {
          url =
            this.$store.getters.apiWithVersionParam("3") +
            "/update/business-owner-birth-certificate-number"
        }
      } else {
        url = this.$store.getters.apiUrlParam + "/ipg/update"
        data = {
          wage: this.$store.state.ipg.wage,
          url: this.$store.state.ipg.url,
          urls: this.$store.state.ipg.urls,
          ips: this.$store.state.ipg.ips,
          tax_code: this.$store.state.business.tax_code,
          avatar: this.$store.state.business.avatar,
          birth_certificate_number: this.birthcertificateNumber, //new field
        }
      }

      this.$http
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.commit(
            "callSuccessSnackbar",
            "شماره شناسنامه با موفقیت ثبت شد"
          )
          // this.$store.commit('updateUserOrBusinessValue', {
          //   key: 'birth_certificate_number',
          //   value: this.birthcertificateNumber,
          // })
          if (this.$store.state.isUser) {
            this.$store.dispatch(
              "retrieveUserObject",
              this.$store.state.business.business_name
            )
          } else {
            this.$store.dispatch(
              "retrieveBusinessObject",
              this.$store.state.business.business_name
            )
          }
          this.loading = false
          this.$emit("closeModal", {
            status: "checkBirthCertificateNumberAndMccChanged",
          })
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
  },
}
</script>
