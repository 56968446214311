<template>
  <v-flex>
    <v-layout
      align-center
      :class="item.tooltipInfo ? 'justify-start' : 'justify-space-between'"
    >
      <!-- link -->
      <v-flex v-if="item.clickable">
        <div class="linkBorder van_green--text ml-auto mr-0">
          <div>
            <a v-if="!item.clickAction" :href="item.value" target="_blank">{{
              item.value.slice(8)
            }}</a>
            <!-- open second modal-->
            <a
              v-else
              style="
                border-bottom: 1px dashed;
                text-decoration: none;
                margin-left: 3px;
                margin-right: 3px;
              "
              @click="$emit('openFormDetail', item.clickAction)"
              >{{ item.value }}</a
            >
          </div>
        </div>
      </v-flex>
      <!-- price -->
      <v-flex v-else-if="item.price">
        <template v-if="!$store.state.report.isWalletAffected">
          <span>بدون تغییر </span>
        </template>
        <template v-else>
          <span class="ml-1" dir="ltr">
            {{ item.value | currency }}
          </span>
          {{ currencyName }}
        </template>
      </v-flex>
      <!-- card -->
      <v-flex v-else-if="item.card">
        <span class="ml-1" dir="ltr">
          {{ item.value }}
        </span>
      </v-flex>
      <!-- other -->
      <div
        v-else
        class="d-flex"
        :style="
          item.bankImg
            ? 'max-width: fit-content; direction:ltr'
            : 'line-height:24px;'
        "
        v-html="item.value"
      >
        <!-- businessOwner -->
        <span v-if="item.businessOwner && item.value != item.businessOwner"
          >({{ item.businessOwner }})</span
        >
      </div>
      <!-- bank icon -->
      <v-flex v-if="item.bankImg" class="mr-2">
        <v-img :src="item.bankImg" height="20" size="20" width="20" />
      </v-flex>
      <!-- copy btn -->
      <copyBtn
        v-if="item.copyClick"
        :input-id="item.inputId"
        :value="item.value"
        label="کپی"
      />
      <!-- tooltip information -->
      <v-flex v-if="item.tooltipInfo" class="mr-2">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              size="20"
              style="direction: rtl; width: 20px"
              class="van_color03--text"
              v-on="on"
            >
              mdi_info_outline
            </v-icon>
          </template>
          <span class="font_33">
            {{ item.tooltipInfo }}
          </span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import copyBtn from "@/components/report/elements/show/details/copyBtn.vue"

export default {
  name: "GeneralDetail",
  components: { copyBtn },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.linkBorder {
  > div {
    max-width: 240px;
    height: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    direction: ltr;

    > a {
      text-decoration: none;
    }
  }
}

.copy {
  > .v-btn {
    min-width: 50px !important;
    margin: 0px !important;
    padding: 0;
    max-width: 80px;
    height: 32px !important;
  }
}
</style>
