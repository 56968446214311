<template>
  <div>
    <v-layout class="cards" justify-start wrap>
      <v-flex class="full-width full-height">
        <BankListCard @show-management="showManagement" />
      </v-flex>
    </v-layout>

    <v-dialog
      ref="banksList"
      v-model="bankManagementModal"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      transition="slide-x-transition"
    >
      <bank-management @close-modal="closeManagement" />
    </v-dialog>
  </div>
</template>

<script>
import BankManagement from "@/components/subscription/elements/BankManagement"
import BankListCard from "@/components/subscription/elements/BankListCard"

export default {
  name: "BankList",
  components: {
    BankListCard,
    BankManagement,
  },
  data() {
    return {
      bankManagementModal: false,
    }
  },
  mounted() {},
  methods: {
    showManagement() {
      this.bankManagementModal = true
    },
    closeManagement() {
      this.bankManagementModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.full-height {
  height: 100% !important;
}

.cards {
  margin-right: -5px;
  margin-left: -5px;

  > .flex {
    align-self: flex-end;
    height: 120px;
    width: 100%;
    margin: 5px 5px;
  }
}

@media (max-width: 1200px) {
  .layout {
    justify-content: center;
  }
}
</style>
