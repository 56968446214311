<template>
  <ChartCard class="mmt-5" :title="kerdarLang.overView.comparisoningOfIncome">
    <OneBarHorizontalBar
      v-if="averageStatus"
      :x-value="[
        kerdarLang.overView.lastDayIncome,
        createDynamicTextForAverageDaily(7),
        createDynamicTextForAverageDaily(30),
        createDynamicTextForAverageDaily(120),
        createDynamicTextForAverageDaily(365),
      ]"
      :y-value="calculateYaxisValueAverage()"
      :round-value="true"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </ChartCard>
</template>

<script>
import ChartCard from "@/components/kerdar/components/chartCard"
import OneBarHorizontalBar from "@/components/kerdar/components/oneBarHorizontalBar"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

export default {
  name: "AllServiceAverageIncomeChart",
  components: {
    ChartCard,
    OneBarHorizontalBar,
  },
  props: {
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      averageData: [],
      averageStatus: false,
    }
  },
  mounted() {
    this.getAverageData()
  },
  methods: {
    async getAverageData() {
      const response = await kerdarServices(
        this.$http,
        this
      ).overView.httpOverViewAverageIncome()

      const data = response.data.data
      this.averageData = data[0]
      this.averageStatus = true
    },
    createDynamicTextForAverageDaily(num) {
      const str = this.kerdarLang.overView.averageDaily
      return str?.replace("{number}", num)
    },
    calculateYaxisValueAverage() {
      return this.averageData.map((item) => {
        return this.$store.state.user.currency === "RIAL"
          ? parseInt(item)
          : parseInt(item / 10)
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
