<template>
  <div class="mb-10">
    <!--  no data  -->
    <empty-table
      v-if="isLoaded && checkoutList && checkoutList.length === 0"
      class="mt-20"
    />

    <template v-else>
      <!--  data list  -->
      <div class="mb-5 font_9 mt-10">صورتحساب‌ها</div>
      <template v-if="isLoaded">
        <table-row
          v-for="(row, index) in checkoutList"
          :key="row + index"
          :checkout-item="row"
          @click-row="showCheckout(row.checkout_id)"
        />
      </template>
      <!-- load more observer -->
      <observer v-if="isLoaded && !endOfLoadMore" @intersect="getData(true)" />

      <!-- table skeleton   -->
      <div v-if="!isLoaded || (loadMoreSkeleton && !endOfLoadMore)">
        <table-skeleton v-for="index in 3" :key="index" />
      </div>
    </template>
    <!-- modals -->
    <v-dialog
      v-model="showCheckoutModal"
      :content-class="
        $store.state.secondEditCustomerModal
          ? 'secondModal vanmodal'
          : 'vanmodal'
      "
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      transition="slide-x-transition"
    >
      <show-checkout
        v-if="selectedCheckoutId"
        :key="`show-${selectedCheckoutId}`"
        :item-id="selectedCheckoutId"
        @closeModal="closeCheckout"
      />
    </v-dialog>
  </div>
</template>
<script>
import observer from "@/components/elements/observer.vue"
import emptyTable from "../elements/emptyTable.vue"
import tableRow from "../elements/tableRow.vue"
import TableSkeleton from "../elements/tableSkeleton"
import ShowCheckout from "@/components/pbv/modals/showCheckout"

export default {
  name: "VandarTable",
  components: {
    ShowCheckout,
    TableSkeleton,
    observer,
    tableRow,
    emptyTable,
  },
  props: ["update", "dontLet"],
  data() {
    return {
      currentPage: 1,
      loading: false,
      endOfLoadMore: false,
      emptySearchResult: false,
      perPage: 10,
      lastPage: 0,
      loadMoreSkeleton: false,
      checkoutList: [],
      isLoaded: false,

      selectedCheckoutId: null,
      //modal
      showCheckoutModal: false,
    }
  },
  watch: {},
  mounted() {
    this.getData(false)
  },
  methods: {
    callCheckoutApi(per_page, current_page) {
      this.$http
        .get(
          this.$store.state.api_pbv_url +
            "/user/checkouts?per_page=" +
            per_page +
            "&page=" +
            current_page,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          if (this.lastPage === 0)
            this.lastPage = response?.data?.meta?.last_page

          if (this.currentPage >= this.lastPage) {
            this.endOfLoadMore = true
          }

          this.isLoaded = true
          if (response?.data?.data?.length !== 0)
            response?.data?.data.forEach((arr) => this.checkoutList.push(arr))
        })
    },
    getData(loadMore) {
      // get nth n(per page) data when load more...
      if (loadMore && !this.endOfLoadMore) {
        this.loadMoreSkeleton = true
        this.currentPage++
        this.callCheckoutApi(this.perPage, this.currentPage)
      }
      // get first n data
      else {
        this.currentPage = 1
        this.loading = false
        this.callCheckoutApi(this.perPage, this.currentPage)
      }
    },

    showCheckout(checkoutId) {
      this.selectedCheckoutId = checkoutId
      this.showCheckoutModal = true
    },
    closeCheckout() {
      this.showCheckoutModal = false
    },
  },
}
</script>
