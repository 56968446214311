<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    :fill="fillColor"
  >
    <path
      class="a"
      d="M12.529,4.882v5.647h1.1l-2.042,2.042L9.546,10.529h1.1V4.882h1.882M14.412,3H8.765V8.647H5l6.588,6.588,6.588-6.588H14.412V3m3.765,14.118H5V19H18.176Z"
      transform="translate(-5 -3)"
    />
  </svg>
</template>
<script>
export default {
  name: "DownloadOutline",
  props: {
    fillColor: {
      type: String,
      default: "#666",
    },
  },
}
</script>
