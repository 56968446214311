<template>
  <!-- modals -->
  <v-dialog
    v-model="localStatus"
    width="360"
    content-class="confirmationMessage height316"
    no-click-animation
    persistent
  >
    <v-card outlined height="100%">
      <v-layout column justify-center align-center>
        <v-flex class="main-title font_1 mt-0">درخواست برداشت لغو شود؟</v-flex>
        <v-flex class="main-des font_4"
          >در صورت لغو، مبلغ به موجودی کیف پول اضافه می‌شود</v-flex
        >
        <v-flex class="background-gray">
          <v-textarea
            v-model="note"
            solo
            flat
            rows="3"
            outlined
            no-resize
            placeholder="اگر یادداشتی برای لغو این برداشت دارید..."
            style="width: 100%"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-textarea>
        </v-flex>
        <v-flex>
          <v-btn
            text
            dark
            class="btn_small_normal error"
            :loading="loading"
            @click="cancelSettlement()"
          >
            بله، لغو شود
          </v-btn>
          <v-btn
            text
            depressed
            outlined
            class="btn_small_normal"
            @click="closeModal()"
          >
            خیر، منصرف شدم
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CacelSettlement",
  props: ["status", "transId"],
  data() {
    return {
      loading: false,
      note: null,
      localStatus: false,
    }
  },
  watch: {
    status: {
      handler: function (newValue) {
        if (newValue) {
          this.localStatus = newValue
        }
      },
      deep: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
      this.localStatus = false
    },
    cancelSettlement() {
      if (this.transId != "undefined" && this.transId != null) {
        this.loading = true
        this.$http
          .post(
            this.$store.getters.apiWithVersionParam("2.1") +
              "/settlement/" +
              this.transId,
            {
              comment: this.note,
            },
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.loading = false
            //update tables and top board
            this.$store.commit("updateUpdate", true)
            this.closeModal()
          })
          .catch((error) => {
            this.loading = false
            this.closeModal()
            //callsnackbar
          })
      }
    },
  },
}
</script>
