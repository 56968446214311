<template>
  <LeftModal
    toolbar-name="واریز بانکی به‌ صورت دستی"
    @closeModal="$emit('closeModal')"
  >
    <div slot="toolbarItems">
      <v-btn
        ref="btn"
        text
        dark
        :disabled="!disable"
        class="btn_small_normal"
        @click="activeApprove()"
      >
        ثبت درخواست
      </v-btn>
    </div>
    <v-card>
      <v-layout>
        <v-flex>
          <v-form ref="form" v-model="valid">
            <IbanSearch
              label="شماره شبا"
              :loading="loading"
              @selected-iban-array="ibanSelect = $event"
            />
            <NumberInput
              v-if="ibanSelect && ibanSelect.length != 0"
              label="مبلغ"
              :loading="loading"
              :wage="wage"
              :total-amount="totalAmount"
              :available-balance="availableBalance"
              :minimum-amount="minimumAmount"
              :is-show-bank-warning="isShowBankWarning"
              @mablaghSet="mablagh = $event"
            />
            <div
              v-if="ibanSelect && isShowBankWarning"
              class="font-11-no-333-17 bank-warning"
            >
              <span>
                در حال حاضر امکان دریافت موجودی امکان پذیر نیست اما امکان ثبت
                درخواست را دارید.
              </span>
            </div>
            <v-layout
              v-if="
                (totalAmount && ibanSelect && ibanSelect.length !== 0) ||
                isShowBankWarning
              "
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
            >
              <v-flex class="label"> یادداشت محرمانه </v-flex>
              <v-flex class="withlabel big">
                <v-textarea
                  v-model="comment"
                  solo
                  flat
                  rows="3"
                  :rules="[
                    (v) =>
                      !v ||
                      v.length < 255 ||
                      '<span>یادداشت محرمانه باید حداکثر ۲۵۵ کاراکتر باشد</span>',
                  ]"
                  outlined
                  :hint="commentHint"
                  :disabled="loading"
                >
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>

      <!--    open approve dialog-->
      <GeneralDialog
        v-if="approveCashIn"
        :active="approveCashIn"
        logo="newIcon/information.svg"
        content-class="confirmationMessageDialog"
        title="از واریز بانکی مطمئنید؟"
        :width="360"
        btn1-text="انجام شود"
        :btn1-loading="loading"
        btn2-text="ویرایش می‌کنم"
        @btn1Clicked="sendForm()"
        @btn2Clicked="approveCashIn = false"
      >
        <template #extra>
          <div
            class="font_2 mt-5 pa-5"
            style="align-items: center; width: 100%; background-color: #f5f5f5"
          >
            <v-layout align-center>
              <v-flex class="font_8"> مبلغ </v-flex>
              <v-flex style="text-align: left">
                {{ thousandSeprator }} {{ currencyName }}
              </v-flex>
            </v-layout>
            <hr class="my-2" style="border: 1px dashed #bbbbbb; width: 100%" />
            <v-layout align-center>
              <v-flex class="font_8"> شماره شبا </v-flex>
              <v-flex style="text-align: left">
                {{ ibanSelect[0] }}
              </v-flex>
            </v-layout>
            <hr class="my-2" style="border: 1px dashed #bbbbbb; width: 100%" />
            <v-layout align-center>
              <v-flex class="font_8"> کارمزد </v-flex>
              <v-flex style="text-align: left">
                {{ wage | currency }} {{ currencyName }}
              </v-flex>
            </v-layout>
          </div>
        </template>
      </GeneralDialog>
    </v-card>
  </LeftModal>
</template>

<script>
import { v4 as uuidv4 } from "uuid"
import IbanSearch from "@/components/report/modals/addCashIn/ibanSearch.vue"
import NumberInput from "@/components/report/modals/addCashIn/numberInput.vue"
import GeneralDialog from "@/components/modals/generalDialog"
import LeftModal from "@/components/modals/leftModal"
import servicesRepository from "@/services/servicesRepository"

function formatAsCurrency(value, dec) {
  dec = dec || 0
  if (value === null) {
    return null
  }
  return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}
export default {
  name: "AddCashIn",
  components: {
    GeneralDialog,
    IbanSearch,
    NumberInput,
    LeftModal,
  },
  props: [],
  data() {
    return {
      approveCashIn: false,
      loading: false,
      valid: false,
      mablagh: null, //v-model of mablagh
      comment: "",
      ibanSelect: "",
      amount: 0,
      totalAmount: null,
      availableBalance: null,
      input: "",
      wage: null,
      //settlement date just for input amount
      trackId: null,
      commentHint:
        '<div class="d-flex align-center"><img style="max-width:10px;margin-left:5px" src="' +
        require("@/assets/img/info.svg") +
        '" >متنی که فقط برای شما قابل مشاهده است</div>',
      disable: false,
      isShowBankWarning: false,
    }
  },
  computed: {
    minimumAmount() {
      if (this.$store.state.user.currency === "RIAL") return 10000
      else return 1000
    },
    amountInWord: {
      get: function () {
        var convert
        if (this.mablagh == null) {
          convert = "0"
        } else {
          convert = this.mablagh
        }
        return wordifyfa(convert)
      },
    },
    thousandSeprator: {
      get: function () {
        return formatAsCurrency(this.mablagh, 0)
      },
      set: function (newValue) {
        if (newValue) {
          this.mablagh = Number(
            this.convertNumbers2English(newValue).replace(/[^0-9.]/g, "")
          )
        } else {
          this.mablagh = null
        }
      },
    },
  },
  watch: {
    mablagh(newValue, oldValue) {
      if (
        this.mablagh &&
        this.mablagh >= this.minimumAmount &&
        this.convertToToman(this.mablagh) <= this.totalAmount
      ) {
        this.getWage()
      } else {
        this.wage = null
      }
      this.changeButtonDisableState()
    },
    ibanSelect(newValue, oldValue) {
      if (newValue !== null) {
        //erase last prediction
        this.mablagh = null
        this.totalAmount = null
        this.availableBalance = null
        this.getBalance()
      } else {
        this.mablagh = null
        this.totalAmount = null
        this.availableBalance = null
      }
    },
    valid() {
      this.changeButtonDisableState()
    },
  },
  mounted() {
    this.trackId = uuidv4()
  },
  methods: {
    activeApprove() {
      if (this.$refs.form.validate()) this.approveCashIn = true
    },
    mablaghSet(value) {
      this.mablagh = value
    },
    convertToRial(value) {
      if (this.$store.state.user.currency !== "RIAL") {
        value = value * 10
        return value
      } else return value
    },
    convertToToman(value) {
      if (this.$store.state.user.currency === "RIAL") {
        value = Math.floor(value / 10)
        return value
      } else return value
    },
    getBalance() {
      this.loading = true
      if (this.ibanSelect[0] !== null) {
        const payload = { iban: this.ibanSelect[0] }
        servicesRepository(this)
          .cashIn.httpCashInAccountBalance(payload)
          .then((response) => {
            this.loading = false
            this.totalAmount = parseInt(response.data.balance) / 10
            this.availableBalance =
              parseInt(response.data.available_balance) / 10

            if (this.$store.state.user.currency !== "RIAL") {
              this.availableBalance = parseInt(this.availableBalance)
              this.totalAmount = parseInt(this.totalAmount)
            }
            this.isShowBankWarning = false
          })
          .catch(() => {
            this.isShowBankWarning = true
            this.loading = false
          })
      }
    },
    getWage() {
      this.wage = null
      if (this.mablagh !== null && this.mablagh >= this.minimumAmount) {
        var data = {
          amount: this.convertToRial(this.mablagh),
        }
        this.$http
          .post(
            this.$store.getters.apiWithVersionParam("3") +
              "/cash-in/account/deposit-wage",
            data
          )
          .then((response) => {
            this.wage = parseInt(response.data.wage) / 10
          })
          .catch((error) => {})
      } else {
        this.wage = null
      }
    },
    sendForm() {
      if (this.$refs.form.validate()) {
        this.loading = true

        let data = {
          amount: this.convertToRial(this.mablagh),
          iban: this.ibanSelect[0],
          comment: this.comment,
          track_id: this.trackId,
        }
        servicesRepository(this)
          .cashIn.httpCashInAccountDeposit(data)
          .then((response) => {
            this.$emit("closeModal", {
              status: true,
              receiptUrl:
                response.data.data.cash_in &&
                response.data.data.cash_in.length === 1
                  ? response.data.data.cash_in[0].receipt_url
                  : null,
            })
            this.approveCashIn = false
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
          })
      }
    },
    changeButtonDisableState() {
      this.disable =
        !this.loading &&
        this.valid &&
        this.mablagh !== null &&
        this.mablagh >= this.minimumAmount &&
        this.ibanSelect &&
        this.ibanSelect.length !== 0
    },
  },
}
</script>
<style scoped>
.v-dialog.vanmodal .v-card > .layout:first-of-type {
  padding-top: 0;
}
.v-dialog.vanmodal .v-card > .layout > .flex {
  margin: 0;
}

.bank-warning {
  background: #fef6dd !important;
  width: 352px;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 97px 8px 0;
  display: flex;
  justify-content: flex-start;
}

@media only screen and (max-width: 600px) {
  .bank-warning {
    margin: 8px 0;
    width: 100%;
  }
}
</style>
