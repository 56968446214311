<template>
  <div class="whats_new_content">
    <div v-if="items.length > 0">
      <v-layout v-for="item in items" :key="item.id" column>
        <v-flex class="font_10">
          {{ persianDate(item.whats_new.publish_date) }}
        </v-flex>
        <v-flex class="font_5 bold">
          {{ item.whats_new.title }}
        </v-flex>
        <v-flex class="font_10" v-html="item.whats_new.content" />
        <v-flex v-if="item.whats_new.video">
          <video width="100%" controls>
            <source :src="item.whats_new.video" />
            مرورگر شما پخش ویدیو را پشتیبانی نمی‌کند.
          </video>
        </v-flex>

        <v-flex v-if="item.whats_new.image">
          <img width="100%" :src="item.whats_new.image" />
        </v-flex>
      </v-layout>
      <!-- load more -->
      <observer v-if="!endOfLoadMore" @intersect="getWhatsNew('loadMore')" />
    </div>
    <div v-if="items.length == 0 || moreSkeleton">
      <v-layout v-for="item in 1" :key="item" column>
        <v-flex class="font_10">
          <span style="width: 20%" class="default_gray" />
        </v-flex>
        <v-flex class="font_5 bold">
          <span style="width: 30%" class="default_gray" />
        </v-flex>
        <v-flex class="font_10">
          <div style="width: 100%" class="default_gray" />
          <div style="width: 100%" class="default_gray" />
          <div style="width: 90%" class="default_gray" />
          <div style="width: 85%" class="default_gray" />
        </v-flex>
        <v-flex>
          <div style="width: 100%; height: 180px" class="default_gray" />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import Observer from "./observer"

export default {
  name: "WhatsNewContent",
  components: { Observer },
  data() {
    return {
      items: [],
      page: 1,
      endOfLoadMore: false,
      moreSkeleton: false,
    }
  },
  mounted() {
    this.getWhatsNew()
  },
  methods: {
    persianDate(item) {
      const timestamp = parseInt(item) * 1000
      let jmoment = require("moment-jalaali")
      jmoment.loadPersian({ dialect: "persian-modern" })

      return jmoment(timestamp).locale("fa").format("jD jMMMM jYYYY")
    },
    getWhatsNew(kind) {
      if (kind == "loadMore") {
        this.page += 1
        this.moreSkeleton = true
      }
      this.$http
        .get(
          this.$store.state.api_base_url +
            "/personal/notifications/dashboard-whats-new?page=" +
            this.page,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.moreSkeleton = false
          this.page = response.data.current_page
          this.items = this.items.concat(response.data.data)
          if (response.data.last_page == this.page) {
            this.endOfLoadMore = true
          }
        })
        .catch((error) => {
          this.moreSkeleton = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.whats_new_content {
  .layout {
    padding-bottom: 24px;
    &:not(:last-child) {
      border-bottom: 1px solid #d5d5d5;
    }
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
}
</style>
