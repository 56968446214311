const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpCreateBusiness(payload) {
    return context.$http.post(
      `${context.$store.state.api_base_url}/business/store`,
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
})
