<template>
  <v-card class="singleField">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">تعیین وضعیت تراکنش‌ها</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <div class="parent">
      <v-switch
        v-model="changeStatus"
        class="van-switch font-size-12 van_color02--text bold mr-0"
        color="van_green"
        label="تعیین وضعیت تراکنش‌های مشکوک شناسه واریز"
        hide-details="auto"
      />
      <div class="font-size-11 van_color03--text mt-1 mr-12">
        با فعال کردن این قابلیت، می‌توانید تراکنش‌های واریز شناسه‌داری که با
        شماره شبای تایید‌نشده توسط مشتری ثبت شده‌اند را قبل از واریز به کیف
        پول‌، از طریق «میز‌کار» تعیین وضعیت کنید.
        <br />
        <br />
        با فعال شدن این قابلیت، برای تعیین وضعیت هر تراکنش واریزی با شبای تایید
        نشده، تنها ۴۸ ساعت از زمان ایجاد آن فرصت دارید. بعد از این مدت زمان و یا
        در صورت رد تراکنش، مبلغ با کسر کارمزد بانک از محل پرداخت مشتری، به حساب
        مبدا بازگردانده می‌شود.
      </div>

      <general-dialog
        :active="showActiveDialog"
        content-class="confirmationMessageDialog"
        logo="1stCheck.svg"
        title="«تعیین وضعیت تراکنش‌های مشکوک» را فعال می کنید؟"
        description="با فعال‌سازی این قابلیت، برای تعیین وضعیت هر تراکنش تنها ۴۸ ساعت از زمان ایجاد آن فرصت دارید.
بعد از این مدت زمان و یا در صورت رد تراکنش، مبلغ با کسر کارمزد بانک از محل پرداخت مشتری، به حساب مبدا بازگردانده می‌شود."
        btn1-text="فعال‌سازی"
        btn2-text="منصرف شدم"
        :btn1-loading="loading"
        @btn1Clicked="setStatus('ACTIVE')"
        @btn2Clicked="
          showActiveDialog = false
          changeStatus = false
        "
      />

      <general-dialog
        :active="showInActiveDialog"
        content-class="confirmationMessageDialog"
        logo="Failed.svg"
        title="«تعیین وضعیت تراکنش‌های مشکوک» را غیر‌فعال می کنید؟"
        description="با غیرفعال کردن این قابلیت، مبالغ تراکنش‌های واریز شناسه‌داری که با شماره شبای تایید‌نشده پرداخت شده‌اند، به‌طور خودکار به کیف پول شما واریز می‌شوند."
        btn1-text="غیرفعال‌سازی"
        btn2-text="منصرف شدم"
        btn1-class="btn_small_normal error"
        :btn1-loading="loading"
        @btn1Clicked="setStatus('INACTIVE')"
        @btn2Clicked="
          showInActiveDialog = false
          changeStatus = true
        "
      />
    </div>
  </v-card>
</template>

<script>
import GeneralDialog from "@/components/modals/generalDialog.vue"

export default {
  name: "SuspiciousActivation",
  components: { GeneralDialog },
  data() {
    return {
      changeStatus: null,
      showActiveDialog: false,
      showInActiveDialog: false,
      stopShowDialog: false,
      loading: false,
    }
  },
  watch: {
    changeStatus(newValue, oldValue) {
      if (oldValue !== null) {
        if (!this.stopShowDialog) {
          if (newValue) {
            this.showActiveDialog = true
            this.stopShowDialog = true
          } else if (!newValue) {
            this.showInActiveDialog = true
            this.stopShowDialog = true
          }
        } else {
          this.stopShowDialog = false
        }
      }
    },
  },
  mounted() {
    this.getSuspiciousStatusFromStore()
  },
  methods: {
    getSuspiciousStatusFromStore() {
      this.stopShowDialog = true
      this.changeStatus = this.$store.state.business?.pic_suspicious_check
      this.stopShowDialog = false
    },
    setStatus(status) {
      this.loading = true
      this.$http
        .post(
          this.$store.getters.apiUrlParamV3 +
            "/cash-in/suspicious-payment/change-status",
          { status: status }
        )
        .then((response) => {
          this.loading = false
          this.$store.commit("changePicSuspicious", status === "ACTIVE")
          this.showActiveDialog = false
          this.showInActiveDialog = false
          this.stopShowDialog = false
          this.$store.commit("callSuccessSnackbar", response.data.message)
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.parent {
  padding: 92px 32px;
}
</style>
