<template>
  <v-card flat class="pa-6" :class="{ 'pa-8': $vuetify.breakpoint.smAndUp }">
    <v-flex
      v-if="title"
      class="font_9 mb-2"
      :class="{ 'd-none': !$vuetify.breakpoint.xsOnly }"
    >
      {{ title }}
    </v-flex>

    <v-layout class="align-center mmb-12">
      <v-flex
        v-if="title"
        class="font_9"
        :class="{ 'd-none': $vuetify.breakpoint.xsOnly }"
      >
        {{ title }}
      </v-flex>
      <v-spacer :class="{ 'd-none': $vuetify.breakpoint.xsOnly }"></v-spacer>
      <v-flex
        v-if="growth && selectedDuration"
        style="max-width: 240px; width: fit-content"
        class="mmx-4"
      >
        <div class="insight-text-box-wrapper">
          <div
            :style="{ background: determineColorStatus() }"
            class="insight-text-box font_2 d-flex align-center"
          >
            <div>
              <span v-if="growth >= 0" class="bold van_green--text">
                <span v-if="growth > 500">
                  {{ kerdarLang.general.growMoreThan500Percent }}
                </span>
                <span v-else>
                  ٪{{ growth }} {{ kerdarLang.general.growth }}
                </span>
              </span>
              <span v-else class="bold van_red--text">
                ٪{{ Math.abs(growth) }} {{ kerdarLang.general.decrease }}
              </span>
              <span>
                {{ kerdarLang.general.fromTheBegining }}
                {{ selectedDurationName }}
                {{
                  isCurrentDuration
                    ? kerdarLang.general.untlNow
                    : kerdarLang.general.untilEnd
                }}
              </span>
            </div>
            <div
              class="triangle-badge"
              :style="{ borderBottom: `12px solid ${determineColorStatus()}` }"
            ></div>
          </div>
        </div>
      </v-flex>
      <v-flex v-if="showFilter" style="max-width: fit-content">
        <v-layout style="width: fit-content" class="mx-0 responsive-320">
          <v-flex>
            <v-select
              v-model="selectedDuration"
              :items="customDuration"
              item-text="name"
              solo
              flat
              height="40"
              outlined
              return-object
              style="width: 136px"
              :menu-props="{ contentClass: 'withActiveStyle' }"
              @change="setTimePeriod()"
            >
            </v-select>
          </v-flex>
          <v-flex class="select-margin">
            <v-select
              v-model="timePeriodSelected"
              :items="timePeriod"
              item-text="name"
              solo
              flat
              height="40"
              outlined
              return-object
              style="width: 136px"
              :menu-props="{ contentClass: 'withActiveStyle' }"
              @change="emitSelectedFilters()"
            >
            </v-select>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <slot></slot>
  </v-card>
</template>
<script>
import kerdarLang from "@/components/kerdar/utils/langResource.json"

export default {
  name: "ChartCardWrapper",
  props: {
    title: {
      type: String,
      default: "",
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    growth: {
      type: Number,
      default: 0,
    },
    selectedCustomDuration: {
      type: Object,
      default: () => {},
    },
    customDuration: {
      type: Array,
      default: () => [
        {
          name: "روز جاری",
          value: "current_day",
        },
        {
          name: "هفته جاری",
          value: "current_week",
        },
        {
          name: "ماه جاری",
          value: "current_month",
        },
        {
          name: "فصل جاری",
          value: "current_quarter",
        },
        {
          name: "سال جاری",
          value: "current_year",
        },
        {
          name: "دیروز",
          value: "last_day",
        },
        {
          name: "هفته گذشته",
          value: "last_week",
        },
        {
          name: "ماه گذشته",
          value: "last_month",
        },
        {
          name: "فصل گذشته",
          value: "last_quarter",
        },
        {
          name: "سال گذشته",
          value: "last_year",
        },
      ],
    },
    importedTimeListForYear: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedDuration: null,
      timePeriodSelected: null,
      kerdarLang,
    }
  },
  computed: {
    selectedDurationName() {
      return this.selectedDuration ? this.selectedDuration.name : ""
    },
    timePeriod() {
      const timeListForDay = [
        {
          name: "براساس ساعت",
          value: "hourly",
        },
      ]
      const timeListForWeek = [
        {
          name: "براساس روز",
          value: "daily",
        },
      ]
      const timeListForMonth = [
        {
          name: "براساس روز",
          value: "daily",
        },
        {
          name: "براساس هفته",
          value: "weekly",
        },
      ]

      const timeListForQuarter = [
        {
          name: "براساس روز",
          value: "daily",
        },
        {
          name: "براساس هفته",
          value: "weekly",
        },
        {
          name: "براساس ماه",
          value: "monthly",
        },
      ]

      const timeListForYear = [
        {
          name: "براساس هفته",
          value: "weekly",
        },
        {
          name: "براساس ماه",
          value: "monthly",
        },
      ]

      if (this.selectedDuration) {
        const secondPartOfSelectedDurationValue =
          this.selectedDuration.value.split("_")[1]

        if (secondPartOfSelectedDurationValue === "day") {
          return timeListForDay
        } else if (secondPartOfSelectedDurationValue === "week") {
          return timeListForWeek
        } else if (secondPartOfSelectedDurationValue === "month") {
          return timeListForMonth
        } else if (secondPartOfSelectedDurationValue === "quarter") {
          return timeListForQuarter
        } else {
          return this.importedTimeListForYear.length === 0
            ? timeListForYear
            : this.importedTimeListForYear
        }
      } else {
        return []
      }
    },
    isCurrentDuration() {
      if (this.selectedDuration) {
        const firstPartOfSelectedDurationValue =
          this.selectedDuration.value.split("_")[0]
        return firstPartOfSelectedDurationValue === "current" ? true : false
      }
    },
  },
  mounted() {
    this.setSelectedDuration()
    this.setTimePeriod()
  },
  methods: {
    emitSelectedFilters() {
      this.$emit("changeFilter", {
        duration: this.selectedDuration.value,
        timePeriod: this.timePeriodSelected.value,
      })
    },
    determineColorStatus() {
      if (this.growth > 0) {
        return "#E7EFED"
      } else if (this.growth === 0) {
        return "#F1F1F1"
      } else {
        return "#D44D421A"
      }
    },
    setTimePeriod() {
      if (this.timePeriod && this.timePeriod.length) {
        this.timePeriodSelected = this.timePeriod[0]
        this.emitSelectedFilters()
      }
    },
    setSelectedDuration() {
      if (!this.selectedCustomDuration?.name) {
        this.selectedDuration = { ...this.customDuration[0] }
      } else {
        this.selectedDuration = { ...this.selectedCustomDuration }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/components/kerdar/utils/sharedStyles/insight-box.scss";

@media screen and (max-width: 360px) {
  .responsive-320 {
    flex-direction: column;
    text-align: right !important;
  }

  .select-margin {
    margin-right: 0;
    margin-top: 8px;
  }
}

@media screen and (min-width: 360px) {
  .select-margin {
    margin-right: 8px;
  }
}
</style>
