<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">بررسی و اصلاح اینماد</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items v-if="selectedRadio != null" class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          :disabled="!valid"
          :class="btnClass"
          :loading="loading"
          @click="sendForm()"
        >
          {{ btnLabel }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout v-if="ipg != null" column justify-space-between>
      <v-flex class="font_2">
        <div class="text-justify">
          {{ detailsNote }}
        </div>

        <v-divider class="my-7" style="border-style: dashed" />

        <v-radio-group v-model="selectedRadio" class="simpleRadio">
          <v-radio
            v-for="item in itemWithCode"
            :key="item.value"
            color="van_color03"
            class="enamad_radio"
            :label="item.label"
            :value="item.value"
          />
        </v-radio-group>

        <div v-if="selectedRadio != null">
          <v-divider class="my-7" style="border-style: dashed" />

          <div v-if="selectedRadio == 1" class="van_color11 pa-5 ma-0 font_10">
            {{ actionOneNote }}
          </div>
          <v-form v-else v-model="valid">
            <v-layout
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
            >
              <v-flex class="label" style="width: 110px">
                آدرس وب‌سایت
                <span>*</span>
              </v-flex>
              <v-flex class="withlabel big">
                <v-text-field
                  v-model="url"
                  solo
                  flat
                  :rules="urlRule"
                  height="40"
                  outlined
                >
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "Enamad",
  data: function () {
    return {
      valid: false,
      loading: false,
      ipg: null,
      selectedRadio: null,
      url: null,
      urlRule: [(v) => !!v || ""],
      radioItem72: [
        {
          value: 1,
          label: "روی همین آدرس اینماد گرفتم، دوباره بررسی کنید.",
        },
        {
          value: 2,
          label: "آدرس جدیدی که برای آن اینماد گرفته‌ام را وارد می‌کنم.",
        },
      ],
      radioItem73: [
        {
          value: 1,
          label: "درخواست فعال‌سازی درگاه پرداخت لغو شود.",
        },
        {
          value: 2,
          label: "دامنه جدیدی که اینماد داره وارد می‌کنم.",
        },
      ],
      radioItem74: [
        {
          value: 1,
          label: "اینماد را روی این آدرس تمدید کردم، دوباره بررسی کنید.",
        },
        {
          value: 2,
          label: "آدرس جدیدی که برای آن اینماد گرفته‌ام را وارد می‌کنم",
        },
      ],
    }
  },
  computed: {
    btnClass() {
      if (
        this.$store.state.business.enamad_detail.code == 73 &&
        this.selectedRadio == 1
      )
        return "btn_small_normal error"
      return "btn_small_normal"
    },
    btnLabel() {
      if (this.selectedRadio == 1) {
        if (this.$store.state.business.enamad_detail.code == 73)
          return "لغو درخواست"
        return "بررسی"
      }
      return "ذخیره"
    },
    detailsNote() {
      switch (this.$store.state.business.enamad_detail.code) {
        case 72:
          return (
            "روی آدرس وب‌سایت " +
            this.ipg.url +
            " اینماد ثبت نشده است. دو راه برای بررسی و اصلاح آن وجود دارد. می‌توانید روی همین آدرس، اینماد بگیرید و دوباره درخواست خود را برای وندار ارسال  کنید. همچنین، می توانید برای آدرس جدیدی اینماد بگیرید که با مشخصات کسب‌و‌کار شما همخوانی دارد؛ سپس آن را اینجا ثبت کنید. "
          )
        case 73:
          if (this.$store.state.business.business_type == "legal") {
            return (
              "اینماد ثبت شده روی آدرس وب‌سایت " +
              this.ipg.url +
              " با کد شناسه ملی کسب‌و‌کار همخوانی ندارد. می‌توانید با مشخصات ثبت شده در وندار، اینماد بگیرید یا یک کسب‌و‌کار جدید با اطلاعات اینماد ثبت شده بسازید. همچنین، می توانید برای آدرس جدیدی اینماد بگیرید که با مشخصات کسب‌و‌کار شما همخوانی دارد؛ سپس آن را اینجا ثبت کنید."
            )
          } else {
            return (
              "اینماد ثبت شده روی آدرس وب‌سایت " +
              this.ipg.url +
              " با کد ملی صاحب کسب‌و‌کار همخوانی ندارد. می‌توانید با مشخصات ثبت شده در وندار، اینماد بگیرید یا یک کسب‌و‌کار جدید با اطلاعات اینماد ثبت شده بسازید. همچنین، می توانید برای آدرس جدیدی اینماد بگیرید که با مشخصات کسب‌و‌کار شما همخوانی دارد؛ سپس آن را اینجا ثبت کنید."
            )
          }
        case 74:
          return (
            "روی آدرس وب‌سایت " +
            this.ipg.url +
            " اینماد ثبت شده،منقضی شده است. دو راه برای بررسی و اصلاح آن وجود دارد. می‌توانید روی همین آدرس، اینماد را تمدید کنید و دوباره درخواست خود را برای وندار ارسال  کنید. همچنین، می توانید برای آدرس جدیدی اینماد بگیرید که با مشخصات کسب‌و‌کار شما همخوانی دارد؛ سپس آن را اینجا ثبت کنید."
          )
      }
    },
    actionOneNote() {
      switch (this.$store.state.business.enamad_detail.code) {
        case 72:
          return "اگر این گزینه را انتخاب و ثبت کنید، درخواست شما دوباره برای شاپرک ارسال می‌شود. از داشتن اینماد روی این آدرس وب‌سایت اطمینان حاصل کنید."
        case 73:
          return "درخواست فعالسازی درگاه پرداخت لغو می‌شود. در ادامه شما می‌توانید کسب ‌و‌کار جدید مطابق با مشخصات اینماد بسازید؛ سپس نسبت به فعال‌سازی درگاه پرداخت اقدام نمایید."
        case 74:
          return "اگر این گزینه را انتخاب و ثبت کنید، درخواست شما برای شاپرک ارسال می‌شود. از تمدید اینماد روی این آدرس وب‌سایت اطمینان حاصل کنید."
      }
    },
    itemWithCode() {
      return this["radioItem" + this.$store.state.business.enamad_detail.code]
    },
  },
  watch: {
    selectedRadio: function (newValue) {
      this.valid = false
      if (newValue == 1) {
        this.valid = true
      }
    },
  },
  mounted() {
    this.getIpgData()
  },
  methods: {
    sendForm() {
      this.loading = true
      if (
        this.$store.state.business.enamad_detail.code == 73 &&
        this.selectedRadio == 1
      ) {
        this.suspendIpg()
        return
      }
      var data = {
        url: this.ipg.url,
      }
      if (this.selectedRadio == 2) {
        data.url = this.url
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/ipg/update-enamad", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.dispatch(
            "retrieveBusinessObject",
            this.$store.state.business.business_name
          )
          this.$store.commit("callSuccessSnackbar", "تنظیمات با موفقیت ثبت شد")

          this.$emit("closeModal")
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          //callsnackbar
        })
    },
    getIpgData() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.$store.getters.apiWithVersionParam("3") + "/ipg/show", {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            this.ipg = response.data.data.ipg
            if (this.ipg != null) {
              this.$store.commit("addIpg", this.ipg)
            }
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    suspendIpg() {
      var data = {
        tools: "ipg",
        status: "INACTIVE",
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/tools/update", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.dispatch(
            "retrieveBusinessObject",
            this.$store.state.business.business_name
          )
          this.$emit("closeModal", { status: "newBusinessDialog" })
        })
        .catch((error) => {
          //callsnackbar
        })
    },
  },
}
</script>
