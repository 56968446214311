<template>
  <v-layout class="shebaList" column>
    <v-flex style="width: 100%">
      <v-layout align-center>
        <v-flex
          class="skeleton_box"
          style="
            max-width: 60px;
            width: 60px;
            height: 63px;
            margin: 0px 0px 0px 10px;
            border: 1px solid #eeeeee;
          "
        />
        <v-flex>
          <div class="font_2 bold" style="max-width: 50%">
            <span class="default_gray" />
          </div>
          <div class="font_2">
            <span class="default_gray" />
          </div>
        </v-flex>
        <v-flex style="max-width: 40px; margin-left: -10px">
          <v-menu
            transition="slide-y-transition"
            offset-y
            close-on-content-click
            bottom
            content-class="up_menu"
          >
            <!-- this on slot sometime is undefined -->
            <template #activator="{ on }">
              <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                <v-icon color="van_color03" size="21" style="line-height: 40px">
                  more_vert
                </v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-divider class="my-5 mx-0" style="border-color: #f5f5f5" />
      <v-layout align-center>
        <v-flex
          class="skeleton_box"
          style="
            max-width: 60px;
            width: 60px;
            height: 63px;
            margin: 0px 0px 0px 10px;
            border: 1px solid #eeeeee;
          "
        />
        <v-flex>
          <div class="font_2 bold" style="max-width: 50%">
            <span class="default_gray" />
          </div>
          <div class="font_2">
            <span class="default_gray" />
          </div>
        </v-flex>
        <v-flex style="max-width: 40px; margin-left: -10px">
          <v-menu
            transition="slide-y-transition"
            offset-y
            close-on-content-click
            bottom
            content-class="up_menu"
          >
            <!-- this on slot sometime is undefined -->
            <template #activator="{ on }">
              <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                <v-icon color="van_color03" size="21" style="line-height: 40px">
                  more_vert
                </v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "MandateListSkeleton",
}
</script>
