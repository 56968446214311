<template>
  <v-card v-if="vandarModal" outlined>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">به‌روز رسانی‌ها</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          outlined
          class="btn_small_normal"
          style="margin-left: 6px"
          @click="sendForm()"
        >
          همه را دیدم
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout>
      <v-flex style="text-align-last: right">
        <whats-new-content />
      </v-flex>
    </v-layout>
  </v-card>
  <v-dialog
    v-else
    v-model="dialog"
    width="580"
    content-class="height500"
    style="border-box: 4px"
  >
    <v-layout class="ma-0">
      <v-flex>
        <v-card outlined light>
          <v-toolbar class="whats_new_toolbar" flat height="70">
            <v-toolbar-title class="pr-0">
              <img src="@/assets/img/newIcon/whatsnewToolbar.svg" />
              <span class="title_whats_new_dialog font_9">
                به‌روز رسانی‌ها</span
              >
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items class="align-center">
              <v-btn
                ref="btn"
                text
                dark
                outlined
                class="btn_small_normal"
                @click="saveAction"
              >
                بعدا می خوانم </v-btn
              ><v-btn
                ref="btn"
                text
                dark
                class="btn_small_normal mr-2"
                @click="sendForm()"
              >
                خواندم
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <div class="whats_new_content_box px-8 pt-8">
            <whats-new-content />
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-dialog>
</template>

<script>
import WhatsNewContent from "./whatsNewContent"
export default {
  name: "WhatsNew",
  components: { WhatsNewContent },
  props: {
    vandarModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.showWhatsNewsDialog
      },
      set(newValue) {
        this.$store.commit("changeShowWhatsNewsDialog", newValue)
      },
    },
    haveNotSeenWhatsNew() {
      if (!this.$store.state.user.hasOwnProperty("actions")) return false
      let lastWhatsNewActions = this.$store.state.user.actions.find(
        (item) => item.action_name === "whats_new"
      )
      if (
        !lastWhatsNewActions &&
        this.$store.state.user.hasOwnProperty("is_whats_new_exists") &&
        this.$store.state.user.is_whats_new_exists
      )
        return true

      if (
        this.$store.state.user.hasOwnProperty("last_whats_new_time") &&
        this.$store.state.user.hasOwnProperty("is_whats_new_exists") &&
        this.$store.state.user.is_whats_new_exists &&
        lastWhatsNewActions &&
        parseInt(lastWhatsNewActions.action_time) <
          parseInt(this.$store.state.user.last_whats_new_time)
      )
        return true
      return false
    },
  },
  mounted() {
    const displayed = localStorage.getItem("whats_new_displayed")
    if (this.haveNotSeenWhatsNew && !this.vandarModal) {
      this.$store.commit("changeShowWhatsNewsDialog", true)
    } else {
      this.$store.commit("changeShowWhatsNewsDialog", false)
    }
  },
  methods: {
    saveAction() {
      this.$store.commit("changeShowWhatsNewsDialog", false)
      this.$store.dispatch("saveAction", { name: "whats_new" })
    },
    openDetails() {
      window.open(this.$store.state.whatsNew.link, "_blank")
    },
    sendForm() {
      this.$http
        .post(
          this.$store.state.api_base_url +
            "/personal/notifications/whats-new/read/all",
          null,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          localStorage.removeItem("whats_new_displayed")
          this.$store.commit("setUserData", {
            key: "is_whats_new_exists",
            value: false,
          })
          this.$emit("closeModal")
          this.$store.commit("changeShowWhatsNewsDialog", false)
        })
        .catch((error) => {
          //callsnackbar
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.title_whats_new_dialog {
  position: absolute;
  top: 22px;
  right: 65px;
}
.whats_new_content_box {
  max-height: 420px;
  overflow-y: scroll;
}

.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 60px;
  width: 250px;
  margin: auto;
}
.v-card {
  text-align: justify;
}
</style>
