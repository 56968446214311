import Vue from "vue"
import VueRouter from "vue-router"

//Authentication
import logout from "../components/auth/logout.vue"

//Dashboard
import Dashboard from "../components/dashboard/pages/index.vue"

//Homepage
import businessHome from "../components/businessHome/pages/index.vue"
import personalHome from "../components/personalHome/pages/index.vue"

//setting
import setting from "../components/pages/setting.vue"
import businessSetting from "../components/pages/businessSetting.vue"

//request money
import requestMoney from "../components/requestMoney/pages/index.vue"

//callback
import callback from "../components/pbv/callback.vue"

//report
import report from "../components/report/pages/index.vue"

//mpg
import mpg from "../components/mpg/pages/index.vue"

//pbv
import pbv from "../components/pbv/pages/index.vue"

//accountant
import accountant from "../components/accountant/pages/index.vue"

//invoice
import invoice from "../components/invoice/pages/index.vue"

//log
import log from "../components/log/pages/index.vue"

//kerdar
import kerdar from "../components/kerdar"

//tickets
import tickets from "../components/tickets"

//ipg
import ipg from "../components/ipg/pages/index.vue"

//subscription
import subscription from "../components/subscription/pages/index.vue"

//customer
import customer from "../components/customer/pages/index.vue"

//404
import pageNotFound from "../components/pages/pageNotFound.vue"

//tools
import tools from "../components/tools/pages/index.vue"

//checkouts
import checkouts from "../components/pbv/checkouts/pages/index.vue"

//p2p
import p2p from "../components/p2p/pages/index.vue"

//settlementBatch
import batchesSettlement from "../components/batchesSettlement/pages/index.vue"

//miando
import miando from "../components/miando/pages/index.vue"

import bankInvoice from "../components/BankInvoice/index.vue"
import OnBoardingComponent from "@/components/OnBoarding/OnBoardingComponent"
import OnboardingResult from "@/components/OnBoarding/elements/OnboardingResult"

//Fix Avoided redundant navigation to current location Error
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {})
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    //Authentication
    {
      path: "/enter",
      name: "mobileInputPage",
      props: { isChangePassPage: "" },
      meta: {
        requireVisitors: true,
      },
      beforeEnter(to, from, next) {
        window.location.href =
          process.env.VUE_APP_SSO_URL + "?callback=" + window.location.href
      },
    },
    {
      path: "/app-entries",
      name: "logOutEntries",
      props: { isChangePassPage: "" },
      meta: {
        requireVisitors: true,
      },
      beforeEnter(to, from, next) {
        window.location.href = process.env.VUE_APP_SSO_URL
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: logout,
    },
    //Dashboard
    {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requireAuth: true,
      },
    },
    //Homepage
    {
      path: "/personal",
      name: "personalHome",
      props: { approveMandate: "", callbackInfo: [], approvedMandateInfo: {} },
      component: personalHome,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/business/:name",
      name: "businessHome",
      props: {
        isFromCreatePage: "",
        openQueuedModal: false,
      },
      component: businessHome,
      meta: {
        requireAuth: true,
        requireHomePagePermission: true,
      },
    },
    //request money
    {
      path: "/personal/request_money",
      name: "requestMoney",
      component: requestMoney,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/business/:name/request_money",
      name: "businessRequestMoney",
      component: requestMoney,
      meta: {
        requireAuth: true,
        requireRequestMoneyPermission: true,
      },
    },
    //setting
    {
      path: "/personal/setting",
      name: "setting",
      component: setting,
      props: { filtered: "" },
      meta: {
        requireAuth: true,
      },
    },
    //checkouts
    {
      path: "/personal/checkouts",
      name: "checkouts",
      component: checkouts,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/business/:name/setting",
      name: "businessSetting",
      component: businessSetting,
      props: { filtered: "" },
      meta: {
        requireAuth: true,
        requireSettingPermission: true,
      },
    },
    {
      path: "/personal/setting/telegramChatId/:id",
      name: "settingTelegram",
      component: setting,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/business/:name/setting/telegramChatId/:id",
      name: "businessSettingTelegram",
      component: businessSetting,
      meta: {
        requireAuth: true,
        requireSettingPermission: true,
      },
    },
    // onboarding
    {
      path: "/onboarding",
      name: "Onboarding",
      component: OnBoardingComponent,
      meta: {
        requireAuth: true,
        template3: true,
      },
    },
    {
      path: "/onboarding-result",
      name: "OnboardingResult",
      component: OnboardingResult,
      meta: {
        requireAuth: true,
        template3: true,
      },
      beforeEnter(to, from, next) {
        if (from.name === "Onboarding") {
          next()
        } else {
          next({ name: "Onboarding" })
        }
      },
    },
    //report
    {
      path: "/personal/report",
      name: "report",
      component: report,
      props: { filtered: "", formId: "" },
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/business/:name/report",
      name: "businessReport",
      component: report,
      props: { filtered: "", formId: "" },
      meta: {
        requireAuth: true,
        requireReportPermission: true,
      },
    },
    //p2p
    {
      path: "/personal/p2p",
      name: "p2p",
      component: p2p,
      meta: {
        requireAuth: true,
      },
    },
    //pbv callback
    {
      path: "/personal/pbv/callback",
      name: "callback",
      component: callback,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/business/:name/p2p",
      name: "businessP2p",
      component: p2p,
      meta: {
        requireAuth: true,
        requirep2pPermission: true,
      },
    },
    //accountant
    {
      path: "/business/:name/pbv",
      name: "pbv",
      component: pbv,
      meta: {
        requireAuth: true,
      },
    },
    //accountant
    {
      path: "/business/:name/accountant",
      name: "accountant",
      component: accountant,
      meta: {
        requireAuth: true,
      },
    },
    //accountant
    {
      path: "/business/:name/mpg/",
      name: "mpg",
      component: mpg,
      meta: {
        requireAuth: true,
      },
    },
    //invoice
    {
      path: "/business/:name/invoice",
      name: "businessInvoice",
      component: invoice,
      props: { filtered: "" },
      meta: {
        requireAuth: true,
        requireInvoicePermission: true,
      },
    },
    //ipg
    {
      path: "/business/:name/ipg",
      name: "ipg",
      component: ipg,
      meta: {
        requireAuth: true,
        requireIpgPermission: true,
      },
    },
    //tools
    {
      path: "/business/:name/tools",
      name: "Businesstools",
      component: tools,
      meta: {
        requireAuth: true,
        requireToolsPermission: true,
      },
    },
    //subscription
    {
      path: "/business/:name/subscription",
      name: "subscription",
      component: subscription,
      meta: {
        requireAuth: true,
        requireSubscriptionPermission: true,
      },
    },
    //customer
    {
      path: "/business/:name/customer",
      name: "customer",
      component: customer,
      meta: {
        requireAuth: true,
        requireCustomerPermission: true,
      },
    },
    //log
    {
      path: "/business/:name/log",
      name: "log",
      component: log,
      meta: {
        requireAuth: true,
        requireLogPermission: true,
      },
    },
    //kerdar
    {
      path: "/business/:name/kerdar",
      name: "kerdar",
      component: kerdar,
      meta: {
        requireAuth: true,
        requireKerdarPermission: true,
      },
    },
    //tickets
    {
      path: "/business/:name/tickets",
      name: "tickets",
      component: tickets,
      meta: {
        requireAuth: true,
        requireTicketsPermission: true,
      },
    },
    //miando
    {
      path: "/business/:name/miando",
      name: "miando",
      component: miando,
      meta: {
        requireAuth: true,
        requireMiandoPermission: true,
      },
    },
    //bank-invoice
    {
      path: "/business/:name/bank-invoice",
      name: "bankInvoice",
      component: bankInvoice,
      meta: {
        requireAuth: true,
      },
    },
    //batchesSettlement
    {
      path: "/business/:name/batchesSettlement",
      name: "batchesSettlement",
      component: batchesSettlement,
      meta: {
        requireAuth: true,
        requireBatchesSettlementPermission: true,
      },
    },
    //emailStatus
    {
      path: "/personal/email/verify/status/:status",
      name: "emailVerifyCallback",
      component: personalHome,
      meta: {
        requireAuth: true,
      },
    },
    //payment callBacks
    {
      path: "/business/:name/payment/status/:status",
      name: "businessPaymentCallback",
      component: businessHome,
      meta: {
        requireAuth: true,
        a2aSnackbar: false,
        snackbarMessage: "",
        requireHomePagePermission: true,
      },
    },
    {
      path: "/business/:name/ipg/payment/status/:status",
      name: "businessIpgPaymentCallback",
      component: ipg,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/website",
      beforeEnter() {
        location.href = "https://vandar.io"
      },
    },
    // and finally the default route, when none of the above matches:
    {
      path: "/pageNotFound",
      component: pageNotFound,
      name: "pageNotFound",
      meta: {
        template2: true,
      },
    },
    {
      path: "*",
      redirect: "/pageNotFound",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

export default router
