<template>
  <div>
    <v-layout :align-center="!reuseIsMobile" :column="reuseIsMobile">
      <v-flex>
        <v-layout align-start justify-space-between>
          <v-flex>
            <h2 class="mb-4 mr-0 ml-auto">صورت‌حساب بانکی</h2>
          </v-flex>
          <v-spacer />
          <v-flex v-if="reuseIsMobile" class="text-left">
            <v-btn
              class="btn_small_normal white_btn mt-0 ml-0"
              dark
              depressed
              outlined
              text
              @click="downloadExcel"
            >
              <template v-if="isDownloadingExcel">
                <v-progress-circular
                  :class="!$vuetify.breakpoint.xsOnly ? 'ml-2' : ''"
                  :size="20"
                  :width="2"
                  color="van_color03"
                  indeterminate
                />
              </template>
              <template v-else>
                <v-img
                  :class="!$vuetify.breakpoint.xsOnly ? 'ml-2' : ''"
                  :src="require('@/assets/img/download-outline.svg')"
                  max-width="13px"
                />
              </template>
              <span v-if="!reuseIsMobile"> خروجی </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-spacer />

      <div>
        <v-layout align-start justify-space-between>
          <v-flex v-if="$vuetify.breakpoint.smAndUp">
            <v-btn
              class="btn_small_normal white_btn mt-0"
              dark
              depressed
              outlined
              text
              @click="downloadExcel"
            >
              <template v-if="isDownloadingExcel">
                <v-progress-circular
                  :class="!$vuetify.breakpoint.xsOnly ? 'ml-2' : ''"
                  :size="20"
                  :width="2"
                  color="van_color03"
                  indeterminate
                />
              </template>
              <template v-else>
                <v-img
                  :class="!$vuetify.breakpoint.xsOnly ? 'ml-2' : ''"
                  :src="require('@/assets/img/download-outline.svg')"
                  max-width="13px"
                />
              </template>
              <span v-if="!$vuetify.breakpoint.xsOnly"> خروجی </span>
            </v-btn>
          </v-flex>
          <v-flex class="search-input-with-type backWhite">
            <v-text-field
              ref="searchInput"
              v-model="computedSearchQuery"
              :prepend-inner-icon="
                computedSearchQuery != null ? 'close' : 'search'
              "
              height="36"
              flat
              hide-details
              outlined
              solo
              placeholder="جست‌وجو"
              class="search-query drop-down"
              @keydown="enterHandler($event)"
              @click:prepend-inner="clearSearch"
              @focusin="isShowSearchDropDown = true"
              @blur="checkForCloseDropDown"
            >
              <template #message="message">
                <div v-html="message.message" />
              </template>
            </v-text-field>
            <v-divider vertical color="#000" />
            <v-select
              v-model="computedSelectedSearchType"
              label="انتخاب کنید"
              :items="searchTypes"
              item-text="text"
              item-value="value"
              class="select-type"
              height="36"
              flat
              hide-details
              outlined
              solo
              :menu-props="{ offsetY: true }"
              disabled
            />
            <v-btn
              :disabled="isDisableSearchBtn"
              class="btn-search-inner"
              text
              @click="setFiltersAndGetData"
            >
              <v-icon class="white--text" size="20"> chevron_left </v-icon>
            </v-btn>
            <div
              v-if="isShowSearchDropDown"
              class="drop-down-search"
              style="height: auto"
            >
              <div
                class="d-flex align-center justify-center text-center van_color11 van_color04--text pa-5 drop-down ma-1 radius-4"
              >
                برای مشاهده نتایج، عبارت مورد نظرتان را وارد کرده و دسته‌بندی را
                انتخاب کنید.
              </div>
              <v-list-item-group
                v-model="computedSelectedSearchTypeIndex"
                class="select-menu"
              >
                <v-list-item
                  v-for="typeElement in searchTypes"
                  :key="typeElement.value"
                >
                  <v-list-item-content class="drop-down">
                    <v-list-item-title class="font_2 drop-down">
                      {{ typeElement.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </v-layout>

    <BankInvoiceTableFilter />
    <div v-if="areFiltersApplied || isSearchApplied">
      <v-layout
        :align-center="!$vuetify.breakpoint.smAndDown"
        :align-start="$vuetify.breakpoint.smAndDown"
        class="grayRow"
      >
        <v-flex
          v-if="bankInvoiceItems.length === 0 && !isShowSkeleton"
          class="font_2 py-1 px-5"
        >
          <span v-if="areFiltersApplied">
            {{ lang.general.noTransactionsWithThisFilter }}
          </span>
          <span v-else-if="isSearchApplied">
            {{ lang.general.noTransactionsWithThisSearch }}
          </span>
        </v-flex>

        <v-flex
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'display:flex;margin-right:auto;'
              : 'display:flex;'
          "
          align-end
          class="btns"
          justify-end
        >
          <v-btn
            v-if="isSearchApplied"
            class="btn_small_normal ml-1"
            dark
            depressed
            outlined
            style="font-size: 10px !important; padding: 0 10px"
            text
            @click="clearSearch"
          >
            <v-icon class="ml-2" color="van_color03" size="15"> close </v-icon>
            {{ lang.general.removeSearch }}
          </v-btn>

          <v-btn
            v-if="areFiltersApplied"
            class="btn_small_normal mr-1"
            dark
            depressed
            outlined
            style="font-size: 10px !important; padding: 0 10px"
            text
            @click="clearAppliedFilters"
          >
            <v-icon class="ml-2" color="van_color03" size="15"> close </v-icon>
            {{ lang.general.removeFilters }}
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
    <BankInvoiceDataTable />
  </div>
</template>

<script>
import screenSize from "@/mixins/screenSize.js"
import BankInvoiceTableFilter from "./BankInvoiceTableFilter.vue"
import BankInvoiceDataTable from "@/components/BankInvoice/DataTable.vue"
import { mapState, mapMutations } from "vuex"
import lang from "@/lang/languageResource.json"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "BankInvoice",
  components: {
    BankInvoiceTableFilter,
    BankInvoiceDataTable,
  },
  mixins: [screenSize],
  data() {
    return {
      localSearchQuery: null,
      isShowSearchDropDown: false,
      searchTypes: [
        { text: "شماره حساب", value: "ACCOUNT" },
        { text: "شماره شبا", value: "IBAN" },
      ],
      localSelectedSearchType: null,
      selectedSearchTypeIndex: null,
      isDisableSearchBtn: true,
      lang,
      isDownloadingExcel: false,
      timeOutId: null,
    }
  },
  computed: {
    ...mapState("bankInvoice", [
      "isSearchApplied",
      "areFiltersApplied",
      "bankInvoiceItems",
      "isShowSkeleton",
      "bankInvoiceUrl",
    ]),
    computedSearchQuery: {
      get() {
        return this.localSearchQuery
      },
      set(val) {
        this.localSearchQuery = val
        this.setSearchQuery(this.localSearchQuery)
        this.isDisableSearchBtn = !(
          this.localSearchQuery &&
          this.localSearchQuery.length > 2 &&
          this.computedSelectedSearchType
        )
      },
    },
    computedSelectedSearchType: {
      get() {
        return this.localSelectedSearchType
      },
      set(val) {
        this.localSelectedSearchType = val
        this.setSelectedSearchType(val)

        this.isDisableSearchBtn = !(
          val &&
          this.computedSearchQuery &&
          this.computedSearchQuery.length > 2
        )
      },
    },
    computedSelectedSearchTypeIndex: {
      get() {
        return this.selectedSearchTypeIndex
      },
      set(val) {
        if (val || val === 0) {
          this.computedSelectedSearchType = this.searchTypes[val].value
          if (this.computedSearchQuery) this.isShowSearchDropDown = false
        } else {
          this.computedSelectedSearchType = null
        }
      },
    },
  },
  beforeDestroy() {
    this.setTableFilters(false)
    this.resetBankInvoiceItems()
    this.clearSearch()
  },
  methods: {
    ...mapMutations("bankInvoice", [
      "toggleSearchAppliedStatus",
      "setSearchQuery",
      "setSelectedSearchType",
      "toggleShouldGetData",
      "setTableFilters",
      "resetBankInvoiceItems",
      "specifyBankInvoiceUrl",
    ]),
    checkForCloseDropDown() {
      window.onclick = function (e) {
        let classList = e.target.classList

        const condition =
          (classList && classList.contains("drop-down")) ||
          this.$refs.searchInput?.isFocused

        if (!condition) {
          this.isShowSearchDropDown = false
        }
      }.bind(this)
    },
    enterHandler(event) {
      if (
        event.key === "Enter" &&
        this.computedSearchQuery &&
        this.computedSearchQuery?.length > 2
      ) {
        this.setFiltersAndGetData()
      } else if (event.code === "Space") {
        event.preventDefault()
      }
    },
    clearSearch() {
      this.computedSearchQuery = null
      this.computedSelectedSearchType = null
      this.computedSelectedSearchTypeIndex = null
      if (this.isSearchApplied) {
        this.toggleSearchAppliedStatus(false)
        this.toggleShouldGetData(true)
      }
    },
    clearAppliedFilters() {
      this.setTableFilters(false)
      this.toggleShouldGetData(true)
    },
    setFiltersAndGetData() {
      if (
        this.computedSearchQuery &&
        this.computedSearchQuery?.length > 2 &&
        this.computedSelectedSearchType
      ) {
        this.toggleSearchAppliedStatus(true)
        this.toggleShouldGetData(true)
      }
    },
    async downloadExcel() {
      try {
        let link = document.createElement("a")
        link.download = "report.xlsx"

        clearTimeout(this.timeOutId)
        this.isDownloadingExcel = true
        this.specifyBankInvoiceUrl({
          page: 1,
          currencyName: this.currencyName,
        })
        const res = await servicesRepository(
          this
        ).bankInvoiceServices.httpGetExcel(this.bankInvoiceUrl)
        let blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })

        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href)
      } finally {
        this.timeOutId = setTimeout(
          () => (this.isDownloadingExcel = false),
          1000
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
