const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  authorization: "Bearer" + context.$store.state.token,
})

export default (context) => ({
  httpGetTerminalIban() {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/terminal/iban`,
      {
        headers: apiHeaders(context),
      }
    )
  },
})
