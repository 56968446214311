<template>
  <v-card
    outlined
    class="d-flex align-center business-card pa-4 pa-sm-10"
    :class="{ 'flex-column business-card-mobile': $vuetify.breakpoint.xsOnly }"
    color="white"
    :ripple="false"
    flat
  >
    <div class="new-business v-avatar ml-sm-8">
      <div class="new-business__avatar">
        <img
          v-if="account.avatar"
          :src="account.avatar"
          :alt="account.business_name_fa"
        />
      </div>
    </div>
    <div class="d-flex flex-column align-center align-sm-start">
      <h2 class="card-title mb-2 mb-sm-1">
        {{ account.account_name }}
      </h2>

      <div
        class="text-center mb-2 mb-sm-0 text-sm-start"
        :class="{
          font_2: $vuetify.breakpoint.smAndUp,
          'font-12-no-333-20': $vuetify.breakpoint.xsOnly,
        }"
      >
        {{ account.wallet }}
      </div>
    </div>
    <v-btn
      text
      depressed
      dark
      class="btn_small_normal mr-sm-8"
      @click="goToCreatingNewBusinessPage"
    >
      {{ account.right_box.text }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "NewBusinessCard",
  props: {
    account: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    goToCreatingNewBusinessPage() {
      this.$router.push("/" + this.account.entity)
    },
  },
}
</script>

<style lang="scss" scoped>
.business-card {
  height: 180px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  box-shadow: 0px 20px 10px 0px #0000001a;
  user-select: none;
}

.v-avatar {
  margin: 0 0 10px 0;
  flex: 1 0 auto;
}

.card-title {
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 25px;
}

.new-business {
  max-height: 110px;
  width: 110px;
  max-width: 110px;
  height: 110px;
  background-color: rgba(76, 159, 135, 0.2);
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
  &__avatar {
    min-width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: rgba(76, 159, 135);

    img {
      width: 24px;
    }
  }
}

.business-card-mobile {
  width: 280px;
}

@media screen and (max-width: 600px) {
  .business-card {
    height: 250px;
  }
  .new-business {
    max-height: 80px;
    width: 80px;
    max-width: 80px;
    height: 80px;
    &__avatar {
      min-width: 55px;
      height: 55px;
    }
  }
}
</style>
