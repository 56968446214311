<template>
  <v-text-field
    ref="postalCode"
    v-model.trim="postalCode"
    :rules="postalCodeRules"
    :placeholder="placeHolder"
    solo
    flat
    height="40"
    :readonly="isReadOnly"
    outlined
    maxlength="10"
    validate-on-blur
    :hint="hint"
    :loading="loading"
    @focus="changeFocusStatus(true)"
    @blur="changeFocusStatus(false)"
  >
    <template #message="message">
      <div v-html="message.message"></div>
    </template>
  </v-text-field>
</template>

<script>
import { mapMutations } from "vuex"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "PostalCode",
  props: {
    withFirstError: {
      type: Boolean,
      default: true,
    },
    inputValue: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    placeHolder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      postalCode: "",
      valid: false,
      loading: false,
    }
  },
  computed: {
    // ASK: is it true??
    isReadOnly() {
      if (
        (!this.$store.state.isUser &&
          this.$store.state.business.has_shaparak_terminal) ||
        (this.$store.state.isUser &&
          this.$store.state.user.has_shaparak_terminal)
      )
        return true
      return false
    },

    postalCodeRules() {
      if (this.withFirstError)
        return [
          (v) => !!v || "<span> کد پستی الزامی است </span>",
          (v) =>
            (this.removeWhiteSpaces(v) &&
              this.removeWhiteSpaces(v).length === 10) ||
            "<span> کدپستی باید 10 رقم باشد </span>",
          (v) => (v && this.valid) || "<span> کدپستی نامعتبر است </span>",
          (v) => this.postalCodeCheck(v) || "<span> کدپستی نامعتبر است </span>",
        ]
      else
        return [
          (v) => !!v || " ",
          (v) =>
            (v && v.length === 10) || "<span> کدپستی باید 10 رقم باشد </span>",
          (v) => (v && this.valid) || "<span> کدپستی نامعتبر است </span>",
          (v) => this.postalCodeCheck(v) || "<span> کدپستی نامعتبر است </span>",
        ]
    },
  },
  watch: {
    postalCode(newValue) {
      if (newValue) {
        this.postalCode = this.convertNumbers2English(newValue).replace(
          /[^0-9]/g,
          ""
        )
        this.postalCode = this.postalCode.slice(0, 10)
        this.$refs.postalCode.lazyValue = this.postalCode
        if (this.postalCode.length == 10 && this.postalCodeCheck(newValue)) {
          if (this.postalCode != this.inputValue) {
            this.loading = true

            servicesRepository(this)
              .checkServices.httpCheckPostalCode({
                postal_code: this.postalCode,
              })
              .then((response) => {
                this.$refs.postalCode.lazyValue = this.postalCode
                this.valid = true
                if (this.$refs.postalCode) this.$refs.postalCode.blur()
                this.$emit("setValue", newValue)
                this.$emit("setAddress", response.data.data)
                this.loading = false
              })
              .catch((error) => {
                this.valid = false
                if (this.$refs.postalCode) this.$refs.postalCode.blur()
                this.loading = false
                //callsnackbar
              })
          } else {
            this.valid = true
            if (this.$refs.postalCode) {
              this.$refs.postalCode.blur()
              this.$refs.postalCode.isResetting = true
              this.$refs.postalCode.lazyValue = this.postalCode
            }
            this.$emit("setValue", newValue)
          }
        } else {
          this.valid = false
          this.$emit("setValue", "")
        }
      }
    },
  },
  mounted() {
    this.postalCode = this.inputValue
  },
  methods: {
    ...mapMutations("newbusiness", ["changePostalCodeFieldFocusStatus"]),
    postalCodeCheck(value) {
      if (
        !(
          value == "0000000000" ||
          value == "1111111111" ||
          value == "2222222222" ||
          value == "3333333333" ||
          value == "4444444444" ||
          value == "5555555555" ||
          value == "6666666666" ||
          value == "7777777777" ||
          value == "8888888888" ||
          value == "9999999999"
        ) &&
        /^(\d{5}-?\d{5})$/.test(value)
      ) {
        return true
      } else {
        return false
      }
    },
    changeFocusStatus(focusState) {
      this.changePostalCodeFieldFocusStatus(focusState)
    },
    removeWhiteSpaces(text) {
      return text.replace(/^\s+|\s+$|\s+(?=\s)/g, "")
    },
  },
}
</script>

<style></style>
