<template>
  <v-card class="singleField">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="closeModal()">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">افزودن شماره شبا</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!valid1"
          :loading="loading"
          @click="!whitchBank ? sendForm() : submitIban()"
        >
          {{ whitchBank == "ayandeh" ? "ذخیره" : "ثبت" }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout column class="adding_sheba">
      <v-flex>
        <div v-if="selfIban" class="font_2 des" style="text-align: center">
          فقط شماره شبای خودتان را می‌توانید اضافه کنید:
        </div>
        <div
          v-else-if="whitchBank == 'ayandeh'"
          class="font_2 des"
          style="text-align: center"
        >
          فقط شماره شبا بانک آینده، به نام
          {{ isPersonal() }}
          را می‌توانید اضافه کنید:
        </div>
        <div v-else class="font_2 des" style="text-align: center">
          پس از ورود شماره شبا، منتظر نمایش نام صاحب حساب و بانک باشید، بعد
          ذخیره کنید:
        </div>
        <v-form ref="form1" :value="valid1">
          <v-text-field
            ref="sheba"
            v-model="inputComputed"
            :rules="inputRules"
            placeholder="XX XXXX XXXX XXXX XXXX XXXX XX"
            solo
            flat
            height="40"
            prefix="IR"
            maxlength="26"
            outlined
            @keydown="numberKeyHandler"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-text-field>
        </v-form>
        <div
          :class="
            error
              ? 'withError gray-text-box with-data'
              : 'gray-text-box with-data'
          "
        >
          <span v-if="ibanOwner == '' && error == ''"
            >در انتظار ورود شماره شبا</span
          >
          <v-layout
            v-else-if="persianName && ibanOwner && error == ''"
            align-center
          >
            <v-flex
              style="
                max-width: 60px;
                padding: 15px;
                margin-left: 10px;
                border: 1px solid #eeeeee;
                background-color: white;
              "
            >
              <v-img
                size="30"
                :src="require(`@/assets/img/banks/${ibank}.svg`)"
              />
            </v-flex>
            <v-flex>
              <div class="font_2 bold">
                {{ ibanOwner }}
              </div>
              <div class="font_4">
                {{ persianName }}
              </div>
            </v-flex>
          </v-layout>
          <span v-else-if="error">{{ error }}</span>
        </div>
      </v-flex>
      <v-flex
        v-if="whitchBank == 'ayandeh'"
        class="info_panel py-0"
        style="margin-top: -20px"
      >
        <v-layout class="my-4" align-center justify-space-between>
          <div class="font_2" style="text-align: right">
            <v-icon
              size="16"
              style="direction: rtl; width: 18px; margin-left: 4px"
            >
              mdi_info_outline </v-icon
            >با داشتن شماره حساب، شماره شبا را پیدا کنید:
          </div>
          <div class="font_2 mb-0" style="text-align: left !important">
            <a
              class="linkBorder"
              target="_blank"
              href="https://ba24.ir/services/sheba"
              >محاسبه شماره شبا</a
            >
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "AddSheba",
  props: {
    defaultIban: {
      type: Boolean,
      default: false,
    },
    selfIban: {
      type: Boolean,
      default: false,
    },
    forCustomer: {
      type: Boolean,
      default: false,
    },
    whitchBank: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      valid1: false,
      input: "",
      inputRules: [
        (v) =>
          (v && v?.length === 24) ||
          (v?.length < 24
            ? "<span>شبا را بصورت کامل وارد کنید</span>"
            : "<span>تعداد ارقام شبا (بدون احتساب IR) ۲۴ عدد می‌باشد.</span>"),
      ],
      ibank: null,
      persianName: "",
      ibanOwner: "",
      error: "",
      isAyandeh: false,
      ifAyandehOwner: null,
      loading: false,
    }
  },
  computed: {
    inputComputed: {
      get() {
        return this.input
      },
      set(newValue) {
        let inputtedValue
        if (newValue?.slice(0, 2) === "IR") {
          inputtedValue = newValue?.slice(2)
        } else inputtedValue = newValue

        if (!/^[IRir 0-9۰-۹-]*$/.test(newValue)) {
          inputtedValue = ""
          this.input = ""
          this.error = ""
        }

        this.input = this.convertNumbers2English(inputtedValue).replace(
          /[a-zA-Z -]/g,
          ""
        )

        this.$refs.sheba.lazyValue = this.input
        this.error = ""
        this.ibanOwner = ""
        this.valid1 = false

        if (this.input.length >= 5) {
          if (!this.whitchBank) {
            this.checkBank()
          } else if (this.whitchBank == "ayandeh") {
            this.isAyandeh = this.checkAyandeh(parseInt(this.input.slice(2, 5)))
            if (this.isAyandeh) {
              this.checkBank()
            } else {
              this.error = "شماره شبا وارد شده، بانک آینده نیست."
            }
          }
        }
      },
    },
  },
  methods: {
    isPersonal() {
      if (this.$store.state.business.business_type == "personal") {
        return "مالک کسب‌وکار"
      }
      return "حقوقی شرکت"
    },
    checkAyandeh(item) {
      // this.ibank = parseInt(item.slice(5, 7))
      let ayandehPrpperties = this.$getMyBanksProperty.find(
        (element) => element.persianName == "بانک آینده"
      )

      if (ayandehPrpperties && item == ayandehPrpperties.code) return true
      return false
    },
    checkBank() {
      if (this.inputComputed.length >= 5) {
        this.ibank = parseInt(this.inputComputed.slice(2, 5))
        this.$getMyBanksProperty.forEach((element, index) => {
          if ("0" + this.ibank === element.code) {
            this.persianName = element.persianName
          }
        })
      } else {
        this.ibank = null
        this.persianName = ""
      }

      if (this.inputComputed.length === 24) {
        this.valid1 = false
        this.ifAyandehOwner = false
        //validation Api
        this.$http
          .post(
            this.$store.getters.ibanApiUrlParam + "/check",
            {
              IBAN: "IR" + this.inputComputed,
            },
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.ibanOwner =
              response.data.data.ibans.account_owner[0].firstName +
              " " +
              response.data.data.ibans.account_owner[0].lastName
            this.ifAyandehOwner = response.data.data.is_owner
            if (this.ifAyandehOwner === false)
              this.error =
                "شماره شبا وارد شده، به نام " + this.isPersonal() + " نیست."

            if (this.whitchBank != "ayandeh" || this.ifAyandehOwner) {
              this.error = ""
              this.valid1 = true
            }
          })
          .catch((error) => {
            //callsnackbar
            this.valid1 = false
            this.error = error
          })
      } else {
        this.valid1 = false
        this.ibanOwner = ""
        this.error = ""
      }
    },
    submitIban() {
      this.loading = true

      let url =
        this.$store.getters.apiWithVersionParam("3") +
        "/settlement/add-ayandeh-default-iban"
      let data = {
        IBAN: "IR" + this.inputComputed,
      }

      this.$http
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.loading = false

          if (response.status == 200) {
            let ibanInfo = {
              ibanOwner: this.ibanOwner,
              ibanNo: this.inputComputed,
            }

            this.$store.commit(
              "callSuccessSnackbar",
              "شماره شبا با موفقیت ذخیره شد."
            )
            this.$emit("submitIban", ibanInfo)
            this.$emit("closeModal")
          } else {
            this.$store.commit("callSnackbar", "خطایی رخ داد.")
          }
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
    sendForm() {
      this.loading = true

      if (this.forCustomer) {
        this.$emit("setIban", this.inputComputed)
        this.loading = false
        return
      }

      let url, data
      if (this.defaultIban) {
        url = this.$store.getters.apiUrlParam + "/settlement/setting"
        data = {
          iban: "IR" + this.inputComputed,
        }
      } else {
        url = this.$store.getters.ibanApiUrlParam + "/store"
        data = {
          IBAN: "IR" + this.inputComputed,
        }
      }
      this.$http
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (this.defaultIban) {
            this.$store.commit("updateUserOrBusinessValue", {
              key: "default_iban",
              value: "IR" + this.inputComputed,
            })
          }
          this.loading = false
          if (response.data.data.hasOwnProperty("message")) {
            this.$store.commit("callsnackbar", response.data.data.message)
          }
          if (!this.defaultIban) {
            this.$http
              .get(this.$store.getters.ibanApiUrlParam, {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "Bearer " + this.$store.state.token,
                  Accept: "application/json",
                },
              })
              .then((response) => {
                this.loading = false
                this.setIban(response.data.data.data)
                this.closeModal(true)
              })
              .catch((error) => {
                //callsnackbar
                this.loading = false
              })
          } else {
            this.closeModal(true)
          }
        })
        .catch(() => {
          //callsnackbar
          this.loading = false
        })
    },
    closeModal(value = false) {
      this.$store.commit("callSecondModal", false)
      this.$emit("closeModal", { status: value })
      this.inputComputed = ""
      this.valid1 = false
      this.ibank = null
      this.persianName = ""
      this.ibanOwner = ""
    },
    setIban(value) {
      this.$emit("setIban", value)
    },
  },
}
</script>
