import service from "./service"
import serviceV2 from "./serviceV2"

export default ($http, context) => ({
  httpOverViewIncome(chartType) {
    const queryParam = "/stat?code=all_services_income_" + chartType
    return service.get($http, context, queryParam)
  },
  httpOverViewTurnOvers(chartType) {
    const queryParam = "/stat?code=all_services_turnover_" + chartType
    return service.get($http, context, queryParam)
  },
  httpOverViewTurnOversApiV2(item) {
    const queryParam = `/overview/turnover?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpOverViewIncomeCount(chartType) {
    const queryParam = "/stat?code=all_services_income_count_" + chartType
    return service.get($http, context, queryParam)
  },
  httpOverViewAverageIncome() {
    const queryParam = "/stat?code=all_services_average"
    return service.get($http, context, queryParam)
  },
  httpOverViewDeposit(item) {
    const queryParam = `/overview/deposit?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpOverViewSuccessDeposit(item) {
    const queryParam = `/overview/success-deposit?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpOverViewAverageIncomeV2() {
    const queryParam = "/overview/average-income"
    return serviceV2.get($http, context, queryParam)
  },
})
