<template>
  <v-text-field
    ref="telephone"
    v-model="phone_number"
    :rules="phone_number_Rules"
    solo
    flat
    height="40"
    outlined
    placeholder="مثل: 22886644"
    :readonly="isReadOnly"
    validate-on-blur
    @focus="changeFocusState(true)"
    @blur="changeFocusState(false)"
  >
    <template #message="message">
      <div v-html="message.message"></div>
    </template>
  </v-text-field>
</template>

<script>
import { mapMutations } from "vuex"
export default {
  name: "PhoneNumber",
  props: ["inputValue"],
  data() {
    return {
      phone_number: null,
      phone_number_Rules: [
        (v) => !!v || "<span> شماره ثابت الزامی است </span>",
        //v => /^[2-9][0-9]{7}+$/.test(v) || '<span> شماره تلفن نادرست است </span>',
        (v) =>
          (v && v.length == 8) ||
          "<span> شماره تلفن ثابت باید 8 رقم باشد </span>",
        (v) =>
          (v && !this.repeatedNumbers(v, 7)) ||
          "<span> اعداد وارد شده تکراری است </span>",
      ],
    }
  },
  computed: {
    isReadOnly() {
      if (
        (!this.$store.state.isUser &&
          this.$store.state.phone_number != null &&
          this.$store.state.business.has_shaparak_terminal) ||
        (!this.$store.state.isUser && this.$store.state.business.status == 1)
      )
        return true
      return false
    },
  },
  watch: {
    phone_number: function (newValue) {
      if (newValue) {
        this.phone_number = this.convertNumbers2English(newValue).replace(
          /[^0-9]/g,
          ""
        )
        this.phone_number = this.phone_number.slice(0, 8)
        if (this.phone_number.length == 8) {
          this.$refs.telephone.blur()
          // this.$refs.telephone.isResetting = true
        }
      }
      this.$refs.telephone.lazyValue = this.phone_number
      this.$emit("setValue", newValue)
    },
  },
  mounted() {
    this.phone_number = this.inputValue
  },
  methods: {
    ...mapMutations("newbusiness", ["changePhoneNumberFieldFocusStatus"]),
    repeatedNumbers(input, N) {
      // it allows just N repeats continiously
      var regex = new RegExp("(\\d)\\1{" + N + "}", "g")

      return !!input.match(regex)
    },
    changeFocusState(focusState) {
      this.changePhoneNumberFieldFocusStatus(focusState)
    },
  },
}
</script>

<style></style>
