const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpUpload(payload) {
    return context.$http.post(
      `${context.$store.state.api_base_url}/business/${context.$store.state.business.business_name}/update`,
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpIsBusinessLegalOwner(payload) {
    return context.$http.post(
      `${context.$store.state.api_base_url}/business/${context.$store.state.business.business_name}/update/business-owner-is-business-legal-owner`,
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
})
