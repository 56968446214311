export const queuedSettlementHelper = {
  methods: {
    typeOrOwnerName(settlement) {
      if (settlement.settlement_items?.length > 1) return "برداشت گروهی"
      else if (settlement.settlement_items?.length === 1)
        return (
          settlement.settlement_items[0].account_owner?.[0]?.firstName +
          " " +
          settlement.settlement_items[0].account_owner?.[0]?.lastName
        )

      return ""
    },
    isBatch(settlement) {
      return this.settlementsCount(settlement) > 1 ?? false
    },
    settlementsCount(settlement) {
      return settlement.settlement_items?.length
    },
    handleAmount(amount) {
      return (
        this.$options.filters.currencyWithRialInput(amount) +
        " " +
        this.currencyName
      )
    },
    sumAmount(settlement) {
      let sum = 0
      settlement?.settlement_items?.forEach((element) => {
        sum += element.amount
      })
      return sum
    },
  },
}
