<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.30861 3.00001C5.0457 2.99909 4.78521 3.05019 4.54215 3.15037C4.29908 3.25055 4.07823 3.39783 3.89233 3.58374C3.70643 3.76964 3.55914 3.99048 3.45896 4.23355C3.35878 4.47662 3.30768 4.73711 3.30861 5.00001V19C3.30768 19.2629 3.35878 19.5234 3.45896 19.7665C3.55914 20.0095 3.70643 20.2304 3.89233 20.4163C4.07823 20.6022 4.29908 20.7495 4.54215 20.8497C4.78521 20.9498 5.0457 21.0009 5.30861 21H19.3086C19.5715 21.0009 19.832 20.9498 20.0751 20.8497C20.3181 20.7495 20.539 20.6022 20.7249 20.4163C20.9108 20.2304 21.0581 20.0095 21.1582 19.7665C21.2584 19.5234 21.3095 19.2629 21.3086 19V5.00001C21.3095 4.73711 21.2584 4.47662 21.1582 4.23355C21.0581 3.99048 20.9108 3.76964 20.7249 3.58374C20.539 3.39783 20.3181 3.25055 20.0751 3.15037C19.832 3.05019 19.5715 2.99909 19.3086 3.00001H5.30861ZM5.30861 5.00001H19.3086V19H5.30861V5.00001ZM13.3086 12L16.5086 17H14.5086L12.3086 13.2L10.1086 17H8.10861L11.3086 12L8.10861 7.00001H10.1086L12.3086 10.8L14.5086 7.00001H16.5086L13.3086 12Z"
      :fill="fillColor"
    />
  </svg>
</template>
<script>
export default {
  name: "ExcelIcon",
  props: {
    fillColor: {
      type: String,
      default: "#666666",
    },
  },
}
</script>
