<template>
  <v-layout :column="reuseIsMobile" justify-start align-start class="mb-0">
    <v-flex v-if="label" class="label">
      {{ label }}
      <span>*</span>
    </v-flex>
    <v-flex
      class="withlabel big"
      :class="{ 'd-flex flex-wrap': reuseIsMobile }"
    >
      <v-autocomplete
        v-if="!ibanSelect"
        ref="ibanSearch"
        v-model="ibanSelect"
        :items="ibansList"
        class="iban_search mb-3"
        solo
        flat
        height="40"
        outlined
        :filter="ibanFilter"
        label="انتخاب شماره شبا یا وارد کردن شماره"
        :disabled="loading"
        clearable
        autofocus
        :loading="ibanLoading"
        clear-icon="clear"
      >
        <template #item="data">
          <v-flex class="iban-combo-box">
            <v-layout align-center>
              <v-flex
                style="
                  max-width: 30px;
                  padding: 7px;
                  margin-left: 5px;
                  border: 1px solid #eeeeee;
                "
              >
                <v-img
                  width="15"
                  :src="
                    require(`@/assets/img/banks/${parseInt(
                      data.item[0].slice(4, 7)
                    )}.svg`)
                  "
                />
              </v-flex>
              <v-flex>
                <div class="d-flex align-center" style="font-size: 10px">
                  <span class="ml-1"> {{ data.item[1] }} </span>

                  <IbanShaparakChips
                    :is-default-iban="data.item[3]"
                    :shaparak-iban-status="data.item[4]"
                  />
                </div>
                <div style="font-size: 10px">
                  {{ data.item[0] }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </template>
        <template #selection="data">
          {{ Array.isArray(data.item) ? data.item[0] : null }}
        </template>
        <template #no-data>
          <div
            class="font_10 d-flex align-center justify-center"
            style="height: 96px"
          >
            شماره شبا ثبت نشده است
          </div>
        </template>
      </v-autocomplete>
      <div
        v-if="ibanSelect && ibanSelect.length != 0"
        class="van_color11 mt-0 mx-auto mb-3 selected-iban-box"
        :class="{ 'pa-2': !reuseIsMobile, 'pa-1': reuseIsMobile }"
      >
        <v-layout
          v-if="Array.isArray(ibanSelect) && ibanSelect.length > 0"
          align-center
        >
          <v-flex
            class="icon-background"
            :class="{ 'pa-1 ml-1': reuseIsMobile, 'pa-3 ml-2': !reuseIsMobile }"
          >
            <v-img
              :width="reuseIsMobile ? 20 : 28"
              aspect-ratio="1"
              :src="
                require(`@/assets/img/banks/${parseInt(
                  ibanSelect[0].slice(5, 7)
                )}.svg`)
              "
            />
          </v-flex>
          <v-flex class="d-flex flex-column align-start">
            <div class="font_2">
              {{ ibanSelect[0] }}
            </div>
            <div
              class="font_2 d-flex"
              :class="{ 'flex-column': reuseIsMobile }"
            >
              <span class="ml-1">
                {{ ibanSelect[1] }} | {{ ibanSelect[2] }}
              </span>
              <IbanShaparakChips
                :is-default-iban="ibanSelect[3]"
                :shaparak-iban-status="ibanSelect[4]"
              />
            </div>
          </v-flex>
          <v-flex class="d-flex justify-end">
            <v-btn icon small class="ma-0" @click.native="clearSelectedIban">
              <v-icon
                color="van_color03"
                class="d-flex justify-center"
                size="20"
              >
                close
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import servicesRepository from "@/services/servicesRepository"
import IbanShaparakChips from "@/components/report/modals/addCashIn/elements/IbanShaparakChips.vue"
import screenSize from "@/mixins/screenSize"

export default {
  components: {
    IbanShaparakChips,
  },
  mixins: [screenSize],
  props: {
    label: {
      type: String,
      default: "فیلد",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ibanSelect: "",
      ibansList: [],
      ibanLoading: true,
      ibansDataListOfObjects: [],
    }
  },
  watch: {
    ibanSelect: {
      handler(newValue) {
        if (Array.isArray(this.ibanSelect)) {
          this.$emit("selected-iban-array", newValue)
          const selectedIbanObject = this.findSelectedIbanObject(newValue)
          this.$emit("selected-iban-Object", selectedIbanObject)
        } else {
          this.$emit("ibanSelectSet", null)
        }
      },
    },
  },
  mounted() {
    if (this.defaultValue.length) this.ibanSelect = this.defaultValue
    if (
      !this.$store.state.isUser &&
      (this.$store.state.business.role == "owner" ||
        this.$store.state.business.role == "admin" ||
        this.$store.state.business.role == "accountant") &&
      this.$store.state.business.hasOwnProperty("tools") &&
      this.$store.state.business.tools["cash-in"].status == "ACTIVE"
    ) {
      this.getIbans()
    }
  },
  methods: {
    ibanFilter(item, queryText, itemText) {
      const searchText = this.convertNumbers2English(queryText)
      return itemText.indexOf(searchText) > -1
    },
    getIbans() {
      this.ibanLoading = true
      servicesRepository(this)
        .cashIn.httpCashInAccount()
        .then((response) => {
          let data = response.data.data
          this.ibansList = []
          data.forEach((item) => {
            const tempIban = [
              item.IBAN,
              item.account_owner[0] &&
              item.account_owner[0].firstName &&
              item.account_owner[0].lastName
                ? item.account_owner[0].firstName +
                  " " +
                  item.account_owner[0].lastName
                : "",
              item.bank_name ? item.bank_name : "",
              item.is_default,
              item.status,
            ]
            this.ibansList.push(tempIban)
            this.$store.commit("updateCashInIbans", data)
          })
          this.ibansDataListOfObjects = data
          this.ibanLoading = false
        })
        .then(() => {
          if (this.$refs.ibanSearch) {
            this.$refs.ibanSearch.focus()
            this.$refs.ibanSearch.isMenuActive = true
          }
        })
    },
    findSelectedIbanObject(selectedIban) {
      return this.ibansDataListOfObjects.find(
        (item) => item.IBAN === selectedIban[0]
      )
    },
    clearSelectedIban() {
      this.ibanFilter = () => ""
      this.ibanSelect = ""
      this.$emit("clear-iban")
    },
  },
}
</script>
<style lang="scss" scoped>
.selected-iban-box {
  border-radius: 4px;
  width: 352px;
}

.icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  border: 1px solid #eeeeee;
  background-color: white;
}
</style>
