<template>
  <chart-card-one-filter
    class="mmt-5"
    :title="kerdarLang.general.errorsFrequency"
    :show-filter="true"
    :growth-text="errorFrequencyPercentageGrowth"
    :duration="localDuration"
    growth-color="#D44D421A"
    @changeFilter="changeErrorFrequencyPercentageFilter($event)"
  >
    <one-bar-horizontal-bar-type2
      v-if="errorFrequencyPercentageStatus"
      :x-value="errorFrequencyPercentageTitle"
      :y-value="errorFrequencyPercentageValue"
      :y-result="errorFrequencyPercentageTooltip"
      :not-show-less-than="1"
      :tooltip-for-less-than="kerdarLang.general.tooltipForOtherCasesWithTag"
      :y-unit="kerdarLang.general.error"
      :max-value-for-bars="total(errorFrequencyPercentageData, 1)"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </chart-card-one-filter>
</template>

<script>
import ChartCardOneFilter from "@/components/kerdar/components/chartCardOneFilter"
import OneBarHorizontalBarType2 from "@/components/kerdar/components/oneBarHorizontalBarType2"
import { sortData } from "@/components/kerdar/utils/generalMethods.js"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

export default {
  name: "DirectDebitErrorFrequency",
  components: {
    "chart-card-one-filter": ChartCardOneFilter,
    "one-bar-horizontal-bar-type2": OneBarHorizontalBarType2,
  },
  props: {
    duration: {
      type: Object,
      default: () => {},
    },
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      errorFrequencyPercentageData: [],
      errorFrequencyPercentageStatus: false,
      errorFrequencyPercentageFilter: null,
      localDuration: [
        {
          name: this.kerdarLang.general.currentDay,
          value: "current_day",
        },
        {
          name: this.kerdarLang.general.currentWeek,
          value: "current_week",
        },
        {
          name: this.kerdarLang.general.lastDay,
          value: "last_day",
        },
        {
          name: this.kerdarLang.general.lastWeek,
          value: "last_week",
        },
      ],
    }
  },
  computed: {
    errorFrequencyPercentageGrowth() {
      if (this.errorFrequencyPercentageFilter) {
        let totalErrorCount = 0
        if (
          this.errorFrequencyPercentageValue &&
          this.errorFrequencyPercentageValue.length
        ) {
          for (let data of this.errorFrequencyPercentageData) {
            totalErrorCount += data[1]
          }
        }
        if (!totalErrorCount) return null
        return (
          `${this.kerdarLang.general.at} ` +
          this.duration[this.errorFrequencyPercentageFilter.value] +
          " " +
          "<span class='bold van_red--text'>" +
          totalErrorCount.toLocaleString() +
          "</span>" +
          ` ${this.kerdarLang.general.haveUnsuccessfulDeposit}`
        )
      }
      return null
    },
    errorFrequencyPercentageTitle() {
      return this.errorFrequencyPercentageData.map((item) => item[0])
    },
    errorFrequencyPercentageValue() {
      return this.errorFrequencyPercentageData.map((item) => Number(item[1]))
    },
    errorFrequencyPercentageTooltip() {
      let totalErrorCount = 0
      if (
        this.errorFrequencyPercentageValue &&
        this.errorFrequencyPercentageValue.length
      ) {
        totalErrorCount = this.total(this.errorFrequencyPercentageData, 1)
      }

      return this.errorFrequencyPercentageData.map((item) => {
        let percentage =
          Math.round(
            ((100 * item[1]) / totalErrorCount + Number.EPSILON) * 100
          ) / 100
        let text =
          '<span style="font-weight: bold;color: rgb(212, 77, 66)">' +
          percentage +
          "%</span>"
        text += ` ${this.kerdarLang.general.becauseOf} ` + item[0]
        return text
      })
    },
  },
  methods: {
    clearErrorFrequencyPercentageData() {
      this.errorFrequencyPercentageStatus = false
      this.errorFrequencyPercentageData = []
    },
    changeErrorFrequencyPercentageFilter(item) {
      this.errorFrequencyPercentageFilter = item
      this.clearErrorFrequencyPercentageData()
      this.getErrorFrequencyPercentageData(item)
    },
    async getErrorFrequencyPercentageData(item) {
      const chartType = item.value
      if (!chartType) {
        return
      }
      const response = await kerdarServices(
        this.$http,
        this
      ).directDebit.httpDirectDebitErrorFrequency(chartType)

      this.errorFrequencyPercentageData = sortData(response.data.data)
      this.errorFrequencyPercentageStatus = true
    },
    total(data, index) {
      let total = 0
      for (let tempData of data) {
        total += tempData[index]
      }
      return total
    },
  },
}
</script>

<style lang="scss" scoped></style>
