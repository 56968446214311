<template>
  <div :class="dark ? 'font_21' : 'font_8'" style="width: 100%">
    <div v-if="load" class="mx-0">
      <div
        v-if="status == 1 && amount != null"
        :style="
          center
            ? 'display: flex;justify-content: center;align-items:center;height:20px;flex-wrap: nowrap;overflow:visible;'
            : 'display: flex;align-items:center;height:20px;flex-wrap: nowrap;overflow:visible;'
        "
      >
        <span
          v-if="withLabel"
          style="padding-left: 3px; width: 45px; min-width: 45px"
        >
          کیف پول:
        </span>
        <div v-if="access" style="display: flex">
          <span dir="ltr">
            {{ amount | currency }}
          </span>
          <span style="padding-right: 4px; width: 27px; white-space: nowrap">
            {{ currencyName }}
          </span>
          <span v-if="blocked_amount > 0" class="istyle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
            >
              <path
                id="Warning"
                d="M9.876,16.877a2.191,2.191,0,0,1-1.267-.671L3.548,11.142a2.191,2.191,0,0,1-.671-1.267,2.191,2.191,0,0,1,.671-1.266L8.609,3.548a2.191,2.191,0,0,1,1.267-.671,2.191,2.191,0,0,1,1.266.671l5.063,5.061a2.188,2.188,0,0,1,.67,1.266,2.188,2.188,0,0,1-.67,1.267l-5.063,5.063A2.191,2.191,0,0,1,9.876,16.877ZM9,9v5h2V9ZM9,6V8h2V6Z"
                transform="translate(-2.876 -2.876)"
                fill="#f9d358"
              />
            </svg>
          </span>
        </div>
        <div v-else>-</div>
      </div>
      <div
        v-else-if="status == 0 || status == -1"
        :style="center ? 'display: flex;justify-content: center;' : ''"
      >
        حساب غیرفعال
      </div>
    </div>
    <span
      v-else
      :style="
        center ? 'width: 40%;margin-left:auto;margin-right:auto' : 'width: 40%'
      "
      :class="dark ? 'default_gray dark' : 'default_gray'"
    />
  </div>
</template>

<script>
export default {
  name: "Wallet",
  props: {
    withLabel: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: null,
    },
    status: {
      type: Number,
      default: 0,
    },
    load: {
      type: Boolean,
      default: false,
    },
    blocked_amount: {
      type: Number,
      default: 0,
    },
    center: {
      type: Boolean,
      default: false,
    },
    access: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style></style>
