<template>
  <div>
    <v-layout
      class="filter_row mb-5 tools_filter mt-0"
      justify-start
      align-center
      wrap
    >
      <v-flex>
        <v-radio-group
          v-model="tab"
          class="py-1 px-2"
          row
          :style="$vuetify.breakpoint.smAndDown ? 'width:100%' : ''"
        >
          <v-radio
            :label="kerdarLang.general.overView"
            value="over-view"
          ></v-radio>
          <v-radio
            v-if="
              $store.state.business.hasOwnProperty('tools') &&
              $store.state.business.tools.ipg.is_created
            "
            :label="kerdarLang.general.paymentGateway"
            value="ipg"
          ></v-radio>
          <v-radio
            v-if="
              $store.state.business.hasOwnProperty('tools') &&
              $store.state.business.tools.subscription.is_created
            "
            :label="kerdarLang.general.subscription"
            value="direct-debit"
          ></v-radio>
          <v-radio
            v-if="
              $store.state.business.hasOwnProperty('tools') &&
              $store.state.business.tools.requestMoney.is_created
            "
            :label="kerdarLang.general.paymentForm"
            value="payment-form"
          ></v-radio>
          <!-- <v-radio
            v-if="
              $store.state.business.hasOwnProperty('tools') &&
              $store.state.business.tools.settlement_batch.is_created
            "
            :label="kerdarLang.general.settlement"
            value="settlement"
          ></v-radio> -->
          <!--          <v-radio label="نمونه" value="samples"></v-radio>-->
        </v-radio-group>
      </v-flex>
    </v-layout>
    <v-layout wrap class="cards" justify-start>
      <v-flex style="width: 100%">
        <!-- <KeepAlive> -->
        <component :is="tab"></component>
        <!-- </KeepAlive> -->
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import Samples from '@/components/kerdar/components/pages/samples'
import OverView from "@/components/kerdar/tabs/overview"
import Ipg from "@/components/kerdar/tabs/ipg"
import DirectDebit from "@/components/kerdar/tabs/direct-debit"
import PaymentForm from "@/components/kerdar/tabs/payment-form"
// import Settlement from "@/components/kerdar/tabs/settlement"
import kerdarLang from "@/components/kerdar/utils/langResource"

export default {
  name: "Kerdar",
  components: {
    // Samples,
    OverView,
    Ipg,
    DirectDebit,
    PaymentForm,
    // Settlement,
  },
  beforeRouteEnter(to, from, next) {
    const previousPageName = from.name
    let selectedTab
    switch (previousPageName) {
      case "businessHome":
        selectedTab = "over-view"
        break
      case "ipg":
        selectedTab = "ipg"
        break
      case "subscription":
        selectedTab = "direct-debit"
        break
      default:
        selectedTab = "over-view"
    }

    next((vm) => (vm.tab = selectedTab))
  },
  data() {
    return {
      tab: "OverView",
      kerdarLang,
    }
  },
}
</script>
