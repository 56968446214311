<template>
  <v-layout style="flex-wrap: wrap; position: relative; top: 24px">
    <v-dialog
      v-model="isOpenDetailModal"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
      @click:outside="closeModal"
    >
      <taxTrackingCode
        v-show="addTaxStatus"
        :edit="taxIsEdit"
        @closeModal="closeModal"
      />
      <locationInfo v-show="addLocationStatus" edit @closeModal="closeModal" />
      <addBirthCertificateNumber
        v-show="editBirthCertificateNumberStatus"
        @closeModal="closeModal"
      />
      <editRegisterDate
        v-show="editRegisterDateStatus"
        @closeModal="closeModal"
      />
      <editRegisterCode
        v-show="EditRegisterCodeStatus"
        @closeModal="closeModal"
      />
      <addSheba
        v-show="addShebaStatus && shouldShowShebaRejectedTextField"
        default-iban
        self-iban
        @closeModal="closeModal"
      />
      <Enamad v-show="openEnamadModal" @closeModal="closeModal" />
    </v-dialog>
    <v-layout column align-center class="px-6">
      <!-- edit tax code -->
      <div v-if="!$store.state.business.tax_code" class="card-element-wrapper">
        <CardElement @click.native="add_tax('add')">
          کد رهگیری مالیاتی خود را وارد کنید
        </CardElement>
      </div>
      <div
        v-else-if="
          $store.state.business.tax_code_detail.tax_code_status == 'FAILED'
        "
        class="card-element-wrapper"
      >
        <CardElement @click.native="add_tax('edit')">
          کد رهگیری مالیاتی خود را اصلاح کنید
        </CardElement>
      </div>
      <!-- edit postal code -->
      <div v-if="postalCodStatus == 'FAILED'" class="card-element-wrapper">
        <CardElement @click.native="add_location">
          کد پستی مرتبط با کد رهگیری مالیاتی را وارد کنید
        </CardElement>
      </div>
      <!-- edit enamad -->
      <div
        v-if="
          $store.state.business.enamad_detail.enamad_status == 'FAILED' &&
          $store.state.business.rejects.ipg_url
        "
        class="card-element-wrapper"
      >
        <CardElement @click.native="editEnamad">
          {{ $store.state.business.rejects.ipg_url }}
        </CardElement>
      </div>
      <!-- edit birthcertificate number-->
      <div
        v-if="
          $store.state.business &&
          $store.state.business.business_type == 'personal' &&
          birthCertificateNumber
        "
        class="card-element-wrapper"
      >
        <CardElement @click.native="editBirthCertificateNumber">
          شماره شناسنامه شخص حقیقی نامعتبر است.
        </CardElement>
      </div>
      <!-- edit register number-->
      <div v-if="registerDate" class="card-element-wrapper">
        <CardElement @click.native="editRegisterDate">
          تاریخ ثبت شرکت را اصلاح کنید.
        </CardElement>
      </div>
      <!-- edit register number-->
      <div v-if="registerCode" class="card-element-wrapper">
        <CardElement @click.native="editRegisterCode">
          شماره ثبت شرکت معتبر نیست، اصلاح کنید
        </CardElement>
      </div>

      <div v-if="shouldShowShebaRejectedTextField" class="card-element-wrapper">
        <CardElement @click.native="openShebaEditionModal">
          {{
            shebaTextField.shebaRejectedText
              ? shebaTextField.shebaRejectedText
              : shebaTextField.defaultText
          }}
        </CardElement>
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import Enamad from "@/components/modals/enamad"
import taxTrackingCode from "@/components/modals/taxTrackingCode.vue"
import editRegisterCode from "@/components/modals/editRegisterCode.vue"
import editRegisterDate from "@/components/modals/editRegisterDate.vue"
import addBirthCertificateNumber from "@/components/modals/addBirthcertificateNumber.vue"
import locationInfo from "@/components/modals/locationInfo.vue"
import CardElement from "@/components/businessHome/elements/CardElement.vue"
import addSheba from "@/components/modals/addSheba.vue"

export default {
  name: "ShaparakActivationItems",
  components: {
    Enamad,
    taxTrackingCode,
    locationInfo,
    editRegisterCode,
    editRegisterDate,
    addBirthCertificateNumber,
    CardElement,
    addSheba,
  },
  props: {
    isShowDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogStatus: false,
      addTaxStatus: false,
      taxIsEdit: false,
      addShebaStatus: false,
      addLocationStatus: false,
      EditRegisterCodeStatus: false,
      editRegisterDateStatus: false,
      editBirthCertificateNumberStatus: false,
      openEnamadModal: false,
      changeBirthCertificateNumber: false,
      changeMccCode: false,
      isOpenDetailModal: false,
    }
  },
  computed: {
    registerCode() {
      if (!this.$store.state.isUser)
        return this.$store.state.business.rejects.register_number
      return null
    },
    registerDate() {
      if (!this.$store.state.isUser)
        return this.$store.state.business.rejects.register_date
      return null
    },
    birthCertificateNumber() {
      if (!this.$store.state.isUser)
        return this.$store.state.business.rejects.birth_certificate_number
      return null
    },
    postalCodStatus() {
      return this.$store.state.business.postal_code_detail.postal_code_status
    },
    businessHasRejected() {
      return this.$store.state.business.has_rejection
    },
    shebaTextField() {
      return {
        shebaRejectedText: this.$store.state.business.rejects.default_iban,
        defaultText: "شماره شبا متعلق به خودتان",
      }
    },
    shouldShowShebaRejectedTextField() {
      return (
        this.businessHasRejected &&
        !this.$store.state.business.default_iban &&
        this.$store.state.business.rejects.default_iban
      )
    },
  },
  methods: {
    closeModal() {
      this.addTaxStatus = false
      this.addLocationStatus = false
      this.EditRegisterCodeStatus = false
      this.editRegisterDateStatus = false
      this.editBirthCertificateNumberStatus = false
      this.openEnamadModal = false
      this.isOpenDetailModal = false
      this.addShebaStatus = false
      this.closeSecondModal()
    },
    add_tax(whichForm) {
      if (whichForm === "edit") this.taxIsEdit = true
      else this.taxIsEdit = false
      this.addTaxStatus = true
      this.openDetailModal()
      this.openSecondModal()
    },
    add_location() {
      this.addLocationStatus = true
      this.openDetailModal()
      this.openSecondModal()
    },
    editRegisterDate() {
      this.editRegisterDateStatus = true
      this.openDetailModal()
      this.openSecondModal()
    },
    openShebaEditionModal() {
      this.addShebaStatus = true
      this.openDetailModal()
      this.openSecondModal()
    },
    editBirthCertificateNumber() {
      this.editBirthCertificateNumberStatus = true
      this.openDetailModal()
      this.openSecondModal()
    },
    editRegisterCode() {
      this.EditRegisterCodeStatus = true
      this.openDetailModal()
      this.openSecondModal()
    },
    editEnamad() {
      this.openEnamadModal = true
      this.openDetailModal()
      this.openSecondModal()
    },
    openDetailModal() {
      this.isOpenDetailModal = true
    },
    openSecondModal() {
      this.$emit("is-open-second-modal")
    },
    closeSecondModal() {
      this.$emit("close-shaparak-modal")
    },
  },
}
</script>

<style lang="scss" scoped>
.card-element-wrapper {
  width: 100%;
  margin: 0 0 16px;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  background-color: white;
  user-select: none;
}
</style>
