import { render, staticRenderFns } from "./IpgErrorFrequencyPercentage.vue?vue&type=template&id=bcce7e10&scoped=true&"
import script from "./IpgErrorFrequencyPercentage.vue?vue&type=script&lang=js&"
export * from "./IpgErrorFrequencyPercentage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcce7e10",
  null
  
)

export default component.exports