<template>
  <v-row class="align-center" no-gutters>
    <v-col cols="3">
      <span class="font_31"> {{ title }} </span>
    </v-col>
    <v-col cols="9">
      <v-text-field
        :value="formattedPrice"
        type="text"
        hide-spin-buttons
        solo
        flat
        maxLength="19"
        @input="setPriceValue"
        @keydown="checkInputtedValue"
      >
        <template #append>
          <span class="font_30">{{ currencyName }}</span>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "PriceInput",
  props: {
    title: {
      type: String,
      default: "مبلغ از:",
    },
  },
  data() {
    return {
      formattedPrice: null,
    }
  },
  computed: {
    ...mapState("bankInvoice", ["shouldResetPriceInput"]),
  },
  watch: {
    shouldResetPriceInput(val) {
      if (val) this.formattedPrice = null
    },
  },
  methods: {
    setPriceValue(val) {
      let num = Number(this.removeThousandSeparator(val))
      this.formattedPrice = num.toLocaleString("en-US")
      this.$emit("send-up-price", {
        price: num,
        formattedPrice: this.formattedPrice,
      })
    },
    checkInputtedValue(event) {
      const onlyNumbers = /^\d+$/
      const selectAllCondition = event.ctrlKey && event.code === "KeyA"

      if (
        !onlyNumbers.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "Delete" &&
        event.key !== "Tab" &&
        event.key !== "ArrowLeft" &&
        event.key !== "ArrowRight" &&
        !selectAllCondition
      ) {
        event.preventDefault()
      }
    },
    removeThousandSeparator(value) {
      return value.replace(/,/g, "")
    },
  },
}
</script>

<style lang="scss" scoped></style>
