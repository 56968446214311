<template>
  <left-modal
    class="batchesSettlement"
    toolbar-name="لیست برداشت‌ها"
    body-padding="pa-0"
    body-padding-xs="pa-0"
    @closeModal="$emit('closeModal', { status: false })"
  >
    <div slot="toolbarItems">
      <v-btn
        v-if="
          !(
            selectedSettlement.status.pending_count > 0 ||
            pendingWithNoCancel ||
            isAllSettlementCanceled
          )
        "
        ref="btn"
        class="btn_small_normal"
        :class="{ 'ml-4 px-3 mr-1': $vuetify.breakpoint.xsOnly }"
        dark
        text
        :loading="loading"
        @click="$emit('exportBatch', selectedSettlement.batch_id)"
      >
        خروجی ثبت برداشت‌ها
      </v-btn>
    </div>
    <div v-if="isAllSettlementCanceled" class="ma-8">
      <div class="font_2 mb-4">این برداشت گروهی لغو شده است.</div>
      <canceledSettlements :batch-id="selectedSettlement.batch_id" />
    </div>

    <pending-settlements
      v-else-if="pendingWithNoCancel"
      class="pa-8"
      :batch-id="selectedSettlement.batch_id"
      :selected-settlement="selectedSettlement"
      @reloadBatchList="$emit('reloadBatchList', { status: false })"
      @goToCanceledTab="goToCanceledTab"
      @canceledAll="canceledAll = true"
    />

    <template v-else>
      <v-tabs v-model="tab">
        <v-tabs-slider color="van_green" />
        <v-tab
          v-if="!selectedSettlement.status.pending_count"
          class="mr-8"
          style="font-size: 12px"
        >
          ثبت شده
        </v-tab>
        <v-tab
          v-if="!selectedSettlement.status.pending_count"
          style="font-size: 12px"
        >
          خطا خورده
        </v-tab>
        <v-tab
          v-if="selectedSettlement.status.pending_count"
          class="mr-8"
          style="font-size: 12px"
        >
          برداشت‌‌ها
        </v-tab>
        <v-tab
          v-if="selectedSettlement.status.canceled_count || activeCanceledTab"
          style="font-size: 12px"
        >
          لغو شده
        </v-tab>
      </v-tabs>

      <v-layout class="base ma-7">
        <v-flex>
          <v-tabs-items v-model="tab">
            <v-tab-item v-if="!selectedSettlement.status.pending_count">
              <success-ful-settlement
                :batch-id="selectedSettlement.batch_id"
                @showOneTransactionDetails="showOneTransactionDetails"
              />
            </v-tab-item>
            <v-tab-item v-if="!selectedSettlement.status.pending_count">
              <error-settlement :batch-id="selectedSettlement.batch_id" />
            </v-tab-item>
            <v-tab-item v-if="selectedSettlement.status.pending_count">
              <pending-settlements
                :batch-id="selectedSettlement.batch_id"
                :selected-settlement="selectedSettlement"
                @goToCanceledTab="goToCanceledTab"
                @canceledAll="canceledAll = true"
                @reloadBatchList="$emit('reloadBatchList', { status: false })"
              />
            </v-tab-item>
            <v-tab-item
              v-if="
                selectedSettlement.status.canceled_count || activeCanceledTab
              "
              ><canceledSettlements
                ref="canceledTab"
                :batch-id="selectedSettlement.batch_id"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </template>
  </left-modal>
</template>

<script>
import successFulSettlement from "../elements/successfulsettlement.vue"
import pendingSettlements from "../elements/pendingSettlements.vue"
import errorSettlement from "../elements/errorSettlement.vue"
import canceledSettlements from "../elements/canceledSettlements.vue"
import leftModal from "@/components/modals/leftModal"
export default {
  components: {
    successFulSettlement,
    errorSettlement,
    pendingSettlements,
    canceledSettlements,
    leftModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectedSettlement: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      tab: 0,
      activeCanceledTab: false,
      canceledAll: false,
    }
  },
  computed: {
    isAllSettlementCanceled() {
      return (
        this.canceledAll ||
        (this.selectedSettlement.cancelable &&
          this.selectedSettlement.status.canceled_count ===
            this.selectedSettlement.status.total_count)
      )
    },
    pendingWithNoCancel() {
      return (
        !this.activeCanceledTab &&
        this.selectedSettlement.cancelable &&
        this.selectedSettlement.status.pending_count ===
          this.selectedSettlement.status.total_count
      )
    },
  },
  methods: {
    showOneTransactionDetails(id) {
      this.$emit("showOneTransactionDetails", id)
    },
    goToCanceledTab() {
      this.activeCanceledTab = true
      this.tab = 1
      this.$refs.canceledTab?.getCanceledSettlements()
    },
  },
}
</script>
<style lang="scss">
.batchesSettlement {
  .v-tabs {
    border-bottom: 1px solid #eee;
    &__item:not(.v-tabs__item--active):hover {
      color: #4c9f87;
    }
  }

  .layout.base {
    padding-top: 0px !important;
    .flex {
      background-color: transparent !important;
      padding: 0px !important;
      text-align-last: auto !important;
      border: none !important;
    }
  }

  .v-tabs__div {
    color: #999999 !important;
    .v-tabs__item--active {
      color: #4c9f87 !important;
    }
  }
}
</style>
