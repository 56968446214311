<template>
  <v-layout column wrap justify-center align-start>
    <v-layout
      justify-space-between
      :align-center="!$vuetify.breakpoint.xsOnly"
      :column="$vuetify.breakpoint.xsOnly"
      style="width: 100%"
    >
      <v-flex :class="{ 'd-flex align-center': $vuetify.breakpoint.xsOnly }">
        <h2 class="mb-4 mr-0 ml-auto">فرم پرداخت</h2>

        <v-btn
          v-role-permission:not="['observer', 'developer']"
          text
          depressed
          dark
          class="btn_small_normal mx-0 mt-0"
          :class="{ 'd-none': $vuetify.breakpoint.smAndUp }"
          @click="$refs.formTable.add_function()"
        >
          ساخت فرم
        </v-btn>
      </v-flex>
      <v-spacer />

      <v-flex class="d-flex justify-end align-start">
        <!--   search box   -->
        <div class="search-input backWhite">
          <v-text-field
            v-model="searchQuery"
            solo
            flat
            outlined
            hide-details
            placeholder="جستجوی فرم پرداخت"
            :prepend-inner-icon="searchQuery != null ? 'close' : 'search'"
            @keydown.enter="enterHandler($event)"
            @click:prepend-inner="deleteSearch()"
          />
          <v-btn
            text
            class="btn-search-inner"
            :disabled="disableSearch"
            @click="searchAndFilters()"
          >
            <v-icon size="20" class="white--text"> chevron_left </v-icon>
          </v-btn>
        </div>

        <!--   export form btn     -->
        <v-btn
          v-role-permission:not="['observer', 'developer']"
          class="btn_small_normal mt-0"
          :class="{
            'ml-0': $vuetify.breakpoint.xsOnly,
          }"
          dark
          depressed
          outlined
          text
          @click="openDownloadModal()"
        >
          <span> خروجی </span>
        </v-btn>

        <!--   new form btn     -->
        <v-btn
          v-role-permission:not="['observer', 'developer']"
          text
          depressed
          dark
          class="btn_small_normal mt-0"
          :class="{
            'd-none': $vuetify.breakpoint.xsOnly,
            'mx-0': $vuetify.breakpoint.smAndUp,
          }"
          @click="$refs.formTable.add_function()"
        >
          ساخت فرم
        </v-btn>
      </v-flex>
    </v-layout>
    <v-flex style="width: 100%">
      <table-filter
        :shake-it="shakeIt"
        :is-changed="isChanged"
        @clicked="setFilters()"
      >
        <VanSelect
          v-for="(element, index) in [
            formsStatus,
            formTransactionsStatus,
            dateOptions,
          ]"
          :key="'selectItem' + index"
          :select-list="element.list"
          :select-type="element.selectType"
          :reset-filter="resetFilter"
          @shakeIt="shakeIt = $event"
          @setResetFilter="isChanged = false"
          @selected="
            element.selectedItem = $event
            isChanged = true
          "
        ></VanSelect>
      </table-filter>

      <!--  filter's result bar     -->
      <v-layout
        v-if="showResultBar || isSearchApplied()"
        class="py-5 pr-1 pl-4 mb-5 van_color10"
      >
        <v-flex v-if="totalCount" class="font_2 py-1 px-5 d-flex align-center">
          <span class="bold ml-1"> {{ totalCount }}</span> فرم پرداخت
        </v-flex>
        <v-flex v-else class="font_2 py-1 px-5 d-flex align-center">
          فرمی با این مشخصات پیدا نشد
        </v-flex>

        <v-btn
          v-if="isSearchApplied()"
          text
          depressed
          outlined
          dark
          class="btn_small_normal ml-1"
          style="font-size: 10px !important; padding: 0 10px"
          @click="deleteSearch()"
        >
          <v-icon color="van_color03" size="15" class="ml-2"> close </v-icon>
          حذف جستجو
        </v-btn>

        <v-btn
          v-if="totalCount && showResultBar"
          text
          depressed
          outlined
          dark
          class="btn_small_normal mr-1 mr-auto"
          style="font-size: 10px !important; padding: 0 10px"
          @click="deleteAllFilters()"
        >
          <div></div>
          <v-icon color="van_color03" size="15" class="ml-2"> close </v-icon>
          حذف فیلتر‌ها
        </v-btn></v-layout
      >
      <request-money-table
        ref="formTable"
        :filters="filters"
        :show-result-bar="showResultBar || isSearchApplied()"
        @getTotal="totalCount = $event"
      />
    </v-flex>

    <v-dialog
      v-model="downloadStatus"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="left-modal"
      no-click-animation
      transition="slide-x-transition"
    >
      <request-money-download-manager
        v-if="downloadStatus"
        :search="filters.q"
        :filters="filters"
        :forms-status="formsStatus"
        :form-transactions-status="formTransactionsStatus"
        @closeModal="downloadStatus = false"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import RequestMoneyTable from "../elements/vandarTable.vue"
import TableFilter from "../../elements/tableFilter"
import VanSelect from "../../elements/vanSelect"
import RequestMoneyDownloadManager from "@/components/requestMoney/modals/downloadManager.vue"

export default {
  name: "Index",
  components: {
    RequestMoneyDownloadManager,
    VanSelect,
    TableFilter,
    RequestMoneyTable,
  },
  data() {
    return {
      totalCount: null,
      shakeIt: false,
      componentKey: 0,
      add: false,
      isChanged: false,
      searchQuery: null,
      disableSearch: true,
      xAxesValue: [],
      yAxesValue: [],
      sum: 0,
      status: false,
      resetFilter: false,
      showResultBar: false,
      filters: {
        status: "0",
        transactionsStatus: "0",
        duration: "0",
        fromDate: null,
        toDate: null,
        q: null,
      },
      formsStatus: {
        list: [
          {
            key: "0",
            value: "همه فرم‌ها",
          },
          {
            key: "active",
            value: "فعال",
          },
          {
            key: "inactive  ",
            value: "غیرفعال",
          },
        ],
        selectedItem: "0",
        selectType: "defaultSelect",
        //checkbox?
        isMultiple: false,
      },
      formTransactionsStatus: {
        list: [
          {
            key: "0",
            value: "همه وضعیت‌ها",
          },
          {
            key: "withTransactions",
            value: "با واریزی",
          },
          {
            key: "withoutTransactions",
            value: "بدون واریزی",
          },
        ],
        selectedItem: "0",
        selectType: "defaultSelect",
        //checkbox?
        isMultiple: false,
      },
      dateOptions: {
        list: [],
        selectedItem: "0",
        // date type(custom date picker
        selectType: "date",
        //checkbox?
        isMultiple: false,
      },

      //export data
      downloadStatus: false,
    }
  },
  watch: {
    searchQuery: function (newValue, oldValue) {
      if (newValue && newValue.length > 2) {
        this.disableSearch = false
      } else {
        this.disableSearch = true
      }
    },
  },

  methods: {
    openDownloadModal() {
      this.downloadStatus = true
    },
    deleteAllFilters() {
      this.filters.status = "0"
      this.filters.transactionsStatus = "0"
      this.filters.duration = "0"
      this.filters.fromDate = null
      this.filters.toDate = null
      this.filters.q = this.searchQuery
      this.showResultBar = false
      this.resetFilter = !this.resetFilter
      this.$refs.formTable.intersected()
    },
    deleteSearch() {
      this.searchQuery = null
      this.setFilters()
    },
    setFilters() {
      this.isChanged = false

      this.filters.status = this.formsStatus.selectedItem
      this.filters.transactionsStatus = this.formTransactionsStatus.selectedItem
      this.filters.q = this.searchQuery

      if (typeof this.dateOptions.selectedItem === "string") {
        this.filters.duration = this.dateOptions.selectedItem
        this.filters.fromDate = null
        this.filters.toDate = null
      } else {
        this.filters.duration = null
        if (this.dateOptions.selectedItem.fromDate) {
          this.filters.fromDate = this.dateOptions.selectedItem.fromDate
        } else {
          this.filters.fromDate = null
        }

        if (this.dateOptions.selectedItem.toDate) {
          this.filters.toDate = this.dateOptions.selectedItem.toDate
        } else {
          this.filters.toDate = null
        }
      }

      for (let el in this.filters) {
        if (el === "q") continue
        if (this.filters[el] && this.filters[el] !== "0") {
          this.showResultBar = true
        }
      }

      this.$refs.formTable.intersected()
    },
    close_modal(obj) {
      this.status = false
      this.add = false
    },
    searchAndFilters() {
      this.isChanged = false
      this.disableSearch = true
      this.setFilters()
      // this.$refs.formTable.intersected()
    },
    isSearchApplied() {
      return (
        this.searchQuery && this.searchQuery.length > 2 && this.disableSearch
      )
    },
    enterHandler(event) {
      if (
        event.key == "Enter" &&
        this.searchQuery &&
        this.searchQuery.length > 2 &&
        !this.disableSearch
      ) {
        this.searchAndFilters()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.search-input {
  position: sticky;
}
</style>
