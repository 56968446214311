<template>
  <GeneralCard>
    <div class="d-flex flex-column align-center">
      <v-flex class="ml-md-5 mb-5 mb-md-0">
        <v-avatar size="72" color="van_color11">
          <v-avatar
            size="48"
            icon
            color="van_color06"
          >
            <v-img
              max-width="24"
              max-height="24"
              class="rounded-0"
              :src="require('@/assets/img/newIcon/settlement_batch_gray.svg')"
            />
          </v-avatar>
        </v-avatar>
      </v-flex>
      <div class="font_13 mt-4">شما هنوز برداشت گروهی ثبت نکرده‌اید</div>
      <div class="font_2 mt-1">
        شرایط برداشت گروهی و فایل نمونه اکسل را، از طریق دکمه «راهنما و فایل
        نمونه» مشاهده کنید یا فایل اکسل را بارگذاری کنید.
      </div>
    </div>
    <!-- href="https://cdn.vandar.io/public/files/sample-batch-settlement.xlsx" -->
  </GeneralCard>
</template>

<script>
import { numbersHelper } from "@/mixins/numbersHelper"
import GeneralCard from "../../elements/generalCard"

export default {
  name: "EmptyBatchesSettlement",
  components: {
    GeneralCard,
  },
  mixins: [numbersHelper],
}
</script>

