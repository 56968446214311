<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1048_461)">
      <path
        d="M19.6588 10.0176C19.3668 8.24784 18.472 6.63727 17.1296 5.46512C15.7871 4.29298 14.0816 3.63317 12.3088 3.6001C10.9146 3.61542 9.55262 4.02569 8.37717 4.78438C7.20173 5.54307 6.25986 6.61984 5.65835 7.8926C4.15444 8.10073 2.77855 8.86081 1.79224 10.0283C0.805929 11.1959 0.277976 12.6894 0.30875 14.2251C0.271358 15.8749 0.881945 17.4724 2.00666 18.6674C3.13138 19.8625 4.67846 20.5575 6.30875 20.6001H19.3084C20.6672 20.5654 21.957 19.9865 22.8945 18.9905C23.832 17.9945 24.3407 16.6627 24.3088 15.2876C24.3355 13.9771 23.8708 12.7049 23.008 11.7272C22.1453 10.7494 20.9486 10.1386 19.6588 10.0176ZM19.3084 18.4751H6.30875C5.22157 18.4477 4.18961 17.9847 3.43963 17.1878C2.68966 16.3909 2.28305 15.3252 2.30915 14.2251C2.284 13.2016 2.63346 12.2048 3.29041 11.4262C3.94736 10.6475 4.86546 10.142 5.86835 10.0067L6.93875 9.88524L7.43915 8.87617C7.87653 7.94123 8.56526 7.14981 9.42646 6.59253C10.2877 6.03525 11.2866 5.73464 12.3088 5.7251C13.6084 5.75125 14.8582 6.23583 15.8424 7.09514C16.8265 7.95446 17.4833 9.13456 17.6992 10.4317L17.9992 12.0248L19.5292 12.1462C20.3148 12.2363 21.038 12.6229 21.5543 13.2289C22.0706 13.8349 22.3419 14.6157 22.3142 15.4153C22.2865 16.2149 21.9617 16.9745 21.4047 17.5424C20.8477 18.1103 20.0995 18.4447 19.3096 18.4787L19.3084 18.4751ZM8.30915 13.1626H10.8592V16.3501H13.7596V13.1626H16.3096L12.3088 8.9126L8.30915 13.1626Z"
        :fill="fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1048_461">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.308594)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "DownloadOutline",
  props: {
    fillColor: {
      type: String,
      default: "#666666",
    },
  },
}
</script>
