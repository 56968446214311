var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"mobile-style-row",on:{"click":_vm.goToTicket}},[_c('li',[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"chip"}})],1):_c('div',{staticClass:"item-status",style:({
        backgroundColor: _vm.returnDynamicContent(_vm.rowData.item.status)
          ?.iconBackgroundColor,
      })},[_c(_vm.returnDynamicContent(_vm.rowData.item.status)?.componentName,{tag:"component"}),_c('span',[_vm._v(_vm._s(_vm.returnDynamicContent(_vm.rowData.item.status)?.persianStatus))])],1)]),_c('li',[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"width":"60","type":"text"}})],1):_c('div',{staticClass:"item-id"},[_c('span',[_vm._v("شناسه")]),_vm._v(": "+_vm._s(_vm.rowData.item.id)+" ")])]),_c('li',{staticClass:"mb-4"},[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"width":"60%","type":"text"}})],1):_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.rowData.item.title)+" ")])]),_c('li',[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"width":"55%","type":"text"}})],1):_c('div',{staticClass:"grid-row"},[_c('span',{staticClass:"item-label"},[_vm._v("دسته‌بندی")]),_c('div',{staticClass:"item-value"},[_c('span',[_vm._v(" "+_vm._s(_vm.rowData.item.category?.parent?.title)+" ")]),(
            _vm.rowData.item.category?.parent?.title &&
            _vm.rowData.item.category?.title
          )?_c('span',[_vm._v(" / ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.rowData.item.category?.title)+" ")])])])]),_c('li',[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"width":"50%","type":"text"}})],1):_c('div',{staticClass:"grid-row"},[_c('span',{staticClass:"item-label"},[_vm._v(" تاریخ ایجاد ")]),_c('span',{staticClass:"item-value"},[_vm._v(" "+_vm._s(_vm.jalaliDate(_vm.rowData.item.created_at, "jYYYY/jM/jD - HH:mm"))+" ")])])]),_c('li',[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"width":"50%","type":"text"}})],1):_c('div',{staticClass:"grid-row"},[_c('span',{staticClass:"item-label"},[_vm._v(" بروزرسانی ")]),_c('span',{staticClass:"item-value"},[_vm._v(" "+_vm._s(_vm.jalaliDate(_vm.rowData.item.updated_at, "jYYYY/jM/jD - HH:mm"))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }