import { render, staticRenderFns } from "./TextMessageSvg.vue?vue&type=template&id=cd603b36&scoped=true&functional=true&"
import script from "./TextMessageSvg.vue?vue&type=script&lang=js&"
export * from "./TextMessageSvg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "cd603b36",
  null
  
)

export default component.exports