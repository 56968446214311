<template>
  <div>
    <v-card class="multi-box" height="100%">
      <v-toolbar flat height="60" class="fixed_toolbar">
        <v-btn icon @click.native="$emit('closeModal', { status: false })">
          <v-icon color="van_color03" size="21"> close </v-icon>
        </v-btn>
        <v-toolbar-title class="pr-0">
          <span
            v-if="loading"
            style="width: 100px; height: 1em"
            class="default_gray"
          />
          <span v-else
            >{{ $store.state.customer.selectedCustomer.customer.name }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-menu
          v-if="!['observer', 'operator'].includes($store.state.business.role)"
          transition="slide-y-transition"
          offset-y
          close-on-content-click
          bottom
          content-class="up_menu"
        >
          <!-- this on slot sometime is undefined -->
          <template #activator="{ on }">
            <v-btn icon size="24" fab small v-on="on">
              <v-icon color="van_color03" size="21" style="line-height: 40px">
                more_vert
              </v-icon>
            </v-btn>
          </template>

          <v-card class="drop_down_menu">
            <v-list class="menu_v_list">
              <v-list-item @click="open_edit()">
                <v-list-item-content>
                  <v-list-item-title>ویرایش اطلاعات</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item @click="deleteStatus = true">
                <v-list-item-content>
                  <v-list-item-title>حذف مشتری</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar>
      <v-tabs v-model="tab" class="pt-15">
        <!--        <v-tabs-slider color="van_green" />-->
        <v-tab class="tab-title px-1 mr-8"> در یک نگاه </v-tab>
        <v-tab class="tab-title px-1"> جزییات کیف‌پول </v-tab>
        <v-tab
          v-if="
            $store.state.business.hasOwnProperty('tools') &&
            $store.state.business.tools.subscription.is_created
          "
          class="tab-title px-1"
        >
          مجوزها
        </v-tab>
        <v-tab class="tab-title px-1"> شماره شباها </v-tab>
        <v-tab class="tab-title px-1"> اطلاعات هویتی </v-tab>
        <v-tab class="tab-title px-1"> شناسه واریز</v-tab>
      </v-tabs>
      <!--      </v-toolbar>-->

      <v-tabs-items v-model="tab" class="customerTabs">
        <v-tab-item>
          <summarize :loading="loading" />
          <wallet-balance :loading="loading" :desc="true" />
        </v-tab-item>
        <v-tab-item>
          <wallet-balance />
          <transactions :id="item.id" />
        </v-tab-item>
        <v-tab-item
          v-if="
            $store.state.business.hasOwnProperty('tools') &&
            $store.state.business.tools.subscription.is_created
          "
        >
          <authorization :id="item.id" />
        </v-tab-item>
        <v-tab-item>
          <Ibans
            :iban-items-total="ibanItemsTotal"
            :iban-items-per-page="ibanItemsPerPage"
            :iban-last-page="ibanLastPage"
          />
        </v-tab-item>
        <v-tab-item>
          <customer-information />
        </v-tab-item>
        <v-tab-item>
          <div class="pa-8">
            <general-card>
              <manual-cash-in />
            </general-card>
            <NewCashInIban />
          </div>
        </v-tab-item>
      </v-tabs-items>

      <!-- modals -->
      <v-dialog
        v-model="status1"
        content-class="vanmodal"
        transition="slide-x-transition"
        :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
        no-click-animation
        persistent
      >
        <add
          v-if="status1"
          type="edit"
          @closeModal="close_modal()"
          @updateTable="$emit('updateTable')"
        />
      </v-dialog>

      <!-- modals -->
      <v-dialog
        v-model="deleteStatus"
        width="360"
        content-class="confirmationMessage height206"
      >
        <v-card height="100%">
          <v-layout column justify-center align-center>
            <v-flex class="main-title font_1">
              درخواست حذف کاربر را دارید؟
            </v-flex>
            <v-flex class="main-des font_4">
              با حذف هر کاربر دسترسی‌هایی که او به شما داده است هم حذف شده و طرح
              برداشت خودکار ایشان لغو می‌شود.
            </v-flex>
            <v-flex>
              <v-btn
                text
                depressed
                outlined
                class="btn_small_normal"
                @click="deleteStatus = false"
              >
                خیر، منصرف شدم
              </v-btn>
              <v-btn
                text
                dark
                class="btn_small_normal error"
                @click="deleteCustomer()"
              >
                بلی، حذف شود
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import Authorization from "../elements/authorization.vue"
import Ibans from "../elements/ibans.vue"
import Summarize from "../elements/summarize.vue"
import Transactions from "../elements/transactions.vue"
import WalletBalance from "../elements/walletBalance.vue"
import CustomerInformation from "../elements/customerInformation.vue"
import add from "./add"
import GeneralCard from "@/components/elements/generalCard.vue"
import NewCashInIban from "@/components/report/modals/addCashIn/elements/NewCashInIban"
import ManualCashIn from "@/components/report/modals/addCashIn/elements/manualCashIn.vue"
import servicesRepository from "@/services/servicesRepository"
import { mapActions } from "vuex"

export default {
  name: "ShowCustomer",
  components: {
    NewCashInIban,
    ManualCashIn,
    GeneralCard,
    add,
    WalletBalance,
    Summarize,
    Authorization,
    Transactions,
    Ibans,
    CustomerInformation,
  },
  props: ["item"],
  data() {
    return {
      tab: 0,
      status1: false,
      deleteStatus: false,
      loading: true,
      ibanItemsPerPage: null,
      ibanItemsTotal: null,
      ibanLastPage: null,
    }
  },
  async mounted() {
    await Promise.all([
      this.getCustomerIbans(),
      this.$store.dispatch("retrieveSelectedCustomerSummarizeObject"),
      this.$store.dispatch("retrieveSelectedCustomerBalance"),
      this.$store.dispatch("retrieveSelectedCustomerTransactions"),
      this.$store.dispatch("retrieveSelectedCustomerInfo"),
    ])
    this.getCashInCode()
    this.loading = false
  },
  methods: {
    ...mapActions(["retrieveSelectedCustomerIbans"]),
    getCashInCode() {
      this.$http
        .get(
          this.$store.getters.apiUrlParamV3 +
            `/cst/${this.item?.id}/cash-in-code`
        )
        .then((response) => {
          this.$store.commit("changeCashInCode", response.data.data)
        })
    },

    open_edit() {
      this.$store.commit("callSecondEditCustomerModal", true)
      this.status1 = true
    },
    close_modal() {
      this.$store.commit("callSecondEditCustomerModal", false)
      this.status1 = false
    },
    deleteCustomer() {
      servicesRepository(this)
        .customers.httpDeleteCustomers()
        .then((response) => {
          this.deleteStatus = false
          this.$emit("updateTable")
          this.$emit("closeModal", {
            status: false,
          })
          this.close_modal()
        })
        .catch((error) => {})
    },
    update_form(form) {
      this.$emit("closeModal", {
        status: true,
      })
      this.close_modal()
    },
    getCustomerIbans() {
      const res = this.retrieveSelectedCustomerIbans()
      res.then((data) => {
        this.ibanItemsTotal = data.meta.total
        this.ibanItemsPerPage = data.meta.per_page
        this.ibanLastPage = data.meta.last_page
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-title {
  font-size: 12px;
}
.v-divider {
  margin: 10px 0;
}

.v-tabs {
  border-bottom: 1px solid #eee;
}

.multi-box {
  @media screen and (max-width: 600px) {
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 5%) !important;
  }

  .v-tooltip__content {
    min-width: auto !important;
    top: 39px !important;
    padding: 5px 8px !important;
  }

  > .layout > .flex:nth-child(3) {
    margin-top: -10px !important;
  }
}

.modal-boxes {
  margin: 30px -4.5px -10px !important;
}

.twoinone {
  height: 220px !important;

  > .layout {
    height: 220px !important;
    padding: 40px;

    .flex {
      text-align: center;

      &:last-child {
        border-right: 1px dashed $van_color05;
      }

      &.bot {
        padding-right: 20px;
        height: 100%;

        > div {
          padding: 15px 0;
          text-align: right;
        }

        > div:not(:last-child) {
          border-bottom: 1px dashed $van_color05;
        }
      }
    }
  }
}

.oneinone {
  height: 140px !important;

  > .layout {
    height: 140px !important;
    padding: 40px;

    .flex {
      text-align: center;
    }
  }
}
</style>

<style lang="scss" scoped></style>
