<template>
  <v-card>
    <v-form ref="form" v-model="valid">
      <v-layout column justify-center align-center>
        <v-flex>
          <h2>انتخاب تاریخ</h2>
        </v-flex>
        <div class="van_color11 py-3 px-2 mt-3 w-100">
          <v-flex>
            <v-layout
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
            >
              <v-flex class="label"> از تاریخ </v-flex>
              <v-flex class="withlabel date_group">
                <v-layout>
                  <v-flex>
                    <v-select
                      v-model="fromDay"
                      :items="fromDayItem"
                      solo
                      flat
                      height="40"
                      outlined
                      label="روز"
                    />
                  </v-flex>
                  <v-flex>
                    <v-select
                      v-model="fromMonth"
                      :items="monthItem"
                      solo
                      flat
                      height="40"
                      outlined
                      label="ماه"
                      @input="changeEndDay('fromMonth')"
                    />
                  </v-flex>
                  <v-flex>
                    <v-select
                      v-model="fromYear"
                      :items="yearItem"
                      solo
                      flat
                      height="40"
                      outlined
                      label="سال"
                      @input="checkForKabiseh('fromYear')"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
            >
              <v-flex class="label"> تا تاریخ </v-flex>
              <v-flex class="withlabel date_group">
                <v-layout>
                  <v-flex>
                    <v-select
                      v-model="toDay"
                      :items="toDayItem"
                      solo
                      flat
                      height="40"
                      outlined
                      label="روز"
                    />
                  </v-flex>
                  <v-flex>
                    <v-select
                      v-model="toMonth"
                      :items="monthItem"
                      solo
                      flat
                      height="40"
                      outlined
                      label="ماه"
                      @input="changeEndDay('toMonth')"
                    />
                  </v-flex>
                  <v-flex>
                    <v-select
                      v-model="toYear"
                      :items="yearItem"
                      solo
                      flat
                      height="40"
                      outlined
                      label="سال"
                      @input="checkForKabiseh('toYear')"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </div>

        <template v-if="timeDuration">
          <v-flex>
            <h2 class="mt-4">انتخاب بازه زمانی</h2>
          </v-flex>
          <div class="van_color11 py-3 px-2 mt-3 w-100">
            <v-flex>
              <v-layout
                :column="$vuetify.breakpoint.xsOnly"
                justify-start
                align-start
              >
                <v-flex class="label"> از زمان </v-flex>
                <v-flex class="withlabel date_group time_group">
                  <v-layout>
                    <v-flex>
                      <v-select
                        v-model="fromMinutes"
                        :items="minutesItem"
                        solo
                        flat
                        height="40"
                        outlined
                        label="دقیقه"
                      />
                    </v-flex>
                    <v-flex>
                      <v-select
                        v-model="fromHour"
                        :items="hourItem"
                        solo
                        flat
                        height="40"
                        outlined
                        label="ساعت"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout
                :column="$vuetify.breakpoint.xsOnly"
                justify-start
                align-start
              >
                <v-flex class="label"> تا زمان </v-flex>
                <v-flex class="withlabel date_group time_group">
                  <v-layout>
                    <v-flex>
                      <v-select
                        v-model="toMinutes"
                        :items="minutesItem"
                        solo
                        flat
                        height="40"
                        outlined
                        label="دقیقه"
                      />
                    </v-flex>
                    <v-flex>
                      <v-select
                        v-model="toHour"
                        :items="hourItem"
                        solo
                        flat
                        height="40"
                        outlined
                        label="ساعت"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </div>
        </template>
        <v-flex>
          <v-btn
            ref="btn"
            text
            dark
            class="btn_small_normal"
            :loading="loading"
            :disabled="diableSave"
            @click="sendForm()"
          >
            انتخاب تاریخ
          </v-btn>
          <v-btn
            text
            outlined
            class="btn_small_normal ml-1"
            @click="clearForm()"
          >
            منصرف شدم
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </v-card>
</template>

<script>
const jalaaliMoment = require("moment-jalaali")
jalaaliMoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "DateFilter",
  props: ["toDateProp", "fromDateProp", "timeDuration"],
  data() {
    return {
      valid: false,
      loading: false,
      fromHour: null,
      fromMinutes: null,
      fromDay: null,
      fromMonth: null,
      fromYear: null,
      toHour: null,
      toMinutes: null,
      toDay: null,
      toMonth: null,
      toYear: null,
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      yearItem: [1397, 1398, 1399, 1400, 1401, 1402],
      toEnd: 31,
      fromEnd: 31,
      alreadyFullday: true,
      alreadyFullmonth: true,
      alreadyFullyear: true,
      fromDate: null,
      toDate: null,
      fromDateText: null,
      toDateText: null,
    }
  },
  computed: {
    diableSave() {
      var result
      if (
        (this.toDay != null && this.toMonth != null && this.toYear != null) ||
        (this.fromDay != null &&
          this.fromMonth != null &&
          this.fromYear != null)
      ) {
        result = false
      } else result = true

      if (
        this.fromDay &&
        this.fromMonth &&
        this.fromYear &&
        this.toDay &&
        this.toMonth &&
        this.toYear
      ) {
        if (!this.fromDateIsLessThanToDate()) {
          result = true
        }
      }
      if (
        (this.fromHour !== null && this.fromMinutes === null) ||
        (this.fromHour === null && this.fromMinutes !== null) ||
        (this.toHour !== null && this.toMinutes === null) ||
        (this.toHour === null && this.toMinutes !== null)
      ) {
        result = true
      }

      return result
    },
    hourItem() {
      let temp = []
      for (let i = 0; i < 24; i++) {
        temp.push(i)
      }
      return temp
    },
    minutesItem() {
      let temp = []
      for (let i = 0; i < 60; i++) {
        temp.push(i)
      }
      return temp
    },
    fromDayItem() {
      var temp = []
      for (var i = 0; i < this.fromEnd; i++) {
        temp[i] = i + 1
      }
      return temp
    },
    toDayItem() {
      var temp = []
      for (var i = 0; i < this.toEnd; i++) {
        temp[i] = i + 1
      }
      return temp
    },
  },
  mounted() {
    this.initializeToDate()
    this.convertDatePropsToDate()
  },
  methods: {
    sLeapYear(year) {
      let ary = [1, 5, 9, 13, 17, 22, 26, 30]
      let b = year % 33
      if (ary.includes(b)) return true
      return false
    },
    convertDatePropsToDate() {
      if (this.fromDateProp !== null) {
        this.fromYear = this.extractYearOfDate(this.fromDateProp)
        this.fromMonth = this.extractMonthOfDate(this.fromDateProp)
        this.fromDay = this.extractDayOfDate(this.fromDateProp)

        if (this.fromDateProp?.length > 6) {
          this.fromHour = this.extractHourOfDate(this.fromDateProp)
          this.fromMinutes = this.extractMinuteOfDate(this.fromDateProp)
        }
      }
      if (this.toDateProp !== null) {
        this.toYear = this.extractYearOfDate(this.toDateProp)
        this.toMonth = this.extractMonthOfDate(this.toDateProp)
        this.toDay = this.extractDayOfDate(this.toDateProp)

        if (this.toDateProp?.length > 6) {
          this.toHour = this.extractHourOfDate(this.toDateProp)
          this.toMinutes = this.extractMinuteOfDate(this.toDateProp)
        }
      }
    },
    extractYearOfDate(date) {
      if (parseInt(date.substring(0, 2)) < 97) {
        return parseInt("14" + date.substring(0, 2))
      } else {
        return parseInt("13" + date.substring(0, 2))
      }
    },
    extractMonthOfDate(date) {
      if (date.substring(2, 3) === "0") {
        return this.monthItem[parseInt(date.substring(3, 4)) - 1]
      } else {
        return this.monthItem[parseInt(date.substring(2, 4)) - 1]
      }
    },
    extractDayOfDate(date) {
      if (date.substring(4, 5) === "0") {
        return parseInt(date.substring(5, 6))
      } else {
        return parseInt(date.substring(4, 6))
      }
    },
    extractHourOfDate(date) {
      if (date.substring(6, 7) === "0") {
        return parseInt(date.substring(7, 8))
      } else {
        return parseInt(date.substring(6, 8))
      }
    },
    extractMinuteOfDate(date) {
      if (date.substring(8, 9) === "0") {
        return parseInt(date.substring(9, 10))
      } else {
        return parseInt(date.substring(8, 10))
      }
    },
    convertDate() {
      if (this.fromDay && this.fromMonth && this.fromYear) {
        let fromYear = this.returnLastTwoDigitOfYear(this.fromYear)
        let fromMonth = this.returnMonthNumeric(this.fromMonth)
        let fromDay = this.makeDoubleDigit(this.fromDay)

        this.fromDate = `${fromYear}${fromMonth}${fromDay}`
        this.fromDateText = `از ${this.fromDay} ${this.fromMonth} ${fromYear}`

        if (this.fromHour !== null && this.fromMinutes !== null) {
          let fromHour = this.makeDoubleDigit(this.fromHour)
          let fromMinutes = this.makeDoubleDigit(this.fromMinutes)

          this.fromDate += `${fromHour}${fromMinutes}`
          this.fromDateText += ` ساعت ${fromHour}:${fromMinutes}`
        }
      }
      if (this.toDay && this.toMonth && this.toYear) {
        let toYear = this.returnLastTwoDigitOfYear(this.toYear)
        let toMonth = this.returnMonthNumeric(this.toMonth)
        let toDay = this.makeDoubleDigit(this.toDay)

        this.toDate = `${toYear}${toMonth}${toDay}`
        this.toDateText = `تا ${this.toDay} ${this.toMonth} ${toYear}`

        if (this.toHour !== null && this.toMinutes !== null) {
          let toHour = this.makeDoubleDigit(this.toHour)
          let toMinutes = this.makeDoubleDigit(this.toMinutes)

          this.toDate += `${toHour}${toMinutes}`
          this.toDateText += ` ساعت ${toHour}:${toMinutes}`
        }
      }
    },
    returnLastTwoDigitOfYear(year) {
      return year.toString().substring(2)
    },
    returnMonthNumeric(month) {
      let numericMonth = null
      let monthIndex = this.monthItem.findIndex((element) => element === month)
      numericMonth = monthIndex + 1
      if (numericMonth < 10) {
        numericMonth = `0${numericMonth}`
      }
      return numericMonth
    },
    makeDoubleDigit(input) {
      if (input < 10) {
        return `0${input}`
      } else {
        return input
      }
    },
    sendForm() {
      this.loading = true

      this.convertDate()

      //sendingForm
      this.$emit("closeModal", {
        status: true,
        toDate: this.toDate,
        toDateText: this.toDateText,
        fromDate: this.fromDate,
        fromDateText: this.fromDateText,
      })

      this.loading = false
    },
    checkForKabiseh(val) {
      switch (val) {
        case "toYear":
          if (this.toMonth === "اسفند" && !this.sLeapYear(this.toYear)) {
            this.toEnd = 29
            this.toDay = null
          } else {
            this.toEnd = 30
          }
          break
        case "fromYear":
          if (this.fromMonth === "اسفند" && !this.sLeapYear(this.fromYear)) {
            this.fromEnd = 29
            this.fromDay = null
          } else {
            this.fromEnd = 30
          }
          break
      }
    },
    changeEndDay(val) {
      if (val == "toMonth") {
        switch (this.toMonth) {
          case "مهر":
          case "آبان":
          case "آذر":
          case "دی":
          case "بهمن":
            this.toEnd = 30
            break
          case "اسفند":
            if (this.toYear && !this.sLeapYear(this.toYear)) {
              this.toEnd = 29
            } else {
              this.toEnd = 30
            }
            break
          default:
            this.toEnd = 31
            break
        }
        if (this.toDay > this.toEnd) {
          this.toDay = null
        }
      } else {
        switch (this.fromMonth) {
          case "مهر":
          case "آبان":
          case "آذر":
          case "دی":
          case "بهمن":
            this.fromEnd = 30
            break
          case "اسفند":
            if (this.fromYear && !this.sLeapYear(this.fromYear)) {
              this.fromEnd = 29
            } else {
              this.fromEnd = 30
            }
            break
          default:
            this.fromEnd = 31
            break
        }
        if (this.fromDay > this.fromEnd) {
          this.fromDay = null
        }
      }
    },
    clearForm() {
      // if we dont have a valid date (!this.diableSave) set date to last date
      if (this.diableSave) this.$emit("closeModal", { status: false })

      // else -> send form with props values we passed
      this.convertDatePropsToDate()
      this.sendForm()
    },
    fromDateIsLessThanToDate() {
      this.convertDate()
      jalaaliMoment().format()

      var firstDate = ""
      if (this.fromDate.slice(0, 2) > 90) {
        firstDate = "13" + this.fromDate.slice(0, 2)
      } else {
        firstDate = "14" + this.fromDate.slice(0, 2)
      }
      firstDate =
        firstDate +
        "/" +
        this.fromDate.slice(2, 4) +
        "/" +
        this.fromDate.slice(4, 6)

      var lastDate = ""
      if (this.toDate.slice(0, 2) > 90) {
        lastDate = "13" + this.toDate.slice(0, 2)
      } else {
        lastDate = "14" + this.toDate.slice(0, 2)
      }
      lastDate =
        lastDate + "/" + this.toDate.slice(2, 4) + "/" + this.toDate.slice(4, 6)

      var date1 = jalaaliMoment(firstDate, "jYYYY/jM/jD")
      var date2 = jalaaliMoment(lastDate, "jYYYY/jM/jD")
      var diff = date2.diff(date1, "days")
      if (parseInt(diff) < 0) {
        return false
      } else return true
    },
    initializeToDate() {
      const newDate = new Date()
      this.toDay = parseInt(
        new Intl.DateTimeFormat("en-US-u-ca-persian", {
          day: "numeric",
        }).format(newDate)
      )

      this.toMonth = new Intl.DateTimeFormat("fa-IR", {
        month: "short",
      }).format(newDate)

      this.toYear = parseInt(
        new Intl.DateTimeFormat("en-US-u-ca-persian", {
          year: "numeric",
        }).format(newDate)
      )
    },
  },
}
</script>
