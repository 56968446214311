<template>
  <div class="d-flex flex-column">
    <v-radio
      v-if="show"
      class="mb-3"
      :style="disable ? 'order:2;margin-top:12px' : ''"
      :value="value"
      color="van_green"
      :disabled="disable"
    >
      <template #label>
        <div class="d-flex justify-space-between w-100 font_2 align-start">
          <span class="bold d-flex flex-column align-start">
            {{ label }}
            <slot name="hint"></slot>
          </span>
          <!--skeleton-->
          <div v-if="skeleton" class="d-flex align-center">
            <span class="default_gray van_color04 mx-auto my-0 px-11"></span>
          </div>
          <!-- time prediction or disable reason-->
          <div
            v-else
            :class="{
              'font_31 d-flex align-center text-right': disable,
              shake: isShaking,
            }"
          >
            {{ extraInfo }}
            <slot name="extraInfoSlot"></slot>
          </div>
        </div>
      </template>
    </v-radio>
    <slot name="infoBox" />
  </div>
</template>

<script>
export default {
  name: "VanSettlementMethodRadioBtn",
  props: {
    value: {
      default: null,
      type: String,
    },
    show: {
      default: null,
      type: Boolean,
    },
    disable: {
      default: true,
      type: Boolean,
    },
    label: {
      default: null,
      type: String,
    },
    extraInfo: {
      default: null,
      type: String,
    },
    skeleton: {
      default: null,
      type: Boolean,
    },
    shake: {
      default: null,
      type: Boolean,
    },
  },
  computed: {
    isShaking() {
      return this.shake
    },
  },
  watch: {},
}
</script>
<style lang="scss" scoped>
:deep(.v-radio) {
  align-items: flex-start !important;
}
</style>
