<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('close')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> تنظیمات پرداخت یکپارچه </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="disabled"
          :loading="loading"
          @click="sendForm()"
          >ثبت درخواست</v-btn
        >
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-card flat>
          <v-layout>
            <v-flex>
              <v-form ref="form">
                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-space-between
                  align-start
                >
                  <v-flex class="label" style="width: 110px">
                    آدرس وب‌سایت
                    <span>*</span>
                  </v-flex>
                  <v-flex class="withlabel big comboboxSmallchip">
                    <v-combobox
                      v-model="domains"
                      hide-selected
                      multiple
                      small-chips
                      solo
                      flat
                      outlined
                      hint="بعد از وارد کردن هر آدرس، دکمه «اینتر» را بزنید. آدرس را با http یا https وارد کنید."
                      deletable-chips
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-combobox>
                  </v-flex>
                </v-layout>

                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-space-between
                  align-start
                >
                  <v-flex class="label">
                    آی‌ پی‌های شما
                    <span>*</span>
                  </v-flex>
                  <v-flex class="withlabel big comboboxSmallchip">
                    <v-combobox
                      v-model="ips"
                      hide-selected
                      multiple
                      small-chips
                      solo
                      flat
                      outlined
                      hint="بعد از وارد کردن هر آی‌‌پی، دکمه «اینتر» را بزنید"
                      deletable-chips
                      @keypress="checkIps($event)"
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-combobox>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "Add",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      subscription: {},
      domains: [],
      ips: [],
      selectItems: ["از خودم کسر شود", "از پرداخت‌کننده دریافت شود"],
      panel: [false],
    }
  },
  computed: {
    disabled() {
      if (this.domains.length && this.ips.length) {
        return false
      }
      return true
    },
  },
  methods: {
    checkIps(event) {
      if (
        !/(^[0-9]*$)/.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "."
      ) {
        event.preventDefault()
      }
    },
    sendForm() {
      const body = {
        types: this.$store.state.mpg.mpgTypes,
        ips: this.ips,
        domains: this.domains,
      }
      this.$emit("submit", body)
    },
  },
}
</script>
