<template>
  <v-dialog
    v-model="active"
    :width="width"
    :content-class="contentClass"
    no-click-animation
    persistent
  >
    <v-card outlined :style="customStyle" height="100%">
      <v-layout column justify-center align-center>
        <v-flex v-if="logo" :class="logoBoxClass">
          <v-img
            :width="imageWidth"
            class="mb-3"
            :src="require('@/assets/img/' + logo)"
          />
        </v-flex>
        <v-flex v-if="title" class="main-title text-center font_9">
          {{ title }}
        </v-flex>
        <v-flex v-if="description" :class="descriptionClass">
          {{ description }}
        </v-flex>
        <slot name="extra" />
        <v-flex>
          <v-btn
            v-if="btn1Text"
            text
            :dark="btn1DarkMode"
            :outlined="btn1Outlined"
            :loading="btn1Loading"
            :disabled="btn1Disabled"
            :class="btn1Class"
            target="_blank"
            @click="$emit('btn1Clicked')"
          >
            {{ btn1Text }}
          </v-btn>
          <v-btn
            v-if="btn2Text"
            text
            :dark="btn2DarkMode"
            :outlined="btn2Outlined"
            :loading="btn2Loading"
            :disabled="btn2Disabled"
            :class="btn2Class"
            @click="$emit('btn2Clicked')"
          >
            {{ btn2Text }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <slot name="bottom" />
  </v-dialog>
</template>

<script>
export default {
  name: "GeneralDialog",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    btn1Text: {
      type: String,
      default: null,
    },
    btn1Loading: {
      type: Boolean,
      default: false,
    },
    btn1Disabled: {
      type: Boolean,
      default: false,
    },
    btn1Class: {
      type: String,
      default: "btn_small_normal primary",
    },
    btn2Text: {
      type: String,
      default: null,
    },
    btn2Loading: {
      type: Boolean,
      default: false,
    },
    btn2Disabled: {
      type: Boolean,
      default: false,
    },
    btn2Class: {
      type: String,
      default: "depressed btn_small_normal",
    },
    width: {
      type: Number,
      default: 360,
    },
    imageWidth: {
      type: Number,
      default: 64,
    },
    contentClass: {
      type: String,
      default: null,
    },
    customStyle: {
      type: String,
      default: "none",
    },
    descriptionClass: {
      type: String,
      default: "main-des font_4",
    },
    logoBoxClass: {
      type: String,
      default: "",
    },
    btn1Outlined: {
      type: Boolean,
      default: false,
    },
    btn1DarkMode: {
      type: Boolean,
      default: true,
    },
    btn2Outlined: {
      type: Boolean,
      default: true,
    },
    btn2DarkMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showLogo() {
      return this.logo
    },
  },
}
</script>

<style scoped></style>
