<template>
  <div>
    <OverViewDepositEChart :kerdar-lang="kerdarLang" />
    <OverViewSuccessDepositEChart :kerdar-lang="kerdarLang" />
    <OverViewTurnOversEChartApiV2 :kerdar-lang="kerdarLang" />
    <AllServiceAverageIncomeChart :kerdar-lang="kerdarLang" />
  </div>
</template>

<script>
import OverViewSuccessDepositEChart from "./elements/OverViewSuccessDepositEChart"
import AllServiceAverageIncomeChart from "./elements/AllServiceAverageIncomeChart"
import OverViewDepositEChart from "./elements/OverViewDepositEChart"
import OverViewTurnOversEChartApiV2 from "./elements/OverViewTurnOversEChartApiV2"
import kerdarLang from "@/components/kerdar/utils/langResource.json"
// import { shouldShowComponent } from "@/components/kerdar/utils/generalMethods.js"

export default {
  name: "Overview",
  components: {
    AllServiceAverageIncomeChart,
    OverViewDepositEChart,
    OverViewSuccessDepositEChart,
    // OverViewTurnOversEChart,
    OverViewTurnOversEChartApiV2,
  },
  data() {
    return {
      kerdarLang,
      // shouldShowComponent,
      // whiteListBusinessNames: ["Mahdi", "ronia"],
    }
  },
}
</script>

<style scoped></style>
