<template>
  <v-layout class="filter_row" justify-start align-center wrap>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="kindFilter"
        :items="kindFilterItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="همه تراکنش‌ها"
        style="max-width: 160px"
        :menu-props="{ contentClass: 'withActiveStyle' }"
      >
        <template #item="data">
          <v-layout align-center justify-center>
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="statusFilters"
        :items="statusFilterItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="همه وضعیت‌ها"
        style="max-width: 160px"
        :menu-props="{ contentClass: 'withActiveStyle', maxHeight: '365px' }"
      >
        <template #item="data">
          <v-layout align-center justify-center>
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        v-model="channelFilter"
        :items="channelItem"
        item-text="value"
        item-value="key"
        solo
        flat
        :disabled="channelItem && channelItem.length === 0"
        height="40"
        outlined
        label="انتخاب ابزار"
        style="max-width: 160px"
        multiple
        :menu-props="{
          contentClass: 'my-awesome-list',
          maxHeight: '404px',
          offsetY: false,
        }"
      >
        <template v-if="channelItem" #selection="{ item, index }">
          <span v-if="index === 0"> {{ item.value }} </span>
          <span v-if="index === 1" class="pr-1">
            ({{ channelFilter.length - 1 }}+ )
          </span>
        </template>
        <template #no-data>
          <v-layout column justify-center align-center>
            <v-flex>
              <img width="24" src="@/assets/img/newIcon/loading-circle.gif" />
            </v-flex>
          </v-layout>
        </template>
        <!-- <template v-slot:item="{ item}">
          <v-checkbox
            v-model="channelFilter"
            :label="item.value"
          ></v-checkbox>
        </template> -->
      </v-select>
      <!-- <v-select
        solo
        flat
        style="max-width: 160px"
        label="انتخاب ابزار"
        multiple
        v-else
       >
        loading
      </v-select> -->
    </v-flex>
    <v-flex class="selectFilter" style="max-width: 160px">
      <v-select
        ref="selectBox"
        v-model="duration"
        :items="dateItem"
        item-text="value"
        item-value="key"
        solo
        flat
        height="40"
        outlined
        label="همه وضعیت‌ها"
        style="max-width: 160px"
        :menu-props="{ maxHeight: '360px', contentClass: 'withActiveStyle' }"
      >
        <template #selection="{ item }">
          <span
            v-if="
              duration === '5' && (fromDateText != null || toDateText != null)
            "
            style="
              font-size: 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 120px;
              overflow: hidden;
            "
          >
            {{ fromDateText }} {{ toDateText }}
          </span>
          <span v-else>{{ item.value }}</span>
        </template>
        <template #item="data">
          <v-layout
            align-center
            justify-center
            @click="openDialog(data.item.key)"
          >
            <v-flex class="font_2">
              {{ data.item.value }}
            </v-flex>
            <v-flex class="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 24 24"
                width="18"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4C9F87"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                  style="&#10;"
                />
              </svg>
            </v-flex>
          </v-layout>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="filterBut">
      <v-btn
        text
        dark
        :class="addShake ? 'btn_small_normal shake' : 'btn_small_normal'"
        :loading="loadingEffect"
        :disabled="disableFilter"
        @click="putFilter()"
      >
        اعمال فیلتر‌ها
      </v-btn>
    </v-flex>
    <!-- :disabled="disableFilter" -->
    <!-- modals -->

    <v-dialog v-model="filterDateStatus" width="360" persistent>
      <DatePickerModal
        v-if="filterDateStatus"
        @send-form="sendDateForm"
        @close-date-picker-modal="filterDateStatus = false"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import DatePickerModal from "@/components/report/modals/DatePickerModal/DatePickerModal.vue"
import { mapState } from "vuex"

export default {
  name: "TableFilter",
  components: {
    DatePickerModal,
  },
  provide: {
    selectedFromDate: this?.wholeFromDate,
    selectedToDate: this?.wholeToDate,
    shouldReset: false,
  },
  data() {
    return {
      addShake: false,
      //filter models
      fromDate: null,
      toDate: null,
      duration: "0",
      kindFilter: "0",
      statusFilters: "0",
      channelFilter: null,
      kindFilterItem: [
        {
          key: "0",
          value: "همه تراکنش‌ها",
        },
        {
          key: "transactions",
          value: "واریز",
        },
        {
          key: "settlements",
          value: "برداشت",
        },
      ],
      dateItem: [
        {
          key: "0",
          value: "از ابتدای ثبت‌نام تاکنون",
        },
        {
          key: "yesterday",
          value: "دیروز",
        },
        {
          key: "day",
          value: "روز جاری",
        },
        {
          key: "week",
          value: "هفته جاری",
        },
        {
          key: "month",
          value: "ماه جاری",
        },
        {
          key: "year",
          value: "سال جاری",
        },
        {
          key: "5",
          value: "بازه زمانی دلخواه",
        },
      ],
      filterDateStatus: false,
      fromDateText: null,
      toDateText: null,
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      lastDuration: "0",
    }
  },
  computed: {
    ...mapState([
      "isRoutedFromSuspiciousItemsModal",
      "shouldFilterWithCashInByCode",
    ]),
    loadingEffect() {
      return !!this.$store.state.report.loading
    },
    channelFilterImported() {
      if (this.channelFilter == null) return false
      else return !(this.channelFilter.length === 0)
    },
    disableFilter() {
      if (
        this.kindFilter === "0" &&
        this.statusFilters === "0" &&
        this.duration === "0" &&
        !this.channelFilterImported &&
        this.toDate == null &&
        this.fromDate == null &&
        !this.$store.getters.isFilterApplied
      )
        return true
      else return false
    },
    statusFilterItem() {
      switch (this.kindFilter) {
        case "transactions":
          return [
            {
              key: "0",
              value: "همه وضعیت‌ها",
            },
            {
              key: "succeed",
              value: "موفق",
            },
            {
              key: "failed",
              value: "ناموفق",
            },
            {
              key: "pending_transactions",
              value: "در صفحه پرداخت",
            },
            {
              key: "revised",
              value: "اصلاح شده",
            },
          ]
        case "settlements":
          return [
            {
              key: "0",
              value: "همه وضعیت‌ها",
            },
            {
              key: "succeed",
              value: "موفق",
            },
            // {
            //   key: 'failed',
            //   value: 'ناموفق',
            // },
            {
              key: "pending_settlements",
              value: "در صف بانک",
            },
            {
              key: "canceled",
              value: "برگشتی",
            },
          ]
        default:
          return [
            {
              key: "0",
              value: "همه وضعیت‌ها",
            },
            {
              key: "succeed",
              value: "موفق",
            },
            {
              key: "failed",
              value: "ناموفق",
            },
            {
              key: "pending_settlements",
              value: "در صف بانک",
            },
            {
              key: "pending_transactions",
              value: "در صفحه پرداخت",
            },
            {
              key: "revised",
              value: "اصلاح شده",
            },
            {
              key: "canceled",
              value: "برگشتی",
            },
          ]
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    channelItem() {
      if (
        this.$store.state[this.$store.getters.userOrBuisenss].hasOwnProperty(
          "tools"
        )
      ) {
        // tools list of business/user
        const tools =
          this.$store.state[this.$store.getters.userOrBuisenss].tools

        var arrayItems = []

        //filter list of tools based on is_created, and convert it to array
        for (var toolName in tools) {
          if (tools[toolName].is_created && this.toolsInfo(toolName)) {
            arrayItems.push({
              key:
                toolName === "ipg"
                  ? this.toolKeyMapper(toolName) + ",mpg-ipg"
                  : this.toolKeyMapper(toolName), // tools english name
              value: this.toolsInfo(toolName)[0], //tools perisan name
              kindFilter: this.toolsInfo(toolName)[1], // tools type(transaction, settlment, both)
            })
            arrayItems.push({ divider: true })
          }
        }

        //we always want 'تسویه و تسهیم' item to be listed, when kindFilter = settlement
        arrayItems.push({
          key: "settlements",
          value: "تسویه و تسهیم",
          kindFilter: "settlements",
        })
        arrayItems.push({ divider: true })

        arrayItems.push({
          key: "shaparak_settlement",
          value: "تسویه شاپرک",
          kindFilter: "shaparak_settlement",
        })
        arrayItems.push({ divider: true })

        arrayItems.push({
          key: "dedicated_channel",
          value: "حساب اختصاصی",
          kindFilter: "transactions",
        })
        arrayItems.push({ divider: true })

        arrayItems.push({
          key: "inquiry",
          value: "استعلام مشتریان",
          kindFilter: "settlements",
        })
        arrayItems.push({ divider: true })

        arrayItems.push({
          key: "settlement_pro",
          value: "تسویه از حساب",
          kindFilter: "settlements",
        })
        arrayItems.push({ divider: true })

        if (
          !this.$store.state.isUser &&
          this.$store.state.business.hasOwnProperty("tools") &&
          this.$store.state.business.tools["MPG"]?.is_created
        ) {
          arrayItems.push({
            key: "MPG-SUBSCRIPTION",
            value: "پرداخت از حساب",
            kindFilter: "transactions",
          })
          arrayItems.push({ divider: true })

          arrayItems.push({
            key: "CREDIT",
            value: "پرداخت اعتباری",
            kindFilter: "transactions",
          })
          arrayItems.push({ divider: true })
        }

        if (this.$store.state.business.cash_in_code) {
          arrayItems.push({
            key: "cash-in-by-code",
            value: "واریز با شناسه",
            kindFilter: "transactions",
          })
        }

        //list of tools for transactions (واریز)
        const transactionList = arrayItems.filter(
          (i) => i.kindFilter === "transactions" || i.kindFilter === "both"
        )

        //list of tools for settlement (برداشت)
        const settlementList = arrayItems.filter(
          (i) =>
            i.kindFilter === "settlements" ||
            i.kindFilter === "both" ||
            i.kindFilter === "shaparak_settlement"
        )

        if (this.kindFilter === "transactions") {
          return transactionList
        } else if (this.kindFilter === "settlements") {
          return settlementList
        }

        //return all the list if both are selected( واریز و برداشت)
        return arrayItems
      }
    },
    reportUpdateFilters() {
      return this.$store.state.report.updateFilters
    },
  },
  watch: {
    reportUpdateFilters: {
      handler(newValue) {
        if (newValue) {
          this.getFilters()
          this.$store.commit("syncFilterDone")
          this.lastDuration = "0"
          this.fromDateText = null
          this.toDateText = null
          if (!this.shouldFilterWithCashInByCode) this.$emit("putFilter")
        }
      },
      deep: true,
    },
    $route: {
      handler(to, from) {
        this.$store.dispatch("deleteAllReportFilters", false)
      },
      immediate: true,
    },
    "$route.params.filtered": {
      handler: function (newValue) {
        if (
          [
            "successfullTransaction",
            "successfullTransactionSubscription",
            "successfullTransactionRequestMoney",
            "successfulTransactionPbv",
          ].includes(newValue)
        ) {
          let data = {
            statusFilters: "succeed",
            channelFilter: ["ipg"],
            kindFilter: "transactions",
          }
          if (newValue === "successfullTransactionSubscription") {
            data["channelFilter"] = ["subscription"]
          }
          if (newValue === "successfullTransactionRequestMoney") {
            data["channelFilter"] = ["form"]
          }
          if (newValue === "successfulTransactionPbv") {
            data["channelFilter"] = ["DIRECT_DEBIT"]
          }
          this.$store.commit("updatePartOfFilters", data)
        }
      },
      deep: true,
      immediate: true,
    },
    kindFilter: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
    statusFilters: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
    channelFilter: function () {
      this.addShake = true
      const timeOut = setTimeout(() => this.disableAddShake(), 300)
    },
    duration(newValue) {
      this._provided.shouldReset = true
      if (newValue !== "5") {
        this.addShake = true
        const timeOut = setTimeout(() => this.disableAddShake(), 300)
      }
    },
    fromDate: function (newValue) {
      if (newValue != null) {
        this.addShake = true
        const timeOut = setTimeout(() => this.disableAddShake(), 300)
      }
    },
    toDate: function (newValue) {
      if (newValue != null) {
        this.addShake = true
        const timeOut = setTimeout(() => this.disableAddShake(), 300)
      }
    },
    shouldFilterWithCashInByCode: {
      handler(value) {
        if (value && this.isRoutedFromSuspiciousItemsModal) {
          this.channelFilter = ["cash-in-by-code"]
          this.putFilter()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getFilters()
  },
  beforeDestroy() {
    this.$store.commit("checkIsRoutedFromSuspiciousItemsModal", false)
  },
  methods: {
    toolKeyMapper(toolName) {
      const map = {
        requestMoney: "form",
        PBV: "DIRECT_DEBIT",
      }
      return map[toolName] ?? toolName
    },

    toolsInfo(toolName) {
      switch (toolName) {
        case "ipg":
          return ["درگاه پرداخت", "transactions"]
        case "subscription":
          return ["پرداخت خودکار", "transactions"]
        case "pos":
          return ["کارتخوان", "transactions"]
        case "p2p":
          return ["انتقال وجه", "both"]
        case "cash-in":
          return ["واریز بانکی", "transactions"]
        case "settlements":
          return ["تسویه و تسهیم", "settlements"]
        case "refund":
          return ["بازگشت وجه", "settlements"]
        case "requestMoney":
          return ["فرم پرداخت", "transactions"]
        case "shaparak_settlement":
          return ["تسویه شاپرک", "shaparak_settlement"]
      }
    },
    disableAddShake() {
      this.addShake = false
    },
    openDialog(item) {
      this.duration = item
      if (item === "5") {
        this.filterDateStatus = true
      } else {
        this.toDateText = null
        this.fromDateText = null
        this.fromDate = null
        this.toDate = null
        this.lastDuration = this.duration
        this.$store.commit("updateToAndFormDate", null)
      }
    },
    getFilters() {
      this.fromDate = this.$store.state.report.fromDate
      this.toDate = this.$store.state.report.toDate
      this.duration = this.$store.state.report.duration
      this.kindFilter = this.$store.state.report.kindFilter
      this.statusFilters = this.$store.state.report.statusFilters
      this.channelFilter = this.$store.state.report.channelFilter
    },
    putFilter() {
      if (this.kindFilter === "transactions") {
        if (this.statusFilters === "canceled") {
          this.statusFilters = "0"
        }
        if (this.channelFilterImported) {
          let i
          for (i = this.channelFilter.length - 1; i >= 0; i -= 1) {
            if (this.channelFilter[i] === "settlements") {
              this.channelFilter.splice(i, 1)
            }
          }
        }
      }
      if (this.kindFilter === "settlements") {
        if (this.statusFilters === "failed") {
          this.statusFilters = "0"
        }

        if (this.channelFilterImported) {
          let j
          for (j = this.channelFilter.length - 1; j >= 0; j -= 1) {
            if (
              this.channelFilter[j] === "ipg" ||
              this.channelFilter[j] === "form" ||
              this.channelFilter[j] === "subscription" ||
              this.channelFilter[j] === "pos" ||
              this.channelFilter[j] === "BNPL_TARA" ||
              this.channelFilter[j] === "DIRECT_DEBIT"
            ) {
              this.channelFilter.splice(j, 1)
            }
          }
        }
      }
      var tempStatusFilters = this.statusFilters
      var tempKindFilter = this.kindFilter

      if (
        this.statusFilters === "pending_transactions" &&
        (this.kindFilter === 0 || this.kindFilter === "transactions")
      ) {
        tempStatusFilters = "pending"
        tempKindFilter = "transactions"
      } else if (
        this.statusFilters === "pending_transactions" &&
        this.kindFilter === "settlements"
      ) {
        this.statusFilters = "0"
        tempStatusFilters = this.statusFilters
      }

      if (
        this.statusFilters === "pending_settlements" &&
        (this.kindFilter === 0 || this.kindFilter === "settlements")
      ) {
        tempStatusFilters = "pending"
        tempKindFilter = "settlements"
      } else if (
        this.statusFilters === "pending_settlements" &&
        this.kindFilter === "transactions"
      ) {
        this.statusFilters = "0"
        tempStatusFilters = this.statusFilters
      }

      let data = {
        duration: this.duration,
        statusFilters: tempStatusFilters,
        channelFilter: this.channelFilter,
        kindFilter: tempKindFilter,
        toDate: this.toDate,
        fromDate: this.fromDate,
      }
      this.$store.commit("updateFilters", data)
      this.$emit("putFilter")
    },
    sendDateForm(payload) {
      this.filterDateStatus = false
      this.fromDate = payload.fromDate
      this.toDate = payload.toDate
      this.fromDateText = payload.fromDateText
      this.toDateText = payload.toDateText

      this._provided.selectedFromDate = payload.wholeFromDate
      this._provided.selectedToDate = payload.wholeToDate
      this._provided.shouldReset = false
    },
  },
}
</script>
