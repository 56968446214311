<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> شباهای اختصاصی کسب‌و‌کار </v-toolbar-title>
      <v-spacer />
      <v-btn text dark class="btn_small_normal" @click="open_addsheba">
        افزودن شماره شبا
      </v-btn>
    </v-toolbar>
    <v-layout column>
      <v-flex v-if="$store.state?.business?.need_shaparak_iban" class="mb-8">
        <v-card flat>
          <v-layout>
            <v-flex>
              <v-layout align-center>
                <v-flex style="max-width: 56px; margin-left: 10px">
                  <v-img
                    :src="require('@/assets/img/newIcon/yellowInfo.svg')"
                    size="24"
                  >
                  </v-img>
                </v-flex>
                <v-flex>
                  <div
                    v-if="$store.state?.business?.need_shaparak_iban"
                    class="font_2"
                  >
                    شما هنوز حسابی را به عنوان حساب تسویه شاپرکی انتخاب
                    نکرده‌اید. برای انتخاب شبا از طریق آیکن
                    <v-icon class="justify-center" size="24">
                      more_vert
                    </v-icon>
                    اقدام کنید. شماره شبا باید به نام
                    <span>
                      {{ persianTextOfBusinessType }}
                    </span>
                    کسب‌و‌کار باشد. در غیر اینصورت شماره شبایی که درهنگام ایجاد
                    کسب‌و‌کار معرفی کرده‌اید مد نظر قرار داده خواهد شد.
                  </div>
                  <div v-else-if="pendingStatusForShaparakIban" class="font_2">
                    تا زمان تایید شماره شبا توسط شاپرک،‌ ترمینال شما قطع است.
                    حداکثر زمان انتظار، طبق اعلام شاپرک ۲۴ ساعت کاری است.
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex
        v-if="
          ibansLoad &&
          shaparakIbanIsLoaded &&
          !isThereDefaultIban &&
          ibans.length === 0
        "
        class="mb-8"
      >
        <v-card flat>
          <v-layout>
            <v-flex>
              <v-layout align-center>
                <v-flex style="max-width: 56px; margin-left: 10px">
                  <v-img
                    :src="require('@/assets/img/newIcon/yellowInfo.svg')"
                    size="24"
                  >
                  </v-img>
                </v-flex>
                <v-flex>
                  <div class="font_2">
                    شباهای اختصاصی برای شارژ کیف‌پول با شناسه واریز اختصاصی
                    کسب‌و‌کار استفاده می‌شوند و باید به نام حقوقی و یا به نام
                    صاحبین امضای همان کسب‌و‌کار باشند.
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex v-if="!ibansLoad && !shaparakIbanIsLoaded">
        <v-skeleton-loader height="130" type="image"></v-skeleton-loader>
      </v-flex>
      <v-flex
        v-if="
          defaultIbanInShaparak && !$store.state?.business?.need_shaparak_iban
        "
        class="mb-8"
      >
        <v-card flat color="van_color11">
          <v-layout class="shebaList" column>
            <v-flex style="width: 100%">
              <v-layout
                :class="{
                  'align-center': !reuseIsMobile,
                  'd-flex flex-column': reuseIsMobile,
                }"
              >
                <div class="d-flex">
                  <v-flex
                    style="
                      max-width: 60px;
                      padding: 15px;
                      margin-left: 10px;
                      border: 1px solid #e5e5e5;
                      background-color: #ffffff;
                    "
                  >
                    <v-img
                      width="30"
                      :src="
                        require(`@/assets/img/banks/${parseInt(
                          defaultIbanInShaparak?.iban?.slice(4, 7)
                        )}.svg`)
                      "
                    />
                  </v-flex>
                  <v-flex>
                    <div
                      v-if="
                        defaultIbanInShaparak.hasOwnProperty('ibanInfo') &&
                        defaultIbanInShaparak.ibanInfo?.account_owner?.length >
                          0
                      "
                      class="font_2 bold"
                    >
                      {{
                        defaultIbanInShaparak?.ibanInfo?.account_owner[0]
                          ?.firstName ?? ""
                      }}
                      {{
                        defaultIbanInShaparak?.ibanInfo?.account_owner[0]
                          ?.lastName ?? ""
                      }}
                    </div>
                    <div class="font_2">
                      {{ defaultIbanInShaparak?.iban }}
                    </div>
                  </v-flex>
                </div>
                <v-spacer />
                <v-flex
                  :class="{ 'mt-2': reuseIsMobile }"
                  style="max-width: fit-content"
                >
                  <v-chip
                    v-if="successStatusForShaparakIban"
                    color="van_pink white--text font-size-11"
                  >
                    حساب تسویه شاپرکی
                  </v-chip>
                  <v-chip
                    v-else-if="pendingStatusForShaparakIban"
                    color="yellow-orange font-size-11"
                  >
                    در انتظار تایید شاپرک
                  </v-chip>
                  <v-chip
                    v-else-if="failedStatusForShaparakIban"
                    color="van_red white--text font-size-11"
                  >
                    رد شده توسط شاپرک
                  </v-chip>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex
        v-if="
          shaparakIbanIsLoaded &&
          (ibans.length || (ibans.length === 0 && !isThereDefaultIban))
        "
      >
        <v-card flat>
          <v-layout class="shebaList py-5" column>
            <!-- skeleton -->
            <template v-if="!ibansLoad">
              <v-flex v-for="index in 2" :key="index" style="width: 100%">
                <v-layout align-center>
                  <v-flex
                    class="text-justify"
                    style="
                      max-width: 60px;
                      width: 60px;
                      height: 63px;
                      margin: 0px 0px 0px 10px;
                      border: 1px solid #eeeeee;
                    "
                  />
                  <v-flex>
                    <div class="font_2 bold" style="max-width: 50%">
                      <span class="default_gray" />
                    </div>
                    <div class="font_2">
                      <span class="default_gray" />
                    </div>
                  </v-flex>
                  <v-flex style="max-width: 40px">
                    <v-menu
                      transition="slide-y-transition"
                      offset-y
                      close-on-content-click
                      bottom
                      content-class="up_menu"
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          size="24"
                          fab
                          small
                          disabled
                          style="margin: 0"
                          v-on="on"
                        >
                          <v-icon
                            :color="'van_color03'"
                            size="21"
                            style="line-height: 40px"
                          >
                            more_vert
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-menu>
                  </v-flex>
                </v-layout>
                <v-divider v-if="index < 2" />
              </v-flex>
            </template>

            <!-- add iban -->
            <v-flex
              v-else-if="ibansLoad && ibans.length === 0 && !isThereDefaultIban"
            >
              <v-layout align-center>
                <v-flex>
                  <div class="font_2 mb-0">
                    در حال حاضر شماره شبای اختصاصی برای کسب‌و‌کار ثبت نکرده‌اید.
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- iban list -->
            <v-flex
              v-for="(iban, index) in ibansListWithOutDefaultIban"
              v-else
              :key="index"
              style="width: 100%"
            >
              <v-layout
                :class="{
                  'd-flex flex-column': reuseIsMobile,
                  'align-center': !reuseIsMobile,
                }"
              >
                <v-flex class="d-flex">
                  <v-flex
                    style="
                      max-width: 60px;
                      padding: 15px;
                      margin-left: 10px;
                      border: 1px solid #eeeeee;
                    "
                  >
                    <v-img
                      width="30"
                      :src="
                        require(`@/assets/img/banks/${parseInt(
                          iban?.iban?.slice(4, 7)
                        )}.svg`)
                      "
                    />
                  </v-flex>
                  <v-flex>
                    <div v-if="iban.account_holder" class="font_2 bold">
                      {{ iban.account_holder ?? "" }}
                    </div>
                    <div class="font_2">
                      {{ iban?.iban }}
                    </div>
                  </v-flex>
                </v-flex>
                <v-spacer />
                <v-flex>
                  <v-chip
                    v-if="iban.status_is_pending"
                    style="border-radius: 4px; float: left"
                    color="van_color03 white--text font-size-11"
                  >
                    در انتظار بررسی
                  </v-chip>
                  <v-menu
                    v-else
                    transition="slide-y-transition"
                    offset-y
                    close-on-content-click
                    bottom
                    content-class="up_menu"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        size="24"
                        fab
                        small
                        style="margin: 0; float: left"
                        v-on="on"
                      >
                        <v-icon
                          :color="'van_color03'"
                          size="21"
                          style="line-height: 40px"
                        >
                          more_vert
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-card outlined class="drop_down_menu">
                      <v-list class="menu_v_list">
                        <v-list-item @click="deleteIban(iban.id, index)">
                          <v-list-item-content>
                            <v-list-item-title>حذف شبا</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="openWarnModal(iban)">
                          <v-list-item-content>
                            <v-list-item-title
                              >انتخاب به عنوان حساب تسویه
                              شاپرک</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-divider
                v-if="index + 1 < ibansListWithOutDefaultIban.length"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-if="status1"
      v-model="status1"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <AddBusinessIban :key="componentKey" @closeModal="close_modal" />
    </v-dialog>

    <GeneralDialog
      :active="deleteStatus"
      content-class="confirmationMessageDialog"
      style="height: 100%"
      title="از حذف شماره شبا اطمینان دارید؟"
      btn2-text="بلی، حذف شود"
      :btn1-loading="loadingSendToShaparak"
      btn1-text="خیر، حذف نشود"
      btn2-class="btn_small_normal"
      @btn2Clicked="finalDelete"
      @btn1Clicked="cancelDelete"
    />

    <GeneralDialog
      :active="isOpenedWarnModal"
      content-class="confirmationMessageDialog"
      logo="newIcon/warning.svg"
      title="محدودیت تسویه های شاپرکی"
      description="شماره شبا جدید تسویه باید توسط شاپرک بررسی شود و  تسویه‌های شاپرکی تا تایید شماره شبای جدید انجام نخواهد شد."
      description-class="main-des font_30"
      btn1-text="ادامه"
      btn1-class="btn_small_normal outlined"
      :btn1-outlined="true"
      btn2-text="انصراف"
      :btn2-dark-mode="true"
      btn2-class="btn_small_normal error none-border white-text"
      @btn1Clicked="sendForShaparak"
      @btn2Clicked="isOpenedWarnModal = false"
    />

    <GeneralDialog
      :active="approveChangeIban"
      content-class="confirmationMessageDialog"
      style="height: 100%"
      title="حساب تسویه شاپرکی تغییر کند؟"
      btn1-text="بله"
      :btn1-loading="loadingSendToShaparak"
      btn2-text="انصراف"
      btn2-class="btn_small_normal"
      @btn1Clicked="setIbanForShaparak"
      @btn2Clicked="approveChangeIban = false"
    >
      <template #extra>
        <div
          class="main-des font-size-12 text--van_color02"
          v-html="ibanSendToShaparakDescription"
        ></div>
        <div
          class="font_2 mt-2 pa-5"
          style="align-items: center; width: 100%; background-color: #f5f5f5"
        >
          <v-layout align-center>
            <v-flex class="font_8"> صاحب حساب </v-flex>
            <v-flex style="text-align: left">
              {{ selectedForSendShaparak?.account_holder }}
            </v-flex>
          </v-layout>
          <hr class="my-2" style="border: 1px dashed #bbbbbb; width: 100%" />
          <v-layout align-center>
            <v-flex class="font_8"> شماره شبا </v-flex>
            <v-flex style="text-align: left; direction: ltr">
              {{ selectedForSendShaparak?.iban }}
            </v-flex>
          </v-layout>
          <hr class="my-2" style="border: 1px dashed #bbbbbb; width: 100%" />
          <v-layout align-center>
            <v-flex class="font_8"> بانک </v-flex>
            <v-flex style="text-align: left">
              {{ selectedForSendShaparak?.bank_name }}
            </v-flex>
          </v-layout>
        </div>
      </template>
    </GeneralDialog>
  </v-card>
</template>

<script>
import GeneralDialog from "@/components/modals/generalDialog.vue"
import AddBusinessIban from "@/components/modals/addBusinessIban.vue"
import servicesRepository from "@/services/servicesRepository"
import { mapMutations } from "vuex"
import screenSize from "@/mixins/screenSize"

export default {
  name: "SettingBusinessIbans",
  components: {
    AddBusinessIban,
    GeneralDialog,
  },
  mixins: [screenSize],
  props: [],
  data() {
    return {
      componentKey: 0,
      ibans: [],
      ibansLoad: false,
      status1: false,
      deleteStatus: false,
      deletedSelection: {
        id: null,
        index: null,
      },
      currentPage: 1,
      alertChangeIban: false,
      approveChangeIban: false,
      loadingSendToShaparak: false,
      selectedForSendShaparak: null,
      defaultIbanInShaparak: null,
      isOpenedWarnModal: false,
      shaparakIbanIsLoaded: false,
    }
  },
  computed: {
    ibanSendToShaparakDescription() {
      let text =
        "در صورت تغییر شبای تسویه، مبالغ تجمیع شده نزد شاپرک به حساب جدید تسویه می‌شوند. \n"

      text += "</br><span class='bold'>"
      text += `شماره شبا باید به نام ${this.persianTextOfBusinessType} کسب‌وکار باشد.`
      text += "</span>"
      text += " در غیر این صورت شماره شبا از سوی شاپرک رد می‌شود."
      return text
    },
    persianTextOfBusinessType() {
      return this.$store.state.business?.business_type === "legal"
        ? "حقوقی"
        : "مالک"
    },
    pendingStatusForShaparakIban() {
      return (
        this.defaultIbanInShaparak.registration_status === "INIT" ||
        this.defaultIbanInShaparak.registration_status === "PENDING"
      )
    },
    successStatusForShaparakIban() {
      return this.defaultIbanInShaparak.registration_status === "SUCCESS"
    },
    failedStatusForShaparakIban() {
      return this.defaultIbanInShaparak.registration_status === "FAILED"
    },
    ibansListWithOutDefaultIban() {
      return this.ibans.filter(
        (iban) => iban?.iban !== this.defaultIbanInShaparak?.iban
      )
    },
    isThereDefaultIban() {
      return this.defaultIbanInShaparak && this.defaultIbanInShaparak.iban
    },
  },
  watch: {
    status1(newValue) {
      if (!newValue) this.$store.commit("callAddBusinessIbanIsActive", false)
    },
  },
  mounted() {
    this.ibansLoad = false
    this.getIbanList()
    this.getShaparakIbanForTerminal()
  },
  methods: {
    ...mapMutations("newbusiness", ["changeBusinessKey"]),
    getIbanList() {
      this.$http
        .get(this.$store.getters.apiUrlParamV3 + "/business-iban")
        .then((response) => {
          this.ibansLoad = true
          this.ibans = response.data.data
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    open_addsheba() {
      this.close_modal()
      this.$store.commit("callAddBusinessIbanIsActive", true)
      this.status1 = true
    },
    close_modal(obj) {
      this.status1 = false
      if (obj?.status) {
        this.getIbanList()
      }
    },
    deleteIban(id, index) {
      this.deletedSelection = {
        id: id,
        index: index,
      }
      this.deleteStatus = true
    },
    cancelDelete() {
      this.resetDeleteSelection()
      this.deleteStatus = false
    },
    resetDeleteSelection() {
      this.deletedSelection = {
        id: null,
        index: null,
      }
    },
    finalDelete() {
      this.$http
        .delete(
          this.$store.getters.apiUrlParamV3 +
            "/business-iban/" +
            this.deletedSelection.id,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.commit("callSuccessSnackbar", response.data.message)
          this.getIbanList()
          this.deleteStatus = false
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    getShaparakIbanForTerminal() {
      return new Promise((resolve, reject) => {
        this.shaparakIbanIsLoaded = false
        servicesRepository(this)
          .ibanServices.httpGetTerminalIban()
          .then(async (resp) => {
            let iban = resp.data.data
            let ibanInfo = null
            if (iban && iban.iban) {
              ibanInfo = await this.getIbanInfo(iban.iban)
              ibanInfo = ibanInfo.ibans
              iban.ibanInfo = ibanInfo
            }

            this.defaultIbanInShaparak = iban
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => (this.shaparakIbanIsLoaded = true))
      })
    },
    getIbanInfo(iban) {
      return new Promise((resolve) => {
        this.$http
          .post(this.$store.getters.ibanApiUrlParam + "/check", {
            IBAN: iban,
          })
          .then((response) => {
            resolve(response.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    openWarnModal(iban) {
      this.isOpenedWarnModal = true
      this.selectedForSendShaparak = iban
    },
    sendForShaparak() {
      this.isOpenedWarnModal = false
      this.approveChangeIban = true
    },
    setIbanForShaparak() {
      this.loadingSendToShaparak = true
      let data = {
        iban: this.selectedForSendShaparak?.iban,
        is_default: true,
      }
      let method = "post"
      let url = this.$store.getters.apiUrlParamV3 + "/terminal/iban/"
      if (
        !this.$store.state.business?.need_shaparak_iban ||
        (this.defaultIbanInShaparak && !this.defaultIbanInShaparak.is_default)
      ) {
        method = "put"
        url += this.defaultIbanInShaparak?.id
      }

      this.$http[method](url, data)
        .then(async () => {
          await this.getShaparakIbanForTerminal()

          this.changeBusinessKey({
            key: "need_shaparak_iban",
            value: false,
          })
          this.loadingSendToShaparak = false
          this.approveChangeIban = false
        })
        .catch(() => {
          this.loadingSendToShaparak = false
          this.approveChangeIban = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.yellow-orange {
  background-color: #fcc114 !important;
}
</style>
