<template>
  <v-layout column>
    <div v-for="(value, index) in yValue" :key="index">
      <v-flex v-if="lineValue(value) > notShowLessThan">
        <v-layout class="align-center">
          <v-flex style="border-left: #333333 1px solid">
            <v-tooltip top color="white">
              <template #activator="{ on, attrs }">
                <v-progress-linear
                  :value="lineValue(value)"
                  height="40"
                  reverse
                  background-color="#F5F5F5"
                  :color="index == 0 ? firstBarColor : 'van_color05'"
                  class="one-horizontal-chart"
                  v-bind="attrs"
                  v-on="on"
                >
                  <div
                    :style="
                      index == 0 && lineValue(value) < 80
                        ? 'color:' + firstBarColor
                        : index == 0
                        ? 'color:white'
                        : ''
                    "
                    class="mmr-4 w-100"
                    dir="rtl"
                  >
                    {{
                      roundValue
                        ? parseInt(value).toLocaleString()
                        : value.toLocaleString() || 0
                    }}
                    {{ yUnit }}
                  </div>
                </v-progress-linear>
              </template>
              <div class="font_2 text-center" style="max-width: 230px">
                <span v-html="yResult[index]"></span>
              </div>
            </v-tooltip>
          </v-flex>
          <v-flex
            style="max-width: 170px"
            :style="index == 0 ? 'color:' + firstBarColor : ''"
            class="font_5 mmr-3"
          >
            {{ xValue[index] }}
          </v-flex>
        </v-layout>
      </v-flex>
    </div>
    <v-flex
      v-if="
        notShowLessThan !== -1 &&
        lineValue(sumValuesUnderOfNotShowLessThan) !== 0
      "
    >
      <v-layout class="align-center">
        <v-flex style="border-left: #333333 1px solid">
          <v-tooltip top color="white">
            <template #activator="{ on, attrs }">
              <v-progress-linear
                :value="lineValue(sumValuesUnderOfNotShowLessThan)"
                height="40"
                reverse
                background-color="#F5F5F5"
                color="van_color05"
                class="one-horizontal-chart"
                v-bind="attrs"
                v-on="tooltipForLessThan ? on : undefined"
              >
                <div
                  style="margin-top: 10px"
                  :style="
                    lineValue(sumValuesUnderOfNotShowLessThan) > 80
                      ? 'color:white'
                      : ''
                  "
                  class="mmr-4 w-100"
                  dir="rtl"
                >
                  {{
                    roundValue
                      ? parseInt(sumValuesUnderOfNotShowLessThan)
                      : sumValuesUnderOfNotShowLessThan || 0
                  }}
                  {{ yUnit }}
                </div>
              </v-progress-linear>
            </template>
            <div class="font_2 text-center" style="max-width: 230px">
              <span :style="`${styleFroLessThanValueTooltip}`">{{
                parseInt(lineValue(sumValuesUnderOfNotShowLessThan) * 100) / 100
              }}</span>
              <span v-html="tooltipForLessThan"></span>
            </div>
          </v-tooltip>
        </v-flex>
        <v-flex style="max-width: 170px" class="font_5 mmr-3"> سایر </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "OneBarHorizontalBarType2",
  props: {
    xValue: { type: Array },
    yValue: { type: Array },
    yResult: { type: Array },
    yUnit: { type: String },
    roundValue: { type: Boolean },
    firstBarColor: { type: String, default: "#D44D42" },
    notShowLessThan: { type: Number, default: -1 },
    tooltipForLessThan: { type: String },
    styleFroLessThanValueTooltip: {
      type: String,
      default: "font-weight: bold;color:rgb(212, 77, 66)",
    },
    maxValueForBars: { type: Number },
  },
  computed: {
    sumValuesUnderOfNotShowLessThan() {
      let sum = 0
      for (const value of this.yValue) {
        if (this.lineValue(value) <= this.notShowLessThan) {
          sum += value
        }
      }
      return sum
    },
  },
  methods: {
    lineValue(value) {
      const max = this.maxValueForBars ?? Math.max(...this.yValue)
      if (!max) return 0
      return this.roundValue
        ? parseInt(((value || 0) / max) * 100)
        : ((value || 0) / max) * 100
    },
  },
}
</script>

<style lang="scss">
.one-horizontal-chart {
  margin: 14px 0;
  &:hover {
    .v-progress-linear__determinate {
      background-color: $van_color03 !important;
    }
  }
  .v-progress-linear {
    &__determinate {
      border-radius: 0 8px 8px 0;
    }
  }
}
</style>
