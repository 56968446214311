<template>
  <div>
    <vanCardWithCheckbox
      v-if="!showSkeleton && pendingSettlementList?.length"
      class="mb-2"
      border="1px solid #eee"
      hover-shadow="0"
      background-color="#F1F1F1"
      :checkbox-label="selectAllLabel"
      :checked-status="selectAllStatus"
      :is-indeterminate="isIndeterminate"
      @onChange="selectAllAction($event)"
    >
      <v-btn
        v-if="selectedItemsIds.length"
        text
        dark
        outlined
        class="btn_small_normal ma-0 mr-auto"
        @click="toggleConfirmModal()"
        >لغو برداشت</v-btn
      ></vanCardWithCheckbox
    >
    <vanCardWithCheckbox
      v-for="(settlement, index) in pendingSettlementList"
      :key="'pending' + settlement.id + index"
      :checked-status="isSelected(settlement)"
      :checkbox-label="handleAmount(sumAmount(settlement))"
      class="mb-2"
      border="1px solid #eee"
      border-radius="0"
      @onChange="onChange($event, settlement, index)"
      @onClick="showDetails(settlement)"
    >
      <div
        class="d-flex justify-space-between w-100"
        :class="{
          'flex-column mr-8 pl-8': $vuetify.breakpoint.xsOnly,
          'align-center': $vuetify.breakpoint.smAndUp,
        }"
      >
        <div class="d-none d-sm-flex mr-4 font-size-11 van_color03--text">
          {{ typeOrOwnerName(settlement) }}
        </div>
        <div
          class="d-flex align-center mr-sm-auto font_2"
          :class="{
            'mr-0 pb-1': $vuetify.breakpoint.xsOnly,
          }"
        >
          <div v-if="isBatch(settlement)">
            {{ settlementsCount(settlement) }} برداشت
          </div>
          <template v-else-if="settlementsCount(settlement) === 1">
            {{ settlement.settlement_items[0].IBAN }}
            <v-img
              width="16"
              max-height="16"
              max-width="16"
              :class="{ 'align-self-center': $vuetify.breakpoint.xsOnly }"
              class="mr-1"
              :src="
                require(`@/assets/img/banks/${parseInt(
                  settlement.settlement_items[0]?.IBAN.slice(4, 7)
                )}.svg`)
              "
            />
          </template>
        </div>
        <div
          class="d-flex d-sm-none align-center border-top pt-2 font-size-11 van_color03--text"
        >
          {{ typeOrOwnerName(settlement) }}
        </div>
      </div>

      <!-- load more -->
      <observer
        v-if="
          !showSkeleton &&
          index === pendingSettlementList.length - 1 &&
          pendingSettlementList.length < total
        "
        @intersect="nextPage()"
      />
    </vanCardWithCheckbox>

    <div v-if="showSkeleton">
      <v-skeleton-loader
        v-for="i in 6"
        :key="i"
        class="mb-2"
        max-height="60"
        type="card"
      ></v-skeleton-loader>
    </div>

    <general-card
      v-else-if="pendingSettlementList.length === 0"
      custom-card-class="py-5 px-10"
    >
      <div class="d-flex align-center">
        <v-img
          max-width="30"
          class="ml-2"
          :src="require('@/assets/img/box-gray-past-time.svg')"
        />
        <span class="font-size-12">
          در حال حاضر هیچ برداشت در نوبتی ندارید.
        </span>
      </div>
    </general-card>

    <general-dialog
      v-if="showCancelDialog && !isUpdatingSelectedItems"
      :active="showCancelDialog && !isUpdatingSelectedItems"
      content-class="confirmationMessageDialog"
      logo="newIcon/error_close.svg"
      :title="cancelConfirmTitle"
      :description="cancelConfirmDescription"
      btn1-text="بله، لغو شود"
      :btn1-loading="cancelBtnLoading"
      btn1-class="btn_small_normal error"
      btn2-text="انصراف"
      @btn1Clicked="cancel()"
      @btn2Clicked="showCancelDialog = false"
    >
      <template v-if="showBatchModal" #extra>
        <div class="pa-5 van_color11 mt-3 w-100">
          <div
            v-for="(item, index) in batchModalRows"
            :key="item.label"
            :class="`d-flex justify-space-between font_31 van_color04--text  ${
              index + 1 !== batchModalRows.length
                ? 'dashed-border pb-3'
                : 'pt-3 '
            }`"
          >
            <span> {{ item.label }}</span>
            <span class="font_7"> {{ item.value }} </span>
          </div>
        </div>
      </template>
    </general-dialog>

    <v-dialog
      v-if="showPendingItem"
      v-model="showPendingItem"
      content-class="left-modal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <queue-show
        :id="selectedSettlement?.id"
        :queue-settlement="selectedSettlement"
        toolbar-label="برداشت در نوبت"
        @closeModal="closeDetails()"
        @cancel="cancelSelectedItem()"
      />
    </v-dialog>
  </div>
</template>

<script>
import VanCardWithCheckbox from "@/components/VANComponents/vanCardWithCheckbox.vue"
import Observer from "@/components/elements/observer.vue"
import GeneralDialog from "@/components/modals/generalDialog.vue"
import GeneralCard from "@/components/elements/generalCard.vue"
import QueueShow from "@/components/businessHome/elements/settlementQueue/show.vue"
import { queuedSettlementHelper } from "@/mixins/queuedSettlementHelper.js"

export default {
  name: "SettlementQueuePendingItems",
  components: {
    QueueShow,
    GeneralCard,
    GeneralDialog,
    Observer,
    VanCardWithCheckbox,
  },
  mixins: [queuedSettlementHelper],
  props: {
    selectedBatch: {
      type: Object,
      default: null,
    },
    pendingSettlements: {
      default: () => [],
      type: Array,
    },
    allPendingIds: {
      default: () => [],
      type: Array,
    },
    onBatchComponent: {
      default: false,
      type: Boolean,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedItemsIds: [],
      selectedItemsLength: 0,
      showCancelDialog: false,
      showSkeleton: false,
      cancelBtnLoading: false,
      showPendingItem: false,
      selectedSettlement: null,
      indexes: [],
      isUpdatingSelectedItems: false,
      fromShowModal: false,
      pendingSettlementList: [...this.pendingSettlements],
    }
  },
  computed: {
    selectAllLabel() {
      if (this.selectedItemsLength) {
        return `${this.selectedItemsLength} از ${this.total} تراکنش`
      } else {
        return `${this.total} تراکنش`
      }
    },
    isIndeterminate() {
      return ![0, this.total].includes(this.selectedItemsLength)
    },
    selectAllStatus() {
      return this.selectedItemsLength === this.total
    },
    settlementsSum() {
      let sum = 0
      this.pendingSettlementList.forEach((element) => {
        sum += this.sumAmount(element)
      })

      return this.handleAmount(sum)
    },
    selectedBathInfo() {
      let sum = 0
      let selectedBatch = this.pendingSettlementList[this.indexes[0]]

      if (selectedBatch) {
        this.pendingSettlementList[this.indexes[0]].settlement_items.forEach(
          (element) => {
            sum += element.amount
          }
        )
        return { sum, count: selectedBatch.settlement_items.length }
      }

      return { sum: 0, count: 0 }
    },
    cancelingSingleBatch() {
      return this.selectedItemsLength === 1 && this.selectedItemsIds.length > 1
    },
    showBatchModal() {
      return (
        (this.onBatchComponent && this.selectAllStatus) ||
        this.cancelingSingleBatch
      )
    },
    cancelConfirmTitle() {
      return this.showBatchModal
        ? "برداشت گروهی لغو شود؟"
        : "از لغو برداشت اطمینان دارید؟"
    },
    cancelConfirmDescription() {
      return this.showBatchModal
        ? "در صورت تایید، برداشت گروهی لغو می‌شود."
        : "در صورت لغو برداشت تراکنش‌های انتخاب شده، در صف بانک قرار نمی‌گیرند."
    },
    batchModalRows() {
      return [
        {
          label: "تعداد برداشت‌ها",
          value: this.onBatchComponent
            ? this.pendingSettlementList.length + " برداشت"
            : this.selectedBathInfo.count + " برداشت",
        },
        {
          label: "جمع مبلغ",
          value: this.onBatchComponent
            ? this.settlementsSum
            : this.handleAmount(this.selectedBathInfo.sum),
        },
      ]
    },
  },
  watch: {
    skeleton(newValue) {
      this.$emit("skeleton", newValue)
    },
    showPendingItem(newValue) {
      if (!newValue) {
        this.closeDetails()
      }
    },
    pendingSettlements() {
      this.showSkeleton = false
      this.pendingSettlementList = [...this.pendingSettlements]
    },
    selectedItemsIds() {
      this.isUpdatingSelectedItems = false
    },
  },
  methods: {
    nextPage() {
      this.showSkeleton = true
      this.$emit("getNewPageData")
    },
    selectAllAction(event) {
      this.indexes = event ? [...Array(this.allPendingIds).keys()] : []
      this.selectedItemsLength = event ? this.total : 0
      this.selectedItemsIds = event ? [...this.allPendingIds] : []
    },
    onChange(event, settlement, index) {
      if (event) {
        this.selectedItemsLength += 1
        this.indexes.push(index)

        settlement.settlement_items.forEach((element) => {
          this.selectedItemsIds.push(element.id)
        })
      } else {
        let findElement = false
        const indexOfIndex = this.indexes.indexOf(index)
        this.indexes.splice(indexOfIndex, 1)

        settlement.settlement_items.forEach((element) => {
          const selectedIndex = this.selectedItemsIds.indexOf(element.id)

          if (selectedIndex > -1) {
            findElement = true
            this.selectedItemsIds.splice(selectedIndex, 1)
          }
        })
        if (findElement) this.selectedItemsLength -= 1
      }
    },
    isSelected(settlement) {
      const ids = settlement.settlement_items.map((e) => e.id)
      let containAllIds = true

      ids.forEach((id) => {
        if (this.selectedItemsIds.indexOf(id) === -1) {
          containAllIds = false
        }
      })

      return containAllIds
    },
    cancelSelectedItem() {
      this.isUpdatingSelectedItems = true
      this.selectedItemsIds = [this.selectedSettlement.id]
      this.selectedItemsLength = 1
      this.showCancelDialog = true
      this.fromShowModal = true
    },
    cancel() {
      let data = {}
      let url
      this.cancelBtnLoading = true

      if (this.selectAllStatus && !this.onBatchComponent) {
        data.exclude_ids = []
        url =
          this.$store.getters.apiUrlParamV3 + "/settlement/queued/cancel/all"
      } else {
        data.ids = this.selectedItemsIds
        url = this.$store.getters.apiUrlParamV3 + "/settlement/queued/cancel"
      }

      this.$http
        .post(url, data)
        .then((response) => {
          if (this.fromShowModal) this.removeDeletedItem()
          this.resetData()
          this.$store.commit("callSuccessSnackbar", response.data.message)

          this.$emit("checkCanceled", !this.fromShowModal)
          this.cancelBtnLoading = false
          this.fromShowModal = false
          this.closeDetails()
        })
        .catch(() => {
          this.cancelBtnLoading = false
        })
    },
    showDetails(settlement) {
      if (this.onBatchComponent) {
        this.$store.commit("changeShowThirdModal", true)
      } else this.$store.commit("changeShowSecondModal", true)

      if (this.isBatch(settlement)) {
        this.$emit("toggleBatchModal", settlement)
        this.selectedSettlement = settlement
        return
      }

      this.selectedSettlement = settlement.settlement_items[0]
      this.showPendingItem = true
    },
    closeDetails() {
      this.showPendingItem = false

      if (this.onBatchComponent) {
        this.$store.commit("changeShowThirdModal", false)
      } else this.$store.commit("changeShowSecondModal", false)
    },
    toggleConfirmModal() {
      this.showCancelDialog = true
    },
    resetData() {
      this.isUpdatingSelectedItems = false
      this.selectedItemsIds = []
      this.selectedItemsLength = 0
      this.showSkeleton = false
      this.cancelBtnLoading = false
      this.showPendingItem = false
      this.showCancelDialog = false
      this.selectedSettlement = null
    },
    removeDeletedItem() {
      const index = this.pendingSettlementList
        .map((element) => element.id)
        ?.indexOf(this.selectedSettlement.id)

      if (index > -1) {
        this.pendingSettlementList.splice(index, 1)
        this.selectedItemsIds = []
        this.selectedItemsLength = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dashed-border {
  border-bottom: 1px dashed $van_color16;
}

.border-top {
  border-top: 1px dashed $van_color16;
}
</style>
