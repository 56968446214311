<template>
  <LeftModal
    toolbar-name="واریز بانکی خودکار"
    @closeModal="$emit('closeModal')"
  >
    <div slot="toolbarItems">
      <v-btn
        ref="btn"
        text
        dark
        class="btn_small_normal"
        :disabled="!amountValue || !selectedIban"
        @click="showApprove = true"
      >
        تایید و ادامه
      </v-btn>
    </div>
    <v-card>
      <v-layout>
        <v-flex>
          <v-form>
            <IbanSearch
              label="شماره شبا"
              :loading="loading"
              @selected-iban-Object="selectedIban = $event"
              @clear-iban="selectedIban = null"
            />

            <InputContainer
              v-if="selectedIban"
              class="mt-5"
              label="انتخاب مبلغ"
              :optional="false"
            >
              <v-flex>
                <v-radio-group v-model="amountValue" class="simpleRadio">
                  <v-radio
                    v-for="item in amountOptionsList"
                    :key="item.value"
                    :value="item"
                  >
                    <template #label>
                      <div class="d-flex flex-column align-start">
                        <span class="font-12-bo-333-18">
                          {{ item.title }}
                        </span>
                        <span class="d-flex font_2 align-center">
                          {{ item.text }}
                          <div class="mr-1">
                            <v-tooltip top max-width="190" color="#666666">
                              <template #activator="{ on, attrs }">
                                <v-img
                                  width="14"
                                  max-width="14"
                                  aspect-ratio="1"
                                  :src="require('@/assets/img/info.svg')"
                                  style="cursor: pointer"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <span>{{ item.tooltipText }}</span>
                            </v-tooltip>
                          </div>
                        </span>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-flex>
            </InputContainer>
          </v-form>
        </v-flex>
      </v-layout>
    </v-card>

    <GeneralDialog
      :active="showApprove"
      content-class="confirmationMessageDialog"
      style="height: 100%"
      title="تنظیمات واریز بانکی خودکار اعمال شود؟"
      btn1-text="بله، اعمال شود"
      :btn1-loading="loading"
      btn2-text="انصراف"
      btn2-class="btn_small_normal"
      @btn1Clicked="sendForm()"
      @btn2Clicked="showApprove = false"
    >
      <template #extra>
        <div
          class="mt-6 font_2 pa-5 text-center"
          style="background-color: #4c9f874d; width: 100%"
          v-html="amountValue?.modalText"
        ></div>
        <div
          class="font_2 mt-2 pa-5"
          style="align-items: center; width: 100%; background-color: #f5f5f5"
        >
          <v-layout align-center>
            <v-flex class="font_8"> صاحب حساب مبدا </v-flex>
            <v-flex style="text-align: left">
              {{
                selectedIban?.account_owner[0].hasOwnProperty("firstName")
                  ? selectedIban?.account_owner[0].firstName
                  : ""
              }}
              {{
                selectedIban?.account_owner[0].hasOwnProperty("lastName")
                  ? selectedIban?.account_owner[0].lastName
                  : ""
              }}
            </v-flex>
          </v-layout>
          <hr class="my-2" style="border: 1px dashed #bbbbbb; width: 100%" />
          <v-layout align-center>
            <v-flex class="font_8"> شماره شبا </v-flex>
            <v-flex style="text-align: left; direction: ltr">
              {{ selectedIban?.IBAN }}
            </v-flex>
          </v-layout>
          <hr class="my-2" style="border: 1px dashed #bbbbbb; width: 100%" />
          <v-layout align-center>
            <v-flex class="font_8"> بانک </v-flex>
            <v-flex style="text-align: left">
              {{ selectedIban?.bank_name }}
            </v-flex>
          </v-layout>
        </div>
      </template>
    </GeneralDialog>
  </LeftModal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal.vue"
import InputContainer from "@/components/elements/inputContainer.vue"
import GeneralDialog from "@/components/modals/generalDialog.vue"
import IbanSearch from "@/components/report/modals/addCashIn/ibanSearch.vue"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "AutoCashInModal",
  components: {
    GeneralDialog,
    InputContainer,
    LeftModal,
    IbanSearch,
  },
  data() {
    return {
      selectedIban: null,
      amountOptionsList: [
        {
          text: "انتقال کل موجودی حساب به کیف پول",
          title: "کل موجودی حساب",
          value: "total_account_balance",
          tooltipText:
            "هر ۱۵ دقیقه کل موجودی حساب بانکی به صورت خودکار به کیف‌پول وندارتان واریز خواهد شد.",
          modalText:
            "با این تنظیمات، هر <strong>۱۵ دقیقه</strong> یکبار، کل موجودی حساب شما به کیف‌پول‌تان واریز می‌شود.",
        },
        {
          text: "انتقال مبالغ تجمیعی تراکنش‌های درگاه پرداخت به کیف پول",
          title: "مبالغ تجمیعی تراکنش‌های شاپرکی",
          value: "cumulative_sums_of_transactions",
          tooltipText: "واریز از ساعت ۱۰ الی ۱۴ هر روز انجام خواهد شد.",
          modalText:
            "با این تنظیمات، بعد از هر تسویه شاپرکی، هر روز ساعت ۱۰ الی ۱۴ <strong>مبالغ تجمیعی درگاه پرداخت</strong> به کیف پول‌تان واریز می‌شود.",
        },
      ],
      showApprove: false,
      loading: false,
      showDeActive: false,
      amountValue: null,
    }
  },
  methods: {
    clearSelectIban() {
      this.selectedIban = null
      this.amountValue = null
    },
    sendForm() {
      this.loading = true
      const payload = {
        iban: this.selectedIban.IBAN,
        price_type: this.amountValue.value,
      }

      servicesRepository(this)
        .cashIn.httpActiveAutoCashIn(payload)
        .then(() => {
          this.$store.commit(
            "callSuccessSnackbar",
            "واریز بانکی خودکار فعال شد."
          )
          this.$emit("closeModal", { activeAutoCashIn: true })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.v-dialog.vanmodal .v-card > .layout:first-of-type {
  padding-top: 0;
}

.v-dialog.vanmodal .v-card > .layout > .flex {
  margin: 0;
}

:deep(.v-input--selection-controls__input) {
  align-self: flex-start !important;
}

:deep(.v-input--radio-group.simpleRadio) {
  margin: 12.5px 0 !important;
}

:deep(.v-input--selection-controls) {
  padding-top: 0 !important;
}
</style>
