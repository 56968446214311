<template>
  <chart-card-one-filter
    class="mmt-5"
    :title="kerdarLang.general.errorsFrequency"
    :show-filter="true"
    :growth-text="mandatesErrorFrequencyPercentageGrowth"
    growth-color="#D44D421A"
    :duration="localDuration"
    @changeFilter="changeMandatesErrorFrequencyPercentageFilter($event)"
  >
    <one-bar-horizontal-bar-type2
      v-if="mandatesErrorFrequencyPercentageStatus"
      :x-value="mandatesErrorFrequencyPercentageTitle"
      :y-value="mandatesErrorFrequencyPercentageValue"
      :y-result="mandatesErrorFrequencyPercentageTooltip"
      :not-show-less-than="1"
      :tooltip-for-less-than="kerdarLang.general.tooltipForOtherCasesWithTag"
      :y-unit="kerdarLang.general.error"
      :max-value-for-bars="total(mandatesErrorFrequencyPercentageData, 1)"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </chart-card-one-filter>
</template>

<script>
import ChartCardOneFilter from "@/components/kerdar/components/chartCardOneFilter"
import OneBarHorizontalBarType2 from "@/components/kerdar/components/oneBarHorizontalBarType2"
import { sortData } from "@/components/kerdar/utils/generalMethods"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

export default {
  name: "DirectDebitMandateErrorFrequency",
  components: {
    "chart-card-one-filter": ChartCardOneFilter,
    "one-bar-horizontal-bar-type2": OneBarHorizontalBarType2,
  },
  props: {
    duration: {
      type: Object,
      default: () => {},
    },
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mandatesErrorFrequencyPercentageData: [],
      mandatesErrorFrequencyPercentageStatus: false,
      mandatesErrorFrequencyPercentageFilter: null,
      localDuration: [
        {
          name: this.kerdarLang.general.currentDay,
          value: "current_day",
        },
        {
          name: this.kerdarLang.general.currentWeek,
          value: "current_week",
        },
        {
          name: this.kerdarLang.general.lastDay,
          value: "last_day",
        },
        {
          name: this.kerdarLang.general.lastWeek,
          value: "last_week",
        },
      ],
    }
  },
  computed: {
    mandatesErrorFrequencyPercentageGrowth() {
      if (this.mandatesErrorFrequencyPercentageFilter) {
        let totalErrorCount = 0
        if (
          this.mandatesErrorFrequencyPercentageValue &&
          this.mandatesErrorFrequencyPercentageValue.length
        ) {
          for (let data of this.mandatesErrorFrequencyPercentageData) {
            totalErrorCount += data[1]
          }
        }
        if (!totalErrorCount) return null
        return (
          `${this.kerdarLang.general.at} ` +
          this.duration[this.mandatesErrorFrequencyPercentageFilter.value] +
          " " +
          "<span class='bold van_red--text'>" +
          totalErrorCount.toLocaleString() +
          "</span>" +
          ` ${this.kerdarLang.general.haveUnsuccessfulDeposit}`
        )
      }
      return null
    },
    mandatesErrorFrequencyPercentageTitle() {
      return this.mandatesErrorFrequencyPercentageData.map((item) => item[0])
    },
    mandatesErrorFrequencyPercentageValue() {
      return this.mandatesErrorFrequencyPercentageData.map((item) =>
        Number(item[1])
      )
    },
    mandatesErrorFrequencyPercentageTooltip() {
      let totalErrorCount = 0
      if (
        this.mandatesErrorFrequencyPercentageValue &&
        this.mandatesErrorFrequencyPercentageValue.length
      ) {
        totalErrorCount = this.total(
          this.mandatesErrorFrequencyPercentageData,
          1
        )
      }

      return this.mandatesErrorFrequencyPercentageData.map((item) => {
        let percentage =
          Math.round(
            ((100 * item[1]) / totalErrorCount + Number.EPSILON) * 100
          ) / 100
        let text =
          '<span style="font-weight: bold;color: rgb(212, 77, 66)">' +
          percentage +
          "%</span>"
        text += ` ${this.kerdarLang.general.becauseOf} ` + item[0]
        return text
      })
    },
  },
  methods: {
    clearMandatesErrorFrequencyPercentageData() {
      this.mandatesErrorFrequencyPercentageStatus = false
      this.mandatesErrorFrequencyPercentageData = []
    },
    changeMandatesErrorFrequencyPercentageFilter(item) {
      this.mandatesErrorFrequencyPercentageFilter = item
      this.clearMandatesErrorFrequencyPercentageData()
      this.getMandatesErrorFrequencyPercentageData(item)
    },
    async getMandatesErrorFrequencyPercentageData(item) {
      const chartType = item.value
      if (!chartType) {
        return
      }
      const response = await kerdarServices(
        this.$http,
        this
      ).directDebit.httpDirectDebitMandatesErrorFrequency(chartType)

      this.mandatesErrorFrequencyPercentageData = sortData(response.data.data)
      this.mandatesErrorFrequencyPercentageStatus = true
    },
    total(data, index) {
      let total = 0
      for (let tempData of data) {
        total += tempData[index]
      }
      return total
    },
  },
}
</script>

<style lang="scss" scoped></style>
