<template>
  <v-text-field
    ref="taxCode"
    v-model.trim="tax_code"
    class="input-text-center"
    solo
    flat
    :rules="notEmpty"
    height="40"
    outlined
    :placeholder="placeHolder"
    validate-on-blur
    :hint="hint"
    :readonly="disable"
    @focus="focusTaxCode"
    @blur="blurTaxCode"
  >
    <template #message="message">
      <div v-html="message.message"></div>
    </template>
  </v-text-field>
</template>

<script>
import { mapMutations } from "vuex"
export default {
  name: "TaxCode",
  props: {
    inputValue: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    placeHolder: {
      type: String,
      default: "",
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tax_code: "",
      notEmpty: [
        (v) => !!v || "<span> کدمالیاتی الزامی است </span>",
        (v) =>
          (v && v.length == 10) || "<span> کدمالیاتی باید 10 رقم باشد </span>",
      ],
    }
  },
  watch: {
    tax_code: function (newValue) {
      if (newValue) {
        this.tax_code = this.convertNumbers2English(newValue).replace(
          /[^0-9]/g,
          ""
        )
        this.tax_code = this.tax_code.slice(0, 10)
        this.$refs.taxCode.lazyValue = this.tax_code

        if (this.tax_code.length == 10) {
          this.$refs.taxCode.blur()
          this.$emit("setValue", this.tax_code)
        }
      }
    },
  },
  created() {
    this.tax_code = this.inputValue
  },
  methods: {
    ...mapMutations("newbusiness", [
      "changeTaxCodeFieldFocusState",
      "changeWageFieldShowingState",
    ]),
    focusTaxCode() {
      this.changeTaxCodeFieldFocusState(true)
      this.changeWageFieldShowingState(false)
    },
    blurTaxCode() {
      this.changeTaxCodeFieldFocusState(false)
    },
  },
}
</script>
