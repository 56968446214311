import service from "./service"
import serviceV2 from "./serviceV2"

export default ($http, context) => ({
  httpFormIncome(chartType) {
    const queryParam = "/stat?code=form_income_" + chartType
    return service.get($http, context, queryParam)
  },
  httpFormIncomeCount(chartType) {
    const queryParam = "/stat?code=form_income_count_" + chartType
    return service.get($http, context, queryParam)
  },
  httpFormUnsuccessfulDepositsAverage() {
    const queryParam = "/stat?code=form_unsuccessful_deposits_average"
    return service.get($http, context, queryParam)
  },
  httpFormUnsuccessfulDepositsAverageV2() {
    const url = `/payment-form/unsuccessful-deposit`
    return serviceV2.get($http, context, url)
  },
  httpFormErrorResources(chartType) {
    const queryParam = "/stat?code=form_error_resources_" + chartType
    return service.get($http, context, queryParam)
  },
  httpFormErrorResourcesV2(item) {
    const queryParam = `/payment-form/error-resources?resolution=${item.timePeriod}`
    return serviceV2.get($http, context, queryParam)
  },
  httpFormErrorFrequency(chartType) {
    const queryParam = "/ipg-stat?code=form_error_frequency_" + chartType
    return service.get($http, context, queryParam)
  },
  httpFormErrorFrequencyV2(item) {
    const queryParam = `/payment-form/error-frequency?resolution=${item.timePeriod}`
    return serviceV2.get($http, context, queryParam)
  },
  httpPaymentFormDeposit(item) {
    const queryParam = `/payment-form/deposit?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpPaymentFormSuccessDeposit(item) {
    const queryParam = `/payment-form/success-deposit?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
})
