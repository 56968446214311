<template>
  <div>
    <v-layout align-center justify-space-between>
      <v-flex>
        <h2 class="mb-4 mr-0 ml-auto">گزارش فراخوانی‌ها</h2>
      </v-flex>
    </v-layout>
    <tableFilter
      :reset-filter="resetFilter"
      @putFilter="putFilter($event)"
      @reset="resetFilter = false"
    />

    <!-- filter details -->
    <div v-if="filterIsActive">
      <v-layout
        v-if="!$store.state.logFilterLoading"
        :align-center="$vuetify.breakpoint.smAndDown ? false : true"
        :align-start="$vuetify.breakpoint.smAndDown ? true : false"
        :class="setFilterBarColor"
        justify-center
      >
        <v-flex v-if="totalDesserts == 0" class="font_2 py-1 px-5">
          گزارش با این فیلترها وجود ندارد
        </v-flex>
        <v-layout
          v-else
          :column="$vuetify.breakpoint.smAndDown"
          :style="$vuetify.breakpoint.smAndDown ? 'width:100%' : ''"
          justify-center
        >
          <v-flex>
            <span class="font_12">{{ totalDesserts | thousandSeprator }}</span>
            <span class="font_2 mr-2">گزارش فیلتر شده</span>
          </v-flex>
        </v-layout>

        <v-flex
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'position: absolute;top: 13px;left: 5px;max-width: 109px'
              : 'max-width: 109px'
          "
        >
          <v-btn
            class="btn_small_normal transparent"
            dark
            depressed
            outlined
            style="font-size: 10px !important; padding: 0 10px"
            text
            @click="deleteAllFilters"
          >
            <v-icon class="ml-2" color="van_color03" size="15"> close </v-icon>
            حذف فیلتر‌ها
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout
        v-else
        class="greenRow"
        style="background-color: #eee; height: 80px"
      />
    </div>

    <div style="position: relative">
      <v-data-table
        v-if="desserts[0] === 'skeleton'"
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'elevation-1 vandarTable smAndDownTable log_table'
            : 'elevation-1 vandarTable reportTable log_table'
        "
        :headers="headers"
        :items="desserts"
        :items-per-page-items="rows"
        :options.sync="pagination"
        :server-items-length="totalDesserts"
        hide-default-footer
        items-per-page-text="نمایش در هر صفحه "
        no-data-text="گزارشی برای نمایش وجود ندارد"
      >
        <template #body>
          <table-row-skeleton />
        </template>
        <template #no-data>
          <v-layout align-center justify-center wrap>
            <v-flex class="vandar-table-empty">
              گزارشی برای نمایش وجود ندارد
            </v-flex>
          </v-layout>
        </template>
      </v-data-table>

      <!--      filled table-->
      <v-data-table
        v-if="desserts[0] !== 'skeleton' && totalDesserts !== 0"
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'elevation-1 vandarTable smAndDownTable log_table'
            : 'elevation-1 vandarTable log_table'
        "
        :headers="headers"
        :hide-default-header="totalDesserts !== 0 ? false : true"
        :items="desserts"
        :items-per-page-items="rows"
        :options.sync="pagination"
        :server-items-length="totalDesserts"
        hide-default-footer
        items-per-page-text="نمایش در هر صفحه "
        no-data-text="گزارشی برای نمایش وجود ندارد"
      >
        <template #item="{ item }">
          <table-row :transaction="item" @showDetail="show_detail" />
        </template>

        <template #no-data>
          <v-layout align-center justify-center wrap>
            <v-flex
              style="text-align: center; padding: 88px 10px; cursor: default"
            >
              تراکنشی برای نمایش وجود ندارد
            </v-flex>
          </v-layout>
        </template>
      </v-data-table>
      <v-layout
        v-else-if="
          desserts[0] != 'skeleton' &&
          totalDesserts == 0 &&
          !$store.state.logFilterLoading &&
          !filterIsActive
        "
        align-center
        elevation-1
        justify-center
        style="background-color: white; font-weight: normal"
        wrap
      >
        <v-flex class="vandar-table-empty">
          گزارشی برای نمایش وجود ندارد
        </v-flex>
      </v-layout>

      <table-paginate
        v-if="totalDesserts > 20"
        :items-length="totalDesserts"
        :total-pages="totalPages"
        @page="pagination.page = $event"
        @rowsPerPage="pagination.itemsPerPage = $event"
      />
    </div>

    <!-- modals -->
    <v-dialog
      v-model="status"
      :content-class="'vanmodal'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      transition="slide-x-transition"
    >
      <show v-if="status" :item="selectedForm" @closeModal="close_modal()" />
    </v-dialog>
  </div>
</template>
<script>
import show from "../modals/show.vue"
import tableFilter from "../elements/tableFilter.vue"
import TableRow from "./tableRow.vue"
import TableRowSkeleton from "./tableRowSkeleton.vue"
import TablePaginate from "../../elements/tablePaginate"

export default {
  components: {
    TablePaginate,
    show,
    tableFilter,
    TableRow,
    TableRowSkeleton,
  },
  data() {
    return {
      status: false,
      totalDesserts: 0,
      desserts: ["skeleton"],
      pagination: {
        descending: false,
        page: 1,
        itemsPerPage: 20,
        // sortBy: null,
        itemsLength: 0,
      },
      totalPages: 0,
      rows: ["20", "50", "100"],
      headers: [
        {
          text: " ",
          align: "right",
          sortable: false,
          value: "status",
        },
        {
          text: "شماره سریال",
          align: "right",
          sortable: false,
          value: "_id",
        },
        {
          text: "تاریخ و ساعت",
          align: "right",
          sortable: false,
          value: "created_at",
        },
        {
          text: "کانال",
          align: "right",
          sortable: false,
          value: "channel",
        },
        {
          text: "متد",
          align: "right",
          sortable: false,
          value: "method",
        },
        {
          text: "وضعیت",
          align: "right",
          sortable: false,
          value: "status",
        },
      ],
      selectedForm: {},
      duration: "DAY",
      status_code: "0",
      methods: "0",
      channel: "0",
      filterIsActive: false,
      resetFilter: false,
    }
  },
  computed: {
    setFilterBarColor() {
      if (this.totalDesserts == "0") return "grayRow pr-6"
      return "greenRow pr-6"
    },
  },
  watch: {
    "$store.state.logFilterLoading": {
      handler() {
        if (this.$store.state.logFilterLoading === true) {
          for (let index = 0; index < this.pagination.itemsPerPage; index++) {
            this.desserts[index] = "skeleton"
          }
        }
      },
      immediate: true,
    },
    pagination: {
      handler() {
        this.getDataFromApi().then((data) => {
          this.desserts = data.items
          this.totalDesserts = data.total
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi().then((data) => {
      this.desserts = data.items
      this.totalDesserts = data.total
    })
  },
  methods: {
    deleteAllFilters() {
      this.duration = "DAY"
      this.status_code = "0"
      this.methods = "0"
      this.channel = "0"
      this.filterIsActive = false
      this.putFilter()
      this.resetFilter = true
    },
    showDetail(item) {
      this.selectedForm = item
      this.status = true
    },
    close_modal() {
      this.status = false
    },
    putFilter(obj) {
      if (obj && obj.status) {
        this.filterIsActive = true
        this.duration = obj.duration
        this.status_code = obj.statusFilter
        this.channel = obj.channelFilter
        this.methods = obj.methodFilter
      }
      this.getDataFromApi().then((data) => {
        this.desserts = data.items
        this.totalDesserts = data.total
      })
    },
    show_detail(item) {
      this.selectedForm = item
      this.status = true
    },
    rowClass(status) {
      switch (status) {
        case 1:
          return "greenRow"
        case 0:
          return "redRow"
        default:
          break
      }
    },
    getDataFromApi() {
      this.$store.commit("changeLogFilterLoading", true)
      return new Promise((resolve, reject) => {
        const { sortBy, descending, page, itemsPerPage } = this.pagination
        var url, pageForApi, itemsPerPageForApi

        pageForApi = page === undefined ? 1 : page
        itemsPerPageForApi = itemsPerPage === undefined ? 20 : itemsPerPage

        url = "/api-logs?page=" + pageForApi + "&per_page=" + itemsPerPageForApi

        if (this.status_code !== "0") {
          url += `&status_code=${this.status_code}`
        }
        if (this.methods !== "0") {
          url += `&method=${this.methods}`
        }
        if (this.channel !== "0") {
          url += `&channel=${this.channel}`
        }
        if (this.duration !== "MONTH") {
          url += `&duration=${this.duration}`
        }

        this.$http
          .get(this.$store.getters.apiUrlParam + url, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            this.$store.commit("changeLogFilterLoading", false)

            if (response.data.data !== null) {
              var items = response.data.data
              var total = response.data.total
              this.totalPages = response.data.last_page
              items.forEach((element, index) => {
                element.rowNumber =
                  index + (pageForApi - 1) * itemsPerPageForApi + 1
                element.channel_name_fa = ""
                switch (element.channel) {
                  case "IPG":
                    element.channel_name_fa = "درگاه"
                    break
                  case "FORM":
                    element.channel_name_fa = "فرم"
                    break
                  case "SETTLEMENT":
                    element.channel_name_fa = "تسویه"
                    break
                  case "P2P":
                    element.channel_name_fa = "انتقال وجه"
                    break
                  case "REFUND":
                    element.channel_name_fa = "بازگشت وجه"
                    break
                  case "DIRECT_DEBIT":
                    element.channel_name_fa = "پرداخت خودکار"
                    break
                  case "CASH-IN":
                    element.channel_name_fa = "واریز بانکی"
                    break
                  case "CUSTOMER":
                    element.channel_name_fa = "مشتریان"
                    break
                  case "PBV":
                    element.channel_name_fa = "پرداخت با وندار"
                    break
                  default:
                    element.channel_name_fa = "-"
                    break
                }
              })
            }
            resolve({
              items,
              total,
            })
          })
          .catch((error) => {
            //callsnackbar
            this.$store.commit("changeLogFilterLoading", false)
          })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.vandar-table-empty {
  font-size: 12px;
  font-weight: normal;
  color: #333333;
  line-height: 24px;
  padding: 12px;
}
</style>
