<template>
  <v-form v-model="formValidation">
    <InputContainer label="کد رهگیری مالیاتی">
      <div :class="{ 'w-100': isMobileMode }">
        <v-flex class="withlabel big">
          <TaxCode
            :disable="$store.state.business.tax_code != null"
            :input-value="$store.state.business.tax_code + ''"
            @setValue="tax_code = $event"
          />
        </v-flex>
        <transition-group v-if="isMobileMode || isInModal" name="list">
          <div
            v-for="(item, index) in taxCodeGuidanceItems"
            v-show="$store.state.newbusiness.taxCodeFieldFocusState"
            :key="index"
            class="align-center max-width-250"
          >
            <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
              info_outline
            </v-icon>
            <span class="font-11-no-bbb-18"> {{ item }} </span>
            <br v-if="index !== taxCodeGuidanceItems.length - 1" />
          </div>
        </transition-group>
      </div>
    </InputContainer>
    <template v-if="$store.state.business.business_type === 'legal'">
      <InputContainer label="شماره ثبت شرکت">
        <v-flex class="withlabel big">
          <v-text-field
            ref="businessRegistrationCode"
            v-model.trim="businessRegistrationCode"
            solo
            flat
            height="40"
            outlined
            :rules="businessRegistrationCodeRules"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-text-field>
        </v-flex>
      </InputContainer>
      <InputContainer label="تاریخ ثبت شرکت" class="custom-input">
        <v-flex class="withlabel big">
          <v-text-field
            v-model.trim="registerDateText"
            solo
            :rules="businessRegistrationDateRules"
            flat
            height="40"
            outlined
            @focus="$store.commit('setShowStateOfDatePicker', true)"
            @keypress="(e) => e.preventDefault()"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-text-field>
        </v-flex>
      </InputContainer>
    </template>
    <!-- v-else-if="
        $store.state.business.business_type == 'personal' &&
        $store.state.business.owner.birthdate.slice(0, 4) <= 1367
      " -->
    <template v-else>
      <InputContainer label="شماره شناسنامه">
        <v-flex class="withlabel big">
          <v-text-field
            ref="birthcertificateNumber"
            v-model.trim="birthcertificateNumber"
            solo
            flat
            height="40"
            outlined
            :rules="birthcertificateNumberRule"
            @focus="isBlurBirthCertificateNumber = false"
            @blur="checkBlur"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-text-field>
        </v-flex>
      </InputContainer>
    </template>
    <DatePicker @setDate="setTheDate($event)" />
  </v-form>
</template>

<script>
import InputContainer from "@/components/elements/inputContainer.vue"
import TaxCode from "@/components/inputs/taxCode"
import DatePicker from "@/components/modals/datePicker.vue"

export default {
  name: "CreatingIpgSecondPart",
  components: {
    InputContainer,
    DatePicker,
    TaxCode,
  },
  inject: ["taxCodeGuidanceItems"],
  props: {
    isInModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tax_code: "",
      businessRegistrationCode: null,
      birthcertificateNumber: null,
      birthcertificateNumberRule: [
        (v) => !!v || "<span> وارد کردن شماره شناسنامه الزامی است</span>",
      ],
      businessRegistrationCodeRules: [
        (v) => !!v || "<span> وارد کردن شماره ثبت شرکت الزامی است</span>",
      ],
      businessRegistrationDateRules: [
        (v) => !!v || "<span> وارد کردن تاریخ ثبت شرکت الزامی است</span>",
      ],
      registerDateText: "",
      registerDate: "",
      showDatePicker: false,
      formValidation: false,
      isBlurBirthCertificateNumber: false,
    }
  },
  computed: {
    providePayload() {
      return {
        tax_code: this.tax_code,
        businessRegistrationCode: this.businessRegistrationCode,
        registerDate: this.registerDate,
        birthcertificateNumber: this.birthcertificateNumber,
        isValid: this.formValidation,
      }
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  watch: {
    businessRegistrationCode(newValue) {
      if (newValue) {
        this.businessRegistrationCode = this.convertNumbers2English(
          newValue
        ).replace(/[^0-9]/g, "")
        this.businessRegistrationCode = this.businessRegistrationCode.slice(
          0,
          50
        )
      }
      this.$refs.businessRegistrationCode.lazyValue =
        this.businessRegistrationCode
    },
    birthcertificateNumber(newValue) {
      if (newValue) {
        this.birthcertificateNumber = this.convertNumbers2English(
          newValue
        ).replace(/[^0-9]/g, "")
        this.birthcertificateNumber = this.birthcertificateNumber.slice(0, 10)
      }
      this.$refs["birthcertificateNumber"].lazyValue =
        this.birthcertificateNumber
    },
    providePayload(val) {
      this.$emit("second-form-validation", val)
    },
  },
  methods: {
    setTheDate(event) {
      this.registerDateText = event.dateText
      this.registerDate = event.date
    },
    checkBlur() {
      this.isBlurBirthCertificateNumber = true
    },
  },
}
</script>

<style lang="scss" scoped>
.list-enter,
.list-leave-to {
  visibility: hidden;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
</style>
