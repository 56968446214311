<template>
  <div>
    <v-layout v-if="load" wrap class="cards justify-center justify-md-start">
      <!-- <v-flex
        v-if="
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.requestMoney.status == 'ACTIVE'
        "
      >
        <v-card
          v-if="skeleton"
          outlined
          style="border-radius: 3px"
          elevation="0"
        >
          <div class="skeleton" />
        </v-card>
        <card
          v-else
          title="فرم پرداخت"
          channel="FORM"
          color="#4c9f87"
          out-link="request_money"
        />
      </v-flex> -->
      <!-- <v-flex
        v-if="
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.ipg.status == 'ACTIVE'
        "
      >
        <v-card
          v-if="skeleton"
          outlined
          style="border-radius: 3px"
          elevation="0"
        >
          <div class="skeleton" />
        </v-card>
        <card
          v-else
          title="درگاه پرداخت"
          channel="IPG"
          color="#F9D358"
          out-link="ipg"
        />
      </v-flex> -->
      <v-flex
        v-if="
          $store.state.isUser ||
          (!$store.state.isUser &&
            !['observer', 'operator'].includes($store.state.business.role))
        "
      >
        <v-card
          outlined
          class="addServiceCard"
          color="white"
          max-width="290px"
          flat
          @click.native="goToTools()"
        >
          <div class="backgound">
            <div>
              <svg
                height="15.997"
                style="fill: #fff"
                viewBox="0 0 16 15.997"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(0 -0.038)">
                  <g transform="translate(0.001 0.038)">
                    <g transform="translate(0 0)">
                      <path
                        class="a"
                        d="M.441,264.282a1.5,1.5,0,0,0,2.127,2.126l4.937-4.938-2.127-2.125Z"
                        transform="translate(-0.001 -250.851)"
                      />
                      <path
                        class="a"
                        d="M279.69,4.376l2.15-1.112,1.126-2.176-1.05-1.05-2.175,1.127-1.114,2.148-2.615,2.618,1.061,1.063Z"
                        transform="translate(-266.967 -0.038)"
                      />
                      <path
                        class="a"
                        d="M13.719,10.047l-.2-.02a2.972,2.972,0,0,0-1.036.195L6.331,4.071a2.972,2.972,0,0,0,.195-1.036l-.02-.2A2.967,2.967,0,0,0,2.244.338L4.235,2.328a.977.977,0,0,1,.225.36A1,1,0,0,1,2.822,3.74L.832,1.75a2.967,2.967,0,0,0,2.5,4.261l.2.02a3,3,0,0,0,1.036-.194l6.149,6.15a3.018,3.018,0,0,0-.193,1.036l.021.2a2.985,2.985,0,0,0,2.976,2.795,2.934,2.934,0,0,0,1.285-.3L12.812,13.73a1.02,1.02,0,0,1-.227-.359,1,1,0,0,1,.933-1.347.982.982,0,0,1,.348.068.958.958,0,0,1,.359.225l1.991,1.991a2.955,2.955,0,0,0,.3-1.285A2.99,2.99,0,0,0,13.719,10.047Z"
                        transform="translate(-0.514 -0.038)"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="font_11">افزودن ابزار جدید</div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import card from "./card"

export default {
  name: "ListOfCards",
  // components: {
  //   card,
  // },
  props: {
    skeleton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      load: false,
    }
  },
  mounted() {
    this.load = true
  },
  methods: {
    goToTools() {
      this.$router.push(
        "/business/" + this.$store.state.business.business_name + "/tools"
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.addServiceCard {
  height: 160px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 0px 30px 0px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  cursor: pointer;
  div.backgound {
    height: 70px !important;
    width: 70px !important;
    background-color: rgba(214, 89, 131, 0.1);
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    > div {
      width: 50px;
      background-color: $van_pink;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $van_color13;
    }
  }
}
.cards {
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 30px;
  > .flex {
    align-self: flex-end;
    // height: 160px;
    max-width: 290px;
    width: 290px;
    margin: 5px 5px;
    position: relative;
  }
}
.skeleton {
  max-width: 290px;
  height: 160px;
  background-color: $van_color10;
  border: $van_color00 1px;
  margin-right: auto;
  margin-left: 0;
}
@media (max-width: 1200px) {
  .layout {
    justify-content: center;
  }
}
</style>
