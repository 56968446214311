<template>
  <div class="activationBlock">
    <span class="topBack pendingTopBack" />
    <v-layout class="mx-auto twoPending" column style="margin: -20px">
      <v-flex class="personal_title alldone">
        <v-layout align-center justify-space-between>
          <v-flex>
            <v-layout align-center>
              <v-flex style="max-width: 60px">
                <v-img
                  :src="require('@/assets/img/newIcon/pbvPending.svg')"
                  alt="avatar"
                  width="40"
                />
              </v-flex>
              <v-flex>
                <div class="font_5">بررسی فعال‌سازی پرداخت با وندار</div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <div class="font_7" style="text-align: left">
              در انتظار تایید کسب‌و‌کار
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "PbvActivationBlock",
  data() {
    return {
      //this is modal status
      status: false,
      time: 0,
    }
  },
  methods: {
    close_modal() {
      this.status = false
    },
  },
}
</script>

<style lang="scss" scoped>
.twoPending {
  > .personal_title:nth-child(2) {
    margin-top: 30px;
  }
}
</style>
