<template>
  <div>
    <v-flex
      v-if="emergancyEnable && emergancyMessage != null"
      class="blockedBlock"
    >
      <span class="back2" />
      <div class="blockText">
        <svg
          style="fill: #f9d358; opacity: 0.3"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
        >
          <path
            class="a"
            d="M568.133,331.057s2.261-2.4,4.523-2.4,4.523,2.4,4.523,2.4l18.084,18.076s2.4,2.262,2.4,4.523-2.4,4.523-2.4,4.523h0l-18.084,18.084s-2.261,2.4-4.523,2.4-4.523-2.4-4.523-2.4l-18.076-18.084s-2.4-2.261-2.4-4.523,2.4-4.523,2.4-4.523Z"
            transform="translate(-547.66 -328.66)"
          />
        </svg>
        <div>
          {{ emergancyMessage }}
          <div v-if="emergancyLink" class="linkBorder">
            <a class="small" flat :ripple="false" @click="pushToMore()"
              >بیشتر بخوانید</a
            >
          </div>
        </div>
      </div>
    </v-flex>
    <v-flex
      v-if="blocked > 0"
      class="blockedBlock"
      :style="
        emergancyEnable && emergancyMessage != null ? 'margin-top: -30px' : ''
      "
    >
      <span class="back2" />
      <div class="blockText">
        <svg
          style="fill: #f9d358; opacity: 0.3"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
        >
          <path
            class="a"
            d="M568.133,331.057s2.261-2.4,4.523-2.4,4.523,2.4,4.523,2.4l18.084,18.076s2.4,2.262,2.4,4.523-2.4,4.523-2.4,4.523h0l-18.084,18.084s-2.261,2.4-4.523,2.4-4.523-2.4-4.523-2.4l-18.076-18.084s-2.4-2.261-2.4-4.523,2.4-4.523,2.4-4.523Z"
            transform="translate(-547.66 -328.66)"
          />
        </svg>
        <div>
          بنا به
          <b>احکام قضایی</b>
          مبلغ {{ blocked | currency }} {{ currencyName }} از موجوی کیف پول شما
          تا اطلاع ثانوی، مسدود شده است. از همکاری شما سپاسگزاریم
        </div>
      </div>
    </v-flex>
    <div class="settlement">
      <v-layout
        class="parrent pa-6 column"
        :class="{ 'pa-8': $vuetify.breakpoint.smAndUp }"
      >
        <v-flex>
          <v-layout
            justify-space-between
            :align-center="$vuetify.breakpoint.smAndUp"
            :column="reuseIsMobile"
            :class="{
              'mb-3':
                $vuetify.breakpoint.smAndUp &&
                settlemnetObj?.payment_facilitator_wallet,
            }"
          >
            <v-flex
              v-if="isThereAnyAccounts"
              class="text-right mx-0"
              :class="{ 'max-width-75': !reuseIsMobile }"
            >
              <v-layout
                class="ma-0"
                :class="{
                  'd-flex flex-column align-start justify-start': reuseIsMobile,
                  'align-center': $vuetify.breakpoint.smAndUp,
                }"
              >
                <div
                  class="mr-0 ml-4"
                  :class="{ 'd-flex align-center': reuseIsMobile }"
                >
                  <img
                    max-width="60"
                    height="60"
                    class="ma-auto"
                    :src="require('@/assets/img/newIcon/contractIcon.svg')"
                  />
                  <div v-if="reuseIsMobile" class="mr-2 w-100">
                    <div>
                      <span v-if="load" dir="ltr" class="font_1 bold ml-1">
                        کل موجودی
                      </span>
                      <div v-else style="width: 40%" class="default_gray" />
                    </div>
                    <div>
                      <div
                        v-if="!load"
                        style="width: 40%"
                        class="default_gray"
                      />
                      <span v-else class="font-12-no-000-24">
                        کیف‌پول وندار و حساب‌های بانکی
                      </span>
                    </div>
                  </div>
                </div>
                <v-flex :class="{ 'w-100': reuseIsMobile }">
                  <div
                    class="d-flex align-center"
                    :class="{
                      'text-right justify-space-between': !reuseIsMobile,
                      'justify-end': reuseIsMobile,
                    }"
                  >
                    <div v-if="!reuseIsMobile">
                      <div>
                        <span v-if="load" dir="ltr" class="font_1 bold ml-1">
                          کل موجودی
                        </span>
                        <div v-else style="width: 40%" class="default_gray" />
                      </div>
                      <div>
                        <div
                          v-if="!load"
                          style="width: 40%"
                          class="default_gray"
                        />
                        <span v-else class="font-12-no-000-24">
                          کیف‌پول وندار و حساب‌های بانکی
                        </span>
                      </div>
                    </div>
                    <span class="font-26-bo-000-40">
                      {{ sumOfWalletAndAccounts }}
                      <span class="font-12-no-000-24">{{ currencyName }}</span>
                    </span>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <span
                    class="d-flex font-11-no-666-17"
                    :class="{
                      'justify-start': !reuseIsMobile,
                      'justify-space-between': reuseIsMobile,
                    }"
                  >
                    <span> آخرین به‌روز رسانی: </span>
                    <span>
                      {{
                        jalaliDate(
                          $store.state.accountLastUpdatedTime * 1000,
                          "jYYYY/jM/jD - HH:mm"
                        )
                      }}
                    </span>
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-else class="text-right mx-0">
              <v-layout
                class="ma-0"
                :class="{
                  'd-flex flex-column align-start justify-start': reuseIsMobile,
                  'align-center': $vuetify.breakpoint.smAndUp,
                }"
              >
                <v-flex class="mr-0 ml-4" style="max-width: 60px">
                  <img
                    max-width="60"
                    height="60"
                    class="ma-auto"
                    :src="require('@/assets/img/newIcon/wallet.svg')"
                  />
                </v-flex>
                <v-flex class="text-right">
                  <div>
                    <div v-if="load" class="font_5">
                      <span dir="ltr" class="font_32 bold ml-1">{{
                        amount | currency
                      }}</span>
                      <span class="font_5 bold">{{ currencyName }}</span>
                      کیف پول وندار
                    </div>
                    <div v-else>
                      <div style="width: 40%" class="default_gray" />
                    </div>
                  </div>
                  <div>
                    <div v-if="!load">
                      <div style="width: 40%" class="default_gray" />
                    </div>
                    <div v-else>
                      <div>
                        <span v-if="overdraft" class="font_5">
                          با در نظر گرفتن اعتبار مازاد، مبلغ قابل برداشت
                          <span dir="ltr" class="bold ml-1">{{
                            deductibleWithOverdraft | currency
                          }}</span>
                          <span class="bold">{{ currencyName }}</span>
                          است.
                        </span>
                        <span v-else class="font_5">
                          مبلغ قابل برداشت
                          <span dir="ltr" class="bold ml-1">{{
                            today | currency
                          }}</span>
                          <span class="bold">{{ currencyName }}</span>
                          است.
                        </span>
                        <span
                          v-if="!settlemnetObj?.payment_facilitator_wallet"
                          class="linkBorder mx-0"
                        >
                          <v-btn
                            class="small mb-0"
                            style="font-size: 12px !important"
                            text
                            :ripple="false"
                            @click="open_detailSettlement"
                            >اطلاعات بیشتر</v-btn
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              class="mx-0 max-width-132 responsive-320"
              :class="{ 'd-flex mt-3': reuseIsMobile }"
            >
              <div
                v-if="
                  load &&
                  ($store.state.isUser ||
                    (!$store.state.isUser &&
                      !['observer', 'operator'].includes(
                        $store.state.business.role
                      )))
                "
                class="mb-0"
              >
                <v-tooltip
                  v-if="!$store.state.isUser || amount >= 5000"
                  :disabled="$store.state.business.status === 1"
                  top
                >
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        text
                        dark
                        :disabled="$store.state.business.status !== 1"
                        class="btn_small_normal mr-0 width-132"
                        @click="openSettlementModal()"
                      >
                        {{ lang.businessHome.withdrawalRequest }}
                      </v-btn>
                    </span>
                  </template>
                  <span>
                    {{ lang.businessHome.approvalPending }}
                  </span>
                </v-tooltip>
                <div v-else class="yellowBox font_7 mx-auto width-132">
                  حداقل برداشت {{ 5000 | currency }} {{ currencyName }}
                </div>
              </div>
              <div v-else-if="load">
                <v-btn disabled text dark class="btn_small_normal mr-0">
                  {{ lang.businessHome.withdrawalRequest }}
                </v-btn>
              </div>
              <div v-else>
                <div class="skeleton mt-1" />
              </div>
              <div v-if="showCashIn">
                <v-btn
                  id="cash_in"
                  text
                  dark
                  outlined
                  class="btn_small_normal mr-0 width-132"
                  :class="{ 'ml-0': reuseIsMobile }"
                  @click="openCashIn()"
                >
                  شارژ کیف‌پول وندار
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-divider
          v-if="
            settlemnetObj?.payment_facilitator_wallet && !isThereAnyAccounts
          "
        ></v-divider>
        <v-flex
          v-if="
            settlemnetObj?.payment_facilitator_wallet && !isThereAnyAccounts
          "
        >
          <v-layout
            justify-space-between
            :column="reuseIsMobile"
            class="align-center mt-3"
          >
            <v-flex>
              <v-layout
                :class="{
                  'd-flex flex-column': reuseIsMobile,
                  'align-center': $vuetify.breakpoint.smAndUp,
                }"
              >
                <v-flex class="mr-0 ml-4" style="max-width: 60px">
                  <img
                    max-width="60"
                    height="60"
                    class="ma-auto"
                    :src="require('@/assets/img/newIcon/lock.svg')"
                  />
                </v-flex>
                <v-flex class="font-size-14 bold text-right">
                  <div v-if="!load">
                    <div style="width: 40%" class="default_gray" />
                  </div>
                  <div v-else class="mb-0">
                    مبالغ تجمیع شده نزد شاپرک (غیرقابل برداشت)
                    {{ settlemnetObj?.payment_facilitator_wallet | currency }}
                    {{ currencyName }}
                    است.

                    <br :class="{ 'd-none': $vuetify.breakpoint.smAndUp }" />
                    <span
                      :class="{ 'd-none': $vuetify.breakpoint.smAndUp }"
                      class="linkBorder mx-0"
                    >
                      <v-btn
                        class="small mb-0"
                        style="font-size: 12px !important"
                        text
                        :ripple="false"
                        @click="open_detailSettlement"
                        >اطلاعات بیشتر</v-btn
                      >
                    </span>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex :class="{ 'max-width-132': $vuetify.breakpoint.smAndUp }">
              <div v-if="!load" class="text-center">
                <div style="width: 40%" class="default_gray" />
              </div>
              <span
                v-else
                class="linkBorder mx-0"
                :class="{ 'd-none': reuseIsMobile }"
              >
                <v-btn
                  class="small mb-0"
                  style="font-size: 12px !important"
                  text
                  :ripple="false"
                  @click="open_detailSettlement"
                  >اطلاعات بیشتر</v-btn
                >
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-dialog
        v-model="detailSettlement"
        :content-class="
          $store.state.secondWalletDetailModal
            ? 'secondModal vanmodal'
            : 'vanmodal'
        "
        transition="slide-x-transition"
        :width="reuseIsMobile ? '100%' : '600'"
        no-click-animation
        :fullscreen="reuseIsMobile"
      >
        <detail-wallet
          v-if="detailSettlement"
          :settlement-data="settlemnetObj"
          :overdraft="overdraft"
          @closeModal="close_modal"
        />
      </v-dialog>
    </div>
    <!-- modals -->
    <v-dialog
      v-if="
        $store.state.isUser ||
        (!$store.state.isUser &&
          $store.state.business.role &&
          ['owner', 'admin', 'accountant'].includes($store.state.business.role))
      "
      v-show="settlementModal"
      v-model="settlementModal"
      :content-class="
        $store.state.secondSettlementModal
          ? 'left-modal secondModal'
          : 'left-modal'
      "
      transition="slide-x-transition"
      :width="reuseIsMobile ? '100%' : '600'"
      :fullscreen="reuseIsMobile"
      no-click-animation
    >
      <WithdrawalMoney
        v-show="settlementModal"
        :key="componentKey"
        @open-cashIn="openCashIn()"
        @closeModal="close_modal"
        @errorHappened="error_happened"
        @settlement-iban-error="setSettlementIbanError"
      ></WithdrawalMoney>
    </v-dialog>

    <!-- store settlement end with error -->
    <SettlementErrorModal
      :is-error-happened="isShowSettlementIbanErrorModal"
      btn-text="بستن"
      modal-title-additional-style="mt-10 mb-5"
      :modal-title="settlementIbanErrorModalTitle"
      @do-require-action="isShowSettlementIbanErrorModal = false"
    ></SettlementErrorModal>
    <SettlementErrorModal
      :is-error-happened="errorHappen"
      has-sub-title
      @do-require-action="$router.push({ name: 'businessReport' })"
    ></SettlementErrorModal>

    <!-- cash in modal -->
    <div v-if="showCashIn">
      <v-dialog
        v-show="cashInModal"
        v-model="cashInModal"
        :content-class="
          $store.state.addBusinessIbanIsActive
            ? 'vanmodal thirdModal'
            : $store.state.showSecondModal
            ? 'secondModal vanmodal'
            : 'vanmodal'
        "
        transition="slide-x-transition"
        :width="reuseIsMobile ? '100%' : '600'"
        :fullscreen="reuseIsMobile"
        no-click-animation
      >
        <CashInModal
          v-show="cashInModal"
          :key="componentKey"
          :is-auto-cash-in-modal-active="isAutoCashInModalActive"
          @close-auto-cash-in-modal="isAutoCashInModalActive = false"
          @closeModal="close_cashIn_modal"
        />
      </v-dialog>
    </div>

    <!-- settlement send successful -->
    <general-dialog
      v-if="settlementDone"
      :active="settlementDone"
      logo="1stCheck.svg"
      content-class="confirmationMessageDialog"
      title="برداشت در صف ارسال به بانک قرار گرفت"
      description="وضعیت برداشت به صورت عمومی از طریق لینک زیر قابل مشاهده است:"
      :width="360"
      btn1-text="مشاهده لیست"
      :btn2-text="
        $store.state[$store.getters.userOrBuisenss].wallet > 0
          ? 'درخواست جدید'
          : null
      "
      @btn1Clicked="goToReports()"
      @btn2Clicked="openSettlementModal()"
    >
      <template slot="extra">
        <v-layout align-center class="mt-8">
          <v-flex
            class="font_10 van_color11 ml-1 mr-0 pt-2"
            style="
              height: 36px;
              width: 218px;
              border-radius: 4px;
              text-align: center;
            "
          >
            {{ receiptUrl }}
          </v-flex>
          <v-btn
            ref="btn"
            text
            depressed
            class="btn_small_normal van_color06 tblCpy-receipt_url"
            style="
              min-width: 80px !important;
              margin: 0px !important;
              padding: 0;
              max-width: 80px;
              height: 36px !important;
            "
            @click="shareReceiptUrl()"
          >
            <v-icon color="van_color03" class="ml-1" size="14">
              content_copy
            </v-icon>
            <span class="font_4">کپی لینک</span>
            <v-tooltip v-model="receipt_url_tooltip_show" top>
              <template #activator="{ on }">
                <v-btn
                  style="
                    position: absolute;
                    opacity: 0 !important;
                    width: 1px;
                    margin: 0px;
                    padding: 0px;
                    z-index: -1;
                    left: 40px;
                  "
                  v-on="on"
                />
              </template>
              <span>کپی شد</span>
            </v-tooltip>
          </v-btn>
          <input
            id="show-hidden-input-receipt_url"
            v-model="receiptUrl"
            class="hidden-input"
          />
        </v-layout>
      </template>
    </general-dialog>

    <!-- queue successful -->
    <general-dialog
      v-if="queueSettlementDone"
      :active="queueSettlementDone"
      logo="box-grren-past-time.svg"
      content-class="confirmationMessageDialog"
      title="برداشت در نوبت قرار گرفت"
      description="پس از بالا رفتن موجودی کیف‌پول، برداشت در صف بانک قرار می‌گیرد."
      :width="360"
      btn1-text="مشاهده لیست"
      btn2-text="درخواست جدید"
      @btn1Clicked="
        queueSettlementDone = false
        showQueueItems = true
      "
      @btn2Clicked="openSettlementModal()"
    >
    </general-dialog>

    <!--    cash-in successful-->
    <GeneralDialog
      v-if="cashInSuccess"
      :active="cashInSuccess"
      content-class="confirmationMessageDialog"
      logo="1stCheck.svg"
      title="واریز بانکی با موفقیت انجام شد"
      description="رسید واریز بانکی به صورت عمومی از طریق لینک زیر قابل مشاهده است:"
      btn1-text="بازگشت"
      btn2-text="درخواست جدید"
      @btn1Clicked="cashInSuccess = false"
      @btn2Clicked="openCashIn()"
    >
      <template slot="extra">
        <div style="width: 100%">
          <v-layout v-if="receiptUrl != null" align-center class="mt-8">
            <v-flex
              class="font_10 van_color11 ml-1 mr-0 pt-2"
              style="
                height: 36px;
                width: 218px;
                border-radius: 4px;
                text-align: center;
              "
            >
              {{ receiptUrl }}
            </v-flex>
            <v-btn
              ref="btn"
              text
              depressed
              class="btn_small_normal van_color06 tblCpy-receipt_url"
              style="
                min-width: 80px !important;
                margin: 0px !important;
                padding: 0;
                max-width: 80px;
                height: 36px !important;
              "
              @click="shareReceiptUrl()"
            >
              <v-icon color="van_color03" class="ml-1" size="14">
                content_copy
              </v-icon>
              <span class="font_4">کپی لینک</span>
              <v-tooltip v-model="receipt_url_tooltip_show" top>
                <template #activator="{ on }">
                  <v-btn
                    style="
                      position: absolute;
                      opacity: 0 !important;
                      width: 1px;
                      margin: 0px;
                      padding: 0px;
                      z-index: -1;
                      left: 40px;
                    "
                    v-on="on"
                  />
                </template>
                <span>کپی شد</span>
              </v-tooltip>
            </v-btn>
            <input
              id="show-hidden-input-receipt_url"
              v-model="receiptUrl"
              class="hidden-input"
            />
          </v-layout>
          <v-layout
            v-else
            class="van_color11 align-center justify-space-between mt-8"
          >
            <v-flex class="font_31 py-5 px-4 text-center">
              برای مشاهده رسیدها، به لیست واریز و برداشت مراجعه کنید
            </v-flex>
          </v-layout>
        </div>
      </template>
    </GeneralDialog>

    <!-- cash in dialog success all pending -->
    <!-- <general-dialog
      :active="cashInSuccessWithPending"
      btn2-class="btn_small_normal"
      btn2-text="بستن"
      content-class="confirmationMessageDialog"
      custom-style="height:270px"
      description="وندار قرارداد را برای شما ارسال می‌کند. پس از دریافت قرارداد، آن را تکمیل و به همراه گواهی امضای ثبت شده در دفتر اسناد رسمی برای وندار ارسال کنید."
      logo="1stCheck.svg"
      style="height: 100%"
      title="دریافت و ارسال مدارک"
      @btn2Clicked="close_modal()"
    /> -->

    <GeneralDialog
      :active="confirmOfAutoCashInModal"
      btn2-class="btn_small_normal"
      btn1-text="تنظیم واریز خودکار"
      btn2-text="بعدا انجام می‌دهم"
      content-class="confirmationMessageDialog"
      custom-style="height:270px"
      description=" با تنظیمات خودکار، روزانه مبلغ انتخاب شده از حسابی که انتخاب کرده‌اید به کیف پول‌تان در وندار واریز می‌شود."
      logo="newIcon/cash-in-icon.svg"
      style="height: 100%"
      title="واریز بانکی، خودکار انجام بشود؟"
      @btn1Clicked="setupAutoCashIn()"
      @btn2Clicked="cancelSetupAutoCashIn()"
    />

    <!-- cash in dialog success with reject -->
    <GeneralDialog
      :active="cashInSuccessWithRejected"
      :description="
        $store.state.business.business_type === 'legal'
          ? '        تعدادی از حساب‌های شما تایید ولی به دلیل همخوانی نداشتن نام حساب‌ها با نام حقوقی کسب‌و‌کار، برخی از حساب‌ها تایید نشدند'
          : '        تعدادی از حساب‌های شما تایید ولی به دلیل همخوانی نداشتن نام حساب‌ها با نام مالک کسب‌و‌کار، برخی از حساب‌ها تایید نشدند'
      "
      :btn1-text="isAllCashInRejected ? 'تلاش مجدد' : 'ادامه'"
      :btn2-text="isAllCashInRejected ? 'منصرف شدم' : ''"
      content-class="confirmationMessageDialog"
      custom-style="height:auto"
      :logo="
        isAllCashInRejected
          ? 'newIcon/error_close.svg'
          : 'newIcon/information.svg'
      "
      style="height: 100%"
      title="وضعیت تایید حساب‌ها"
      @btn1Clicked="showCashInSuccessWithPending()"
      @btn2Clicked="cancelCashin"
    >
      <template #extra>
        <div
          :class="
            cashInActiveIban.length > 0 ||
            cashInPendingIban.length > 0 ||
            cashInRejectedIban.length > 0
              ? 'mt-6'
              : ''
          "
          style="width: 100%"
        >
          <div v-if="cashInActiveIban.length > 0" style="width: 100%">
            <v-layout
              v-for="item in cashInActiveIban"
              :key="item.iban"
              class="van_green_shade_2 align-center justify-space-between px-5 py-3 mt-1"
            >
              <v-flex class="font_6" style="max-width: 200px"> تایید </v-flex>
              <v-flex class="text-left font_2">
                {{ item.iban }}
              </v-flex>
            </v-layout>
          </div>
          <div v-if="cashInPendingIban.length > 0" style="width: 100%">
            <v-layout
              v-for="item in cashInPendingIban"
              :key="item.iban"
              class="van_green_shade_2 align-center justify-space-between px-5 py-3 mt-1"
            >
              <v-flex class="font_6" style="max-width: 200px">
                تایید اولیه
              </v-flex>
              <v-flex class="text-left font_2">
                {{ item.iban }}
              </v-flex>
            </v-layout>
          </div>
          <div v-if="cashInRejectedIban.length > 0" style="width: 100%">
            <v-layout
              v-for="item in cashInRejectedIban"
              :key="item.iban"
              class="van_color11 align-center justify-space-between px-5 py-3 mt-1"
            >
              <v-flex class="font_6" style="max-width: 200px"> رد شد</v-flex>
              <v-flex class="text-left font_2">
                {{ item.iban }}
              </v-flex>
            </v-layout>
          </div>
        </div>
      </template>
    </GeneralDialog>

    <!-- cash in dialog success directly active -->
    <general-dialog
      :active="cashInDirectlyActive"
      btn1-text="درخواست واریز بانکی"
      btn2-class="btn_small_normal"
      btn2-text="بستن"
      content-class="confirmationMessageDialog"
      custom-style="height:auto"
      description="با توجه به مهلت قرارداد شما ابزار واریز بانکی بدون نیاز به ارسال قرارداد جدید فعال شد"
      logo="1stCheck.svg"
      style="height: 100%"
      title="ابزار فعال شد"
      @btn1Clicked="addCashIn()"
      @btn2Clicked="close_modal()"
    >
      <template v-if="cashInRejectedIban.length > 0" #extra>
        <div style="width: 100%">
          <v-layout
            v-for="item in cashInRejectedIban"
            :key="item.iban"
            class="van_color11 align-center justify-space-between px-5 py-3 mt-1"
          >
            <v-flex class="font_6" style="max-width: 200px"> رد شد</v-flex>
            <v-flex class="text-left font_2">
              {{ item.iban }}
            </v-flex>
          </v-layout>
        </div>
      </template>
    </general-dialog>

    <!-- cash in dialog failed -->
    <general-dialog
      :active="cashInFail"
      btn1-text="تلاش مجدد"
      btn2-class="btn_small_normal"
      btn2-text="انصراف"
      content-class="confirmationMessageDialog"
      custom-style="height:260px"
      description="به دلیل بروز خطا عملیات انجام نشد. لطفا دوباره تلاش کنید."
      logo="newIcon/warning.svg"
      style="height: 100%"
      title="عملیات انجام نشد"
      @btn1Clicked="addCashIn()"
      @btn2Clicked="close_modal()"
    />

    <!-- cash in dialog failed all rejected -->
    <general-dialog
      :active="cashInFailWitAllRejected"
      :description="
        $store.state.business.business_type === 'legal'
          ? 'به دلیل همخوانی نداشتن نام حساب‌ها با نام حقوقی کسب‌وکار، هیچکدام از حساب‌ها تایید نشدند.'
          : 'به دلیل همخوانی نداشتن نام حساب‌ها با نام مالک کسب‌وکار، هیچکدام از حساب‌ها تایید نشدند.'
      "
      btn1-text="تلاش مجدد"
      btn2-class="btn_small_normal"
      btn2-text="انصراف"
      content-class="confirmationMessageDialog"
      custom-style="height:auto"
      logo="newIcon/error_close.svg"
      style="height: 100%"
      title="وضعیت تایید حساب‌ها"
      @btn1Clicked="addCashIn()"
      @btn2Clicked="close_modal()"
    >
      <template #extra>
        <div class="mt-6" style="width: 100%">
          <v-layout
            v-for="item in cashInRejectedIban"
            :key="item.iban"
            class="van_color11 align-center justify-space-between px-5 py-3 mt-1"
          >
            <v-flex class="font_6" style="max-width: 200px"> رد شد</v-flex>
            <v-flex class="text-left font_2">
              {{ item.iban }}
            </v-flex>
          </v-layout>
        </div>
      </template>
    </general-dialog>

    <v-dialog
      v-if="showQueueItems"
      v-model="showQueueItems"
      :content-class="
        $store.state.showSecondModal ? 'secondModal left-modal' : 'left-modal'
      "
      transition="slide-x-transition"
      :width="reuseIsMobile ? '100%' : '600'"
      :fullscreen="reuseIsMobile"
      no-click-animation
    >
      <settlement-queue-items
        @closeModal="showQueueItems = false"
        @refreshQueuedCard="$emit('refreshQueuedCard')"
      />
    </v-dialog>
  </div>
</template>

<script>
import detailWallet from "../modals/detailWallet.vue"
import GeneralDialog from "@/components/modals/generalDialog"
import CashInModal from "@/components/report/modals/addCashIn/cashInModal"
import WithdrawalMoney from "@/components/A2A/modals/withdrawalMoney"
import SettlementQueueItems from "@/components/businessHome/elements/settlementQueue/settlementQueueItems.vue"
import servicesRepository from "@/services/servicesRepository"
import lang from "@/lang/languageResource.json"
import screenSize from "@/mixins/screenSize.js"
import currencyConvert from "@/mixins/currencyConvert"
import SettlementErrorModal from "@/components/elements/SettlementErrorModal.vue"

export default {
  name: "WalletStatus",
  components: {
    SettlementErrorModal,
    SettlementQueueItems,
    CashInModal,
    WithdrawalMoney,
    detailWallet,
    GeneralDialog,
  },
  mixins: [screenSize, currencyConvert],
  props: ["update"],
  data() {
    return {
      componentKey: 0,
      load: false,
      amount: 0,
      tomorrow: 0,
      today: 0,
      wallet_without_payment_facilitator_wallet: 0,
      blocked: 0,
      modalStatus: false,
      detailSettlement: false,
      status: true,
      settlemnetObj: null,
      overdraft: null,
      emergancyEnable: false,
      emergancyMessage: null,
      emergancyLink: null,
      settlementModal: false,
      updateTable: false,
      errorHappen: false,
      settlementDone: false,
      queueSettlementDone: false,
      receiptUrl: null,
      receipt_url_tooltip_show: false,
      //cash in data
      cashInModal: false,
      cashInSuccess: false,
      cashInSuccessWithPending: false,
      cashInSuccessWithRejected: false,
      cashInActiveIban: [],
      cashInPendingIban: [],
      cashInRejectedIban: [],
      cashInDirectlyActive: false,
      cashInFail: false,
      cashInFailWitAllRejected: false,
      showQueueItems: false,
      confirmOfAutoCashInModal: false,
      isAutoCashInModalActive: false,
      isShowSettlementIbanErrorModal: false,
      settlementIbanErrorModalTitle: "",
      lang,
    }
  },
  computed: {
    showCashIn() {
      return (
        this.load &&
        !this.$store.state.isUser &&
        (this.$store.state.business.role == "owner" ||
          this.$store.state.business.role == "admin" ||
          this.$store.state.business.role == "accountant")
      )
    },
    deductibleWithOverdraft() {
      return Math.min(this.today + this.overdraft.remaining, this.amount)
    },
    isAllCashInRejected() {
      return (
        this.cashInActiveIban.length === 0 &&
        this.cashInPendingIban.length === 0 &&
        this.cashInRejectedIban.length > 0
      )
    },
    isThereAnyAccounts() {
      return this.$store.state.accountsList?.length > 0
    },
    sumOfWalletAndAccounts() {
      const sum =
        this.reuseConvertCurrencyForTomanAmount(this.amount) +
        this.reuseConvertCurrencyForRialAmount(
          this.$store.state.totalBalanceAccounts
        )
      return this.$options.filters.thousandSeprator(sum)
    },
  },
  watch: {
    update: function (newValue, oldValue) {
      if (newValue == true) {
        this.initialState()
        this.getApi()
      }
    },
  },
  mounted() {
    this.getApi()
    this.getEmergancyMessage()
    if (this.$route.query["cash_in_status"]) {
      this.cashInStatusProgress()
    }
  },
  methods: {
    //cash-in
    showCashInSuccessWithPending() {
      if (this.isAllCashInRejected) {
        servicesRepository(this)
          .cashIn.httpCashInEnable()
          .then((response) => {
            window.location = response.data.redirect_url
          })
          .catch(() => {
            this.cashInActivateLoading = false
          })
      } else {
        this.cashInSuccessWithRejected = false
        this.confirmOfAutoCashInModal = true
      }
    },
    cashInStatusProgress() {
      this.cashInActiveIban = []
      this.cashInPendingIban = []
      this.cashInRejectedIban = []
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            "/cash-in/recently-accounts"
        )
        .then((response) => {
          for (const item of response.data) {
            switch (item?.status) {
              case "ACTIVE":
                this.cashInActiveIban.push(item)
                break
              case "PENDING":
                this.cashInPendingIban.push(item)
                break
              case "REJECTED":
                this.cashInRejectedIban.push(item)
                break
            }
          }
          if (this.$route.query["cash_in_status"] === "failed") {
            if (
              response.data &&
              response.data.length > 0 &&
              response.data.length === this.cashInRejectedIban.length
            ) {
              this.cashInFailWitAllRejected = true
            } else {
              this.cashInFail = true
            }
            this.$router.replace({})
          } else if (this.$route.query["cash_in_status"] === "succeed") {
            if (
              response.data &&
              response.data.length ===
                this.cashInPendingIban.length + this.cashInActiveIban.length &&
              this.cashInPendingIban.length > 0
            ) {
              this.cashInSuccessWithPending = true
            } else if (this.cashInPendingIban.length !== 0) {
              this.cashInSuccessWithRejected = true
            } else if (response.data.length > 0) {
              this.cashInDirectlyActive = true
            }
            this.$router.replace({})
          }
        })
        .catch(() => {
          this.$router.replace({})
        })
    },
    addCashIn() {
      this.cashInSuccessWithPending = false
      this.cashInSuccessWithRejected = false
      this.cashInDirectlyActive = false
      this.cashInFail = false
      this.cashInFailWitAllRejected = false
      this.cashInModal = true
    },
    cancelCashin() {
      this.cashInSuccessWithRejected = false
    },
    setupAutoCashIn() {
      this.cashInModal = true
      this.isAutoCashInModalActive = true
    },
    cancelSetupAutoCashIn() {
      this.confirmOfAutoCashInModal = false
      this.cashInModal = true
      this.isAutoCashInModalActive = false
    },
    shareReceiptUrl() {
      /* Get the text field */
      let copyText = document.querySelector("#show-hidden-input-receipt_url")

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)
        //Alert the copied text
        this.receipt_url_tooltip_show = true
        setTimeout(() => {
          this.receipt_url_tooltip_show = false
        }, 2000)
      }
    },
    openSettlementModal() {
      this.settlementDone = false
      this.queueSettlementDone = false
      this.settlementModal = true
    },
    openCashIn() {
      this.cashInSuccess = false
      this.cashInModal = true
    },
    close_cashIn_modal(obj) {
      this.cashInModal = false
      if (obj?.status) {
        if (obj["receiptUrl"]) {
          this.receiptUrl = obj.receiptUrl
        } else {
          this.receiptUrl = null
        }
        this.cashInSuccess = true
        this.allupdates()
        this.componentKey = !this.componentKey
      }
    },
    error_happened() {
      this.settlementModal = false
      this.errorHappen = true
      this.componentKey = !this.componentKey
      this.allupdates()
    },
    pushToMore() {
      if (this.emergancyLink) window.open(this.emergancyLink)
    },
    initialState() {
      this.load = false
      this.amount = 0
      this.tomorrow = 0
      this.today = 0
      this.blocked = 0
    },
    open_detailSettlement() {
      this.detailSettlement = true
    },
    close_modal(obj) {
      //cash-in
      this.cashInSuccessWithPending = false
      this.cashInSuccessWithRejected = false
      this.cashInDirectlyActive = false
      this.cashInFail = false
      this.cashInFailWitAllRejected = false

      //end
      this.detailSettlement = false
      this.settlementModal = false
      if (obj.isInQueue) {
        this.queueSettlementDone = true
        this.$emit("refreshQueuedCard")
      } else if (obj && obj.receiptUrl) {
        this.receiptUrl = obj.receiptUrl
        this.settlementDone = true
      } else {
        this.receiptUrl = null
      }
      if (obj && obj.status) {
        this.allupdates()
        this.componentKey = !this.componentKey
      }
    },
    allupdates() {
      //update settlement and table and wallet
      this.getApi()
    },
    getEmergancyMessage() {
      this.$http
        .get(
          this.$store.state.api_base_url + "/information/emergency-message",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.emergancyEnable =
            response.data.data.is_enable == 0 ? false : true
          this.emergancyMessage = response.data.data.message
          this.emergancyLink = response.data.data.link
        })
        .catch((error) => {})
    },
    getApi() {
      servicesRepository(this)
        .settlementServices.httpGetSettlementInfo()
        .then((response) => {
          let res = response.data.data
          this.amount = res.wallet

          //check if amount want to update
          let param
          this.$store.state.isUser ? (param = "user") : (param = "business")
          if (this.$store.state[param].wallet != this.amount) {
            this.$store.commit("changeWallet", {
              name: param,
              wallet: this.amount,
            })
          }

          this.tomorrow = res.tomorrow_deductible_amount
          this.today = res.deductible_amount
          this.wallet_without_payment_facilitator_wallet =
            res.wallet_without_payment_facilitator_wallet
          this.blocked = res.blocked_amount
          this.overdraft = res.overdraft
          this.load = true
          this.$store.commit("setWalletAmountDetails", {
            deductibleAmount: res.deductible_amount,
            paymentFacilitatorWallet: res.payment_facilitator_wallet,
            walletWithoutPaymentFacilitatorWallet:
              res.wallet_without_payment_facilitator_wallet,
          })
          this.settlemnetObj = res
          this.$emit("doneUpdateSettlement")
        })
        .catch((error) => {
          if (
            error &&
            error.response != undefined &&
            error.response.status &&
            error.response.status === 429
          ) {
            setTimeout(() => {
              this.getApi()
            }, 2000)
          }
          //else{
          //callsnackbar
          //}
          this.$emit("doneUpdateSettlement")
        })
    },
    goToReports() {
      this.settlementDone = false

      let urlPartial = this.$store.state.isUser
        ? "personal"
        : "/business/" + this.$store.state.business.business_name

      this.$router.push(urlPartial + "/report")
    },
    setSettlementIbanError(err) {
      this.isShowSettlementIbanErrorModal = true
      this.settlementIbanErrorModalTitle = err
      this.settlementModal = false
      this.componentKey = !this.componentKey
    },
  },
}
</script>

<style lang="scss" scoped>
.overdraft {
  background-color: #eeeeee;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  bottom: -23px;
  right: calc(50% - 25px);
  cursor: pointer;
  > div {
    background-color: $van_pink;
    border-radius: 50%;
    padding: 11.82px 10px;
    display: flex;
  }
}
.skeleton {
  width: 72px;
  height: 15px;
  background-color: $van_color11;
  border-radius: 3px;
  margin: 15px auto 5px auto;
}
.blockedBlock {
  position: relative;
  z-index: 2;
  margin: -10px auto 65px;
  height: 50px;
  .back2 {
    width: 100vw;
    height: 90px;
    background-color: #f8f3e0;
    position: absolute;
    top: -26px;
    right: calc((920px - 100vw) / 2);
    bottom: 0;
    left: 0;
    z-index: 0;
    @media screen and (max-width: 1275px) {
      right: -181px;
      left: -30px;
      width: 152vw;
    }
    @media screen and (max-width: 599px) {
      //display: none;
    }
  }
  .blockText {
    position: relative;
    display: flex;
    align-items: center;
    max-height: 50px;
    > div {
      color: $van_color03;
      font-size: 12px;
      z-index: 20;
      margin-right: -25px;
    }
  }
}
.settlement {
  margin: 0 0 65px 0;
  > span.back {
    width: 120vw;
    background-color: #eeeeee;
    position: absolute;
    top: 0;
    right: calc((920px - 100vw) / 2);
    bottom: 0;
    left: 0;
    z-index: 0;
    @media screen and (max-width: 1275px) {
      right: -181px;
      left: -30px;
      width: 152vw;
    }
    @media screen and (max-width: 599px) {
      display: none;
    }
  }
  > .parrent {
    background: $van_color13 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000d;
    border: 1px solid $van_color11;
    border-radius: 4px 0px 0px 4px;
    opacity: 1;
    z-index: 5;
    position: relative;
    @media screen and (max-width: 599px) {
      height: 100%;
      > .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        > div:nth-child(1) {
          margin-bottom: 0px !important;
        }
      }
    }
    .flex {
      .layout {
        width: 100%;
        > .flex {
          text-align: center;

          .yellowBox {
            background-color: rgba(249, 211, 88, 0.3);
            text-align: center;
            padding: 6px 10px 6px 10px;
          }
          &.special {
            min-width: 235px;
            height: 160px;
            background: $van_color13 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 20px #0000001a;
            border: 1px solid $van_color11;
            border-radius: 4px;
            opacity: 1;
            margin: 0px;
            padding: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            > div:nth-child(1) {
              margin-bottom: 6px;
            }
            > div:nth-child(2) {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
}

.max-width-132 {
  max-width: 132px;
}

.width-132 {
  width: 132px;
}

@media screen and (max-width: 320px) {
  .responsive-320 {
    flex-direction: column;
    text-align: right !important;
  }
}

.max-width-75 {
  max-width: 75% !important;
}
</style>
