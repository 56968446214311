<template>
  <left-modal
    :toolbar-name="item?.title"
    class="cash-in-modal"
    @closeModal="$emit('closeModal')"
  >
    <template>
      <v-layout column style="border-top: 1px solid #f5f5f5">
        <v-tabs v-model="tab" class="pr-8 van_color13">
          <v-tab v-if="element?.details" class="tab-title px-1 font-size-12"
            >جزییات معامله</v-tab
          >
          <v-tab
            v-if="element?.history?.length"
            class="tab-title px-1 font-size-12"
            >تاریخچه</v-tab
          >
          <v-tab
            v-if="element?.tickets?.length"
            class="tab-title px-1 font-size-12"
            >تیکت‌ها</v-tab
          >
        </v-tabs>
      </v-layout>
      <v-layout v-if="element" column justify-space-between class="pa-8">
        <v-flex class="mb-2">
          <v-tabs-items v-model="tab">
            <v-tab-item v-if="element?.details">
              <v-card :color="stateColor" class="px-10 py-5 radius-4">
                <div class="d-flex align-center justify-space-between">
                  <div class="font-size-12">وضعیت:</div>
                  <div class="text-left font-size-14 font-weight-bold">
                    {{ item.state }}
                  </div>
                </div>
              </v-card>
              <general-card class="mt-8">
                <v-flex>
                  <v-layout column class="rows">
                    <v-flex>
                      <v-layout wrap align-center>
                        <v-flex
                          class="label"
                          :style="
                            $vuetify.breakpoint.xsOnly
                              ? 'width:100%;'
                              : 'width:110px;'
                          "
                        >
                          شناسه
                        </v-flex>
                        <v-flex>{{ element.details.id }}</v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        align-start
                      >
                        <v-flex class="label"> دسته‌بندی </v-flex>
                        <v-flex>{{ element.details.category }}</v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        align-start
                      >
                        <v-flex class="label">
                          فروشنده<span
                            v-if="element.details.seller.creator"
                            class="van_color03--text"
                            >(ایجاد کننده)</span
                          >
                        </v-flex>
                        <v-flex style="text-decoration: underline">
                          <div class="linkBorder">
                            <a @click="openCustomer(element.seller?.id)">{{
                              element.details.seller?.name
                            }}</a>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        align-start
                      >
                        <v-flex class="label">
                          خریدار<span
                            v-if="element.details.buyer.creator"
                            class="van_color03--text"
                            >(ایجاد کننده)</span
                          >
                        </v-flex>
                        <v-flex style="text-decoration: underline">
                          <div class="linkBorder">
                            <a
                              @click="openCustomer(element.details.buyer?.id)"
                              >{{ element.details.buyer?.name }}</a
                            >
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        align-start
                      >
                        <v-flex class="label"> قیمت محصول </v-flex>
                        <v-flex
                          >{{ (element.details.price / 10) | currency }}
                          {{ currencyName }}</v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        align-start
                      >
                        <v-flex class="label"> مبلغ بیعانه </v-flex>
                        <v-flex
                          >{{
                            (element.details.deposit_price / 10) | currency
                          }}
                          {{ currencyName }}</v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        align-start
                      >
                        <v-flex class="label"> تاریخ ایجاد </v-flex>
                        <v-flex>{{
                          jalaliDate(element.details.created_at * 1000)
                        }}</v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        align-start
                      >
                        <v-flex class="label"> تاریخ آخرین رخداد </v-flex>
                        <v-flex>{{
                          jalaliDate(element.details.updated_at * 1000)
                        }}</v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        align-start
                      >
                        <v-flex class="label"> کارمزد وندار </v-flex>
                        <v-flex
                          >{{ (element.details.wage_price / 10) | currency }}
                          {{ currencyName }}</v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout
                        :column="$vuetify.breakpoint.xsOnly"
                        align-start
                      >
                        <v-flex class="label"> توضیحات </v-flex>
                        <v-flex style="max-width: 340px">{{
                          element.details.description
                        }}</v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout wrap align-center>
                        <v-flex
                          class="label"
                          :style="
                            $vuetify.breakpoint.xsOnly
                              ? 'width:100%;'
                              : 'width:110px;'
                          "
                        >
                          لینک آگهی
                        </v-flex>
                        <v-flex style="text-decoration: underline">
                          <div class="linkBorder">
                            <a @click="openLink(element.details.link)">{{
                              element.details.link
                            }}</a>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    v-if="element.details?.attachments?.length"
                    class="wrap mt-4"
                  >
                    <v-flex
                      v-for="(image, i) in element.details.attachments"
                      :key="'image-' + i"
                      class="pa-2"
                    >
                      <v-img
                        :src="image"
                        height="106"
                        width="177"
                        contain
                        style="border-radius: 8px"
                      ></v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </general-card>
              <template>
                <div class="font-size-12 font-weight-bold">بستانکاران</div>
                <general-card class="mt-4">
                  <v-data-table
                    :headers="headersHeaders"
                    :items="element.details.creditors"
                    class="elevation-0 vandarTable customer_transaaction_table noHover"
                    no-data-text="بستانکاری برای نمایش وجود ندارد"
                    no-results-text="در حال دریافت لیست بستانکاران"
                    hide-default-footer
                  >
                    <template #item.name="{ item }">
                      <div class="linkBorder">
                        <a @click="openCustomer(item.user_id)">{{
                          item.name
                        }}</a>
                      </div>
                    </template>
                    <template #item.price="{ item }">
                      {{ parseInt(item.price / 10) | currency }}
                    </template>
                  </v-data-table>
                </general-card>
              </template>
            </v-tab-item>
            <v-tab-item v-if="element?.history?.length">
              <general-card>
                <v-flex>
                  <v-layout column class="rows">
                    <v-flex
                      v-for="history in element.history"
                      :key="history.created_at"
                    >
                      <v-layout column align-start>
                        <v-flex class="font-size-12">
                          {{ history.message }}
                        </v-flex>
                        <v-flex class="font-size-11 van_color04--text mt-1">
                          {{ jalaliDate(history.created_at * 1000) }} |
                          {{ history.created_at_human }}</v-flex
                        >
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </general-card>
            </v-tab-item>
            <v-tab-item v-if="element?.tickets?.length">
              <general-card>
                <v-data-table
                  :headers="ticketsHeaders"
                  :items="element.tickets"
                  class="elevation-0 vandarTable customer_transaaction_table noHover"
                  no-data-text="تیکتی برای نمایش وجود ندارد"
                  no-results-text="در حال دریافت لیست تیکت‌ها"
                  hide-default-footer
                >
                  <template #item.creator="{ item }">
                    <div class="linkBorder">
                      <a @click="openCustomer(item.creator.id)">{{
                        item.creator.name
                      }}</a>
                    </div>
                  </template>
                  <template #item.created_at="{ item }">
                    {{
                      jalaliDate(item.created_at * 1000, "jYY/jMM/jDD HH:mm:ss")
                    }}
                  </template>
                </v-data-table>
              </general-card>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </template>

    <!--    dialogs     -->

    <!-- introduce modal -->
    <v-dialog
      v-if="secondModal"
      v-model="secondModal"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      transition="slide-x-transition"
    >
    </v-dialog>
  </left-modal>
</template>

<script>
import LeftModal from "../../modals/leftModal"
import GeneralCard from "../../elements/generalCard"

export default {
  name: "CashInModal",
  components: {
    GeneralCard,
    LeftModal,
  },
  props: {
    item: {
      type: [Object, null],
    },
  },
  data() {
    return {
      element: null,
      tab: null,
      disable: false,
      secondModal: false,
      headersHeaders: [
        {
          text: "نقش",
          value: "role",
        },
        {
          text: "نام و نام خانوادگی",
          value: "name",
        },
        {
          text: "شناسه کاربری",
          value: "user_id",
        },
        {
          text: "مبلغ",
          value: "price",
        },
      ],
      ticketsHeaders: [
        {
          text: "شناسه تیکت",
          value: "id",
        },
        {
          text: "ایجاد کننده",
          value: "creator",
        },
        {
          text: "دلیل ثبت مشکل",
          value: "reason",
        },
        {
          text: "تاریخ ایجاد",
          value: "created_at",
        },
      ],
    }
  },
  computed: {
    stateColor() {
      switch (this.item?.state) {
        case "رد شده":
        case "لغو شده":
          return "#D44D4233"
        case "ثبت مشکل شده":
          return "#F9D35833"
        default:
          return "#4C9F871A"
      }
    },
  },
  watch: {
    secondModal: function (newValue) {
      if (!newValue) {
        this.close_modal()
        this.$store.commit("changeShowSecondModal", false)
      }
    },
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    close_modal(obj) {
      this.secondModal = false
      if (obj && obj.status) {
        this.$emit("closeModal", {
          status: true,
        })
      }
    },
    getDetails() {
      this.$http
        .get(
          this.$store.state.miando_url +
            `/api/dashboard/v1/agreements/${this.$store.state.business.id}/${this.item.id}`,
          {
            headers: {
              "X-Requested-With": "user",
            },
          }
        )
        .then((resp) => {
          this.element = resp.data.data
        })
        .catch(() => {
          this.close_modal()
        })
    },
    openLink(link) {
      window.open(link)
    },
    openCustomer() {
      //
    },
  },
}
</script>
