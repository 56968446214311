<template>
  <chart-card-one-filter
    class="mmt-5"
    :title="kerdarLang.directDebit.comparisoningOfUnsuccessfulLicense"
    :show-filter="false"
  >
    <one-bar-horizontal-bar-type2
      v-if="compareFailedLicenseRequestsStatus"
      :x-value="[
        kerdarLang.general.unsuccessfulOfLastDay,
        generateTextOfAverageLastSomeDays(7),
        generateTextOfAverageLastSomeDays(30),
      ]"
      :y-value="compareFailedLicenseRequestsValue"
      :y-result="compareFailedLicenseRequestsTooltip"
      :y-unit="kerdarLang.general.unsuccessfulDemand"
      :round-value="true"
    />
    <div v-else class="skeleton_box2 mmt-10" style="min-height: 276px" />
  </chart-card-one-filter>
</template>

<script>
import ChartCardOneFilter from "@/components/kerdar/components/chartCardOneFilter"
import OneBarHorizontalBarType2 from "@/components/kerdar/components/oneBarHorizontalBarType2"
import {
  calculateGrowPercentage,
  generateTextOfAverageLastSomeDays,
  generateTextOfAverageUnsuccessfulDepositsLastSomeDays,
} from "@/components/kerdar/utils/generalMethods"
import kerdarServices from "@/components/kerdar/services/servicesRepository"

export default {
  name: "DirectDebitUnsuccessfulDepositsComparison",
  components: {
    "chart-card-one-filter": ChartCardOneFilter,
    "one-bar-horizontal-bar-type2": OneBarHorizontalBarType2,
  },
  props: {
    kerdarLang: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      compareFailedLicenseRequestsData: [],
      compareFailedLicenseRequestsStatus: false,
      compareFailedLicenseRequestsBarColor: "#d44d42",
      generateTextOfAverageLastSomeDays,
    }
  },
  computed: {
    compareFailedLicenseRequestsValue() {
      return this.compareFailedLicenseRequestsData
    },
    compareFailedLicenseRequestsTooltip() {
      if (this.compareFailedLicenseRequestsValue) {
        let text0 = null
        let text1 = generateTextOfAverageUnsuccessfulDepositsLastSomeDays(7)
        if (
          calculateGrowPercentage(
            this.compareFailedLicenseRequestsValue[1],
            this.compareFailedLicenseRequestsValue[0]
          ) == 0
        ) {
          text1 += this.kerdarLang.general.unchangedWithTag
        } else if (
          calculateGrowPercentage(
            this.compareFailedLicenseRequestsValue[1],
            this.compareFailedLicenseRequestsValue[0]
          ) > 500
        ) {
          text1 += this.kerdarLang.general.moreThan500PercentWithTag
        } else {
          text1 += `<span style='color:#D44D42'> ${Math.abs(
            calculateGrowPercentage(
              this.compareFailedLicenseRequestsValue[1],
              this.compareFailedLicenseRequestsValue[0]
            )
          )}٪ `
          if (
            calculateGrowPercentage(
              this.compareFailedLicenseRequestsValue[1],
              this.compareFailedLicenseRequestsValue[0]
            ) >= 0
          ) {
            text1 += this.kerdarLang.general.itHasBeenMoreWithTag
          } else {
            text1 += this.kerdarLang.general.itHasBeenLessWithTag
          }
        }

        let text2 = generateTextOfAverageUnsuccessfulDepositsLastSomeDays(30)
        if (
          calculateGrowPercentage(
            this.compareFailedLicenseRequestsValue[2],
            this.compareFailedLicenseRequestsValue[0]
          ) == 500
        ) {
          text2 += this.kerdarLang.general.unchangedWithTag
        } else if (
          calculateGrowPercentage(
            this.compareFailedLicenseRequestsValue[2],
            this.compareFailedLicenseRequestsValue[0]
          ) > 0
        ) {
          text2 += this.kerdarLang.general.moreThan500PercentWithTag
        } else {
          text2 += `<span style='color:#D44D42'> ${Math.abs(
            calculateGrowPercentage(
              this.compareFailedLicenseRequestsValue[2],
              this.compareFailedLicenseRequestsValue[0]
            )
          )}٪ `
          if (
            calculateGrowPercentage(
              this.compareFailedLicenseRequestsValue[2],
              this.compareFailedLicenseRequestsValue[0]
            ) >= 0
          ) {
            text2 += this.kerdarLang.general.itHasBeenMoreWithTag
          } else {
            text2 += this.kerdarLang.general.itHasBeenLessWithTag
          }
        }
        return [text0, text1, text2]
      } else {
        return ["", "", ""]
      }
    },
  },
  mounted() {
    this.getCompareFailedLicenseRequestsData()
  },
  methods: {
    async getCompareFailedLicenseRequestsData() {
      const response = await kerdarServices(
        this.$http,
        this
      ).directDebit.httpDirectDebitCompareFailedLicenseRequests()

      const data = response.data.data
      this.compareFailedLicenseRequestsData = data[0].map(this.convertToNumber)
      this.compareFailedLicenseRequestsStatus = true
    },
    convertToNumber(num) {
      return Number(num)
    },
  },
}
</script>

<style lang="scss" scoped></style>
