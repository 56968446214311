<template>
  <v-container class="d-flex flex-column align-center justify-center container">
    <div class="d-flex align-center w-100 progress-bar-wrapper">
      <div class="d-flex flex-column align-center position-relative">
        <span
          class="font-14-bo-green-22 mb-2 titles-general-styles first-block-style"
          :class="{ 'stepper-title-font-size-mobile': isMobileMode }"
        >
          اطلاعات پایه
        </span>
        <span class="circle success-circle"></span>
      </div>
      <v-divider color="#4c9f87"></v-divider>
      <div class="d-flex flex-column align-center position-relative">
        <span
          class="font-14-bo-green-22 mb-2 titles-general-styles second-block-style"
          :class="{ 'stepper-title-font-size-mobile': isMobileMode }"
        >
          اطلاعات مکانی
        </span>
        <span class="circle success-circle"></span>
      </div>
      <v-divider color="#4c9f87"></v-divider>
      <div class="d-flex flex-column align-center position-relative">
        <span
          class="font-14-bo-green-22 mb-2 titles-general-styles third-block-style"
          :style="!isOnboardingThirdStepDone ? 'color:#000000' : ''"
          :class="{ 'stepper-title-font-size-mobile': isMobileMode }"
        >
          بارگذاری مدرک
        </span>
        <span
          class="circle"
          :class="{
            'success-circle': isOnboardingThirdStepDone,
            'void-circle': !isOnboardingThirdStepDone,
          }"
        ></span>
      </div>
      <v-divider color="#4c9f87"></v-divider>
      <div class="d-flex flex-column align-center position-relative">
        <span
          class="font-14-bo-green-22 mb-2 titles-general-styles fourth-block-style"
          :style="!isOnboardingFourthStepDone ? 'color:#000000' : ''"
          :class="{ 'stepper-title-font-size-mobile': isMobileMode }"
        >
          درگاه پرداخت
        </span>
        <span
          class="circle"
          :class="{
            'success-circle': isOnboardingFourthStepDone,
            'void-circle': !isOnboardingFourthStepDone,
          }"
        ></span>
      </div>
    </div>
    <div class="d-flex flex-column align-center mt-16">
      <template v-if="isOnboardingThirdStepDone && isOnboardingFourthStepDone">
        <p class="font_12 title-font-size-mobile">همه مراحل انجام شد</p>
        <span class="font_36 subtitle-font-size-mobile">
          پس از فعال‌ شدن کسب‌وکار، اطلاعات درگاه پرداخت بررسی می‌شوند.
        </span>
      </template>
      <template v-if="isOnboardingThirdStepDone && !isOnboardingFourthStepDone">
        <p class="font_12 title-font-size-mobile">
          مراحل ساخت کسب‌وکار تکمیل شد
        </p>
        <span class="font_36 subtitle-font-size-mobile">
          پس از فعال شدن کسب‌وکار می‌تونی درخواست فعال‌سازی ابزارهای وندار رو از
          جعبه ابزار ثبت کنی.
        </span>
      </template>
      <template v-if="!isOnboardingThirdStepDone && isOnboardingFourthStepDone">
        <p class="font_12 title-font-size-mobile">
          مراحل ساخت کسب‌وکار تکمیل نشده
        </p>
        <span class="font_36 subtitle-font-size-mobile">
          برای فعال شدن درگاه باید مدارک کسب‌وکار رو تکمیل کنی! پس از تایید
          کسب‌وکار درگاه پرداخت تایید می‌شه.
        </span>
      </template>
      <template
        v-if="!isOnboardingThirdStepDone && !isOnboardingFourthStepDone"
      >
        <p class="font_12 title-font-size-mobile">
          مراحل ساخت کسب‌وکار تکمیل نشده
        </p>
        <span class="font_36 subtitle-font-size-mobile">
          برای فعال شدن کسب‌وکار باید مدارک رو بارگذاری کنی!
        </span>
      </template>
      <v-btn
        outlined
        width="120"
        :ripple="false"
        height="46"
        class="mt-4"
        @click="goToBusiness"
      >
        متوجه شدم
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "OnboardingResult",
  computed: {
    ...mapState("newbusiness", [
      "isOnboardingThirdStepDone",
      "isOnboardingFourthStepDone",
    ]),
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  methods: {
    goToBusiness() {
      if (this.$store.state.newbusiness.payementRequire) {
        window.location =
          this.$store.state.pay_base_url +
          "/business/" +
          this.$store.state.newbusiness.business_name +
          "/payment/request"
      } else {
        this.$router.push({
          name: "businessHome",
          params: {
            name: this.$store.state.newbusiness.business_name,
            isFromCreatePage: "yes",
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  position: relative;
}

.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.success-circle {
  background: $van_green;
}

.void-circle {
  border: 2px solid;
}

.position-relative {
  position: relative;
}

.titles-general-styles {
  position: absolute;
  top: -30px;
  min-width: 100px;
}

.first-block-style {
  right: -8px;
}

.second-block-style {
  right: -20px;
}

.third-block-style {
  right: -25px;
}

.fourth-block-style {
  right: -35px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .progress-bar-wrapper {
    padding: 0 40px;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .fourth-block-style {
    right: -46px;
  }

  .progress-bar-wrapper {
    padding: 0 40px;
  }
}

@media screen and (max-width: 600px) {
  .stepper-title-font-size-mobile {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .title-font-size-mobile {
    font-size: 18px;
  }
  .subtitle-font-size-mobile {
    font-size: 12px;
  }
}
</style>
