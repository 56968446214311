<template>
  <v-card class="headerBox" outlined>
    <v-toolbar class="fixed_toolbar" flat height="60">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title v-if="type === 'edit'" class="pr-0">
        ویرایش پرداخت با وندار
      </v-toolbar-title>
      <v-toolbar-title v-else class="pr-0"> پرداخت با وندار </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          v-if="type === 'edit'"
          :disabled="!disable"
          :loading="loading"
          class="btn_small_normal"
          dark
          text
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
        <v-btn
          v-else
          :disabled="!disable"
          :loading="loading"
          class="btn_small_normal"
          dark
          text
          @click="sendForm()"
        >
          ساخت اپلیکیشن
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-card flat outlined>
          <v-layout>
            <v-flex>
              <v-form ref="form" v-model="valid">
                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  align-start
                  justify-space-between
                >
                  <v-flex class="label" style="width: 110px">
                    آدرس وب‌سایت
                    <span>*</span>
                  </v-flex>
                  <v-flex class="withlabel big comboboxSmallchip">
                    <v-combobox
                      v-model="domains"
                      :rules="domainsRule"
                      deletable-chips
                      dir="ltr"
                      flat
                      hide-selected
                      hint="بعد از وارد کردن هر آدرس، دکمه «اینتر» را بزنید. آدرس را با http یا https وارد کنید."
                      multiple
                      outlined
                      small-chips
                      solo
                    >
                      <template #message="message">
                        <div v-html="message.message" />
                      </template>
                    </v-combobox>
                  </v-flex>
                </v-layout>

                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  align-start
                  justify-space-between
                >
                  <v-flex class="label">
                    آی‌ پی‌های شما
                    <span>*</span>
                  </v-flex>
                  <v-flex class="withlabel big comboboxSmallchip">
                    <v-combobox
                      v-model="ips"
                      :rules="ipsRule"
                      deletable-chips
                      dir="ltr"
                      flat
                      hide-selected
                      hint="بعد از وارد کردن هر آی‌‌پی، دکمه «اینتر» را بزنید"
                      multiple
                      outlined
                      small-chips
                      solo
                      @keypress="checkIps($event)"
                    >
                      <template #message="message">
                        <div v-html="message.message" />
                      </template>
                    </v-combobox>
                  </v-flex>
                </v-layout>

                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  align-start
                  justify-start
                >
                  <v-flex class="label" style="max-width: 118px">
                    کارمزد تراکنش
                  </v-flex>
                  <v-flex class="withlabel">
                    <v-select
                      v-model="wage_type"
                      :items="selectItems"
                      flat
                      height="40"
                      item-text="title"
                      item-value="value"
                      outlined
                      solo
                    />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "Add",
  props: ["type"],
  data: function () {
    return {
      valid: false,
      loading: false,
      pbv: {},
      domains: [],
      ips: [],
      wage_type: "APPLICATION_SELF",
      selectItems: [
        {
          title: "از خودم کسر شود",
          value: "APPLICATION_SELF",
        },
        {
          title: "از پرداخت‌کننده دریافت شود",
          value: "APPLICATION_USER",
        },
      ],
      panel: [false],
      domainsRule: [
        (v) => !!v || "<span>آدرس وب‌سایت الزامی است</span>",
        (v) =>
          (!!v && this.checkFormat(v, this.domainsPattern)) ||
          "<span> آدرس وب‌سایت به درستی وارد نشده است</span>",
      ],
      ipsRule: [
        (v) => !!v || "<span> وارد کردن آی‌ پی‌ الزامی است</span>",
        (v) =>
          (!!v && this.checkFormat(v, this.ipValidationPattern)) ||
          "<span> فرمت صحیح آی‌ پی‌ را وارد کنید</span>",
      ],
      domainsPattern: new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ),
      ipValidationPattern:
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    }
  },
  computed: {
    disable() {
      if (
        !this.loading &&
        this.valid &&
        this.ips &&
        this.ips.length > 0 &&
        this.domains &&
        this.domains.length > 0
      )
        return true
      else return false
    },
  },
  methods: {
    checkFormat(items, selectedPattern) {
      let doesAllItemsMatch = true

      for (let element of items) {
        if (element.includes("localhost")) continue
        //convert farsi digits to english
        element = this.convertNumbers2English(element)
        if (selectedPattern && !selectedPattern.test(element)) {
          // if item does not match the pattern return false
          doesAllItemsMatch = false
          return false
        }
      }

      return doesAllItemsMatch
    },
    checkIps(event) {
      if (
        !/(^[0-9۰-۹٫]*$)/.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "."
      ) {
        event.preventDefault()
      }
    },
    sendForm() {
      this.loading = true
      const data = {
        wage_type: this.wage_type,
        domains: this.domains,
        ips: this.ips,
      }
      this.$http
        .post(
          this.$store.state.api_pbv_url +
            this.$store.getters.urlParam +
            "/application/store",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.pbv = response.data.application
          const newBusiness = this.$store.state.business
          newBusiness.tools.PBV.is_created = true
          this.$store.commit("setBusiness", newBusiness)
          this.$store.commit(
            "callSuccessSnackbar",
            "ابزار پرداخت با وندار با موفقیت فعال شد."
          )
          this.$emit("closeModal")
          this.loading = false
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
    //},
  },
}
</script>
