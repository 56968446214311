<template>
  <div>
    <v-list class="menu_v_list">
      <v-list-item to="/">
        <v-list-item-action>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            class="svgs"
          >
            <path
              d="M15.444,3A1.55,1.55,0,0,1,17,4.556V15.444A1.55,1.55,0,0,1,15.444,17H10.778V3M9.222,3V9.222H3V4.556A1.55,1.55,0,0,1,4.556,3m4.667,7.778V17H4.556A1.55,1.55,0,0,1,3,15.444V10.778"
              transform="translate(-3 -3)"
              class="a"
            />
          </svg>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>همه کسب‌و‌کارها</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="userSettingstatus = true">
        <v-list-item-action>
          <svg
            class="svgs"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              class="a"
              d="M3,15.444v1.778H8.333V15.444H3M3,4.778V6.556h8.889V4.778H3M11.889,19V17.222H19V15.444H11.889V13.667H10.111V19h1.778M6.556,8.333v1.778H3v1.778H6.556v1.778H8.333V8.333H6.556M19,11.889V10.111H10.111v1.778H19M13.667,8.333h1.778V6.556H19V4.778H15.444V3H13.667Z"
              transform="translate(19 -3) rotate(90)"
            />
          </svg>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>تنظیمات کلی حساب</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="logUserOut()">
        <v-list-item-action>
          <svg
            class="svgs"
            xmlns="http://www.w3.org/2000/svg"
            width="14.118"
            height="15"
            viewBox="0 0 14.118 15"
          >
            <path
              class="a"
              d="M15.082,5.153,13.8,6.432a5.282,5.282,0,1,1-5.5-.009L7.035,5.153a7.059,7.059,0,1,0,8.047,0M11.941,3H10.176v8.824h1.765"
              transform="translate(-4 -3)"
            />
          </svg>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ logout.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- modals -->
    <v-dialog
      v-model="userSettingstatus"
      :content-class="
        $store.state.secondUserSetting ? 'secondModal vanmodal' : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <userSetting v-if="userSettingstatus" @closeModal="close_modal()" />
    </v-dialog>
  </div>
</template>

<script>
import userSetting from "../modals/userSetting"
export default {
  name: "NavigationDrawer",
  components: {
    userSetting,
  },
  data() {
    return {
      logout: { title: "خروج", icon: "power_settings_new", to: "/logout" },
      userSettingstatus: false,
    }
  },
  methods: {
    close_modal() {
      this.userSettingstatus = false
    },
    logUserOut() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          this.$router.push("/app-entries")
        })
        .catch((error) => {
          this.$router.push("/app-entries")
          //callsnackbar
        })
    },
  },
}
</script>
