<template>
  <div class="navigation">
    <div class="dashboardNavigation">
      <vMenu />
    </div>
  </div>
</template>

<script>
import vMenu from "./vMenu.vue"
export default {
  name: "DashboardNavigationDrawer",
  components: {
    vMenu,
  },
}
</script>
