<template>
  <v-card>
    <!--    skeleton-->
    <v-card v-if="!dailyReportArr" class="multi-box">
      <v-toolbar flat height="68" class="fixed_toolbar">
        <v-layout>
          <v-flex class="label mr-5">
            <span class="default_gray ma-1" />
          </v-flex>
        </v-layout>
      </v-toolbar>
      <v-layout class="modal-container">
        <v-card height="128" class="default_gray w-100 ma-1 mb-8" flat />
        <v-card height="64" class="default_gray w-100 ma-1 mb-4" flat />
        <v-card height="64" class="default_gray w-100 ma-1 mb-4" flat />
        <v-card height="64" class="default_gray w-100 ma-1 mb-4" flat />
        <v-card height="64" class="default_gray w-100 ma-1 mb-4" flat />
        <v-card height="64" class="default_gray w-100 ma-1 mb-4" flat />
        <v-card height="64" class="default_gray w-100 ma-1 mb-4" flat />
        <v-card height="64" class="default_gray w-100 ma-1 mb-4" flat />
        <v-card height="64" class="default_gray w-100 ma-1 mb-4" flat />
      </v-layout>
    </v-card>
    <template v-else>
      <v-toolbar flat height="68" class="fixed_toolbar">
        <v-btn class="mr-4" icon @click.native="$emit('closeModal')">
          <v-icon color="van_color03" size="21"> close </v-icon>
        </v-btn>
        <v-toolbar-title class="pr-0">جزییات تراکنش روزانه</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items class="align-center">
          <v-btn
            ref="btn"
            text
            dark
            class="btn_small_normal"
            :disabled="dailyReportArr.length > 0"
            @click="generateExcel(dailyReportArr)"
          >
            دریافت
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- container -->
      <v-layout class="modal-container">
        <!-- first box -->
        <div class="main-card-container mb-4">
          <v-card class="main-card" flat>
            <div class="font_2 des">
              جزییات تراکنش روزانه به دلیل محدودیت‌های زیرساخت‌های بانکی ممکن
              است تا ۶ روز به‌روزرسانی شود.
            </div>
          </v-card>
        </div>

        <div
          v-for="(dayItem, dayIndex) in dailyReportArr"
          :key="dayIndex"
          class="main-card-container mb-4"
        >
          <!-- if there is no transaction for the day -->
          <v-card
            v-if="
              dayItem.hasOwnProperty('total') &&
              noTransactionExist(dayItem.total)
            "
            class="d-flex main-card py-5 align-center"
            flat
          >
            <div class="day-title text-right font_22 van_color04--text">
              {{ persianDateText(dayIndex.replaceAll("-", "")) }}
            </div>
            <div
              v-if="today === dayIndex.replaceAll('-', '')"
              class="van_color03--text mr-3"
            >
              <v-icon color="van_color03" size="20">error_outline</v-icon>
              جزییات تراکنش روز جاری، فردا برای شما قابل مشاهده و دریافت است.
            </div>
            <div v-else class="van_color04 status-btn mr-auto">بدون تراکنش</div>
          </v-card>

          <!-- else, show transactions for the day -->
          <v-card v-else class="main-card py-5" flat>
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="pa-0">
                  <div class="text-right font_22 van_color02--text">
                    {{ persianDateText(dayIndex.replaceAll("-", "")) }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="(tool, toolKey) in dayItem" :key="toolKey">
                    <!-- for each tool which is not zero, make a box in accordion  -->
                    <v-card
                      v-if="
                        dayItem.hasOwnProperty(toolKey) &&
                        !noTransactionExist(dayItem[toolKey])
                      "
                      class="mt-5"
                      flat
                    >
                      <div>
                        <v-toolbar
                          :color="
                            toolKey === 'total' ? '#4c9f871a' : 'van_color11'
                          "
                          flat
                          height="56"
                        >
                          <v-toolbar-title
                            class="pr-0 d-flex van_color03--text font_10"
                          >
                            <img
                              alt="tool icon"
                              width="28"
                              height="28"
                              :src="getIcon(toolKey)"
                              class="ml-2"
                            />
                            <span class="van_color03--text">
                              {{ getToolsInfo(toolKey).title }}
                            </span>
                          </v-toolbar-title>
                        </v-toolbar>
                        <!-- make a row forEach amount in the tools row -->
                        <div class="pa-5 pt-2">
                          <div
                            v-for="(toolRow, toolRowKey) in Object.keys(tool)"
                            :key="toolRowKey"
                            class="daily-boxs"
                          >
                            <div
                              v-if="handleVisibleFileds(toolRow, toolKey)"
                              style="display: flex"
                              :class="[
                                'py-2 ',
                                $vuetify.breakpoint.xsOnly
                                  ? 'flex-column align-start'
                                  : 'flex-row align-center',
                              ]"
                            >
                              <div
                                :class="[
                                  'text-right font_10 pl-2 rowTitles',
                                  $vuetify.breakpoint.xsOnly ? 'mb-1' : '',
                                ]"
                              >
                                {{ getToolRowText(toolRow) }}
                                <span
                                  v-if="
                                    toolKey === 'settlement' &&
                                    toolRow === 'total_amount'
                                  "
                                >
                                  (برداشت برگشتی)
                                  <v-tooltip bottom>
                                    <template #activator="{ on }">
                                      <v-icon
                                        size="20"
                                        style="direction: rtl; width: 20px"
                                        v-on="on"
                                        >mdi_info_outline
                                      </v-icon>
                                    </template>
                                    <span>
                                      واریزهایی که برای بازگرداندن مبالغ «برداشت
                                      برگشتی» انجام شده‌اند.
                                    </span>
                                  </v-tooltip>
                                </span>
                              </div>
                              <v-divider
                                dashed
                                :class="[
                                  'connection-dots',
                                  $vuetify.breakpoint.xsOnly
                                    ? 'w-100 mt-1'
                                    : null,
                                ]"
                                :style="
                                  $vuetify.breakpoint.xsOnly ? 'order:3;' : null
                                "
                              />
                              <div
                                :class="[
                                  'd-flex font_10',
                                  $vuetify.breakpoint.xsOnly ? 'mb-1' : null,
                                ]"
                              >
                                <span
                                  :class="[
                                    '',
                                    !$vuetify.breakpoint.xsOnly ? 'pr-2' : null,
                                  ]"
                                />
                                <template v-if="toolRow !== 'count'">
                                  {{
                                    (parseFloat(tool[toolRow]) / 10) | currency
                                  }}
                                  <span v-if="toolRow !== 'count'" class="pr-1">
                                    {{ currencyName }}
                                  </span>
                                </template>
                                <template v-else>
                                  {{ tool[toolRow] }}
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <div
                    v-if="toolsWidthNoTransactionHandler(dayItem).length > 0"
                    class="van_color11 infoBox mt-6 mb-4 px-5 py-3 w-100"
                  >
                    <v-layout justify-center wrap>
                      <div class="text-center">
                        امروز تراکنش
                        {{ toolsWidthNoTransactionHandler(dayItem) }} ثبت نشده
                        است.
                      </div>
                    </v-layout>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </div>
      </v-layout>
    </template>
  </v-card>
</template>
<script>
import { dateTimeHanddler } from "@/mixins/dateTimeHanddler.js"
import { accountantDailyReport } from "@/mixins/accountantDailyReport.js"
import jmoment from "moment-jalaali"

export default {
  name: "DailyReport",
  mixins: [dateTimeHanddler, accountantDailyReport],
  props: ["month", "year"],
  data() {
    return {
      toolsWidthNoTransaction: [],
      dailyReportArr: null,
      today: "",
      settlementList: [
        "p2p",
        "settlement",
        "refund",
        "reconciliation",
        "total",
      ],
      transactionList: [
        "ipg",
        "p2p",
        "settlement",
        "subscription",
        "cash_in",
        "request_money",
        "pos",
        "reconciliation",
        "total",
      ],
    }
  },
  computed: {},
  mounted() {
    this.fillData()
    let today = new Date()
    this.today = jmoment(today).format("jYYYYjMMjDD")
  },
  methods: {
    fillData() {
      this.getData(this.month, this.year).then((response) => {
        this.dailyReportArr = response
      })
    },
    handleVisibleFileds(toolRow, toolKey) {
      // check if this filed should be displayed for this tool
      return (
        !["transaction_amount", "settlement_amount"].includes(toolRow) ||
        (toolRow === "transaction_amount" &&
          this.transactionList.includes(toolKey)) ||
        (toolRow === "settlement_amount" &&
          this.settlementList.includes(toolKey))
      )
    },
    toolsWidthNoTransactionHandler(obj) {
      // get array of tools with no transaction for this day
      var arr = []

      Object.values(obj).forEach((element, index) => {
        if (
          this.noTransactionExist(element) &&
          !["reconciliation", "settlement"].includes(Object.keys(obj)[index])
        ) {
          arr.push("«" + this.getToolsInfo(Object.keys(obj)[index]).title + "»")
        }
      })
      if (arr.length === 0) return arr
      if (arr.length === 1) return arr[0]
      return arr.slice(0, -1).join("، ") + " و " + arr.slice(-1)
    },

    noTransactionExist(obj) {
      return Object.values(obj).every((value) => value === 0)
    },
    getIcon(whichTool) {
      whichTool = whichTool.replaceAll("_", "-")
      if (whichTool === "p2p") whichTool = "p2p_new"
      return require("@/assets/img/newIcon/tools/" + whichTool + ".svg")
    },
  },
}
</script>

<style lang="scss" scoped>
.icons {
  margin-left: 12px;
  max-width: 40px;
}

.v-expansion-panel {
  box-shadow: none !important;

  &-header {
    min-height: unset !important;
  }
}

.details-box {
  margin-top: 20px;
  padding: 0 !important;
}
.v-expansion-panel:deep(.v-expansion-panel__header) {
  padding: 0 !important;
  min-height: unset;
}
.main-card-container {
  .v-toolbar {
    border-radius: 4px 4px 0 0;
    border: none !important;
  }
}
.connection-dots {
  border-style: dashed;
}

:deep(.v-toolbar__content) {
  padding: 0 20px !important;
}

.day-title {
  min-width: fit-content;
  font-family: IRANSans !important;

  + div {
    font-size: 11px;
  }
}

@media screen and (max-width: 599px) {
  .daily-boxs {
    &:last-child {
      .connection-dots {
        display: none;
      }
    }
  }
}
.rowTitles {
  min-width: fit-content;
}
</style>
