<template>
  <v-layout :id="`bank-${bank.id}`" align-center>
    <div class="image-box ml-2">
      <v-img
        :lazy-src="bank.logo"
        :src="bank.logo"
        contain
        height="32px"
        width="32px"
      ></v-img>
    </div>
    <v-flex>{{ bank.name }}</v-flex>
    <v-flex style="max-width: 80px; position: relative">
      <v-btn
        v-if="bank.status === 'DEACTIVATED'"
        :loading="loading"
        class="btn_small_normal full-width"
        color="#4C9F87"
        depressed
        text
        @click="activate(bank)"
      >
        <span class="text--white" style="color: #fff !important">نمایش</span>
      </v-btn>
      <v-btn
        v-else
        :loading="loading"
        class="btn_small_normal full-width"
        depressed
        text
        outlined
        @click="showDeactivateModal(bank)"
      >
        <span>عدم نمایش</span>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "BankManagementItem",
  props: ["bank"],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    activate(bank) {
      this.loading = true
      this.$http
        .put(`${this.$store.getters.urlMPG}/payment-methods/${bank.slug}`, {
          status: "ACTIVE",
        })
        .then((response) => {
          this.$store.commit(
            "callSuccessSnackbar",
            `${bank.name} برای کاربران شما نمایش داده می‌شود.`
          )
          bank.status = "ACTIVE"
        })
        .catch((error) => {
          //callsnackbar
        })
        .finally(() => {
          this.loading = false
        })
    },
    showDeactivateModal(bank) {
      this.$emit("showDeactivateModal", bank)
    },
  },
}
</script>

<style scoped>
.full-width {
  width: 100% !important;
}
.image-box {
  border: 1px solid #eeeeee;
  padding: 9px;
}
</style>
