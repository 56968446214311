<template>
  <TicketsLeftModal
    :toolbar-name="ticket.title"
    @closeModal="$emit('closeModal')"
  >
    <div slot="toolbarItems">
      <v-btn
        class="btn_small_normal"
        style="margin-left: 0"
        dark
        text
        @click="createNewMessage"
      >
        {{ isClosed ? "باز کردن مجدد تیکت" : "پیام جدید" }}
      </v-btn>
    </div>
    <span v-if="!ticket.protested_at">
      <div v-if="!isThereComplaint" class="complaint-wrapper complaint-box">
        <span class="font_30 complaint-wrapper__text">
          اگر از وضعیت پاسخگویی رضایت کامل ندارید، به ما اطلاع دهید.
        </span>
        <v-btn outlined class="btn_small_normal" dark text @click="doComplaint">
          رضایت ندارم
        </v-btn>
      </div>
      <div v-else class="complaint-success-sending-info font_2 complaint-box">
        درخواست شما به واحد «موفقیت مشتریان» ارسال شد. به زودی با شما تماس
        خواهیم گرفت.
      </div>
    </span>
    <v-card
      class="status-card elevation-0"
      :style="{
        backgroundColor: returnDynamicContent(ticket.status)
          .cardBackgroundColor,
      }"
    >
      <div
        class="svg-wrapper"
        :style="{
          backgroundColor: returnDynamicContent(ticket.status)
            .iconBackgroundColor,
        }"
      >
        <component :is="returnDynamicContent(ticket.status).componentName" />
      </div>
      <div class="status-card__status">
        <span class="status-card__bold-title">
          {{ returnDynamicContent(ticket.status)?.persianStatus }}
        </span>
        <div class="detail-button-wrapper" @click="openTicketDetail">
          <span class="detail-button">جزئیات</span>
          <span class="chevron-wrapper">
            <ChevronUpSvg v-if="isOpenTicketDetail" />
            <ChevronDownSvg v-else />
          </span>
        </div>
      </div>
    </v-card>

    <ul v-show="isOpenTicketDetail" class="ticket-details">
      <li class="grid-row">
        <span class="item-label">دسته‌بندی</span>
        <div class="item-value">
          <span>
            {{ ticket.category?.parent?.title }}
          </span>
          <span v-if="ticket.category?.parent?.title && ticket.category?.title">
            /
          </span>
          <span> {{ ticket.category?.title }} </span>
        </div>
      </li>
      <li class="grid-row">
        <span class="item-label">شناسه</span>
        <span class="item-value">{{ ticket.id }}</span>
      </li>
      <li class="grid-row">
        <span class="item-label">ایجاد کننده</span>
        <span class="item-value">
          {{ ticket.user.name }}
          <span v-if="ticket.user.role"> / {{ ticket.user.role }}</span>
        </span>
      </li>
      <li class="grid-row">
        <span class="item-label">زمان ایجاد</span>
        <span class="item-value">
          {{ jalaliDate(ticket.created_at, "jYYYY/jM/jD - HH:mm") }}
        </span>
      </li>
      <li class="grid-row">
        <span class="item-label">به‌روزرسانی</span>
        <span class="item-value">
          {{ jalaliDate(ticket.updated_at, "jYYYY/jM/jD - HH:mm") }}
        </span>
      </li>
      <li v-if="ticket.protested_at" class="grid-row">
        <span class="item-label">اعلام نارضایتی</span>
        <span class="item-value">
          {{ jalaliDate(ticket.protested_at, "jYYYY/jM/jD - HH:mm") }}
        </span>
      </li>
    </ul>

    <div class="messages-wrapper">
      <div
        v-for="message in ticketMessageData.data"
        :key="message.id"
        class="message-card-wrapper"
        :class="{ 'message-card-wrapper-agent': message.agent?.name }"
      >
        <span
          v-if="message.agent?.name"
          class="avatar-agent"
          :class="{ 'mr-2': !isMobile, 'mr-1': isMobile }"
        >
          <v-img :src="require('@/assets/img/vandar-avatar.svg')"></v-img>
        </span>
        <span
          v-else
          class="avatar"
          :class="{ 'ml-2': !isMobile, 'ml-1': isMobile }"
        >
          <v-img
            v-if="message.user?.image"
            width="32"
            aspect-ratio="1"
            style="border-radius: 50%"
            :src="message.user?.image"
          ></v-img>
          <v-img
            v-else-if="business.avatar"
            width="32"
            aspect-ratio="1"
            style="border-radius: 50%"
            :src="$store.state.storage_base_url + business.avatar"
          >
            <template #placeholder>
              <div
                style="
                  height: 32px;
                  width: 32px;
                  background-color: #f5f5f5;
                  border-radius: 50%;
                "
              ></div>
            </template>
          </v-img>
        </span>

        <div
          class="card-column"
          :class="{ 'card-column-agent': message.agent?.name }"
        >
          <v-card
            class="card-column__card card elevation-0 rounded"
            :class="{ 'card-column__card-agent': message.agent?.name }"
          >
            <p v-if="message.agent" class="card__title">
              {{ message.agent?.name }} :
            </p>
            <p v-else class="card__title">
              {{ message.user.name }}
              <span v-if="message.user.role">| {{ message.user.role }}</span> :
            </p>
            <p class="card__message">
              {{ message.message }}
            </p>
            <div
              v-if="message.attachments.length > 0"
              class="uploaded-files-wrapper"
            >
              <span v-for="url in message.attachments" :key="url">
                <a :href="url" target="_blank">
                  <v-img
                    max-width="46px"
                    max-height="46px"
                    width="46px"
                    aspect-ratio="1"
                    :src="getIcon(url)"
                    class="ml-2 rounded-lg overflow-hidden mb-1"
                  ></v-img>
                </a>
              </span>
            </div>
          </v-card>
          <span
            class="card-column__message-date"
            :class="{ 'card-column__message-date-agent': message.agent?.name }"
          >
            {{ jalaliDate(message.created_at, "jYYYY/jM/jD - HH:mm") }}
          </span>
        </div>
      </div>
    </div>
  </TicketsLeftModal>
</template>

<script>
import TicketsLeftModal from "@/components/tickets/components/TicketsLeftModal.vue"
import MessageSvg from "@/components/tickets/components/TextMessageSvg.vue"
import PendingSvg from "@/components/tickets/components/HourGlassSvg.vue"
import DoneSvg from "@/components/tickets/components/DoneSvg.vue"
import InprogressSvg from "@/components/tickets/components/InprogressSvg.vue"
import AvatarSvg from "@/components/tickets/components/AvatarSvg.vue"
import VandarAvatarSvg from "@/components/tickets/components/VandarAvatarSvg.vue"
import { returnDynamicContent } from "./utils/generalMethods"
import ChevronUpSvg from "@/components/tickets/components/ChevronUpSvg.vue"
import ChevronDownSvg from "@/components/tickets/components/ChevronDownSvg.vue"

export default {
  name: "TicketModal",
  components: {
    TicketsLeftModal,
    MessageSvg,
    PendingSvg,
    DoneSvg,
    InprogressSvg,
    AvatarSvg,
    VandarAvatarSvg,
    ChevronUpSvg,
    ChevronDownSvg,
  },
  props: {
    ticket: {
      type: Object,
      default: () => {},
    },
    ticketsListUrl: {
      type: String,
      default: "",
    },
    ticketModalIsOpen: {
      type: Boolean,
      default: false,
    },
    shouldGetMessages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      returnDynamicContent,
      ticketMessageData: {},
      messageUrl: this.ticketsListUrl + "/" + this.ticket.id + "/messages",
      reopenUrl: this.ticketsListUrl + "/" + this.ticket.id + "/reopen",
      protestUrl: this.ticketsListUrl + "/" + this.ticket.id + "/protest",
      isOpenTicketDetail: false,
      fileIcons: {
        pdf: require("@/assets/img/file-uploader/pdf.svg"),
        excel: require("@/assets/img/file-uploader/excel.png"),
      },
      isThereComplaint: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    business() {
      return this.$store.state.business
    },
    isClosed() {
      return this.ticket.status === "closed"
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  watch: {
    ticketModalIsOpen: {
      handler(val) {
        if (val) {
          this.getMessage()
        }
      },
      immediate: true,
    },
    shouldGetMessages(val) {
      if (val) {
        this.getMessage()
      }
    },
  },
  methods: {
    getIcon(url) {
      switch (this.getUrlExtension(url)) {
        case "pdf":
          return this.fileIcons["pdf"]
        case "xlsx":
        case "xlsm":
        case "xlsb":
        case "xltx":
        case "xltm":
        case "xls":
        case "xml":
        case "csv":
        case "xla":
        case "xlw":
          return this.fileIcons["excel"]
        default:
          return url
      }
    },

    async createNewMessage() {
      if (this.isClosed) {
        await this.$http.put(this.reopenUrl)
        this.$emit("reopen-ticket")
      }
      this.$emit("showNewMessageModal", this.messageUrl)
    },
    async getMessage() {
      const res = await this.$http.get(this.messageUrl)
      this.ticketMessageData = { ...res.data }
    },
    openTicketDetail() {
      this.isOpenTicketDetail = !this.isOpenTicketDetail
    },
    getUrlExtension(url) {
      return url?.split(/[#?]/)[0].split(".").pop().trim()
    },
    async doComplaint() {
      await this.$http.post(this.protestUrl)
      this.$emit("get-tickets-again")
      this.isThereComplaint = true
    },
  },
}
</script>

<style lang="scss" scoped>
.complaint-box {
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;
}

.complaint-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: thin solid #d5d5d5;
}

.complaint-success-sending-info {
  background: #eaf2f0;
}

.ticket-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 32px 30px;
  list-style-type: none;
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

li {
  border-bottom: 1px dashed #d5d5d5;
  height: 40px;
  box-sizing: content-box;
}

li:last-child {
  border-bottom: none;
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
}

.item-label {
  font-size: 11px;
  line-height: 17px;
  color: #999999;
  grid-column: 1;
}

.item-value {
  font-size: 12px;
  line-height: 24px;
  color: #666666;
  grid-column: 2/5;
}
.detail-button-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chevron-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.detail-button {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 11px;
  line-height: 18px;
}
.status-card {
  height: 72px;
  display: flex;
  align-items: center;
  padding: 16px 30px;

  &__status {
    flex-basis: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__create {
    border-left: none;
  }

  &__bold-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    color: #333333;
  }

  &__title {
    color: #999999;
    font-size: 11px;
    line-height: 17px;
  }

  &__subtitle {
    color: #666666;
    font-size: 11px;
    line-height: 17px;
    margin-top: 4px;
  }
}

.svg-wrapper {
  flex-basis: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin-left: 16px;
  border-radius: 10px 10px 0px 10px !important;
}

.messages-wrapper {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  padding: 30px;
  margin-top: 32px;
}

.message-card-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 10px;
}

.avatar {
  grid-column: 1;
  justify-self: flex-start;
}

.avatar-agent {
  grid-column: 12;
  justify-self: flex-end;
}
.card-column {
  grid-column-start: 2;
  grid-column-end: 11;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  max-width: 360px;

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    background: #f5f5f5 !important;
    border-radius: 4px;
  }

  &__card-agent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    background: #4c9f871a !important;
    border-radius: 4px;
  }

  &__message-date {
    color: #666666;
    font-size: 11px;
    line-height: 17px;
    margin-top: 4px;
  }

  &__message-date-agent {
    display: flex;
    justify-content: flex-end;
  }
}

.card-column-agent {
  grid-column-start: 12;
  grid-column-end: 3;
  grid-row: 1;
}
.card {
  &__title {
    color: #666666;
    font-size: 11px;
    line-height: 17px;
  }

  &__message {
    color: #333333;
    font-size: 12px;
    line-height: 24px;
  }
}

.uploaded-files-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4px;
}

@media screen and (max-width: 600px) {
  .ticket-details {
    padding: 30px;
  }

  li {
    height: 32px;
  }

  .status-card {
    &__status {
      flex-direction: column;
      align-items: flex-start;
    }

    &__update {
      margin: 0;
      padding: 4px 0px;
    }

    &__bold-title {
      padding: 4px 0px;
    }
  }
  .messages-wrapper {
    padding: 16px;
    margin-top: 16px;
  }

  .complaint-wrapper {
    flex-direction: column;

    &__text {
      text-align: center;
    }
  }

  .complaint-success-sending-info {
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
  .card-column-agent {
    grid-column-start: 12;
    grid-column-end: 2;
    grid-row: 1;
  }

  .card-column {
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row: 1;
  }
}
</style>
