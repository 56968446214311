import overViewApiServices from "./overViewApiServices"
import directDebitApiServices from "./directDebitApiServices"
import ipgApiServices from "./ipgApiServices"
import paymentFormApiServices from "./paymentFormApiServices"
import settlementApiServices from "./settlementApiServices"

export default ($http, context) => ({
  overView: overViewApiServices($http, context),
  directDebit: directDebitApiServices($http, context),
  ipg: ipgApiServices($http, context),
  paymentForm: paymentFormApiServices($http, context),
  settlement: settlementApiServices($http, context),
})
