<template>
  <div>
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      outlined
      class="business"
      color="white"
      flat
    >
      <!-- INIT / ACTIVE / REJECT / INACTIVE -->
      <div class="titles">
        <div
          v-if="$store.state.subscription && $store.state.subscription.urls"
          class="income"
          style="width: 332px"
        >
          <h2 style="display: inline">
            {{ $store.state.subscription.urls[0] }}
          </h2>
          <v-chip
            v-if="$store.state.business.status == 0"
            small
            class="ma-2"
            color="van_red"
            text-color="white"
          >
            در انتظار تایید حساب
          </v-chip>
          <v-chip
            v-if="
              $store.state.business.status == 1 &&
              $store.state.subscription.status == 'INIT'
            "
            small
            class="ma-2"
            color="#F9D358"
            text-color="white"
          >
            در حال بررسی و تایید
          </v-chip>
          <v-chip
            v-if="
              $store.state.business.status == 1 &&
              $store.state.subscription.status == 'ACTIVE'
            "
            small
            class="ma-2"
            color="van_green"
            text-color="white"
          >
            فعال
          </v-chip>
        </div>
        <div class="income">
          شناسه:
          {{ $store.state.business.business_name }}
        </div>
      </div>
      <div
        v-if="
          $store.state.business.status == 1 &&
          $store.state.subscription.status == 'ACTIVE'
        "
        class="more-data"
      >
        <div class="bus bus-right">
          <div v-if="$store.state.business.role != 'developer'" class="mablagh">
            {{
              $store.state.subscription.successful_withdrawals_count
                | thousandSeprator
            }}
          </div>
          <div v-else class="mablagh">-</div>
          <div class="sub-text">تراکنش</div>
        </div>
        <div class="bus bus-right">
          <div
            v-if="$store.state.business.role != 'developer'"
            dir="ltr"
            class="mablagh"
          >
            {{ $store.state.subscription.transactions_sum | currency }}
          </div>
          <div v-else class="mablagh">-</div>
          <div class="sub-text">{{ currencyName }} تراکنش</div>
        </div>
        <div class="bus bus-left">
          <div v-if="$store.state.business.role != 'developer'" class="mablagh">
            {{ $store.state.subscription.customers_count | thousandSeprator }}
          </div>
          <div v-else class="mablagh">-</div>
          <div class="sub-text">نفر مشتری</div>
        </div>
      </div>
      <v-layout column class="botton">
        <v-flex>
          <v-btn
            v-if="$store.state.subscription.status == 'INIT'"
            text
            depressed
            outlined
            class="btn_small_normal"
            @click="pushToDoc()"
          >
            مستندات
          </v-btn>
          <v-btn
            text
            depressed
            outlined
            class="btn_small_normal"
            @click="$emit('view_form')"
          >
            جزییات
          </v-btn>
        </v-flex>
        <v-flex
          v-if="
            $store.state.subscription.status == 'ACTIVE' &&
            $store.state.business.role !== 'developer'
          "
        >
          <v-btn
            text
            depressed
            outlined
            class="btn_small_normal"
            :to="{
              name: 'businessReport',
              params: { filtered: 'successfullTransactionSubscription' },
            }"
          >
            تراکنش‌ها
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card
      v-if="!$vuetify.breakpoint.mdAndUp"
      outlined
      class="business-md-down"
      color="white"
      flat
    >
      <v-layout wrap justify-space-between>
        <v-flex>
          <div class="titles">
            <div
              v-if="$store.state.subscription && $store.state.subscription.urls"
              class="income"
              style="width: 332px"
            >
              <h2 style="display: inline">
                {{ $store.state.subscription.urls[0] }}
              </h2>
              <v-chip
                v-if="$store.state.business.status == 0"
                small
                class="ma-2"
                color="van_red"
                text-color="white"
              >
                در انتظار تایید حساب
              </v-chip>
              <v-chip
                v-if="
                  $store.state.business.status == 1 &&
                  $store.state.subscription.status == 'INIT'
                "
                small
                class="ma-2"
                color="#F9D358"
                text-color="white"
              >
                در حال بررسی و تایید
              </v-chip>
              <v-chip
                v-if="
                  $store.state.business.status == 1 &&
                  $store.state.subscription.status == 'ACTIVE'
                "
                small
                class="ma-2"
                color="van_green"
                text-color="white"
              >
                فعال
              </v-chip>
            </div>
            <div class="income">
              شناسه:
              {{ $store.state.business.business_name }}
            </div>
          </div>
        </v-flex>
        <v-flex style="max-width: 40px; margin-left: -10px">
          <v-menu
            transition="slide-y-transition"
            offset-y
            close-on-content-click
            bottom
            content-class="up_menu"
          >
            <!-- this on slot sometime is undefined -->
            <template #activator="{ on }">
              <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                <v-icon color="van_color03" size="21" style="line-height: 40px">
                  more_vert
                </v-icon>
              </v-btn>
            </template>

            <v-card outlined class="drop_down_menu">
              <v-list class="menu_v_list">
                <v-list-item
                  v-if="$store.state.subscription.status == 'INIT'"
                  @click="pushToDoc()"
                >
                  <v-list-item-content>
                    <v-list-item-title>مستندات</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$emit('view_form')">
                  <v-list-item-content>
                    <v-list-item-title>جزییات</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="$store.state.business.role !== 'developer'"
                  :to="{
                    name: 'businessReport',
                    params: { filtered: 'successfullTransactionSubscription' },
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title>تراکنش‌ها</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="
          $store.state.business.status == 1 &&
          $store.state.subscription.status == 'ACTIVE'
        "
        class="more-data"
        align-center
        justify-center
        fill-height
      >
        <v-flex>
          <div class="bus bus-right">
            <div
              v-if="$store.state.business.role != 'developer'"
              class="mablagh"
            >
              {{
                $store.state.subscription.successful_withdrawals_count
                  | thousandSeprator
              }}
            </div>
            <div v-else class="mablagh">-</div>
            <div class="sub-text">تراکنش</div>
          </div>
        </v-flex>
        <v-flex>
          <div class="bus bus-right">
            <div
              v-if="$store.state.business.role != 'developer'"
              dir="ltr"
              class="mablagh"
            >
              {{ $store.state.subscription.transactions_sum | currency }}
            </div>
            <div v-else class="mablagh">-</div>
            <div class="sub-text">{{ currencyName }} تراکنش</div>
          </div>
        </v-flex>
        <v-flex>
          <div class="bus bus-left">
            <div
              v-if="$store.state.business.role != 'developer'"
              class="mablagh"
            >
              {{ $store.state.subscription.customers_count | thousandSeprator }}
            </div>
            <div v-else class="mablagh">-</div>
            <div class="sub-text">نفر مشتری</div>
          </div>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Card",
  data() {
    return {
      isHover: false,
      hover: false,
    }
  },
  methods: {
    pushToDoc() {
      var url = "https://vandarpay.github.io/docs/subscription/#directdebit"
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}

.business {
  height: 120px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}
.business-md-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;
  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
  .more-data {
    display: flex;
    //width: 250px;
    margin-top: 16px;
  }
  .bus {
    background-color: $van_color11;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 11px 5px;
    height: 80px;
    width: 100%;
    margin: 5px;
    max-width: 95%;

    &.bus-right,
    &.bus-left {
      width: 95%;
    }

    &.flat {
      background: transparent;
      color: $van_pink;
      font-size: 12px;
      font-weight: bold;
      height: 28px;
    }
  }
}
.v-avatar {
  margin: 0 0 10px 0;
}

.titles {
  max-width: 200px;
}

h2 {
  margin: 0 0 9px 0;
  line-height: 20px;
}

.income {
  font-size: 12px;
  color: #666;
  font-weight: normal;

  h2 {
    font-size: 14px;
  }
}

.bus-right {
  margin-left: 10px;
}

.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;
  max-width: 120px;

  &.bus-right,
  &.bus-left {
    width: 50%;
  }

  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}

.business .more-data {
  display: flex;
  width: 384px;
}

.mablagh {
  font-size: 14px;
  font-weight: bold;
}

.sub-text {
  font-size: 9px;
  color: #999;
}

.layout.botton {
  max-width: 88px;

  .v-btn {
    width: calc(100% - 16px);
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.v-chip--small {
  height: 25px !important;
  margin-top: 5px !important;
}
</style>
