import service from "./service"
import serviceV2 from "./serviceV2"

export default ($http, context) => ({
  httpDirectDebitIncome(chartType) {
    const queryParam = "/stat?code=direct_debit_income_" + chartType
    return service.get($http, context, queryParam)
  },
  httpDirectDebitIncomeCount(chartType) {
    const queryParam = "/stat?code=direct_debit_income_count_" + chartType
    return service.get($http, context, queryParam)
  },
  httpDirectDebitWithdrawalMandate() {
    const queryParam = "/stat?code=direct_debit_withdrawal_mandate"
    return service.get($http, context, queryParam)
  },
  httpDirectDebitWithdrawalMandateApiV2() {
    const queryParam = "/direct-debit/withdrawal_mandate"
    return serviceV2.get($http, context, queryParam)
  },
  httpDirectDebitIncomeFrequencyCount(chartType) {
    const queryParam =
      "/stat?code=direct_debit_income_frequency_count_" + chartType
    return service.get($http, context, queryParam)
  },
  httpDirectDebitUnsuccessfulDepositsAverage() {
    const queryParam = "/stat?code=direct_debit_unsuccessful_deposits_average"
    return service.get($http, context, queryParam)
  },
  httpDirectDebitErrorFrequency(chartType) {
    const queryParam = "/stat?code=direct_debit_error_frequency_" + chartType
    return service.get($http, context, queryParam)
  },
  httpDirectDebitErrorFrequencyV2(item) {
    const queryParam = `/direct-debit/error-frequency?resolution=${item.timePeriod}`
    return serviceV2.get($http, context, queryParam)
  },
  httpDirectDebitCustomerMandate() {
    const queryParam = "/stat?code=direct_debit_customer_mandate"
    return service.get($http, context, queryParam)
  },
  httpDirectDebitCustomerMandateApiV2() {
    const queryParam = "/direct-debit/customer_mandate"
    return serviceV2.get($http, context, queryParam)
  },
  httpDirectDebitMandateChurn(chartType) {
    const queryParam = "/stat?code=direct_debit_mandate_churn_" + chartType
    return service.get($http, context, queryParam)
  },
  httpDirectDebitMandateChurnApiV2(item) {
    const queryParam = `/direct-debit/mandate-churn?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpDirectDebitActiveMandate() {
    const queryParam = "/stat?code=direct_debit_active_mandate"
    return service.get($http, context, queryParam)
  },
  httpDirectDebitCompareFailedLicenseRequests() {
    const queryParam = "/stat?code=direct_debit_compare_failed_license_requests"
    return service.get($http, context, queryParam)
  },
  httpDirectDebitMandatesErrorFrequency(chartType) {
    const queryParam =
      "/stat?code=direct_debit_mandates_error_frequency_" + chartType
    return service.get($http, context, queryParam)
  },
  httpDirectDebitMandatesErrorFrequencyV2(item) {
    const queryParam = `/direct-debit/mandate-error-frequency?resolution=${item.timePeriod}`
    return serviceV2.get($http, context, queryParam)
  },
  httpDirectDebitMandateConversion(duration) {
    const queryParam = `/direct-debit/conversion?duration=${duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpDirectDebitDeposit(item) {
    const queryParam = `/direct-debit/deposit?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpDirectDebitSuccessDeposit(item) {
    const queryParam = `/direct-debit/success-deposit?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpDirectDebitMonthlyRecurringRevenue(item) {
    const queryParam = `/direct-debit/monthly-recurring-revenue?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
  httpDirectDebitMandatesGrowth(item) {
    const queryParam = `/direct-debit/mandates-growth?resolution=${item.timePeriod}&duration=${item.duration}`
    return serviceV2.get($http, context, queryParam)
  },
})
