<template>
  <v-layout column class="authorizationList">
    <!-- skeleton -->
    <v-flex v-if="authorizationLoad == false">
      <v-layout class="shebaList" column>
        <v-flex style="width: 100%">
          <v-layout align-center>
            <v-flex
              class="skeleton_box"
              style="
                max-width: 60px;
                width: 60px;
                height: 63px;
                margin: 0px 0px 0px 10px;
                border: 1px solid #eeeeee;
              "
            />
            <v-flex>
              <div class="font_2 bold" style="max-width: 50%">
                <span class="default_gray" />
              </div>
              <div class="font_2">
                <span class="default_gray" />
              </div>
            </v-flex>
            <v-flex style="max-width: 40px; margin-left: -10px">
              <v-menu
                transition="slide-y-transition"
                offset-y
                close-on-content-click
                bottom
                content-class="up_menu"
              >
                <!-- this on slot sometime is undefined -->
                <template #activator="{ on }">
                  <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                    <v-icon
                      color="van_color03"
                      size="21"
                      style="line-height: 40px"
                    >
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-divider class="my-5 mx-0" />
          <v-layout align-center>
            <v-flex
              class="skeleton_box"
              style="
                max-width: 60px;
                width: 60px;
                height: 63px;
                margin: 0px 0px 0px 10px;
                border: 1px solid #eeeeee;
              "
            />
            <v-flex>
              <div class="font_2 bold" style="max-width: 50%">
                <span class="default_gray" />
              </div>
              <div class="font_2">
                <span class="default_gray" />
              </div>
            </v-flex>
            <v-flex style="max-width: 40px; margin-left: -10px">
              <v-menu
                transition="slide-y-transition"
                offset-y
                close-on-content-click
                bottom
                content-class="up_menu"
              >
                <!-- this on slot sometime is undefined -->
                <template #activator="{ on }">
                  <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                    <v-icon
                      color="van_color03"
                      size="21"
                      style="line-height: 40px"
                    >
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex
      v-if="
        !(
          !authorizationLoad ||
          (authorization && authorization.length > 0) ||
          (notActiveauthorization && notActiveauthorization.length > 0)
        )
      "
      class="pa-5"
    >
      <v-layout class="shebaList" column>
        <!-- add iban -->
        <v-flex>
          <v-layout align-center justify-start>
            <v-flex style="max-width: 56px; margin-left: 10px">
              <v-avatar color="van_color11" size="56">
                <img
                  style="max-width: 24px"
                  :src="require('@/assets/img/newIcon/Subscribe.svg')"
                />
              </v-avatar>
            </v-flex>
            <v-flex>
              <div class="font_2" style="text-align-last: right">
                اشتراکی برای این مشتری ثبت نشده است
              </div>
            </v-flex>
            <v-spacer />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>

    <!-- authorization list -->
    <v-flex
      v-if="authorizationLoad && authorization && authorization.length > 0"
    >
      <v-layout class="shebaList" column>
        <v-flex
          v-for="(auth, index) in authorization"
          :key="index"
          style="width: 100%"
        >
          <v-layout align-center>
            <v-flex
              style="
                max-width: 60px;
                padding: 15px;
                margin-left: 10px;
                border: 1px solid #eeeeee;
              "
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-img
                    width="30"
                    :src="
                      require(`@/assets/img/banks/${parseInt(
                        auth.bank_code
                      )}.svg`)
                    "
                    v-on="on"
                  />
                </template>
                <span>{{ auth.bank_name }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex style="text-align-last: right">
              <div class="font_2 bold pb-2">
                سقف
                {{ auth.limit | currency }}
                {{ currencyName }}
                (
                {{ auth.count }}
                برداشت مجاز ماهانه )
              </div>
              <div class="font_30">
                از
                <span v-if="auth.is_today">امروز</span>
                <span v-else>{{ auth.created_at }}</span>
                تا
                {{ auth.expiration_date }}
                |
                {{ auth.withdrawal_sum | currency }}
                {{ currencyName }}
                درآمد |
                {{ auth.withdrawal_count }}
                برداشت
              </div>
            </v-flex>
            <v-spacer />
            <v-flex style="max-width: 40px; margin-left: -10px">
              <v-menu
                transition="slide-y-transition"
                offset-y
                close-on-content-click
                bottom
                content-class="up_menu"
              >
                <!-- this on slot sometime is undefined -->
                <template #activator="{ on }">
                  <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                    <v-icon
                      color="van_color03"
                      size="21"
                      style="line-height: 40px"
                    >
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>

                <v-card outlined class="drop_down_menu">
                  <v-list class="menu_v_list">
                    <v-list-item
                      :to="{
                        name: 'businessInvoice',
                        params: {
                          filtered: 'successfullTransaction',
                          uuid: auth.authorization_id,
                        },
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>مشاهده برداشت ها</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-divider v-if="index + 1 < authorization.length" class="my-5" />
        </v-flex>
      </v-layout>
    </v-flex>

    <!-- authorization list -->
    <v-flex
      v-if="
        authorizationLoad &&
        notActiveauthorization &&
        notActiveauthorization.length > 0
      "
    >
      <v-layout class="shebaList" column>
        <v-flex
          v-for="(auth, index) in notActiveauthorization"
          :key="index"
          style="width: 100%"
        >
          <v-layout align-center>
            <v-flex
              style="
                max-width: 60px;
                padding: 15px;
                margin-left: 10px;
                border: 1px solid #eeeeee;
              "
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-img
                    width="30"
                    style="filter: grayscale(1); opacity: 0.58"
                    :src="
                      require(`@/assets/img/banks/${parseInt(
                        auth.bank_code
                      )}.svg`)
                    "
                    v-on="on"
                  />
                </template>
                <span>{{ auth.bank_name }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex style="text-align-last: right">
              <div class="font_2 bold pb-2" style="color: #999999">
                سقف
                {{ auth.limit | currency }}
                {{ currencyName }}
                (
                {{ auth.count }}
                برداشت مجاز ماهانه )
              </div>
              <div class="font_30" style="color: #999999">
                از
                <span v-if="auth.is_today">امروز</span>
                <span v-else>{{ auth.created_at }}</span>
                تا
                {{ auth.expiration_date }}
                |
                {{ auth.withdrawal_sum | currency }}
                {{ currencyName }}
                درآمد |
                {{ auth.withdrawal_count }}
                برداشت
              </div>
            </v-flex>
            <v-spacer />
            <v-flex style="max-width: 65px; text-align-last: left">
              <div class="statusBox">
                <span v-if="auth.status == 'revoked'"> لغوشده </span>
                <span v-if="auth.status == 'expired'"> منقضی‌شده </span>
              </div>
            </v-flex>
            <v-flex style="max-width: 40px; margin-left: -10px">
              <v-menu
                transition="slide-y-transition"
                offset-y
                close-on-content-click
                bottom
                content-class="up_menu"
              >
                <!-- this on slot sometime is undefined -->
                <template #activator="{ on }">
                  <v-btn icon size="24" fab small style="margin: 0px" v-on="on">
                    <v-icon
                      color="van_color03"
                      size="21"
                      style="line-height: 40px"
                    >
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>

                <v-card outlined class="drop_down_menu">
                  <v-list class="menu_v_list">
                    <v-list-item
                      :to="{
                        name: 'businessInvoice',
                        params: {
                          filtered: 'successfullTransaction',
                          uuid: auth.authorization_id,
                        },
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>مشاهده برداشت ها</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-divider
            v-if="index + 1 < notActiveauthorization.length"
            class="my-5"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Authorization",
  props: ["id"],
  data() {
    return {
      authorizationLoad: false,
      authorization: [],
      notActiveauthorization: [],
    }
  },
  mounted() {
    this.getAuthorization()
  },
  methods: {
    getAuthorization() {
      this.$http
        .get(
          this.$store.getters.apiUrlParam +
            "/customers/" +
            this.id +
            "/authorizations"
        )
        .then((response) => {
          this.authorizationLoad = true
          response.data.data.forEach((element) => {
            if (element.bank_code == "102") element.bank_code = "062"
            this.$getMyBanksProperty.forEach((item, index) => {
              if (item.code.indexOf(element.bank_code) > -1) {
                element.bank_name = item.persianName
              }
            })
            if (element.status == "active") this.authorization.push(element)
            else this.notActiveauthorization.push(element)
          })
        })
        .catch((error) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.v-divider {
  margin-right: -31px;
  margin-left: -31px;
  max-width: 120%;
  border-color: #eeeeee !important;
}
.statusBox span {
  background-color: #999999;
  border-radius: 4px;
  color: white;
  font-size: 10px;
  padding: 5px 10px;
  white-space: nowrap;
}
.authorizationList > .flex:nth-child(2) {
  margin-top: -10px !important;
}
</style>
