<template>
  <v-item>
    <v-card flat class="d-flex flex-column align-center">
      <v-card
        flat
        class="mb-2 radius-4 w-100 border_eee default_gray"
        style="height: 103px"
      />
      <div class="v-chip default_gray" />
    </v-card>
  </v-item>
</template>

<script>
export default {
  name: "BankCardSkeleton",
}
</script>
