<template>
  <v-layout
    class="data_table_paginate elevation-1"
    align-center
    :column="$vuetify.breakpoint.xsOnly"
    :reverse="$vuetify.breakpoint.xsOnly"
  >
    <v-flex>
      <v-layout class="align-center justify-center">
        <v-flex class="item_of_items">
          {{ pageStart | thousandSeprator }} -
          {{ pageStop | thousandSeprator }} از
          {{ itemsLength | thousandSeprator }}
          <span>|</span>
        </v-flex>
        <v-flex class="select_rows">
          <v-layout class="align-center">
            <v-flex style="min-width: 100px"> نمایش در هر صفحه </v-flex>
            <v-flex>
              <v-select v-model="rowsPerPage" :items="rows" hide-details />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex class="page_numbers" text-center>
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="totalVisible"
        color="#4c9f8733"
        class="text--van_color02 pagination"
      />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "TablePaginate",
  props: {
    totalPages: {
      default: 1,
    },
    rows: {
      default() {
        return [20, 50, 100]
      },
      type: Array,
    },
    itemsLength: {
      default: null,
    },
    totalVisible: {
      default: 7,
    },
  },
  data() {
    return {
      rowsPerPage: this.rows[0],
      page: 1,
    }
  },
  computed: {
    pageStart() {
      return (parseInt(this.page) - 1) * parseInt(this.rowsPerPage) + 1
    },
    pageStop() {
      const end = parseInt(this.page) * parseInt(this.rowsPerPage)
      return end <= this.itemsLength ? end : this.itemsLength
    },
  },
  watch: {
    page: function (newValue) {
      this.$emit("page", newValue)
      // this.$vuetify.goTo(0)
    },
    rowsPerPage: function (newValue) {
      this.$emit("rowsPerPage", newValue)
      // this.$vuetify.goTo(0)
    },
    totalPages: function (newValue) {
      if (newValue < this.page) {
        this.page = 1
      }
    },
  },
}
</script>
