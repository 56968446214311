<template>
  <left-modal
    toolbar-name="برداشت گروهی هوشمند"
    @closeModal="$emit('closeModal')"
  >
    <general-card>
      <div class="font_2">
        در این روش پس از بارگذاری فایل اکسل، سیستم با بررسی هر ردیف، بر اساس
        شرایط‌ تاثیر گذار در روش برداشت انتخاب می‌کند تراکنش‌ها را به صورت حساب
        به حساب یا پایا ارسال می‌کند.
      </div>
      <div class="font_13 mt-3 mb-2">شرایط ارسال برداشت به صورت تسویه آنی:</div>
      <ul class="conditions font_2 van_color03--text">
        <li>شبای آن ردیف، جزو بانک‌های تسویه آنی وندار باشد.</li>
        <li>شبای آن ردیف، جزو بانک‌های فعال تسویه آنی کسب‌وکار شما باشد.</li>
        <li>بانک شبای آن ردیف، باقی مانده امروز داشته باشد.</li>
        <li>شبای آن ردیف، سقف امروز تسویه آنی داشته باشند.</li>
      </ul>
      <div class="font_2 mt-3">
        در صورتی‌که هر برداشت، شرایط ارسال به صورت تسویه آنی را نداشته باشد
        در سیکل پایا قرار می‌گیرد.
      </div>
    </general-card></left-modal
  >
</template>

<script>
import leftModal from "@/components/modals/leftModal.vue"
import generalCard from "@/components/elements/generalCard"
export default {
  name: "SmartSettlementGuide",
  components: {
    generalCard,
    leftModal,
  },
}
</script>
<style lang="scss" scoped>
.conditions {
  list-style: inside;
  li {
    &::marker {
      color: $van-color03;
    }
  }
}</style>