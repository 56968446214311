<template>
  <v-card class="container py-6 px-2" color="transparent" elevation="0">
    <span class="topBack approval-pending-tools-back"></span>
    <section class="container__first">
      <img
        v-if="$vuetify.breakpoint.smAndUp"
        width="100px"
        height="100px"
        src="@/assets/img/newIcon/tools/tools.svg"
      />
    </section>
    <section class="container__second">
      <h1
        class="van_color02--text mb-1"
        :class="{ font_23: $vuetify.breakpoint.xsOnly }"
      >
        {{ lang.businessHome.approvalPendingTools }}
      </h1>
      <span class="font-11-no-333-17">
        {{ lang.businessHome.evaluateToolsActivation }}
      </span>
      <section
        v-if="Object.entries(toolsObject).length > 0"
        ref="toolsList"
        class="container__second__tools-list"
      >
        <div
          v-for="(tool, index) in pendingToolsList"
          :key="index"
          class="container__second__tools-list__container"
        >
          <span class="d-flex align-center font_30">
            <img
              width="18"
              height="18"
              :src="
                require(`@/assets/img/newIcon/tools/${tool[1].name_en}.svg`)
              "
            />
            {{ tool[1].name_fa }}
          </span>
        </div>
      </section>
    </section>
  </v-card>
</template>

<script>
import lang from "@/lang/languageResource.json"

export default {
  name: "ApprovalPendingToolsContainer",
  data() {
    return {
      lang,
    }
  },
  computed: {
    toolsObject() {
      return this.$store?.state?.business?.tools || {}
    },
    pendingToolsList() {
      return Object.entries(this.toolsObject).filter(
        (item) => item[1]?.status === "PENDING"
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 182px;
  margin-bottom: 10px;
  position: relative;

  &__first {
    width: 13%;
    text-align: right;
    user-select: none;
    z-index: 1;
  }

  &__second {
    width: 87%;
    z-index: 1;

    &__tools-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;

      &__container {
        display: flex;
        margin-left: 8px;
        flex-shrink: 0;
        padding: 8px 12px;
        background: $van_color18;
        border-radius: 4px;
        margin-bottom: 8px;

        &__tool {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: auto;
    min-height: 182px;

    &__second {
      width: 97%;
      &__tools-list {
        &__container {
          display: flex;
          justify-content: center;
          width: 128px;
        }
      }
    }
  }
}
</style>
