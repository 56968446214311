<template>
  <left-modal
    toolbar-name="مانده حساب‌ تسویه آنی"
    @closeModal="$emit('closeModal')"
  >
    <div slot="toolbarItems">
      <v-btn
        class="btn_small_normal"
        outlined
        text
        @click="$emit('openBankManagementModal')"
      >
        مدیریت بانک‌ها
      </v-btn>
    </div>
    <div v-if="!bankListIsEmpty">
      <show-daily-item
        v-for="(bank, index) in bankList"
        :key="bank + index"
        :bank="bank"
      />
    </div>
    <general-card v-for="i in 2" v-else :key="i">
      <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        tile
        type="list-item-avatar-three-line"
      />
    </general-card>
  </left-modal>
</template>

<script>
import LeftModal from "../../modals/leftModal"
import GeneralCard from "@/components/elements/generalCard"
import ShowDailyItem from "@/components/A2A/elements/showDailyItem"
export default {
  name: "ShowDailyDetails",
  components: { ShowDailyItem, GeneralCard, LeftModal },
  props: ["isChanged"],
  data() {
    return {
      bankList: [],
    }
  },
  computed: {
    bankListIsEmpty() {
      return !this.bankList?.length > 0
    },
  },
  watch: {
    isChanged: function (newValue, oldValue) {
      this.bankList = []
      this.getBankList()
    },
  },
  mounted() {
    this.getBankList()
  },
  methods: {
    getBankList() {
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") + "/settlement/banks",
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.bankList = response?.data?.data?.filter(
            (b) => b.a2a.is_active && b.a2a.has_ability
          )
        })
        .catch(() => {
          //callsnackbar
        })
    },
  },
}
</script>
