<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">ویرایش شناسه ملی</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          :disabled="!valid"
          class="btn_small_normal"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex>
        <v-form ref="form" v-model="valid">
          <div class="font_2">شناسه ملی را مطابق با روزنامه رسمی وارد کنید</div>
          <v-divider class="mb-7" style="border-style: dashed" />
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              شناسه ملی
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <national-id
                :input-value="national_id"
                @setValue="national_id = $event"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import NationalId from "../inputs/nationalId.vue"

export default {
  name: "AddNationalId",
  components: {
    NationalId,
  },
  data: function () {
    return {
      valid: false,
      national_id: null,
      loading: false,
    }
  },

  methods: {
    sendForm() {
      this.loading = true
      var data = {
        national_id: this.national_id,
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/update/national-id", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.commit("callSuccessSnackbar", response.data.message)
          this.$store.commit("updateNationalId", this.national_id)
          this.$emit("closeModal")
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
  },
}
</script>
