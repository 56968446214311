<template>
  <general-card>
    <div class="wrap-container d-flex flex-wrap justify-space-between">
      <!--  right section-->
      <div class="right-section d-flex ml-8">
        <div
          class="d-flex"
          :class="{
            'flex-column': reuseIsMobile,
            'align-center': $vuetify.breakpoint.smAndUp,
          }"
        >
          <v-img
            class="ml-4"
            :src="require('@/assets/img/newIcon/A2ACellings.svg')"
            width="60"
            height="60"
            max-width="60"
          />
          <div class="d-flex flex-column flex-sm-row">
            <div class="font_9 mb-2">مانده حساب تسویه آنی</div>
            <div class="font_2">
              <span class="linkBorder ml-0 mr-0 mr-sm-4">
                <v-btn
                  class="small mb-0"
                  style="font-size: 12px !important"
                  text
                  :ripple="false"
                  @click="changeIsShowingDailyCellingModalState(true)"
                >
                  مشاهده جزییات
                </v-btn>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- left section-->
      <div
        v-if="activeBanks?.length > 0"
        class="left-section d-flex flex-wrap mr-auto align-center"
      >
        <a2a-daily-card-item
          v-for="(bank, index) in activeBanks"
          :key="bank + index"
          :bank="bank"
        />
      </div>
      <!-- skeleton -->
      <div v-else class="mr-auto">
        <v-progress-circular
          v-for="i in 3"
          :key="i"
          class="outerProgressCircle mx-4 my-2"
          :class="{ 'mt-12': reuseIsMobile }"
          value="0"
          indeterminate
          width="4"
          :size="reuseIsMobile ? '50' : '62'"
          color="van_color06"
        />
      </div>
    </div>

    <!--    modals-->
    <v-dialog
      v-if="showDailyCellingModal"
      v-model="showDailyCellingModal"
      :fullscreen="reuseIsMobile"
      :width="reuseIsMobile ? '100%' : '600'"
      :content-class="
        showBankManagementModal ? 'left-modal secondModal' : 'left-modal'
      "
      no-click-animation
      transition="slide-x-transition"
      @click:outside="changeIsShowingDailyCellingModalState(false)"
    >
      <show-daily-details
        :is-changed="isChanged"
        @closeModal="changeIsShowingDailyCellingModalState(false)"
        @openBankManagementModal="changeIsShowingBankManagementModalState(true)"
      />
    </v-dialog>

    <!--    modals-->
    <v-dialog
      v-if="showBankManagementModal"
      v-model="showBankManagementModal"
      :fullscreen="reuseIsMobile"
      :width="reuseIsMobile ? '100%' : '600'"
      content-class="left-modal"
      no-click-animation
      transition="slide-x-transition"
      @click:outside="changeIsShowingBankManagementModalState(false)"
    >
      <banks-management
        :activation-step="false"
        @onBanksUpdate="updateBanks"
        @closeModal="changeIsShowingBankManagementModalState(false)"
      />
    </v-dialog>
  </general-card>
</template>

<script>
import GeneralCard from "../../elements/generalCard"
import BanksManagement from "../modals/banksManagement"
import A2aDailyCardItem from "@/components/A2A/elements/a2aDailyCardItem"
import ShowDailyDetails from "@/components/A2A/modals/showDailyDetails"
import vuetify from "@/plugins/vuetify"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "A2aDailyCard",
  components: {
    ShowDailyDetails,
    A2aDailyCardItem,
    BanksManagement,
    GeneralCard,
  },
  mixins: [screenSize],
  props: {
    isThereBankAccounts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChanged: false,
      showBankManagementModal: false,
      activeBanks: [],
      showDailyCellingModal: false,
    }
  },
  mounted() {
    this.getBanksLimits()
    let showSnackbar = this.$route?.params?.a2aSnackbar
    let message = this.$route?.params?.snackbarMessage

    if (showSnackbar) {
      this.$store.commit("callSuccessSnackbar", message)
    }
  },
  methods: {
    vuetify() {
      return vuetify
    },
    updateBanks() {
      this.activeBanks = []
      this.isChanged = !this.isChanged
      this.getBanksLimits()
    },
    getBanksLimits() {
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") + "/settlement/banks",
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          let banks = response?.data?.data
          banks = banks?.filter((b) => b.a2a?.is_active && b.a2a?.has_ability)

          if (banks?.length === 0) this.$emit("noBankIsActive")
          //sort bank list so banks with zero remained amount comes last and -1 comes first
          banks?.sort(
            (firstBank, secondBank) =>
              this.limitlessCelling(firstBank, secondBank) ||
              this.limitedCelling(firstBank, secondBank)
          )

          this.activeBanks = banks
        })
        .catch(() => {
          //callsnackbar
        })
    },
    limitedCelling(firstBank, secondBank) {
      return (
        secondBank.a2a?.limit?.amount_limit?.remained !== -1 &&
        firstBank.a2a?.limit?.amount_limit?.remained !== -1 &&
        secondBank.a2a?.limit?.amount_limit?.remained -
          firstBank.a2a?.limit?.amount_limit?.remained
      )
    },
    limitlessCelling(firstBank, secondBank) {
      return (
        firstBank.a2a?.limit?.amount_limit?.remained === -1 &&
        firstBank.a2a?.limit?.amount_limit?.remained -
          secondBank.a2a?.limit?.amount_limit?.remained
      )
    },
    changeAccountDetailsSecondModal(state) {
      this.$store.commit("changeAccountDetailsSecondModal", state)
    },
    changeAccountDetailsThirdModal(state) {
      this.$store.commit("changeAccountDetailsThirdModal", state)
    },
    changeIsShowingDailyCellingModalState(state) {
      this.showDailyCellingModal = state

      if (this.isThereBankAccounts) {
        this.changeAccountDetailsSecondModal(state)
      }
    },
    changeIsShowingBankManagementModalState(state) {
      this.showBankManagementModal = state

      if (this.isThereBankAccounts) {
        this.changeAccountDetailsThirdModal(state)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap-container {
  gap: 20px;
}
.right-section {
  min-width: fit-content;
}

.left-section {
  direction: ltr;
  gap: 5.8px;
}

span.back {
  width: 120vw;
  margin-bottom: -47px;
  background-color: #eeeeee;
  position: absolute;
  top: -26px;
  right: calc((920px - 100vw) / 2);
  bottom: 0;
  left: 0;
  z-index: -1;
  @media screen and (max-width: 1275px) {
    right: -181px;
    left: -30px;
    width: 152vw;
  }
  @media screen and (max-width: 599px) {
    display: none;
  }
}
</style>
