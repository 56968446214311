<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title
        v-if="setting && !editAddress && !editPhone"
        class="pr-0"
      >
        اطلاعات مکانی کسب‌وکار
      </v-toolbar-title>
      <v-toolbar-title v-else-if="setting && editAddress" class="pr-0">
        اصلاح آدرس
      </v-toolbar-title>
      <v-toolbar-title v-else-if="setting && editPhone" class="pr-0">
        اصلاح تلفن ثابت
      </v-toolbar-title>
      <v-toolbar-title v-else-if="edit" class="pr-0">
        اصلاح کدپستی
      </v-toolbar-title>
      <v-toolbar-title v-else class="pr-0"> اطلاعات مکانی </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          :disabled="!valid"
          class="btn_small_normal"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex>
        <v-form ref="form" v-model="valid">
          <div v-if="setting" class="font_2">
            اطلاعات محل سکونت شما مطابق با کدپستی مشخص می‌شوند.
          </div>
          <div v-else-if="edit" class="font_2">
            کدپستی که هنگام ثبت کسب‌و‌کار ارسال کردید، مورد تایید شاپرک قرار
            نگرفت. احتمالا باید کد پستی که هنگام ثبت کد رهگیری مالیاتی اعلام
            کردید را وارد کنید
          </div>
          <div v-else class="font_2">
            اطلاعات محل سکونت شما مطابق با کدپستی مشخص می‌شوند.
          </div>
          <v-divider class="mb-7" style="border-style: dashed" />
          <v-layout
            v-if="!editAddress && !editPhone"
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              کد پستی
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <postal-code
                :input-value="postal_code"
                @setValue="setPostalCode($event)"
                @setAddress="setAddress($event)"
              />
            </v-flex>
          </v-layout>
          <div v-if="postal_code">
            <v-layout
              v-if="!editPhone"
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
              style="margin-bottom: 10px"
            >
              <v-flex class="label">
                استان و شهر
                <span>*</span>
              </v-flex>
              <v-flex v-if="addressDetailsRequired" class="withlabel">
                <v-autocomplete
                  v-model="city_province_id"
                  :rules="city_id_Rules"
                  :items="ostans"
                  item-text="value"
                  item-value="key"
                  solo
                  flat
                  height="40"
                  outlined
                  single-line
                  no-data-text="اطلاعاتی برای نمایش موجود نیست"
                  placeholder="برای جستجو تایپ کنید"
                />
              </v-flex>
              <v-flex v-else class="withlabel">
                <v-text-field
                  v-model="cityAndProvince"
                  solo
                  flat
                  height="40"
                  outlined
                  readonly
                >
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout
              v-if="!editPhone"
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
              style="margin-bottom: 10px"
            >
              <v-flex class="label">
                آدرس
                <span>*</span>
              </v-flex>
              <v-flex class="withlabel big">
                <v-textarea
                  v-model="address"
                  :rules="address_Rules"
                  solo
                  flat
                  outlined
                  rows="2"
                  :readonly="
                    (!$store.state.isUser &&
                      $store.state.business.address_detail.address != null &&
                      $store.state.business.has_shaparak_terminal) ||
                    (!$store.state.isUser &&
                      $store.state.business.status == 1) ||
                    ($store.state.isUser &&
                      $store.state.user.has_shaparak_terminal)
                  "
                >
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-textarea>
              </v-flex>
            </v-layout>
            <v-layout
              v-show="!editAddress"
              v-if="!$store.state.isUser && !edit"
              :column="$vuetify.breakpoint.xsOnly"
              justify-start
              align-start
            >
              <v-flex class="label">
                تلفن ثابت
                <span>*</span>
              </v-flex>
              <v-flex class="withlabel inputBeside">
                <v-text-field
                  ref="cityCode"
                  v-model="cityCode"
                  class="smallPart"
                  solo
                  flat
                  height="40"
                  outlined
                  disabled
                  placeholder="کد"
                >
                  <template #message="message">
                    <div v-html="message.message"></div>
                  </template>
                </v-text-field>
                <phoneNumber
                  :input-value="phone_number"
                  @setValue="phone_number = $event"
                />
              </v-flex>
            </v-layout>
          </div>
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import PostalCode from "@/components/inputs/postalCode"
import phoneNumber from "@/components/inputs/phoneNumber.vue"

export default {
  name: "LocationInfo",
  components: {
    PostalCode,
    phoneNumber,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    setting: {
      type: Boolean,
      default: false,
    },
    editAddress: {
      type: Boolean,
      default: false,
    },
    editPhone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      postal_code: "",
      city_province_id: "",
      city: "",
      city_id: "",
      province: "",
      province_id: "",
      address: "",
      valid: false,
      cityCode: "",
      phone_number: "",
      ostans: [],
      cityCodes: [],
      city_id_Rules: [(v) => !!v || ""],
      addressDetailsRequired: false,
      loading: false,
      address_Rules: [
        (v) => !!v || "<span> آدرس شرکت را وارد کنید </span>",
        (v) =>
          /[\u0600-\u06FF]+/.test(v) ||
          "<span> آدرس باید با حروف فارسی وارد شود </span>",
        (v) =>
          (v && v.length > 8) ||
          "<span> نشانی باید بیش از 8 کاراکتر باشد </span>",
      ],
    }
  },
  computed: {
    cityAndProvince() {
      return this.province + ", " + this.city
    },
  },
  watch: {
    city_province_id: function (newValue) {
      if (newValue != "") {
        this.cityCode =
          this.cityCodes[parseInt(this.city_province_id.split("-")[0] - 1)]
        this.province_id = parseInt(this.city_province_id.split("-")[0])
        this.city_id = parseInt(this.city_province_id.split("-")[1])
      }
    },
  },
  created() {
    if (
      this.setting &&
      this.$store.state[this.$store.getters.userOrBuisenss].address_detail
    ) {
      if (this.$store.state[this.$store.getters.userOrBuisenss].postal_code) {
        this.postal_code =
          this.$store.state[
            this.$store.getters.userOrBuisenss
          ].postal_code.toString()
      }
      this.city =
        this.$store.state[
          this.$store.getters.userOrBuisenss
        ].address_detail.city
      this.province =
        this.$store.state[
          this.$store.getters.userOrBuisenss
        ].address_detail.provinces
      this.address =
        this.$store.state[
          this.$store.getters.userOrBuisenss
        ].address_detail.address
      this.phone_number =
        this.$store.state[this.$store.getters.userOrBuisenss].phone_number
      this.cityCode =
        this.$store.state[
          this.$store.getters.userOrBuisenss
        ].address_detail.provinces_code
    }
    if (
      this.$store.state[this.$store.getters.userOrBuisenss].address_detail &&
      !this.$store.state[this.$store.getters.userOrBuisenss].address_detail
        .city &&
      this.$store.state[this.$store.getters.userOrBuisenss].address_detail
        .city == ""
    ) {
      this.getCities()
      this.addressDetailsRequired = true
      this.city_province_id =
        this.$store.state[this.$store.getters.userOrBuisenss].address_detail
          .provinces_id +
        "-" +
        this.$store.state[this.$store.getters.userOrBuisenss].address_detail
          .city_id
    }
  },
  //this doesnt work
  mounted() {
    if (
      this.$store.state[this.$store.getters.userOrBuisenss].status == 1 ||
      (this.$store.state.isUser && this.$store.state.user.has_shaparak_terminal)
    ) {
      this.valid = false
    }
  },
  methods: {
    setAddress(response) {
      if (response != null) {
        this.addressDetailsRequired = false
        this.city = response.city
        this.province = response.provinces
        this.address = response.address
        this.cityCode = response.provinces_code
      } else {
        this.getCities()
        this.addressDetailsRequired = true
        this.city = ""
        this.province = ""
        this.address = ""
        this.cityCode = ""
        this.city_province_id = ""
        this.city_id = ""
        this.province_id = ""
      }
    },
    setPostalCode(response) {
      this.postal_code = response
    },
    sendForm() {
      this.loading = true
      var data = {
        postal_code: this.postal_code,
        address: this.address,
      }
      if (this.addressDetailsRequired) {
        data["city_id"] = this.city_id
        data["province_id"] = this.province_id
      }
      if (!this.$store.state.isUser) {
        data["phone_number"] = this.phone_number
      }
      if (this.edit && !this.setting) {
        data["is_for_shaparak"] = true
      }
      this.$http
        .put(this.$store.getters.apiUrlParam + "/postalcode", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.commit("callSuccessSnackbar", response.data.message)

          //save city untill refresh
          this.$store.commit("updateAddressDetail", {
            key: "city",
            value: this.city,
          })
          //save city_id untill refresh
          this.$store.commit("updateAddressDetail", {
            key: "city_id",
            value: this.city_id,
          })
          //save province untill refresh
          this.$store.commit("updateAddressDetail", {
            key: "provinces",
            value: this.province,
          })

          //save province_id untill refresh
          this.$store.commit("updateAddressDetail", {
            key: "provinces_id",
            value: this.province_id,
          })
          //save province_code untill refresh
          this.$store.commit("updateAddressDetail", {
            key: "provinces_code",
            value: this.cityCode,
          })
          //save address temporary untill refresh
          this.$store.commit("updateAddressDetail", {
            key: "address",
            value: this.address,
          })
          //save phoneNumber temporary untill refresh
          if (!this.$store.state.isUser) {
            this.$store.commit("updateUserOrBusinessValue", {
              key: "phone_number",
              value: this.phone_number,
            })
          }
          //save postalCode temporary untill refresh
          this.$store.commit("updateUserOrBusinessValue", {
            key: "postal_code",
            value: this.postal_code,
          })
          this.$store.commit("updatePostalCodeStatus", "PENDING")
          if (!this.$store.state.isUser) {
            this.$store
              .dispatch(
                "retrieveBusinessObject",
                this.$store.state.business.business_name
              )
              .then((response) => {
                //DO Nothing
              })
              .catch((error) => {
                //callsnackbar
              })
          }
          this.$emit("closeModal", { status: true })
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
    getCities() {
      this.$http
        .get(this.$store.state.api_base_url + "/information/cities", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.ostans = Object.keys(response.data.data).map(function (key) {
            return { key: key, value: response.data.data[key] }
          })
          this.cityCodes = Object.keys(response.data.cityCode).map(function (
            key
          ) {
            return response.data.cityCode[key]
          })
        })
        .catch((error) => {
          //callsnackbar
          window.location.reload()
        })
    },
  },
}
</script>
