<template>
  <v-card class="singleField">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="closeModal()">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">افزودن شماره شبا</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!valid1"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout column class="adding_sheba">
      <v-flex>
        <div
          class="font-size-12 bold van_color02--text mb-2"
          style="text-align: center"
        >
          پس از ورود شماره شبا، منتظر نمایش نام صاحب حساب و بانک باشید، بعد
          ذخیره کنید.
        </div>
        <div
          class="font-size-12 bold van_color03--text mb-2"
          style="text-align: center"
        >
          شماره شبا باید به نام
          {{ isPersonal() }}
          کسب‌و‌کار باشد.
        </div>
        <v-form ref="form1" :value="valid1">
          <v-text-field
            ref="sheba"
            v-model="inputComputed"
            placeholder="XX XXXX XXXX XXXX XXXX XXXX XX"
            solo
            flat
            height="40"
            prefix="IR"
            maxlength="26"
            outlined
            @keydown="numberKeyHandler"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-text-field>
        </v-form>
        <div
          :class="
            error
              ? 'withError gray-text-box with-data'
              : 'gray-text-box with-data'
          "
        >
          <span v-if="ibanOwner == '' && error == ''"
            >در انتظار ورود شماره شبا</span
          >
          <v-layout
            v-else-if="persianName && ibanOwner && error == ''"
            align-center
          >
            <v-flex
              style="
                max-width: 60px;
                padding: 15px;
                margin-left: 10px;
                border: 1px solid #eeeeee;
                background-color: white;
              "
            >
              <v-img
                size="30"
                :src="require(`@/assets/img/banks/${ibank}.svg`)"
              />
            </v-flex>
            <v-flex>
              <div class="font_2 bold">
                {{ ibanOwner }}
              </div>
              <div class="font_4">
                {{ persianName }}
              </div>
            </v-flex>
          </v-layout>
          <span v-else-if="error">{{ error }}</span>
        </div>
      </v-flex>
    </v-layout>
    <general-dialog
      :active="showPendingModal"
      logo="newIcon/pending-square-yellow.svg"
      content-class="confirmationMessageDialog"
      style="height: 100%"
      title="شماره شبا در وضعیت بررسی قرار گرفت"
      description="شماره شبا توسط وندار بررسی و نتیجه آن از طریق پیامک برای شما ارسال می‌شود. از تب شباهای اختصاصی کسب‌وکار می‌توانید وضعیت را مشاهده کنید."
      btn1-text="مشاهده شباها"
      btn2-text="برگشت به میز کار"
      @btn1Clicked="$emit('closeModal', { status: true })"
      @btn2Clicked="$router.push({ name: 'businessHome' })"
    >
    </general-dialog>
  </v-card>
</template>

<script>
import GeneralDialog from "@/components/modals/generalDialog.vue"

export default {
  name: "AddBusinessIban",
  components: { GeneralDialog },
  props: {
    defaultIban: {
      type: Boolean,
      default: false,
    },
    selfIban: {
      type: Boolean,
      default: false,
    },
    forCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      valid1: false,
      input: "",
      ibank: null,
      persianName: "",
      ibanOwner: "",
      error: "",
      loading: false,
      showPendingModal: false,
    }
  },
  computed: {
    inputComputed: {
      get() {
        return this.input
      },
      set(newValue) {
        let inputtedValue
        if (newValue?.slice(0, 2) === "IR") {
          inputtedValue = newValue?.slice(2)
        } else inputtedValue = newValue

        if (!/^[IRir 0-9۰-۹-]*$/.test(newValue)) {
          newValue = ""
          this.input = ""
          this.error = ""
        }

        this.input = this.convertNumbers2English(inputtedValue).replace(
          /[a-zA-Z -]/g,
          ""
        )

        this.$refs.sheba.lazyValue = this.input
        this.error = ""
        this.ibanOwner = ""
        this.valid1 = false

        if (this.input.length >= 5) this.checkBank()
      },
    },
  },
  methods: {
    isPersonal() {
      if (this.$store.state.business.business_type == "personal") {
        return "مالک"
      }
      return "حقوقی و یا به نام صاحبین امضای"
    },
    checkAyandeh(item) {
      // this.ibank = parseInt(item.slice(5, 7))
      let ayandehPrpperties = this.$getMyBanksProperty.find(
        (element) => element.persianName == "بانک آینده"
      )

      if (ayandehPrpperties && item == ayandehPrpperties.code) return true
      return false
    },
    checkBank() {
      if (this.inputComputed.length >= 5) {
        this.ibank = parseInt(this.inputComputed.slice(2, 5))
        this.$getMyBanksProperty.forEach((element, index) => {
          if ("0" + this.ibank == element.code) {
            this.persianName = element.persianName
          }
        })
      } else {
        this.ibank = null
        this.persianName = ""
      }

      if (this.inputComputed.length === 24) {
        this.valid1 = false
        //validation Api
        this.$http
          .post(this.$store.getters.ibanApiUrlParam + "/check", {
            IBAN: "IR" + this.inputComputed,
          })
          .then((response) => {
            this.ibanOwner =
              response.data.data.ibans.account_owner[0].firstName +
              " " +
              response.data.data.ibans.account_owner[0].lastName
            if (
              !response.data?.data?.is_owner &&
              this.$store.state.business.business_type == "personal"
            ) {
              this.error =
                "تطابق مالکیت شبای وارد شده با مالک کسب و کار احراز نشد."
              return
            }
            this.error = ""
            this.valid1 = true
          })
          .catch((error) => {
            //callsnackbar
            this.valid1 = false
            this.error = error
          })
      } else {
        this.valid1 = false
        this.ibanOwner = ""
        this.error = ""
      }
    },
    sendForm() {
      this.loading = true
      let url, data

      url = this.$store.getters.apiUrlParamV3 + "/business-iban/"
      data = {
        iban: "IR" + this.inputComputed,
      }
      this.$http
        .post(url, data)
        .then((response) => {
          this.loading = false
          if (response.data.data.hasOwnProperty("message")) {
            this.$store.commit("callsnackbar", response.data.data.message)
          }

          // If business is personal or iban accepted
          if (
            this.$store.state.business.business_type == "personal" ||
            (response.data.data?.status !== "rejected" &&
              response.data.data?.status_is_pending === false)
          ) {
            this.$store.commit(
              "callSuccessSnackbar",
              "شماره شبا به لیست شباهای اختصاصی کسب‌و‌کار، اضافه شد."
            )
            this.closeModal({ status: true })
            return
          }

          // If business is legal and iban is pending
          if (response.data.data?.status_is_pending) {
            this.showPendingModal = true
          }
        })
        .catch(() => {
          //callsnackbar
          this.loading = false
        })
    },
    closeModal(value = false) {
      this.$store.commit("callSecondModal", false)
      this.$emit("closeModal", { status: value })
      this.inputComputed = ""
      this.valid1 = false
      this.inputComputed = ""
      this.ibank = null
      this.persianName = ""
      this.ibanOwner = ""
    },
  },
}
</script>
