<template>
  <left-modal
    toolbar-name="برداشت‌های در نوبت"
    @closeModal="$emit('closeModal')"
  >
    <template v-if="canceledQueueSettlement?.length" #subToolbar>
      <v-tabs
        v-model="tab"
        :class="[$vuetify.breakpoint.xsOnly ? 'px-6' : 'px-8']"
      >
        <v-tab class="tab-title px-4 font-size-12">برداشت‌های در نوبت</v-tab>
        <v-tab class="tab-title px-4 font-size-12"
          >برداشت‌های لغو شده</v-tab
        ></v-tabs
      >
    </template>

    <div v-if="!canceledQueueSettlement">
      <v-skeleton-loader
        v-for="i in 6"
        :key="i"
        class="mb-2"
        max-height="60"
        type="card"
      ></v-skeleton-loader>
    </div>
    <div v-else-if="canceledQueueSettlement?.length === 0" class="mb-10">
      <SettlementQueuePending
        @skeleton="changeSkeleton($event)"
        @checkCanceled="checkCanceled($event)"
      />
    </div>
    <v-tabs-items v-else v-model="tab">
      <v-tab-item class="mb-10">
        <SettlementQueuePending
          @skeleton="changeSkeleton($event)"
          @checkCanceled="checkCanceled($event)"
        />
      </v-tab-item>
      <v-tab-item class="mb-10">
        <SettlementQueueCanceled
          :canceled-settlements="canceledQueueSettlement"
          :total="totalCanceled"
          :cancel-skeleton="cancelSkeleton"
          @nextPage="getNextPageCancel()"
        />
      </v-tab-item>
    </v-tabs-items>
  </left-modal>
</template>

<script>
import SettlementQueuePending from "@/components/businessHome/elements/settlementQueue/settlementQueuePending.vue"
import LeftModal from "@/components/modals/leftModal.vue"
import SettlementQueueCanceled from "@/components/businessHome/elements/settlementQueue/settlementQueueCanceled.vue"

export default {
  name: "SettlementQueueItems",
  components: {
    SettlementQueueCanceled,
    LeftModal,
    SettlementQueuePending,
  },
  data() {
    return {
      skeleton: false,
      cancelPage: 1,
      canceledQueueSettlement: null,
      totalCanceled: 0,
      tab: 0,
      cancelSkeleton: true,
    }
  },

  mounted() {
    this.getCanceledData()
  },
  methods: {
    checkCanceled(event = true) {
      this.cancelPage = 1
      this.getCanceledData()
      this.$emit("refreshData")
      this.$emit("refreshQueuedCard")

      if (event) this.tab = 1
    },
    changeSkeleton(status) {
      this.skeleton = status
    },
    getCanceledData() {
      this.cancelSkeleton = true
      this.$http
        .get(
          this.$store.getters.apiUrlParamV3 +
            `/settlement/queued/list?status=CANCELED&page=${this.cancelPage}`
        )
        .then((resp) => {
          if (this.cancelPage === 1) {
            this.canceledQueueSettlement = []
          }

          this.canceledQueueSettlement = this.canceledQueueSettlement.concat(
            resp.data.data
          )

          this.totalCanceled = resp.data.meta?.total
          this.cancelSkeleton = false
        })
        .catch(() => {
          this.cancelSkeleton = false
        })
    },
    getNextPageCancel() {
      this.cancelPage += 1
      this.getCanceledData()
    },
  },
}
</script>

<style scoped></style>
