<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">اعلان‌ها</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-layout column>
      <v-flex>
        <v-layout justify-center column>
          <v-flex xs12>
            <v-layout
              justify-space-between
              :align-center="!$vuetify.breakpoint.xsOnly"
              :column="$vuetify.breakpoint.xsOnly"
            >
              <v-flex
                class="font_10 align-last"
                :xs6="!$vuetify.breakpoint.xsOnly"
                :style="$vuetify.breakpoint.xsOnly ? 'margin-bottom:30px;' : ''"
              >
                واریزها
              </v-flex>
              <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                <v-layout wrap :column="$vuetify.breakpoint.xsOnly">
                  <v-flex
                    v-if="checkPermission"
                    :xs6="!$vuetify.breakpoint.xsOnly"
                  >
                    <v-checkbox
                      v-model="notification.transaction.sms"
                      color="van_green"
                      label="پیامک"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('transaction', 'sms')"
                    />
                  </v-flex>
                  <v-flex
                    v-if="checkPermission"
                    :xs6="!$vuetify.breakpoint.xsOnly"
                  >
                    <v-checkbox
                      v-model="notification.transaction.email"
                      color="van_green"
                      label="ایمیل"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('transaction', 'email')"
                    />
                  </v-flex>
                  <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                    <v-checkbox
                      v-model="notification.transaction.telegram"
                      color="van_green"
                      label="تلگرام"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('transaction', 'telegram')"
                    />
                  </v-flex>
                  <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                    <v-checkbox
                      v-model="notification.transaction.notif"
                      color="van_green"
                      label="اعلان درون پنل"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('transaction', 'notif')"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-divider />
          <v-flex xs12>
            <v-layout
              justify-space-between
              :align-center="!$vuetify.breakpoint.xsOnly"
              :column="$vuetify.breakpoint.xsOnly"
            >
              <v-flex
                class="font_10 align-last"
                :xs6="!$vuetify.breakpoint.xsOnly"
                :style="$vuetify.breakpoint.xsOnly ? 'margin-bottom:30px;' : ''"
              >
                برداشت‌ها
              </v-flex>
              <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                <v-layout wrap :column="$vuetify.breakpoint.xsOnly">
                  <v-flex
                    v-if="checkPermission"
                    :xs6="!$vuetify.breakpoint.xsOnly"
                  >
                    <v-checkbox
                      v-model="notification.settlement.sms"
                      color="van_green"
                      label="پیامک"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('settlement', 'sms')"
                    />
                  </v-flex>
                  <v-flex
                    v-if="checkPermission"
                    :xs6="!$vuetify.breakpoint.xsOnly"
                  >
                    <v-checkbox
                      v-model="notification.settlement.email"
                      color="van_green"
                      label="ایمیل"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('settlement', 'email')"
                    />
                  </v-flex>
                  <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                    <v-checkbox
                      v-model="notification.settlement.telegram"
                      color="van_green"
                      label="تلگرام"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('settlement', 'telegram')"
                    />
                  </v-flex>
                  <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                    <v-checkbox
                      v-model="notification.settlement.notif"
                      color="van_green"
                      label="اعلان درون پنل"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('settlement', 'notif')"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-divider v-if="$store.state.isUser" />
          <v-flex v-if="$store.state.isUser" xs12>
            <v-layout
              justify-space-between
              :align-center="!$vuetify.breakpoint.xsOnly"
              :column="$vuetify.breakpoint.xsOnly"
            >
              <v-flex
                class="font_10 align-last"
                :xs6="!$vuetify.breakpoint.xsOnly"
                :style="$vuetify.breakpoint.xsOnly ? 'margin-bottom:30px;' : ''"
              >
                ورود به حساب
              </v-flex>
              <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                <v-layout wrap :column="$vuetify.breakpoint.xsOnly">
                  <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                    <v-checkbox
                      v-model="notification.auth.sms"
                      color="van_green"
                      label="پیامک"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('auth', 'sms')"
                    />
                  </v-flex>
                  <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                    <v-checkbox
                      v-model="notification.auth.email"
                      color="van_green"
                      label="ایمیل"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('auth', 'email')"
                    />
                  </v-flex>
                  <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                    <v-checkbox
                      v-model="notification.auth.telegram"
                      color="van_green"
                      label="تلگرام"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('auth', 'telegram')"
                    />
                  </v-flex>
                  <v-flex :xs6="!$vuetify.breakpoint.xsOnly">
                    <v-checkbox
                      v-model="notification.auth.notif"
                      color="van_green"
                      label="اعلان درون پنل"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-input-right' : ''"
                      @change="sendForm('auth', 'notif')"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        v-if="
          notification.transaction.telegram ||
          notification.settlement.telegram ||
          notification.auth.telegram
        "
        class="infoBox"
      >
        <v-layout wrap>
          <v-flex style="text-align-last: right; line-height: 22px">
            <v-icon size="16" class="ml-1" color="van_color02">
              info_outline </v-icon
            >برای فعال شدن اعلان در تلگرام، بات وندار را «شروع» کنید:
          </v-flex>
          <v-spacer />
          <v-flex>
            <a href="https://t.me/VandarBot" target="_blank">t.me/VandarBot</a>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "SettingNotification",
  props: [],
  data: function () {
    return {
      valid: false,
      input: "",
      //notification:{},
      notification: {
        transaction: {
          sms: false,
          email: true,
          telegram: false,
          notif: true,
        },
        settlement: {
          sms: false,
          email: true,
          telegram: false,
          notif: true,
        },
        auth: {
          sms: false,
          email: true,
          telegram: false,
          notif: true,
        },
      },
    }
  },
  computed: {
    checkPermission() {
      return this.$store.state.isUser ||
        (!this.$store.state.isUser &&
          this.$store.state.business.role == "owner")
        ? true
        : false
    },
  },
  mounted() {
    var url = this.$store.getters.apiWithVersionParam("3") + "/notifications"
    if (this.$store.state.isUser)
      url = this.$store.getters.apiWithVersionParam("2") + "/notifications"
    this.$http
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + this.$store.state.token,
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.notification = response.data.data.notification
      })
      .catch((error) => {
        //callsnackbar
      })
  },
  methods: {
    sendForm(type, channel) {
      var url =
        this.$store.getters.apiWithVersionParam("3") + "/notifications/update"
      if (this.$store.state.isUser)
        url =
          this.$store.getters.apiWithVersionParam("2") + "/notifications/update"
      var data = {
        type: type,
        channel: channel,
        status: this.notification[type][channel],
      }
      if (this.$store.state.isUser)
        data = {
          notification: this.notification,
        }
      this.$http
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.commit(
            "callSuccessSnackbar",
            "اعلان مورد نظر برای شما ذخیره شد"
          )
        })
        .catch((error) => {
          //callsnackbar
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-divider {
  margin: 25px 0;
  border-color: $van_color10;
}
</style>
