<template>
  <general-card
    custom-card-class="h-100"
    :parent-class="$vuetify.breakpoint.smAndDown ? 'w-100 mt-6' : 'w-50'"
  >
    <div class="d-flex flex-column h-100">
      <div
        class="d-flex"
        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
      >
        <div
          class="d-flex align-center font-size-16 bold van_color02--text mr-2"
        >
          مدیریت واریز‌های شناسه‌دار
        </div>
        <v-spacer />
        <div
          class="van_color06 radius-4 text-center pa-2"
          :class="{ 'mt-3': $vuetify.breakpoint.xsOnly }"
          style="width: 120px"
        >
          <span class="font-11-bo-333-17">
            {{ $store.state.suspiciousCount }}
          </span>
          <span class="font-11-no-333-17">
            {{ lang.suspicious.suspiciousTransaction }}
          </span>
        </div>
      </div>
      <div
        v-if="$store.state.suspiciousCount"
        :class="`font-size-12 ${
          $vuetify.breakpoint.smAndDown ? 'mt-8' : 'mt-auto'
        }`"
      >
        تا ۴۸ ساعت زمان دارید که {{ $store.state.suspiciousCount }} تراکنش‌
        مشکوک را بررسی و تعیین وضعیت کنید. در صورت عدم تعیین وضعیت مبلغ به صورت
        خودکار به حساب مبدا بازگردانده می‌شود.
      </div>
      <div
        v-else
        :class="`font-size-12 ${
          $vuetify.breakpoint.smAndDown ? 'mt-8' : 'mt-auto'
        }`"
      >
        در حال حاضر تراکنش مشکوکی برای تعیین وضعیت وجود ندارد. برای مشاهده وجوه
        در راه کلیک کنید.
      </div>
      <div class="d-flex align-center mt-5">
        <v-btn
          outlined
          text
          width="120"
          class="ma-0 van_color03--text"
          @click="showSuspiciousItems = !showSuspiciousItems"
        >
          <span class="ml-3 font-size-12"> مشاهده </span>
          <v-icon size="12" style="width: 12px"> arrow_back </v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-if="showSuspiciousItems"
      v-model="showSuspiciousItems"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <suspicious-items @closeModal="closeModal()" />
    </v-dialog>
  </general-card>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import SuspiciousItems from "@/components/businessHome/elements/suspicious/suspiciousItems.vue"
import lang from "@/lang/languageResource.json"

export default {
  name: "SuspiciousCard",
  components: { SuspiciousItems, GeneralCard },
  data() {
    return {
      showSuspiciousItems: false,
      lang,
    }
  },
  methods: {
    closeModal() {
      this.showSuspiciousItems = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
